interface Options {
  fontFamily?: string;
  fontSize: string;
  fontWeight?: string;
  lineHeight?: string;
  width?: string;
  wordBreak?: string;
}

function createDummyElement(text: string, options: Options): HTMLElement {
  const element = document.createElement("div");
  const textNode = document.createTextNode(text);

  element.appendChild(textNode);

  element.style.fontFamily = options?.fontFamily || "";
  element.style.fontSize = options.fontSize;
  element.style.fontWeight = options?.fontWeight || "";
  element.style.lineHeight = options?.lineHeight || "";
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.left = "-999px";
  element.style.top = "-999px";
  element.style.width = options?.width || "";
  element.style.height = "auto";
  element.style.wordBreak = options?.wordBreak || "";

  document.body.appendChild(element);

  return element;
}

function destroyElement(element: HTMLElement): void {
  element?.parentNode?.removeChild(element);
}

export function calculateElementSize(
  text: string,
  options: Options
): { clientWidth: number } {
  const newElement = createDummyElement(text, options);
  const clientWidth = { clientWidth: newElement?.clientWidth || 0 };
  destroyElement(newElement);
  return clientWidth;
}
