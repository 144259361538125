type CustomEnvVaribles = {
  REACT_APP_REDIRECT_SIGN_IN: string;
  REACT_APP_REDIRECT_SIGN_OUT: string;
  REACT_APP_COGNITO_UI_DOMAIN: string;
  REACT_APP_COOKIES_DOMAIN: string;
  REACT_APP_AWS_APP_REGION: string;
  REACT_APP_AWS_USER_POOL_ID: string;
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID: string;
  REACT_APP_X_API_KEY: string;
  REACT_APP_API_DOMAIN: string;
  REACT_APP_CONTACT_US_EMAIL: string;
  REACT_APP_DVSUM_TOUR_LINK: string;
  REACT_APP_DVSUM_TEST_DRIVE_LINK: string;
  REACT_APP_DVSUM_GET_STARTED_URL: string;
  REACT_APP_DVSUM_INITIATE_TEST_DRIVE_URL: string;
  REACT_APP_CHROME_EXT_ID: string;
  REACT_APP_AIR_TABLE_API_KEY: string;
  REACT_APP_AIR_TABLE_BASE_KEY: string;
  REACT_APP_AIR_TABLE_TUTORIAL_TABLE_ID: string;
  REACT_APP_AIR_TABLE_HELP_TABLE_ID: string;
  REACT_APP_AIR_TABLE_HELP_FEEDBACK_TABLE_ID: string;
  REACT_APP_HELP_CENTER: string;
  REACT_APP_WEB_SOCKET_URL: string;
  REACT_APP_ENV: "dev" | "prod";
  REACT_APP_CLOUD_SAWS_IP_ADDRESS: string;
  REACT_APP_AG_GRID_LICENCE_KEY: string;
  REACT_APP_ZENDESK_ARTICLE_TOKEN: string;
  REACT_APP_DISTINCT_COUNT_FOR_METRIC_AS_ATTRIBUTE: string;
  REACT_APP_DISTINCT_COUNT_FOR_ATTRIBUTE: string;
};

export function getEnvVariables(): CustomEnvVaribles {
  return {
    REACT_APP_REDIRECT_SIGN_IN: process?.env?.REACT_APP_REDIRECT_SIGN_IN || "",
    REACT_APP_REDIRECT_SIGN_OUT:
      process?.env?.REACT_APP_REDIRECT_SIGN_OUT || "",
    REACT_APP_COGNITO_UI_DOMAIN:
      process?.env?.REACT_APP_COGNITO_UI_DOMAIN || "",
    REACT_APP_COOKIES_DOMAIN: process?.env?.REACT_APP_COOKIES_DOMAIN || "",
    REACT_APP_AWS_APP_REGION: process?.env?.REACT_APP_AWS_APP_REGION || "",
    REACT_APP_AWS_USER_POOL_ID: process?.env?.REACT_APP_AWS_USER_POOL_ID || "",
    REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID:
      process?.env?.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID || "",
    REACT_APP_X_API_KEY: process?.env?.REACT_APP_X_API_KEY || "",
    REACT_APP_API_DOMAIN: process?.env?.REACT_APP_API_DOMAIN || "",
    REACT_APP_CONTACT_US_EMAIL: process?.env?.REACT_APP_CONTACT_US_EMAIL || "",
    REACT_APP_DVSUM_TOUR_LINK: process?.env?.REACT_APP_DVSUM_TOUR_LINK || "",
    REACT_APP_DVSUM_TEST_DRIVE_LINK:
      process?.env?.REACT_APP_DVSUM_TEST_DRIVE_LINK || "",
    REACT_APP_DVSUM_GET_STARTED_URL:
      process?.env?.REACT_APP_DVSUM_GET_STARTED_URL || "",
    REACT_APP_DVSUM_INITIATE_TEST_DRIVE_URL:
      process?.env?.REACT_APP_DVSUM_INITIATE_TEST_DRIVE_URL || "",
    REACT_APP_CHROME_EXT_ID: process?.env?.REACT_APP_CHROME_EXT_ID || "",
    REACT_APP_AIR_TABLE_API_KEY:
      process?.env?.REACT_APP_AIR_TABLE_API_KEY || "",
    REACT_APP_AIR_TABLE_BASE_KEY:
      process?.env?.REACT_APP_AIR_TABLE_BASE_KEY || "",
    REACT_APP_AIR_TABLE_TUTORIAL_TABLE_ID:
      process?.env?.REACT_APP_AIR_TABLE_TUTORIAL_TABLE_ID || "",
    REACT_APP_AIR_TABLE_HELP_TABLE_ID:
      process?.env?.REACT_APP_AIR_TABLE_HELP_TABLE_ID || "",
    REACT_APP_AIR_TABLE_HELP_FEEDBACK_TABLE_ID:
      process?.env?.REACT_APP_AIR_TABLE_HELP_FEEDBACK_TABLE_ID || "",
    REACT_APP_HELP_CENTER: process?.env?.REACT_APP_HELP_CENTER || "",
    REACT_APP_WEB_SOCKET_URL: process?.env?.REACT_APP_WEB_SOCKET_URL || "",
    REACT_APP_ENV:
      (process?.env?.REACT_APP_ENV as CustomEnvVaribles["REACT_APP_ENV"]) ||
      "dev",
    REACT_APP_CLOUD_SAWS_IP_ADDRESS:
      process?.env?.REACT_APP_CLOUD_SAWS_IP_ADDRESS || "",
    REACT_APP_AG_GRID_LICENCE_KEY:
      process?.env?.REACT_APP_AG_GRID_LICENCE_KEY || "",
    REACT_APP_ZENDESK_ARTICLE_TOKEN:
      process?.env?.REACT_APP_ZENDESK_ARTICLE_TOKEN || "",
    REACT_APP_DISTINCT_COUNT_FOR_ATTRIBUTE:
      process?.env?.REACT_APP_DISTINCT_COUNT_FOR_ATTRIBUTE || "",
    REACT_APP_DISTINCT_COUNT_FOR_METRIC_AS_ATTRIBUTE:
      process?.env?.REACT_APP_DISTINCT_COUNT_FOR_METRIC_AS_ATTRIBUTE || "",
  };
}
