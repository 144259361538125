import styled from "styled-components";

export const ShareViewerFormStyled = styled.div`
  padding: 19.2px;
  padding-top: 20.8px;

  .modal-heading-sec {
    display: flex;
    align-items: center;
    margin-bottom: 17.6px;

    .heading {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      margin: 0px;
      font-size: 25px;
      line-height: 22px;
      width: 345.6px;
    }

    .cross-modal-icon {
      display: flex;
      align-items: center;

      svg {
        pointer-events: auto;
        cursor: pointer;

        path {
          fill: ${({ theme }): string => theme.bgColors.RED_100};
          opacity: 0.5;
        }

        :hover {
          path {
            opacity: unset;
          }
        }
      }
    }
  }

  .ant-form {
    .ant-form-item {
      padding-left: unset;

      .dvsum-select-field {
        width: 360px;
      }

      #share-textarea {
        height: 160px !important;
      }

      &.send-btn-sec {
        margin-bottom: 0px;

        .ant-form-item-control-input-content {
          display: flex;
          justify-content: right;
        }
      }
    }

    .copy-link-icon {
      margin-bottom: 0px !important;

      .chain-icon {
        display: flex;
        gap: 8px;
        align-items: center;

        svg {
          path {
            fill: ${({ theme }): string =>
              theme.textColors.BLUE_116} !important;
          }
        }
      }
    }
  }
`;
