import { SliderRangeProps, SliderSingleProps } from 'antd/lib/slider';

import { StyledSlider } from './slider.styles';

const Slider = (props: SliderRangeProps|SliderSingleProps): JSX.Element => {
  return (
    <StyledSlider {...props} />
  );
};

export default Slider;
