import styled from "styled-components";

export const CloneRuleSummaryModalGridStyled = styled.div`
  margin-top: 25px;
  position: relative;

  .summary-download-btn {
    position: absolute;
    right: 0;
    top: -50px;

    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
    }
  }

  .table-container {
    .ag-root-wrapper {
      .ag-header-row {
        border: 1px solid
          ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
        background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};

        .ag-header-cell {
          border-right: 1px solid
            ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
        }
      }
    }

    .ag-body-viewport {
      min-height: 200px;
      border: 1px solid
        ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
      border-top: none;
      border-bottom: none;
      border-right: 1px solid
        ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};

      border-left: 1px solid
        ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};

      .ag-row {
        border: none;

        .ag-cell {
          border-right: 1px solid
            ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};

          background: white;

          .ag-group-child-count {
            display: none;
          }
        }
      }
    }
  }

  .ag-body-viewport {
    border: 1px solid red;
  }
`;

export const RuleNameRendererStyled = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  span {
    text-decoration: none;
  }
`;

export const RuleStatusRendererStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;

  .status-error {
    svg {
      path {
        fill: ${({ theme }): string => theme?.textColors?.RED_100};
      }
    }
  }
`;
