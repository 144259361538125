import styled from "styled-components";

export const HierarchyFieldStyled = styled.div`
  width: max-content;
  .first-label {
    min-width: 260px;
    margin-top: 20px;
  }

  .second-label {
    margin-top: 20px;
  }

  .fields-content {
    margin-top: 16px;
    width: max-content;
    column-gap: 20px;
  }

  .remove-field-btn {
    fill: #949494;
  }

  .error {
    color: red;
    text-align: right;
    margin: 3px 0 0 0;
    font-size: 13.4px;
  }
`;

export const InputLabel = styled.p`
  font-family: OpenSansSemiBold;
  font-size: 13.6px;
  color: ${({ theme }): string => theme?.textColors?.GREY_24};
  margin-bottom: 5px;
  display: block;
  /* min-width: 272px; */
`;

export const DescriptionWrapperStyled = styled.div<{
  width?: string;
}>`
  padding: 12px;
  overflow-x: auto;
  border: 1px solid #c9c9c9;
  background-color: #f6f6f6;
  margin-top: 16px;

  width: ${({ width }): string => width || ""};

  color: #949494;
  font-size: 12px;

  .divider {
    margin-top: 10px;
    margin-bottom: 13px;
  }
`;

export const SpinnerLoaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
`;

export const VerticalDividerStyled = styled.div<{
  width?: string;
  height?: string;
  color?: string;
  marginLeft?: string;
  marginRight?: string;
}>`
  height: ${({ height }): string => height || "19px"};
  width: ${({ width }): string => width || "1px"};
  background: ${({ theme, color }): string =>
    color || theme.borderColors.LIGHT_GREY_15};
  ${({ marginLeft }): any => marginLeft && `margin-left: ${marginLeft};`};
  ${({ marginRight }): any => marginRight && `margin-right: ${marginRight};`};
`;

export const OpenDictView = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-top: 8px;

  a {
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    font-size: 12px;
    font-family: OpenSansSemiBold;
  }
`;
