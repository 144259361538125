import { IAppliedFiltersProps } from "../api/enterprisesearchservice/enterprisesearchservice";
import { ES_FILTERS_API_NAMES } from "../constants/labelkeysconstants";

import { getModuleLevelAccessInfo } from "./getModuleLevelAccessInfo";
import { getGlobalFiltersData } from "./getglobalfiltersdata";

export function createDefaultEncodedParamsForGuidedSearch(text = ""): string {
  const { selected_filters: selectedFilters } = getGlobalFiltersData();
  const { hasCatalogAccess } = getModuleLevelAccessInfo();
  const { domain, source } = selectedFilters || {};

  const {
    is_enabled: isSourceEnabled = false,
    filters_list: sourcesFiltersList = [],
  } = source || {};
  const {
    is_enabled: isDomainEnabled = false,
    filters_list: domainsFiltersList = [],
  } = domain || {};

  const domainsNames = isDomainEnabled
    ? domainsFiltersList?.map((item) => item?.name)
    : [];
  const sourcesNames = isSourceEnabled
    ? sourcesFiltersList?.map((item) => item?.name)
    : [];

  return window.btoa(
    JSON.stringify({
      search_text: text,
      keyword: {
        TBL: text,
        COL: text,
        TRM: text,
        DSR: text,
        ANL: text,
        RLS: text,
      },
      node_type: hasCatalogAccess ? "TBL" : "TRM",
      isNewSearch: true,
      nodes_term_filters: {
        TBL: {
          [ES_FILTERS_API_NAMES.tbl_source]: sourcesNames,
          [ES_FILTERS_API_NAMES.tbl_data_domain]: domainsNames,
        },
        COL: {
          [ES_FILTERS_API_NAMES.col_source]: sourcesNames,
          [ES_FILTERS_API_NAMES.col_data_domain]: domainsNames,
        },
        TRM: {
          [ES_FILTERS_API_NAMES.trm_data_domain]: domainsNames,
        },
        DSR: {
          [ES_FILTERS_API_NAMES.tbl_source]: sourcesNames,
          [ES_FILTERS_API_NAMES.tbl_data_domain]: domainsNames,
        },
        ANL: {
          [ES_FILTERS_API_NAMES.tbl_source]: sourcesNames,
          [ES_FILTERS_API_NAMES.tbl_data_domain]: domainsNames,
        },
        RLS: {
          [ES_FILTERS_API_NAMES.tbl_source]: sourcesNames,
          [ES_FILTERS_API_NAMES.tbl_data_domain]: domainsNames,
        },
      },
      nodes_range_filters: {
        TBL: {},
        DSR: {},
        COL: {},
        TRM: {},
        ANL: {},
        RLS: {},
      },
      term_filters: {},
      range_filters: {},
    } as IAppliedFiltersProps)
  );
}
