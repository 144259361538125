import * as yup from "yup";
import {
  getGreaterThanMessage,
  getLessThanMessage,
  INVALID_EMAIL,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";

export const addUserSchema = yup.object().shape({
  first_name: yup.string()?.trim().required(REQUIRED_MESSAGE),
  last_name: yup.string()?.trim().required(REQUIRED_MESSAGE),
  email: yup
    .string()
    ?.trim()
    ?.lowercase()
    ?.email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  user_group: yup.string().required(REQUIRED_MESSAGE),
  start_date: yup
    .date()
    .max(
      yup.ref("expiry_date"),
      getLessThanMessage("Start Date", "Expiration Date")
    )
    .required(REQUIRED_MESSAGE),
  expiry_date: yup
    .date()
    .min(yup.ref("start_date"), getGreaterThanMessage("End Date", "Start Date"))
    .required(REQUIRED_MESSAGE),
});

export const deleteUserSchema = yup.object().shape({
  assign_user_to: yup.string().required(REQUIRED_MESSAGE),
});

export const deactivateUserSchema = yup.object().shape({
  comment: yup.string()?.trim().required(REQUIRED_MESSAGE),
});
