import styled from "styled-components";

export const TooltipWithIconStyled = styled.div<{
  minWidth?: string;
  marginTop?: string;
  marginLeft?: string;
  width?: string;
}>`
  margin-left: ${(props): string => props?.marginLeft || "10px"};
  margin-top: ${(props): string => props?.marginTop || "0px"};
  .white-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        width: 22.071068px;
        height: 22.071068px;
        .ant-tooltip-arrow-content {
          box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
          right: 3px;
          left: 1px;
          width: 20px;
          height: 20px;
          background: ${(props): string => props.theme.bgColors.WHITE_COLOR};
        }
      }
      .ant-tooltip-inner {
        min-width: ${({ minWidth }): string => minWidth || "262px"};
        width: ${({ width }): string => width || "auto"};
        color: ${({ theme }): string => theme.textColors.GREY_26};
        box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
        background: ${(props): string => props.theme.bgColors.WHITE_COLOR};
        padding: 15px;
        font-size: 13px;
        font-family: OpenSans;
      }
    }
  }
`;
