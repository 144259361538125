import styled from "styled-components";
import { ellipsis } from "../../../../../../../../styles/app.styles";
import { InputStyled } from "../../../../../../../../components/inputs/inputs.styles";

export const ListStyled = styled.div`
  max-height: calc(100vh - 480px);
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-basis: auto;
  column-gap: 10px;
  transition: width 0.3s;

  height: ${({ isEditMode }: { isEditMode: boolean }): string =>
    isEditMode ? "50px" : "40px"};

  align-items: center;
  justify-content: space-between;

  .actions-wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  button {
    line-height: 10px;
  }
`;

export const Separator = styled.div`
  border-bottom: 1px dashed
    ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
`;

export const ActionsContainerStyled = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const MetricValueContainerStyled = styled.div`
  ${ellipsis}
`;

export const EditInputStyled = styled(InputStyled)`
  border: 1px solid orange;
  padding: 8px;

  &.edited-input {
    border-radius: unset;
  }
`;
