import styled from "styled-components";

export const VerboseLogsFormStyled = styled.div<{ isChatPage: boolean }>`
  padding-bottom: 20px;
  width: 100%;

  .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    background-color: ${({ theme }): string => theme?.bgColors?.GREY_56};

    .title {
      font-family: OpenSansSemiBold;
      font-size: 13.6px;
    }

    .title-sec-right-sec {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .copy-icon {
        font-size: 12px;
        color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        svg {
          path {
            fill: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
          }
        }
      }

      .cross-icon-container {
        cursor: pointer;
      }
    }
  }

  .content-section {
    background-color: ${({ theme, isChatPage }): string =>
      isChatPage
        ? theme?.bgColors?.WHITE_COLOR
        : theme?.bgColors?.LIGHT_GREY_22};

    .logs-sec {
      word-break: break-word;
      padding: 12px;
      background: transparent;
      max-height: 351px;
      overflow-y: auto;
      white-space: pre-line;

      .bold {
        font-family: OpenSansSemiBold;
      }
    }

    .question-text {
      color: ${({ theme }): string => theme.textColors.GREY_40};
      font-size: 14.4px;
    }
  }

  .form-act-sec {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 15px 25px;
    background-color: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
  }
`;

export const EmptyVerboseLogState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 15px 25px;
  color: ${({ theme }): string => theme.textColors.GREY_40};
  font-size: 13.6px;
`;
