import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import {
  InputField,
  SelectField,
  TextAreaField,
} from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";

import { API_CONFIG } from "../../constants/apiconfig";
import { appendQueryParamInUrl, useRequestWithMethod } from "../../api";
import { useGetRefByType } from "../../api/refservice";

import { addTermSchema } from "../../utils/schemas/termschemas";

import { useCancelModal } from "../../customhooks";

import { AddTermFormType } from "./addtermform.types";

import { AddTermFormStyled } from "./addtermform.styles";
import { APP_ROUTES, TERM_SECTION_TITLES } from "../../constants";
import { termDetailPageTabsValues } from "../../pages/termspage/termspage.constants";
import { useGetLoggedInUserDomainAndSubdomainForTerm } from "../../api/glossarycategoriesservice/glossarycategoriesservice";

const { OVERVIEW } = TERM_SECTION_TITLES;
const { DESCRIPTION, TITLE, TERM_NAME, TERM_CATEGORY, TERM_TYPE } = OVERVIEW;

const AddTermForm = (): JSX.Element => {
  const onCancel = useCancelModal();
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    parsedData: termCategories,
    isLoading: isLoadingCategories,
    error: errorCategories,
  } = useGetLoggedInUserDomainAndSubdomainForTerm();

  const {
    parsedData: parsedTermSubtypes,
    isLoading: isLoadingTermSubtypes,
    error: errorTermSubtypes,
  } = useGetRefByType("TERM_SUBTYPE");

  const isOnlyOneTermCategory = termCategories?.length === 1;

  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
    setError,
  } = useForm<AddTermFormType>({
    defaultValues: {
      name: "",
      title: "",
      definition: "",
      category: isOnlyOneTermCategory ? termCategories?.[0]?.value : undefined,
      type: undefined,
    },
    resolver: yupResolver(addTermSchema),
    mode: "onChange",
  });

  const onSuccess = useCallback((response) => {
    const termId = response?.data?.[0]?.FN_CREATE_NODE || "";
    const api = API_CONFIG?.get_current_node?.url;
    const url = appendQueryParamInUrl(api, ["TRM"]);
    queryClient.invalidateQueries(url, { fetching: false });

    history.push(
      `${APP_ROUTES.private_routes.terms}/${termId}/${termDetailPageTabsValues.overview}`
    );
    onCancel();
  }, []);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "add_term",
    undefined,
    true,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    onExecuteRequest({
      term_name: values?.name,
      term_title: values?.title || values?.name,
      term_desc: values?.definition,
      cat_id: Number.parseInt(values?.category),
      term_sub_type: values?.type,
    });
  }, []);

  const termSubtypes = useMemo(() => {
    return parsedTermSubtypes?.map((term) => ({
      value: term?.id,
      label: term?.name,
    }));
  }, [parsedTermSubtypes]);

  const termCategoriesOption = useMemo(() => {
    return termCategories?.length
      ? [
          {
            label: "Select the below Domain > Sub-Domain",
            value: "",
            options: termCategories,
          },
        ]
      : [];
  }, [termCategories]);

  return (
    <StateHandler
      isFetching={isLoading || isLoadingCategories || isLoadingTermSubtypes}
      error={error || errorCategories || errorTermSubtypes}
      isModal
    >
      <AddTermFormStyled>
        <FormStyled onFinish={handleSubmit(onSubmit) as any} isItemColumnLayout>
          <div className="form-content">
            <FormItemStyled label={TERM_CATEGORY} required>
              <SelectField
                control={control}
                name="category"
                options={termCategoriesOption || []}
                setValue={setValue}
                setError={setError}
                placeholder="Select"
                showSearch
                width="495px"
                isAllowClear={false}
                filterOption={(input: string, option: any): boolean =>
                  option?.labelText
                    ?.toString()
                    .toLowerCase()
                    .includes(input?.toString().toLowerCase())
                }
                disabled={isOnlyOneTermCategory}
              />
            </FormItemStyled>

            <FormItemStyled label={TERM_NAME} required>
              <InputField control={control} name="name" />
            </FormItemStyled>

            <FormItemStyled label={TITLE}>
              <InputField control={control} name="title" />
            </FormItemStyled>

            <FormItemStyled label={DESCRIPTION} required>
              <TextAreaField control={control} name="definition" />
            </FormItemStyled>

            <FormItemStyled label={TERM_TYPE} required>
              <SelectField
                control={control}
                name="type"
                options={termSubtypes}
                setValue={setValue}
                setError={setError}
                placeholder="Select"
                width="495px"
                isAllowClear={false}
                showSearch
                filterOption={(input: string, option: any): boolean =>
                  option?.label
                    ?.toString()
                    .toLowerCase()
                    .includes(input?.toString().toLowerCase())
                }
              />
            </FormItemStyled>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddTermFormStyled>
    </StateHandler>
  );
};

export default AddTermForm;
