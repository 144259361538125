import styled from "styled-components";

import { FlexStyled } from "../../../flex";

export const PopularityScoreCriteriaStyled = styled(FlexStyled)`
  margin-right: 10px;

  .criteria-item {
    .criteria-point {
      width: 4px;
      height: 4px;
      background: ${({ theme }): string => theme.bgColors.GREY_24};
      border-radius: 50%;
      margin-top: 9px;
    }

    .criteria-item-body {
      font-size: 13px;
      color: ${({ theme }): string => theme.textColors.GREY_24};

      .criteria-title {
        font-family: "OpenSansSemiBold";
      }
    }
  }
`;
