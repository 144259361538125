import { DQActionStatusType } from "../app.types";
import { ProminentTagType } from "../components/genericprominenttag/genericprominenttag.types";

export const dqActionStatusMapping = (
  actionStatusId: DQActionStatusType,
  actionStatus: string
): ProminentTagType => {
  const availableColors: {
    [key in DQActionStatusType]: ProminentTagType["type"];
  } = {
    OPN: "RED",
    ASN: "YLW",
    RSN: "YLW",
    INP: "ORG",
    RSL: "GRN",
    "": "BLU",
  };
  return {
    id: actionStatusId,
    name: actionStatus || "",
    type: availableColors?.[actionStatusId],
  };
};
