import { useCallback, useMemo, useState } from "react";
import { Input } from "antd";

import Checkbox from "../../checkbox";
import { ChoiceBoxStyled } from "./choiceboxfield.styles";
import { ChoiceBoxProps } from "./choiceboxfield.types";

import { CheckboxGroupField } from "..";
import Flex from "../../flex";
import { searchIcon1 } from "../../../svgs";

const searchIconSvg = searchIcon1("12.5px");

const ChoiceBoxField = (props: ChoiceBoxProps): JSX.Element => {
  const { options, width, control, setValue, name, watch } = props;
  const [items, setItems] = useState(options);
  const watchCheckboxGroup = watch()[name];

  const hasSelectedAllItems = useMemo(
    () => watchCheckboxGroup?.length === items?.length,
    [watchCheckboxGroup]
  );

  const onSectionSelection = useCallback(
    (e): void =>
      setValue(
        name,
        e?.target?.checked ? items?.map((item) => item?.value) : []
      ),
    [items]
  );

  const onSearch = useCallback(
    (e): void => {
      if (e?.target?.value) {
        const searchInputText = e?.target?.value || "";
        const searchInputTextInLowerCase = searchInputText
          ?.toString()
          ?.toLowerCase();

        setItems((prevItems) =>
          prevItems?.filter((item) =>
            item?.label
              ?.toString()
              ?.toLowerCase()
              .includes(searchInputTextInLowerCase)
          )
        );
      } else {
        setItems(options);
      }
    },
    [items]
  );

  return (
    <ChoiceBoxStyled width={width}>
      <Input onChange={onSearch} prefix={searchIconSvg} />
      <div className="content">
        <Flex>
          <Checkbox
            width="15px"
            height="15px"
            mode="secondary"
            checked={hasSelectedAllItems}
            onChange={onSectionSelection}
          />
          <div className="item">Select/Unselect All</div>
        </Flex>
        <div className="item_content">
          <CheckboxGroupField
            control={control}
            setValue={setValue}
            name={name}
            className="checkbox"
            options={items}
          />
        </div>
      </div>
    </ChoiceBoxStyled>
  );
};
export default ChoiceBoxField;
