import { useCallback, useMemo } from "react";
import CoreNodesCircleIcon from "../../../../../../../../../../../../components/corenodetypescircleicon";

import LinkButton from "../../../../../../../../../../../../components/linkbutton";

import { useOpenDrawer } from "../../../../../../../../../../../../customhooks";
import { databaseTableIcon } from "../../../../../../../../../../../../svgs";

import {
  checkIsChatPage,
  parseStringToHtml,
} from "../../../../../../../../../../../../utils";

import { AboutThePromptSecStyled } from "./aboutthepromptsec.styles";
import { AboutThePromptSecProps } from "./aboutthepromptsec.types";
import { ELEMENT_IDS } from "../../../../../../../../../../../../constants";

const { caddi_abt_qry_blk: CADDI_ABT_QRY_BLK } = ELEMENT_IDS;

const AboutThePromptSec = (props: AboutThePromptSecProps): JSX.Element => {
  const openDrawer = useOpenDrawer();
  const isChatPage = checkIsChatPage();

  const { data, onShowSampleData, index, isExpanded, isAuthor } = props;

  const {
    introduction = "",
    source = "",
    source_type: sourceType = "",
    tables = [],
    refresh_ts: refreshTs,
    stewards = [],
    aboutText = "",
  } = data || {};

  const isAnythingExistsInSummary = useMemo(
    () =>
      aboutText ||
      introduction ||
      source ||
      sourceType ||
      tables?.length ||
      refreshTs?.min ||
      refreshTs?.max ||
      stewards?.length,
    [data]
  );

  const onOpenRef = useCallback((id: number) => {
    openDrawer({ drawerId: "table_ref", visible: true, drawerProps: { id } });
  }, []);

  return (
    <AboutThePromptSecStyled id={`${CADDI_ABT_QRY_BLK}-${index + 1}`}>
      {isAnythingExistsInSummary ? (
        <>
          <div className="heading">About the Bot</div>
          {!isExpanded && <div className="subtitle">{aboutText}</div>}
          {isExpanded && (
            <div className="intro">{parseStringToHtml(introduction)}</div>
          )}
          {isExpanded && (
            <>
              <div className="tables-info-wrapper">
                <div className="subtitle">{aboutText}</div>

                <div className="tables-list">
                  {!!tables?.length &&
                    tables?.map((table, nestIndex) => (
                      <div
                        className="table-name"
                        key={`about-prompt-sec-${table?.id}`}
                      >
                        <CoreNodesCircleIcon
                          nodeType="TBL"
                          height="19.2px"
                          width="19.2px"
                          fontSize="12"
                        />
                        <LinkButton onClick={(): void => onOpenRef(table?.id)}>
                          {/*for previously existing analysis where title property doesn't exists */}
                          {table?.title || table?.name}
                        </LinkButton>
                        {!(isChatPage && !isAuthor) && (
                          <LinkButton
                            className="sample-data-btn"
                            onClick={(): void =>
                              onShowSampleData(
                                `${table?.id || 0}`,
                                table?.title,
                                table?.name
                              )
                            }
                            elementId={`${CADDI_ABT_QRY_BLK}-${index + 1}-tbl-${
                              nestIndex + 1
                            }`}
                          >
                            ({databaseTableIcon("15.2", "15.2")} Show sample
                            data )
                          </LinkButton>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              {!!stewards?.length && (
                <div className="stewards-info">
                  <div className="subtitle">
                    You can reach out to these people for more information about
                    this data:
                  </div>

                  <ul className="stewards-list">
                    {stewards?.map((steward) => (
                      <li
                        className="steward-name"
                        key={`about-prompt-sec-stewards-${steward?.email}`}
                      >
                        {steward?.name || ""}:{" "}
                        <a
                          className="steward-email"
                          href={`mailto:${steward?.email || ""}`}
                        >
                          {steward?.email || ""}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="no-summary-state">
          No summary exists about the topic.
        </div>
      )}
    </AboutThePromptSecStyled>
  );
};

export default AboutThePromptSec;
