import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const ResultsSecStyled = styled.div`
  .article-item {
    span {
      display: block;
      width: 340px;
      text-align: left;
      ${ellipsis}
    }
  }

  .sec-blank-slate {
    color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    opacity: unset;
  }
`;
