import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";
import { FilterCriteriaSchema } from "./filtercriteriaschema";

export const addWidgetFilterSchema = yup.object().shape({
  name: yup.string()?.trim().required(REQUIRED_MESSAGE),
  save_as_template: yup.boolean(),

  filter_criteria: yup.lazy((_p, p1) => {
    return yup.array().of(
      yup.object().shape({
        condition: yup.array().of(FilterCriteriaSchema),
      })
    );
  }),
});
