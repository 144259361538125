import { API_CONFIG } from "../../constants/apiconfig";
import {
  getParsedRefDict,
  getParsedRefDicts,
  getParsedRefDictValues,
} from "../../parsers/referencedictionary/referencedictionaryparser";
import {
  ReferenceDictionariesParserReturnType,
  ReferenceDictionariesValuesParserReturnType,
  ReferenceDictionaryParserReturnType,
} from "../../parsers/referencedictionary/referencedictionaryparser.types";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_all_reference_dictionaries: getAllReferenceDictionaries,
  get_reference_dictionary: getReferenceDictionary,
  get_reference_dictionary_values: getReferenceDictionaryValues,
} = API_CONFIG;

export function useGetAllReferenceDictionaries(): CustomReactQueryHookReturnType<
  ReferenceDictionariesParserReturnType[]
> {
  return useGetAll({
    apiConfig: getAllReferenceDictionaries,
    parser: getParsedRefDicts,
  });
}

export function useGetReferenceDictionary(
  refDictId: string
): CustomReactQueryHookReturnType<ReferenceDictionaryParserReturnType> {
  return useGetAll({
    apiConfig: getReferenceDictionary,
    parser: getParsedRefDict,
    params: [refDictId],
    options: { enabled: !!refDictId },
  });
}

export function useGetReferenceDictionaryValues(
  refDictId: string
): CustomReactQueryHookReturnType<ReferenceDictionariesValuesParserReturnType> {
  return useGetAll({
    apiConfig: getReferenceDictionaryValues,
    parser: getParsedRefDictValues,
    params: [refDictId],
    options: { enabled: !!refDictId },
  });
}
