import { QueryObserverResult } from "react-query";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import { TagsData } from "../../components/tagseditor/tagseditor.types";
import { API_CONFIG } from "../../constants/apiconfig";

import { NodeSuggestionsParserReturnType } from "../../parsers/nodesuggestions/nodesuggestionsparser.types";

import {
  ColumnParsedConfigurationType,
  FiltersColumnParsedConfigurationType,
  ReturnTypeOfGetDataModelMaxLevelParser,
  ReturnTypeOfGetDataModelStateParser,
  ReturnTypeOfGetRelatedTables,
  ReturnTypeOfRelationshipColumnsDataParser,
  ReturnTypeOfRelationshipTablesDataParser,
  ReturnTypeOfTableDocParser,
  ReturnTypeOfTableRefParser,
  RulesEditDrawerParsedDataType,
  UserOrUsersGroupParsedType,
} from "../../parsers/tablepage/tablepageparser.types";

import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

import {
  getParsedRelationshipColumnsData,
  getParsedDataModelWithLevels,
  getParsedTableDocForRefPage,
  getParsedDataModelMaxLevel,
  getParsedDataModelState,
  getColumnConfigData,
  getTableEditConfigData,
  getColumnsWithDistributionData,
  getNodeTypeTAGContextParser,
} from "../../parsers/tablepage/tablepageparser";

import { getFiltersfromLocalStorage, getObjectKeys } from "../../utils";
import { GetClassificationCustomTagsAddInfo } from "../../parsers/parserutils/getclassificationcustomtags";
import { NodeType } from "../../app.types";

const {
  get_node: getNode,
  get_node_tags: getTableTag,
  get_suggestions: getSuggestion,
  get_user_users_groups: getUserAndGroups,
  get_related_tables: getRelatedTables,
  get_data_model_max_level: getDataModelMaxLevels,
  get_data_model_with_levels: getDataModelWithLevels,
  get_relationship_columns_data: getRelationshipColumnsData,
  get_source_columns_data: getSourceColumnsData,
  get_data_model_state: getDataModelState,
  get_column_config: getColumnConfig,
  get_node_edit_config: getTableEditConfig,
} = API_CONFIG;

export function useGetOverViewData(
  id: string,
  version: "PUB" | "DFT",
  enabled?: boolean
): CustomReactQueryHookReturnType<ReturnTypeOfTableDocParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage("TBL");

  return useGetAll({
    apiConfig: getNode,
    params: [id, "TBL", version, "", "", parsedNodeFilter],
    options: { enabled: version === "PUB" ? true : !!enabled },
  });
}

export function useGetClassificationData(
  id: string,
  enabled: boolean,
  isDraftExist: boolean
): {
  parsedData: TagsData;
  isLoading: boolean;
  error: any;
  refetch: () => Promise<QueryObserverResult<unknown, unknown>>;
} {
  const { parsedData } = useGetOverViewData(id, isDraftExist ? "DFT" : "PUB");

  const getClassificationRow = (tagsetId: string): SectionRowProps =>
    parsedData?.classification?.data?.find(
      (item) =>
        (item?.additionalInfo as GetClassificationCustomTagsAddInfo)
          ?.tagsetId === tagsetId
    ) || { title: "" };

  const {
    parsedData: parsedTags = {},
    isLoading,
    error,
    refetch,
  } = useGetAll<TagsData>({
    apiConfig: getTableTag,
    params: ["TBL"],
    options: { enabled },
    parser: getNodeTypeTAGContextParser,
  });

  return {
    parsedData: {
      ...getObjectKeys(parsedTags)?.reduce((prev, item) => {
        const row = getClassificationRow(item);
        const { tagsetConfig = {} } = (row?.additionalInfo ||
          {}) as GetClassificationCustomTagsAddInfo;

        const {
          tagset_name: tagsetName,
          is_comment_required: isCommentRequired,
          is_multi_value_allowed: isMultiValueAllowed,
          is_new_value_allowed: isNewValueAllowed,
          tagset_type: tagsetType,
        } = tagsetConfig?.[item] || {};

        const isSelected = (val: string): boolean => {
          const combinedTags = [
            ...(row?.tags || []),
            ...(row?.prominentTags?.tags?.map((promTag) => promTag?.name) ||
              []),
          ];
          return combinedTags?.length
            ? combinedTags?.includes(val)
            : val === row?.value;
        };
        return {
          ...prev,
          [tagsetName]: {
            ...parsedTags?.[item],
            isMultiple: !!isMultiValueAllowed,
            isNewValueAllowed: !!isNewValueAllowed,
            isNoteRequired: !!isCommentRequired,
            tagsetType,
            tagsData: parsedTags?.[item]?.tagsData?.map((nestItem) => ({
              ...nestItem,
              selected: isSelected(nestItem?.label),
              isAdded: isSelected(nestItem?.label),
              comment: row?.prominentTags?.tags?.find(
                (item) => item?.id === nestItem?.value
              )?.tagDetails?.tagNote,
            })),
          },
        };
      }, {}),
    },
    isLoading,
    error,
    refetch,
  };
}

export function useGetSuggestionsForOverview(
  id: string,
  enabled: boolean
): CustomReactQueryHookReturnType<NodeSuggestionsParserReturnType> {
  return useGetAll({
    apiConfig: getSuggestion,
    params: [id],
    options: { enabled },
  });
}

export function useGetUserAndGroups(
  enabled: boolean,
  onlyUsers?: boolean,
  onlyAdminAndEditorRole?: boolean
): CustomReactQueryHookReturnType<UserOrUsersGroupParsedType[]> {
  return useGetAll({
    apiConfig: getUserAndGroups,
    params: [
      onlyAdminAndEditorRole ? "true" : "",
      onlyUsers ? "USR" : "USR,UGP",
      "",
    ],
    options: { enabled: !!enabled },
  });
}

export function useGetRelatedTables(
  tableId: string
): CustomReactQueryHookReturnType<ReturnTypeOfGetRelatedTables[]> {
  return useGetAll({ apiConfig: getRelatedTables, params: [tableId, ""] });
}

export function useGetTableDoc(
  nodeID: string,
  nodeType: string,
  nodeStatus: string
): CustomReactQueryHookReturnType<ReturnTypeOfTableRefParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage("TBL");

  return useGetAll({
    apiConfig: getNode,
    params: [nodeID, nodeType, nodeStatus, "true", "", parsedNodeFilter],
    parser: getParsedTableDocForRefPage,
  });
}

export function useGetDataModelMaxLevel(
  tableId: string
): CustomReactQueryHookReturnType<ReturnTypeOfGetDataModelMaxLevelParser> {
  return useGetAll({
    apiConfig: getDataModelMaxLevels,
    params: [tableId],
    parser: getParsedDataModelMaxLevel,
  });
}

export function useGetDataModelWithLevels(
  tableId: string,
  tableName: string,
  diagramLevel: number,
  enabled: boolean
): CustomReactQueryHookReturnType<ReturnTypeOfRelationshipTablesDataParser> {
  return useGetAll({
    apiConfig: getDataModelWithLevels,
    params: [tableId, `${diagramLevel >= 3 ? 3 : diagramLevel}`],
    parser: (res): ReturnTypeOfRelationshipTablesDataParser =>
      getParsedDataModelWithLevels(res, diagramLevel, tableId, tableName),
    options: { enabled },
  });
}

export function useGetRelationshipColumnsData(
  tableId: string,
  erdTableIds: string,
  enabled: boolean
): CustomReactQueryHookReturnType<ReturnTypeOfRelationshipColumnsDataParser> {
  return useGetAll({
    apiConfig: getRelationshipColumnsData,
    params: [tableId, erdTableIds],
    options: { enabled },
  });
}

export function useGetSourceColumnsData(
  tableId: string,
  sourceId: string
): CustomReactQueryHookReturnType<ReturnTypeOfRelationshipColumnsDataParser> {
  return useGetAll({
    apiConfig: getSourceColumnsData,
    params: [tableId, sourceId],
    parser: getParsedRelationshipColumnsData,
    options: { enabled: !!sourceId && !!tableId },
  });
}

export function useGetDataModelState(
  tableId: string
): CustomReactQueryHookReturnType<ReturnTypeOfGetDataModelStateParser> {
  return useGetAll({
    apiConfig: getDataModelState,
    params: [tableId],
    parser: getParsedDataModelState,
  });
}

// SEE IF WE CAN COMBINE useGetColumnConfig AND useGetColumnsWithDistribution INTO ONE
export function useGetColumnConfig(
  tableId: string
): CustomReactQueryHookReturnType<ColumnParsedConfigurationType[]> {
  return useGetAll({
    apiConfig: getColumnConfig,
    params: [tableId],
    parser: getColumnConfigData,
    options: { enabled: !!tableId },
  });
}

export function useGetColumnsWithDistribution(
  tableId: string
): CustomReactQueryHookReturnType<FiltersColumnParsedConfigurationType[]> {
  return useGetAll({
    apiConfig: getColumnConfig,
    params: [tableId],
    parser: getColumnsWithDistributionData,
    options: { enabled: !!tableId },
  });
}

export function useGetEditTableConfig(
  tableId: string,
  nodeType: NodeType
): CustomReactQueryHookReturnType<RulesEditDrawerParsedDataType> {
  return useGetAll({
    apiConfig: getTableEditConfig,
    params: [tableId, nodeType],
    parser: getTableEditConfigData,
    options: { enabled: !!tableId && !!nodeType },
  });
}
