// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../../../utils";
// <--- Utils End --->

export const BreadcrumSectionStyled = styled.div`
  background: ${({ theme }): string =>
    hexToRgbA(theme.bgColors.LIGHT_GREY_46, 0.5)};
  color: ${({ theme }): string => theme.textColors.DARK_BLUE};
  padding: 9.5px 28px;
  display: flex;
  align-items: center;
  gap: 8.5px;

  .breadcrum-item {
    display: flex;
    align-items: center;
    gap: 5px;

    .item-icon {
      width: auto;
      height: 16px;

      svg {
        height: 16px;

        path {
          fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
        }
      }
    }

    .core-nodes-circle-icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
    }
  }
`;
