import styled from "styled-components";

export const CancelProfilingModalStyled = styled.div`
  .label {
    padding: 30px 80px 70px 30px;
    font-size: 14px;
    text-align: flex-start;
    color: ${({ theme }): string => theme.textColors.GREY_40};
  }
`;

export const CancelProfilingModalStyledFooterStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 18px 8px 18px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
