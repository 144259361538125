import styled from "styled-components";

export const FilterConditionExpandedStyled = styled.div`
  .label {
    padding: 30px 80px 50px 30px;
    font-size: 14px;
    text-align: flex-start;
  }
`;

export const FilterConditionWrapperStyled = styled.div`
  /* padding: 0 45px; */
  /* min-height: 350px; */
  /* max-height: 600px; */
  /* overflow-y: auto; */
  /* margin: 45px 0; */
`;

export const FilterConditionExpandedFooterStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 18px 8px 18px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
