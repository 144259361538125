import { useCallback } from "react";
import { useGatewayStatusContext } from "../contexts";
import { CustomException } from "../customexception";
import { useGetCurrentGateway } from "./usegetcurrentgateway";

export const useGatewayDownPrompt = (
  gatewayId: string
): {
  triggerPrompt: (err: CustomException | undefined) => void;
} => {
  const { dispatch } = useGatewayStatusContext();

  const { isGatewayRunning } = useGetCurrentGateway(gatewayId);

  const triggerPrompt = useCallback(
    (err: CustomException | undefined) => {
      const isTimeOutError = err?.formattedMsg?.includes("timeout");

      if (!err?.status && !isTimeOutError && isGatewayRunning) {
        dispatch({ type: "SHOW_GATEWAY_DOWN_PROMPT", id: gatewayId });
      }
    },
    [gatewayId, isGatewayRunning]
  );

  return { triggerPrompt };
};
