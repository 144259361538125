import { useMemo } from "react";
import { useQuery, QueryObserverOptions } from "react-query";

import {
  appendQueryParamInUrl,
  fetchRequestWithCustomHeaders,
  fetchRequestWithoutHeaders,
  fetchRequestWithoutParam,
} from "../api";

import { API_CONFIG_TYPE } from "../constants/apiconfig";
import { CustomReactQueryHookReturnType } from "./services.types";

import { ApiMethods } from "../app.types";

export function useGetAll<T>({
  apiConfig,
  params,
  parser,
  options,
  headers,
  isReqWithoutHeader,
  isReqWithCustomHeader,
  fetchRequestWithoutHeadersMethod = "GET",
}: {
  apiConfig: API_CONFIG_TYPE;
  params?: string[];
  parser?: (_data: any) => any;
  options?: QueryObserverOptions;
  headers?: any;
  isReqWithoutHeader?: boolean;
  isReqWithCustomHeader?: boolean;
  fetchRequestWithoutHeadersMethod?: ApiMethods;
}): CustomReactQueryHookReturnType<T> {
  const fetcher = isReqWithoutHeader
    ? fetchRequestWithoutHeaders(fetchRequestWithoutHeadersMethod)
    : isReqWithCustomHeader
    ? fetchRequestWithCustomHeaders(undefined, headers)
    : fetchRequestWithoutParam(headers);
  const updatedConfigApi = {
    ...apiConfig,
    ...(params && { url: appendQueryParamInUrl(apiConfig?.url, params ?? []) }),
    parser: parser ?? apiConfig?.parser,
  };

  const config = useQuery({
    ...options,
    queryKey: updatedConfigApi.url,
    queryFn: fetcher,
  });

  const memoizedParseData = useMemo(() => {
    try {
      return (!config?.isLoading && updatedConfigApi?.parser
        ? updatedConfigApi?.parser(config?.data || {})
        : undefined) as any;
    } catch (err) {
      console.log("error in parsing data", err);
      return undefined;
    }
  }, [JSON.stringify(config?.data)]);

  return {
    ...config,
    parsedData: memoizedParseData,
  };
}
