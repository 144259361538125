import { cloneElement, useCallback, useMemo } from "react";

import { ShareHOCProps, ShareOwnerHOCEmbedProps } from "./sharehoc.types";
import { ShareOwnerFormType, SharedWithType } from "../shareownerform.types";

const ShareHOC = ({
  nodeType = "",
  children,
  saveShareRefNodes,
  shareWith,
  parsedSharedWith,
  parsedUsers,
  nodeId,
}: ShareHOCProps): JSX.Element => {
  const isShowShareForm = shareWith?.length > 0 || false;

  const onListingSubmit = useCallback(
    (values: ShareOwnerFormType) => {
      const shareWith = isShowShareForm
        ? parsedUsers
            ?.filter((user) => values?.share_with?.includes(user?.value))
            ?.map((user) => ({
              node_id: Number.parseInt(user?.id || ""),
              node_type: user?.type,
              access_type:
                values?.share_with_role === "viewer"
                  ? "GOV_VIEWER"
                  : "GOV_EDITOR",
            }))
        : values?.shared_with_list?.map((value) => ({
            node_id: value?.shared_with_id,
            node_type: value?.type,
            access_type:
              value?.access_type === "viewer"
                ? "GOV_VIEWER"
                : value?.access_type === "editor"
                ? "GOV_EDITOR"
                : "GOV_OWNER",
          }));
      saveShareRefNodes(
        {
          refNodes: shareWith || [],
          description: values?.message || "",
        },
        [nodeId, nodeType]
      );
    },
    [parsedUsers, isShowShareForm, nodeId, nodeType]
  );

  const onAnalysisShareSubmit = useCallback(
    (values: ShareOwnerFormType) => {
      const shareWith = isShowShareForm
        ? parsedUsers
            ?.filter((user) => values?.share_with?.includes(user?.value))
            ?.map((user) => ({
              node_id: Number.parseInt(user?.id || ""),
              node_type: user?.type,
              access_type:
                values?.share_with_role === "viewer"
                  ? "USER_VIEWER"
                  : "USER_EDITOR",
            }))
        : values?.shared_with_list?.map((value) => ({
            node_id: value?.shared_with_id,
            node_type: value?.type,
            access_type:
              value?.access_type === "viewer"
                ? "USER_VIEWER"
                : value?.access_type === "editor"
                ? "USER_EDITOR"
                : value?.access_type === "remove-access"
                ? "RMV"
                : "USER_OWNER",
          }));

      const isAnyViewerOrEditorInShareList = shareWith?.find(
        (item) =>
          item?.access_type === "USER_EDITOR" ||
          item?.access_type === "USER_VIEWER"
      );

      saveShareRefNodes(
        {
          refNodes: shareWith || [],
          description: values?.message || "",
        },
        [nodeId, nodeType, isAnyViewerOrEditorInShareList ? "LIM" : "NON"]
      );
    },
    [parsedUsers, isShowShareForm, nodeId, nodeType]
  );

  const sharedWithItemsOnListing = useMemo(() => {
    const sharedWithList =
      parsedSharedWith
        ?.filter((item) =>
          ["GOV_EDITOR", "GOV_VIEWER"]?.includes(item?.access_type)
        )
        ?.map((sharedWith) => {
          return {
            shared_with_id: sharedWith?.id || 0,
            name: sharedWith?.name || "",
            email: sharedWith?.email || "",
            type: sharedWith?.type,
            access_type: ["GOV_VIEWER", "GOV_EDITOR"].includes(
              sharedWith?.access_type
            )
              ? sharedWith?.access_type === "GOV_VIEWER"
                ? "viewer"
                : "editor"
              : "owner",
          };
        }) || [];

    return sharedWithList;
  }, [parsedSharedWith]);

  const sharedWithItemsOnAnalysis = useMemo(() => {
    const sharedWithList =
      parsedSharedWith
        ?.filter((item) =>
          ["USER_EDITOR", "USER_VIEWER"]?.includes(item?.access_type)
        )
        ?.map((sharedWith) => {
          return {
            shared_with_id: sharedWith?.id || 0,
            name: sharedWith?.name || "",
            email: sharedWith?.email || "",
            type: sharedWith?.type,
            access_type: ["USER_VIEWER", "USER_EDITOR"].includes(
              sharedWith?.access_type
            )
              ? sharedWith?.access_type === "USER_VIEWER"
                ? "viewer"
                : "editor"
              : "owner",
          };
        }) || [];

    return sharedWithList;
  }, [parsedSharedWith]);

  const ownerDetails = useMemo(() => {
    const ownerNode = parsedSharedWith?.find(
      (item) =>
        item?.access_type === "USER_OWNER" || item?.access_type === "GOV_OWNER"
    );

    const owner: SharedWithType = {
      shared_with_id: ownerNode?.id || 0,
      name: ownerNode?.name || "",
      email: ownerNode?.email || "",
      type: ownerNode?.type || "USR",
      access_type: "owner",
    };
    return owner;
  }, [parsedSharedWith]);

  return cloneElement(children, {
    onSubmit: nodeType === "ANL" ? onAnalysisShareSubmit : onListingSubmit,
    sharedWithListItems:
      nodeType === "ANL"
        ? [ownerDetails, ...sharedWithItemsOnAnalysis]
        : [ownerDetails, ...sharedWithItemsOnListing],
  } as ShareOwnerHOCEmbedProps);
};

export default ShareHOC;
