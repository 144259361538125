import * as yup from "yup";
import {
  DOMAIN_ALREADY_EXISTS,
  INVALID_DOMAIN_NAME,
  INVALID_IP_ADDRESS,
  INVALID_PORT,
  INVALID_PROVIDER_NAME,
  INVALID_SAWS_NAME,
  IP_ADDRESS_REGEX,
  IP_ALREADY_EXISTS,
  ALPHANUMERIC_REGEX,
  PORT_NUMBER_REGEX,
  REQUIRED_MESSAGE,
  URL_REGEX,
  HOSTNAME_REGEX,
  INVALID_HOSTNAME,
  INVALID_METADATA_URL,
  INVALID_IDENTIFIER,
  USER_ALREADY_EXISTS,
} from "../../constants/formconstants";

export const addIpAddressSchema = (
  ipAddresses: string[]
): yup.AnyObjectSchema =>
  yup.object().shape({
    value: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .matches(IP_ADDRESS_REGEX, {
        message: INVALID_IP_ADDRESS,
        excludeEmptyString: true,
      })
      .test(
        "includes",
        IP_ALREADY_EXISTS,
        (value) => !ipAddresses.includes(value || "")
      ),
  });

export const addDomainNameSchema = (domains: string[]): yup.AnyObjectSchema =>
  yup.object().shape({
    value: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .matches(URL_REGEX, {
        message: INVALID_DOMAIN_NAME,
        excludeEmptyString: true,
      })
      .test(
        "includes",
        DOMAIN_ALREADY_EXISTS,
        (value) => !domains.includes(value || "")
      ),
  });

export const addUserSchema = (users: string[]): yup.AnyObjectSchema =>
  yup.object().shape({
    value: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .test(
        "includes",
        USER_ALREADY_EXISTS,
        (value) => !users.includes(value || "")
      ),
  });

export const addSAWSSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      "all-spaces-not-allowed",
      INVALID_SAWS_NAME,
      (value) => value?.trim() !== ""
    ),
  host_name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(HOSTNAME_REGEX, INVALID_HOSTNAME),
  port: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(PORT_NUMBER_REGEX, INVALID_PORT),
});

export const addSSOSchema = yup.object().shape({
  provider_name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(3, INVALID_PROVIDER_NAME)
    .matches(ALPHANUMERIC_REGEX, INVALID_PROVIDER_NAME),
  identifiers: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(URL_REGEX, INVALID_IDENTIFIER),
  idp_metadata: yup.string().required(REQUIRED_MESSAGE),
  metadata_url: yup.string().matches(URL_REGEX, {
    message: INVALID_METADATA_URL,
    excludeEmptyString: true,
  }),
  s3_url: yup.string(),
  attributes: yup.array().of(
    yup.object().shape({
      attribute_url: yup.string().required(REQUIRED_MESSAGE),
      attribute_type: yup.string().required(REQUIRED_MESSAGE),
    })
  ),
});
