import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dynamicform from "../../dynamicform";
import { AddCountFormType } from "./addcountform.types";

import { addCountForm } from "./addcountform.config";
import { addCountSchema } from "../../../../utils/schemas";

import { GetAddRuleFormContentProps } from "../../addruleform.types";

import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";

const AddCountForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    columnsData,
    sourceId,
    tableId,
    onAddRule,
    ruleCategoryId,
    formId,
    isEdit = false,
    ruleConfig,
    propsOnCancel,
    onUpdateRule,
    isPageLoading,
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const methods = useForm<AddCountFormType>({
    defaultValues: {
      id: "count",
      rule_type: "Count",
      rule_description: ruleConfig?.ruleDesc,
      is_filter_criteria_active: isEdit,
      filter_criteria: [],
    },
    resolver: yupResolver(addCountSchema),
    mode: "onChange",
  });

  const onSubmit = useCallback(
    (values: AddCountFormType) => {
      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const ruleDesc = values?.rule_description;

      const dataToSubmit = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: ruleDesc,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        filter: filterCondition,
        rule_def_config: { rule_type: formId },
      };

      const modifyRulePayload = {
        rule_type: formId,
        rule_desc: ruleDesc,
        filter_cond: filterCondition,
      };

      // REVIEW THIS

      isEdit ? onUpdateRule?.(modifyRulePayload) : onAddRule(dataToSubmit);
    },
    [tableId, sourceId, columnsData, onAddRule]
  );

  return (
    <FormProvider {...methods}>
      <Dynamicform
        schema={addCountForm(isEdit, initialFilters)}
        onSubmit={onSubmit}
        columnsData={columnsData}
        isEdit={isEdit}
        propsOnCancel={propsOnCancel}
        isPageLoading={isPageLoading}
      />
    </FormProvider>
  );
};

export default AddCountForm;
