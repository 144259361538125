import * as yup from "yup";

import {
  CHARACTERS_LIMIT_EXCEEDED,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";

export const addContextsSchema = yup.object().shape({
  context: yup
    .string()
    ?.trim()
    .required(REQUIRED_MESSAGE)
    .max(1000, CHARACTERS_LIMIT_EXCEEDED(1000)),
});
