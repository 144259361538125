// <--- Components Start --->
import Flex from "../../../flex";
// <--- Components End --->

// <--- Constants Start --->
import { POPULARITY_SCORE_CRITERIA_TOOLTIP } from "./popularityscorecriteria.constants";
// <--- Constants End --->

// <--- Styles Start --->
import { PopularityScoreCriteriaStyled } from "./popularityscorecriteria.styles";
// <--- Styles End --->

const PopularityScoreCriteria = (): JSX.Element => {
  return (
    <PopularityScoreCriteriaStyled
      rowGap={10}
      direction="column"
      alignItems="flex-start"
    >
      {POPULARITY_SCORE_CRITERIA_TOOLTIP?.map((criteria) => (
        <Flex
          customClass="criteria-item"
          alignItems="flex-start"
          columnGap={5}
          key={criteria?.title}
        >
          <div className="criteria-point" />

          <Flex
            customClass="criteria-item-body"
            direction="column"
            alignItems="flex-start"
          >
            <div className="criteria-title">{criteria?.title}:</div>
            <div className="criteria-description">{criteria?.description}</div>
          </Flex>
        </Flex>
      ))}
    </PopularityScoreCriteriaStyled>
  );
};

export default PopularityScoreCriteria;
