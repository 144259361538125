import styled from "styled-components";

export const DeleteGlossaryDomainFormStyled = styled.div`
  .ant-form {
    padding-top: 22px;

    .content-sec {
      .static-text {
        color: ${({ theme }): string => theme.textColors.BLACK_17};
        margin-bottom: 18px;
        margin-left: 110.5px;
        width: 400px;
        .bold {
          font-family: OpenSansSemiBold;
        }
      }

      .ant-form-item {
        margin-bottom: 53px;
      }
    }
  }
`;
