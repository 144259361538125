import * as yup from "yup";

import { CHARACTERS_LIMIT_EXCEEDED } from "../../constants/formconstants";

export const messageInputFieldFormSchema = yup.object().shape({
  text: yup
    .string()
    ?.trim()
    ?.required("")
    ?.max(2000, CHARACTERS_LIMIT_EXCEEDED(2000)),
});
