import { Radio } from "antd";
import { RadioGroupStyled } from "./radiobuttons.styles";
import { RadioFieldType } from "./radiobuttons.types";

function RadioGroupButtons({
  width = "19px",
  height = "19px",
  direction = "column",
  options,
  ...props
}: RadioFieldType): JSX.Element {
  return (
    <RadioGroupStyled
      {...props}
      width={width}
      height={height}
      direction={direction}
    >
      {options.map((option) =>
        option?.label ? (
          <Radio value={option.value} key={option.label}>
            {option.label}
          </Radio>
        ) : (
          <Radio value={option.value} key={option.label} />
        )
      )}
    </RadioGroupStyled>
  );
}

export default RadioGroupButtons;
