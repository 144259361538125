import styled from "styled-components";

export const SourceSchemaOptionStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  .svg {
    color: ${({ theme }): string => theme.textColors.GREY_18};
    font-size: 12px;
  }
`;
