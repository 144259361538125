import * as R from "ramda";

export const uniqByKey = <T extends Record<string, unknown>>(
  array: T[],
  key: keyof T
): T[] => {
  try {
    return R.uniqBy(R.prop(key as string), array);
  } catch {
    return [];
  }
};
