import { getEnvVariables } from "../../utils/envvariables";

const {
  REACT_APP_AWS_APP_REGION,
  REACT_APP_REDIRECT_SIGN_IN,
  REACT_APP_REDIRECT_SIGN_OUT,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_USER_POOL_ID,
} = getEnvVariables();

export const awsConfig = {
  Auth: {
    region: REACT_APP_AWS_APP_REGION,
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    redirectSignIn: REACT_APP_REDIRECT_SIGN_IN,
    redirectSignOut: REACT_APP_REDIRECT_SIGN_OUT,
  },
  API: {
    endpoints: [
      {
        name: "auth-token",
        endpoint: "local-dvsum.auth.us-west-2.amazoncognito.com",
      },
    ],
  },
};
