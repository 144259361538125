import { useCancelModal, useGetAppState } from "../../../../customhooks";

import { TrainBotFeedbackForm } from "../../../../pages/analysisdetailpage/analysisdetailpage.views";
import { TrainBotFeedbackFormProps } from "../../../../pages/analysisdetailpage/analysisdetailpage.views/trainbotfeedbackform";

import { TrainBotFeedbackModalStyled } from "./trainbotfeedbackmodal.styles";

const TrainBotFeedbackModal = (): JSX.Element => {
  const onCancelModal = useCancelModal();

  const { modal } = useGetAppState();
  const props = (modal?.modalProps || {}) as TrainBotFeedbackFormProps;

  return (
    <TrainBotFeedbackModalStyled>
      <TrainBotFeedbackForm {...props} onCrossClick={onCancelModal} />
    </TrainBotFeedbackModalStyled>
  );
};

export default TrainBotFeedbackModal;
