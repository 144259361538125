import styled from "styled-components";
import { hexToRgbA } from "../../utils";

export const JobRefDrawerStyled = styled.div`
  .job-ref-section {
    display: grid;
    grid-template-columns: 250px 1fr;
    width: 100%;
  }

  .job-ref-header {
    padding: 32px 44px 22px 31px;

    .header_row_2 {
      .letter-icon-mapping {
        top: 44%;
      }

      .job-redirect-icon {
        svg {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
`;

export const JobRefSectionStyled = styled.div`
  display: flex;

  flex-direction: column;
  padding: 15px 44px 15px 31px;
  border-top: 1px solid
    ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};
  border-bottom: 1px solid
    ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};
`;

export const MetaDataInfoStyled = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  padding-bottom: 12.8px;

  .key {
    color: ${({ theme }): string => theme.textColors.GREY_24};
  }

  .value {
    display: flex;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    max-width: max-content;
  }
`;

export const RuleNamesWrapperStyled = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const DescriptionRendererStyled = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const MultipleRulesRendererStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
`;

export const InternalRecipientsRendererStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;

  .value {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  svg {
    path {
      fill: ${({ theme }): string => theme.textColors.GREY_COLOR_12};
    }
  }
`;
