import { useEffect } from "react";
import { useLocation } from "react-router";
import { APP_ROUTES } from "../constants";
import { useQueryParams } from "../customhooks";

const notApplicableRoutes = [
  APP_ROUTES.private_routes.analytics,
  APP_ROUTES.private_routes.enterprise_search,
  APP_ROUTES.private_routes.account_settings,
];

export default function ScrollToTop(): any {
  const { pathname } = useLocation();
  const params = useQueryParams();

  useEffect(() => {
    const isAllowed = notApplicableRoutes?.every(
      (item) => !pathname?.includes(item)
    );

    if (isAllowed) {
      window?.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [pathname, params?.get("column")]);

  return null;
}
