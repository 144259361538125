import styled from "styled-components";

import { ellipsis } from "../../../styles/app.styles";
import { TagListProps } from "./taglist.types";

export const TagListStyled = styled.div<{ type: TagListProps["type"] }>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 11px 0px;
  line-height: 16px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme, type }): string =>
      type === "primary"
        ? theme.bgColors.LIGHT_GREY_36
        : theme.bgColors.LIGHT_GREY_27};
    color: ${({ theme, type }): string =>
      type === "primary"
        ? theme.textColors.BLUE_105
        : theme.textColors.BLACK_COLOR};
    border: 1px solid
      ${({ theme, type }): string =>
        type === "primary"
          ? theme.borderColors.LIGHT_GREY_32
          : theme.borderColors.LIGHT_GREY_40};
    padding: 0px 8px;
    border-radius: 3px;
    height: 24px;
    font-size: 11.2px;

    &.tag-name {
      max-width: 140px;

      .tag-name-text {
        ${ellipsis};
      }
    }

    &.count {
      font-family: "OpenSansBold";
    }
  }
`;

export const TagsRemainingSecStyled = styled.div`
  max-height: 200px;
  overflow-y: auto;
  .remaining-tags-tag {
    ${ellipsis}
  }
`;
