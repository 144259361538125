// <--- Components Start --->
import { SkeltonButtonBlankSlate } from "../../components/skeltonblankslate";
// <--- Components End --->

// <--- Constants Start --->
import { ELEMENT_IDS } from "../../constants";
// <--- Constants End --->

// <--- Types Start --->
import { TablePageParamViewKeys, TablePageTabsType } from "./tablepage.types";
// <--- Types End --->

const {
  datdict_detpg_data_fulpag_tab: DATDICT_DETPG_DATA_FULPAG_TAB,
  datdict_detpg_info_fulpag_tab: DATDICT_DETPG_INFO_FULPAG_TAB,
  datdict_detpg_data_quality_fulpag_tab: DATDICT_DETPG_DATA_QUALITY_FULPAG_TAB,
  datdict_detpg_data_model_fulpag_tab: DATDICT_DETPG_DATA_MODEL_FULPAG_TAB,
  datdict_detpg_analysis_fulpag_tab: DATDICT_DETPG_ANALYSIS_FULPAG_TAB,
} = ELEMENT_IDS;

export const tableDetailPageTabsValues: {
  [key in TablePageParamViewKeys]: TablePageParamViewKeys;
} = {
  overview: "overview",
  data: "data",
  data_quality: "data_quality",
  data_model: "data_model",
};

export const tableDetailPageTabs = ({
  isLoading = false,
  isDqEnabled = false,
  isDmEnabled = false,
}: {
  isLoading: boolean;
  isDqEnabled: boolean;
  isDmEnabled: boolean;
}): TablePageTabsType[] => {
  const tabs: TablePageTabsType[] = [
    {
      key: tableDetailPageTabsValues.overview,
      value: "Overview",
      id: DATDICT_DETPG_INFO_FULPAG_TAB,
    },
    {
      key: tableDetailPageTabsValues.data,
      value: "Data",
      id: DATDICT_DETPG_DATA_FULPAG_TAB,
    },

    ...((isDmEnabled && [
      {
        key: tableDetailPageTabsValues.data_model,
        value: "Data Model",
        id: DATDICT_DETPG_DATA_MODEL_FULPAG_TAB,
      },
    ]) ||
      []),

    ...((isDqEnabled && [
      {
        key: tableDetailPageTabsValues.data_quality,
        value: "Data Quality",
        id: DATDICT_DETPG_DATA_QUALITY_FULPAG_TAB,
      },
    ]) ||
      []),
  ];

  if (isLoading) {
    tabs?.push({
      key: tableDetailPageTabsValues.data_quality,
      value: <SkeltonButtonBlankSlate width="100px" />,
      id: DATDICT_DETPG_ANALYSIS_FULPAG_TAB,
    });
  }

  return tabs;
};

export const CERTIFY_TAGSET = "Certification Tag";
