import { FieldValues, useForm, UseFormWatch } from "react-hook-form";
import { DynamicFormGroupProps } from "../../connectionsettingstabformgroup/connectionsettingstabformgroup.types";
import { ActionTypeProps } from "../connectionsettingstabform.types";
import {
  enableDisableFormElements,
  hideShowGroupOrField,
} from "../connectionsettingstabform.utils";

export function handleDynamicOnFieldChange(
  watch: UseFormWatch<FieldValues>,
  form: ReturnType<typeof useForm>,
  onFieldsChangeConfig?: ActionTypeProps["onFieldsChange"],
  setEnableDisableElements?: DynamicFormGroupProps["setEnableDisableElements"],
  setGroupVisibility?: DynamicFormGroupProps["setGroupVisibility"]
): any {
  if (onFieldsChangeConfig) {
    const {
      fields,
      enableDisableElements: enableDisableElementsConfigs,
      hideShowElements,
    } = onFieldsChangeConfig;

    return (
      fields &&
      watch &&
      watch((values: any, { name }) => {
        if (name) {
          if (fields.includes(name as string)) {
            const { _formState: formState } = form?.control;

            const dirtyFields = Object.keys(
              formState?.val?.dirtyFields || {}
            ).filter((key) => fields.includes(key));

            const allEmpty = fields?.every(
              (field) => values?.[`${field}`]?.length === 0
            );

            hideShowGroupOrField(
              hideShowElements,
              setGroupVisibility,
              !allEmpty && dirtyFields.length === 0
            );

            enableDisableFormElements(
              enableDisableElementsConfigs,
              setEnableDisableElements,
              !allEmpty && dirtyFields.length === 0
            );
            // enableDisableElements &&
            //   enableDisableElements((st) => ({
            //     ...st,
            //     [fieldName]: dirtyFields.length === 0 ? !disable : disable,
            //   }));
          }
        }
      })
    );
  }

  return undefined;
}
