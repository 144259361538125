// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localstorageconstants";
// <--- Constants End --->

// <--- Utils Start --->
import { getObjectKeys, jsonParse } from ".";
import { GlobalFiltersData } from "../parsers/globalfiltersparser/globalfiltersparser.types";
// <--- Utils End --->

export function getGlobalFiltersData(): GlobalFiltersData {
  const data =
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.global_filters) || "";
  const parsedData = jsonParse(data, true);
  const isViewKeysEmpty = !getObjectKeys(parsedData?.view_keys)?.length;

  return isViewKeysEmpty
    ? {
        ...parsedData,
        view_keys: {
          domain: {
            COL: "Data Domain",
            DSR: "Data Domain",
            TBL: "Data Domain",
            TRM: "Data Domain",
          },
          source: {
            COL: "Source",
            DSR: "Source",
            TBL: "Source",
            RLS: "Source",
          },
        },
      }
    : parsedData;
}
