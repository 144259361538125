import { LoaderStyled } from "./loader.styles";

const Loader = (): JSX.Element => {
  return (
    <LoaderStyled>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </LoaderStyled>
  );
};

export default Loader;
