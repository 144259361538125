import { createGlobalStyle } from "styled-components";

export const DvSumPopoverStyled = createGlobalStyle<{ showArrow: boolean }>`
  .dvsum-popover {
    .ant-popover-content {
      .ant-popover-arrow {
        ${(props): string =>
          props?.showArrow
            ? "width:13px; height:13px; display:block;"
            : "display: none;"}
        
        .ant-popover-arrow-content{
          ${(props): string =>
            props?.showArrow
              ? "width:13px; height:13px; display:block;"
              : "display: none;"}
        }
      }
      .ant-popover-inner {
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        .ant-popover-title {
          padding: 0px;
          border: none;
        }
        .ant-popover-inner-content {
          padding: 0px;
        }
      }
    }
  }
`;
