import { Popover } from "antd";
import { useState } from "react";
import { useGetAppState } from "../../customhooks";
import { plusIconInCircleOutlined, suggestionIcon, tickIcon } from "../../svgs";
import { getObjectKeys } from "../../utils";
import Linkbutton from "../linkbutton";
import { ListItemsViewStyled } from "./listitemsview.styles";
import { ListItemsViewProps } from "./listitemsview.types";
import LinkButton from "../linkbutton/linkbutton";
import { ELEMENT_IDS } from "../../constants";

const { allcat_lst_dflt_views_sec: ALLCAT_LST_DFLT_VIEWS_SEC } = ELEMENT_IDS;

function ListItemsView(props: ListItemsViewProps): JSX.Element {
  const {
    data,
    sectionTitle,
    onClick,
    selected,
    children,
    popoverProps,
    onClickCreateView,
    isShowIcon,
    isViewer = false,
    createViewElemId = "",
    nodeType,
  } = props;
  const [visible, setVisible] = useState<boolean>();

  const { isOnboardingMode } = useGetAppState();

  return (
    <ListItemsViewStyled>
      <Popover
        content={
          <div className="content">
            <div className="scroll-sec">
              {sectionTitle && (
                <div className="sec-title">
                  <div className="sec-title-inner">{sectionTitle}</div>
                </div>
              )}
              {getObjectKeys(data).map((sec, secIndex) => (
                <div
                  className="category-items-list"
                  key={sec}
                  id={secIndex === 0 ? ALLCAT_LST_DFLT_VIEWS_SEC : ""}
                >
                  <div className="category-title">{sec}</div>
                  {data[sec]?.map((item) => (
                    <LinkButton
                      tooltipProps={{
                        title: item?.desc,
                        placement: "right",
                      }}
                      className={
                        String(item?.id) === selected
                          ? "category-item selected"
                          : "category-item"
                      }
                      key={`${sec}-${item?.id}-${item?.value}`}
                      onClick={(): void => {
                        onClick && onClick(item);
                        setVisible(false);
                      }}
                    >
                      {String(item?.id) === selected && (
                        <span className="category-item-selected-icon">
                          {tickIcon("12.2", "10.2")}
                        </span>
                      )}
                      {isShowIcon && (item?.icon || suggestionIcon("10", "14"))}
                      <span title={item?.value}>{item?.value}</span>
                    </LinkButton>
                  ))}
                </div>
              ))}
            </div>
            {onClickCreateView && (
              <div className="footer">
                <Linkbutton
                  onClick={(): void => {
                    onClickCreateView();
                    setVisible(false);
                  }}
                  disabled={isViewer}
                  elementId={createViewElemId}
                >
                  {plusIconInCircleOutlined("14px", "14px")}
                  {nodeType === "DSH" ? (
                    <div>Create Dashboard</div>
                  ) : (
                    <div>Create View</div>
                  )}
                </Linkbutton>
              </div>
            )}
          </div>
        }
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
        overlayClassName="list-view-popover"
        trigger={["click"]}
        placement="bottomLeft"
        onVisibleChange={(visible): void => {
          setVisible(isOnboardingMode ? true : visible);
        }}
        visible={visible}
        zIndex={1}
        {...popoverProps}
      >
        {children}
      </Popover>
    </ListItemsViewStyled>
  );
}

export default ListItemsView;
