import { FilterData } from "./exclusioncriteriasection.types";

export const FILTERS_OBJECT: FilterData = {
  BLK: "Is Empty",
  NBL: "Not Empty",
  EQL: "Equals",
  NEQ: "Does not equal",
  LTH: "Less Than",
  LTE: "Less Than Equal To",
  GTH: "Greater Than",
  GTE: "Greater Than Equal To",
  BTW: "In Between",
  NBT: "Not Between",
  WCM: "Wildcard Match",
  INC: "Includes",
  NIN: "Excludes",
};
