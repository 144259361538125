import { getDvSumInformativeText } from "../../../../utils";
import {
  FieldBase,
  SchemaType,
  SectionField,
} from "../../dynamicform/dynamicform.types";

export const addCustomQueryForm = (
  isEdit?: boolean,
  showStoredProc?: boolean
): SchemaType => {
  const {
    dat_dict_dat_qual_add_new_rle_fnd_cus_qry: {
      description: datDictDatQualAddNewRleFndCusQry = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_fnd_cus_qry_str_proc: {
      description: datDictDatQualAddNewRleFndStrProc = "",
    } = {},
  } = getDvSumInformativeText();
  return [
    ...((!isEdit && [
      {
        id: "basic",
        type: "section",
        fields: [
          {
            id: "rule_type",
            type: "select",
            name: "rule_type",
            label: "Rule Type",
            required: true,
            disabled: true,
          },
          {
            id: "rule_description",
            type: "text_area",
            name: "rule_description",
            label: "Rule Description",
            placeholder: "Description",
            description: "",
          },
        ],
      } as SectionField,
    ]) ||
      []),

    {
      id: "basic_input",
      type: "section",
      title: "Basic Input",
      fields: [
        ...((showStoredProc && [
          {
            id: "stored_procedure",
            type: "checkbox",
            name: "stored_procedure",
            label: "Stored Procedure",
            description: datDictDatQualAddNewRleFndStrProc,
          } as FieldBase,
        ]) ||
          []),

        {
          id: "custom_query",
          required: true,
          type: "text_area",
          name: "custom_query",
          label: "Custom Query",
          placeholder: "Custom Query",
          width: "64%",
          className: "custom-query-rule-text-area",
          // description: datDictDatQualAddNewRleFndCusQry,
        },
      ],
    },
  ];
};
