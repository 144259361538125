import styled from "styled-components";

export const TrainBotFeedbackFormStyled = styled.div<{
  isReasonSelected: boolean;
  isChatPage: boolean;
  isOtherReasonOnBot: boolean;
}>`
  position: relative;
  padding-top: ${({ isChatPage }): string => (isChatPage ? "" : "25.6px")};

  .train-bot-feedback-close {
    position: absolute;
    top: 29px;
    right: 25.6px;
  }

  .train-bot-feedback-form-body-wrapper {
    padding: 20.6px 25.6px;
    height: ${({ isChatPage }): string =>
      isChatPage ? "" : "calc(100vh - 75px)"};
    overflow-y: auto;
    display: flex;
    align-items: ${({ isReasonSelected }): string =>
      isReasonSelected ? "start" : "center"};

    .train-bot-feedback-form-body {
      width: ${({ isChatPage, isOtherReasonOnBot }): string =>
        !isChatPage && isOtherReasonOnBot ? "55%" : "100%"};
      margin: 0 auto;
      transition: height 1s ease-in-out;
    }
  }

  .ant-form {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item.caddi-form-actions-sec {
      margin-bottom: 0;

      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: end;
          padding: 0 25.6px 20.6px 7px;
        }
      }
    }
  }
`;
