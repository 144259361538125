import { createContext, useCallback, useContext, useReducer } from "react";
import { WIDGET_CREATION_INITIAL_STATE } from "./widgetcreationcontext.config";

import {
  updateCurrentViewIdType,
  updateSelectedGovernanceCategoryType,
  updateSelectedWidgetType,
  updateWidgetCreationStateType,
  updateWidgetCreationType,
  WidgetCreationActionsType,
  WidgetCreationContextType,
  WidgetCreationPageContextType,
} from "./widgetcreationcontext.types";

const initialState = WIDGET_CREATION_INITIAL_STATE;

const reducer = (
  state: WidgetCreationContextType,
  action: WidgetCreationActionsType
): WidgetCreationContextType => {
  switch (action?.type) {
    case "UPDATE_STATE":
      return {
        ...state,
        ...action?.updatedState,
      };
    case "UPDATE_CURRENT_VIEW_ID":
      return {
        ...state,
        currentViewId: action?.updatedCurrentViewId,
      };
    case "UPDATE_WIDGET_CREATION_TYPE":
      return {
        ...state,
        widgetCreationType: action?.updatedWidgetCreationType,
      };

    case "UPDATE_SELECTED_GOVERNANCE_CATEGORY":
      return {
        ...state,
        selectedGovernanceCategory: action?.updatedSelectedGovernanceCategory,
      };

    case "UPDATE_SELECTED_WIDGET":
      return {
        ...state,
        selectedWidget: action?.updatedSelectedWidget,
      };
    default:
      return state;
  }
};

const WidgetCreationPageContext = createContext({
  state: initialState,
  dispatch: (_: WidgetCreationActionsType) => {},
});

const WidgetCreationPageProvider: React.FC = ({ children }) => {
  // Use useReducer instead of useState
  const [state, dispatch] = useReducer(reducer, initialState);

  // Define your callback functions using dispatch
  const updateState: updateWidgetCreationStateType = useCallback(
    (updatedState) => {
      dispatch({ type: "UPDATE_STATE", updatedState });
    },
    [dispatch]
  );

  const updateCurrentViewId: updateCurrentViewIdType = useCallback(
    (updatedCurrentViewId) => {
      dispatch({ type: "UPDATE_CURRENT_VIEW_ID", updatedCurrentViewId });
    },
    [dispatch]
  );

  const updateWidgetCreationType: updateWidgetCreationType = useCallback(
    (updatedWidgetCreationType) => {
      dispatch({
        type: "UPDATE_WIDGET_CREATION_TYPE",
        updatedWidgetCreationType,
      });
    },
    [dispatch]
  );

  const updateSelectedGovernanceCategory: updateSelectedGovernanceCategoryType = useCallback(
    (updatedSelectedGovernanceCategory) => {
      dispatch({
        type: "UPDATE_SELECTED_GOVERNANCE_CATEGORY",
        updatedSelectedGovernanceCategory,
      });
    },
    [dispatch]
  );

  const updateSelectedWidget: updateSelectedWidgetType = useCallback(
    (updatedSelectedWidget) => {
      dispatch({ type: "UPDATE_SELECTED_WIDGET", updatedSelectedWidget });
    },
    [dispatch]
  );

  // Provide state and dispatch value to the context
  const contextValue: WidgetCreationPageContextType = {
    state,
    updateState,
    dispatch,
  };

  return (
    <WidgetCreationPageContext.Provider value={contextValue}>
      {children}
    </WidgetCreationPageContext.Provider>
  );
};

const useWidgetCreationPageContext = (): WidgetCreationPageContextType => {
  const context = useContext(WidgetCreationPageContext);

  if (!context) {
    console.log("WidgetCreationPageContext is not present");
  }

  return context as WidgetCreationPageContextType;
};

export {
  WidgetCreationPageProvider,
  WidgetCreationPageContext,
  useWidgetCreationPageContext,
};
