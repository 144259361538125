// <--- Library Imports Start --->
import { useMemo } from "react";
import { Tooltip } from "antd";
// <--- Library Imports End --->

// <--- Components Start --->
import Flex from "../flex";
import { PopularityScoreCriteria } from "./popularityscoredetailcard.components";
// <--- Components End --->

// <--- Constants Start --->
import { NEVER_USED_TEXT } from "./popularityscoredetailcard.constants";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
// <--- Constants End --->

// <--- Styles Start --->
import { PopularityScoreDetailCardStyled } from "./popularityscoredetailcard.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { twoStarsIcon, userIcon } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { PopularityScoreDetailCardProps } from "./popularityscoredetailcard.types";
// <--- Types End --->

const PopularityScoreDetailCard = (
  props: PopularityScoreDetailCardProps
): JSX.Element => {
  const {
    title = "",
    used: { usageCount = 0, usedByCount = 0, usedInCount = "" } = {},
    topUsers = [],
    isNeverUsed = false,
  } = props;

  const renderUsers = useMemo(() => {
    return topUsers?.map((user, index) => (
      <Flex columnGap={7.2} customClass="user-row" key={`${user}-${index}`}>
        <span className="user-icon">{userIcon}</span>
        {user}
      </Flex>
    ));
  }, [topUsers]);

  return (
    <PopularityScoreDetailCardStyled>
      <Flex direction="column" rowGap={12.8} alignItems="flex-start">
        <Flex customClass="title-section" columnGap={12.7}>
          <Tooltip
            title={<PopularityScoreCriteria />}
            placement="bottomLeft"
            overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} dvsum-popularity-score-tooltip`}
            zIndex={1081}
          >
            {twoStarsIcon("19.2", "16")}
          </Tooltip>
          {title}
        </Flex>

        {!isNeverUsed ? (
          <div className="used-details-section">
            Used <span className="bold">{usageCount} times</span> in last{" "}
            {usedInCount} by {usedByCount} users
          </div>
        ) : (
          <div className="never-used-section">{NEVER_USED_TEXT}</div>
        )}
      </Flex>

      {!isNeverUsed ? (
        <Flex
          customClass="top-users-section"
          direction="column"
          alignItems="flex-start"
          columnGap={8}
        >
          <div className="heading">Top Users</div>

          <Flex
            direction="column"
            alignItems="flex-start"
            columnGap={6.4}
            customClass="top-users-list"
          >
            {renderUsers}
          </Flex>
        </Flex>
      ) : (
        <div />
      )}
    </PopularityScoreDetailCardStyled>
  );
};

export default PopularityScoreDetailCard;
