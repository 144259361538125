import { getTimeDifference } from "./gettimedifference";
import { utcTOLocalTimeZone } from "./utctolocaltimezone";

export const getTimeDifferenceInLocalTimeZone = (time: string): string => {
  try {
    return getTimeDifference(utcTOLocalTimeZone(time || ""));
  } catch {
    return "";
  }
};
