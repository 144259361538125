import styled from "styled-components";
import { FlexStyled } from "../../../../components/flex";

export const AddViewActionButtonsStyled = styled(FlexStyled)`
  padding: 19px 45px 19px 23px;
  border-bottom: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

  .clone-button,
  .delete-button {
    display: flex;
    gap: 7px;
    align-items: center;
    font-size: 14px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .clone-button {
    color: ${({ theme }): string => theme.textColors.GREY_24};

    svg {
      path {
        stroke: ${({ theme }): string => theme.borderColors.GREY_24};
      }
    }
  }

  .delete-button {
    color: ${({ theme }): string => theme.textColors.RED_100};

    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.RED_100};
      }
    }
  }

  .dvsum-tooltip {
    max-width: 320px;

    .ant-tooltip-inner {
      max-width: 320px !important;
    }
  }
`;

export const ScreenOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: ${({ theme }): string => theme?.bgColors?.BLACK_COLOR};
  opacity: 0.4;
`;