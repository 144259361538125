import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { appendQueryParamInUrl, useRequestWithMethod } from "../../api";
import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import StateHandler from "../../components/statehandler/statehandler";
import { API_CONFIG } from "../../constants/apiconfig";
import { useGetAppState } from "../../customhooks";
import { useCancelModal } from "../../customhooks/useCancelModal";
import { solidSuccessTickIcon } from "../../svgs";
import { getFiltersfromLocalStorage, openNotification } from "../../utils";
import { DiscardTableDetailChangesStyled } from "./discardtabledetailchanges.styles";

function DiscardTableDetailChanges(): JSX.Element {
  const queryClient = useQueryClient();
  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const { onDiscard, isSaved, nodeId, nodeType } = modalProps;

  const onCancel = useCancelModal();

  const parsedNodeFilter = useMemo(() => {
    return getFiltersfromLocalStorage(nodeType);
  }, [nodeType]);

  const onSuccess = useCallback(
    (response) => {
      onDiscard && onDiscard();

      const api = appendQueryParamInUrl(API_CONFIG.get_node.url, [
        nodeId,
        nodeType,
        "PUB",
        "",
        "",
        parsedNodeFilter,
      ]);

      queryClient.setQueriesData(api, response);
      onCancel();
      openNotification(
        <span>
          <span className="action-text">{solidSuccessTickIcon} Success!</span>
          Your changes have been discarded
        </span>
      );
    },
    [parsedNodeFilter]
  );

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "update_node",
    [nodeId, nodeType, "PUB", "", "", parsedNodeFilter],
    true,
    onSuccess
  );

  const onDiscardClick = useCallback(() => {
    if (isSaved) {
      onExecuteRequest({ status: "DIS" });
    } else {
      onDiscard && onDiscard();
      onCancel();
    }
  }, [modalProps]);

  return (
    <StateHandler isModal isFetching={isLoading} error={error}>
      <DiscardTableDetailChangesStyled>
        <div className="discard-info-text">
          Are you sure you want to discard these changes. The changes you made
          will not be reflected on the {nodeType === "TBL" ? "dataset" : "term"}
          .
        </div>
        <FormStyled>
          <FormItemStyled label="" className="form-actions-sec">
            <Button id="cancel" width="82px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="93px"
              marginLeft="8px"
              onClick={onDiscardClick}
            >
              Discard
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DiscardTableDetailChangesStyled>
    </StateHandler>
  );
}

export default DiscardTableDetailChanges;
