import { useEffect, useState } from "react";

export function on(
  obj: typeof window,
  ...args: [string, () => void, any]
): void {
  obj.addEventListener(...args);
}

export function off(
  obj: typeof window,
  ...args: [string, () => void, any]
): void {
  obj.removeEventListener(...args);
}

export const useScrollingUp = (id: string): boolean => {
  const [scrollingUp, setScrollingUp] = useState(false);
  const handleScroll = (): void => {
    const myHeader = document.getElementById(id);
    const headerOffset = myHeader?.offsetTop ?? 0;
    const currScroll = window.pageYOffset;
    const isScrolled = !!(currScroll > headerOffset && myHeader);
    setScrollingUp(isScrolled);
  };

  useEffect(() => {
    on(window, "scroll", handleScroll, { passive: true });
    return (): void => {
      off(window, "scroll", handleScroll, { passive: true });
      window.onbeforeunload = (): void => {
        off(window, "scroll", handleScroll, { passive: true });
      };
    };
  }, []);

  return scrollingUp;
};

export default useScrollingUp;
