import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const DateFormatInputStyled = styled.div<{ width?: string }>`
  display: flex;
  width: 100%;

  .select-field-container,
  .ant-select {
    width: ${({ width }): string => width || "100%"};
    .date-format-select {
      .ant-select-selector {
        height: 40px;

        .ant-select-selection-item {
          display: block;
        }
      }
    }
  }

  .date-format-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
    height: 100%;

    .date-format-label {
      font-size: 13.6px;
      color: ${({ theme }): string => theme?.textColors?.GREY_40};
    }

    .date-format-example {
      font-size: 13.6px;
      color: ${({ theme }): string => theme?.textColors?.GREY_210};
      ${ellipsis}
    }
  }
`;
