import { getObjectKeys } from "../../../../../../utils";

export const validateWorkflowJson = (workFlowJson: any): boolean => {
  try {
    const states = getObjectKeys(workFlowJson?.state_machine?.states);

    const startStateShouldBeDefined = !!workFlowJson?.state_machine
      ?.start_state;
    const statesShouldBeDefined = !!states?.length;

    const eachStateShouldHaveAnAction = states?.every((state) => {
      return !!workFlowJson?.state_machine?.states[state]?.actions?.length;
    });

    const shouldContsainSourceConfig = !!workFlowJson?.source_configs;

    return (
      startStateShouldBeDefined &&
      statesShouldBeDefined &&
      eachStateShouldHaveAnAction &&
      shouldContsainSourceConfig
    );
  } catch {
    return false;
  }
};
