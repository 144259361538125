import styled from "styled-components";

export const ResendInviteUserModalStyled = styled.div`
  padding-top: 20.8px;

  .content-sec {
    .static-text {
      color: ${({ theme }): string => theme.textColors.LIGHT_GREY_11};
      margin-bottom: 20px;
      padding-left: 20.8px;
      padding-right: 20.8px;

      .bold {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        font-family: OpenSansSemiBold;
        margin-top: 14px;
      }

      .note {
        margin-top: 10px;

        div {
          gap: 6px;

          .iwt-icon {
            svg {
              height: 11px;

              path {
                fill: ${({ theme }): string => theme.textColors.GREY_COLOR_12};
              }
            }
          }

          .iwt-icon-text {
            color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
            font-style: italic;
            font-size: 13px;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .modal-actions-sec {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    background-color: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
    border-top: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
    padding: 8px 18px 8px 18px;
  }
`;
