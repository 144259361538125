import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setDrawer } from "../reducers/appreducer/appreducer";

import { AppState } from "../reducers/appreducer/appreducer.types";

export function useOpenDrawer(): (_drawer: AppState["drawer"]) => void {
  const dispatch = useDispatch();

  return useCallback((drawer: AppState["drawer"]) => {
    dispatch(setDrawer(drawer));
  }, []);
}
