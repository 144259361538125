import { useCallback } from "react";
import { useController } from "react-hook-form";
import { CheckboxGroupFieldStyled } from "./checkboxgroupfield.styles";
import { CheckboxGroupFieldProps } from "./checkboxgroupfield.types";

const CheckboxGroupField = ({
  name = "",
  control,
  ...props
}: CheckboxGroupFieldProps): JSX.Element => {
  const {
    field: { ref, ...checkboxProps },
  } = useController({ name, control });

  const handleChange = useCallback(
    (value) => {
      checkboxProps.onChange(value);
    },
    [name, checkboxProps]
  );

  return (
    <CheckboxGroupFieldStyled
      {...props}
      {...checkboxProps}
      ref={ref}
      onChange={handleChange}
    />
  );
};

export default CheckboxGroupField;
