import { useCallback, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import FilterCriteria from "../filtercriteria/filtercriteria";

import {
  FilterConditionExpandedStyled,
  FilterConditionWrapperStyled,
} from "./filterconditionexpanded.styles";

import { FilterConditionExpandedProps } from "./filterconditionexpanded.types";

const FilterConditionExpanded = (
  props: FilterConditionExpandedProps
): JSX.Element => {
  const isRendered = useRef<boolean>(false);

  const {
    isEditMode,
    parsedFields,
    parsedFilters,
    existingFilters,
    nameOfFilterComp = "filter_criteria",
  } = props;

  const { trigger, watch } = useFormContext();

  const {
    filter_criteria: filterCriteria,
    reference_filter_criteria: referenceFilterCriteria,
  } = watch();

  useEffect(() => {
    trigger("filter_criteria");
    trigger("reference_filter_criteria");
  }, [filterCriteria, referenceFilterCriteria]);

  const changeIsRender = useCallback(() => {
    isRendered.current = true;
  }, [isRendered]);

  return (
    <FilterConditionExpandedStyled>
      <FilterConditionWrapperStyled>
        <FilterCriteria
          name={nameOfFilterComp}
          parsedFields={parsedFields}
          parsedFilters={parsedFilters}
          isEditMode={isEditMode}
          existingFilters={existingFilters || []}
          nodeType="RLS"
        />
      </FilterConditionWrapperStyled>
    </FilterConditionExpandedStyled>
  );
};

export default FilterConditionExpanded;
