export function findMaxLengthString(list: string[]): string {
  try {
    return list?.reduce((maxString, currentString) => {
      return currentString.length > maxString.length
        ? currentString
        : maxString;
    }, "");
  } catch {
    return "";
  }
}
