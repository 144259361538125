//   <--Library Imports Start-->
import styled from "styled-components";
//   <--Library Imports End-->

export const DvSumFallBackSpinnerStyled = styled.div`
  width: 100%;
  height: calc(100vh - 45px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
