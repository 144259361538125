import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Dropdown } from "antd";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  DESC_MAX_RANGE_MSG,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";
import { chevronRight, lightCrossIcon, searchIcon, tickIcon } from "../../svgs";

import { TextAreaField } from "../formfields";
import { Input } from "../inputs";

import { InputStyled } from "../inputs/inputs.styles";
import LinkButton from "../linkbutton";

import {
  TagCategoryStyled,
  TagsDropdownItemStyled,
  TagsDropdownStyled,
  TagsEditorStyled,
  TagsVlaueStyled,
  TagValue,
} from "./tagseditor.styles";

import { TagItem, TagsEditorProps } from "./tagseditor.types";
import { getObjectKeys, getUserPermissions } from "../../utils";

import SectionBlankSlateText from "../sectionblankslatetext/sectionblankslatetext";
import { LISTING_PAGE_TAGSET_NAME } from "../../pages/listingpage/listingpage.constants";

const commentSecSchema = yup.object().shape({
  comment: yup.string().required(REQUIRED_MESSAGE).max(500, DESC_MAX_RANGE_MSG),
});

const SelectedCategoryTags = (props: {
  tags: TagItem[];
  onSelectTag: (_val: string, _isAdded: boolean) => void;
}): JSX.Element => {
  const { tags, onSelectTag } = props;

  return (
    <>
      {tags?.map((item) => (
        <TagsVlaueStyled
          isSelected={item?.selected || item?.isAdded}
          onClick={(): void => onSelectTag(item?.value, !!item?.isAdded)}
          key={`tags-tag-${item?.value}`}
        >
          <div className="tag-value" title={item?.label}>
            {item?.label}
          </div>
          {item?.isAdded && tickIcon()}
        </TagsVlaueStyled>
      ))}
    </>
  );
};

function TagsEditor(props: TagsEditorProps): JSX.Element {
  const { is_data_security_admin: isDataSecurityAdmin } = getUserPermissions();

  const {
    tagsDataState: state,
    setTagsDataState: setState,
    appliedTags = {},
    setShowSecondScreen,
    showSecondScreen,
  } = props;

  const [cmntVal, setCmntVal] = useState("");
  const [tagsDropdownVisibility, setTagsDropdownVisibility] = useState(false);

  const {
    control,
    getValues,
    setValue,
    setError,
    formState: { isValid },
  } = useForm<{ comment: string }>({
    defaultValues: { comment: "" },
    mode: "onChange",
    resolver: yupResolver(commentSecSchema),
  });

  const tagsKeys = Object.keys(state?.data || {});

  const isCustomTags = state?.data[state?.selectedCategory]?.isNewValueAllowed;

  const tagsetWithPermissionName =
    getObjectKeys(state?.data)?.find(
      (key) => state?.data?.[key]?.tagsetType === "CLS"
    ) || "";

  const filteredAppliedTags = {
    ...appliedTags,
    ...(!isDataSecurityAdmin && { [tagsetWithPermissionName]: undefined }),
  };

  const onChangeCategory = useCallback(
    (val) => {
      setState((st) => {
        const updCatTagsData = st?.data?.[val]?.tagsData || [];

        return {
          ...st,
          selectedCategory: val,
          data: {
            ...st?.data,
            [val]: {
              ...st?.data?.[val],
              tagsData: [
                ...updCatTagsData?.filter((tag) => tag?.isAdded),
                ...updCatTagsData?.filter((tag) => !tag?.isAdded),
              ],
            },
          },
        };
      });
    },
    [state]
  );

  const isTagAlreadyExist = useCallback(
    (category: string) => {
      const allValues = state?.data?.[category]?.tagsData?.map(
        (item) => item?.label
      );
      return allValues?.includes(state?.data?.[category]?.inputTextVal || "");
    },
    [state]
  );

  const isTagAlreadyExistsInSelectedCategory = isTagAlreadyExist(
    `${state?.selectedCategory}`
  );

  const onEnterSearch = useCallback(
    (onEnter?: boolean) => {
      if (state?.data?.[state?.selectedCategory]?.isNoteRequired && !cmntVal) {
        setShowSecondScreen(true);
      } else {
        if (onEnter && !isTagAlreadyExistsInSelectedCategory) {
          if (!isTagAlreadyExistsInSelectedCategory) {
            setState((st) => {
              const isMultipleAllowed =
                state?.data[st?.selectedCategory]?.isMultiple;
              return {
                ...st,
                customTagsInputText: "",
                data: {
                  ...st.data,
                  [st?.selectedCategory]: {
                    ...st?.data?.[st?.selectedCategory],
                    inputTextVal: "",
                    tagsData: [
                      {
                        label:
                          st?.data?.[st?.selectedCategory]?.inputTextVal || "",
                        value:
                          st?.data?.[st?.selectedCategory]?.inputTextVal || "",
                        selected: isMultipleAllowed,
                        isAdded: isMultipleAllowed,
                        isNewAdded: true,
                        comment: st?.data?.[st?.selectedCategory]
                          ?.isNoteRequired
                          ? cmntVal
                          : "",
                      },
                      ...st?.data?.[st?.selectedCategory]?.tagsData,
                    ],
                  },
                },
              };
            });
          }
        } else {
          setState((st) => ({
            ...st,
            customTagsInputText: "",
            data: tagsKeys?.reduce(
              (prev, next) => ({
                ...prev,
                [next]: {
                  ...st?.data[next],
                  inputTextVal: !isTagAlreadyExist(next)
                    ? ""
                    : st?.data[next]?.inputTextVal,
                  tagsData: [
                    ...(!isTagAlreadyExist(next) &&
                    st?.data?.[next]?.inputTextVal
                      ? [
                          {
                            label: st?.data?.[next]?.inputTextVal || "",
                            value: st?.data?.[next]?.inputTextVal || "",
                            selected: st?.data?.[next]?.isMultiple,
                            isAdded: st?.data?.[next]?.isMultiple,
                            isNewAdded: true,
                            comment: st?.data?.[next]?.isNoteRequired
                              ? cmntVal
                              : "",
                          },
                        ]
                      : []),
                    ...st?.data?.[next]?.tagsData,
                  ],
                },
              }),
              {}
            ),
          }));
        }

        setShowSecondScreen(false);
        setCmntVal("");
      }
    },
    [state, cmntVal, showSecondScreen, isTagAlreadyExistsInSelectedCategory]
  );

  const onApply = useCallback(
    (isAdded: boolean, tagset?: string) => {
      onEnterSearch();
      if (
        state?.data?.[tagset || state?.selectedCategory]?.isNoteRequired &&
        !cmntVal &&
        !isAdded
      ) {
        setShowSecondScreen(true);
      } else {
        setState((st) => ({
          ...st,
          data: tagsKeys?.reduce(
            (prev, next) => ({
              ...prev,
              [next]: {
                ...st?.data[next],
                tagsData: st?.data[next]?.tagsData?.map((item) => ({
                  ...item,
                  isAdded: item?.selected,
                  comment:
                    item?.selected &&
                    !item?.isAdded &&
                    st?.data[next]?.isNoteRequired
                      ? cmntVal
                      : item?.comment,
                })),
              },
            }),
            {}
          ),
        }));
        setShowSecondScreen(false);
        setCmntVal("");
      }
    },
    [state, onEnterSearch, showSecondScreen, tagsKeys]
  );

  const onSelectTag = useCallback(
    (val, isAdded, tagset?: string) => {
      setState((st) => {
        const isMultiValAllowed = state?.data[st?.selectedCategory]?.isMultiple;
        return {
          ...st,
          data: {
            ...st?.data,
            [st?.selectedCategory]: {
              ...st?.data[st?.selectedCategory],
              tagsData: st?.data[st?.selectedCategory]?.tagsData
                ?.map((item) => ({
                  ...item,
                  selected: isMultiValAllowed
                    ? item?.value === val && !item?.isAdded
                      ? !item?.selected
                      : item?.selected
                    : item?.value === val,
                }))
                .filter(
                  (item) =>
                    !(
                      item?.value === val &&
                      item?.isNewAdded &&
                      !item?.selected
                    )
                ),
            },
          },
        };
      });
      onApply(isAdded, tagset);
    },
    [state, onApply]
  );

  const onRemoveTag = useCallback(
    (category, val, isNewAdded) => {
      setState((st) => ({
        ...st,
        data: {
          ...st?.data,
          [category]: isNewAdded
            ? {
                ...st?.data[category],
                tagsData: st?.data[category]?.tagsData?.filter(
                  (item) => item.value !== val
                ),
              }
            : {
                ...st?.data[category],
                tagsData: st?.data[category]?.tagsData?.map((item) => ({
                  ...item,
                  isAdded: item?.value === val ? false : item?.isAdded,
                  selected: item?.value === val ? false : item?.isAdded,
                })),
              },
        },
      }));
    },
    [state]
  );

  const onChangeSearchText = useCallback(
    (e) => {
      setTagsDropdownVisibility(true);
      setState((st) => ({ ...st, searchText: e?.target?.value || "" }));
    },
    [state]
  );

  const selectedCategoryTags = useMemo(() => {
    const selectedCatTagsData =
      state?.data?.[state?.selectedCategory]?.tagsData || [];
    return selectedCatTagsData;
  }, [state]);

  const tagsDropDownData = useMemo(() => {
    const tagsetNames = getObjectKeys(state?.data)?.filter((item) =>
      isDataSecurityAdmin ? true : item !== tagsetWithPermissionName
    );

    const combineTagsOfAllTagsets = tagsetNames?.map((tagsetName) => {
      return state?.data?.[tagsetName]?.tagsData?.map((tag) => ({
        tagsetName,
        tag: tag?.label,
        isAdded: tag?.isAdded,
        id: tag?.value,
      }));
    });

    const filteredTags = combineTagsOfAllTagsets?.flat()?.filter((item) => {
      const combineTagAndTagsetName = `${item?.tag} ${item?.tagsetName}`;
      return combineTagAndTagsetName
        ?.toString()
        ?.toLowerCase()
        ?.includes(state?.searchText?.toString()?.toLowerCase());
    });
    return filteredTags;
  }, [state, isDataSecurityAdmin, tagsetWithPermissionName]);

  const onChange = useCallback(
    (e) => {
      setState((st) => ({
        ...st,
        data: {
          ...st?.data,
          [st?.selectedCategory]: {
            ...st?.data?.[st?.selectedCategory],
            inputTextVal: e?.target?.value || "",
          },
        },
      }));
    },
    [state]
  );

  const onSelectTagFromDropDown = useCallback(
    (category: string, tagId: string, isAdded: boolean) => {
      setState((st) => ({ ...st, selectedCategory: category }));
      onSelectTag(tagId, isAdded, category);
      setTagsDropdownVisibility(false);
    },
    [onSelectTag]
  );

  const onCommentApply = useCallback(() => {
    setCmntVal(getValues("comment") || "");
    setValue("comment", "");
  }, [cmntVal]);

  const onCancelCommentScreen = useCallback(() => {
    setShowSecondScreen(false);
    setValue("comment", "");
    setError("comment", { message: "" });
  }, [showSecondScreen]);

  useEffect(() => {
    if (cmntVal) {
      onApply(true);
    }
  }, [cmntVal]);

  return (
    <TagsEditorStyled
      isNewAllowed={isCustomTags}
      isTagAlreadyExists={isTagAlreadyExistsInSelectedCategory}
    >
      {showSecondScreen ? (
        <div className="comment-screen-view">
          <div className="comment-screen-view-text">
            Applying this tag requires adding a note
          </div>
          <TextAreaField
            name="comment"
            control={control}
            height="118px"
            placeholder="Enter tag note here"
          />
          <div className="comment-screen-view-footer">
            <LinkButton
              fontFamily="OpenSansSemibold"
              fontSize="14px"
              id="secondary"
              onClick={onCancelCommentScreen}
              marginRight="12px"
            >
              Cancel
            </LinkButton>
            <LinkButton
              fontFamily="OpenSansSemibold"
              fontSize="14px"
              id="secondary"
              onClick={onCommentApply}
              disabled={!isValid}
            >
              Apply
            </LinkButton>
          </div>
        </div>
      ) : (
        <>
          <div className="selected-tags-sec">
            <div className="selected-tags-sec-heading">Existing tags</div>
            <div className="selected-tags">
              {getObjectKeys(filteredAppliedTags).map((item) =>
                filteredAppliedTags[item]?.tagsData?.map((tag) => (
                  <TagValue
                    key={`tag-editor-${item}-${tag?.value}`}
                    className="tag-rendrer"
                  >
                    <div className="content">
                      <div
                        className="tag-label"
                        title={`${item} > ${tag?.label || ""}`}
                      >
                        {item}
                        {chevronRight} {tag?.label}
                      </div>
                      <span
                        role="button"
                        onClick={(): void =>
                          onRemoveTag(item, tag?.value, tag?.isNewAdded)
                        }
                        className="remove-tag"
                      >
                        {lightCrossIcon("8", "8")}
                      </span>
                    </div>
                  </TagValue>
                ))
              )}
            </div>
          </div>
          <Dropdown
            overlay={
              <TagsDropdownStyled>
                <div className="tags-dropsown-scrollable-sec">
                  <SectionBlankSlateText
                    isEmpty={!tagsDropDownData?.length}
                    text="Your search produced no result."
                  >
                    <>
                      {tagsDropDownData?.map((item, index) => (
                        <TagsDropdownItemStyled
                          key={`${item?.tagsetName}-${item?.tag}-${index}`}
                          isAdded={!!item?.isAdded}
                          role="button"
                          onClick={(): void =>
                            onSelectTagFromDropDown(
                              item?.tagsetName,
                              item?.id,
                              !!item?.isAdded
                            )
                          }
                        >
                          {item?.tagsetName} {chevronRight} {item?.tag}
                        </TagsDropdownItemStyled>
                      ))}
                    </>
                  </SectionBlankSlateText>
                </div>
              </TagsDropdownStyled>
            }
            destroyPopupOnHide
            trigger={["click"]}
            onVisibleChange={setTagsDropdownVisibility}
            visible={tagsDropdownVisibility}
            getPopupContainer={(trigger): HTMLElement =>
              trigger.parentNode as HTMLElement
            }
          >
            <Input
              className="edit-tags-search"
              prefix={searchIcon("16px")}
              suffix={
                state?.searchText ? (
                  <LinkButton onClick={onChangeSearchText}>
                    {lightCrossIcon()}
                  </LinkButton>
                ) : (
                  <div />
                )
              }
              onChange={onChangeSearchText}
              value={state?.searchText}
              placeholder="Search Tags"
            />
          </Dropdown>
          <div className="tag-editor-body">
            <div className="tags-left-sec">
              {tagsKeys
                ?.filter((item) =>
                  isDataSecurityAdmin ? true : item !== tagsetWithPermissionName
                )
                ?.map((item) => (
                  <TagCategoryStyled
                    onClick={(): void => onChangeCategory(item)}
                    isSelected={state?.selectedCategory === item}
                    key={`tags-category-${item}`}
                  >
                    <div className="tag-category">{item}</div> {chevronRight}
                  </TagCategoryStyled>
                ))}
            </div>
            <div className="tags-right-sec">
              {selectedCategoryTags?.length ? (
                <div className="tags-scroll" key={`${state?.selectedCategory}`}>
                  <SelectedCategoryTags
                    tags={selectedCategoryTags}
                    onSelectTag={onSelectTag}
                  />
                </div>
              ) : !selectedCategoryTags?.length && state?.searchText ? (
                <div className="apply-tags">
                  Your search produced no results
                </div>
              ) : (
                <div className="apply-tags">Apply Tags</div>
              )}
              {isCustomTags && (
                <>
                  <InputStyled
                    placeholder="Add custom tags"
                    className="add-custom-tags-input"
                    onChange={onChange}
                    onPressEnter={(): void => {
                      !isTagAlreadyExistsInSelectedCategory &&
                        state?.data?.[
                          state?.selectedCategory
                        ]?.inputTextVal?.trim() !== "" &&
                        onEnterSearch(true);
                    }}
                    value={
                      state?.data?.[state?.selectedCategory]?.inputTextVal || ""
                    }
                  />
                  {isTagAlreadyExistsInSelectedCategory && (
                    <div className="already-exist-tag">
                      This tag already exists
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </TagsEditorStyled>
  );
}

export default TagsEditor;
