import styled from "styled-components";

export const AddSAWSFormStyled = styled.div`
  .ant-form {
    .form-content {
      padding: 40px 109px;

      .ant-form-item-control {
        width: 495px;

        .form-item-with-inline-info {
          .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            column-gap: 8px;
          }
        }
      }

      .switch-field {
        flex-direction: row;

        .ant-form-item-label {
          width: 165px !important;
          text-align: left;
        }
      }
    }
  }

  #communication-key-input {
    color: ${({ theme }): string => theme.textColors.LIGHT_GREY_45};
  }
`;
