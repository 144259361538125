import { SkeltonButtonBlankSlate } from "../../components/skeltonblankslate";
import { LinkTabsProps } from "../../components/tabs/linktabs/linktabs.types";
import { SourceDetailPageParamViewKeys } from "./sourcedetailpage.types";

export const datasourceConditionalTabsIds = ["settings", "files"];

export const sourceDetailPageTabsValues: {
  [key in SourceDetailPageParamViewKeys]: SourceDetailPageParamViewKeys;
} = {
  overview: "overview",
  settings: "settings",
  files: "files",
  scan_history: "scan_history",
  scan_logs: "scan_logs",
  "chat-bots": "chat-bots",
};

export const sourceDetailPageTabs = (
  isLoading?: boolean,
  isExlSource?: boolean,
  isRestDataSource?: boolean,
  isChatEnabled?: boolean,
  tab?: SourceDetailPageParamViewKeys,
  botsCount?: number
): LinkTabsProps["tabs"] => {
  const isExcelTab = tab === sourceDetailPageTabsValues?.files;
  const isChatBotsTab = tab === sourceDetailPageTabsValues?.["chat-bots"];
  const isSettingsTab = tab === sourceDetailPageTabsValues?.settings;

  const tabs: any = [
    {
      key: sourceDetailPageTabsValues.overview,
      value: "Overview",
      disabled: isRestDataSource,
    },
    {
      key: sourceDetailPageTabsValues.scan_history,
      value: "Scan History",
      disabled: isRestDataSource,
    },
    {
      key: sourceDetailPageTabsValues.scan_logs,
      value: "Logs",
      disabled: isRestDataSource,
    },
  ];

  if (!isLoading) {
    if (isExlSource) {
      tabs.push({ key: sourceDetailPageTabsValues.files, value: "Files" });
    }

    tabs.push({
      key: "settings",
      value: "Settings",
    });

    if (isChatEnabled) {
      tabs.push({
        key: sourceDetailPageTabsValues["chat-bots"],
        value: `Chatbots (${botsCount || 0})`,
      });
    }
  }

  if (isLoading) {
    tabs.push({
      key: "settings",
      value: isSettingsTab ? (
        "Settings"
      ) : isExcelTab ? (
        "Files"
      ) : (
        <SkeltonButtonBlankSlate active />
      ),
    });

    tabs.push({
      key: "chat-bots",
      value: isChatBotsTab ? "Chatbots" : <SkeltonButtonBlankSlate active />,
    });
  }
  return tabs;
};
