import { Button } from "antd";
import styled from "styled-components";

export const PrimaryTabsStyled = styled.div`
  height: 63.5px;
  background-color: ${({ theme }): string => theme.bgColors.BLUE_26};
`;

export const TabButton = styled(Button)<{ selected: boolean }>`
  &.ant-btn-link {
    position: relative;
    color: ${(props): string => props.theme.bgColors.WHITE_COLOR};
    font-size: 20px;
    height: 100%;
    font-weight: ${(props): string => (props.selected ? "bold" : "normal")};
    font-family: ${(props): string =>
      props.selected ? "OpenSansBold" : "OpenSans"};
    padding: 13px 28px 18px 29px;
    transition: unset;
    background: ${({ theme, selected }): string =>
      selected ? theme.bgColors.LIGHT_BLUE_13 : "transparent"};
    :not(:last-of-type) {
      margin-right: 22px;
    }
  }
`;
