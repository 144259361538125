import { Represents } from "../../../../components/represents/represents.types";
import { RuleDetailPageTabType } from "../../../../contexts/ruledetailpagecontext/ruledetailpagecontext.types";
import { ReturnTypeOfRefParser } from "../../../../parsers";
import { RuleDefinitionValueType } from "../../../../parsers/ruleparser/ruleparser.types";

import {
  DefinitionFilterConfig,
  onNodeDefinitionEditSaveType,
  onNodeInstructionsEditSaveType,
} from "../../ruledetailpage.types";

export type RuleDetailPageContentProps = {
  isLoading: boolean;
  isNodeEditSaveLoading: boolean;

  ruleDefinitionConfig: RuleDefinitionValueType;
  defFiltersConfiguration?: DefinitionFilterConfig;
  onNodeDefinitionEditSave: onNodeDefinitionEditSaveType;
  onNodeInstructionsEditSave: onNodeInstructionsEditSaveType;
};

export type RuleDetailPageTabsKeys =
  | "data"
  | "history"
  | "definition"
  | "instructions"
  | "columnSequence";

export const ruleDetailPageTabsValues: {
  [key in RuleDetailPageTabsKeys]: RuleDetailPageTabType;
} = {
  data: "data",
  history: "history",
  definition: "definition",
  instructions: "instructions",
  columnSequence: "column-sequence",
};

export type RulePageTabsType = {
  key: RuleDetailPageTabType;
  value: string | JSX.Element;
  id: string;
};

export type RuleOverviewSectionRowType = {
  title: string;
  value?: string;
  representsData?: Represents[];
  assignedTo?: string;
  actionKey?: string;
  url?: string;
  id?: string;
};
