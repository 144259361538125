import styled from "styled-components";
import { hexToRgbA } from "../../utils";
import { IconWithSelectedStateProps } from "./iconselectedstate.types";

export const IconWithSelectedStateStyled = styled.div<IconWithSelectedStateProps>`
  cursor: ${({ disabled }): string => (disabled ? "not-allowed" : "pointer")};
  width: ${({ width }): string => width || "28px"};
  height: ${({ height }): string => height || "28px"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ selected, theme }): any =>
    selected && hexToRgbA(theme.bgColors.BLUE_116, 0.06)};
  margin-left: ${({ marginLeft }): any => marginLeft && marginLeft};
  pointer-events: all;
  opacity: ${({ disabled }): string => (disabled ? "0.5" : "")};
`;
