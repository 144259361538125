// <--- Components Start --->
import SectionRow from "../../../../../sectionrow";
import SectionWithBody from "../../../../../sectionwithbody";

// <--- Components End --->

// <--- Renderers Start --->
import { ruleTypeDetailModalRenderers } from "./ruletypedetailmodalruledefinitionsec.renderers";
// <--- Renderers End --->

// <--- Styles Start --->
import { RuleTypeDetailsRuleDefinitionSecStyled } from "./ruletypedetailmodalruledefinitionsec.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { infoIcon } from "../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { RuleTypeDetailsRuleDefinitionSecProps } from "./ruletypedetailmodalruledefinitionsec.types";
import { RuleDefinitionTooltip } from "..";
// <--- Types End --->

function RuleTypeDetailsRuleDefinitionSec(
  props: RuleTypeDetailsRuleDefinitionSecProps
): JSX.Element {
  const { data = [] } = props;

  return (
    <RuleTypeDetailsRuleDefinitionSecStyled data-testid="rule-type-details-rule-definition-sec-component">
      <SectionWithBody
        headingProps={{
          title: "Rule definition",
          isShowIcon: false,
          paddingBottom: "1px",
          marginBottom: "18px",
          fontSize: "16px",
          leftIcon: infoIcon,
          leftSecGap: 10,
          leftIconTooltip: {
            title: RuleDefinitionTooltip,
            placement: "right",
            overlayClassName: "left-icon-tooltip",
            getPopupContainer: (trigger): HTMLElement =>
              trigger.parentNode as HTMLElement,
          },
        }}
      >
        <>
          {data?.map((item) => (
            <SectionRow
              {...item}
              customJSX={ruleTypeDetailModalRenderers?.[item?.title]?.(item)}
              key={`rules-defination-sec${item?.title}`}
              data-testid={`rules-defination-sec-${item?.title}`}
            />
          ))}
        </>
      </SectionWithBody>
    </RuleTypeDetailsRuleDefinitionSecStyled>
  );
}

export default RuleTypeDetailsRuleDefinitionSec;
