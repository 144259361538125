// <--- Import Statements Start --->
import { useCallback, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
// <--- Import Statements End --->

// <--- Components Start --->
import { Button } from "../../components";
import LinkButton from "../../components/linkbutton";

import FileUploader from "../../components/fileuploader";
import StateHandler from "../../components/statehandler/statehandler";

import SectionWithBody from "../../components/sectionwithbody";
import {
  InputField,
  RadioGroupField,
  SelectField,
} from "../../components/formfields";

import {
  HelpingTextSection,
  ServiceProviderConfigurations,
} from "./addssoform.components";
// <--- Components Start --->

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";
import { attributeOptions, configsSavedText } from "./addssoform.constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import { useCancelModal, useGetAppState, useSetData } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Schemas Start --->
import { addSSOSchema } from "../../utils/schemas/accountsettingsschemas";
// <--- Schemas End --->

// <--- Services Start --->
import { useRequestWithMethod } from "../../api";
// <--- Services End --->

// <--- Styles Start --->
import { FormItemStyled, FormStyled } from "../../components/form";
import { AddSSOFormStyled, UploadFileSection } from "./addssoform.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { plusIconInFilledCircle, tickIconGreen } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  AddSSOFormProps,
  AddSSOFormType,
  AttributeType,
  FileState,
} from "./addssoform.types";
// <--- Types End --->

const AddSSOForm = (props: AddSSOFormProps): JSX.Element => {
  const { isEditMode } = props;

  const [state, setState] = useState<FileState>({
    selected: "",
    progress: 0,
    status: "Pending",
  });

  const onCancel = useCancelModal();
  const onSetData = useSetData();
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const { get_all_sso: getAllSSO } = API_CONFIG;

  // Form data population
  const {
    provider_name = "",
    identifiers = "",
    idp_metadata = "metadata_url",
    metadata_url,
    s3_url,
    attributes = [{ attribute_url: "", attribute_type: undefined }],
  } = modalProps as AddSSOFormType;

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm<AddSSOFormType>({
    defaultValues: {
      provider_name,
      identifiers,
      idp_metadata,
      metadata_url,
      s3_url,
      attributes,
    },
    resolver: yupResolver(addSSOSchema),
    mode: "onChange",
  });

  const {
    fields: attributeFields = [],
    append,
  } = useFieldArray<AddSSOFormType>({
    control,
    name: "attributes",
  });

  const selectedIdpMetadata = watch("idp_metadata");

  // APIs integration
  // Add SSO API
  const onSuccess = useCallback((response) => {
    const data = response?.data;
    onSetData(getAllSSO, data);
    reset();
    onCancel();
  }, []);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode ? "edit_sso" : "add_sso",
    isEditMode ? [identifiers] : undefined,
    true,
    onSuccess
  );

  // File Upload API
  const onFailureFileUpload = useCallback(() => {
    setState((st) => ({ ...st, progress: 100, status: "Failed" }));
    clearInterval(state?.interval ? Number(state?.interval) : undefined);
  }, []);

  const onSuccessFileUpload = useCallback((response) => {
    const downloadLink = response?.data?.download_link;

    setValue("s3_url", downloadLink);
    setState((st) => ({ ...st, progress: 100, status: "Completed" }));
    clearInterval(state?.interval ? Number(state?.interval) : undefined);
  }, []);

  const {
    onExecuteRequest: onExecuteRequestFileUpload,
  } = useRequestWithMethod(
    "upload_metadata_file",
    undefined,
    true,
    onSuccessFileUpload,
    onFailureFileUpload,
    { "Content-Type": "multipart/form-data" }
  );

  // Callbacks
  const onChangeFile = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append("file", file.file);

      onExecuteRequestFileUpload(formData);

      const interval = setInterval(() => {
        setState((st) => ({
          ...st,
          progress: st.progress === 100 ? st.progress : st.progress + 10,
        }));
      }, 1500);

      setState((st) => ({
        ...st,
        file,
        interval,
      }));

      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    },
    [state]
  );

  // Submit Form
  const onSubmit = useCallback((values) => {
    const attributes = values?.attributes?.map((attribute: AttributeType) => {
      return { [attribute?.attribute_type]: attribute?.attribute_url };
    });

    let requestBody = {
      identifier: values?.identifiers || "",
      idp_public_link:
        values?.idp_metadata === "metadata_url"
          ? values?.metadata_url || ""
          : "",
      idp_file_s3_link:
        values?.idp_metadata === "upload_file" ? values?.s3_url : "",
      attributes,
    };

    if (!isEditMode) {
      requestBody = {
        ...requestBody,
        ...{ provider_name: values?.provider_name || "" },
      };
    }

    onExecuteRequest(requestBody);
  }, []);

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <AddSSOFormStyled maxHeight="calc(100vh - 128px)">
        <FormStyled
          paddingLeft="130px"
          onFinish={handleSubmit(onSubmit) as any}
          isItemColumnLayout
        >
          <div className="scroll-sec">
            <div className="form-sec">
              <ServiceProviderConfigurations />

              <SectionWithBody
                headingProps={{
                  title: "Identity Provider Configurations",
                  isShowIcon: false,
                  className: "section-with-body",
                }}
              >
                <FormItemStyled label="Provider Name" required>
                  <InputField
                    control={control}
                    name="provider_name"
                    disabled={isEditMode}
                  />
                </FormItemStyled>

                <FormItemStyled label="Identifier" required>
                  <InputField control={control} name="identifiers" />
                </FormItemStyled>

                <FormItemStyled label="IDP Metadata" required>
                  <RadioGroupField
                    name="idp_metadata"
                    value={selectedIdpMetadata}
                    control={control}
                    options={[
                      {
                        label: "Metadata URL",
                        value: "metadata_url",
                        children: (
                          <InputField
                            control={control}
                            className="metadata-url"
                            name="metadata_url"
                            placeholder="Provide meta data endpoint URL"
                          />
                        ),
                      },
                      {
                        label: "Upload File",
                        value: "upload_file",
                        children: (
                          <UploadFileSection>
                            <FileUploader
                              completionProgress={state.progress}
                              status={state.status}
                              file={state?.file}
                              onChange={onChangeFile}
                              customClass="metadata-file"
                            />
                            {s3_url && (
                              <div className="file-exist-tag">
                                {tickIconGreen()} {configsSavedText}
                              </div>
                            )}
                            <InputField
                              control={control}
                              name="s3_url"
                              hidden
                            />
                          </UploadFileSection>
                        ),
                      },
                    ]}
                  />
                </FormItemStyled>

                <FormItemStyled label="Attributes" required>
                  {attributeFields?.map((_, index) => (
                    <div
                      className="attributes-list"
                      key={`add-sso-form-${index}`}
                    >
                      <InputField
                        control={control}
                        name={`attributes.${index}.attribute_url`}
                        placeholder="Enter Attribute URL"
                      />

                      <SelectField
                        control={control}
                        name={`attributes.${index}.attribute_type`}
                        placeholder="Select attribute"
                        options={attributeOptions}
                        setValue={setValue}
                        setError={setError}
                      />
                    </div>
                  ))}

                  <LinkButton
                    className="add-attributes-btn"
                    onClick={(): void =>
                      append({ attribute_url: "", attribute_type: undefined })
                    }
                  >
                    {plusIconInFilledCircle("16px", "16px")}Add Attributes
                  </LinkButton>
                </FormItemStyled>
              </SectionWithBody>
            </div>
            <HelpingTextSection />
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              height="39px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddSSOFormStyled>
    </StateHandler>
  );
};

export default AddSSOForm;
