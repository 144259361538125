import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const addWidgetFormSchema = yup.object().shape({
  title: yup
    .string()
    ?.trim()
    ?.test(
      "no-more-than-60-chars",
      "Title should be less than 100 characters",
      (currentField) => {
        const currentFieldLength = currentField?.length || 0;
        if (currentFieldLength > 101) return false;
        return true;
      }
    )
    .required(REQUIRED_MESSAGE),
  module: yup.string()?.trim().required(REQUIRED_MESSAGE),
  module_filter_criteria: yup.string()?.trim(),
  chart_type: yup.string()?.trim().required(REQUIRED_MESSAGE),
  slicer: yup.lazy((value, originalObject) => {
    const chartType = originalObject.parent.chart_type;

    const isKpiChart = chartType === "KPI";
    return !isKpiChart
      ? yup.array().of(yup.string()).min(1, "Select at least 1 slicer")
      : yup.array();
  }),
  metric: yup.array().of(yup.string()).min(1, "Select at least 1 metric"),
  sort_by_property: yup.string(),
  enable_data_labels: yup.boolean(),
});
