import styled from "styled-components";

export const AddWidgetCreateOrPickWrapperStyled = styled.div`
  height: 90vh;
`;

export const WidgetCardsWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  height: calc(100vh - 106px);
`;

export const WidgetFooterWrapperStyled = styled.div`
  background-color: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 18px 8px 18px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const AddWidgetsCard = styled.div`
  max-width: 802px;
  padding: 19px;
  border: 1px solid ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
  cursor: pointer;

  .add-widget-card-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
  }

  .add-widget-card-desc {
    color: ${({ theme }): string => theme.textColors.GREY_24};
  }
`;
