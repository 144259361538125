// <--- Library Imports Start --->
import { Key } from "antd/lib/table/interface";

import { Collapse, Menu } from "antd";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import LinkButton from "../../../../../../components/linkbutton";

import CoreNodesCircleIcon from "../../../../../../components/corenodetypescircleicon";

// <--- Components End --->

// <--- Constants Start --->
import { PANELS_HEADINGS } from "./analysisdetailpageexplorersec.constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import { useDebounce, useSetData } from "../../../../../../customhooks";
// <--- Custom Hooks End --->

// <--- Parsers Start --->
import { getParsedDatasets } from "../../../../../../parsers";
// <--- Parsers End --->

// <--- Services Start --->
import dvSumAxios, { useRequestWithMethod } from "../../../../../../api";
// <--- Services End --->

// <--- Styles Start --->
import { AnalysisDetailPageExplorerSecStyled } from "./analysisdetailpageexplorersec.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { chatNavIcon, greyDatabaseIcon } from "../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { AnalysisDetailPageExplorerSecProps } from "./analysisdetailpageexplorersec.types";

import { AnalysisSchemasListType } from "../../../../analysisdetailpage.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  ManageFieldsView,
  PinnedTablesView,
  TableExplorerView,
  TableItem,
} from "./analysisdetailpageexplorersec.comp";
import { API_CONFIG } from "../../../../../../constants/apiconfig";
import { sortObjectsArrayByKey } from "../../../../../../utils";
// <--- Utils End --->

const { CancelToken } = dvSumAxios;

const AnalysisDetailPageExplorerSec = (
  props: AnalysisDetailPageExplorerSecProps
): JSX.Element => {
  const {
    pinnedTablesList = [],
    updatePinnedTablesList,
    onAddQuery,
    tableIds,
    sourceId,
    queries = [],
    analysisId = "",
    onSaveDoneClick,
    hasManageFieldsChanges,
    onManageFieldsChange,
  } = props;

  // States
  const [isShowRelatedDatasets, setIsShowRelatedDatasets] = useState<boolean>(
    false
  );
  const [searchText, setSearchText] = useState("");

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [isActiveManageFields, setIsActiveManageFields] = useState(false);
  const [cancelToken, setCancelToken] = useState<{ token: any }>();

  const [isUpdatingPrompt, setIsUpdatingPrompt] = useState<boolean>(false);

  const firstRender = useRef(true);
  const cancelPinTblApi = useRef<any>(null);

  // Custom Hooks
  const searchDebounce = useDebounce(searchText, 500);

  const onSetData = useSetData();

  // Services
  const {
    onExecuteRequest: onRelatedDatasetsExecuteRequest,
    data: parsedRelatedDatasets = [],
    isLoading: isLoadingRelatedDatasets,
  } = useRequestWithMethod("get_related_datasets", [], true);

  const pinnedTableIds = useMemo(
    () => pinnedTablesList?.map((tbl) => tbl.id).join(","),
    [pinnedTablesList?.length]
  );

  useEffect(() => {
    if (pinnedTablesList.length) {
      const payload = {
        src_id: sourceId || "",
        tbl_ids: pinnedTableIds || "",
      };

      onRelatedDatasetsExecuteRequest(
        payload,
        undefined,
        undefined,
        undefined,
        undefined,
        getParsedDatasets
      );
    } else {
      setIsShowRelatedDatasets(false);
    }
  }, [pinnedTablesList.length]);

  const {
    onExecuteRequest: onGetDatasetsExecuteRequest,
    data: parsedSchemasList = [],
    isLoading: isLoadingDatasets,
  } = useRequestWithMethod("get_datasets", [], true);

  const onUpdatePromptSuccess = useCallback(
    (res) => {
      setIsUpdatingPrompt(false);
      if (analysisId) {
        onSetData(API_CONFIG.get_pinned_tbls_manage_fields, res?.data, [
          analysisId,
          pinnedTableIds,
        ]);
      }
    },
    [analysisId, pinnedTableIds]
  );

  const onUpdatePromptFailure = useCallback((err) => {
    if (err?.formattedMsg !== "Network Error") {
      setIsUpdatingPrompt(false);
    }
  }, []);

  const {
    onExecuteRequest: onSendPinnedTableExecuteRequest,
  } = useRequestWithMethod(
    "update_prompt",
    undefined,
    true,
    onUpdatePromptSuccess,
    onUpdatePromptFailure
  );
  // Memoized variables
  const debouncedSearchText = searchDebounce && searchText?.toLowerCase();

  const isSearchTextEmpty = debouncedSearchText === "";

  const transformedSchemasList = useMemo(() => {
    const list: AnalysisSchemasListType = isShowRelatedDatasets
      ? parsedRelatedDatasets
      : parsedSchemasList;

    const sortedList = sortObjectsArrayByKey(list, "title");

    return sortedList
      ?.filter((item) =>
        !isSearchTextEmpty
          ? item?.tables?.find((table) =>
              table?.title?.toLowerCase()?.includes(debouncedSearchText)
            )
          : true
      )
      ?.map((schema) => {
        const sortedTables = sortObjectsArrayByKey(schema?.tables, "title");

        return {
          title: (
            <div
              className="parent-title-container"
              title={`${schema?.title} (${schema?.tables?.length || 0})`}
            >
              <span className="parent-title">{schema?.title}</span>

              <span className="children-count">{` (${
                (schema?.tables?.length && schema?.tables?.length) || 0
              })`}</span>
            </div>
          ),
          key: schema?.title,
          children: sortedTables
            ?.filter((table) =>
              !isSearchTextEmpty
                ? table?.title?.toLowerCase()?.includes(debouncedSearchText)
                : true
            )
            ?.map((table) => {
              const { id = 0, title = "", name = "" } = table || {};

              const isPinned = pinnedTablesList?.some(
                (pinnedTable) => pinnedTable?.id === table?.id
              );

              const isSchemaIncludesInTitle = title?.includes(".");

              const updatedTitle = isSchemaIncludesInTitle
                ? title
                : `${schema?.title}.${title}`;

              const isSchemaIncludesInName = name?.includes(".");

              const updatedName = isSchemaIncludesInName
                ? name
                : `${schema?.title}.${name}`;

              return {
                title: (
                  <TableItem
                    id={id}
                    title={updatedTitle}
                    isPinned={isPinned}
                    queries={queries}
                    onAddQuery={onAddQuery}
                    updatePinnedTablesList={updatePinnedTablesList}
                    name={updatedName}
                  />
                ),
                key: table?.id,
                icon: (
                  <CoreNodesCircleIcon
                    nodeType="TBL"
                    height="16px"
                    width="16px"
                    fontSize="11px"
                  />
                ),
                isLeaf: true,
                selectable: !table?.isPinned,
              };
            }),
        };
      });
  }, [
    parsedRelatedDatasets,
    parsedSchemasList,
    pinnedTablesList,
    debouncedSearchText,
    isSearchTextEmpty,
    queries,
    tableIds,
    isShowRelatedDatasets,
  ]);

  const initialExpandedKeys = useMemo(() => {
    return transformedSchemasList?.map((item) => item?.key) || [];
  }, [transformedSchemasList]);

  // Callbacks

  const onClickShowRelatedDatasets = useCallback(() => {
    setIsShowRelatedDatasets((s) => !s);
    setExpandedKeys(initialExpandedKeys);
  }, [initialExpandedKeys]);

  const onSearchTextChange = useCallback((e) => {
    setSearchText(e?.target?.value || "");
  }, []);

  const onChangeCollapsible = useCallback(
    async (key: string | string[]) => {
      const isTableBrowserPanelOpen = key?.includes(
        PANELS_HEADINGS?.available_tables
      );

      if (isTableBrowserPanelOpen && !isDataLoaded) {
        const payload = {
          src_id: sourceId || "",
          tbl_ids: tableIds || "",
        };

        await Promise.all([
          onGetDatasetsExecuteRequest(
            payload,
            undefined,
            undefined,
            undefined,
            undefined,
            getParsedDatasets
          ),
        ]);

        setIsDataLoaded(true);
      }
    },
    [isDataLoaded, sourceId, tableIds]
  );

  useEffect(() => {
    if (isDataLoaded && !(isLoadingRelatedDatasets || isLoadingDatasets)) {
      setExpandedKeys(initialExpandedKeys);
    }
  }, [isDataLoaded, isLoadingRelatedDatasets, isLoadingDatasets]);

  useEffect(() => {
    setExpandedKeys(initialExpandedKeys);
  }, [searchDebounce]);

  useEffect(() => {
    if (firstRender?.current) {
      firstRender.current = false;
    } else {
      setIsUpdatingPrompt(true);
      cancelPinTblApi.current = CancelToken.source();
      setCancelToken({ token: cancelPinTblApi?.current?.cancel });

      cancelToken?.token?.();

      const pinnedTables =
        pinnedTablesList?.map((pinnedTable) => ({
          pin_tbl_id: pinnedTable?.id,
          pin_tbl_name: pinnedTable?.name,
          pin_tbl_type: "TBL",
        })) || [];

      onSendPinnedTableExecuteRequest(
        { pin_tables: JSON.stringify(pinnedTables) },
        [analysisId || "null", "", analysisId ? pinnedTableIds : ""],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        cancelPinTblApi?.current?.token
      );
    }
  }, [pinnedTablesList?.length]);

  const onClickManageFields = useCallback(
    (e) => {
      e?.stopPropagation();
      setIsActiveManageFields((st) => !st);
      if (!analysisId) {
        onSaveDoneClick?.({ updatePromptAfterSave: () => {} });
      }
    },
    [analysisId, onSaveDoneClick]
  );

  return (
    <AnalysisDetailPageExplorerSecStyled data-testid="analysis-detail-page-explorer-sec-main-wrapper">
      <Menu
        mode="inline"
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
      >
        <Collapse
          bordered={false}
          onChange={onChangeCollapsible}
          {...(isActiveManageFields && {
            activeKey: PANELS_HEADINGS?.tables_for_chat,
          })}
        >
          {isActiveManageFields ? (
            <ManageFieldsView
              onClickManageFields={onClickManageFields}
              analysisId={analysisId}
              pinnedTableIds={pinnedTableIds}
              onManageFieldsChange={onManageFieldsChange}
              hasManageFieldsChanges={hasManageFieldsChanges}
            />
          ) : (
            <Collapse.Panel
              header={
                <div
                  className="collapse-header-content"
                  data-testid="analysis-detail-page-explorer-sec-pinned-sec-header"
                >
                  <div className="collapse-header-content-title">
                    {chatNavIcon("15", "15")}
                    {PANELS_HEADINGS?.tables_for_chat}
                    {!!pinnedTablesList?.length && (
                      <div className="count">{pinnedTablesList?.length}</div>
                    )}
                  </div>
                  {!!pinnedTableIds?.length && (
                    <LinkButton
                      fontFamily="openSans"
                      fontSize="13px"
                      onClick={onClickManageFields}
                      disabled={isUpdatingPrompt}
                      tooltipProps={{
                        title: isUpdatingPrompt
                          ? "Prompt is being updated"
                          : "",
                      }}
                    >
                      Manage Fields
                    </LinkButton>
                  )}
                </div>
              }
              key={PANELS_HEADINGS?.tables_for_chat}
            >
              <PinnedTablesView
                pinnedTablesList={pinnedTablesList}
                updatePinnedTablesList={updatePinnedTablesList}
                onAddQuery={onAddQuery}
                queries={queries}
              />
            </Collapse.Panel>
          )}

          {!isActiveManageFields && (
            <Collapse.Panel
              header={
                <div
                  className="collapse-header-content"
                  data-testid="analysis-detail-page-explorer-sec-table-browser-sec-header"
                >
                  {greyDatabaseIcon} {PANELS_HEADINGS?.available_tables}
                </div>
              }
              key={PANELS_HEADINGS?.available_tables}
            >
              <TableExplorerView
                isShowRelatedDatasets={isShowRelatedDatasets}
                onClickShowRelatedDatasets={onClickShowRelatedDatasets}
                isLoadingDatasets={!!isLoadingDatasets}
                searchText={searchText}
                onSearchTextChange={onSearchTextChange}
                transformedSchemasList={transformedSchemasList}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
                isSearchTextEmpty={isSearchTextEmpty}
                hideRelatedDatasetButton={!pinnedTablesList?.length}
                isLoadingRelatedDatasets={!!isLoadingRelatedDatasets}
              />
            </Collapse.Panel>
          )}
        </Collapse>
      </Menu>
    </AnalysisDetailPageExplorerSecStyled>
  );
};

export default AnalysisDetailPageExplorerSec;
