import { MetricFormatStyled } from "./metricformat.styles";
import { AddMetricInput } from "./metricformat.components";

import Flex from "../../../../../../components/flex";
import { MetricFormatProps } from "./metricformat.types";

import {
  SecDescStyled,
  SecTitleStyled,
} from "../../chatpreferencesview.styles";

const MetricFormat = (props: MetricFormatProps): JSX.Element => {
  const {
    label = "",
    description = "",
    currencyFormatMetrics,
    percentFormatMetrics,
    fieldsInEditMode,
    setCurrencyFormatMetrics,
    setPercentFormatMetrics,
    onFieldEditButtonClick,
  } = props;

  return (
    <MetricFormatStyled>
      <SecTitleStyled marginBottom="0px">{label}</SecTitleStyled>
      <SecDescStyled>{description}</SecDescStyled>

      <Flex columnGap={70} alignItems="flex-start">
        <AddMetricInput
          keyName="percent"
          label="Percent Format Fields"
          placeholder="Add Keyword E.g. Growth Rate"
          setMetrics={setPercentFormatMetrics}
          metrics={percentFormatMetrics}
          restrictedMetrics={currencyFormatMetrics}
          alternateRestrictedInputMessage="The value you are trying to enter or a similar one already exists under Currency Format Fields"
          onFieldEditButtonClick={onFieldEditButtonClick}
          editModeFields={fieldsInEditMode}
        />
        <AddMetricInput
          keyName="currency"
          label="Currency Format Fields"
          placeholder="Add Keyword E.g. Spends"
          setMetrics={setCurrencyFormatMetrics}
          metrics={currencyFormatMetrics}
          restrictedMetrics={percentFormatMetrics}
          alternateRestrictedInputMessage="The value you are trying to enter or a similar one already exists under Percent Format Fields"
          onFieldEditButtonClick={onFieldEditButtonClick}
          editModeFields={fieldsInEditMode}
        />
      </Flex>
    </MetricFormatStyled>
  );
};

export default MetricFormat;
