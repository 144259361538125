import {
  ToggleButtonWrapper,
  ToggleButtonStyled,
} from "./andortogglebutton.styles";
import { OptionItemType, ToggleButtonTypes } from "./andortogglebutton.types";

export const AndOrToggleButton = (props: ToggleButtonTypes): JSX.Element => {
  const { options, onClick, selectedOption, disabled } = props;

  return (
    <ToggleButtonWrapper className="toggle-button-wrapper">
      {options?.map((option: string, index: number) => {
        const isSelected = selectedOption === option;
        return (
          <ToggleButtonStyled
            selected={isSelected}
            key={`toggle-button-option-${index}`}
            onClick={(): void => onClick(option)}
            className={`toggle-button-option${isSelected ? "-selected" : ""}`}
            disabled={disabled}
          >
            {option}
          </ToggleButtonStyled>
        );
      })}
    </ToggleButtonWrapper>
  );
};
