import { Popover } from "antd";

import { DvSumPopoverStyled } from "./dvsumpopover.styles";
import { DvSumPopoverProps } from "./dvsumpopover.types";

function DvSumPopover(props: DvSumPopoverProps): JSX.Element {
  const { children, showArrow = false, ...resProps } = props;
  return (
    <>
      <DvSumPopoverStyled showArrow={showArrow} />
      <Popover {...resProps} overlayClassName="dvsum-popover">
        {children}
      </Popover>
    </>
  );
}

export default DvSumPopover;
