import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import Checkbox from "../../../../components/checkbox";
import { helpIcon, searchIcon } from "../../../../svgs";
import { InputFieldStyled } from "../../../../components/formfields/inputfield/inputfield.styles";
import { GlobalFiltersViewStyled } from "./globalfiltersview.styles";
import { SourceTypes } from "../../../../app.types";
import { Button } from "../../../../components";
import { VerticalDividerStyled } from "../../../../components/dividers/dividers.styles";
import { useGetGlobalFiltersService } from "../../../../api/globalfiltersservice";
import { useRequestWithMethod } from "../../../../api";
import { LOCAL_STORAGE_CONSTANTS } from "../../../../constants";
import StateHandler from "../../../../components/statehandler";
import { globalFiltersBlankSlates } from "../../../../blankslates/globalfiltersblankslate";
import { getParsedSelectedGlobalFilter } from "../../../../parsers/globalfiltersparser/globalfiltersparser";
import { getGlobalFiltersData } from "../../../../utils/getglobalfiltersdata";
import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import RouterPrompt from "../../../../components/routerprompt";
import {
  getDataSourceLogoIconByType,
  openNotification,
} from "../../../../utils";
import { GlobalFiltersViewProps } from "./globalfiltersview.types";

const GlobalFiltersView = (props: GlobalFiltersViewProps): JSX.Element => {
  const history = useHistory();

  const { lastPage } = props;

  const sourceFiltersWrapperRef = useRef<HTMLDivElement>(null);
  const domainFiltersWrapperRef = useRef<HTMLDivElement>(null);

  const [isSourcesActive, setIsSourcesActive] = useState<boolean>();
  const [isDomainsActive, setIsDomainsActive] = useState<boolean>();

  const [
    locallySelectedSourcesFilters,
    setLocallySelectedSourcesFilters,
  ] = useState<number[]>([]);
  const [
    locallySelectedDomainsFilters,
    setLocallySelectedDomainsFilters,
  ] = useState<number[]>([]);

  const [sourcesSearchTerm, setSourcesSearchTerm] = useState("");
  const [domainsSearchTerm, setDomainsSearchTerm] = useState("");

  const [selectionCanceled, setSelectionCanceled] = useState(false);

  const { selected_filters: selectedFilters } = getGlobalFiltersData();
  const { source, domain } = selectedFilters || {};

  const {
    is_enabled: isSourceEnabled = false,
    filters_list: sourcesFiltersList = [],
  } = source || {};

  const {
    is_enabled: isDomainEnabled = false,
    filters_list: domainsFiltersList = [],
  } = domain || {};

  useEffect(() => {
    setIsSourcesActive(isSourceEnabled);
    setIsDomainsActive(isDomainEnabled);
  }, [isSourceEnabled, isDomainEnabled]);

  const isEdited = useMemo(
    () =>
      [...sourcesFiltersList, ...domainsFiltersList]
        ?.map((filter) => filter?.id)
        .sort()
        .join(",") !==
        [...locallySelectedSourcesFilters, ...locallySelectedDomainsFilters]
          .sort()
          .join(",") ||
      isSourcesActive !== isSourceEnabled ||
      isDomainsActive !== isDomainEnabled,
    [
      sourcesFiltersList,
      domainsFiltersList,
      isSourcesActive,
      isSourceEnabled,
      isDomainsActive,
      isDomainEnabled,
      locallySelectedSourcesFilters,
      locallySelectedDomainsFilters,
    ]
  );

  useEffect(() => {
    const selectedSourcesFilters =
      sourcesFiltersList?.map((source) => source?.id) || [];
    const selectedDomainsFilters =
      domainsFiltersList?.map((domain) => domain?.id) || [];

    setLocallySelectedSourcesFilters(selectedSourcesFilters);
    setLocallySelectedDomainsFilters(selectedDomainsFilters);
    setIsSourcesActive(isSourceEnabled);
    setIsDomainsActive(isDomainEnabled);
    setSelectionCanceled(false);
    setSourcesSearchTerm("");
    setDomainsSearchTerm("");
    sourceFiltersWrapperRef?.current?.scrollTo(0, 0);
    domainFiltersWrapperRef?.current?.scrollTo(0, 0);
  }, [selectionCanceled]);

  const {
    parsedData: sourcesParsedData = [],
    isLoading: sourcesIsLoading,
    error: sourcesError,
  } = useGetGlobalFiltersService("SRC");

  const {
    parsedData: domainsParsedData = [],
    isLoading: domainsIsLoading,
    error: domainsError,
  } = useGetGlobalFiltersService("DMN");

  const totalFiltersCount = useMemo(
    () =>
      (isSourcesActive ? locallySelectedSourcesFilters?.length : 0) +
      (isDomainsActive ? locallySelectedDomainsFilters?.length : 0),
    [
      isSourcesActive,
      isDomainsActive,
      locallySelectedSourcesFilters,
      locallySelectedDomainsFilters,
    ]
  );

  const filteredSources = useMemo(
    () =>
      [
        ...sourcesParsedData?.filter((parsedSource) =>
          sourcesFiltersList?.some(
            (selectedSource) => selectedSource?.name === parsedSource?.name
          )
        ),
        ...sourcesParsedData?.filter(
          (parsedSource) =>
            !sourcesFiltersList?.some(
              (selectedSource) => selectedSource?.name === parsedSource?.name
            )
        ),
      ]?.filter((source) =>
        source?.name?.toLowerCase()?.includes(sourcesSearchTerm?.toLowerCase())
      ),
    [sourcesParsedData, sourcesSearchTerm, selectedFilters]
  );

  const filteredDomains = useMemo(
    () =>
      [
        ...domainsParsedData?.filter((parsedDomain) =>
          domainsFiltersList?.some(
            (selectedDomain) => selectedDomain?.name === parsedDomain?.name
          )
        ),
        ...domainsParsedData?.filter(
          (parsedDomain) =>
            !domainsFiltersList?.some(
              (selectedDomain) => selectedDomain?.name === parsedDomain?.name
            )
        ),
      ]?.filter((domain) =>
        domain?.name?.toLowerCase()?.includes(domainsSearchTerm?.toLowerCase())
      ),
    [domainsParsedData, domainsSearchTerm]
  );

  const reset = useCallback(() => {
    setSourcesSearchTerm("");
    setDomainsSearchTerm("");
    setSelectionCanceled(true);
    sourceFiltersWrapperRef?.current?.scrollTo(0, 0);
    domainFiltersWrapperRef?.current?.scrollTo(0, 0);
  }, []);

  const onFilterChange = useCallback(
    (filter: number, type: "source" | "domain") => {
      if (type === "source") {
        !locallySelectedSourcesFilters?.includes(filter)
          ? setLocallySelectedSourcesFilters([
              ...locallySelectedSourcesFilters,
              filter,
            ])
          : setLocallySelectedSourcesFilters(
              locallySelectedSourcesFilters?.filter(
                (source) => source !== filter
              )
            );
      } else if (type === "domain") {
        !locallySelectedDomainsFilters?.includes(filter)
          ? setLocallySelectedDomainsFilters([
              ...locallySelectedDomainsFilters,
              filter,
            ])
          : setLocallySelectedDomainsFilters(
              locallySelectedDomainsFilters?.filter(
                (domain) => domain !== filter
              )
            );
      }
    },
    [locallySelectedSourcesFilters, locallySelectedDomainsFilters]
  );

  const onSuccess = useCallback(
    (res) => {
      const parsedResponse = getParsedSelectedGlobalFilter(res);

      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.global_filters,
        JSON.stringify(parsedResponse || {})
      );

      openNotification(
        <SuccessNotificationMessage message="Global filters have been updated successfully!" />
      );

      reset();

      if (lastPage) {
        history.go(lastPage);
      }
    },
    [lastPage]
  );

  const {
    isLoading: saveSelectedFiltersIsLoading,
    error: saveSelectedFiltersError,
    onExecuteRequest,
  } = useRequestWithMethod("update_global_filters", [], true, onSuccess);

  const handleSave = useCallback(() => {
    onExecuteRequest({
      source: {
        is_enabled: isSourcesActive,
        filters_list: sourcesParsedData
          ?.filter((source) =>
            locallySelectedSourcesFilters?.includes(source?.id)
          )
          ?.map((filteredSource) => ({
            id: filteredSource?.id,
            name: filteredSource?.name,
          })),
      },
      domain: {
        is_enabled: isDomainsActive,
        filters_list: domainsParsedData?.filter((domain) =>
          locallySelectedDomainsFilters?.includes(domain?.id)
        ),
      },
    });
  }, [
    isSourcesActive,
    isDomainsActive,
    sourcesParsedData,
    locallySelectedSourcesFilters,
    domainsParsedData,
    locallySelectedDomainsFilters,
  ]);

  return (
    <StateHandler
      isFetching={saveSelectedFiltersIsLoading}
      error={saveSelectedFiltersError}
      isModal
    >
      <GlobalFiltersViewStyled
        isSourcesActive={isSourcesActive}
        isDomainsActive={isDomainsActive}
      >
        <RouterPrompt
          when={isEdited}
          title="Discard Changes"
          desc="You have unsaved changes on Global Filters. These changes will be lost if you leave this page."
        />
        <div className="content">
          <div className="header">
            <div className="heading-sec">
              <div className="heading">Global Filters</div>
              <div className="selected-filters-tag">
                {totalFiltersCount} Selected
              </div>
            </div>

            <div className="desc-sec">
              Set your Data Source and Data Domain filters. This will limit your
              search results and views to data assets. This can be useful if for
              example you are only interested in viewing the Production Data
              Sources or if your responsibility is for a certain domain of data.
            </div>
          </div>

          <div className="filters-sec">
            <div className="filter-sec-container">
              <div className="filter-sec-header">
                <div className="filter-sec-heading">Data Sources</div>
                <div
                  className="checkbox-container"
                  role="button"
                  onClick={(): void => setIsSourcesActive(!isSourcesActive)}
                >
                  <Checkbox checked={isSourcesActive} /> Filter to specific
                  sources
                </div>
              </div>
              <StateHandler
                isFetching={sourcesIsLoading}
                error={sourcesError}
                blankSlate={globalFiltersBlankSlates}
              >
                <div className="sources-filters-box-wrapper">
                  <div className="filters-box sources-filters-box">
                    <InputFieldStyled
                      className="search-field"
                      prefix={searchIcon()}
                      placeholder="Search"
                      value={sourcesSearchTerm}
                      onChange={(e): void =>
                        setSourcesSearchTerm(e?.target?.value)
                      }
                      allowClear
                    />
                    <div
                      ref={sourceFiltersWrapperRef}
                      className="filters-wrapper"
                    >
                      {filteredSources?.map((source, index) => (
                        <div
                          key={source?.name + index}
                          className="checkbox-container"
                          role="button"
                          onClick={(): void =>
                            onFilterChange(source?.id, "source")
                          }
                        >
                          <Checkbox
                            checked={locallySelectedSourcesFilters?.includes(
                              source?.id
                            )}
                          />{" "}
                          {getDataSourceLogoIconByType(
                            source?.source_type as SourceTypes
                          )}
                          <span className="filter-name" title={source?.name}>
                            {source?.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </StateHandler>
            </div>

            <div className="filter-sec-container">
              <div className="filter-sec-header">
                <div className="filter-sec-heading">Data Domains</div>
                <div
                  className="checkbox-container"
                  role="button"
                  onClick={(): void => setIsDomainsActive(!isDomainsActive)}
                >
                  <Checkbox checked={isDomainsActive} /> Filter to specific
                  domains
                </div>
              </div>
              <StateHandler
                isFetching={domainsIsLoading}
                error={domainsError}
                blankSlate={globalFiltersBlankSlates}
              >
                <div className="domains-filters-box-wrapper">
                  <div className="filters-box domains-filters-box">
                    <InputFieldStyled
                      className="search-field"
                      prefix={searchIcon()}
                      placeholder="Search"
                      value={domainsSearchTerm}
                      onChange={(e): void =>
                        setDomainsSearchTerm(e?.target?.value)
                      }
                      allowClear
                    />
                    <div
                      ref={domainFiltersWrapperRef}
                      className="filters-wrapper"
                    >
                      {filteredDomains?.map((domain, index) => (
                        <div
                          key={domain?.name + index}
                          className="checkbox-container"
                          role="button"
                          onClick={(): void =>
                            onFilterChange(domain?.id, "domain")
                          }
                        >
                          <Checkbox
                            checked={locallySelectedDomainsFilters?.includes(
                              domain?.id
                            )}
                          />
                          <span className="filter-name" title={domain?.name}>
                            {domain?.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </StateHandler>
            </div>
          </div>
        </div>

        <div className="options-sec">
          {isEdited && (
            <>
              <div className="edited-text">Edited</div>
              {helpIcon()}
              <VerticalDividerStyled />
            </>
          )}
          <Button
            id="cancel"
            onClick={(): void => setSelectionCanceled(true)}
            disabled={!isEdited}
          >
            Cancel
          </Button>
          <Button
            disabled={!isEdited}
            onClick={handleSave}
            tooltipProps={{
              title: !isEdited ? "You haven't changed anything" : "",
              placement: "topRight",
            }}
          >
            Save
          </Button>
        </div>
      </GlobalFiltersViewStyled>
    </StateHandler>
  );
};

export default GlobalFiltersView;
