//   <--Library Imports Start-->
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router";
//   <--Library Imports End-->

//   <--Components Start-->
import { Button } from "..";
//   <--Components Start-->

//   <--CustomHooks Start-->
import { useGetAppState } from "../../customhooks";
//   <--CustomHooks Start-->

//   <--Styles Start-->
import { ModalStyled } from "../modalwrapper/modalwrapper.styles";
import { DialogBoxStyled } from "../dialogbox/dialogbox.styles";
//   <--Styles End-->

import { setRouterPrompt } from "../../reducers/appreducer/appreducer";

function RouterPrompt(props: {
  when: boolean;
  title?: string;
  desc?: string;
  highlightText?: string;
  onLeaveCallback?: () => void;
}): any {
  const {
    when,
    title = "Confirmation",
    desc = `You have unsaved changes. These changes will be lost if you leave
  this page.`,
    highlightText = `Do you want to proceed?`,
    onLeaveCallback,
  } = props;

  const {
    routerPrompt: { isAnyUnsavedChanges, isPrompted },
    hasSessionExpired,
  } = useGetAppState();
  const dispatch = useDispatch();

  const history = useHistory();
  const [currentPath, setCurrentPath] = useState<{
    pathName: string;
    search: string;
    state: any;
  }>({ pathName: "", search: "", state: undefined });

  useEffect(() => {
    if (when && !hasSessionExpired) {
      window.onbeforeunload = (e): string => {
        e.returnValue = "hello";
        return "";
      };
      history.block((prompt) => {
        setCurrentPath({
          pathName: prompt?.pathname || "",
          search: prompt?.search || "",
          state: prompt?.state,
        });
        dispatch(
          setRouterPrompt({
            isPrompted:
              prompt.pathname !== history.location.pathname ||
              prompt?.search !== history?.location?.search,
            isAnyUnsavedChanges: true,
          })
        );
        return "true";
      });
      dispatch(
        setRouterPrompt({ isPrompted: false, isAnyUnsavedChanges: true })
      );
    } else {
      history.block(() => {});
      dispatch(setRouterPrompt({}));
    }

    return (): void => {
      history.block(() => {});
      window.onbeforeunload = null;
    };
  }, [history, when, hasSessionExpired]);

  const handleOK = useCallback(() => {
    const isSameRoute =
      currentPath?.pathName === history.location.pathname &&
      currentPath?.search === history?.location?.search;
    if (!isSameRoute) {
      history.block(() => {});
      history.push({
        pathname: currentPath?.pathName,
        search: currentPath?.search,
        state: currentPath?.state,
      });
    }
    onLeaveCallback && onLeaveCallback();
    dispatch(
      setRouterPrompt({
        isPrompted: false,
        isAnyUnsavedChanges: isSameRoute,
      })
    );
  }, [currentPath, history]);

  const handleCancel = useCallback(() => {
    // history.push(history.location.pathname);
    dispatch(setRouterPrompt({ isPrompted: false, isAnyUnsavedChanges: true }));
  }, [isPrompted, isAnyUnsavedChanges, currentPath]);

  return (
    <ModalStyled
      visible={isPrompted}
      title={title}
      width="536px"
      closeIcon={<div />}
      data-testid="router-prompt-modal-wrapper"
    >
      <DialogBoxStyled data-testid="router-prompt-content-wrapper">
        <div className="content-sec" data-testid="router-prompt-content-sec">
          <div className="static-text" data-testid="router-prompt-content-desc">
            {desc}
            <div className="bold" data-testid="router-prompt-content-bold-text">
              {" "}
              {highlightText}
            </div>
          </div>
        </div>
        <div
          className="modal-actions-sec"
          data-testid="router-prompt-actions-sec"
        >
          <Button
            id="cancel"
            width="78px"
            onClick={handleCancel}
            data-testid="router-prompt-cancel-btn"
          >
            Cancel
          </Button>

          <Button
            id="primary"
            width="78px"
            onClick={handleOK}
            marginLeft="5px"
            data-testid="router-prompt-leave-btn"
          >
            Leave
          </Button>
        </div>
      </DialogBoxStyled>
    </ModalStyled>
  );
}

export default RouterPrompt;
