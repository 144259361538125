import * as yup from "yup";
import { FilterCriteriaSchema } from "../filtercriteriaschema";
import { descriptionSchema } from "./ruledescriptionschema";
import { getObjectKeys } from "../../getobjectkeys";

export const rulesetFormFilterCriteriaSchema = yup.object().shape({
  filter_criteria: yup.lazy((_p, p1) => {
    return yup.array().of(
      yup.object().shape({
        condition: yup.array().of(FilterCriteriaSchema),
      })
    );
  }),
});

export const rulesetFormSchema = yup.object().shape({
  rule_description: descriptionSchema,

  is_filter_criteria_active: yup.boolean(),

  filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),

  ruleset_check: yup.lazy((p, p1) => {
    return yup
      .object()
      .test(
        "lengthCheck",
        "At least one ruleset check should be selected.",
        (): boolean => {
          const objKeys = getObjectKeys(p);

          return (
            objKeys?.length > 0 &&
            objKeys?.filter((val) => getObjectKeys(p[val])?.length > 0)
              ?.length > 0
          );
        }
      );
  }),
});
