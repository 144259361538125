// <--- Library Imports Start --->
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// <--- Library Imports End --->

// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../../constants/localstorageconstants";
// <--- Constants End --->

// <--- Types Start --->
import { AppState } from "./appreducer.types";
// <--- Types End --->

const initialState: AppState = {
  selectedTheme: (localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.theme) ??
    "light") as AppState["selectedTheme"],
  sideBarPosition: (localStorage.getItem(
    LOCAL_STORAGE_CONSTANTS?.sidebar_position
  ) ?? "not-collapsed") as AppState["sideBarPosition"],
  caddiSideBarPosition: (localStorage.getItem(
    LOCAL_STORAGE_CONSTANTS?.caddi_sidebar_position
  ) ?? "not-collapsed") as AppState["caddiSideBarPosition"],
  modal: {},
  secondaryModal: {},
  isLogedIn: Boolean(localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.code)),
  drawer: {},
  routerPrompt: {},
  appType:
    window.self !== window.top
      ? "EXT"
      : navigator?.userAgent === "dvsum-searchassistant"
      ? "DKT"
      : "WA",
  hasSessionExpired: false,
  isOnboardingMode: false,
  moduleAccessConfigurations: {},
};

export const appSlice = createSlice({
  name: "appreducer",
  initialState,
  reducers: {
    updateTheme: (
      state,
      action: PayloadAction<AppState["selectedTheme"]>
    ): AppState => ({
      ...state,
      selectedTheme: action.payload,
    }),
    changeSideBarPosition: (
      state,
      action: PayloadAction<AppState["sideBarPosition"]>
    ): AppState => ({
      ...state,
      sideBarPosition: action.payload,
    }),
    changeCaddiSideBarPosition: (
      state,
      action: PayloadAction<AppState["caddiSideBarPosition"]>
    ): AppState => ({
      ...state,
      caddiSideBarPosition: action.payload,
    }),
    setModal: (state, action: PayloadAction<AppState["modal"]>): AppState => ({
      ...state,
      modal: action.payload,
    }),

    setSecondaryModal: (
      state,
      action: PayloadAction<AppState["modal"]>
    ): AppState => ({
      ...state,
      secondaryModal: action.payload,
    }),

    setLogedIn: (
      state,
      action: PayloadAction<AppState["isLogedIn"]>
    ): AppState => ({
      ...state,
      isLogedIn: action.payload,
    }),
    setDrawer: (
      state,
      action: PayloadAction<AppState["drawer"]>
    ): AppState => ({
      ...state,
      drawer: action.payload,
    }),
    setRouterPrompt: (
      state,
      action: PayloadAction<AppState["routerPrompt"]>
    ): AppState => ({
      ...state,
      routerPrompt: action.payload,
    }),
    setAppType: (
      state,
      action: PayloadAction<AppState["appType"]>
    ): AppState => ({
      ...state,
      appType: action.payload,
    }),
    setSessionExpired: (
      state,
      action: PayloadAction<AppState["hasSessionExpired"]>
    ): AppState => ({
      ...state,
      hasSessionExpired: action.payload,
    }),
    setIsOnboardingMode: (
      state,
      action: PayloadAction<AppState["isOnboardingMode"]>
    ): AppState => ({
      ...state,
      isOnboardingMode: action.payload,
    }),
    setModuleSettingsConfig: (
      state,
      action: PayloadAction<AppState["moduleAccessConfigurations"]>
    ): AppState => ({
      ...state,
      moduleAccessConfigurations: action?.payload,
    }),
  },
});

export const {
  updateTheme,
  changeSideBarPosition,
  changeCaddiSideBarPosition,
  setModal,
  setSecondaryModal,
  setLogedIn,
  setDrawer,
  setRouterPrompt,
  setAppType,
  setSessionExpired,
  setIsOnboardingMode,
  setModuleSettingsConfig,
} = appSlice.actions;

export default appSlice.reducer;
