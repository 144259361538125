import styled from "styled-components";

export const TrainbotFeedBackFormInputResponseStyled = styled.div`
  border: solid 1px ${({ theme }): string => theme?.borderColors?.GREY_56};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  padding: 16px;

  .username-initials-wrapper {
    width: 28px;
    min-width: 28px;
    height: 28px;
    font-size: 12px;
    margin-right: 10px;
  }

  .train-bot-feedback-form-input-res-title {
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
    margin-bottom: 12.8px;
    font-family: OpenSansSemibold;
  }

  .sql-query-preview-container {
    margin-bottom: 0;

    .title-copy-icon-wrapper {
      margin-top: 0;
    }

    .query-wrapper {
      min-height: 200px;
    }
  }

  .train-me-no-sql {
    color: ${({ theme }): string => theme?.textColors?.GREY_210};
  }

  .visualize-sub-box {
    border: 1px solid #e8e8e8;
    padding: 20px 16px;
    font-size: 16px;

    .sub-heading {
      color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
      font-family: OpenSansSemibold;
      margin-bottom: 5px;
    }

    .ant-card-bordered {
      border: none;
    }

    .ant-card-body {
      padding: 5px;
      overflow-y: hidden;
    }
  }
`;
