import { ColsInfoType } from "../../../../../../parsers/ruleparser/ruleparser.types";

export const getColumnNameById = (
  colsInfo: ColsInfoType,
  colId: number
): string => {
  const ruleAppliedOnColumn = colsInfo?.find(
    (column) => Number(column?.col_id) === Number(colId)
  );

  return ruleAppliedOnColumn?.col_name || "";
};

export const ruleTypeFromId = {
  MET: "Metric",
  BLK: "Blanks",
  CMM: "Compare Metric",
  CMS: "Compare Schema",
  CNT: "Count",
  CUQ: "Custom Query",
  DAF: "Data Format",
  FRC: "Freshness",
  ORK: "Orphan Keys",
  ORR: "Orphan Records",
  MDR: "Ruleset",
  UNQ: "Uniqueness",
  VLR: "Value Range",
  UNV: "Unique Values",
};

export const getMetricType = {
  AVG: "Average",
  CNT: "Count",
  MAX: "Maximum",
  MIN: "Minimum",
  SUM: "Sum",
  CD: "Distinct Count",
};

export const getComparisionType = {
  FUL: "Full",
  INN: "Inner",
  LFT: "Left",
  RGT: "Right",
};

export const getRangeType = {
  RV: "Range Value",
  SV: "Single Value",
  FD: "Field Dictionary",
  RD: "Ref Dictionary",
};

export const getValueType = {
  ABS: "Absolute Rule Value",
  REF: "Reference Column",
  EXP: "Expression",
};

export const getDataType = {
  DEC: "Decimal",
  INT: "Integer",
  STR: "String",
  NUM: "Whole Number",
};

export const getOperatorType = {
  GTH: "Greater Than",
  GTE: "Greater Than Equal",
  LTH: "Less Than",
  LTE: "Less Than Equal",
  EQL: "Equal To",
  NEQ: "Not Equal To",
};
