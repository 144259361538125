// <--- Library Imports Start --->
import styled from "styled-components";
import { ellipsis } from "../../../../../../styles/app.styles";
import { hexToRgbA } from "../../../../../../utils";
// <--- Library Imports End --->

const flexStyle = `
  display: flex;
  align-items: center;
`;

export const AnalysisDetailPageExplorerSecStyled = styled.div`
  height: 100%;

  .ant-menu.ant-menu-inline-collapsed {
    width: 100%;
    height: 100%;
    margin-right: 0;
    border: none;

    .explorer-header {
      height: 100%;
      align-items: self-start;
      justify-content: center;
    }
  }

  .explorer-header {
    ${flexStyle}
    justify-content: space-between;
    padding: 16px;
    border-bottom: solid 1px ${({ theme }): string => theme?.bgColors?.GREY_56};
    cursor: pointer;

    .heading {
      font-size: 14.4px;
      font-family: OpenSansSemiBold;
    }
  }

  .ant-collapse {
    .ant-collapse-item {
      background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};

      .ant-collapse-header {
        ${flexStyle}

        .collapse-header-content {
          width: 100%;
          ${flexStyle}
          column-gap: 8px;
          font-size: 13.6px;
          font-family: OpenSansSemiBold;

          svg {
            path {
              fill: ${({ theme }): string => theme?.textColors?.GREY_210};
            }
          }

          .collapse-header-content-title {
            ${flexStyle}
            column-gap: 8px;
            font-size: 13.6px;
            font-family: OpenSansSemiBold;
            flex: 1;

            .count {
              margin-top: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: ${({ theme }): string =>
                theme?.bgColors?.GREY_56};
              height: 18px;
              width: 18px;
              border-radius: 50px;
              font-size: 10px;
            }
            svg {
              path {
                fill: ${({ theme }): string => theme?.textColors?.GREY_210};
              }
            }
          }
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding-top: 0;
          padding-left: 22.4px;

          .dvsum-fallback-spinner {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            z-index: 1;
            background: ${({ theme }): string =>
              hexToRgbA(theme?.bgColors?.WHITE_COLOR, 0.5)};
            color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
          }

          .table-title-wrapper,
          .pinned-table-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              ${ellipsis}
              width: 80%;
              cursor: pointer;
            }

            .action-btns {
              display: flex;
              column-gap: 10px;

              .view-icon,
              .pin-icon,
              .unpin-icon {
                svg {
                  display: none;
                }
              }

              .view-icon {
                svg {
                  path {
                    stroke: ${({ theme }): string =>
                      theme?.borderColors?.BLUE_116};
                  }
                }
              }

              .pin-icon,
              .unpin-icon {
                svg {
                  path,
                  rect {
                    fill: ${({ theme }): string =>
                      theme?.borderColors?.BLUE_116};
                  }
                }
              }

              .unpin-icon {
                margin-top: 4px;
              }
            }
          }

          .pinned-table-title-wrapper {
            width: 100%;
          }

          .pinned-item {
            ${flexStyle}
            column-gap: 8px;
            padding: 4px 9.6px;

            .title {
              font-size: 14.4px;
              color: ${({ theme }): string => theme?.textColors?.BLUE_116};
            }

            &:hover {
              background: ${({ theme }): string =>
                hexToRgbA(theme?.borderColors?.BLUE_20, 0.5)};

              .table-title-wrapper {
                .action-btns {
                  svg {
                    display: block;
                  }

                  .unpinned-table {
                    svg {
                      path {
                        fill: ${({ theme }): string =>
                          theme?.borderColors?.WHITE_COLOR};
                        stroke: ${({ theme }): string =>
                          theme?.borderColors?.BLUE_116};
                        stroke-width: 2px;
                      }
                    }
                  }
                }
              }
            }
          }

          .explorer-collapse-item-content-container {
            padding: 8px 0px 8px 19.2px;
            border-left: 1px solid
              ${({ theme }): string => theme?.bgColors?.BLUE_116};

            .pinned-tables-blankstate {
              font-size: 12px;
              color: ${({ theme }): string => theme?.bgColors?.GREY_24};
              font-style: italic;
            }

            .pinned-item:not(:last-child) {
              margin-bottom: 8px;
            }

            &.table-browser-sec {
              .table-browser-search {
                border-radius: 3.2px;
                margin-bottom: 10px;

                .ant-input-prefix {
                  svg {
                    path {
                      fill: ${({ theme }): string =>
                        theme?.textColors?.BLUE_116};
                    }
                  }
                }

                .ant-input {
                  font-size: 12px;
                }

                .ant-input-suffix {
                  .ant-btn-link {
                    svg {
                      fill: ${({ theme }): string =>
                        theme?.textColors?.GREY_18};
                    }
                  }
                }
              }

              .no-schemas-state {
                .sec-blank-slate {
                  min-height: 0px;
                }
              }

              .ant-tree {
                /* max-height: 320px;
                overflow-y: auto; */

                .ant-tree-treenode {
                  .ant-tree-title {
                    ${ellipsis}
                    max-width: 96%;

                    .parent-title-container {
                      .parent-title {
                        ${ellipsis}
                        max-width: 90%;
                      }

                      .children-count {
                        color: ${({ theme }): string =>
                          theme?.textColors?.GREY_210};
                      }
                    }
                  }
                  .ant-tree-indent-unit-start,
                  .ant-tree-indent-unit-end,
                  .ant-tree-indent-unit {
                    &::before {
                      border-right: 1px solid
                        ${({ theme }): string => theme?.borderColors?.BLUE_116};
                    }
                  }

                  .ant-tree-node-selected {
                    background: white;
                  }

                  .ant-tree-switcher-noop {
                    display: none;
                  }

                  .ant-tree-node-content-wrapper-normal,
                  .ant-tree-icon__customize {
                    display: flex;
                    align-items: center;
                  }

                  .ant-tree-node-content-wrapper-normal {
                    color: ${({ theme }): string =>
                      theme?.borderColors?.BLUE_116};
                    padding: 4px 9.6px;
                    cursor: default;
                    width: 90%;

                    .ant-tree-title {
                      width: 96%;
                    }

                    &:hover {
                      background: ${({ theme }): string =>
                        hexToRgbA(theme?.borderColors?.BLUE_20, 0.5)};

                      .ant-tree-title {
                        .table-title-wrapper {
                          .action-btns {
                            svg {
                              display: block;
                            }

                            .unpinned-table {
                              svg {
                                path {
                                  fill: ${({ theme }): string =>
                                    theme?.borderColors?.WHITE_COLOR};
                                  stroke: ${({ theme }): string =>
                                    theme?.borderColors?.BLUE_116};
                                  stroke-width: 2px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .table-title-wrapper {
                      ::after {
                        background: ${({ theme }): string =>
                          theme?.bgColors?.BLUE_116};
                        position: absolute;
                        top: 0;
                        height: 36px;
                        content: " ";
                        width: 0.5px;
                        left: -13px;
                      }
                    }
                  }
                }

                .ant-tree-treenode-switcher-open {
                  .ant-tree-switcher_open {
                    svg {
                      path {
                        fill: ${({ theme }): string =>
                          theme?.textColors?.BLUE_116};
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        .ant-collapse-arrow {
          svg {
            path {
              fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
            }
          }
        }
      }
    }
  }
`;

export const TableAdded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-right: 10px;

  .text {
    color: green;
  }
`;
