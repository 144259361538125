import { Tooltip } from "antd";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../constants";
import { approveIcon } from "../../../../svgs";

import { MatchingStatusPreviewerStyled } from "./matchingstatuspreviewer.styles";
import { MatchingStatusPreviewerProps } from "./matchingstatuspreviewer.types";

function MatchingStatusPreviewer(
  props: MatchingStatusPreviewerProps
): JSX.Element {
  const { isApproved } = props;
  return (
    <MatchingStatusPreviewerStyled
      className="status-icon"
      role="button"
      {...props}
    >
      <Tooltip title="Approved" overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}>
        {isApproved ? (
          approveIcon("matching-status-approve")
        ) : (
          <div className="empty-icon" />
        )}
      </Tooltip>
    </MatchingStatusPreviewerStyled>
  );
}

export default MatchingStatusPreviewer;
