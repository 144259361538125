import { useForm } from "react-hook-form";
import {
  DynamicFormFieldProps,
  DynamicLogEventTypes,
  DynamicXhrConfigProps,
} from "../connectionsettingstabform.types";
import { getItemValue } from "../connectionsettingstabform.utils";

export function connectionSettingsLogger(
  logEventsConfig: DynamicXhrConfigProps["logEvents"],
  setFieldLogs: DynamicFormFieldProps["setFieldLogs"],
  logEventType?: DynamicLogEventTypes,
  form?: ReturnType<typeof useForm>,
  response?: any
): void {
  const { loading, success, failed, completed } = logEventsConfig;

  if (setFieldLogs && logEventType) {
    switch (logEventType) {
      case "loading":
        loading &&
          setFieldLogs((st) => [
            ...st,
            ...[getItemValue({ ...loading }, form)],
          ]);
        break;
      case "success":
        success &&
          setFieldLogs((st) => [
            ...st,
            ...[getItemValue({ ...success }, form)],
          ]);
        break;
      case "failed":
        failed &&
          setFieldLogs((st) => [
            ...st,
            ...[
              getItemValue({ ...failed }, form)?.concat(
                failed?.appendError ? response?.errorMessage ?? "" : ""
              ),
            ],
          ]);
        break;
      default:
        completed &&
          setFieldLogs((st) => [
            ...st,
            ...[getItemValue({ ...completed }, form)],
          ]);
        break;
    }
  }
}
