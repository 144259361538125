import { AxiosResponse } from "axios";
import { getTimeDifference, isJsonString, jsonParse } from "../../utils";
import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";
import {
  NodeSuggestionsParserReturnType,
  NodeSuggestionsResponse,
  SuggestionDetais,
} from "./nodesuggestionsparser.types";

export function getNodeSuggestionsList({
  data,
}: AxiosResponse<NodeSuggestionsResponse[]>): NodeSuggestionsParserReturnType {
  return {
    suggestionDetaislList: data?.map((item) => {
      const duration =
        getTimeDifference(utcTOLocalTimeZone(item?.CREATED_ON)) ?? "";
      const suggestionDetails: SuggestionDetais = jsonParse(
        item?.SUGG_DETAILS,
        true
      );

      return {
        id: `${item?.SUGG_ID || ""}`,
        suggestedBy: item?.CREATED_BY || "",
        name: suggestionDetails?.title || "",
        desc: suggestionDetails?.description || "",
        tags: (suggestionDetails?.tags ?? [])?.map((tag) => tag?.tag_name),
        duration,
        suggNodeId: `${item?.SUGG_NODE_ID || ""}`,
        isDeprecatedSuggestion: suggestionDetails?.status === "deprecate",
        comment: suggestionDetails?.comment || "",
      };
    }),
    suggestionsList: data?.map((item) => {
      const suggestedBy = `${item?.CREATED_BY || ""} suggested`;
      const time =
        getTimeDifference(utcTOLocalTimeZone(item?.CREATED_ON)) ?? "";

      return { value: `${suggestedBy} ${time}`, id: `${item?.SUGG_ID || ""}` };
    }),
  };
}
