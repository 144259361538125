import { Slider } from 'antd';
import styled from 'styled-components';

export const StyledSlider = styled(Slider)`
  .ant-slider-rail {
    height: 2px;
    background-color: #e8e8e8;
  }
  .ant-slider-track {
    height: 2px;
    background-color: #428bca;
  }
  .ant-slider-track {
    height: 2px;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    border: 2.5px solid #428bca;
  }
`;