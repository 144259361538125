import { DynamicAlertDataCheck } from "../connectionsettingstabform.types";
import { getDataByPattern } from "./getDataByPattern.util";

function getResponseCheckMissingData(
  patternedData: any,
  singleResponseCheck: DynamicAlertDataCheck
): any {
  if (singleResponseCheck?.value) {
    return singleResponseCheck?.value !== patternedData
      ? undefined
      : singleResponseCheck?.value;
  }

  return singleResponseCheck?.values?.filter(
    (val) => !patternedData?.includes(val)
  );
}

function getResponseCheckMatchedData(
  patternedData: any,
  singleResponseCheck: DynamicAlertDataCheck
): any {
  if (singleResponseCheck?.value) {
    return singleResponseCheck?.value === patternedData
      ? undefined
      : singleResponseCheck?.value;
  }

  return singleResponseCheck?.values?.filter((val) =>
    patternedData?.includes(val)
  );
}

function getStartWithData(
  patternedData: any,
  singleResponseCheck: DynamicAlertDataCheck
): any {
  if (singleResponseCheck?.value) {
    if (typeof patternedData === "string") {
      return (patternedData as String)?.startsWith(singleResponseCheck?.value);
    }

    return patternedData?.map(
      (item: string) =>
        singleResponseCheck?.value &&
        item?.startsWith(singleResponseCheck?.value)
    );
  }

  if (singleResponseCheck?.values) {
    if (typeof patternedData === "string") {
      return singleResponseCheck?.values?.some((item) =>
        (patternedData as String)?.startsWith(item)
      );
    }

    return patternedData?.map(() =>
      singleResponseCheck?.values?.every((item) =>
        (patternedData as String)?.startsWith(item)
      )
    );
  }

  return undefined;
}

export function getResponseCheckData(
  responseCheck?: DynamicAlertDataCheck[],
  response?: any
): string[] {
  return (
    (responseCheck &&
      response &&
      responseCheck
        ?.map((rCheck) => {
          const result = getDataByPattern(response, rCheck?.keyPattern);

          if (rCheck?.checkType) {
            switch (rCheck?.checkType) {
              case "DO_NOT_MATCH":
                return getResponseCheckMissingData(result, rCheck);
              case "END_WITH":
                return "";
              case "INCLUDE":
                return "";
              case "MATCH":
                return getResponseCheckMatchedData(result, rCheck);
              case "START_WITH":
                return getStartWithData(result, rCheck);
              default:
                return "";
            }
          }

          // if (rCheck?.checkDoNotMatch) {
          //   return getResponseCheckMissingData(result, rCheck);
          // }

          // if (rCheck?.checkDoMatch) {
          //   return getResponseCheckMatchedData(result, rCheck);
          // }

          return undefined;
        })
        ?.filter((value) => !!value)
        ?.flat()) ||
    []
  );
}
