import { ChartYAxisType } from "../../../../components/recharts/columnchart/columnchart.types";

export const BOT_ENGAGEMENTS_Y_AXIS: ChartYAxisType = [
  {
    label: {
      value: "Users",
      offset: -3,
    },
    orientation: "left",
    yAxisId: "Users",
    prefix: "",
    suffix: "",
    hide: false,
    domain: [0, "auto"],
    allowDecimals: false,
  },
  {
    label: {
      value: "Questions",
      offset: 60,
    },
    orientation: "right",
    yAxisId: "Questions",
    prefix: "",
    suffix: "",
    hide: false,
    domain: [0, "auto"],
    allowDecimals: false,
  },
];
