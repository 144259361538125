import { NodeType } from "../../app.types";
import { API_CONFIG } from "../../constants/apiconfig";

import { getParsedDataSourceFieldDoc } from "../../parsers/datasourcefieldparser/datasourcefieldparser";
import { ReturnTypeOfDataSourceFieledRefDocParser } from "../../parsers/datasourcefieldparser/datasourcefieldparser.types";

import { NodePublishedStatusIds } from "../../parsers/parser.types";
import { getFiltersfromLocalStorage } from "../../utils";
import { useGetAll } from "../baseservice";

import { CustomReactQueryHookReturnType } from "../services.types";

export function useGetDataSourceFieldRefDoc(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: NodePublishedStatusIds
): CustomReactQueryHookReturnType<ReturnTypeOfDataSourceFieledRefDocParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);

  return useGetAll({
    apiConfig: API_CONFIG.get_node,
    params: [nodeID, nodeType, nodeStatus, "true", "", parsedNodeFilter],
    parser: getParsedDataSourceFieldDoc,
  });
}
