import styled from 'styled-components';
import { CustomSelectProps } from './customselectdropdown.types';

export const CustumSelectStyled = styled.div<CustomSelectProps & { open: boolean }>`
  position: relative;
  .dropdown-toggle-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${(props): string => props.width || '467px'};
    height: 36px;
    padding: 0 0 0 8px;
    border: 1px solid ${(props): string => props.theme.borderColors.GREY_45};
    color: ${(props): string =>
      props.selectedValue ? props.theme.textColors.BLACK_50 : props.theme.textColors.BLACK_60};
    box-shadow: 0 0 3px white inset, 0 1px 1px rgb(0 0 0 / 10%);
    position: relative;
    border-bottom-color: ${(props): string => props.open && props.theme.borderColors.WHITE_COLOR};
    font-size: 13px;
    cursor: pointer;

    div:nth-of-type(2) {
      position: absolute;
      right: 7px;
      top: ${(props): string => (props.open ? '34%' : '19%')};
    }
  }
  .dropdown-section {
    position: absolute;
    top: 33px;
    left: 0px;
    width: ${(props): string => props.width || '467px'};
    background: ${(props): string => props.theme.bgColors.WHITE_COLOR};
    z-index: 1;
    border: 1px solid ${(props): string => props.theme.borderColors.GREY_45};
    border-top-color: ${(props): string => props.open && props.theme.borderColors.WHITE_COLOR};

    .chosen-search {
      padding: 3px 4px;
      .chosen-search-input {
        padding: 4px 7px 4px 5px;
        width: 100%;
        height: 25px;
        border: 1px solid ${(props): string => props.theme.borderColors.GREY_45};

        :hover,
        :focus,
        :active {
          border: 1px solid ${(props): string => props.theme.borderColors.GREY_45};
          box-shadow: none;
        }

        &.ant-input-affix-wrapper-focused {
          border: 1px solid ${(props): string => props.theme.borderColors.GREY_45};
          box-shadow: none;
        }

        .ant-input-suffix {
          svg {
            path {
              fill: ${(props): string => props.theme.bgColors.GREY_120};
            }
          }
        }
      }
    }

    .dropdown-options {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: 4px;
      max-height: 240px;

      .dropdown-option {
        padding: 1px 6px;
        cursor: pointer;
        color: ${(props): string => props.theme.textColors.GREY_90};
        height: 25px;
        font-size: 13px;
        :hover {
          background: ${(props): string => props.theme.bgColors.BLUE_110};
          color: ${(props): string => props.theme.textColors.WHITE_COLOR};
        }
      }

      .no-result-match {
        background: ${(props): string => props.theme.bgColors.GREY_115};
        padding: 1px 6px;
        font-size: 13px;
      }
    }
  }
`;
