import styled from "styled-components";

export const ColumnInfoContentStyled = styled.div`
  width: 500px;
  height: 330px;
  padding: 20px;
  max-width: 500px;
  box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
  background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  border-radius: 8px;
  border: 1px solid ${({ theme }): string => theme.borderColors.BLUE_116};
  max-height: 340px;
  overflow-y: auto;

  .title {
    width: 160px;
  }

  .value {
    width: 280px;
    color: black;
  }

  .row {
    width: 500px;
  }

  .title-main {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .group {
    margin-bottom: 10px;
  }

  .main-group {
    width: 100%;
  }

  .icon-datatype {
    cursor: pointer;
  }
`;
