import styled from "styled-components";

export const JobApiInfoPreviewerStyled = styled.div``;

export const RequestBodyStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
  font-size: 13.6px;

  .url {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 13.6px;
  }
`;
