// <--- Styles Start --->
import styled from "styled-components";
// <--- Styles End --->

export const AnalysisDetailPageHeaderWithNoSelectionStyled = styled.div`
  .analysis-header-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 53px;

    .refresh-confirmation-wrapper,
    .delete-confirmation-wrapper {
      display: flex;
      justify-content: end;
      width: 100%;
    }
  }
`;
