import { API_CONFIG } from "../../constants/apiconfig";
import { getParsedDomains } from "../../parsers/accountsettings/accountsettingsparser";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const { get_domains: getDomains } = API_CONFIG;

export function useGetAllDomains(): CustomReactQueryHookReturnType<string[]> {
  return useGetAll({
    apiConfig: getDomains,
    parser: getParsedDomains,
  });
}
