import { NodeType } from "../app.types";
import { ELEMENT_IDS } from ".";

const {
  anldict_lst_cre_btn: ANLDICT_LST_CRE_BTN,
  anldict_lst_exp_btn: ANLDICT_LST_EXP_BTN,
  anldict_lst_getvie_btn: ANLDICT_LST_GETVIE_BTN,
  anldict_lst_edi_btn: ANLDICT_LST_EDI_BTN,
  anldict_lst_del_btn: ANLDICT_LST_DEL_BTN,
  anldict_lst_hig_tab: ANLDICT_LST_HIG_TAB,
  anldict_lst_masupdtag_btn: ANLDICT_LST_MASUPDTAG_BTN,
  anldict_lst_masupd_btn: ANLDICT_LST_MASUPD_BTN,
  anldict_lst_shr_btn: ANLDICT_LST_SHR_BTN,
  datdict_lst_cre_btn: DATDICT_LST_CRE_BTN,
  datdict_lst_del_btn: DATDICT_LST_DEL_BTN,
  datdict_lst_edit_btn: DATDICT_LST_EDIT_BTN,
  datdict_lst_exp_btn: DATDICT_LST_EXP_BTN,
  datdict_lst_getvw_btn: DATDICT_LST_GETVW_BTN,
  datdict_lst_hlt_tab: DATDICT_LST_HLT_TAB,
  datdict_lst_masupdtag_btn: DATDICT_LST_MASUPDTAG_BTN,
  datdict_lst_masupd_btn: DATDICT_LST_MASUPD_BTN,
  datdict_lst_shr_btn: DATDICT_LST_SHR_BTN,
} = ELEMENT_IDS;

export const VIEW_NAME_ELEM_ID: { [key in NodeType]?: string } = {
  DSR: ANLDICT_LST_GETVIE_BTN,
  TBL: DATDICT_LST_GETVW_BTN,
};

export const CREATE_VIEW_NAME_ELEM_ID: { [key in NodeType]?: string } = {
  DSR: ANLDICT_LST_CRE_BTN,
  TBL: DATDICT_LST_CRE_BTN,
};

export const EXP_VIEW_NAME_ELEM_ID: { [key in NodeType]?: string } = {
  DSR: ANLDICT_LST_EXP_BTN,
  TBL: DATDICT_LST_EXP_BTN,
};

export const SHR_VIEW_NAME_ELEM_ID: { [key in NodeType]?: string } = {
  DSR: ANLDICT_LST_SHR_BTN,
  TBL: DATDICT_LST_SHR_BTN,
};

export const EDIT_VIEW_NAME_ELEM_ID: { [key in NodeType]?: string } = {
  DSR: ANLDICT_LST_EDI_BTN,
  TBL: DATDICT_LST_EDIT_BTN,
};

export const DELETE_VIEW_ELEM_ID: { [key in NodeType]?: string } = {
  DSR: ANLDICT_LST_DEL_BTN,
  TBL: DATDICT_LST_DEL_BTN,
};

export const LISTING_PAGE_ALL_TILE_ID_MAPPING: {
  [key in NodeType]?: string;
} = {
  DSR: ANLDICT_LST_HIG_TAB,
  TBL: DATDICT_LST_HLT_TAB,
};

export const LISTING_MASS_UPDATE_ELEM_ID: {
  [key in NodeType]?: string;
} = {
  DSR: ANLDICT_LST_MASUPD_BTN,
  TBL: DATDICT_LST_MASUPD_BTN,
};

export const LISTING_UPD_TAGS_ELEM_ID: {
  [key in NodeType]?: string;
} = {
  DSR: ANLDICT_LST_MASUPDTAG_BTN,
  TBL: DATDICT_LST_MASUPDTAG_BTN,
};
