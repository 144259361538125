// <--- Library Imports Start --->
import { yupResolver } from "@hookform/resolvers/yup";

import { ChangeEvent } from "react";
import { useForm, FormProvider } from "react-hook-form";

// <--- Library Imports End --->

// <--- Components Start --->
import MessageInputField from "../../../../components/formfields/messageinputfield/messageinputfield";
// <--- Components End --->

// <--- Styles Start --->
import { EditQueryQuestionFormStyled } from "./editqueryquestionform.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { crossIconInCircle } from "../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { EditQueryQuestionFormProps } from "./editqueryquestionform.types";
import { messageInputFieldFormSchema } from "../../../../utils/schemas/messageinputfieldformschema";
// <--- Types End --->

const EditQueryQuestionForm = (
  props: EditQueryQuestionFormProps
): JSX.Element => {
  const {
    defaultTitle = "",
    queryNodeId = "",
    editedQueryValue = "",
    onEditingQuery,
    handleCancel,
    onSubmit,
  } = props;

  const formProvider = useForm({
    defaultValues: {
      text: defaultTitle,
    },
    mode: "onChange",
    resolver: yupResolver(messageInputFieldFormSchema),
  });

  const onQueryTextEditing = (e?: ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target?.value?.replaceAll("\n", " ")?.trim() || "";
    onEditingQuery?.(queryNodeId, value);
  };

  return (
    <EditQueryQuestionFormStyled data-testid="analysis-detail-page-query-block-edit-query-question-form-main-wrapper">
      <FormProvider {...formProvider}>
        <MessageInputField
          name="text"
          placeholder=""
          isSubmitButtonDisabled={editedQueryValue === defaultTitle}
          onSubmit={onSubmit}
          propOnChange={onQueryTextEditing}
          sendIconPlacement="top"
        />
      </FormProvider>

      <div
        className="cross-icon-wrapper"
        data-testid="analysis-detail-page-query-block-edit-query-question-form-cross-btn"
        role="button"
        onClick={handleCancel}
      >
        {crossIconInCircle()}
      </div>
    </EditQueryQuestionFormStyled>
  );
};

export default EditQueryQuestionForm;
