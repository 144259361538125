import { Tooltip } from "antd";
import { useCallback } from "react";

import { useController } from "react-hook-form";
import { InputFieldStyled } from "../../../../components/formfields/inputfield/inputfield.styles";

import { InputFieldContainerStyled } from "./gridinputfield.styles";
import { InputFieldType } from "./gridinputfield.types";

const GridInputField = ({
  width,
  name = "",
  control,
  propOnChange,
  tooltipClassName,
  tooltipPlacement,
  ...props
}: InputFieldType): JSX.Element => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = useCallback(
    (event) => {
      inputProps?.onChange(event);
      propOnChange && propOnChange(event);
    },
    [inputProps]
  );

  return (
    <InputFieldContainerStyled
      isError={!!error?.message}
      className="input-field-container"
    >
      <Tooltip
        placement={tooltipPlacement || "topRight"}
        title={error?.message}
        overlayClassName={tooltipClassName || "dvsum-error-tooltip"}
      >
        <InputFieldStyled
          {...props}
          {...inputProps}
          onChange={handleChange}
          ref={ref}
          width={width}
          title=""
        />
      </Tooltip>
    </InputFieldContainerStyled>
  );
};

export default GridInputField;
