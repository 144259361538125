import * as yup from "yup";
import {
  CHARACTERS_LIMIT_EXCEEDED,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";

export const ssaSaveAnalysisFormSchema = yup.object().shape({
  title: yup
    .string()
    ?.trim()
    ?.required(REQUIRED_MESSAGE)
    ?.max(100, CHARACTERS_LIMIT_EXCEEDED(100)),
  desc: yup.string()?.max(10000, CHARACTERS_LIMIT_EXCEEDED(10000)),
});

export const ssaSaveAnalysisRequiredFormSchema = yup.object().shape({
  title: yup
    .string()
    ?.trim()
    ?.required(REQUIRED_MESSAGE)
    ?.max(100, CHARACTERS_LIMIT_EXCEEDED(100)),
  desc: yup
    .string()
    ?.required(REQUIRED_MESSAGE)
    ?.max(10000, CHARACTERS_LIMIT_EXCEEDED(10000)),
});
