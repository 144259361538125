import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useGetAppState } from ".";
import { setDrawer } from "../reducers/appreducer/appreducer";

export function useCancelDrawer(): () => void {
  const {
    drawer: { drawerId, isStartFromTop },
  } = useGetAppState();
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setDrawer({ drawerId, isStartFromTop }));
  }, [drawerId]);
}
