import { useMemo } from "react";
import { useGetAppState } from "../../customhooks";

import { maximizeIcon, minimizedIcon } from "../../svgs";
import { TableViewDrawerStyled } from "./tableviewdrawer.styles";

import Columnsampledata from "../../components/detailpagesdatatab/views/columnview/columnsampledata/columnsampledata";
import { TableViewDrawerType } from "./tableviewdrawer.types";

import LinkButton from "../../components/linkbutton/linkbutton";

const maximizedIcon = maximizeIcon();
const minimizeIcon = minimizedIcon();

const TableViewDrawer = (): JSX.Element => {
  const { drawer } = useGetAppState();

  const { drawerProps = {}, isMaximized } = drawer || {};

  const {
    tableName = "",
    tableId = "",
    changeTableView,
  } = drawerProps as TableViewDrawerType;

  const memoizedSampleDataView = useMemo(
    () => (
      <Columnsampledata
        tableId={tableId}
        isShowChart
        isUsingOutsideTableDetailPage
      />
    ),
    [tableId]
  );

  return (
    <TableViewDrawerStyled>
      <div className="table-view-header">
        <p className="heading">
          <div className="bold">Preview:</div> {tableName}
        </p>
        <LinkButton onClick={(): void => changeTableView(!isMaximized)}>
          {isMaximized ? minimizeIcon : maximizedIcon}
        </LinkButton>
      </div>
      <div className="table-data">{memoizedSampleDataView}</div>
    </TableViewDrawerStyled>
  );
};
export default TableViewDrawer;
