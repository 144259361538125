import styled from "styled-components";
import { ProgressProps } from "./customprogresbar.types";

export const ProgressBarStyled = styled.div<ProgressProps>`
  width: ${({ width }): any => width};
  height: ${({ height }): any => height};
  background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE};

  .progress-inner {
    width: ${({ value }): string => `${value}%`};
    height: ${({ height }): any => height};
    background: #328db5;
    padding-right: 7px;
    font-size: ${({ showPercentage }): any =>
      showPercentage ? "17px" : "0px"};
    color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;
