import { AxiosResponse } from "axios";
import moment from "moment";

import { clockIcon } from "../../svgs";
import { renderItem, renderTitle } from "./searchwithdropdown.utils";

import {
  MenuItemsGroupedType,
  ParsedDataForSearchType,
} from "./searchwithdropdown.types";

import { APP_ROUTES } from "../../constants";
import {
  createDefaultEncodedParamsForGuidedSearch,
  getDetailpageUrlBasedOnNodeType,
} from "../../utils";
import { NodeSubType, NodeType } from "../../app.types";
import { getModuleLevelAccessInfo } from "../../utils/getModuleLevelAccessInfo";

export interface ISearchParseProps {
  searchText: string;
  data: {
    description: string;
    node_id: number;
    node_name: string;
    node_type: NodeType;
    node_sub_type: NodeSubType;
    parent_name: string;
    score: number;
    updated_on: string;
    record_count: string;
    node_title: string;
  }[];
}

export const getRecentSearchKeyWords = ({
  data,
}: AxiosResponse<
  {
    LSI1_UPDATED_ON: string;
    NODE_NAME: string;
  }[]
>): MenuItemsGroupedType[] => {
  const { hasCatalogAccess } = getModuleLevelAccessInfo();

  const getRoute = (text: string): string => {
    const encodedSearchObject = createDefaultEncodedParamsForGuidedSearch(text);
    return `${APP_ROUTES.private_routes.enterprise_search}/${
      hasCatalogAccess ? "tables" : "terms"
    }/${encodedSearchObject}`;
  };
  return [
    {
      label: renderTitle("Recent"),
      options:
        data?.map((item) =>
          renderItem({
            title: item?.NODE_NAME || "",
            icon: clockIcon("16px", "16px"),
            route: getRoute(item?.NODE_NAME || ""),
            key: `${item?.LSI1_UPDATED_ON}:${item?.NODE_NAME}`,
          })
        ) || [],
    },
  ];
};

export const getParsedDataForSearch = (
  props: ISearchParseProps
): ParsedDataForSearchType => {
  const encodedSearchObject = createDefaultEncodedParamsForGuidedSearch(
    props?.searchText
  );

  const { hasCatalogAccess } = getModuleLevelAccessInfo();

  return props?.data
    ? [
        {
          ...renderItem({
            title: props?.searchText,
            icon: clockIcon("16px", "16px"),
            route: `${APP_ROUTES.private_routes.enterprise_search}/${
              hasCatalogAccess ? "tables" : "terms"
            }/${encodedSearchObject}`,
            isSearchText: true,
            key: `${Date.now()}-${props?.searchText}`,
          }),
        },
        {
          label: renderTitle("Recommended Searches"),
          options: props?.data?.map((searchItem) => {
            const subText = (): string => {
              let text = searchItem?.parent_name;
              text += searchItem?.record_count
                ? ` • ${searchItem?.record_count} `
                : "";
              text += searchItem?.updated_on
                ? ` • ${moment
                    .utc(searchItem?.updated_on)
                    .local()
                    .format("MM/DD/YYYY hh:mm:ss A")}`
                : "";
              return text;
            };
            return renderItem({
              title: searchItem?.node_title || "",
              nodeType: searchItem?.node_type,
              nodeSubType: searchItem?.node_sub_type,
              route: getDetailpageUrlBasedOnNodeType(
                `${searchItem.node_id}`,
                searchItem?.node_type,
                searchItem?.node_sub_type
              ),
              subText: subText(),
              key: `${searchItem?.node_id}`,
            });
          }),
        },
      ]
    : [];
};
