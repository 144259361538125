import { useCallback, useEffect } from "react";
import { IHeaderParams } from "ag-grid-community";

import Flex from "../../../../../../../../../../../../components/flex/flex";

import NodeStatusRendrer from "../../../../../../../../../../../../components/nodestatusrendrer/nodestatusrendrer";

import { QueryResultsGridHeaderRendrerStyled } from "./queryresultsgrid.styles";

import { ResultGridHeaderParams } from "./queryresultsgrid.types";

import {
  checkDataTypeOfColumn,
  getDataTypeIcon,
} from "../../../../../../../../../../../../utils";

import {
  gridSortAscIcon,
  gridSortDescIcon,
} from "../../../../../../../../../../../../svgs";

export const QueryResultsGridHeaderRendrer = (
  props: IHeaderParams
): JSX.Element => {
  const { column } = props;
  const params = (column?.getColDef?.()?.headerComponentParams ||
    {}) as ResultGridHeaderParams;

  const {
    col_status: colStatus = "",
    col_status_id: colStatusId,
    col_type_id: colTypeId,
    column_display_name: columnDisplayName = "",
    data_type: dataType,
    data_type_info: dataTypeInfo = "",
    is_selected: isSelected = false,
    column_name: columnName = "",
    query_id: queryId = "",
    applied_filters: appliedFilters = {},
    transiant_filters: transiantFilters = {},
    onChangeQueryColumnSorting,
    sortModel,
    query_type: queryType,
    isPivotView = false,
    onChangeQuerySettings,
  } = params;

  const { isNumberCol } = checkDataTypeOfColumn(dataType);

  const isFilterApplied =
    queryType === "CCQ"
      ? transiantFilters?.[columnName]?.filter_model
      : transiantFilters?.[columnName]?.filter_model ||
        appliedFilters?.[columnName]?.filter_model;

  const currentColSortDir =
    sortModel?.colId === columnName ? sortModel?.sort : "";

  useEffect(() => {
    props?.api?.refreshCells({ force: true });
  }, [isSelected, isFilterApplied]);

  const onColumnNameClick = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      onChangeQuerySettings?.({
        e,
        id: queryId,
        columnName,
        type: "col_selection",
        tab: "field",
      });
    },
    [queryId, columnName, onChangeQuerySettings]
  );

  return (
    <QueryResultsGridHeaderRendrerStyled
      isApplied={!!isFilterApplied}
      isSelected={isSelected}
      onClick={(): void => {
        onChangeQueryColumnSorting?.(queryId, columnName);
      }}
      isNumberOrMetricCol={isNumberCol || colTypeId === "MET"}
    >
      {isPivotView ? (
        <Flex columnGap={5}>
          {props?.displayName}{" "}
          {currentColSortDir === "asc"
            ? gridSortAscIcon
            : currentColSortDir === "desc"
            ? gridSortDescIcon
            : ""}
        </Flex>
      ) : (
        <Flex columnGap={4}>
          {dataType && (
            <div title={dataTypeInfo} className="data-type-icon">
              {getDataTypeIcon(dataType)}
            </div>
          )}
          <div
            className="col-name"
            role="button"
            onClick={(e): void => onColumnNameClick(e)}
          >
            {columnDisplayName}
          </div>
          <NodeStatusRendrer
            status={colStatusId}
            tooltipTitle={colStatus}
            nodeType="COL"
          />
          {currentColSortDir === "asc"
            ? gridSortAscIcon
            : currentColSortDir === "desc"
            ? gridSortDescIcon
            : ""}
        </Flex>
      )}
    </QueryResultsGridHeaderRendrerStyled>
  );
};
