import styled from "styled-components";

export const UpdateTagsStyled = styled.div<{ isRemovedView?: boolean }>`
  .ant-form {
    .content-sec {
      padding: ${({ isRemovedView }): string =>
        isRemovedView ? "70px 0px 70px" : "50px 0px 38px"};
      max-height: 456px;
      overflow-y: auto;

      .ant-form-item-control-input-content {
        .ant-checkbox-wrapper {
          align-items: flex-start;

          .ant-checkbox {
            width: 14px !important;
            height: 14px !important;

            .ant-checkbox-input,
            .ant-checkbox-inner {
              width: inherit !important;
              height: inherit !important;
            }

            .ant-checkbox-inner {
              &::after {
                border-width: 2px !important;
                width: 4.5px !important;
                height: 7px !important;
              }
            }
          }

          span:nth-of-type(2) {
            padding-left: 5px !important;
          }
        }
      }

      .overwrite-form-item {
        margin-bottom: 10px;
      }

      .note {
        padding-left: 100px;

        div {
          gap: 6px;

          .iwt-icon {
            svg {
              height: 11px;

              path {
                fill: ${({ theme }): string => theme.textColors.GREY_COLOR_12};
              }
            }
          }

          .iwt-icon-text {
            color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
            font-style: italic;
            font-size: 13px;
            opacity: 0.7;
            max-width: 359px;
          }
        }
      }
    }
  }
`;
