import { APP_ROUTES } from "../constants";
import { checkIsChatPage } from "./ischatpage";

export const checkIsViewOnlyChatQuestionPage = (): boolean => {
  const pathname = window?.location?.pathname || "";
  const isChatPage = checkIsChatPage();

  try {
    return (
      isChatPage &&
      pathname?.includes(APP_ROUTES.private_routes.view_only_chat_question)
    );
  } catch (e) {
    return false;
  }
};
