import styled from "styled-components";

export const AnalysisOverviewSecStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19.2px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;

  .overview-content {
    width: 100%;
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};

    .shared-with-container {
      display: flex;
      column-gap: 33.6px;

      .users-container {
        display: flex;
        column-gap: 19.2px;
        flex-wrap: wrap;
        row-gap: 8px;

        .user {
          display: flex;
          align-items: center;
          column-gap: 8px;

          svg {
            path {
              fill: #d2d2d2;
            }
          }
        }
      }
    }
  }

  .cross-icon-container {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }
`;
