import { useCallback } from "react";
import { useClipboard } from "use-clipboard-copy";
import { openNotification } from "../utils";

export function useCopyToClipboard(): (_text: string) => void {
  const clipboard = useClipboard({
    copiedTimeout: 500,
    onSuccess() {
      openNotification("Copied to clipboard.");
    },
  });

  return useCallback(
    (text) => {
      clipboard.copy(text);
    },
    [clipboard.copy]
  );
}
