import {
  AddDataFormatFormApiDataType,
  AddDataFormatFormType,
} from "./adddataformatform.types";

export const dataFormatFormDataToApiData = (
  formData: AddDataFormatFormType
): AddDataFormatFormApiDataType => {
  return {
    rule_type: "DAF",
    col_id: Number(formData?.column),
    pattern: formData?.pattern,
    regex: formData?.regex,
  };
};
