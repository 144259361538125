export const attributeOptions = [
  { label: "Address", value: "address" },
  { label: "Birthdate", value: "birthdate" },
  { label: "Email", value: "email" },
  { label: "Email Verified", value: "email_verified" },
  { label: "Family Name", value: "family_name" },
  { label: "Gender", value: "gender" },
  { label: "Given Name", value: "given_name" },
  { label: "Locale", value: "locale" },
  { label: "Middle Name", value: "middle_name" },
  { label: "Name", value: "name" },
  { label: "Nickname", value: "nickname" },
  { label: "Phone Number", value: "phone_number" },
  { label: "Picture", value: "picture" },
  { label: "Preferred User Name", value: "preferred_user_name" },
  { label: "Profile", value: "profile" },
  { label: "Updated At", value: "updated_at" },
  { label: "Username", value: "username" },
  { label: "Website", value: "website" },
  { label: "Zone Info", value: "zone_info" },
];

export const configsSavedText = "Idp configurations are saved";
