import { getChatPrefrencesData } from "./getchatprefrencesdata";

export const isUserPrefferedPercentageMetricColumn = (
  name: string,
  listOfKeywords?: string[]
): boolean => {
  const { metric_format: metricFormat } = getChatPrefrencesData();

  const { percentage_cols_list: percentageColsList = [] } = metricFormat || {};

  const listWithoutUnderScore = (
    listOfKeywords || percentageColsList
  )?.map((item) => item?.toLocaleLowerCase()?.replaceAll(/_/g, " "));

  try {
    const nameWithoutUnderscore = name
      ?.toLocaleLowerCase()
      ?.replaceAll(/_/g, " ");

    return (
      !!listWithoutUnderScore?.includes(nameWithoutUnderscore) &&
      !!listWithoutUnderScore?.length
    );
  } catch {
    return false;
  }
};
