import { TagStyled } from "./simpletag.styles";
import { SimpleTagProps } from "./simpletag.types";

function SimpleTag(props: SimpleTagProps): JSX.Element {
  const { className, title } = props;

  return (
    <TagStyled {...props} className={className}>
      <div
        className="content"
        title={
          title || (typeof props?.children === "string" ? props?.children : "")
        }
      >
        {props?.children}
      </div>
    </TagStyled>
  );
}

export default SimpleTag;
