export function convertEpocTimeToMilliseconds(
  epocTime: number,
  valueType?: "seconds" | "milliseconds"
): number {
  try {
    const updatedEpocTime =
      valueType === "milliseconds" ? epocTime / 1000 : epocTime;

    return new Date(0).setSeconds(updatedEpocTime);
  } catch {
    return 0;
  }
}
