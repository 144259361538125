import styled from "styled-components";

export const ExcelSourceFileEmptyStateStyled = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .heading-static-text {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    font-family: OpenSansBold;
    font-size: 20px;
    margin-top: 10px;
  }

  .heading-static-desc {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    margin-bottom: 15px;
  }
`;
