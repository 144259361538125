import { useMemo } from "react";

import { helpingText } from "./helpingtextsection.types";

import { HelpingTextSectionStyled } from "./helpingtextsection.styles";

const HelpingTextSection = (): JSX.Element => {
  const { firstSection, secondSection } = helpingText || {
    firstSection: [],
    secondSection: [],
  };

  const renderFirstSection = useMemo(() => {
    return firstSection.map((item, index) => (
      <div key={index} className="helping-item">
        <div className="title">{item?.title}</div>
        <div className="description">{item?.description}</div>
        {item?.points && (
          <ul className="points">
            {item.points.map((point, index) => (
              <li key={`${point}-${index}`}>{point}</li>
            ))}
          </ul>
        )}
      </div>
    ));
  }, [firstSection]);

  const renderSecondSection = useMemo(() => {
    return secondSection.map((item, index) => (
      <div key={index} className="helping-item">
        <div className="title">{item?.title}</div>
        <div className="description">{item?.description}</div>
        {item?.points && (
          <div className="points">
            {item.points.map((point, index) => (
              <div className="point" key={`${point}-${index}`}>
                <div className="point-heading">{point?.title}</div>
                <div className="point-description">{point?.description}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  }, [secondSection]);

  return (
    <HelpingTextSectionStyled>
      <div className="first-sec">{renderFirstSection}</div>
      <div className="second-sec">{renderSecondSection}</div>
    </HelpingTextSectionStyled>
  );
};

export default HelpingTextSection;
