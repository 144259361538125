import styled from "styled-components";
import LinkButton from "../../../linkbutton/linkbutton";

import { ellipsis } from "../../../../styles/app.styles";

export const UpdateScheduleModalStyled = styled.div`
  padding-top: 30px;
`;

export const UpdateScheduleModalContentStyled = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 80px;

  .description {
    font-size: 14px;
    color: ${({ theme }): string => theme.textColors.GREY_40};
    margin-bottom: 15px;
  }

  .input-label {
    font-size: 14px;
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme.textColors.GREY_40};
    margin-bottom: 10px;
  }

  .required {
    color: ${({ theme }): string => theme.textColors.RED};
  }

  .create-new-job-btn {
    font-size: 14px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
  }

  .ant-select {
    width: 100%;

    .ant-select-selection-placeholder {
      padding-top: 3px;
    }

    .ant-select-selector {
      height: 42px;
      max-height: 42px;
      overflow-y: auto;
    }

    .ant-select-item-option-grouped {
      padding-left: 12px;
    }

    .ant-select-item-option-state {
      svg {
        display: none;
      }
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;

      .ant-select-selection-item-content {
        .job-link,
        .separator,
        .job-name {
          display: none;
        }
      }
    }
  }

  .job-details-wrapper {
    display: flex;
    justify-content: space-between;

    .job-name {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    }
  }
`;

export const JobDetailWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 4px;
  /* padding-right: 10px; */
  /* padding-left: 10px; */
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 20px;

  .job-name {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    ${ellipsis}
  }

  .flex-grow {
    flex-grow: 1;
  }

  .job-link {
    margin-top: 7px;
  }
`;

export const CreateScheduleButton = styled(LinkButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  margin: 8px 12px;
  font-family: OpenSansSemiBold;
`;
