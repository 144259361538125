import { QueryObserverResult } from "react-query";

import { useGetAll } from "../baseservice";

import { API_CONFIG } from "../../constants/apiconfig";

import {
  dataSourceRefNodeDocParser,
  getParsedDataSourceDocForDetailPage,
  getParsedReportFields,
  getParsedReportPages,
  getParsedSimilarNodes,
} from "../../parsers/datasourcesparsers/datasourcesparsers";

import { GetClassificationCustomTagsAddInfo } from "../../parsers/parserutils/getclassificationcustomtags";

import {
  ParsedDataSourceDocForDetailPage,
  RetunTypeOfReportPagesParser,
  ReturnTypeOfDataSouurceParser,
  ReturnTypeOfParsedReportFields,
  SimilarNodesParserReturnType,
} from "../../parsers/datasourcesparsers/datasourcesparsers.types";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { TagsData } from "../../components/tagseditor/tagseditor.types";
import { NodePublishedStatusIds } from "../../parsers/parser.types";
import { CustomReactQueryHookReturnType } from "../services.types";
import { NodeSubType, NodeType } from "../../app.types";
import { getFiltersfromLocalStorage } from "../../utils";
import { getNodeTypeTAGContextParser } from "../../parsers/tablepage/tablepageparser";

const {
  get_node_tags: getTags,
  get_node: getNode,
  get_report_pages: getReportPages,
  get_report_fields: getReportFields,
  get_similar_nodes: getSimilarNodes,
} = API_CONFIG;

export function useGetDataSourceRefDoc(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: NodePublishedStatusIds
): CustomReactQueryHookReturnType<ReturnTypeOfDataSouurceParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);

  return useGetAll({
    apiConfig: getNode,
    params: [nodeID, nodeType, nodeStatus, "true", "", parsedNodeFilter],
    parser: dataSourceRefNodeDocParser,
  });
}

export function useGetDataSourceDocForDetailPage(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: "PUB" | "DFT",
  enabled?: boolean
): CustomReactQueryHookReturnType<ParsedDataSourceDocForDetailPage> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
  return useGetAll<ParsedDataSourceDocForDetailPage>({
    apiConfig: getNode,
    params: [nodeID, nodeType, nodeStatus, "", "", parsedNodeFilter],
    parser: getParsedDataSourceDocForDetailPage,
    options: {
      enabled: nodeStatus === "PUB" ? true : !!enabled,
    },
  });
}

export function useGetClassificationDataForDataSource(
  id: string,
  isDraftExist: boolean,
  enabled: boolean
): {
  parsedData: TagsData;
  isLoading: boolean;
  error: any;
  refetch: () => Promise<QueryObserverResult<unknown, unknown>>;
} {
  const { parsedData } = useGetDataSourceDocForDetailPage(
    id,
    "DSR",
    isDraftExist ? "DFT" : "PUB"
  );

  const getClassificationRow = (tagsetId: string): SectionRowProps =>
    parsedData?.classification?.data?.find(
      (item) =>
        (item?.additionalInfo as GetClassificationCustomTagsAddInfo)
          ?.tagsetId === tagsetId
    ) || { title: "" };

  const {
    parsedData: parsedTags = {},
    isLoading,
    error,
    refetch,
  } = useGetAll<TagsData>({
    apiConfig: getTags,
    params: ["DSR"],
    options: {
      enabled,
    },
    parser: getNodeTypeTAGContextParser,
  });

  return {
    parsedData: {
      ...Object.keys(parsedTags)?.reduce((prev, item) => {
        const row = getClassificationRow(item);
        const { tagsetConfig = {} } = (row?.additionalInfo ||
          {}) as GetClassificationCustomTagsAddInfo;

        const {
          tagset_name: tagsetName,
          is_comment_required: isCommentRequired,
          is_multi_value_allowed: isMultiValueAllowed,
          is_new_value_allowed: isNewValueAllowed,
          tagset_type: tagsetType,
        } = tagsetConfig?.[item] || {};

        const isSelected = (val: string): boolean => {
          const combinedTags = [
            ...(row?.tags || []),
            ...(row?.prominentTags?.tags?.map((promTag) => promTag?.name) ||
              []),
          ];
          return combinedTags?.length
            ? combinedTags?.includes(val)
            : val === row?.value;
        };
        return {
          ...prev,
          [tagsetName]: {
            ...parsedTags?.[item],
            isMultiple: !!isMultiValueAllowed,
            isNewValueAllowed: !!isNewValueAllowed,
            isNoteRequired: !!isCommentRequired,
            tagsetType,
            tagsData: parsedTags?.[item]?.tagsData?.map((nestItem) => ({
              ...nestItem,
              selected: isSelected(nestItem?.label),
              isAdded: isSelected(nestItem?.label),
              comment: row?.prominentTags?.tags?.find(
                (item) => item?.id === nestItem?.value
              )?.tagDetails?.tagNote,
            })),
          },
        };
      }, {}),
    },
    isLoading,
    error,
    refetch,
  };
}

export function useGetReportPages(
  nodeId: string
): CustomReactQueryHookReturnType<RetunTypeOfReportPagesParser> {
  return useGetAll({
    apiConfig: getReportPages,
    params: [nodeId],
    parser: getParsedReportPages,
  });
}

export function useGetReportFields(
  nodeId: string
): CustomReactQueryHookReturnType<ReturnTypeOfParsedReportFields[]> {
  return useGetAll({
    apiConfig: getReportFields,
    params: [nodeId],
    parser: getParsedReportFields,
  });
}

export const useGetSimilarNodes = (
  nodeId: string,
  nodeSubType: NodeType | NodeSubType
): CustomReactQueryHookReturnType<SimilarNodesParserReturnType[]> => {
  return useGetAll({
    apiConfig: getSimilarNodes,
    params: [nodeId, nodeSubType],
    parser: getParsedSimilarNodes,
  });
};
