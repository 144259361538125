import { Collapse } from "antd";
import styled from "styled-components";

export const DQRuleDetailsModalDetailsSecStyled = styled.div`
  width: 90%;

  .sec-row {
    .main-row {
      .row-title {
        width: 130px;
        color: ${({ theme }): string => theme?.textColors?.GREY_24};
      }

      .row-value {
        width: 85%;
      }
    }
  }

  .sec-blank-slate {
    border: 1px solid #e6e6e6;
    margin-left: 125px;
    min-height: 150px;
  }
`;

export const LinkCollapseStyled = styled(Collapse)`
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  border: none;
  width: 100%;

  .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      padding: 0px;
      display: flex;
    }

    .ant-collapse-content {
      border: none;
      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }

  .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 0px;
    margin-bottom: 15px;
  }
`;
