import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLinkComponent = styled(Link)`
  display: flex;
  color: ${({ theme }): string => theme?.textColors?.BLUE_116};

  :hover {
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
  }
`;
