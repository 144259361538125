// <--Constants Start-->
import { API_CONFIG } from "../../constants/apiconfig";
// <--Constants End-->

// <--Parsers Start-->
import {
  getDataImportTemplateDownloadLinkMetadata,
  getImportOwnerParsedData,
  getParsedDataImportConfig,
  getParsedDataImportFieldsConfig,
  getParsedDataImportUploadSignedUrl,
} from "../../parsers/dataimportparsers/dataimportparsers";

import { useGetAll } from "../baseservice";
// <--Services End-->

// <--Types Start-->
import { NodeType } from "../../app.types";
import { CustomReactQueryHookReturnType } from "../services.types";

import {
  DataImportUploadSignedUrlReturnType,
  ImportDomainReturnType,
  ReturnTypeOfDataImportConfig,
  ReturnTypeOfDataImportFieldsConfig,
} from "../../parsers/dataimportparsers/dataimportparsers.types";
// <--Types End-->

const {
  get_data_import_config: getDataImportConfig,
  get_data_import_upload_signed_url: getDataImportUploadSignedUrl,
  get_data_import_fields_config: getDataImportFieldsConfig,
  get_signed_url_of_data_import_template: getDataImportMetadataUrl,
  get_import_owner_details: getImportOwnerDetails,
} = API_CONFIG;

export const useGetDataImportConfig = (): CustomReactQueryHookReturnType<ReturnTypeOfDataImportConfig> => {
  return useGetAll({
    apiConfig: getDataImportConfig,
    parser: getParsedDataImportConfig,
  });
};

export const useGetDataImportFieldsConfig = (
  assetType: NodeType
): CustomReactQueryHookReturnType<ReturnTypeOfDataImportFieldsConfig> => {
  return useGetAll({
    apiConfig: getDataImportFieldsConfig,
    parser: getParsedDataImportFieldsConfig,
    params: [assetType],
    options: { enabled: !!assetType },
  });
};

export function useGetDataImportTemplateDownloadLinkMetadata(
  assetType: string
): CustomReactQueryHookReturnType<string> {
  return useGetAll({
    apiConfig: getDataImportMetadataUrl,
    parser: getDataImportTemplateDownloadLinkMetadata,
    params: [assetType],
  });
}

export function useGetDataImportUploadSignedUrl(
  nodeType: string
): CustomReactQueryHookReturnType<DataImportUploadSignedUrlReturnType> {
  return useGetAll({
    apiConfig: getDataImportUploadSignedUrl,
    params: [nodeType],
    parser: getParsedDataImportUploadSignedUrl,
  });
}

export function useGetImportOwners(): CustomReactQueryHookReturnType<
  ImportDomainReturnType[]
> {
  return useGetAll({
    apiConfig: getImportOwnerDetails,
    parser: getImportOwnerParsedData,
  });
}
