import { Input } from "antd";
import styled from "styled-components";
import { InputProps } from "../inputs/inputs.types";

export const TimePickerStyled = styled.div<{
  width?: string;
  height?: string;
}>`
  position: relative;
  /* width: ${({ width }): string => width || "100%"};
  height: ${({ height }): string => height || "42px"}; */
  border-radius: 0px;
  /* padding: 8px 12px; */

  .clear-btn {
    padding: 0px;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 12px;
    color: ${(props): string => props.theme.textColors.BLUE_115};
    height: unset;
  }

  &.ant-picker-focused,
  &:hover {
    box-shadow: none;
    border-color: ${({ theme }): string => theme.borderColors.YELLOW_RD};
  }

  .ant-picker {
    width: ${({ width }): string => width || "100%"};
    height: ${({ height }): string => height || "100%"};
    &:not(.ant-picker-disabled):hover {
      border-color: ${({ theme }): string => theme.borderColors.DARK_GREY_15};
    }
    &.ant-picker-focused,
    &.ant-picker-focused:hover {
      box-shadow: none;
      border-color: ${({ theme }): string => theme.borderColors.YELLOW_RD};
    }
    .ant-picker-input {
      .ant-input {
        border: none;
        border-radius: 0px;
        padding-right: 3px;
        font-size: 13px;
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      }

      .ant-picker-suffix {
        svg path {
          fill: ${({ theme }): string => theme.textColors.DARK_GREY_100};
        }
      }
    }
  }

  .timepicker-tooltip {
    top: 28px !important;
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        box-shadow: none;
        width: 15.07px;
        height: 15.07px;
        top: -6px;

        .ant-tooltip-arrow-content {
          width: 8px;
          height: 8px;
          background-color: ${(props): string =>
            props.theme.bgColors.WHITE_COLOR};
          border-top: 1px solid
            ${(props): string => props.theme.borderColors.LIGHT_GREY_25};
          border-left: 1px solid
            ${(props): string => props.theme.borderColors.LIGHT_GREY_25};
        }
      }
      .ant-tooltip-inner {
        box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
        background: ${(props): string => props.theme.bgColors.WHITE_COLOR};
        border: 1px solid
          ${(props): string => props.theme.borderColors.LIGHT_GREY_25};
        padding: 0px;
        width: 162px;
      }
    }
  }
`;

export const TimePickerInputStyled = styled(Input)<InputProps>``;

export const TimePickerPanelStyled = styled.div`
  &.timepicker-content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 8px;

    .timepicker-header,
    .timepicker-body,
    .timepicker-footer {
      display: flex;
      align-items: center;

      > div {
        height: 36px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        svg {
          width: 15px;
          height: 20px;
          path {
            fill: ${(props): string => props.theme.bgColors.GREY_COLOR};
          }
        }
      }

      .separator {
        text-align: center;
        height: 30px;
        width: 8px;
        margin: 0;
        padding: 2px;
        color: ${(props): string => props.theme.textColors.GREY_90};
      }
    }

    .timepicker-header,
    .timepicker-footer {
      > div {
        cursor: pointer;
        border: 1px transparent solid;
        :hover {
          background-color: ${(props): string =>
            props.theme.bgColors.LIGHT_GREY_35};
          border-color: ${(props): string => props.theme.borderColors.GREY_75};
        }
      }
    }
  }
`;
