import { Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { FormItemStyled } from "../form";
import { DatePickerField, InputField, SelectField } from "../formfields";

import LinkButton from "../linkbutton/linkbutton";

import { minusIconFilled, plusIconInFilledCircle } from "../../svgs";
import { CriteriaContainerStyled } from "./criteriaselection.styles";

import {
  AddCriteria,
  CriteriaProps,
  SpecifyCriteriaType,
} from "./criteriaselection.types";

import { useGetIncExcFieldData } from "../../api/listingsservice";
import { LoadingSpinerStyled } from "../../styles/app.styles";

import { defaultCriteria } from "../../pages/accountsettingspage/accountsettingspage.views/modulesettings/modulesettingsform/modulesettingsform.types";
import StateHandler from "../statehandler/statehandler";

import { accountSettingsInputBlankSlate } from "../../blankslates/accountsettingsblankslates";
import { SelectFieldOption } from "../formfields/selectfield/selectfield.types";
import { sortListOnSpecificKeyValue } from "../../utils";

const CriteriaSelection = ({
  name,
  parsedFields,
  parsedFilters,
  paddingLeft,
  onReset,
  type,
  isEditMode,
  criteriaFields,
  callOnChange,
}: CriteriaProps): JSX.Element => {
  const { control, setValue, setError } = useFormContext<AddCriteria>();

  const [showIncExcLoading, setShowIncExcLoading] = useState(isEditMode);
  const [showSpinnerInIncExl, setShowSpinnerInIncExl] = useState(false);

  const { append, remove, fields, update } = useFieldArray<AddCriteria>({
    control,
    name,
  });

  const removeField = useCallback(
    (index: number, fieldsLength: number) => {
      callOnChange?.();
      if (fieldsLength > 1) remove(index);
      else {
        onReset();
      }
    },
    [onReset, remove, fields]
  );
  const dateFormat = "MM/DD/YYYY";

  const fieldsForSelect = useMemo(() => {
    return (
      parsedFields
        ?.filter((item) => !item?.is_field_hidden && item?.is_field_filterable)
        ?.map((field) => ({
          key: `${field?.field_display_name}`,
          value: `${field?.field_id}`,
          label: field?.field_display_name,
        })) || []
    );
  }, [parsedFields]);

  const filtersForSelect = useCallback(
    (index: number) => {
      const columnId = fields?.[index]?.column;
      const fieldId = Number.parseInt(columnId || "");
      const field = parsedFields?.find(
        (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
      );
      const fieldDataType = field?.field_datatype || "";

      const filtersArray =
        parsedFilters?.filter((parsedFilter) => {
          const addIncExclOption =
            parsedFilter?.elligibleTypes?.includes("ATR") ||
            parsedFilter?.elligibleTypes?.includes("BOL");
          return (
            parsedFilter?.elligibleTypes?.includes(fieldDataType) ||
            (field?.is_attribute ? addIncExclOption : false)
          );
        }) || [];

      const sortedFilters = sortListOnSpecificKeyValue({
        list: filtersArray,
        key: "label",
      });
      return sortedFilters;
    },
    [parsedFilters, fields, parsedFields]
  );

  const handleChangeValue = useCallback(
    (
      index: number,
      updatedObject: SpecifyCriteriaType,
      type?: "field" | "filter",
      selectFieldOption?: SelectFieldOption[],
      isSingleSelect?: boolean
    ) => {
      callOnChange?.();
      const fieldObj = { ...updatedObject };
      const isField = type === "field";
      const isFilter = type === "filter";
      if (isField) {
        const fieldId = Number.parseInt(updatedObject?.column || "");
        const field = parsedFields?.find(
          (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
        );
        fieldObj.data_type = field?.field_datatype;
        fieldObj.filter = "";
      } else if (isFilter) {
        const filter = parsedFilters?.find(
          (filter) => filter?.value === `${updatedObject?.filter}`
        );
        if (["INC", "NIN"].includes(filter?.value || ""))
          setShowSpinnerInIncExl(true);
      }

      if (selectFieldOption && isSingleSelect)
        fieldObj.columnName = selectFieldOption?.[0]?.key;

      fieldObj.valuesList = selectFieldOption?.map((item) => item?.key || "");

      update(index, fieldObj);
    },
    [parsedFields]
  );

  const resetRowValues = useCallback(
    (index: number, isFilterSelection?: boolean, isDateType?: boolean) => {
      if (isDateType) {
        setValue(`${name}.${index}.date_value`, defaultCriteria.date_value);
        setValue(
          `${name}.${index}.second_date_value`,
          defaultCriteria.second_date_value
        );
        setValue(`${name}.${index}.value`, defaultCriteria.value);
        setValue(`${name}.${index}.second_value`, defaultCriteria.second_value);
      } else {
        !isFilterSelection &&
          setValue(`${name}.${index}.filter`, defaultCriteria.filter);
        setValue(`${name}.${index}.value`, defaultCriteria.value);
        setValue(`${name}.${index}.second_value`, defaultCriteria.second_value);
        setValue(`${name}.${index}.values_list`, defaultCriteria?.values_list);
        setValue(`${name}.${index}.date_type`, defaultCriteria.date_type);
        setValue(`${name}.${index}.date_value`, defaultCriteria.date_value);
        setValue(
          `${name}.${index}.second_date_value`,
          defaultCriteria.second_date_value
        );
      }
    },
    []
  );

  const incExcFieldConfig = useMemo(() => {
    return fields
      ?.filter((item) => {
        return ["INC", "NIN"]?.includes(item?.filter || "") && item?.column;
      })
      ?.map((item) => {
        const fieldId = Number.parseInt(item?.column || "");
        const field = parsedFields?.find(
          (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
        );

        return {
          params: [
            field?.field_name || "",
            field?.field_id_field || "null",
            type,
            field?.delimiter || "",
          ],
          isDelimiter: field?.delimiter !== "",
          field_name: field?.field_name || "",
          field_id: `${field?.field_id || ""}`,
        };
      })
      ?.filter((nestItem) => nestItem?.field_id && nestItem?.field_name);
  }, [fields, parsedFields, type]);

  const {
    isLoading: isLoadingGetFieldData,
    isFetching,
    error: errorGetFieldData,
    parsedData,
  } = useGetIncExcFieldData(incExcFieldConfig, true);

  useEffect(() => {
    if (isEditMode && !isFetching && showIncExcLoading) {
      setShowIncExcLoading(false);
      setShowSpinnerInIncExl(false);
    } else if (isFetching) setShowIncExcLoading(true);
  }, [isFetching]);

  useEffect(() => {
    if (isEditMode && !fields.length) {
      append(criteriaFields);
    }
  }, []);

  return (
    <CriteriaContainerStyled paddingLeft={paddingLeft}>
      <FormItemStyled paddingLeft="0">
        <div className="specify-criteria-fields">
          {fields?.map((criteria, index) => (
            <div className="field" key={criteria?.id}>
              <SelectField
                isAllowClear={false}
                control={control}
                name={`${name}.${index}.column`}
                placeholder="Select Column"
                options={fieldsForSelect || []}
                setValue={setValue}
                showSearch
                filterOption={(input: string, option: any): boolean =>
                  option?.label
                    ?.toString()
                    .toLowerCase()
                    .includes(input?.toString().toLowerCase())
                }
                propOnChange={(option, selectOptionObj): void => {
                  const finalSelectOption = Array.isArray(selectOptionObj)
                    ? selectOptionObj
                    : [selectOptionObj];

                  handleChangeValue(
                    index,
                    {
                      ...criteria,
                      column: option,
                    },
                    "field",
                    finalSelectOption,
                    true
                  );
                  resetRowValues(index);
                }}
              />

              <SelectField
                isAllowClear={false}
                control={control}
                name={`${name}.${index}.filter`}
                placeholder="Select Operator"
                options={filtersForSelect(index) || []}
                setValue={setValue}
                showSearch
                filterOption={(input: string, option: any): boolean =>
                  option?.label
                    ?.toString()
                    .toLowerCase()
                    .includes(input?.toString().toLowerCase())
                }
                propOnChange={(option): void => {
                  handleChangeValue(
                    index,
                    {
                      ...criteria,
                      filter: option,
                    },
                    "filter"
                  );
                  resetRowValues(index, true);
                }}
                disabled={criteria?.column === undefined}
              />

              {criteria?.data_type !== "DT" ? (
                <>
                  {criteria?.filter && (
                    <>
                      {!["BLK", "NBL", "INC", "NIN"].includes(
                        criteria?.filter || ""
                      ) && (
                        <InputField
                          control={control}
                          name={`${name}.${index}.value`}
                          placeholder={`Enter ${
                            criteria?.data_type === "NUM" ? "Numeric" : "String"
                          } Value`}
                          propOnChange={(event): void => {
                            handleChangeValue(index, {
                              ...criteria,
                              value: event?.target?.value || "",
                            });
                          }}
                        />
                      )}

                      {["BTW", "NBT"].includes(criteria?.filter || "") && (
                        <InputField
                          control={control}
                          name={`${name}.${index}.second_value`}
                          placeholder={`Enter ${
                            criteria?.data_type === "NUM" ? "Numeric" : "String"
                          } Value`}
                          propOnChange={(event): void => {
                            handleChangeValue(index, {
                              ...criteria,
                              second_value: event?.target?.value || "",
                            });
                          }}
                        />
                      )}

                      {["INC", "NIN"].includes(criteria?.filter || "") && (
                        <StateHandler
                          error={errorGetFieldData}
                          isFetching={
                            showIncExcLoading && !showSpinnerInIncExl
                              ? isFetching
                              : false
                          }
                          blankSlate={accountSettingsInputBlankSlate}
                        >
                          <SelectField
                            control={control}
                            name={`${name}.${index}.values_list`}
                            mode="multiple"
                            setValue={setValue}
                            options={
                              !isEditMode && isLoadingGetFieldData
                                ? []
                                : parsedData?.[criteria?.column || ""] || []
                            }
                            notFoundContent={
                              isLoadingGetFieldData ? (
                                <LoadingSpinerStyled>
                                  Data is being fetched <Spin size="small" />
                                </LoadingSpinerStyled>
                              ) : errorGetFieldData ? (
                                <LoadingSpinerStyled>
                                  No results found
                                </LoadingSpinerStyled>
                              ) : null
                            }
                            showSearch
                            filterOption={(
                              input: string,
                              option: any
                            ): boolean =>
                              option?.label
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toString()?.toLowerCase())
                            }
                            propOnChange={(
                              option,
                              multipleSelectOption
                            ): void => {
                              handleChangeValue(
                                index,
                                {
                                  ...criteria,
                                  values_list: option,
                                },
                                undefined,
                                multipleSelectOption
                              );
                            }}
                          />
                        </StateHandler>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {criteria?.filter &&
                    !["BLK", "NBL", "INC", "NIN"].includes(
                      criteria?.filter || ""
                    ) && (
                      <>
                        <SelectField
                          control={control}
                          name={`${name}.${index}.date_type`}
                          options={[
                            {
                              value: "REL",
                              label: "Relative Date",
                            },
                            {
                              value: "ABS",
                              label: "Absolute Date",
                            },
                          ]}
                          defaultValue="REL"
                          setValue={setValue}
                          setError={setError}
                          isAllowClear={false}
                          propOnChange={(option): void => {
                            handleChangeValue(index, {
                              ...criteria,
                              date_type: option,
                            });
                            resetRowValues(index, false, true);
                          }}
                        />

                        {criteria?.date_type === "REL" ? (
                          <>
                            <InputField
                              control={control}
                              name={`${name}.${index}.value`}
                              placeholder="Enter Number of Days"
                              propOnChange={(event): void => {
                                handleChangeValue(index, {
                                  ...criteria,
                                  value: event?.target?.value || "",
                                });
                              }}
                            />

                            {["BTW", "NBT"].includes(
                              criteria?.filter || ""
                            ) && (
                              <InputField
                                control={control}
                                name={`${name}.${index}.second_value`}
                                placeholder="Enter Number of Days"
                                propOnChange={(event): void => {
                                  handleChangeValue(index, {
                                    ...criteria,
                                    second_value: event?.target?.value || "",
                                  });
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <DatePickerField
                              control={control}
                              format={dateFormat}
                              name={`${name}.${index}.date_value`}
                              placeholder="Enter Date Value"
                              propOnChange={(option): void => {
                                handleChangeValue(index, {
                                  ...criteria,
                                  date_value: option,
                                });
                              }}
                            />

                            {["BTW", "NBT"].includes(
                              criteria?.filter || ""
                            ) && (
                              <DatePickerField
                                control={control}
                                format={dateFormat}
                                name={`${name}.${index}.second_date_value`}
                                placeholder="Enter Date Value"
                                propOnChange={(option): void => {
                                  handleChangeValue(index, {
                                    ...criteria,
                                    second_date_value: option,
                                  });
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                </>
              )}
              {/* </div> */}

              <LinkButton
                className="remove-criteria-btn"
                onClick={(): void => removeField(index, fields?.length)}
              >
                {minusIconFilled("14.4px", "14.4px")}
              </LinkButton>
            </div>
          ))}
        </div>

        <LinkButton
          className="add-criteria-btn"
          onClick={(): void => {
            append(defaultCriteria);
          }}
        >
          {plusIconInFilledCircle("12px", "12px")} Add
        </LinkButton>
        <br />
      </FormItemStyled>
    </CriteriaContainerStyled>
  );
};

export default CriteriaSelection;
