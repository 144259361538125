import styled from "styled-components";

export const AddCommentSecStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;

  .ant-input {
    border-radius: 0;
  }

  .add-comment-icon {
    height: 40.8px;
  }
`;
