import { Layout } from "react-grid-layout";
import { WidgetParsedType } from "./dashboardparser.types";
import { sortListOnSpecificKeyValue } from "../../utils";

export const getSortedWidgets = (
  widgets: WidgetParsedType[]
): WidgetParsedType[] => {
  const sortedWidgets = sortListOnSpecificKeyValue({
    key: "widgetOrder",
    list: widgets,
    type: "number",
    sortAscending: false,
  });

  return sortedWidgets;
};

export const computeLayout = (widgets: WidgetParsedType[]): Layout[] => {
  const updatedLayout: Layout[] = [];
  let x = 0;
  let y = 0;

  widgets?.forEach((item) => {
    const { width, height } = item;
    updatedLayout?.push({
      i: String(item?.id), // Unique identifier for the element
      x,
      y,
      w: width,
      h: height,
    });

    x += width;

    // If the current row is full, move to the next row
    if (x >= 12) {
      // Assuming a 12-column grid
      x = 0;
      y += height;
    }
  });

  return updatedLayout || [];
};

export const addClonedWidget = (
  widgets: WidgetParsedType[],
  clonedWidget: WidgetParsedType,
  indexToCloneAt: number
): WidgetParsedType[] => {
  const widgetsLength = widgets?.length || 0;

  const beforeClonedWidgetArray = widgets?.slice(0, indexToCloneAt) || [];

  const afterClonedWidgetArray = widgets?.slice(indexToCloneAt, widgetsLength);

  const slicedArray = [
    ...beforeClonedWidgetArray,
    clonedWidget,
    ...afterClonedWidgetArray,
  ];

  return slicedArray || [];
};

export const updateWidgetsOrderAfterAddition = (
  widgets: WidgetParsedType[],
  addedWidgetOrder: number
): WidgetParsedType[] => {
  const updatedWidgets =
    widgets?.map((widget) => {
      if (widget?.widgetOrder >= addedWidgetOrder) {
        return {
          ...widget,
          order: widget?.widgetOrder + 1,
        };
      }
      return widget;
    }) || [];

  return updatedWidgets;
};

export const getSortedLayout = (layout: Layout[]): Layout[] => {
  const sortedLayout = layout?.sort((a, b) => {
    if (a?.y !== b?.y) {
      return a?.y - b?.y;
    }
    return a?.x - b?.x;
  });
  return sortedLayout;
};
