import { NodeType } from "../../app.types";

export const getNodeNameBasedOnType = (
  nodeType: NodeType,
  isReportPage?: boolean,
  firstLetterCapital?: boolean
): string => {
  const node =
    nodeType === "TRM"
      ? "term"
      : nodeType === "TBL"
      ? "table"
      : nodeType === "RLS"
      ? "rule"
      : nodeType === "DSR" && isReportPage
      ? "report"
      : nodeType === "QRY"
      ? "question"
      : "dataset";

  return firstLetterCapital
    ? node?.charAt(0).toUpperCase() + node?.slice(1)
    : node;
};

export const RULES_DUMMY_ACTIVITIES = {
  "2021-09-01T10:00:00Z": [
    {
      action_desc: "Resolved by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Resolved",
      action_type: "RSL",
      showDescription: false,
    },
    {
      action_desc: "Acknowledged by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Acknowledged",
      action_type: "ACK",
      showDescription: false,
    },
    {
      action_desc: "'Edit the description'",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Commented",
      action_type: "CMT",
      showDescription: true,
    },
    {
      action_desc: "Resolved by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Resolved",
      action_type: "RSL",
      showDescription: false,
    },
    {
      action_desc: "Acknowledged by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Acknowledged",
      action_type: "ACK",
      showDescription: false,
    },
  ],
  "2021-08-01T10:00:00Z": [
    {
      action_desc: "Updated by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Updated",
      action_type: "UPD",
      showDescription: false,
    },
  ],
  "2021-07-01T10:00:00Z": [
    {
      action_desc: "Resolved by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Resolved",
      action_type: "RSL",
      showDescription: false,
    },
    {
      action_desc: "Acknowledged by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Acknowledged",
      action_type: "ACK",
      showDescription: false,
    },
    {
      action_desc: "'Edit the description'",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Commented",
      action_type: "CMT",
      showDescription: true,
    },
    {
      action_desc: "Updated by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Updated",
      action_type: "UPD",
      showDescription: false,
    },
    {
      action_desc: "Updated by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Updated",
      action_type: "UPD",
      showDescription: false,
    },
    {
      action_desc: "Resolved by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Resolved",
      action_type: "RSL",
      showDescription: false,
    },
    {
      action_desc: "Acknowledged by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Acknowledged",
      action_type: "ACK",
      showDescription: false,
    },
  ],
  "2021-06-01T10:00:00Z": [
    {
      action_desc: "Resolved by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Resolved",
      action_type: "RSL",
      showDescription: false,
    },
    {
      action_desc: "Acknowledged by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Acknowledged",
      action_type: "ACK",
      showDescription: false,
    },
    {
      action_desc: "'Edit the description'",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Commented",
      action_type: "CMT",
      showDescription: true,
    },
    {
      action_desc: "Updated by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Updated",
      action_type: "UPD",
      showDescription: false,
    },
    {
      action_desc: "Updated by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Updated",
      action_type: "UPD",
      showDescription: false,
    },
    {
      action_desc: "Resolved by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Resolved",
      action_type: "RSL",
      showDescription: false,
    },
    {
      action_desc: "Acknowledged by John Doe",
      performed_on: "2021-09-01T10:00:00Z",
      performed_by: "John Doe",
      action_title: "Acknowledged",
      action_type: "ACK",
      showDescription: false,
    },
  ],
};
