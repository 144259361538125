import styled from "styled-components";

export const SSAAddQueryModalStyled = styled.div`
  .ant-form {
    .form-wrapper {
      max-height: 350px;
      overflow: auto;
      padding-bottom: 41px;

      .checkbox-wrapper {
        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              display: flex;
              align-items: center;
              column-gap: 8px;

              .ant-checkbox-wrapper {
                width: fit-content;
              }
            }
          }
        }
      }
    }

    .bold {
      font-family: OpenSansSemiBold;
    }
  }
`;
