import { useCallback } from "react";
import DropdownButton from "../../../../../../components/dropdownbutton/dropdownbutton";

import { ELEMENT_IDS } from "../../../../../../constants";

import {
  actionsIcon,
  cloneIcon,
  dateIcon,
  deactivateIcon,
  deleteIcon,
  hideIcon,
  removeScheduleIcon,
  shieldIcon,
  unhideIcon,
} from "../../../../../../svgs";

import {
  CatalogGridActionsWrapperStyled,
  ThreeDotMenuButton,
} from "./moreactionsdropdown.styles";

import { MoreActionsDropdownCompPropsType } from "./moreactionsdropdown.types";
import { useOpenModal } from "../../../../../../customhooks";

import { CloneRuleModalProps } from "../../../../../../components/modalwrapper/modals/clonerulemodal/clonerulemodal.types";
import { RemoveScheduleModalProps } from "../../../../../../components/modalwrapper/modals/removeschedulemodal/removeschedulemodal.types";

import { UpdateScheduleModalProps } from "../../../../../../components/modalwrapper/modals/updateschedulemodal/updateschedulemodal.types";
import { SwitchFieldStyled } from "../../../../../../components/formfields/switchfield/switchfield.styles";

import { getPageNode } from "../../../../listingpage.utils";
import { useRequestWithMethod } from "../../../../../../api";

import {
  getPostLoginData,
  getUserPermissions,
  jsonParse,
  openNotification,
} from "../../../../../../utils";
import SuccessNotificationMessage from "../../../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";

const {
  allcat_lst_lst_more_actions_btn: ALLCAT_LST_LST_MORE_ACTIONS_BTN,

  allcat_lst_lst_hide_btn: ALLCAT_LST_LST_HIDE_BTN,
  allcat_lst_lst_un_hide_btn: ALLCAT_LST_LST_UN_HIDE_BTN,
  allcat_lst_lst_data_classification_btn: ALLCAT_LST_LST_DATA_CLASSIFICATION_BTN,
  allcat_lst_lst_delete_btn: ALLCAT_LST_LST_DELETE_BTN,
  allcat_lst_lst_update_schedule_btn: ALLCAT_LST_LST_UPDATE_SCHEDULE_BTN,
  allcat_lst_lst_remove_schedule_btn: ALLCAT_LST_LST_REMOVE_SCHEDULE_BTN,
  allcat_lst_lst_clone_rule_btn: ALLCAT_LST_LST_CLONE_RULE_BTN,
  allcat_lst_lst_activate_job_btn: ALLCAT_LST_LST_ACTIVATE_JOB_BTN,
  allcat_lst_lst_deactivate_job_btn: ALLCAT_LST_LST_DEACTIVATE_JOB_BTN,
  datdict_detpg_dq_ruldet_three_dot_menu: DATDICT_DETPG_DQ_RULDET_THREE_DOT_MENU,
  datdict_detpg_dq_ruldet_three_dot_menu_ad_job: DATDICT_DETPG_DQ_RULDET_THREE_DOT_MENU_AD_JOB,
} = ELEMENT_IDS;

const unhideEyeIcon = unhideIcon("20", "20");
const hideEyeIcon = hideIcon("20", "20");
const dataClassificationIcon = shieldIcon("20", "20");
const scheduleBtnIcon = dateIcon;
const removeScheduleBtnIcon = removeScheduleIcon("13", "13");
const cloneBtnIcon = cloneIcon;
const threeDotActionIcon = actionsIcon();
const deactivateBtnJob = deactivateIcon("24", "24");

export const MoreActionsDropdownComp = (
  props: MoreActionsDropdownCompPropsType
): JSX.Element => {
  const onOpenModal = useOpenModal();

  const {
    viewId,
    nodeType,
    disabled,
    countFilter,
    filterModel,
    sortModel,
    isSelectAll,
    isHideDisabled,
    exceptionSwitch,
    isUnhideDisabled,
    hideDropdownIcon,
    selectedRowsCount = 0,
    selectedNodeTagIds,
    isOutsideListingVews,
    isFromRuleDetailPage,
    isActivateJobDisabled,
    isDeactivateJobDisabled,
    selectedRulesWithSourceInfo,
    populateData,
    onClickHide,
    onClickUnHide,
    handleClearAll,
    onAddOrRemoveJobClick,
    onClickDeleteRecords,
  } = props;

  const { isTablePage, isColumnPage, isJobPage, isRulePage } = getPageNode(
    nodeType
  );

  const {
    is_catalog_admin: isCatalogAdmin,
    is_data_security_admin: isDataSecurityAdmin,
  } = getUserPermissions();

  const postLoginData = getPostLoginData();
  const { user_info } = postLoginData || {};
  const { job_settings } = user_info || {};
  const {
    offline_profiling_limit: offlineProfLinmit = 0,
    offline_rules_limit: offlineRulesLimit = 0,
  } = job_settings || {};

  // confirm this
  const addToJobLimit = isRulePage ? offlineRulesLimit : offlineProfLinmit;

  const selectedNodeIds = selectedRulesWithSourceInfo?.map(
    (node) => node?.node_id
  );

  const selectedNodeIdsCount = selectedNodeIds?.length || 0;

  const isAddToJobDisable = selectedRowsCount > addToJobLimit;

  const jobIdsExists = selectedRulesWithSourceInfo?.some(
    (obj) => "job_ids" in obj
  );

  const selectedNodeJobIds = jobIdsExists
    ? selectedRulesWithSourceInfo?.flatMap((node) => node?.job_ids)
    : [];

  const isColumnOrTablePage = isTablePage || isColumnPage;

  const nodeName = isTablePage
    ? "Table(s)"
    : isColumnPage
    ? "Column(s)"
    : isRulePage
    ? "Rule(s)"
    : "Job(s)";

  const isCloneRuleDisabled = selectedRowsCount > 50 && !isOutsideListingVews;

  const onActivateDeactivateJobSuccess = useCallback(
    (response) => {
      const parsedRes = jsonParse(response?.data?.FN_UPDATE_GOV_JOBS_DATA);

      openNotification(
        <SuccessNotificationMessage
          message={`${parsedRes?.total_cnt} ${nodeName} has been updated.`}
        />
      );
      populateData?.({});
    },
    [nodeName, populateData]
  );

  const {
    onExecuteRequest: onActivateDeactivateJob,
    isLoading: IsActivatingDeactivatingJobLoading,
    error: errorInActivatingDeactivatingJob,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, viewId],
    false,
    onActivateDeactivateJobSuccess
  );

  const isMassUpdate: boolean =
    isSelectAll || (!isSelectAll && selectedNodeIds?.length > 1);

  const onActivateJobClick = useCallback(() => {
    const activateJobPayload = [
      {
        is_active: "true",
        job_def_ids: selectedNodeIds,
        is_mass_update: isMassUpdate,
        isSelectAll,
        filterModel: filterModel || {},
        countFilter: countFilter || "all",
      },
    ];
    onActivateDeactivateJob(activateJobPayload);
  }, [isSelectAll, countFilter, filterModel, selectedNodeIds, isMassUpdate]);

  const onDeactivateJobClick = useCallback(() => {
    const deActivateJobPayload = [
      {
        is_active: "false",
        job_def_ids: selectedNodeIds,
        is_mass_update: isMassUpdate,
        isSelectAll,
        filterModel: filterModel || {},
        countFilter: countFilter || "all",
      },
    ];
    onActivateDeactivateJob(deActivateJobPayload);
  }, [isSelectAll, countFilter, filterModel, selectedNodeIds, isMassUpdate]);

  const onUpdateScheduleClick = useCallback(() => {
    if (isFromRuleDetailPage) onAddOrRemoveJobClick?.();
    else {
      onOpenModal({
        modalId: "update_schedule_modal",
        visible: true,
        modalTitle: "Add to Job(s)",
        modalProps: {
          viewId,
          nodeType,
          countFilter,
          filterModel,
          sortModel,
          isSelectAll,
          selectedNodeJobIds,
          selectedRulesWithSourceInfo,
          handleClearAll,
          populateData,
        } as UpdateScheduleModalProps,
      });
    }
  }, [
    viewId,
    nodeType,
    countFilter,
    filterModel,
    isSelectAll,
    selectedNodeJobIds,
    isFromRuleDetailPage,
    selectedRulesWithSourceInfo,
    handleClearAll,
    populateData,
  ]);

  const onRemoveScheduleClick = useCallback(() => {
    if (isFromRuleDetailPage) onAddOrRemoveJobClick?.();
    else {
      onOpenModal({
        modalId: "remove_schedule_modal",
        visible: true,
        modalTitle: "Remove from Job(s)",
        modalProps: {
          viewId,
          nodeType,
          countFilter,
          filterModel,
          isSelectAll,
          allSelectedNodesIds: selectedNodeIds,
          populateData,
          handleClearAll,
        } as RemoveScheduleModalProps,
      });
    }
  }, [
    viewId,
    nodeType,
    countFilter,
    filterModel,
    isSelectAll,
    selectedNodeIds,
    isFromRuleDetailPage,
    populateData,
    handleClearAll,
  ]);

  const onClickClassification = useCallback(() => {
    onOpenModal({
      modalId: "data_classification",
      visible: true,
      modalTitle: "Data Classification",
      modalProps: {
        nodeType,
        tags: selectedNodeTagIds,
        isSelectAll,
        selectedNodesIds: selectedNodeIds,
        countFilter,
        filterModel,
        searchId: `${viewId}`,
        selectedRowsCount: selectedNodeIdsCount,
        populateData,
      },
    });
  }, [
    viewId,
    nodeType,
    countFilter,
    isSelectAll,
    filterModel,
    selectedNodeIds,
    selectedNodeTagIds,
    selectedNodeIdsCount,
    populateData,
  ]);

  const onCloneRuleclick = useCallback(() => {
    onOpenModal({
      modalId: "clone_rule_modal",
      visible: true,
      modalTitle: "Clone Rule",
      modalProps: {
        selectedRulesWithSourceInfo,
      } as CloneRuleModalProps,
    });
  }, [selectedRulesWithSourceInfo]);

  const {
    showAllExceptionsInOnlineRun,
    onShowAllExceptionsInOnlineRunToggled,
  } = exceptionSwitch || {};

  return (
    <CatalogGridActionsWrapperStyled>
      <DropdownButton
        width="180px"
        hideDropdownIcon={hideDropdownIcon}
        items={[
          ...((isFromRuleDetailPage && [
            {
              key: "all-exceptions-btn",
              value: "All Exceptions in online run",
              onClick: (): void => {},
              id: ALLCAT_LST_LST_UPDATE_SCHEDULE_BTN,
              customClass: "all-exceptions-btn",
              customComponentAfterItemLabel: (
                <SwitchFieldStyled
                  size="small"
                  width="40px"
                  checked={showAllExceptionsInOnlineRun}
                  onChange={onShowAllExceptionsInOnlineRunToggled}
                  height="20.5px"
                />
              ),
            },
          ]) ||
            []),

          ...((!isColumnPage &&
            !isJobPage && [
              {
                key: "update-schedule",
                value: "Add to Job(s)",
                icon: scheduleBtnIcon,
                onClick: onUpdateScheduleClick,
                id: DATDICT_DETPG_DQ_RULDET_THREE_DOT_MENU_AD_JOB,
                customClass: "update-schedule-btn",
                disabled: isAddToJobDisable,
                tooltipProps: isAddToJobDisable
                  ? {
                      title: `Supports a maximum of ${addToJobLimit} records. Deselect some records to perform this action.`,
                    }
                  : undefined,
              },
              {
                key: "remove-schedule",
                value: "Remove from Job(s)",
                icon: removeScheduleBtnIcon,
                onClick: onRemoveScheduleClick,
                id: ALLCAT_LST_LST_REMOVE_SCHEDULE_BTN,
                customClass: "remove-schedule-btn",
              },
            ]) ||
            []),

          ...((isJobPage && [
            {
              key: "activate-job",
              value: "Activate Job(s)",
              icon: scheduleBtnIcon,
              onClick: onActivateJobClick,
              id: ALLCAT_LST_LST_ACTIVATE_JOB_BTN,
              customClass: "activate-job-btn",
              disabled: isActivateJobDisabled,
            },
            {
              key: "deactivate-job",
              value: "Deactivate Job(s)",
              icon: deactivateBtnJob,
              onClick: onDeactivateJobClick,
              id: ALLCAT_LST_LST_DEACTIVATE_JOB_BTN,
              customClass: "deactivate-job-btn",
              disabled: isDeactivateJobDisabled,
            },
          ]) ||
            []),

          ...((isColumnOrTablePage &&
            isDataSecurityAdmin && [
              {
                key: "hide-items",
                value: `Hide ${nodeName}`,
                icon: hideEyeIcon,
                onClick: onClickHide,
                id: ALLCAT_LST_LST_HIDE_BTN,
                disabled: isHideDisabled,
              },
              {
                key: "unhide-items",
                value: `Unhide ${nodeName}`,
                icon: unhideEyeIcon,
                onClick: onClickUnHide,
                id: ALLCAT_LST_LST_UN_HIDE_BTN,
                disabled: isUnhideDisabled,
              },
            ]) ||
            []),

          ...(((isColumnPage || isTablePage) &&
            isDataSecurityAdmin && [
              {
                key: "Data Classification",
                value: "Data Classification",
                icon: dataClassificationIcon,
                onClick: onClickClassification,
                id: ALLCAT_LST_LST_DATA_CLASSIFICATION_BTN,
              },
            ]) ||
            []),

          ...((isRulePage && [
            {
              key: "clone-rule",
              value: "Clone Rule",
              icon: cloneBtnIcon,
              onClick: onCloneRuleclick,
              id: ALLCAT_LST_LST_CLONE_RULE_BTN,
              customClass: "clone-rule-btn",
              disabled: isCloneRuleDisabled,
              tooltipProps: isCloneRuleDisabled
                ? {
                    title: `Supports a maximum of 50 records. Deselect some records to perform this action.`,
                  }
                : undefined,
            },
          ]) ||
            []),

          {
            key: "delete-items",
            value: `Delete ${nodeName}`,
            icon: deleteIcon,
            onClick: onClickDeleteRecords,
            id: ALLCAT_LST_LST_DELETE_BTN,
            customClass: "delete-btn-icon",
          },
        ]}
        disabled={disabled}
        overlay={<div />}
        drpdnBtnElementId={DATDICT_DETPG_DQ_RULDET_THREE_DOT_MENU}
      >
        {isFromRuleDetailPage ? (
          <ThreeDotMenuButton>
            <div className="action-icon">{threeDotActionIcon}</div>
          </ThreeDotMenuButton>
        ) : (
          "More Actions"
        )}
      </DropdownButton>
    </CatalogGridActionsWrapperStyled>
  );
};
