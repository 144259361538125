import styled from "styled-components";
import { ellipsis } from "../../../../../../../../../../../../styles/app.styles";

export const QueryResultsContentStyled = styled.div<{
  isChatPage?: boolean;
  isGridView?: boolean;
  isPivotView?: boolean;
  isFiltersExist?: boolean;
  isMaximizedQuery?: boolean;
}>`
  margin-top: ${({ isChatPage, isPivotView, isFiltersExist }): string =>
    isChatPage ? "5px" : !isPivotView && isFiltersExist ? "15.2px" : " "};
  position: relative;
  background-color: ${({ isChatPage, theme }): string =>
    isChatPage ? theme.bgColors.WHITE_COLOR : "transparent"};

  .ag-theme-alpine {
    padding-top: ${({
      isGridView,
      isChatPage,
      isPivotView,
      isFiltersExist,
    }): string =>
      !isChatPage && (isGridView || isPivotView)
        ? "0"
        : isChatPage && isGridView && isFiltersExist
        ? "10px"
        : ""};
  }

  .ag-root-wrapper {
    border: none;

    .ag-root-wrapper-body {
      border: 1px solid
        ${({ isPivotView }): string =>
          isPivotView ? "#babfc7" : "transparent"};

      .ag-header {
        border: none;

        .ag-header-viewport {
          .ag-header-row {
            .ag-header-cell {
              padding-left: 0;
              padding-right: 0;
              height: 49px;

              .ag-react-container {
                width: 100%;
                height: 100%;
              }

              .ag-header-cell-label {
                padding: 9.6px;
                border-left: 1px solid #e6e6e6;
              }
            }
          }
        }
      }

      .ag-body-viewport {
        border: 1px solid
          ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
        border-top: none;
        border-bottom: none;
        border-right: 1px solid
          ${({ theme, isPivotView }): string =>
            isPivotView ? "transparent" : theme?.borderColors?.LIGHT_GREY_51};

        border-left: 1px solid
          ${({ theme, isPivotView }): string =>
            isPivotView ? "transparent" : theme?.borderColors?.LIGHT_GREY_51};

        height: ${({ isPivotView, isMaximizedQuery }): string =>
          isPivotView && !isMaximizedQuery
            ? "550px"
            : isPivotView && isMaximizedQuery
            ? "calc(100vh - 300px)"
            : "100%"} !important;

        min-height: 400px;

        .ag-row {
          border: none;

          .ag-cell {
            border-right: 1px solid
              ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
            border-bottom: 1px solid
              ${({ theme, isPivotView }): string =>
                isPivotView ? theme?.borderColors?.LIGHT_GREY_51 : ""};
            background: white;

            .ag-group-child-count {
              display: none;
            }
          }
        }
      }

      .ag-side-bar {
        .ag-side-buttons {
          display: none;
        }

        .ag-tool-panel-wrapper {
          .ag-column-panel {
            .ag-pivot-mode-panel {
              display: none;
            }

            .ag-column-panel-column-select {
              flex-grow: 1.5;

              .ag-column-select-header {
                .ag-text-field-input {
                  border: 1px solid
                    ${(props): string => props.theme.borderColors.LIGHT_GREY_40};

                  color: ${(props): string =>
                    props.theme.textColors.BLACK_COLOR};
                  :not(:disabled) {
                    :hover {
                      border-color: ${(props): string =>
                        props.theme.borderColors.DARK_GREY_15};
                      box-shadow: none;
                    }

                    :focus {
                      border-color: ${(props): string =>
                        props.theme.borderColors.YELLOW_RD};
                      color: ${(props): string =>
                        props.theme.textColors.BLACK_COLOR};
                      box-shadow: none;
                    }
                  }
                }
              }

              .ag-checkbox {
                .ag-checkbox-input-wrapper {
                  &::after {
                    color: ${(props): string =>
                      props.theme.textColors.BLUE_116};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QueryResultsGridHeaderRendrerStyled = styled.div<{
  isSelected: boolean;
  isApplied: boolean;
  isNumberOrMetricCol: boolean;
}>`
  background: ${({ theme, isSelected, isApplied }): string =>
    isSelected
      ? theme?.bgColors?.BLUE_116
      : isApplied
      ? theme?.bgColors?.LIGHT_BLUE_25
      : theme?.bgColors?.LIGHT_GREY_22};
  height: 100%;
  padding: 0 18px;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  justify-content: ${({ isNumberOrMetricCol }): string =>
    isNumberOrMetricCol ? "flex-end" : ""};

  .col-name {
    font-family: OpenSansSemiBold;
    color: ${({ theme, isSelected }): string =>
      isSelected
        ? theme?.textColors?.WHITE_COLOR
        : theme?.textColors?.BLUE_116};
  }

  .data-type-icon {
    svg {
      margin-top: 4px;
      path {
        fill: ${({ theme }): string => theme?.bgColors?.GREY_36};
      }
    }
  }

  svg {
    max-width: 12px;

    .grid-sort-asc-icon-fill {
      ${({ isSelected }): string => (isSelected ? `fill:white` : "inherit")}
    }

    .grid-sort-desc-icon-fill {
      ${({ isSelected }): string => (isSelected ? `fill:white` : "inherit")}
    }
  }

  .node-status-rendrer {
    svg {
      margin-top: 0px;
    }
  }
`;

export const NumberRendererStyled = styled.div<{ isChatPage?: boolean }>`
  display: flex;
  justify-content: flex-end;
`;

export const DateRendererStyled = styled.div<{ isChatPage?: boolean }>`
  ${ellipsis}
`;
