import { useController } from "react-hook-form";
import {
  SwitchFieldStyled,
  SwitchFieldWrapperStyled,
} from "./switchfield.styles";
import { SwitchFieldType } from "./switchfield.types";

const SwitchField = (props: SwitchFieldType): JSX.Element => {
  const { name = "", control, label, description, isLabelBold = true } = props;

  const {
    field: { ref, ...switchProps },
  } = useController({ name, control });

  return (
    <SwitchFieldWrapperStyled isLabelBold={isLabelBold}>
      <SwitchFieldStyled
        ref={ref}
        checkedChildren="YES"
        unCheckedChildren="NO"
        {...switchProps}
        {...props}
        isLabelOrProp={!!label || !!description}
      />

      {(!!label || !!description) && (
        <div className="label-desc">
          {!!label && <div className="label">{label}</div>}
          {!!description && <div className="desc">{description}</div>}
        </div>
      )}
    </SwitchFieldWrapperStyled>
  );
};

export default SwitchField;
