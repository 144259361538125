import styled from "styled-components";

export const PopularityScoreStyled = styled.div<{
  isSelected: boolean;
}>`
  cursor: pointer;

  .separator {
    background: ${({ theme }): string => theme.bgColors.GREY_18};
    width: 1px;
    height: 16px;
  }

  .stars-container {
    padding: 7.6px;
    border-radius: 24.8px;
    ${({ theme, isSelected }): string =>
      isSelected ? `background: ${theme.bgColors.LIGHT_GREY_22};` : ""}
  }
`;
