// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Constants Start --->
import { ellipsis } from "../../styles/app.styles";
// <--- Constants End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../utils";
// <--- Utils End --->

export const ColRefDrawerStyled = styled.div<{ hasPromintntTags: boolean }>`
  padding-bottom: 2px;

  .col-ref-header {
    padding: 38px 44px 20px 23px;
    .header {
      display: flex;
      margin-bottom: 6px;
      .header-item {
        display: flex;
        align-items: center;
        min-width: 70px;
        .item-icon {
          margin-right: 6px;
          max-height: 23.5px;
          svg {
            path {
              fill: ${({ theme }): string =>
                theme.bgColors.BLUE_116} !important;
            }
          }
        }
        .item-title {
          cursor: pointer;
          font-size: 15px;
          color: ${({ theme }): string => theme.textColors.BLUE_116};
          ${ellipsis}
        }
        .right-icon {
          margin: 0px 11px;
        }
      }
    }

    .header_row_2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;

      .node-title {
        font-size: 15px;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        width: 100%;
        ${ellipsis}
        opacity: 0.8;
        margin-bottom: ${({ hasPromintntTags }): string =>
          hasPromintntTags ? "16px" : ""};
        padding-left: 39px;
      }

      .prominent-tags-sec {
        padding-left: 39px;
      }

      .section-header {
        flex: 1;

        > svg {
          margin-right: 11px;
          path {
            fill: ${({ theme }): string => theme.bgColors.BLUE_116};
          }
        }

        .info {
          margin: 0;
          margin-right: 0px;

          .info-title {
            ${ellipsis}
            max-width:400px;
            line-height: unset;
          }
        }

        .deprecate-icon {
          display: flex;
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }

        .term-redirect-icon {
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }
      }
    }
  }

  .section-header {
    /* padding-left: 23px; */
    > svg {
      margin-right: 12px;
      path {
        fill: ${({ theme }): string => theme.bgColors.BLUE_116};
      }
    }

    .info {
      margin: 0;
      margin-right: 0;

      .info-title {
        ${ellipsis}
        max-width:400px;
        line-height: unset;
      }
    }

    .deprecate-icon {
      display: flex;
      svg {
        margin-right: 0px;
        margin-left: 12.5px;
      }
    }

    > button {
      width: 33px;
    }
  }

  .col-ref-section {
    /* margin-top: 34px; */
    padding: 27px 23px 0 23px;
    border-top: 1px solid
      ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};

    .col-ref-sec-titile {
      min-height: unset;
      .title {
        font-size: 17px;
      }
    }
    .sec-row {
      margin-bottom: 12px;
      .main-row {
        .row-title {
          min-width: 217px;
        }

        .row-value {
          .data-type-rendrer {
            svg {
              path {
                fill: ${({ theme }): string => theme.textColors.BLACK_COLOR};
              }
            }
          }

          .col-ref-profiling-renderer {
            margin-top: 7px;
            width: 100%;
          }
        }
      }
      .sublist {
        .sublist-row-sec {
          .sublist-row {
            .sublist-title {
              width: 201px;
            }
          }
        }
      }

      .autoset-static-text {
        font-size: 17px;
        margin-bottom: 23px;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        opacity: 0.6;
      }

      .last-updated-static-text {
        font-size: 13px;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        opacity: 0.6;
        margin-bottom: 23px;
      }
    }
  }

  .profilling-info-sec {
    .sec-row {
      margin-bottom: 18px;
    }
  }
`;

export const VisualDistributionStyled = styled.div`
  .main-row {
    pointer-events: none;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-content {
          margin-top: 15px;

          .ant-card {
            .ant-card-body {
              pointer-events: all;
              padding: 0;
              padding-top: 20;
              background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_5};
            }
          }
        }
      }
    }
    .row-value {
      display: flex;
      align-items: center;
    }
  }
`;

export const EntityRendrerStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 6px;
  }
`;
