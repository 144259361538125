import { FlexStyled } from "./flex.styles";
import { FlexProps } from "./flex.types";

function Flex(props: FlexProps): JSX.Element {
  const { children, customClass, elementId = "" } = props;
  return (
    <FlexStyled {...props} className={customClass} id={elementId}>
      {children}
    </FlexStyled>
  );
}

export default Flex;
