import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../../constants/formconstants";
import { descriptionSchema } from "./ruledescriptionschema";

export const addPatternSchema = yup.object().shape({
  name: yup
    ?.string()
    ?.trim()
    ?.test(
      "no-more-than-100-chars",
      "Name should be less than 100 words",
      (currentField) => {
        const currentFieldLength = currentField?.length || 0;
        if (currentFieldLength > 100) return false;
        return true;
      }
    )
    .required(REQUIRED_MESSAGE),
  // regex: yup.string()?.trim()?.required(REQUIRED_MESSAGE),
  regex: yup.lazy((value, p) => {
    const startText = p?.parent?.regex?.slice(0, 1);
    const endText = p?.parent?.regex?.slice(-1);
    const hasContentBetween = value?.length > 2;

    // if (startText !== "^" || endText !== "$") {
    return yup
      .string()
      .required(REQUIRED_MESSAGE)
      .test(
        "resolver",
        "The regex pattern must start with '^', end with '$' and include characters in the middle.",
        () => {
          return startText === "^" && endText === "$" && hasContentBetween;
        }
      );
    // }
  }),
  description: descriptionSchema,
});
