import { ELEMENT_IDS } from "../../constants";
import { TermDetailPageParamViewKeys } from "./termspage.types";

const { busgls_detpg_tab: BUSGLS_DETPG_TAB } = ELEMENT_IDS;

export const termDetailPageTabsValues: {
  [key in TermDetailPageParamViewKeys]: TermDetailPageParamViewKeys;
} = {
  overview: "overview",
  discussion: "discussion",
  activity: "activity",
};

export const termDetailPageTabs = [
  {
    key: termDetailPageTabsValues.overview,
    value: "Overview",
    id: BUSGLS_DETPG_TAB,
  },
  // { key: "Activity", value: "Activity" },
  // { key: "Discussions", value: "Discussions (6)" },
];
