import styled from "styled-components";

export const StyledSectionWithBody = styled.div`
  margin-bottom: 40px;

  .content-header {
    .title {
      color: ${({ theme }): string => theme.textColors.BLUE_105};

      .left-icon {
        display: flex;
        align-items: center;
      }
    }
  }

  .section-body {
    margin: 20px 0px;
  }
`;
