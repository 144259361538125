import styled from "styled-components";

export const SaveSourceFormStyled = styled.div`
  .ant-form {
    padding-top: 27.5px;

    .content-sec {
      margin-bottom: 50px;
      .static-text {
        color: ${({ theme }): string => theme.textColors.BLACK_17};
        font-size: 17px;
        margin-bottom: 22px;
        padding-left: 32px;

        .bold {
          font-family: OpenSansSemiBold;
        }
      }
    }
  }
`;
