export const computeMagnitude = (value: number): number => {
  try {
    const convertdNum = Number(value);

    return Math.abs(convertdNum) >= 10 ** 12
      ? 12
      : Math.abs(convertdNum) >= 10 ** 9
      ? 9
      : Math.abs(convertdNum) >= 10 ** 6
      ? 6
      : Math.abs(convertdNum) >= 10 ** 3
      ? 3
      : 0;
  } catch {
    return 0;
  }
};
