import { useCallback } from "react";
import AgGridTable from "../../../../../../components/aggridtable";
import {
  excelSourceFileGridViewColumns,
  excelSourceFileGridViewRendrers,
  ExcelSourceFilesActionRendrer,
  UpdatedOnRendrers,
  FileNameRendrer,
  SizeRendrer,
  UpdatedByRendrer,
} from "./excelsourcefilesgridview.rendrer";
import { ExcelSourceFilesGridViewStyled } from "./excelsourcefilesgridview.styles";
import {
  ExcelSourceFilesGridViewProps,
  ExcelSourceFilesGridViewType,
} from "./excelsourcefilesgridview.types";

function ExcelSourceFilesGridView(
  props: ExcelSourceFilesGridViewProps
): JSX.Element {
  const { onGridReady, rowData } = props;

  const getRowId = useCallback((params: any) => {
    return params?.id;
  }, []);

  return (
    <ExcelSourceFilesGridViewStyled>
      <AgGridTable<ExcelSourceFilesGridViewType[number]>
        tableColumns={excelSourceFileGridViewColumns}
        rowData={rowData}
        rowHeight={43}
        headerHeight={43}
        maxHeight="calc(100vh - 326px)"
        frameworkComponents={{
          ExcelSourceFilesActionRendrer,
          UpdatedOnRendrers,
          FileNameRendrer,
          SizeRendrer,
          UpdatedByRendrer,
        }}
        cellRenderers={excelSourceFileGridViewRendrers}
        theme="ag-theme-alpine table-container"
        defaultColDef={{
          resizable: false,
          lockPosition: true,
        }}
        onGridReady={onGridReady}
        getRowNodeId={getRowId}
        animateRows
      />
    </ExcelSourceFilesGridViewStyled>
  );
}

export default ExcelSourceFilesGridView;
