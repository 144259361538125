export const containsOrderByClause = (sqlQuery: string): boolean => {
  try {
    // Regular expression pattern to search for "ORDER BY" keyword
    const pattern = /\bORDER\s+BY\b/i; // Case insensitive search

    // Search for the pattern in the SQL query
    return pattern.test(sqlQuery);
  } catch {
    return false;
  }
};
