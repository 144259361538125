import { LinkProps, useLocation } from "react-router-dom";
import { StyledLinkComponent } from "./styledlink.styles";
import { SyledLinkProps } from "./styledlink.types";

const StyledLink = ({
  to = "",
  target,
  id,
  children,
  disabled,
  cutomClassName = "",
  elementId = "",
}: LinkProps & SyledLinkProps): JSX.Element => {
  const location = useLocation();

  return (
    <StyledLinkComponent
      className={cutomClassName}
      to={{ pathname: `${to}`, state: { from: location } }}
      id={id || elementId}
      target={target}
      aria-disabled={disabled}
    >
      {children}
    </StyledLinkComponent>
  );
};

export default StyledLink;
