import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { InputField, TextAreaField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";

import { addTagsetSchema } from "../../utils/schemas/tagsetsschemas";

import { useRequestWithMethod } from "../../api";
import { API_CONFIG } from "../../constants/apiconfig";

import { useCancelModal, useSetData } from "../../customhooks";

import { AddTagsetFormType } from "./addtagsetform.types";

import { APP_ROUTES } from "../../constants";

import { AddTagsetFormStyled } from "./addtagsetform.styles";

const AddTagsetForm = (): JSX.Element => {
  const onCancel = useCancelModal();
  const onSetData = useSetData();
  const history = useHistory();

  const { get_all_tagsets: getAllTagsets } = API_CONFIG;

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<AddTagsetFormType>({
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: yupResolver(addTagsetSchema),
    mode: "onChange",
  });

  const onSuccess = useCallback((response) => {
    onSetData(getAllTagsets, response?.data?.tagsets_list);
    const tagsetId = response?.data?.tagset_id || 0;
    history.push(`${APP_ROUTES.private_routes.tagsets}/${tagsetId}`, {
      isEdit: true,
    });
    onCancel();
  }, []);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "add_tagset",
    undefined,
    false,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    onExecuteRequest({
      tagset_name: values?.name,
      tagset_desc: values?.description,
    });
  }, []);

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <AddTagsetFormStyled>
        <FormStyled onFinish={handleSubmit(onSubmit) as any} isItemColumnLayout>
          <div className="form-content">
            <FormItemStyled label="Name" required>
              <InputField control={control} name="name" />
            </FormItemStyled>

            <FormItemStyled label="Description" required>
              <TextAreaField control={control} name="description" />
            </FormItemStyled>
          </div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Add
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddTagsetFormStyled>
    </StateHandler>
  );
};

export default AddTagsetForm;
