import { TooltipPlacement } from "antd/lib/tooltip";
// <--- Library Imports Start --->
import { Tag } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";

import "ag-grid-enterprise";
import { useHistory, useLocation, useParams } from "react-router";
// <--- Library Imports End --->

// <--- Blankslates Start --->
import {
  listingPageBlankSlate,
  selectedViewBlankSlate,
} from "../../blankslates";
// <--- Blankslates End --->

// <--- Components Start --->
import BreadCrum from "../../components/breadcrum";
import StateHandler from "../../components/statehandler/statehandler";

import { ChartSec, PageHeader } from "./listingpage.components";
import TableWithFilterTiles from "../../components/tablewithfiltertiles";

import { HorizontalDividerStyled } from "../../components/dividers/dividers.styles";
import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
// <--- Components End --->

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";

import { defaultCriteriaField } from "../../forms/addgovernanceviewform/addgovernanceviewform.constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import {
  useCancelModal,
  useOpenModal,
  useQueryParams,
  useSetData,
} from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Parsers Start --->
import {
  getParsedCurrentNode,
  getParsedDownloadView,
  getParsedIsViewFavourite,
  getParsedFieldData999Values,
  getParsedNodeData,
  NodeDataParserReturnType,
  ReturnTypeOfRefParser,
  CaddiAnalyticsStatusIdType,
} from "../../parsers";
// <--- Parsers End --->

// <--- Renderers Start --->
import {
  DataTypeRenderer,
  DateTimeRenderer,
  NumberRenderer,
  RefreshStatusRenderer,
  StatusRenderer,
  TagsRenderer,
  ColumnNameRendrer,
  EntityRendrer,
  TableNameRendrer,
  TableRendrer,
  TermNameRendrer,
  MatchInfoRendrer,
  ConfidenceScoreRendrer,
  DataSourceNameRendrer,
  DescriptionRenderer,
  DQScoreRenderer,
  AlertTypeRenderer,
  IsCDEFieldRendrer,
  PopularityScoreRenderer,
  WorkflowStatusRenderer,
  AssignedToAndAttributesRenderer,
  RuleIdRendrer,
  RuleAlertStatusRendrer,
  RuleRunStatusRendrer,
  RuleWorkflowStatusRenderer,
  RuleTrendRenderer,
  RunResultRenderer,
  JobStatusRenderer,
  JobIdRenderer,
  JobExecutionIdRenderer,
  JobExecutionStatusRenderer,
  AssignedToRenderer,
  ProfilingInfoRendrer,
  ChatNameRenderer,
  FeedbackRenderer,
  AnalyticsStatusRenderer,
  QuestionRenderer,
} from "./listingpage.renderer";

// <--- Renderers End --->

// <--- Services Start --->
import { useRequestWithMethod } from "../../api";
import { useGetCurrentNode } from "../../api/listingsservice";

import { useGetCategorisedGovernanceViews } from "../../api/listingsservice/listingsservice";
// <--- Services End --->

// <--- SVGs Start --->
import {
  editIcon,
  iconRefresh,
  plusIconInFilledCircle,
  solidSuccessTickIcon,
} from "../../svgs";
// <--- SVGs End --->

// <--- Styles Start --->
import { GlobalFiltersWrapper, ListingPageStyled } from "./listingpage.styles";
// <--- Styles End --->

// <--- Types Start --->
import { AgGridSortState } from "../../components/aggridwithserversidepagination/aggridwithserversidepagination.types";
import { PaginationState } from "../../components/pagination/pagination.types";

import {
  GetDataApiCommonPropsType,
  ListingPageProps,
  PopulateDataParams,
  UpdateFeedbackStatusConfigType,
  ListingPageRouteStateType,
} from "./listingpage.types";
import { KeyValuePairType } from "../../app.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  getTileFiltersList,
  parseFilterModel,
  isDownloadDisabled,
  getPageNode,
  getPageNodeName,
} from "./listingpage.utils";
import {
  calculateElementSize,
  findMaxLengthString,
  getObjectKeys,
  getObjectLength,
  getPostLoginData,
  getUniqueList,
  getUserPermissions,
  isJsonString,
  jsonParse,
  jsonStringify,
  openNotification,
  sortObjectsArrayByKey,
} from "../../utils";

import {
  LISTING_PAGE_TAB_FILTER_VALUES,
  QUERY_PARAMS,
} from "./listingpage.constants";

import { getGlobalFiltersData } from "../../utils/getglobalfiltersdata";

import {
  listingPageBlankSlateWithoutFilters,
  selectedFiltersBlankSlate,
  selectedTitleBlankSlate,
} from "../../blankslates/listingpagesblankslate";

import { joinWordsWithUnderscore } from "../../utils/joinwordswithunderscore";
import { getDataImportURL } from "../dataimportpage/dataimportpage.utils";

import { APP_ROUTES, ELEMENT_IDS } from "../../constants";
import { useGetLoggedInUserDomainAndSubdomain } from "../../api/glossarydomainsservice";

import LinkButton from "../../components/linkbutton/linkbutton";
import { useGetRefByType } from "../../api/refservice";

import OnlineProfilingProgressBar from "../../components/onlineprofilingprogressbar/onlineprofilingprogressbar";
import useNodeProfiling from "../../customhooks/useNodeProfilingHook";

import { AddNewJobModalProps } from "../../forms/addnewjobform/addnewjobform.types";
import { getJobDetailPageUrl } from "../../utils/getjobdetailpageurl";
import {
  hasAdministrationEditAccess,
  hasAnyAdministrationAccess,
} from "../../utils/getadminfinegrainaccesslist";
import { AccessDialogueStyled } from "../../styles/app.styles";
import { Banner } from "../../components";
// <--- Utils End --->

const initialPagination = {
  pageSize: 50,
  currentPage: 1,
};

const ACCESS_OPTIONS = [
  { label: "Viewer", value: "viewer", key: "viewer" },
  { label: "Editor", value: "editor", key: "editor" },
];

const { allcat_lst_edi_edit_btn: ALLCAT_LST_EDI_EDIT_BTN } = ELEMENT_IDS;

const donwloadDict: Record<string, string> = {
  TRM: "Glossary",
  TBL: "Data_Dictionary",
  COL: "Field_Dictionary",
  RLS: "Rules_Dictionary",
  JOB: "Jobs",
  ANL: "Analytics Dictionary",
  SCH: "Job_Executions",
};

const ListingPages = (props: ListingPageProps): JSX.Element => {
  const {
    type,
    isOutsideListingVews,
    tableId = "",
    onGridReadyWrapper,
    doesExternalFilterPass,
    gridApi,
    onChangeLoading,
    onDataFetchSuccess,
    jobDefId = "",
    customRightSecOutsideListingViews,
    sourceId = 0,
    srcTypeId,
    freshnessRuleColumnId = 0,
    refreshDataFromParentComp,
    parentNodeId = "",
    gridElemId = "",
    onChangeHeaderRightView,
  } = props;

  // Custom hooks
  const onOpenModal = useOpenModal();
  const onSetData = useSetData();
  const onCancel = useCancelModal();
  const params = useQueryParams();
  const history = useHistory();
  const location = useLocation();

  const accessMessage = !hasAnyAdministrationAccess(
    type === "JOB" ? "jobs" : "job_executions"
  )
    ? "no"
    : "view-only";

  const urlColumnName = params?.get(QUERY_PARAMS?.COLUMN_NAME) || "";
  const urlColumnId = params?.get(QUERY_PARAMS?.COLUMN_ID) || "";
  const urlColumnValue = params?.get(QUERY_PARAMS?.COLUMN_VALUE) || "";
  const urlWidgetId = params?.get(QUERY_PARAMS?.WIDGET_ID) || "";

  const [urlFilterColumn, _] = useState(urlColumnName);
  const [urlFilterColumnId, __] = useState(urlColumnId);
  const [urlFilterValue, ___] = useState(urlColumnValue);
  const [widgetId, ____] = useState(urlWidgetId);

  const [isExpandedGridView, setIsExpandedGridView] = useState(false);

  const onToggleGridView = useCallback(() => {
    setIsExpandedGridView((st) => !st);
  }, []);

  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);

  const {
    view_keys: viewKeys,
    selected_filters: selectedFilters,
  } = useMemo(() => {
    return getGlobalFiltersData();
  }, []);

  const {
    source: sourcesKeys = {},
    domain: domainsKeys = {},
    fieldType: fieldTypeKeys = {},
  } = viewKeys || {};

  const { source: sourcesGlobalFilters, domain: domainsGlobalFilters } =
    selectedFilters || {};

  const isChatListingPage = type === "CHT";
  const isFeedbackAnalyticsPage = type === "FBK";

  const isGlobalFilterKeysExists = useMemo(
    () => !!(sourcesKeys?.[type] || domainsKeys?.[type] || urlFilterValue),
    [sourcesKeys, domainsKeys, type, urlFilterValue]
  );

  const gridInitializingFirstTimeRef = useRef(isGlobalFilterKeysExists);

  const gridRef = useRef<GridReadyEvent>(null);

  const [appliedFiltersKeys, setAppliedFiltersKeys] = useState<Array<string>>(
    []
  );

  const [showHiddenRecords, setShowHiddenRecords] = useState(false);

  const permissions = getUserPermissions();

  const { user_info: userInfo } = getPostLoginData();

  const {
    catalog_access_filter: catalogAccessType = "",
    glossary_access_filter: glossaryAccessType = "",
  } = userInfo || {};

  const parsedCatalog = jsonParse(catalogAccessType);
  const parsedGlossary = jsonParse(glossaryAccessType);

  const isGlossaryViewer = permissions?.is_glossary_viewer;
  const isCatalogAdmin = permissions?.is_catalog_admin;
  const isDataSecurityAdmin = permissions?.is_data_security_admin;

  const isTermGlossaryViewer = type === "TRM" && isGlossaryViewer;

  const nodeDict =
    type === "TBL" ||
    type === "COL" ||
    type === "DSR" ||
    type === "JOB" ||
    type === "RLS";

  const isAdmin =
    (type === "TRM" && permissions?.is_glossary_admin) ||
    (nodeDict && permissions?.is_catalog_admin);

  const isViewer =
    (type === "TRM" && permissions?.is_glossary_viewer) ||
    (nodeDict && permissions?.is_catalog_viewer);

  const {
    isTablePage,
    isColumnPage,
    isRulePage,
    isJobPage,
    isJobExecutionsPage,
    isDashboardPage,
    isTermPage,
    isDatasetReportsPage,
  } = getPageNode(type);

  const appliedFilterModels = gridRef?.current?.api?.getFilterModel();

  const {
    is_enabled: isGlobalSourceEnabled = false,
    filters_list: sourcesGlobalFiltersList = [],
  } = sourcesGlobalFilters || {};

  const {
    is_enabled: isGlobalDomainEnabled = false,
    filters_list: domainsGlobalFiltersList = [],
  } = domainsGlobalFilters || {};

  const sourcesFiltersValues = isGlobalSourceEnabled
    ? sourcesGlobalFiltersList?.map((source) => source?.name) || []
    : [];

  const domainsFiltersValues = isGlobalDomainEnabled
    ? domainsGlobalFiltersList?.map((domain) => domain?.name) || []
    : [];

  const globalFilterModel = useMemo(
    () => ({
      ...(sourcesGlobalFiltersList?.length &&
        isGlobalSourceEnabled &&
        sourcesKeys[type] && {
          [sourcesKeys[type] as string]: {
            filterType: "set",
            values: sourcesFiltersValues,
          },
        }),

      ...(urlFilterColumn && {
        [urlFilterColumn as string]: {
          filterType: "set",
          values: [urlFilterValue],
        },
      }),

      ...(domainsGlobalFiltersList?.length &&
        isGlobalDomainEnabled &&
        domainsKeys[type] && {
          [domainsKeys[type] as string]: {
            filterType: "set",
            values: domainsFiltersValues,
          },
        }),

      ...(!showHiddenRecords && (type === "TBL" || type === "COL")
        ? {
            is_hidden: {
              values: ["false"],
              filterType: "set",
            },
          }
        : {}),
    }),
    [
      sourcesKeys,
      isGlobalSourceEnabled,
      domainsKeys,
      isGlobalDomainEnabled,
      showHiddenRecords,
      urlFilterValue,
      urlFilterColumn,
    ]
  );

  const {
    get_current_node: getCurrentNode,
    get_governance_views: getGovernanceViews,
  } = API_CONFIG;

  // States
  const [pagination, setPagination] = useState<PaginationState>(
    initialPagination
  );

  // to apply default sorting on a listing page
  const [sortColumn, setSortColumn] = useState<AgGridSortState>({
    sortColName:
      type === "TRM"
        ? "Short Name"
        : type === "TBL"
        ? "Table Name"
        : type === "COL"
        ? "Column Name"
        : isRulePage || isJobPage
        ? "ID"
        : isJobExecutionsPage
        ? "Start Time"
        : isFeedbackAnalyticsPage
        ? "Feedback Time"
        : "Name",

    sortType:
      isRulePage || isJobPage || isJobExecutionsPage || isFeedbackAnalyticsPage
        ? "desc"
        : "asc",
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [isPopulateDataCalled, setIsPopulateDataCalled] = useState<boolean>(
    false
  );
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [recordsShownCount, setRecordsShownCount] = useState<number>(0);

  const selectedFilter = params?.get(QUERY_PARAMS?.SELECTED_FILTER) || "all";

  const paramNodeId = useMemo(() => {
    const { NODE_ID } = QUERY_PARAMS;
    return Number.parseInt(params?.get(NODE_ID) || "");
  }, [params]);

  // API Integrations
  // GET APIs

  const {
    onExecuteRequest: onRunNodeProfiling,
    isLoading: isLoadingRunNodeProfiling,
    error: errorInRunNodeProfiling,
  } = useRequestWithMethod("run_profiling", undefined, true);

  //
  const {
    parsedData: glossaryDomainAndSubdomain,
    isLoading: glossaryDomainsLoading,
  } = useGetLoggedInUserDomainAndSubdomain();

  const {
    parsedData: parsedFeedbackStatuses,
    isLoading: isLoadingFeedbackStatuses,
    error: errorFeedbackStatuses,
  } = useGetRefByType("ANL_FEEDBACK_STATUS");

  const sortedFeedbackStatuses = useMemo(
    () => sortObjectsArrayByKey(parsedFeedbackStatuses, "displayOrder"),
    [parsedFeedbackStatuses]
  );

  // Governance views
  const {
    parsedData: parsedGovernanceViews,
    isLoading: isLoadingGovernanceViews,
    error: errorGovernanceViews,
    refetch: refetchGovernanceViews,
  } = useGetCategorisedGovernanceViews(type, parentNodeId);

  // Bot Engagements
  const {
    onExecuteRequest: onExecuteRequestBotEngagements,
    isLoading: isLoadingBotEngagements = false,
    error: errorBotEngagements,
    data: parsedBotEngagements,
  } = useRequestWithMethod("get_bot_engagements", [], true);

  // Bot Top Users
  const {
    onExecuteRequest: onExecuteRequestBotTopUsers,
    isLoading: isLoadingBotTopUsers = false,
    error: errorBotTopUsers,
    data: parsedBotTopUsers,
  } = useRequestWithMethod("get_bot_top_users", [], true);

  // Is View Favourite
  const {
    onExecuteRequest: onExecuteRequestIsViewFavourite,
    isLoading: isLoadingIsViewFavourite,
    error: errorIsViewFavourite,
    data: parsedDataIsFavourite,
  } = useRequestWithMethod("get_social_node", undefined, true);

  // Check Is Favourite Callback
  const checkIsFavourite = useCallback((nodeId: number) => {
    onExecuteRequestIsViewFavourite(
      {},
      [nodeId],
      undefined,
      undefined,
      undefined,
      getParsedIsViewFavourite
    );
  }, []);

  // Callbacks: Select All and Clear All
  // Unselects all displayed checkboxes and records
  const handleClearAll = useCallback(() => {
    setIsSelectAll(false);
    setSelectedNodes([]);
    gridRef?.current?.api?.deselectAll();
  }, []);

  const handleShowHiddenRecords = useCallback((e) => {
    setShowHiddenRecords(e?.target?.checked || false);
  }, []);

  // Selects all records and displayed checkboxes
  const handleSelectAll = useCallback(() => {
    setIsSelectAll(true);
    gridRef?.current?.api?.selectAll();
  }, []);

  // GET: Current Node
  const onSuccessCurrentNode = useCallback((response) => {
    const node = getParsedCurrentNode(response);

    checkIsFavourite(node?.node_id);
  }, []);

  const {
    parsedData: parsedCurrentNode,
    isLoading: isLoadingCurrentNode,
    error: errorCurrentNode,
    refetch: refetchCurrentNode,
    isFetching: isFetchingCurrentNode,
  } = useGetCurrentNode(type, parentNodeId, onSuccessCurrentNode);

  const currentNodeId = parsedCurrentNode?.node_id;
  const currentNodeCreatedById = parsedCurrentNode?.created_by_id;

  const getBotEngagementsAndTopUsers = useCallback(() => {
    if (isChatListingPage) {
      onExecuteRequestBotEngagements(
        {
          filterModel: {},
        },
        [currentNodeId, parentNodeId, new Date().getTimezoneOffset()]
      );

      onExecuteRequestBotTopUsers(
        {
          filterModel: {},
        },
        [currentNodeId, parentNodeId, new Date().getTimezoneOffset()]
      );
    }
  }, [isChatListingPage, currentNodeId, parentNodeId]);

  useEffect(() => {
    if (parentNodeId && currentNodeId) {
      getBotEngagementsAndTopUsers();
    }
  }, [currentNodeId]);

  // POST/PUT/DELETE APIs
  // Get Table Data
  const onSuccess = useCallback(
    (response, isShowSavedMessage?: boolean) => {
      const data = response?.data;

      setTotalRecords(data?.totalRecords);
      // setTotalRecords(data?.data?.length);

      setRecordsShownCount(data?.endRow - data?.startRow);
      const renderedRowData: NodeDataParserReturnType["data"] = [];

      gridRef?.current?.api?.forEachNode((node) => {
        renderedRowData.push(node?.data);
      });

      const parsedData = getParsedNodeData(response);

      const updatedParsedData = {
        ...parsedData,
        data: parsedData?.data?.map((item, index): { [x: string]: any } => ({
          ...item,
          // isHidden: index <= 1 && type === "TBL",
          // classificationTag: index <= 1 ? "Sensitive Data" : "",
          // ...(domainsKeys[type] &&
          //   !isViewContainingDomainColumn &&
          //   !isOutsideListingVews && {
          //     [joinWordsWithUnderscore(
          //       domainsKeys[type] || ""
          //     )]: domainsFiltersValues[0],
          //   }),
          // ...(sourcesKeys[type] &&
          //   !isViewContainingSourceColumn &&
          //   !isOutsideListingVews && {
          //     [joinWordsWithUnderscore(
          //       sourcesKeys[type] || ""
          //     )]: sourcesFiltersValues[0],
          //   }),
        })),
      };

      !isRulePage && refreshDataFromParentComp && onDataFetchSuccess?.();

      const newUpdateItems: any[] = [];
      const addItems: {
        addIndex: number;
        add: KeyValuePairType<any>[];
      }[] = [];

      if (renderedRowData?.length === 0 && updatedParsedData?.data?.length) {
        addItems.push({ addIndex: 0, add: updatedParsedData?.data });
      } else {
        ([...updatedParsedData?.data] || [])?.forEach((item, index) => {
          const rowNode = gridRef?.current?.api?.getRowNode(`${item?.id}`);
          if (rowNode) {
            newUpdateItems.push({ ...item });
          } else {
            addItems.push({ addIndex: index, add: [{ ...item }] });
          }
        });
      }

      const parsedDataIds = updatedParsedData?.data?.map((item) => item?.id);

      const newRemoveItems = renderedRowData?.filter(
        (rowData) => !parsedDataIds.includes(rowData?.id)
      );

      if (newUpdateItems?.length) {
        gridRef?.current?.api?.applyTransaction({
          update: newUpdateItems,
        });
      }

      if (addItems?.length) {
        addItems?.forEach((item) => {
          gridRef?.current?.api?.applyTransaction(item);
        });
      }

      if (newRemoveItems?.length) {
        gridRef?.current?.api?.applyTransaction({
          remove: newRemoveItems,
        });
      }

      setIsPopulateDataCalled(false);

      gridRef?.current?.api?.refreshCells({
        force: true,
        columns: [
          "match_status",
          "table_name",
          "column_name",
          "table",
          "rule_id",
          "job_id",
        ],
        suppressFlash: true,
      });

      isShowSavedMessage &&
        openNotification(
          <SuccessNotificationMessage message="Your changes have been saved" />
        );
    },
    [
      parsedCurrentNode,
      sourcesKeys,
      domainsKeys,
      domainsFiltersValues,
      sourcesFiltersValues,
      showHiddenRecords,
      refreshDataFromParentComp,
    ]
  );

  const onFailureOfNodeData = useCallback(
    async (e) => {
      if (e?.formattedMsg === "Unable to access deleted view.") {
        await refetchCurrentNode();
        await refetchGovernanceViews();
      }
    },
    [parsedCurrentNode]
  );

  const {
    isLoading: isLoadingNodeData,
    error: errorNodeData,
    onExecuteRequest,
    data: parsedTableData,
  } = useRequestWithMethod(
    "get_node_data",
    undefined,
    true,
    onSuccess,
    onFailureOfNodeData
  );

  const updatedColWithHiddenFields = useMemo(() => {
    const isViewContainingFieldType = isOutsideListingVews
      ? parsedCurrentNode?.columns?.some(
          (item: any) => item?.headerName === fieldTypeKeys[type]
        )
      : true;

    const hiddenFieldTypeCols =
      isOutsideListingVews &&
      fieldTypeKeys[type] &&
      !isViewContainingFieldType &&
      parsedCurrentNode?.columns
        ? [
            {
              column_id: "FieldType",
              field: joinWordsWithUnderscore(fieldTypeKeys[type] || ""),
              headerName: sourcesKeys[type],
              hide: true,
            },
          ]
        : [];

    const isViewContainingSourceColumn = parsedCurrentNode?.columns?.some(
      (item: any) => item?.headerName === sourcesKeys[type]
    );

    const hiddenSourceColumn =
      !isOutsideListingVews &&
      sourcesKeys[type] &&
      !isViewContainingSourceColumn &&
      parsedCurrentNode?.columns
        ? [
            {
              column_id: "Sources",
              field: joinWordsWithUnderscore(sourcesKeys[type] || ""),
              headerName: sourcesKeys[type],
              flex: 1,
              minWidth: 220,
              filter: "agSetColumnFilter",
              floatingFilter: true,
              menuTabs: ["generalMenuTab"],
              filterParams: {
                filterOptions: [
                  "equals",
                  "notEqual",
                  "lessThan",
                  "lessThanOrEqual",
                  "greaterThan",
                  "greaterThanOrEqual",
                  "inRange",
                  {
                    displayKey: "blanks",
                    displayName: "Blanks",
                    hideFilterInput: true,
                  },
                  {
                    displayKey: "nonBlanks",
                    displayName: "Non-Blanks",
                    hideFilterInput: true,
                  },
                ],
                buttons: ["apply"],
                selectAllOnMiniFilter: true,
                suppressSorting: true,
                closeOnApply: true,
                inRangeInclusive: true,
                excelMode: "windows",
                showTooltips: true,
              },
              headerCheckboxSelection: true,
              checkboxSelection: true,
              hide: true,
            },
          ]
        : [];

    const isViewContainingDomainColumn = parsedCurrentNode?.columns?.some(
      (item: any) => item?.headerName === domainsKeys[type]
    );

    const hiddenDomainColumn =
      !isOutsideListingVews &&
      domainsKeys[type] &&
      !isViewContainingDomainColumn &&
      parsedCurrentNode?.columns
        ? [
            {
              column_id: "Domains",
              field: joinWordsWithUnderscore(domainsKeys[type] || ""),
              headerName: domainsKeys[type],
              flex: 1,
              minWidth: 220,
              filter: "agSetColumnFilter",
              floatingFilter: true,
              menuTabs: ["generalMenuTab"],
              filterParams: {
                filterOptions: [
                  "equals",
                  "notEqual",
                  "lessThan",
                  "lessThanOrEqual",
                  "greaterThan",
                  "greaterThanOrEqual",
                  "inRange",
                  {
                    displayKey: "blanks",
                    displayName: "Blanks",
                    hideFilterInput: true,
                  },
                  {
                    displayKey: "nonBlanks",
                    displayName: "Non-Blanks",
                    hideFilterInput: true,
                  },
                ],
                buttons: ["apply"],
                selectAllOnMiniFilter: true,
                suppressSorting: true,
                closeOnApply: true,
                inRangeInclusive: true,
                excelMode: "windows",
                showTooltips: true,
              },
              headerCheckboxSelection: true,
              checkboxSelection: true,
              hide: true,
            },
          ]
        : [];

    const isViewContainingDashboardColumn = parsedCurrentNode?.columns?.some(
      (item: any) => item?.headerName === urlFilterColumn
    );

    const hiddenDashboardColumn =
      !isOutsideListingVews &&
      urlFilterColumn &&
      !isViewContainingDashboardColumn
        ? [
            {
              column_id: joinWordsWithUnderscore(urlFilterColumn),
              field: joinWordsWithUnderscore(urlFilterColumn),
              headerName: urlFilterColumn,
              flex: 1,
              minWidth: 220,
              filter: "agSetColumnFilter",
              floatingFilter: true,
              menuTabs: ["generalMenuTab"],
              filterParams: {
                filterOptions: [
                  "equals",
                  "notEqual",
                  "lessThan",
                  "lessThanOrEqual",
                  "greaterThan",
                  "greaterThanOrEqual",
                  "inRange",
                  {
                    displayKey: "blanks",
                    displayName: "Blanks",
                    hideFilterInput: true,
                  },
                  {
                    displayKey: "nonBlanks",
                    displayName: "Non-Blanks",
                    hideFilterInput: true,
                  },
                ],
                buttons: ["apply"],
                selectAllOnMiniFilter: true,
                suppressSorting: true,
                closeOnApply: true,
                inRangeInclusive: true,
                excelMode: "windows",
                showTooltips: true,
              },
              headerCheckboxSelection: true,
              checkboxSelection: true,
              hide: true,
            },
          ]
        : [];

    const finalColumns = [
      ...hiddenFieldTypeCols,
      ...hiddenDomainColumn,
      ...hiddenSourceColumn,
      ...hiddenDashboardColumn,
      ...(parsedCurrentNode?.columns || []),
    ]?.map((item) => {
      const { field = "" } = item || {};

      const dynamicWidthColKeys = getObjectKeys(
        parsedCurrentNode?.dynamic_width_cols
      );

      const colValues = dynamicWidthColKeys?.includes(field)
        ? parsedTableData?.data?.map((tblItem: any) =>
            (tblItem?.[field] || "")?.toString()
          )
        : [];

      const widthOfElement = colValues?.length
        ? calculateElementSize(findMaxLengthString(colValues), {
            fontSize: "15",
          })?.clientWidth
        : 0;

      const widthOfHeaderName = colValues?.length
        ? calculateElementSize(item?.headerName, {
            fontSize: "15",
          })?.clientWidth
        : 0;

      const finalWidth = Math.max(...[widthOfElement, widthOfHeaderName]);

      const isHeaderWidthHigherThanElem = widthOfHeaderName > widthOfElement;

      return {
        ...item,
        ...(finalWidth && {
          minWidth:
            finalWidth +
            (parsedCurrentNode?.dynamic_width_cols?.[field] ||
            isHeaderWidthHigherThanElem
              ? 130
              : 80),
          flex: 0,
        }),
      };
    });

    return finalColumns;
  }, [
    parsedCurrentNode?.columns,
    parsedCurrentNode?.dynamic_width_cols,
    sourcesKeys,
    domainsKeys,
    isLoadingNodeData,
    fieldTypeKeys,
    type,
    urlFilterColumn,
  ]);

  // Callback: Get filter model object
  const getFilterModel = useCallback(
    (filters?: KeyValuePairType<any>) => {
      const filterModel =
        filters || gridRef?.current?.api?.getFilterModel() || {};

      return parseFilterModel(filterModel, updatedColWithHiddenFields, true);
    },
    [updatedColWithHiddenFields]
  );

  // API Integration: Download View Link
  const onSuccessDownloadView = useCallback((response) => {
    const downloadLink = getParsedDownloadView(response);

    window.open(downloadLink, "_self");

    openNotification(
      <>
        <span className="action-text">{solidSuccessTickIcon}</span> Downloading
        file
      </>
    );
  }, []);

  const {
    onExecuteRequest: onExecuteRequestDownloadView,
    isLoading: isLoadingDownloadView,
  } = useRequestWithMethod(
    "download_governance_view",
    undefined,
    false,
    onSuccessDownloadView
  );

  const {
    onExecuteRequest: onExecuteRequestFieldData,
    error: errorFieldData999,
  } = useRequestWithMethod("get_field_data_999", undefined, true);

  const getDataApiCommonProps: GetDataApiCommonPropsType = useCallback(
    (params) => {
      const { nodeId, columns, filters, triggeredFrom } = params;

      const sortColumnName = (columns || parsedCurrentNode?.columns)?.find(
        (item: any) => item?.headerName === sortColumn?.sortColName
      )
        ? sortColumn?.sortColName
        : (columns || parsedCurrentNode?.columns)?.[0]?.headerName;

      const updatedPagination =
        (nodeId && columns) || triggeredFrom === "filter_change"
          ? { ...pagination, currentPage: initialPagination?.currentPage }
          : pagination;

      const hiddenRecordsFilter =
        !showHiddenRecords && (type === "TBL" || type === "COL")
          ? {
              is_hidden: {
                values: ["false"],
                filterType: "set",
              },
            }
          : {};

      const sortAndFilterModels = {
        sortModel:
          sortColumn?.sortColName && sortColumn?.sortType
            ? [
                {
                  colId: sortColumnName,
                  sort: sortColumn?.sortType,
                },
              ]
            : [],

        filterModel: !isOutsideListingVews
          ? gridInitializingFirstTimeRef?.current
            ? globalFilterModel
            : {
                ...getFilterModel(filters),
                ...hiddenRecordsFilter,
              }
          : { ...hiddenRecordsFilter },
        countFilter: selectedFilter,
      };

      const manageAccessFilter =
        type === "TRM"
          ? parsedGlossary?.TRM?.filter_sql_stmt
          : parsedCatalog?.[type]?.filter_sql_stmt;

      return {
        payload: {
          ...(!isOutsideListingVews && {
            pageNumber: updatedPagination?.currentPage,
            pageSize: updatedPagination?.pageSize,
          }),
          manage_access_filter: manageAccessFilter || "",
          ...sortAndFilterModels,
          tableId,
          jobDefId,
          widgetId,
          mandatory_fields: [
            ...(parsedCurrentNode?.mandatory_fields_ids || []),
            urlFilterColumnId,
          ],
          timezone: new Date().getTimezoneOffset(),
        },
        updatedPagination,
      };
    },
    [
      tableId,
      pagination,
      parsedCurrentNode,
      sortColumn,
      type,
      selectedFilter,
      showHiddenRecords,
      widgetId,
      urlFilterColumnId,
    ]
  );

  // Callback: Populate Data
  const populateData = useCallback(
    (params: PopulateDataParams) => {
      const { nodeId, columns, isShowSavedMessage, triggeredFrom } = params;

      const dataApiCommonProps = getDataApiCommonProps(params);

      onExecuteRequest(
        {
          ...dataApiCommonProps?.payload,
        },
        [
          nodeId !== undefined ? nodeId : parsedCurrentNode?.node_id,
          parentNodeId,
        ],
        (response) => onSuccess(response, isShowSavedMessage)
      );

      if (
        triggeredFrom === "edit_governance_view" ||
        triggeredFrom === "refresh_governance_view"
      ) {
        getBotEngagementsAndTopUsers();
      }

      ((nodeId && columns) || triggeredFrom === "filter_change") &&
        setPagination(dataApiCommonProps?.updatedPagination);
      handleClearAll();
    },
    [
      parsedCurrentNode,
      onSuccess,
      onExecuteRequest,
      parentNodeId,
      getDataApiCommonProps,
    ]
  );

  const handleResetPagination = useCallback((isKeepPageSize?: boolean) => {
    setPagination((s) =>
      isKeepPageSize
        ? { ...initialPagination, pageSize: s?.pageSize }
        : initialPagination
    );
  }, []);

  const applyGlobelFiltersToGridFilterModel = useCallback(() => {
    gridRef?.current?.api?.setFilterModel({
      [joinWordsWithUnderscore(sourcesKeys[type] || "")]: {
        filterType: "set",
        values: sourcesFiltersValues?.length ? [...sourcesFiltersValues] : null,
      },
      [joinWordsWithUnderscore(urlFilterColumn)]: {
        filterType: "set",
        values: [urlFilterValue],
      },
      [joinWordsWithUnderscore(domainsKeys[type] || "")]: {
        filterType: "set",
        values: domainsFiltersValues?.length ? domainsFiltersValues : null,
      },
    });
  }, [
    gridRef,
    sourcesKeys,
    domainsKeys,
    sourcesFiltersValues,
    domainsFiltersValues,
    type,
    urlFilterValue,
    urlFilterColumn,
  ]);

  const handleResetGridStates = useCallback(
    (
      isComingFrom?:
        | "edit_view"
        | "clone_view"
        | "create_view"
        | "refresh_view"
        | "delete_view"
    ): void => {
      const filterModel = gridRef?.current?.api?.getFilterModel() || {};
      getObjectLength(filterModel) && setIsPopulateDataCalled(true);

      if (isComingFrom !== "refresh_view") {
        gridInitializingFirstTimeRef.current = isGlobalFilterKeysExists;
        handleResetPagination(true);
      } else if (isComingFrom === "refresh_view") {
        populateData({ triggeredFrom: "refresh_governance_view" });
      }
    },
    [appliedFiltersKeys, populateData, isGlobalFilterKeysExists]
  );

  const isAssignedToMeTabActive =
    selectedFilter === LISTING_PAGE_TAB_FILTER_VALUES?.ASSIGNED_TO_COUNT;

  // Tile filters memoized variable
  const tileFilters = useMemo(() => {
    return getTileFiltersList(parsedTableData?.summary?.[0] || {}, type);
  }, [parsedTableData, type]);

  const handleChangeTileFilter = useCallback(
    (filter: string, nodeId?: number) => {
      const currentPath = history?.location;
      const availableFilters = tileFilters?.map((tileFilter) => tileFilter?.id);
      const selectedFilter = availableFilters?.includes(filter)
        ? filter
        : "all";
      const { SELECTED_FILTER, NODE_ID } = QUERY_PARAMS;

      history?.push({
        ...currentPath,
        search: `?${SELECTED_FILTER}=${selectedFilter}&${NODE_ID}=${
          nodeId || paramNodeId || ""
        }`,
      });

      handleResetPagination();
    },
    [tileFilters, paramNodeId]
  );

  // Change Governance View
  const onSuccessChangeView = useCallback(
    (response) => {
      const data = response?.data;

      onSetData(getCurrentNode, data, [type, parentNodeId]);

      const parsedData = getParsedCurrentNode(response);

      setIsPopulateDataCalled(true);
      handleResetGridStates();
      handleChangeTileFilter("all", parsedData?.node_id);
      populateData({
        nodeId: parsedData?.node_id,
        columns: parsedData?.columns,
        filters: {},
      });
    },
    [type, selectedFilter, handleResetGridStates, populateData, parentNodeId]
  );

  const {
    isLoading: isLoadingChangeView = false,
    error: errorChangeView,
    onExecuteRequest: onExecuteRequestChangeView,
  } = useRequestWithMethod(
    "change_governance_view",
    undefined,
    true,
    onSuccessChangeView
  );

  // Delete Gov View
  const onSuccessDeleteView = useCallback(
    (response) => {
      const data = response?.data;
      const deletedNodeId = parsedCurrentNode?.node_id;

      onSetData(getCurrentNode, data, [type, parentNodeId]);
      onSetData(
        getGovernanceViews,
        { id: deletedNodeId },
        [type, parentNodeId],
        {
          action: "DELETE",
          key: "SEARCH_ID",
        }
      );

      const parsedData = getParsedCurrentNode(response);

      const { SELECTED_FILTER, NODE_ID } = QUERY_PARAMS;
      const currentPath = history?.location;

      setIsPopulateDataCalled(true);
      handleResetGridStates();
      populateData({
        nodeId: parsedData?.node_id,
        columns: parsedData?.columns,
        filters: {},
      });

      history?.push({
        ...currentPath,
        search: `?${SELECTED_FILTER}=${selectedFilter}&${NODE_ID}=${
          parsedData?.node_id || ""
        }`,
      });

      onCancel();
    },
    [
      parsedCurrentNode,
      selectedFilter,
      handleResetGridStates,
      populateData,
      parentNodeId,
    ]
  );

  const {
    onExecuteRequest: onExecuteRequestDeleteView,
    isLoading: isLoadingDeleteView,
    error: errorDeleteView,
  } = useRequestWithMethod(
    "delete_governance_view",
    [parsedCurrentNode?.node_id, type],
    true,
    onSuccessDeleteView
  );

  // Save Gov View
  const onSuccessSaveView = useCallback(
    async (isFavourite: boolean, nodeId: number) => {
      await Promise.all([refetchGovernanceViews(), checkIsFavourite(nodeId)]);

      openNotification(
        <SuccessNotificationMessage
          message={`View ${
            isFavourite ? "removed from" : "added to"
          } favorites`}
        />
      );
    },
    []
  );

  const {
    onExecuteRequest: onExecuteRequestSaveView,
    isLoading: isLoadingSaveView,
    error: errorSaveView,
  } = useRequestWithMethod("add_follow_action");

  // Callbacks
  // Delete governance view
  const handleDeleteGovernanceView = useCallback(() => {
    onCancel();
    onExecuteRequestDeleteView?.({}, [parsedCurrentNode?.node_id, type]);
  }, [JSON.stringify(parsedCurrentNode), type]);

  // Edit/Clone governance view
  const handleEditCloneGovernanceView = useCallback(
    (isCloneView?: boolean) => {
      onOpenModal({
        modalId: !isCloneView
          ? "edit_governance_view"
          : "clone_governance_view",
        visible: true,
        modalTitle: !isCloneView ? "Edit View" : "Clone View",
        modalProps: {
          id: parsedCurrentNode?.node_id,
          view_name: parsedCurrentNode?.selected_view,
          selected_fields: parsedCurrentNode?.columns?.map(
            (col) => `${col?.column_id || 0}`
          ),
          actionButtons: {
            clone: {
              onClick: (): void => handleEditCloneGovernanceView(true),
            },
            delete: {
              onClick: handleDeleteGovernanceView,
              disabled:
                parsedCurrentNode?.is_default_view ||
                (isViewer &&
                  parsedCurrentNode?.access_type !== "GOV_EDITOR" &&
                  parsedCurrentNode?.access_type !== "GOV_OWNER"),
            },
          },
          filter_criteria: parsedCurrentNode?.filterCriteria,
          type,
          populateData,
          resetGridStates: handleResetGridStates,
          checkIsFavourite,
          setIsPopulateDataCalled,
          parentNodeId,
        },
      });
    },
    [parsedCurrentNode, type, handleResetGridStates, populateData, parentNodeId]
  );

  // Add new governance view
  const handleAddGovernanceView = useCallback(() => {
    onOpenModal({
      modalId: "add_governance_view",
      visible: true,
      modalTitle: "Create View",
      modalProps: {
        type,
        selected_fields: parsedCurrentNode?.columns?.map(
          (col) => `${col?.column_id || 0}`
        ),
        actionButtons: {
          clone: {
            onClick: (): void => handleEditCloneGovernanceView(true),
            disabled: true,
          },
          delete: {
            onClick: handleDeleteGovernanceView,
            disabled: true,
          },
        },
        populateData,
        resetGridStates: handleResetGridStates,
        checkIsFavourite,
        setIsPopulateDataCalled,
        parentNodeId,
      },
    });
  }, [
    parsedCurrentNode,
    handleEditCloneGovernanceView,
    handleDeleteGovernanceView,
    handleResetGridStates,
    populateData,
    parentNodeId,
  ]);

  // Change governance view
  const handleChangeView = useCallback(
    (item) => {
      onExecuteRequestChangeView(undefined, [
        item?.id,
        type,
        "USR",
        parentNodeId,
      ]);

      gridInitializingFirstTimeRef.current = isGlobalFilterKeysExists;
      setAppliedFiltersKeys([]);
    },
    [
      type,
      selectedFilter,
      onExecuteRequestChangeView,
      isGlobalFilterKeysExists,
      parentNodeId,
    ]
  );

  // When user selects one or multiple rows
  const handleSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedNodes = event?.api?.getSelectedNodes();

    const nodes = selectedNodes?.map((node) => node?.data);

    setSelectedNodes(nodes);
  }, []);

  // Memoized Variable for Download View
  const visibleFields = useMemo(() => {
    return parsedCurrentNode?.columns?.map(
      (column) => column?.headerName || ""
    );
  }, [parsedCurrentNode]);

  // Download governance view
  const handleDownload = useCallback(() => {
    const sortColumnName = parsedCurrentNode?.columns?.find(
      (item: any) => item?.headerName === sortColumn?.sortColName
    )
      ? sortColumn?.sortColName
      : parsedCurrentNode?.columns?.[0]?.headerName;

    const dictionaryName = donwloadDict[type];

    onExecuteRequestDownloadView(
      {
        sortModel: sortColumn?.sortColName
          ? [
              {
                colId: sortColumnName,
                sort: sortColumn?.sortType,
              },
            ]
          : [],
        filterModel: {
          ...getFilterModel(),
          // WHENEVER USER DOWNLLOADS, SEND THE DEFAULT PAYLOAD
          ...(type === "TBL" || type === "COL"
            ? {
                is_hidden: {
                  values: ["false"],
                  filterType: "set",
                },
              }
            : {}),
        },
        countFilter: selectedFilter,
        visibleFields,
      },
      [parsedCurrentNode?.node_id, dictionaryName]
    );
  }, [
    parsedCurrentNode,
    visibleFields,
    type,
    sortColumn,
    selectedFilter,
    showHiddenRecords,
  ]);

  const handleImport = useCallback(() => {
    history?.push(getDataImportURL(2, type), {
      isFromListingPage: true,
    });
  }, []);

  // Favorite/Un-favorite governance view
  const handleSave = useCallback(() => {
    const isFavourite: boolean = parsedDataIsFavourite?.isFavourite;

    onExecuteRequestSaveView(
      {
        action: isFavourite ? "unsave" : "save",
      },
      [parsedCurrentNode?.node_id, "SRH"],
      () => onSuccessSaveView(isFavourite, parsedCurrentNode?.node_id)
    );
  }, [parsedCurrentNode, parsedDataIsFavourite]);

  // When user applies a filter
  const handleFilterChanged = useCallback(() => {
    if (!gridInitializingFirstTimeRef?.current && !isOutsideListingVews) {
      setIsPopulateDataCalled(true);
      handleResetPagination(true);
      populateData({ triggeredFrom: "filter_change" });
    }

    gridInitializingFirstTimeRef.current = false;

    const appliedFilterModels = gridRef?.current?.api?.getFilterModel();

    setAppliedFiltersKeys(getObjectKeys(appliedFilterModels));
  }, [parsedCurrentNode, populateData]);

  const sortModel = useMemo(() => {
    const sortColumnName = parsedCurrentNode?.columns?.find(
      (item: any) => item?.headerName === sortColumn?.sortColName
    )
      ? sortColumn?.sortColName
      : parsedCurrentNode?.columns?.[0]?.headerName;

    return sortColumn?.sortColName
      ? [
          {
            colId: sortColumnName,
            sort: sortColumn?.sortType,
          },
        ]
      : [];
  }, [parsedCurrentNode?.columns?.length, sortColumn]);

  // Populate filter values when user clicks funnel icon
  const getFilterValues = useCallback(
    (e: any) => {
      const columnName = e?.colDef?.headerName || "";

      const isSrcColumnName =
        sourcesKeys[type] && sourcesKeys[type] === columnName;

      const isDataDomainColumnName =
        domainsKeys[type] && domainsKeys[type] === columnName;

      // confirm from faizan
      const isFilterOnOtherColumn = urlFilterColumn === columnName;

      const globalFilterValues = isSrcColumnName
        ? sourcesFiltersValues
        : isDataDomainColumnName
        ? domainsFiltersValues
        : isFilterOnOtherColumn
        ? [urlFilterValue]
        : [];

      const manageAccessFilter =
        type === "TRM"
          ? parsedGlossary?.TRM?.filter_sql_stmt
          : parsedCatalog?.[type]?.filter_sql_stmt;

      const {
        selectedFilter: selectedFilterFromColDef,
        filterModel: parsedFilterModelForValues,
      } =
        (gridRef?.current?.api?.getColumnDefs()?.[0] as any)
          ?.headerComponentParams || {};

      delete parsedFilterModelForValues?.[columnName];

      onExecuteRequestFieldData(
        {
          filterModel: parsedFilterModelForValues,
          countFilter: selectedFilterFromColDef,
          manage_access_filter: manageAccessFilter || "",
          // sortModel,
        },
        [
          parsedCurrentNode?.node_id,
          columnName,
          // `${columnName},${sortModel?.[0]?.colId}`,
          type,
          isJobExecutionsPage ? jobDefId : parentNodeId,
        ],
        (response) => {
          const parsedFilterValues =
            getParsedFieldData999Values(response) || [];

          e?.success([...globalFilterValues, ...parsedFilterValues]);
        }
      );
    },
    [
      parsedCurrentNode,
      sourcesKeys,
      domainsKeys,
      sourcesFiltersValues,
      domainsFiltersValues,
      type,
      selectedFilter,
      parsedGlossary,
      parsedCatalog,
      urlFilterValue,
      onExecuteRequestFieldData,
      getFilterModel,
      parentNodeId,
      jobDefId,
      isJobExecutionsPage,
      sortModel,
      urlFilterColumn,
    ]
  );

  // Callback: Selected node ids. In case of select all it will be node ids of unselected nodes.
  const selectedNodesIds = useCallback(
    (nodeIds: string[]) => {
      const unselectedNodesIds =
        gridRef?.current?.api
          ?.getRenderedNodes()
          ?.filter((node) => !nodeIds?.includes(node?.id || ""))
          ?.map((node) => node?.id) || [];

      return isSelectAll
        ? recordsShownCount === selectedNodes?.length
          ? []
          : [...unselectedNodesIds]
        : nodeIds;
    },
    [selectedNodes, recordsShownCount]
  );

  // Memoized Variables: GRID ACTIONS
  // For mass update, add tags, remove tags
  const allSelectedNodesIds = useMemo(() => {
    const ids = selectedNodes?.map((node) => node?.id) || [];

    return selectedNodesIds(ids) || [];
  }, [selectedNodes]);

  const selectedRulesWithSourceInfo = useMemo(() => {
    const selectedNodesLength = selectedNodes?.length;

    const selectedNodeJobIds =
      selectedNodesLength === 1 ? selectedNodes?.[0]?.job_ids : "";

    const jobIds = jsonParse(selectedNodeJobIds || "");
    return (
      selectedNodes?.map((node) => ({
        node_id: node?.id,
        node_name: node?.rule_id,
        src_id: node?.src_id,
        src_name: node?.source,
        is_editable: node?.is_editable,
        business_name: node?.table_name,
        parent_name: node?.source,
        ...(jobIds?.length && { job_ids: jobIds }),
      })) || []
    );
  }, [selectedNodes]);

  // For delete terms
  const nonDefaultSelectedNodesIds = useMemo(() => {
    const ids = selectedNodes?.map((node) => node?.id) || [];

    return selectedNodesIds(ids) || [];
  }, [selectedNodes]);

  // Selected node tag ids. Returns array of ids or undefined
  const selectedNodeTagIds = useMemo(() => {
    return selectedNodes?.length === 1
      ? (selectedNodes?.[0]?.tag_ids as string)?.split(",")
      : undefined;
  }, [selectedNodes]);

  // Shows a count of the records that are selected
  const selectedRowsCount = useMemo(() => {
    const displayedNodesUnSelected = recordsShownCount - selectedNodes?.length;
    const selectedNodesCount = selectedNodes?.length;

    return !isSelectAll
      ? selectedNodesCount
      : totalRecords - displayedNodesUnSelected;
  }, [isSelectAll, selectedNodes, totalRecords, recordsShownCount]);

  const {
    onlineProfiling,
    onClickRunProfileOnline,
    onClickRunProfileOffline,
    onCancelProfilingClick,
    isProfilingInProgress,
    isProfilingFailed,
  } = useNodeProfiling({
    nodeType: type,
    nodeIds: allSelectedNodesIds?.map((item) => item || ""),
    onOnlineProfilingProcessCompletedInGrid: populateData,
    clearGridSelection: handleClearAll,
    gridRef,
    isSelectAll,
    countFilter: selectedFilter,
    listingGovViewId: `${parsedCurrentNode?.node_id}`,
    filterModel: getFilterModel(),
  });

  const nodeName = getPageNodeName(type);

  const onSuccessMassUpdate = useCallback(
    (response) => {
      const parsedRes = isTablePage
        ? jsonParse(response?.data?.FN_UPDATE_GOV_TBLS_DATA)
        : jsonParse(response?.data?.FN_UPDATE_GOV_COLS_DATA);

      if (parsedRes?.total_cnt) {
        openNotification(
          <SuccessNotificationMessage
            message={`${parsedRes?.total_cnt} ${nodeName}${
              parsedRes?.total_cnt > 1 ? "s have" : " has"
            } been updated.`}
          />
        );
      } else {
        openNotification(
          `No ${nodeName}(s) has been updated.`,
          undefined,
          {},
          "info"
        );
      }

      populateData({});
    },
    [populateData, selectedRowsCount, type, nodeName]
  );

  const {
    onExecuteRequest: onExecuteRequestMassUpdate,
    isLoading: isLoadingMassUpdate,
    error: errorMassUpdate,
  } = useRequestWithMethod(
    "update_field_data",
    [type, `${parsedCurrentNode?.node_id}`],
    false,
    onSuccessMassUpdate
  );

  // Callbacks: GRID ACTIONS
  // When user clicks on add new term button
  const onClickAdd = useCallback(() => {
    onOpenModal({
      modalId: type === "TRM" ? "add_term" : "add_table",
      visible: true,
      modalTitle: "Add Term",
    });
  }, []);

  const onAddNewJobSuccess = useCallback((newJobId) => {
    const url = getJobDetailPageUrl(newJobId);
    history?.push(url);
  }, []);

  const onAddNewExecuteViaApiSuccess = useCallback(() => {
    populateData?.({});
  }, [populateData]);

  const onClickAddJob = useCallback(() => {
    onOpenModal({
      modalId: "add_new_job_modal",
      visible: true,
      modalTitle: "Add Job",
      modalProps: {
        propsOnSave: onAddNewJobSuccess,
      } as AddNewJobModalProps,
    });
  }, [onAddNewExecuteViaApiSuccess]);

  // When user clicks on delete term button
  const onClickDelete = useCallback(() => {
    onOpenModal({
      modalId: "delete_terms",
      visible: true,
      modalTitle: "Delete Terms",
      modalProps: {
        nodeType: type,
        // IMPROVE THIS
        selectedNodesIds: nonDefaultSelectedNodesIds?.map(
          (item) => Number(item) || ""
        ),
        isSelectAll,
        selectedRowsCount,
        countFilter: selectedFilter,
        searchId: `${parsedCurrentNode?.node_id}`,
        filterModel: getFilterModel(),
        populateData,
      },
    });
  }, [
    nonDefaultSelectedNodesIds,
    type,
    isSelectAll,
    selectedRowsCount,
    selectedFilter,
    parsedCurrentNode,
    populateData,
  ]);

  const onClickHide = useCallback(() => {
    onExecuteRequestMassUpdate([
      {
        isSelectAll,
        countFilter: selectedFilter,
        filterModel: getFilterModel(),
        is_mass_update:
          isSelectAll || (!isSelectAll && selectedNodesIds?.length > 1),
        [type === "TBL" ? "tbl_ids" : "col_ids"]: [
          ...(allSelectedNodesIds || []),
        ],
        is_hidden: true,
      },
    ]);
  }, [
    type,
    selectedNodes,
    isSelectAll,
    selectedFilter,
    allSelectedNodesIds,
    parsedCurrentNode,
    selectedRowsCount,
    getFilterModel,
  ]);

  const onClickUnHide = useCallback(() => {
    onExecuteRequestMassUpdate([
      {
        isSelectAll,
        countFilter: selectedFilter,
        filterModel: getFilterModel(),
        is_mass_update:
          isSelectAll || (!isSelectAll && selectedNodesIds?.length > 1),
        [type === "TBL" ? "tbl_ids" : "col_ids"]: [
          ...(allSelectedNodesIds || []),
        ],
        is_hidden: false,
      },
    ]);
  }, [
    type,
    selectedNodes,
    isSelectAll,
    selectedFilter,
    allSelectedNodesIds,
    parsedCurrentNode,
    selectedRowsCount,
    getFilterModel,
  ]);

  // When user clicks on mass update button
  const onClickMassUpdate = useCallback(() => {
    const nodeData =
      selectedNodes?.length === 1 ? selectedNodes?.[0] : undefined;

    onOpenModal({
      modalId: "mass_update",
      visible: true,
      modalTitle: "Mass Update",
      modalProps: {
        nodeType: type,
        nodeData,
        isSelectAll,
        selectedNodesIds: allSelectedNodesIds,
        countFilter: selectedFilter,
        filterModel: getFilterModel(),
        searchId: `${parsedCurrentNode?.node_id}`,
        selectedRowsCount,
        populateData,
      },
    });
  }, [
    type,
    selectedNodes,
    isSelectAll,
    selectedFilter,
    allSelectedNodesIds,
    parsedCurrentNode,
    selectedRowsCount,
    populateData,
  ]);

  const onSuccessRunProfile = useCallback((res) => {
    const jobName = res?.data?.[0]?.SCAN_NAME || "";
    const srcId = res?.data?.[0]?.SRC_ID || "";
    openNotification(
      <SuccessNotificationMessage
        showSuccessText={false}
        message={
          <span>
            <LinkButton
              isTextUnderLine
              onClick={(): void => {
                history?.push(
                  `${APP_ROUTES.private_routes.sources}/${srcId}/scan_history`
                );
              }}
              marginLeft="3px"
              marginRight="3px"
            >
              {jobName}
            </LinkButton>{" "}
            Job scheduled successfully.
          </span>
        }
      />
    );
  }, []);

  const onRunNodeProfilingSuccess = useCallback(
    (res) => {
      const jobName = res?.data?.job_id;
      openNotification(
        <SuccessNotificationMessage
          message={
            <span>
              <LinkButton isTextUnderLine marginLeft="3px" marginRight="3px">
                {jobName}
              </LinkButton>{" "}
              {type === "RLS" ? "Rules " : "Profiling"} Job scheduled
              successfully.
            </span>
          }
        />
      );
      handleClearAll();
    },
    [type]
  );

  //Run Profile method
  const {
    isLoading: isRunningProfile,
    onExecuteRequest: onRunProfile,
    error: isRuuningProfileError,
  } = useRequestWithMethod("run_scan", undefined, true, onSuccessRunProfile);

  // Update Tags: Add/Overwrite
  const handleAddTags = useCallback(() => {
    onOpenModal({
      modalId: "listing_page_add_tags",
      visible: true,
      modalTitle: "Add Tags",
      modalProps: {
        nodeType: type,
        tags: selectedNodeTagIds,
        isSelectAll,
        selectedNodesIds: allSelectedNodesIds,
        countFilter: selectedFilter,
        filterModel: getFilterModel(),
        searchId: `${parsedCurrentNode?.node_id}`,
        selectedRowsCount,
        populateData,
      },
    });
  }, [
    type,
    isSelectAll,
    selectedFilter,
    selectedNodeTagIds,
    allSelectedNodesIds,
    parsedCurrentNode,
    selectedRowsCount,
    populateData,
  ]);

  // Update Tags: Remove Tags
  const handleRemoveTags = useCallback(() => {
    onOpenModal({
      modalId: "listing_page_remove_tags",
      visible: true,
      modalTitle: "Remove Tags",
      modalProps: {
        nodeType: type,
        tags: selectedNodeTagIds,
        isSelectAll,
        selectedNodesIds: allSelectedNodesIds,
        countFilter: selectedFilter,
        filterModel: getFilterModel(),
        searchId: `${parsedCurrentNode?.node_id}`,
        selectedRowsCount,
        populateData,
      },
    });
  }, [
    type,
    isSelectAll,
    selectedFilter,
    selectedNodeTagIds,
    allSelectedNodesIds,
    parsedCurrentNode,
    selectedRowsCount,
    populateData,
  ]);

  // API Integration: Match Status
  const onSuccessMatchStatus = useCallback(() => {
    onCancel();
    populateData({ isShowSavedMessage: true });
  }, [parsedCurrentNode, populateData]);

  const {
    onExecuteRequest: onExecuteRequestMatchStatus,
    isLoading: isLoadingMatchStatus,
    error: errorMatchStatus,
  } = useRequestWithMethod(
    "update_field_data",
    [type, currentNodeId],
    false,
    onSuccessMatchStatus
  );

  const onMassUpdateStatus = useCallback(
    (statusId: CaddiAnalyticsStatusIdType) => {
      onExecuteRequestMatchStatus([
        {
          field: "qry_ids",
          qry_fdbk_status: statusId,
          qry_ids: allSelectedNodesIds,
          is_mass_update: allSelectedNodesIds?.length > 1,
          isSelectAll,
          filterModel: getFilterModel(),
          countFilter: selectedFilter,
        },
      ]);
    },
    [allSelectedNodesIds, isSelectAll, selectedFilter, getFilterModel]
  );

  const updateFeedbackStatusConfig = useMemo((): UpdateFeedbackStatusConfigType => {
    const commonApiProps = {
      nodeType: type,
      currentNodeId,
      isSelectAll,
      filterModel: getFilterModel(),
      countFilter: selectedFilter,
    };

    return {
      sortedFeedbackStatuses,
      commonApiProps,
      populateData,
    };
  }, [
    sortedFeedbackStatuses,
    isSelectAll,
    getFilterModel,
    selectedFilter,
    type,
    currentNodeId,
    populateData,
  ]);

  // Use Effects

  useEffect(() => {
    if (
      !isLoadingCurrentNode &&
      parsedCurrentNode &&
      !isFetchingCurrentNode &&
      !isPopulateDataCalled &&
      !Number.isNaN(paramNodeId) &&
      paramNodeId === parsedCurrentNode?.node_id
    ) {
      populateData({});
    }
  }, [
    isLoadingCurrentNode,
    jsonStringify(pagination),
    jsonStringify(sortColumn),
    selectedFilter,
    isFetchingCurrentNode,
    paramNodeId,
    showHiddenRecords,
  ]);

  useEffect(() => {
    if (refreshDataFromParentComp) {
      populateData({});
    }
  }, [refreshDataFromParentComp]);

  useEffect(() => {
    if (
      !isLoadingCurrentNode &&
      !isFetchingCurrentNode &&
      !isLoadingChangeView &&
      !isLoadingGovernanceViews
    ) {
      const { NODE_ID, SELECTED_FILTER } = QUERY_PARAMS;

      if (Number.isNaN(paramNodeId)) {
        const currentPath = history?.location;

        if (urlFilterValue) {
          // redirect useer to default view when source filter is selected

          const defaultViewId = parsedGovernanceViews?.default?.[0]?.id;
          handleChangeView({ id: defaultViewId });
        } else {
          history?.replace({
            ...currentPath,
            search: `?${SELECTED_FILTER}=${selectedFilter}&${NODE_ID}=${parsedCurrentNode?.node_id}`,
          });
        }
      } else if (paramNodeId !== parsedCurrentNode?.node_id) {
        handleChangeView({ id: paramNodeId });
      }
    }
  }, [
    isLoadingCurrentNode,
    isFetchingCurrentNode,
    isLoadingChangeView,
    urlFilterValue,
    isLoadingGovernanceViews,
    // paramNodeId,
  ]);

  // Memoized variables
  const governanceViews = useMemo(() => {
    const dropdownData: { [key: string]: any } = {
      "": parsedGovernanceViews?.default,
    };

    if (parsedGovernanceViews?.favourites?.length > 0)
      dropdownData.Favorites = parsedGovernanceViews?.favourites;

    if (parsedGovernanceViews?.createdByMe?.length > 0)
      dropdownData["Created by me"] = parsedGovernanceViews?.createdByMe;

    if (parsedGovernanceViews?.sharedWithMe?.length > 0)
      dropdownData["Shared with me"] = parsedGovernanceViews?.sharedWithMe;

    return dropdownData;
  }, [parsedGovernanceViews]);

  const pageHeading = useMemo(() => {
    switch (type) {
      case "COL":
        return "Columns";

      case "TBL":
        return "Tables";

      case "DSR":
        return "Analytics Dictionary";

      case "RLS":
        return "Rules";

      case "JOB":
        return "Jobs";

      case "SCH":
        return "Job Executions";

      case "TRM":
      default:
        return "Business Glossary";
    }
  }, [type]);

  const isRunProfileDisable = useMemo(() => {
    return isTablePage || isColumnPage
      ? getUniqueList(selectedNodes?.map((node) => node?.src_id))?.length > 1
      : false;
  }, [selectedNodes, isTablePage, isColumnPage]);

  const formattedErrorNodeData =
    errorNodeData?.formattedMsg === "Unable to access deleted view."
      ? ""
      : errorNodeData;

  const disableGlossaryHeader =
    isLoadingNodeData || isLoadingMatchStatus || errorNodeData instanceof Error;

  const termDisableBasedOnRole =
    type === "TRM" && (isViewer || !glossaryDomainAndSubdomain?.length);

  const massUpdateDisableBasedOnRole =
    type === "TRM" &&
    ((isViewer && !isAssignedToMeTabActive) ||
      (!glossaryDomainAndSubdomain?.length && !isAssignedToMeTabActive));

  const isFetchingData =
    isLoadingCurrentNode ||
    isLoadingNodeData ||
    isLoadingChangeView ||
    isLoadingDeleteView ||
    isFetchingCurrentNode ||
    isLoadingMatchStatus ||
    isLoadingFeedbackStatuses ||
    isLoadingMassUpdate ||
    isLoadingGovernanceViews;

  const isFetchingViewConfig =
    isLoadingCurrentNode ||
    isLoadingGovernanceViews ||
    isLoadingDeleteView ||
    isLoadingChangeView ||
    isFetchingCurrentNode;

  const isErrorGridData =
    (errorCurrentNode ||
      formattedErrorNodeData ||
      errorChangeView ||
      errorDeleteView ||
      errorFieldData999 ||
      errorMatchStatus ||
      errorFeedbackStatuses ||
      errorMassUpdate) instanceof Error;

  const clearFilters = useCallback(
    (key?: string) => {
      key
        ? gridRef?.current?.api?.destroyFilter(key)
        : appliedFiltersKeys?.forEach((item) => {
            gridRef?.current?.api?.destroyFilter(item);
          });
    },
    [gridRef, appliedFiltersKeys]
  );

  useEffect(() => {
    if (
      gridInitializingFirstTimeRef?.current === true &&
      parsedTableData?.data
    ) {
      applyGlobelFiltersToGridFilterModel?.();
    }
  }, [parsedTableData]);

  const isLoadingDataCond = isFetchingData || isErrorGridData;

  useEffect(() => {
    if (isOutsideListingVews) {
      onChangeLoading?.(isFetchingData || isFetchingViewConfig);
    }
  }, [isFetchingData, isFetchingViewConfig]);

  const showSelectedRowsGridActions = useMemo(() => {
    return !!selectedRowsCount;
  }, [selectedRowsCount]);

  const isAllSelectedNodesAreHidden = useMemo(
    () =>
      !!selectedNodes?.length &&
      selectedNodes?.every((node) => node?.is_hidden),

    [selectedNodes]
  );

  const isAllSelectedNodesAreUnHidden = useMemo(() => {
    return (
      !!selectedNodes?.length &&
      selectedNodes?.every((node) => !node?.is_hidden)
    );
  }, [selectedNodes]);

  const isAllSelectedNodesAreActivated = useMemo(
    () =>
      !!selectedNodes?.length &&
      selectedNodes?.every((node) => node?.is_active),

    [selectedNodes]
  );

  const isAllSelectedNodesAreDeactivated = useMemo(() => {
    return (
      !!selectedNodes?.length &&
      selectedNodes?.every((node) => !node?.is_active)
    );
  }, [selectedNodes]);

  const appliedFilterKeyHasLength = !!appliedFiltersKeys?.length;

  const showGlobalFiltersTagWrapper =
    (appliedFilterKeyHasLength && !isOutsideListingVews) ||
    (appliedFilterKeyHasLength && isOutsideListingVews && isJobExecutionsPage);

  const profilingProgress = (
    <OnlineProfilingProgressBar
      onCancelProfilingClick={onCancelProfilingClick}
      onClickRunProfileOnline={onClickRunProfileOnline}
      progressPercenatge={onlineProfiling?.progressPercenatge}
      textMessage={
        type === "RLS" ? "Running in Progress:" : "Profiling in Progress:"
      }
      profilingStatus={isProfilingFailed ? "error" : "info"}
      profilingJobId={onlineProfiling?.jobId}
    />
  );

  // we need to add jobs/sch in global filters key

  const isJobRelatedPage = isJobExecutionsPage || isJobPage;

  const isDataRelatedPage =
    isColumnPage ||
    isTablePage ||
    isTermPage ||
    isDatasetReportsPage ||
    isRulePage;

  const hasGlobalFilters =
    urlFilterValue ||
    domainsGlobalFiltersList?.length > 0 ||
    sourcesGlobalFiltersList?.length > 0;

  const hasKeysForCurrentType =
    sourcesKeys?.[type] || domainsKeys?.[type] || urlFilterColumn;

  const isGlobalFiltersLoading =
    (isJobRelatedPage &&
      urlFilterColumn &&
      gridInitializingFirstTimeRef?.current) ||
    (isDataRelatedPage &&
      hasGlobalFilters &&
      hasKeysForCurrentType &&
      gridInitializingFirstTimeRef?.current);

  return (
    <ListingPageStyled
      isViewSaved={parsedDataIsFavourite?.isFavourite}
      isLoadingData={isLoadingDataCond}
      isSelectAll={isSelectAll}
      isProfilingInProgress={isProfilingInProgress}
      isRulePageOutsideOfListing={!!(isRulePage && isOutsideListingVews)}
    >
      <StateHandler
        isFetching={false}
        error={isRuuningProfileError}
        isShowBlankSlate={false}
      >
        <div />
      </StateHandler>
      {!isChatListingPage &&
        !isFeedbackAnalyticsPage &&
        !isOutsideListingVews && (
          <BreadCrum currentPage={{ label: pageHeading }} />
        )}
      {(type === "JOB" || type === "SCH") &&
        !isOutsideListingVews &&
        !hasAdministrationEditAccess(
          type === "JOB" ? "jobs" : "job_executions"
        ) && (
          <AccessDialogueStyled>
            <Banner
              content={
                <div className="readonly-chat-banner">
                  You currently have {accessMessage} access to{" "}
                  <b>{type === "JOB" ? "Jobs" : "Job Executions"}</b> page.
                  Please contact the administrator if you require edit
                  permissions.
                </div>
              }
              color="black"
              bgColor="#e6f3ff"
            />
          </AccessDialogueStyled>
        )}

      {(isTablePage || isColumnPage || (isRulePage && !isOutsideListingVews)) &&
        (isProfilingInProgress || isProfilingFailed) &&
        profilingProgress}

      <div className="page-content">
        <PageHeader
          tableId={tableId}
          sourceId={sourceId}
          srcTypeId={srcTypeId}
          nodeType={type}
          showDividerAfterGridActBtns={
            !!isOutsideListingVews && !!appliedFiltersKeys?.length
          }
          isShowActionButtons={showSelectedRowsGridActions}
          actionButtonsProps={{
            viewId: Number(paramNodeId),
            countFilter: selectedFilter,
            filterModel: getFilterModel(),
            isSelectAll,
            allSelectedNodesIds,
            populateData,
            selectedNodeTagIds,
            isOfflineProfilingInProgress: !!isLoadingRunNodeProfiling,
            isOnlineProfilingInProgress: !!isProfilingInProgress,
            selectedRowsCount,
            sortModel: sortColumn?.sortColName
              ? [
                  {
                    colId: parsedCurrentNode?.columns?.find(
                      (item: any) =>
                        item?.headerName === sortColumn?.sortColName
                    )
                      ? sortColumn?.sortColName
                      : parsedCurrentNode?.columns?.[0]?.headerName,
                    sort: sortColumn?.sortType,
                  },
                ]
              : [],

            selectedRulesWithSourceInfo,
            isOutsideListingVews: !!isOutsideListingVews,
            isButtonDisable: termDisableBasedOnRole,
            isMassUpdateDisable: massUpdateDisableBasedOnRole,
            isRunProfileDisable,
            isDisableDeleteButton: isSelectAll ? false : termDisableBasedOnRole,
            sortedFeedbackStatuses,
            tooltipTitle: glossaryDomainsLoading
              ? undefined
              : isTermGlossaryViewer
              ? "In order to delete a term you must have Editor or Admin role"
              : !glossaryDomainAndSubdomain?.length
              ? "In order to delete a term you should be a data steward for at-least one domain or sub-domain"
              : "Default terms cannot be deleted",

            handleAddTags,
            handleRemoveTags,
            onClickDelete,
            onClickMassUpdate,
            onMassUpdateStatus,
            onClickHide,
            isHideDisabled: !isSelectAll && isAllSelectedNodesAreHidden,
            onClickUnHide,
            isUnhideDisabled: !isSelectAll && isAllSelectedNodesAreUnHidden,
            isActivateJobDisabled:
              !isSelectAll && isAllSelectedNodesAreActivated,
            isDeactivateJobDisabled:
              !isSelectAll && isAllSelectedNodesAreDeactivated,

            onClickRunProfileOffline,
            onClickRunProfileOnline,
            handleClearAll,
          }}
          glossaryHeaderProps={{
            isExpandedGridView,
            isOnlineProfilingInProgress: !!isProfilingInProgress,
            customRightSecOutsideListingViews,
            isOutsideListingVews,
            selectedRowsCount,
            freshnessRuleColumnId,

            nodeType: type,
            nodeId: currentNodeId,
            createdById: Number(currentNodeCreatedById),
            isAdmin,
            data: governanceViews,
            title: parsedCurrentNode?.selected_view,
            sectionTitle: "",
            isDefaultView: parsedCurrentNode?.is_default_view,
            isViewer,
            hideRightSection:
              isChatListingPage ||
              isFeedbackAnalyticsPage ||
              isOutsideListingVews,
            hideLeftSideActions: isOutsideListingVews,
            onClick: handleChangeView,
            onClickCreateView: !isOutsideListingVews
              ? type === "JOB" || type === "SCH"
                ? hasAdministrationEditAccess(
                    type === "JOB" ? "jobs" : "job_executions"
                  )
                  ? handleAddGovernanceView
                  : undefined
                : handleAddGovernanceView
              : undefined,
            save: {
              isFavourite: parsedDataIsFavourite?.isFavourite,
              isLoading:
                isLoadingSaveView ||
                isLoadingIsViewFavourite ||
                disableGlossaryHeader ||
                false,
              onClick: handleSave,
            },
            download: {
              isLoading:
                isLoadingDownloadView ||
                isDownloadDisabled(tileFilters, selectedFilter) ||
                disableGlossaryHeader ||
                false,
              onClick: handleDownload,
            },
            dataImport: {
              isLoading: disableGlossaryHeader || false,
              onClick: handleImport,
              isDisabled: termDisableBasedOnRole,
            },
            share: {
              isLoading: disableGlossaryHeader || false,
            },
            onChangeHeaderRightView,
            leftSecButtons: [
              ...(((!isOutsideListingVews ||
                (isOutsideListingVews && isJobExecutionsPage)) && [
                {
                  icon: iconRefresh("14.4px", "14.4px"),
                  title: "Refresh",
                  customClass: "refresh-btn",
                  onClick: (): void => handleResetGridStates("refresh_view"),
                  disabled: isLoadingNodeData || isProfilingInProgress,
                },
              ]) ||
                []),
              ...((!isOutsideListingVews && [
                {
                  icon: editIcon,
                  title: "Edit",
                  isHide:
                    type === "JOB" || type === "SCH"
                      ? !hasAdministrationEditAccess(
                          type === "JOB" ? "jobs" : "job_executions"
                        )
                      : false,
                  customClass: "edit-btn",
                  onClick: (): void => handleEditCloneGovernanceView(),
                  disabled:
                    disableGlossaryHeader ||
                    isProfilingInProgress ||
                    parsedCurrentNode?.is_default_view ||
                    (isViewer &&
                      parsedCurrentNode?.access_type !== "GOV_EDITOR" &&
                      parsedCurrentNode?.access_type !== "GOV_OWNER"),
                  id: ALLCAT_LST_EDI_EDIT_BTN,
                },
                {
                  icon: <>{plusIconInFilledCircle("12", "12")} Add Term</>,
                  title: "",
                  customClass: "add-term-btn",
                  onClick: onClickAdd,
                  disabled: disableGlossaryHeader || termDisableBasedOnRole,
                  isHide: type !== "TRM",
                  tooltipProps: glossaryDomainsLoading
                    ? undefined
                    : isViewer
                    ? {
                        title:
                          "In order to add a term you must have Editor or Admin role",
                        placement: "right" as TooltipPlacement,
                        overlayClassName: "term-info-tooltip",
                      }
                    : !glossaryDomainAndSubdomain?.length
                    ? {
                        title:
                          "In order to create a new term you should be a data steward for at least one domain or sub-domain",
                        placement: "right" as TooltipPlacement,
                        overlayClassName: "term-info-tooltip",
                      }
                    : undefined,
                },
                {
                  icon: <>{plusIconInFilledCircle("12", "12")} Add Job</>,
                  title: "",
                  customClass: "add-job-btn",
                  onClick: onClickAddJob,
                  isHide:
                    !isJobPage ||
                    !isCatalogAdmin ||
                    !hasAdministrationEditAccess("jobs"),
                  disabled: disableGlossaryHeader,
                },
              ]) ||
                []),
            ],
            stateHandlerProps: {
              isFetching: isFetchingViewConfig,
              error:
                errorCurrentNode ||
                errorGovernanceViews ||
                errorDeleteView ||
                errorChangeView ||
                errorIsViewFavourite ||
                errorSaveView ||
                errorFieldData999,
              blankSlate: isOutsideListingVews
                ? selectedTitleBlankSlate
                : selectedViewBlankSlate,
              apiKey: "get_current_node",
              children: <></>,
            },
          }}
        />

        {isRulePage &&
          isOutsideListingVews &&
          (isProfilingInProgress || isProfilingFailed) &&
          profilingProgress}

        {!isExpandedGridView &&
          !(isFeedbackAnalyticsPage && selectedRowsCount) && (
            <HorizontalDividerStyled
              marginBottom="20px"
              className="listing-page-header-divider"
            />
          )}

        {isChatListingPage && (
          <ChartSec
            botEngagementsData={parsedBotEngagements}
            botEngagementsIsLoading={
              isLoadingBotEngagements ||
              isLoadingChangeView ||
              isLoadingCurrentNode
            }
            botEngagementsError={errorBotEngagements}
            topUsersData={parsedBotTopUsers}
            topUsersIsLoading={
              isLoadingBotTopUsers ||
              isLoadingChangeView ||
              isLoadingCurrentNode
            }
            topUsersError={errorBotTopUsers}
          />
        )}

        <StateHandler
          isFetching={isFetchingData}
          error={
            errorCurrentNode ||
            formattedErrorNodeData ||
            errorChangeView ||
            errorDeleteView ||
            errorFieldData999 ||
            errorMatchStatus
          }
          blankSlate={
            <span data-testid="listing-page-blank-slate-test-id">
              {isOutsideListingVews
                ? listingPageBlankSlateWithoutFilters
                : listingPageBlankSlate}
            </span>
          }
          apiKey="get_node_data"
        >
          <div />
        </StateHandler>

        {isChatListingPage && !isFetchingData && (
          <div className="chat-listing-heading">Chats Listing</div>
        )}

        {isLoadingChangeView ? (
          <div />
        ) : (
          <div className="listing-grid" id={isFetchingData ? "" : gridElemId}>
            <StateHandler
              isFetching={!!isGlobalFiltersLoading}
              blankSlate={selectedFiltersBlankSlate}
              error={
                errorCurrentNode ||
                formattedErrorNodeData ||
                errorChangeView ||
                errorDeleteView ||
                errorFieldData999 ||
                errorMatchStatus
              }
            >
              {showGlobalFiltersTagWrapper ? (
                <GlobalFiltersWrapper className="listing-filters-wrapper">
                  <div className="filters-list-label">Filtered by: </div>

                  <div className="filters-list-wrapper">
                    {appliedFiltersKeys?.map((filterKey, index) => (
                      <Tag
                        key={filterKey + index}
                        closable
                        onClose={(): void => clearFilters(filterKey)}
                        className="filter-tag"
                        title={
                          appliedFilterModels?.[filterKey]?.values?.join(
                            ", "
                          ) || ""
                        }
                      >
                        <span className="filter-count">
                          {!["number", "date"].includes(
                            appliedFilterModels?.[filterKey]?.filterType
                          ) &&
                            `${
                              appliedFilterModels?.[filterKey]?.values
                                ?.length || ""
                            } `}
                        </span>
                        {filterKey?.replaceAll("_", " ")}
                      </Tag>
                    ))}
                    {appliedFilterKeyHasLength && (
                      <div
                        className="clear-all-btn"
                        role="button"
                        onClick={(): void => clearFilters()}
                      >
                        Clear All
                      </div>
                    )}
                  </div>
                </GlobalFiltersWrapper>
              ) : (
                <div />
              )}
            </StateHandler>

            <TableWithFilterTiles
              isExpandedGridView={isExpandedGridView}
              onToggleGridView={onToggleGridView}
              isFetchingData={!!isFetchingData}
              renderFilters={!isLoadingDataCond && !isOutsideListingVews}
              filters={tileFilters}
              selectedFilter={selectedFilter}
              isShowFilter={!selectedNodes?.length}
              gridApi={gridApi}
              isOutSideListingViews={isOutsideListingVews}
              tableData={{
                tableColumns: updatedColWithHiddenFields?.map((item) => ({
                  ...item,
                  // Using headerComponentParams to pass selectedFilter to the getFilterValues function
                  headerComponentParams: {
                    selectedFilter,
                    filterModel: getFilterModel(),
                    selectedRowsCount,
                  },
                  headerCheckboxSelection:
                    (isViewer && !isAssignedToMeTabActive) || isChatListingPage
                      ? false
                      : (type === "JOB" || type === "SCH") &&
                        !hasAdministrationEditAccess(
                          type === "JOB" ? "jobs" : "job_executions"
                        )
                      ? false
                      : item?.headerCheckboxSelection,
                  checkboxSelection:
                    (isViewer && !isAssignedToMeTabActive) || isChatListingPage
                      ? false
                      : (type === "JOB" || type === "SCH") &&
                        !hasAdministrationEditAccess(
                          type === "JOB" ? "jobs" : "job_executions"
                        )
                      ? false
                      : item?.headerCheckboxSelection,
                  ...(isOutsideListingVews && {
                    headerCheckboxSelectionFilteredOnly: true,
                  }),
                  filterParams: {
                    ...item?.filterParams,
                    values: getFilterValues,
                    refreshValuesOnOpen: true,
                  },
                })),
                maxHeight:
                  isChatListingPage || isFeedbackAnalyticsPage
                    ? "400px"
                    : `calc(100vh - ${
                        isExpandedGridView
                          ? "300px"
                          : !selectedNodes?.length
                          ? "420px"
                          : "361px"
                      })`,
                applyColumnDefOrder: true,
                rowData: [],
                isTableWidthfitTowindowSize: false,
                rowSelection: isChatListingPage
                  ? undefined
                  : isViewer && !isAssignedToMeTabActive
                  ? ""
                  : "multiple",
                rowMultiSelectWithClick: false,
                getRowNodeId: (params: any): string => {
                  return params?.id;
                },
                gridOptions: {
                  floatingFilter: true,
                  defaultColDef: {
                    suppressFiltersToolPanel: true,
                    filter: true,
                  },
                  tooltipShowDelay: 0,
                  enableBrowserTooltips: true,
                },
                frameworkComponents: {
                  TableNameRendrer,
                  TermNameRendrer,
                  ColumnNameRendrer,
                  DataSourceNameRendrer,
                  TableRendrer,
                  EntityRendrer,
                  StatusRenderer,
                  DateTimeRenderer,
                  TagsRenderer,
                  RefreshStatusRenderer,
                  NumberRenderer,
                  DataTypeRenderer,
                  MatchInfoRendrer,
                  ConfidenceScoreRendrer,
                  DescriptionRenderer,
                  DQScoreRenderer,
                  AlertTypeRenderer,
                  IsCDEFieldRendrer,
                  PopularityScoreRenderer,
                  WorkflowStatusRenderer,
                  AssignedToAndAttributesRenderer,
                  ProfilingInfoRendrer,

                  RuleIdRendrer,
                  RuleAlertStatusRendrer,
                  RuleRunStatusRendrer,
                  RuleTrendRenderer,
                  RuleWorkflowStatusRenderer,
                  RunResultRenderer,
                  JobIdRenderer,
                  JobExecutionIdRenderer,
                  JobStatusRenderer,
                  JobExecutionStatusRenderer,
                  AssignedToRenderer,
                  ChatNameRenderer,
                  QuestionRenderer,
                  FeedbackRenderer,
                  AnalyticsStatusRenderer,
                },
                cellRenderers: {
                  short_name: { render: "TermNameRendrer" },
                  table_name: {
                    render: isRulePage ? "TableRendrer" : "TableNameRendrer",
                  },
                  column_name: { render: "ColumnNameRendrer" },
                  rule_id: { render: "RuleIdRendrer" },
                  job_id: { render: "JobIdRenderer" },
                  execution_id: { render: "JobExecutionIdRenderer" },
                  alert_status: { render: "RuleAlertStatusRendrer" },
                  run_status: { render: "RuleRunStatusRendrer" },
                  trend: { render: "RuleTrendRenderer" },
                  name: { render: "DataSourceNameRendrer" },
                  is_cde: { render: "IsCDEFieldRendrer" },
                  full_name: {
                    render:
                      type === "DSR"
                        ? "DataSourceNameRendrer"
                        : "TableNameRendrer",
                  },
                  status: {
                    render: isFeedbackAnalyticsPage
                      ? "AnalyticsStatusRenderer"
                      : isJobExecutionsPage
                      ? "JobExecutionStatusRenderer"
                      : "StatusRenderer",
                    params: { nodeType: type },
                  },

                  tags: { render: "TagsRenderer" },
                  refresh_status: { render: "RefreshStatusRenderer" },
                  scan_status: { render: "RefreshStatusRenderer" },
                  record_count: { render: "NumberRenderer" },
                  count_distinct: { render: "NumberRenderer" },
                  count_blanks: { render: "NumberRenderer" },
                  length: { render: "NumberRenderer" },
                  run_result: { render: "RunResultRenderer" },
                  data_type: { render: "DataTypeRenderer" },
                  workflow_status: {
                    render: isRulePage
                      ? "RuleWorkflowStatusRenderer"
                      : "WorkflowStatusRenderer",
                  },

                  assigned_to: { render: "AssignedToAndAttributesRenderer" },
                  attributes: { render: "AssignedToAndAttributesRenderer" },
                  // last_execution: { render: "DateTimeRenderer" },
                  // next_execution: { render: "DateTimeRenderer" },
                  // start_time: { render: "DateTimeRenderer" },
                  // end_time: { render: "DateTimeRenderer" },
                  "no._of_recipients": { render: "NumberRenderer" },
                  object_count: { render: "NumberRenderer" },

                  entity: {
                    render: "EntityRendrer",
                    params: {
                      onSuccess: onSuccessMatchStatus,
                      nodeType: type,
                      nodeId: `${parsedCurrentNode?.node_id}`,
                    },
                  },
                  table: { render: "TableRendrer" },
                  match_status: {
                    render: "MatchInfoRendrer",
                    params: {
                      onSuccess: onSuccessMatchStatus,
                      onExecute: onExecuteRequestMatchStatus,
                      nodeType: type,
                      nodeId: `${parsedCurrentNode?.node_id}`,
                    },
                  },
                  confidence_score: { render: "ConfidenceScoreRendrer" },
                  description: { render: "DescriptionRenderer" },
                  rule_desc: { render: "DescriptionRenderer" },
                  dq_score: { render: "DQScoreRenderer" },
                  alert_type: { render: "AlertTypeRenderer" },
                  popularity: { render: "PopularityScoreRenderer" },
                  profiling_info: { render: "ProfilingInfoRendrer" },
                  chat_name: {
                    render: "ChatNameRenderer",
                    params: { isFeedbackAnalyticsPage },
                  },
                  execution_status: { render: "JobStatusRenderer" },
                  range_value: {
                    render: "MinMaxMaskingRendrer",
                  },
                  total_questions: { render: "NumberRenderer" },
                  approved_feedbacks: { render: "NumberRenderer" },
                  rejected_feedbacks: { render: "NumberRenderer" },
                  feedbacks_count: { render: "NumberRenderer" },
                  // latest_chat_time: { render: "DateTimeRenderer" },
                  // created_on: { render: "DateTimeRenderer" },
                  question: {
                    render: "QuestionRenderer",
                    params: {
                      updateFeedbackStatusConfig,
                      getDataApiCommonProps,
                    },
                  },
                  feedback: { render: "FeedbackRenderer" },
                },
                defaultColDef: {
                  resizable: true,
                  lockPosition: true,
                },
                rowClassRules: {
                  "disabled-row": (params): boolean =>
                    params?.data?.status_id === "DIS",
                  "hidden-row": (params): boolean => {
                    return params?.data?.is_hidden;
                  },
                },
                gridRef,
                ...(isOutsideListingVews && {
                  doesExternalFilterPass,
                  isExternalFilterPresent: (): boolean => true,
                  onGridReady: onGridReadyWrapper,
                }),
                onSelectionChanged: handleSelectionChanged,
                onFilterChanged: handleFilterChanged,
                // @ts-ignore
                onFilterOpened: (): void => {
                  try {
                    document.getElementsByClassName(
                      "ag-filter-apply-panel-button"
                    )[0].innerHTML = "Apply Filter";
                  } catch {
                    //
                  }
                },
              }}
              paginationData={{
                paginationState: pagination,
                setPaginationState: setPagination,
                totalCount: totalRecords,
                selectionState: {
                  isSelectAll,
                  selectedRecords: selectedRowsCount,
                },
                onSelectAll: handleSelectAll,
                onClear: handleClearAll,
                onHiddenRecordCheckboxChange: handleShowHiddenRecords,
                hiddenRecordsSelected:
                  isTablePage || isColumnPage ? showHiddenRecords : undefined,

                ...(isDataSecurityAdmin &&
                  isCatalogAdmin &&
                  (isTablePage || isColumnPage) && {
                    customHiddenRecordText: isTablePage
                      ? "Show hidden tables"
                      : "Show hidden columns",
                  }),
              }}
              sortProps={{
                sortState: sortColumn,
                setSortState: setSortColumn,
              }}
              handleResetPagination={handleResetPagination}
              handleChangeFilter={handleChangeTileFilter}
              {...props}
            />
          </div>
        )}
      </div>
    </ListingPageStyled>
  );
};

export default ListingPages;
