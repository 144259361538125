import styled from "styled-components";
import { SectionBlankSlateTextType } from "./sectionblankslatetext.types";

export const SectionBlankSlateTextStyled = styled.div<SectionBlankSlateTextType>`
  .sec-blank-slate {
    width: 100%;
    min-height: ${({ containMinHeight, minHeight }): any =>
      containMinHeight && (minHeight || "75px")};
    display: flex;
    align-items: center;
    justify-content: ${({ position }): string => position || "center"};
    font-size: 14px;
    opacity: 0.5;
    color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
    font-family: ${({ fontFamily }): any => fontFamily || "OpenSans"};
  }
`;
