import * as yup from "yup";
import { FilterCriteriaSchema } from "./filtercriteriaschema";

export const rulesListingEditDrawerFormSchema = yup.object().shape({
  is_filter_criteria_active: yup.boolean(),
  filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),
});
