import { infoIcon } from "../../svgs";
import IconWithText from "../iconwithtext";
import { BusinessNameEditInfoMessageStyled } from "./businessnameeditinfo.styles";

const BusinessNameEditInfoMessage = (): JSX.Element => (
  <BusinessNameEditInfoMessageStyled className="note">
    <IconWithText
      text="If left empty, it will automatically reset to its technical name."
      icon={infoIcon}
      textFlexDirection="column"
    />
  </BusinessNameEditInfoMessageStyled>
);

export default BusinessNameEditInfoMessage;
