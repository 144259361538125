import { Radio } from "antd";
import styled from "styled-components";

export const RadioGroupStyled = styled(Radio.Group)<{
  width: string;
  height: string;
  direction: string;
  containerWidth?: string;
  gap?: number;
  innerCircleWidth?: string;
  innerCircleHeight?: string;
  innerCirclePrimaryBgColor?: boolean;
}>`
  &.ant-radio-group {
    display: flex;
    flex-direction: ${(props): string => props.direction};
    gap: ${(props): string => `${props?.gap || 0}px`};

    .ant-radio-wrapper {
      width: ${({ containerWidth }): string => containerWidth || "170px"};
      .ant-radio {
        .ant-radio-inner {
          background: ${(props): string => props.theme.bgColors.LIGHT_BLUE_5};
          border: 1px solid
            ${(props): string => props.theme.borderColors.GREY_100};
          width: ${(props): string => props.width};
          height: ${(props): string => props.height};
        }

        .ant-radio-input:focus + .ant-radio-inner {
          box-shadow: none;
        }
      }

      :hover {
        .ant-radio {
          .ant-radio-inner {
            border: 1px solid #ff893c;
          }
        }
      }

      > span {
        color: #393939;
        padding-left: 3px;
        font-size: 13px;
      }
    }

    .ant-radio-wrapper-checked {
      .ant-radio {
        .ant-radio-inner {
          ::after {
            background: ${({ theme, innerCirclePrimaryBgColor }): string =>
              innerCirclePrimaryBgColor
                ? theme.bgColors.BLUE_116
                : theme.bgColors.BLUE_55};

            width: ${({ innerCircleWidth }): string =>
              innerCircleWidth || "11px"};

            height: ${({ innerCircleHeight }): string =>
              innerCircleHeight || "11px"};
            transition: unset;

            top: 3px;
            left: 3px;
          }
        }
      }

      .ant-radio-checked {
        ::after {
          transition: unset;
          animation: unset;
        }
      }

      :hover {
        .ant-radio-checked {
          ::after {
            border: none;
          }
        }
      }
    }
  }
`;
