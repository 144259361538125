import Flex from "../components/flex/flex";
import {
  RuleTrendId,
  RunStatusId,
} from "../parsers/ruleparser/ruleparser.types";

import {
  exceptionsIcon,
  filledCrossIcon,
  filledTickIcon,
  tickinBoxIcon,
  trendDownIcon,
  trendStraingLineIcon,
  trendUpIcon,
  warningIconInFilledCircle,
} from "../svgs";

//REVAMP

export const getRuleRunStatusIcon = (runStatusId: RunStatusId): JSX.Element => {
  const runStatusIcons: Record<RunStatusId, JSX.Element> = {
    VLD: tickinBoxIcon("17", "17"),
    INV: warningIconInFilledCircle("15", "15", "#D15B47"),
    PSS: filledTickIcon("13", "13"),
    FAL: filledCrossIcon("15", "13"),
    EXP: exceptionsIcon("15", "16"),
  };

  return runStatusIcons[runStatusId];
};

export const getRuleRunStatusLabel = (runStatusId: RunStatusId): string => {
  const runStatusLabels: Record<RunStatusId, string> = {
    VLD: "Valid",
    INV: "Invalid",
    PSS: "Passed",
    FAL: "Failed",
    EXP: "Exception",
  };

  return runStatusLabels[runStatusId];
};

export const getRuleRunStatusJsx = (runStatusId: RunStatusId): JSX.Element => {
  return (
    <Flex alignItems="center" columnGap={5}>
      {getRuleRunStatusIcon(runStatusId)}
      <span>{getRuleRunStatusLabel(runStatusId)}</span>
    </Flex>
  );
};

export const getRuleTrendIcon = (ruleTrendId: RuleTrendId): JSX.Element => {
  const ruleTrendIcons: Record<RuleTrendId, JSX.Element> = {
    PSU: trendUpIcon(),
    NGD: trendDownIcon(),
    FLT: trendStraingLineIcon(),
  };

  return ruleTrendIcons[ruleTrendId];
};
