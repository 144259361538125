import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import Dynamicform from "../../dynamicform";
import { addUniquenessForm } from "./adduniquenessform.config";

import { addUniquenessSchema } from "../../../../utils/schemas";
import { AddUniquenessFormType } from "./adduniquenessform.types";

import { GetAddRuleFormContentProps } from "../../addruleform.types";
import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";

const AddUniquenessForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    columnSelectOptions,
    columnsData,
    sourceId,
    tableId,
    onAddRule,
    ruleCategoryId,
    formId,
    ruleConfig,
    isEdit = false,
    propsOnCancel,
    onUpdateRule,
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const { rule_desc: desc = "", col_ids: colIds = [] } = ruleConfig || {};

  const form = useForm<AddUniquenessFormType>({
    defaultValues: {
      id: "uniqueness",
      rule_type: "Uniqueness",
      rule_description: desc,
      columns: colIds ? colIds?.map((col) => String(col)) : [],

      is_filter_criteria_active: isEdit,
      filter_criteria: [],
    },
    resolver: yupResolver(addUniquenessSchema),
    mode: "onChange",
  });

  const uniquessFormConfig = addUniquenessForm({
    columnSelectOptions,
    existingFilters: initialFilters,
    isEdit,
  });

  const onSubmit = useCallback(
    (values: AddUniquenessFormType) => {
      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const selectedColumnIds = values?.columns?.map((column) =>
        Number(column)
      );

      const updatedDesc = values?.rule_description;

      const ruleConfig = {
        rule_type: formId,
        rule_desc: updatedDesc,
        // NEEDED THESE IDS IN CONFIG
        col_ids: selectedColumnIds,
      };

      const addNewRulePayload = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: values?.rule_description,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        rule_def_config: ruleConfig,
        filter: filterCondition,
        col_ids: selectedColumnIds,
      };

      const modifyRulePayload = {
        ...ruleConfig,
        filter_cond: filterCondition,
      };

      isEdit ? onUpdateRule?.(modifyRulePayload) : onAddRule(addNewRulePayload);
    },
    [tableId, sourceId, columnsData, onAddRule]
  );

  return (
    <FormProvider {...form}>
      <Dynamicform
        schema={uniquessFormConfig}
        onSubmit={onSubmit}
        columnsData={columnsData}
        isEdit={isEdit}
        propsOnCancel={propsOnCancel}
      />
    </FormProvider>
  );
};

export default AddUniquenessForm;
