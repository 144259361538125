import * as yup from "yup";
import {
  CHARACTERS_LIMIT_EXCEEDED,
  REQUIRED_MESSAGE,
} from "../../constants/formconstants";
import { FilterCriteriaSchema } from "./filtercriteriaschema";

export const addGovernanceViewSchema = yup.object().shape({
  view_name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .max(100, CHARACTERS_LIMIT_EXCEEDED(100)),
  filter_criteria: yup.lazy((_p, p1) => {
    return yup.array().of(
      yup.object().shape({
        condition: yup.array().of(FilterCriteriaSchema),
      })
    );
  }),
});

export const updateTagsSchema = yup.object().shape({
  tags: yup.array().of(yup.string()).min(1, REQUIRED_MESSAGE),
  overwrite: yup.boolean(),
});
