import styled, { FlattenSimpleInterpolation } from "styled-components";
import { disabledContent, ellipsis } from "../../styles/app.styles";

export const TransferStyled = styled.div<{
  isRightTransferActionDisable?: boolean;
}>`
  margin-top: 4px;

  position: relative;
  padding-top: 30px;
  width: fit-content;

  .error-message {
    text-align: right;
    color: ${({ theme }): string => theme.textColors.RED};
  }

  .left-title,
  .right-title {
    position: absolute;
    top: 0px;
    font-size: 14px;
    color: ${({ theme }): string => theme.textColors.GREY_24};
  }

  .right-title {
    right: 280px;
  }

  .left-title {
    .transfer-left-tilte {
      display: flex;
      align-items: center;
      > svg {
        margin-left: 6px;
      }
    }
  }

  .left-icon,
  .right-icon {
    position: absolute;
    top: 48px;
    left: 25px;
    z-index: 12;
  }

  .right-icon {
    right: 300px;
    left: unset;
  }

  .available-filters-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
          box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
          background-color: ${({ theme }): string =>
            theme.bgColors.WHITE_COLOR};
          width: 8px;
          height: 8px;
        }
      }
      .ant-tooltip-inner {
        width: 300px;
        min-height: 82px;
        padding: 16px 9.2px 18px 15px;
        box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        font-size: 13px;
        color: ${({ theme }): string => theme.textColors.GREY_26};
      }
    }
  }

  .ant-transfer {
    .ant-transfer-list {
      padding: 10px;
      width: 341px;
      height: 415px;
      border: 1px solid ${({ theme }): string => theme.borderColors.GREY_17};
      border-radius: 8px;
      .ant-transfer-list-header {
        display: none;
      }

      .ant-transfer-list-body {
        .ant-transfer-list-body-search-wrapper {
          padding: 0px;
          margin-bottom: 10px;
          .ant-input {
            height: 34px;
            border-radius: 5px;
            border: 1px solid
              ${(props): string => props.theme.borderColors.GREY_21};
            color: ${(props): string => props.theme.textColors.LIGHT_GREY_45};
            font-size: 13px;
            padding-left: 35px;
            :not(:disabled) {
              :hover {
                border-color: ${(props): string =>
                  props.theme.borderColors.YELLOW_RD};
                box-shadow: none;
              }

              :focus {
                border-color: ${(props): string =>
                  props.theme.borderColors.YELLOW_RD};
                color: ${(props): string =>
                  props.theme.textColors.DARK_GREY_105};
                box-shadow: none;
              }
            }

            ::placeholder {
              font-size: 14px;
              color: ${(props): string => props.theme.textColors.LIGHT_GREY_45};
            }
          }

          .ant-transfer-list-search-action {
            display: none;
          }
        }

        .ant-transfer-list-content {
          .ant-transfer-list-content-item {
            margin-bottom: 0px;
            padding: 0px;
            min-height: 0px;
            color: ${({ theme }): string => theme.textColors.GREY_24};

            :not(.ant-transfer-list-content-item-disabled) {
              :hover {
                background: ${({ theme }): string => theme.bgColors.GREY_23};
              }
            }
            .ant-checkbox-wrapper {
              margin-right: 0px;
              display: none;
            }

            .decs {
              opacity: 0.5;
              display: inline-block;
              max-width: 285px; /* Adjust as needed */
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
            }

            .decs::after {
              content: ")";
              position: absolute;
              right: 0px;
            }

            .ant-transfer-list-content-item-text {
              .dvsum-transfer-item-rendrer {
                display: flex;
                align-items: center;
                column-gap: 4px;
                padding: 5px 6px;
                min-height: 30px;
                ${ellipsis}
              }

              .item {
                display: flex;
                align-items: center;
                svg {
                  margin-right: 10px;
                  path {
                    fill: ${({ theme }): string => theme?.bgColors?.GREY_210};
                  }
                }
              }
            }
          }

          .ant-transfer-list-content-item-checked {
            background: ${({ theme }): string => theme.bgColors.GREY_23};
          }

          .ant-transfer-list-content-item-disabled {
            color: ${({ theme }): string => theme.textColors.BLACK_17};
          }
        }
      }
    }
    .ant-transfer-operation {
      margin: 0px 34px;

      .ant-btn {
        width: 36px;
        height: 36px;
        border: 1px solid ${({ theme }): string => theme.borderColors.GREY_17};
        border-radius: 5px;
        background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};

        ::after {
          box-shadow: none;
          animation: unset;
          transition: unset;
        }

        svg {
          path {
            fill: ${({ theme }): string => theme.bgColors.GREY_22};
          }
        }

        :first-child {
          margin-bottom: 12px;
          ${({
            isRightTransferActionDisable,
          }): FlattenSimpleInterpolation | string =>
            isRightTransferActionDisable ? disabledContent : ""}
        }

        :disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .up-down-icons-sec {
    position: absolute;
    top: 44.5%;
    right: -90px;

    .up-down-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border: 1px solid #cbcbcb;
      border-radius: 5px;
      background: #fff;
      margin-bottom: 12px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 5px;
        background: #fff;
        padding-left: unset;
      }

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: #434954;
        }
      }
    }
  }

  .ant-transfer-list-body-not-found {
    margin-top: 0px;
  }
`;
