import { useCallback, useMemo, useState } from "react";

//                <--BlankSlates Start-->
import { ruleDetailsModalBlankSlate } from "../../../../blankslates";
//                <--BlankSlates End-->

//                <--Components Start-->
import StateHandler from "../../../statehandler";
import TabsWithRightSection from "../../../tabswithrightsec";

import { FormItemStyled, FormStyled } from "../../../form";
import { Button } from "../../../button/button.component";

import {
  DQRuleActions,
  DataQualityHistoryChart,
  RuleTypeDetailModalDetailsSec,
  RuleTypeDetailsRuleDefinitionSec,
} from "./dqruledetailsmodal.components";
//                <--Components End-->

//                <--Custom Hooks Start-->
import { useCancelModal, useGetAppState } from "../../../../customhooks";
//                <--Custom Hooks End-->

//                <--Service Start-->
import { useGetDQRuleDetails } from "../../../../api/dataqualityservice/dataqualityservice";
//                <--Service End-->

//                <--Styles Start-->
import {
  DQRuleDetailsModalStyled,
  DQRuleDetailsModalChildStyled,
} from "./dqruledetailsmodal.styles";
//                <--Styles End-->

//                <--Types Start-->
import { DQRulesType } from "../../../../parsers";
import { DQRuleDetailsModalProps } from "./dqruledetailsmodal.types";
//                <--Types End-->

function DQRuleDetailsModal(): JSX.Element {
  const cancelModal = useCancelModal();
  const { modal: { modalProps = {} } = {} } = useGetAppState();

  const {
    chartProps,
    ruleTypeId = "ALL",
    nodeId = "",
    nodeName = "",
    nodeType,
    dqRulesgridNodeId = "",
  } = modalProps as DQRuleDetailsModalProps;

  const [selectedTab, setSelectedTab] = useState<DQRulesType>(ruleTypeId);

  const { parsedData, isLoading, error } = useGetDQRuleDetails(
    nodeId,
    nodeType
  );

  const { tabs = [], details = {} } = parsedData || {};

  const {
    dq_score_details: dqScoreDetails,
    rule_action_status: ruleActionStatus = "",
    rule_action_status_id: ruleActionStatusId = "",
    rule_action_details: ruleActionDetails = [],
    rule_defination: ruleDefination = [],
    rule_hist_id: ruleHistoryId = "",
    rule_id: ruleId = "",
    rule_alert: ruleAlert = "",
  } = details?.[selectedTab] || {};

  const otherContent = useMemo(() => {
    return (
      <div className="other-content-sec">
        <RuleTypeDetailModalDetailsSec
          dqScore={dqScoreDetails}
          ruleTypeId={selectedTab}
          nodeId={nodeId}
          nodeName={nodeName}
          nodeType={nodeType}
          ruleHistoryId={ruleHistoryId}
          ruleAlert={ruleAlert}
          isLoadingDQRuleDetails={isLoading}
        />
        <RuleTypeDetailsRuleDefinitionSec data={ruleDefination} />
      </div>
    );
  }, [nodeId, nodeType, details, selectedTab]);

  const onChangeTab = useCallback(
    (tab) => {
      setSelectedTab(tab);
    },
    [selectedTab]
  );

  return (
    <DQRuleDetailsModalStyled
      isLoading={isLoading || !!error}
      data-testid={`rule-type-details-modal-component-${nodeId}`}
    >
      <StateHandler
        isFetching={isLoading}
        error={error}
        blankSlate={ruleDetailsModalBlankSlate}
      >
        <FormStyled>
          <DQRuleDetailsModalChildStyled>
            <TabsWithRightSection
              data-testid={`rule-type-details-modal-tab-with-right-sec-${nodeId}`}
              tabs={{ tabs, selectedTab, onChange: onChangeTab }}
            >
              {selectedTab !== "ALL" ? (
                <DQRuleActions
                  data-testid={`rule-type-details-modal-dq-rule-actions-${nodeId}`}
                  actionStatusId={ruleActionStatusId}
                  ruleId={ruleId}
                  nodeId={nodeId}
                  actionStatus={ruleActionStatus}
                  nodeType={nodeType}
                  dqRulesgridNodeId={dqRulesgridNodeId}
                  ruleActionDetails={ruleActionDetails}
                />
              ) : (
                <div />
              )}
            </TabsWithRightSection>
            {selectedTab === "ALL" ? (
              <div
                className="dq-history-chart"
                data-testid={`rule-type-details-modal-history-chart-${nodeId}`}
              >
                <DataQualityHistoryChart {...chartProps} ruleCategoryId="ALL" />
              </div>
            ) : (
              <>
                {selectedTab === "CCP" && otherContent}
                {selectedTab === "CCV" && otherContent}
                {selectedTab === "CDF" && otherContent}
                {selectedTab === "CPV" && otherContent}
                {selectedTab === "CUQ" && otherContent}
                {selectedTab === "CVR" && otherContent}
                {selectedTab === "TDD" && otherContent}
                {selectedTab === "TSD" && otherContent}
              </>
            )}
            <FormItemStyled
              className="form-actions-sec"
              data-testid={`rule-type-details-modal-close-btn-${nodeId}`}
            >
              <Button onClick={cancelModal}>Close</Button>
            </FormItemStyled>
          </DQRuleDetailsModalChildStyled>
        </FormStyled>
      </StateHandler>
    </DQRuleDetailsModalStyled>
  );
}

export default DQRuleDetailsModal;
