// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localstorageconstants";
// <--- Constants End --->

// <--- Types Start --->
import { ChatPrefrencesType } from "../parsers";
// <--- Types End --->

// <--- Utils Start --->
import { getObjectKeys, jsonParse } from ".";
import { MOMENT_DATE_FORMATS } from "../constants";
// <--- Utils End --->

export function getChatPrefrencesData(): ChatPrefrencesType {
  const data =
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.chat_prefrences) || "";

  const parsedData = jsonParse<ChatPrefrencesType>(data, true);

  return {
    ...parsedData,
    default_answer_view: (parsedData?.default_answer_view?.trim() ||
      "chart") as ChatPrefrencesType["default_answer_view"],
    default_chart_sorting: (parsedData?.default_chart_sorting?.trim() ||
      "desc") as ChatPrefrencesType["default_chart_sorting"],
    collapse_view: (parsedData?.collapse_view?.trim() ||
      "default_view") as ChatPrefrencesType["collapse_view"],
    date_format: parsedData?.date_format || MOMENT_DATE_FORMATS?.["MM/DD/YYYY"],
    metric_format: {
      ...parsedData?.metric_format,
      percentage_cols_list:
        parsedData?.metric_format?.percentage_cols_list || [],
      currency_cols_list: parsedData?.metric_format?.currency_cols_list || [],
    },
  };
}
