import styled from "styled-components";

export const AddUserFormStyled = styled.div<{ maxHeight?: string }>`
  .ant-form {
    .ant-form-item {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .input-field-container > input,
            .textarea-field-container > textarea {
              width: 512px;
            }
          }
        }
      }
    }

    .scroll-sec {
      padding-top: 29px;
      max-height: ${({ maxHeight }): string =>
        maxHeight || "calc(100vh - 142px)"};
      overflow: auto;
    }

    .form-item-with-inline-info {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    .form-actions-sec {
      position: absolute;
      width: 100vw;
      bottom: 0px;
      margin-bottom: 0px;
    }

    .white-tooltip {
      .ant-tooltip-content {
        .ant-tooltip-inner {
          width: fit-content;
          min-width: fit-content;
        }
      }
    }
  }
`;
