import styled from "styled-components";

export const AgGridCountPaginationStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
  padding: 0 20px;
  background: #eff3f8;
  color: ${({ theme }): string => theme.textColors.GREY_90};
  border: 1px solid ${({ theme }): string => theme.borderColors.BLUE_10};
  height: 42px;
  font-size: 13px;
  font-family: OpenSans;

  .count-label {
    font-family: OpenSansSemiBold;
  }
`;
