// <--- Library Imports Start --->
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Constants Start --->
import {
  TABLE_DATA_TAB_SECTION_TITLES,
  TABLE_SECTION_TITLES,
} from "../../constants/labelconstants";
// <--- Constants End --->

// <--- Schemas Start --->
import { dataSetDescriptionSchema } from "../../utils/schemas/sectionwitheditableschema";
// <--- Schemas End --->

// <--- SVGs Start --->
import { userGroupsIcon, userIcon } from "../../svgs";
// <--- SVGs End --->

// <--- Type Start --->
import { Actions } from "../../components/additionalinfo/additionalinfo.types";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import { SourceTypes } from "../../app.types";
import { SectionWithEditableSourcesProps } from "../../components/sectionwitheditables/sectionwitheditablesources/sectionwitheditablesources.types";

import {
  TagItem,
  TagsData,
} from "../../components/tagseditor/tagseditor.types";
import { DQScoreProps } from "../../components/dqscore/dqscore.types";

import {
  NodeParentalInfoResponse,
  NodeTypeTAGContextResponse,
  TableDocResponse,
  ReturnTypeOfTableDocParser,
  UserOrUsersGroupParsedType,
  UserOrUsersGroupRespone,
  ProfillingInfoResponse,
  TableRecordCountResponse,
  RelatedTablesResponse,
  ReturnTypeOfGetRelatedTables,
  ReturnTypeOfTableRefParser,
  AdditionalAttrsType,
  ReturnTypeOfRelationshipTablesDataParser,
  RelationshipTablesDataResponse,
  RelationshipColumnsDataResponse,
  ReturnTypeOfRelationshipColumnsDataParser,
  ReturnTypeOfGetDataModelMaxLevelParser,
  DataModelMaxLevelResponse,
  DataModelStateResponse,
  ReturnTypeOfGetDataModelStateParser,
  ColumnResponseCongigurationType,
  ColumnParsedConfigurationType,
  RulesEditDrawerResponseType,
  RulesEditDrawerParsedDataType,
  AdditionalInfoType,
  FilterResponseType,
  ColDistributionType,
  FiltersColumnParsedConfigurationType,
  SearchedNodeTypeCatResponse,
  SearchedNodeTypeCatParsed,
  RecordCountChartType,
} from "./tablepageparser.types";

import { ScoreType } from "../../components/popularityscore/popularityscore.types";
// <--- Type End --->

// <--- Utils Start --->
import {
  formatBytes,
  getObjectKeys,
  getProminentTags,
  getTimeDifference,
  isArray,
  isJsonString,
  jsonParse,
  multiLevelSortArrayByKeys,
  removeEscapeCharForNewLine,
  sortListOnSpecificKeyValue,
} from "../../utils";

import {
  getClassificationCustomTags,
  getLineagePreviewData,
  parseAdditionalInfoData,
} from "../parserutils";

import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";
import { getPopularityScoreData } from "../parserutils/getpopularityscoredata";

import {
  getErdDiagramNodesAndEdges,
  getErdGridData,
} from "./tablepageparser.utils";

import { apiToFormCriteria } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";
import {
  MONTH_DATE_YEAR,
  MONTH_NAME_DAY_YEAR_HOUR_MIN_SEC,
} from "../../constants";

// <--- Utils End --->

export function getOverviewData({
  data,
}: AxiosResponse<TableDocResponse>): ReturnTypeOfTableDocParser {
  const srcTypeId = data?.src_type_id as SourceTypes;

  const functionalAreaName =
    data?.classifications?.functional_area?.value?.functional_area_name || "";
  const functionalAreaId =
    data?.classifications?.functional_area?.value?.functional_area_id || "";

  const entityId = data?.classifications?.entity?.value?.entity_id || "";
  const entityName = data?.classifications?.entity?.value?.entity_name || "";

  const {
    dq_status_id: dqStatusId,
    dq_status: dqStatus,
    dq_score: dqScore,
    dq_variance: dqVariance,
  } = data?.data_quality || {};
  const createdObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TABLE_SECTION_TITLES?.audit_trail?.created
  );
  const updatedObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TABLE_SECTION_TITLES?.audit_trail?.updated
  );
  return {
    description: {
      editable: false,
      validationSchema: dataSetDescriptionSchema,
      data: [
        {
          title: TABLE_SECTION_TITLES?.overview?.usability_score,
          value: "",
          isRowVisibleInEditView: false,
        },
        {
          title: TABLE_SECTION_TITLES?.overview?.title,
          value: data?.title?.value || "",
          isRowVisibleInNormalView: false,
          isEditable: true,
          type: "input",
          isShowInfoMsg: true,
        },
        {
          title: TABLE_SECTION_TITLES?.overview?.definition,
          value: data?.description?.description?.value || "",
          type: "textarea",
          isEditable: true,
        },

        {
          title: TABLE_SECTION_TITLES?.overview?.file_path,
          value: data?.table_path || "",
          isEditable: false,
          isRowVisibleInEditView: false,
          isRowVisibleInNormalView: srcTypeId === "ADL",
        },
        {
          title: TABLE_SECTION_TITLES?.classification?.functional_area,
          value: functionalAreaName,
          selectedValues: [`${functionalAreaId}`],
          isEditable: true,
          type: "select",
        },
        {
          title: TABLE_SECTION_TITLES?.classification?.entity,
          value: entityName,
          selectedValues: [`${entityId}`],
          isEditable: true,
          type: "select",
        },
      ],
      isEdited: false,
    },
    managedBy: {
      editable: false,
      data: [
        {
          title: TABLE_SECTION_TITLES?.managed_by?.data_owners,
          value: TABLE_SECTION_TITLES?.managed_by?.data_stewards,
          keyValueTags: data?.managed_by?.data_owners?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_owner_name || ""} (${
                      item?.data_owner_email || ""
                    })`
                  : item?.data_owner_name,
              type: item?.user_type,
              email: item?.data_owner_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_owners?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_owner_name || ""} (${
                    item?.data_owner_email || ""
                  })`
                : item?.data_owner_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.data_owners?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_owners?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
        },
        {
          title: TABLE_SECTION_TITLES?.managed_by?.system_owner,
          value: "",
          keyValueTags: data?.managed_by?.system_owners?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.system_owner_name || ""} (${
                      item?.system_owner_email || ""
                    })`
                  : item?.system_owner_name,
              type: item?.user_type,
              email: item?.system_owner_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.system_owners?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.system_owner_name || ""} (${
                    item?.system_owner_email || ""
                  })`
                : item?.system_owner_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.system_owners?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.system_owners?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
        },
        {
          title: TABLE_SECTION_TITLES?.managed_by?.data_stewards,
          value: "",
          keyValueTags: data?.managed_by?.data_stewards?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_steward_name || ""} (${
                      item?.data_steward_email || ""
                    })`
                  : item?.data_steward_name,
              type: item?.user_type,
              email: item?.data_steward_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_stewards?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_steward_name || ""} (${
                    item?.data_steward_email || ""
                  })`
                : item?.data_steward_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.data_stewards?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_stewards?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
          isDataStewardField: true,
        },
        {
          title: TABLE_SECTION_TITLES?.overview?.audit_trail,
          value: `${createdObj?.activity || ""} by ${
            createdObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(createdObj?.activity_on || "")
          )}:${updatedObj?.activity || ""} by ${
            updatedObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(updatedObj?.activity_on || "")
          )}`,
          isEditable: true,
        },
      ],
      isEdited: false,
    },
    classification: {
      data: [
        {
          title: TABLE_SECTION_TITLES?.classification?.format,
          value: data?.classifications?.format || "",
        },
        {
          title: TABLE_SECTION_TITLES?.classification?.sub_format,
          value: data?.classifications?.subformat || "",
        },

        ...getClassificationCustomTags(data?.classifications || {}),
      ],
      isEdited: false,
      editable: false,
    },
    dataQuality: {
      data: [
        {
          title: TABLE_SECTION_TITLES?.data_quality?.overall_score,
          value: dqScore || "",
          additionalInfo: {
            statusId: dqStatusId,
            status: dqStatus,
            score: dqScore,
            trend: dqVariance,
          } as DQScoreProps,
        },
        {
          title: TABLE_SECTION_TITLES?.data_quality?.last_updated,
          value: data?.last_refreshed_on || "",
        },
      ],
      editable: false,
      isEdited: false,
    },
    provinanceData: {
      isEditable: false,
      data: [
        {
          title: TABLE_SECTION_TITLES?.provinance_data?.table_and_sources,
          isEditable: true,
          sources: (data?.provenance_scan?.provenance?.value || [])?.map(
            (item) => ({
              dbName: item?.parent_name || "",
              tblName: item?.node_name || "",
              tblId: `${item?.node_id || ""}`,
              dbId: `${item?.parent_id || ""}`,
              isRefView: false,
              isTblSelection: true,
            })
          ),
          apiKey: "get_node_parental_info_by_node_type",
          urlParam: ["TBL", "TBL"],
        },
        {
          title: TABLE_SECTION_TITLES?.provinance_data?.scan_frequency,
          value: data?.provenance_scan?.scan_frequency || "",
        },
      ],
      isEdited: false,
    },
    additionalInfo: {
      isEditable: false,
      data: parseAdditionalInfoData(data?.additional_info),
      isEdited: false,
    },
    get_access_info: data?.get_access_info?.value || "",
    isEditable: !!data?.is_editable,
    tableName: data?.table_name || "",
    parentId: data?.parent_id || 0,
    parentName: data?.parent_name || "",
    isDraftExist: data?.is_draft_exists,
    isEndorsed: data?.endorsed_status?.value,
    endorsedCount: data?.endorsed_count || 0,
    isDeprecated: data?.published_status_id === "DEP",
    publishStatusId: data?.published_status_id,
    publishStatus: data?.published_status,
    lineage: getLineagePreviewData(data?.lineage),
    srcTypeId,
    nodeUrl: data?.table_url || "",
    popularityScore: getPopularityScoreData(
      data?.tbl_popularity_id as ScoreType,
      data?.tbl_popularity,
      data?.tbl_popularity_info
    ),

    isDqEnabled: data?.is_dq_enabled || false,
    isWorkflowEnabled: data?.is_wf_enabled || false,
    lastRefreshedOn: data?.last_refreshed_on || "",
  };
}

export function getUsersOrUserGroups({
  data,
}: AxiosResponse<UserOrUsersGroupRespone[]>): UserOrUsersGroupParsedType[] {
  const filteredData = data && [
    ...data?.filter((item) => item?.NODE_TYPE === "UGP"),
    ...data?.filter((item) => item?.NODE_TYPE === "USR"),
  ];

  const nodeName = (node: UserOrUsersGroupRespone): string =>
    node?.NODE_TYPE === "USR"
      ? `${node?.NODE_NAME || ""} (${node?.USER_EMAIL || ""})`
      : `${node?.NODE_NAME || ""}`;

  return filteredData?.map((item) => ({
    key: nodeName(item),
    value: nodeName(item),
    labelText: nodeName(item),
    label: nodeName(item),
    icon: item?.NODE_TYPE === "USR" ? userIcon : userGroupsIcon(),
    id: `${item?.NODE_ID || ""}`,
    type: item?.NODE_TYPE,
    email: item?.USER_EMAIL || "",
    name: item?.NODE_NAME || "",
  }));
}

export const commonItemParser = (
  items: NodeTypeTAGContextResponse[]
): TagItem[] => {
  return (
    items?.map((item) => ({
      label: item?.NODE_NAME || "",
      value: `${item?.NODE_ID || ""}`,
      desc: item?.NODE_DESC || "",
      isProminent: !!item?.IS_PROMINENT,
      colorCode: item?.COLOR_CODE,
    })) || []
  ).filter((item) => item?.label && item?.value);
};

export function getNodeTypeCATParser({
  data,
}: AxiosResponse<NodeParentalInfoResponse[]>): TagsData {
  return {
    Entity: {
      tagsData: data?.map((item) => ({
        label: item?.NODE_NAME || "",
        value: `${item?.NODE_ID || ""}`,
      })),
      isMultiple: false,
      isNewValueAllowed: false,
      isNoteRequired: false,
    },
  };
}

export function getSearchedNodeTypeCat({
  data,
}: AxiosResponse<SearchedNodeTypeCatResponse[]>): SearchedNodeTypeCatParsed[] {
  return data?.map((item) => ({
    key: String(item?.NODE_ID),
    // name: `${item?.PARENT_NAME}=>${item?.NODE_NAME}` || "",
    name: item?.NODE_TITLE || item?.NODE_NAME || "",
    parentId: item?.PARENT_ID || 0,
    parentName: item?.PARENT_NAME || "",
    description: item?.DESCRIPTION || "",
  }));
}

export function getNodeTypeTAGContextParser({
  data,
}: AxiosResponse<NodeTypeTAGContextResponse[]>): TagsData {
  const parentIds = data?.map((item) => item?.PARENT_ID || "") || [];
  const uniqueNames = [...new Set(parentIds)];
  return uniqueNames?.reduce((prev, parentId) => {
    const currItem = data?.find((item) => item?.PARENT_ID === parentId);
    return {
      ...prev,
      [parentId]: {
        tagsData: commonItemParser(
          data?.filter((item) => item?.PARENT_ID === parentId) || []
        ),
        isMultiple: currItem?.IS_MULTI_VALUE_ALLOWED,
        isNewValueAllowed: currItem?.IS_NEW_VALUE_ALLOWED,
        isNoteRequired: false,
      },
    };
  }, {});
}

export function getNodeParentalInfoByNodeType({
  data,
}: AxiosResponse<
  NodeParentalInfoResponse[]
>): SectionWithEditableSourcesProps["state"]["data"][number]["sources"] {
  const firstObj = data?.length ? data?.[0] : {};
  const isColumn =
    "ROOT_PARENT_NAME" in firstObj && "ROOT_PARENT_ID" in firstObj;

  return multiLevelSortArrayByKeys(data, [
    "ROOT_PARENT_NAME",
    "PARENT_NAME",
    "NODE_TITLE",
  ])?.map((item) =>
    isColumn
      ? {
          dbName: item?.ROOT_PARENT_NAME || "",
          tblName: item?.PARENT_NAME || "",
          tblId: `${item?.PARENT_ID || ""}`,
          dbId: item?.ROOT_PARENT_ID || "",
          colName: item?.NODE_NAME || "",
          colId: `${item?.NODE_ID || ""}`,
          isRefView: false,
          isTblSelection: false,
        }
      : {
          dbName: item?.PARENT_NAME || "",
          tblName: item?.NODE_TITLE || "",
          tblId: `${item?.NODE_ID || ""}`,
          dbId: item?.PARENT_ID || "",
          isRefView: false,
          isTblSelection: true,
        }
  );
}

export function getDataTabProfilingInfo({
  data,
}: AxiosResponse<ProfillingInfoResponse>): SectionRowProps[] {
  const additionalAttrs = jsonParse(
    data?.[0]?.TBL_ADDITIONAL_ATTRS
  ) as AdditionalAttrsType;

  return [
    {
      title: TABLE_DATA_TAB_SECTION_TITLES?.profiling_information?.record_count,
      value: `${data?.[0]?.TBL_RECORD_CNT || "--"}`,
      isRowVisibleInNormalView: true,
    },
    {
      title:
        TABLE_DATA_TAB_SECTION_TITLES?.profiling_information?.last_scanned_on,
      value: utcTOLocalTimeZone(
        data?.[0]?.TBL_LAST_REFRESHED_ON,
        MONTH_NAME_DAY_YEAR_HOUR_MIN_SEC
      ),
    },
    {
      title:
        TABLE_DATA_TAB_SECTION_TITLES?.profiling_information?.last_updated_on,
      value: utcTOLocalTimeZone(
        data?.[0]?.TBL_SRC_LAST_UPDATED_ON,
        MONTH_NAME_DAY_YEAR_HOUR_MIN_SEC
      ),
    },
    {
      title: TABLE_DATA_TAB_SECTION_TITLES?.profiling_information?.file_size,
      value: formatBytes(Number.parseInt(additionalAttrs?.tbl_size)) || "",
      isEditable: false,
      isRowVisibleInEditView: false,
      isRowVisibleInNormalView: true,
    },

    {
      title: TABLE_DATA_TAB_SECTION_TITLES?.profiling_information?.file_count,
      value: additionalAttrs?.tbl_files_count || "",
      isEditable: false,
      isRowVisibleInEditView: false,
      isRowVisibleInNormalView: true,
    },
  ];
}

export function getTableCountHistory({
  data,
}: AxiosResponse<TableRecordCountResponse>): RecordCountChartType {
  return (
    data
      ?.map((item) => ({
        name: item?.TBL_LAST_REFRESHED_ON
          ? `${utcTOLocalTimeZone(
              item?.TBL_LAST_REFRESHED_ON,
              MONTH_DATE_YEAR
            )}`
          : "",
        "Record Count": item?.TBL_RECORD_CNT || 0,
      }))
      ?.sort((a, b) => {
        if (!a?.name) return -1;
        if (!b?.name) return 1;
        return new Date(a?.name)?.valueOf() - new Date(b?.name)?.valueOf();
      }) || []
  );
}

export function getRelatedTables({
  data,
}: AxiosResponse<RelatedTablesResponse[]>): ReturnTypeOfGetRelatedTables[] {
  return data?.map((item) => ({
    entityId: `${item?.TBL_ENTITY_ID || ""}`,
    nodeId: `${item?.TBL_ID || ""}`,
    header: [
      { nodeType: "SRC", title: item?.SRC_NAME || "" },
      { nodeType: "TBL", title: item?.TBL_DISPLAY_NAME || "" },
    ],
    title: {
      nodeType: "TBL",
      mainTitle: item?.TBL_DISPLAY_NAME || "",
      subTitle: `${
        item?.TBL_ENDORSED_CNT ? `${item?.TBL_ENDORSED_CNT} people liked` : ""
      }`,
    },
    desc: item?.TBL_USER_DESC || "",
    additionalInfo: [
      {
        title: TABLE_DATA_TAB_SECTION_TITLES?.related_tables?.functional_area,
        value: item?.FUNC_AREA_NAME || "--",
      },
      {
        title: TABLE_DATA_TAB_SECTION_TITLES?.related_tables?.last_refreshed_on,
        value: utcTOLocalTimeZone(item?.TBL_LAST_REFRESHED_ON),
      },
    ],
  }));
}

export function getParsedTableDocForRefPage({
  data,
}: AxiosResponse<TableDocResponse>): ReturnTypeOfTableRefParser {
  const createdObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TABLE_SECTION_TITLES?.audit_trail?.created
  );
  const updatedObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TABLE_SECTION_TITLES?.audit_trail?.updated
  );

  const functionalAreaName =
    data?.classifications?.functional_area?.value?.functional_area_name || "";
  const functionalAreaId =
    data?.classifications?.functional_area?.value?.functional_area_id || "";

  const entityId = data?.classifications?.entity?.value?.entity_id || "";
  const entityName = data?.classifications?.entity?.value?.entity_name || "";

  const classificationData =
    getClassificationCustomTags(data?.classifications || {}) || [];

  const {
    dq_status_id: dqStatusId,
    dq_status: dqStatus,
    dq_score: dqScore,
    dq_variance: dqVariance,
  } = data?.data_quality || {};

  return {
    overview_info: [
      {
        title: TABLE_SECTION_TITLES?.overview?.usability_score,
        value: `${data?.description?.metadata_score?.value || ""}`,
      },
      {
        title: TABLE_SECTION_TITLES?.overview?.definition,
        value: data?.description?.description?.value,
      },
      {
        title: TABLE_SECTION_TITLES?.overview?.audit_trail,
        value: `${createdObj?.activity || ""} by ${
          createdObj?.user_name || ""
        } ${getTimeDifference(
          utcTOLocalTimeZone(createdObj?.activity_on || "")
        )}:${updatedObj?.activity || ""} by ${
          updatedObj?.user_name || ""
        } ${getTimeDifference(
          utcTOLocalTimeZone(updatedObj?.activity_on || "")
        )}`,
      },
      {
        title: TABLE_SECTION_TITLES?.classification?.functional_area,
        value: functionalAreaName,
        selectedValues: [`${functionalAreaId}`],
      },
      {
        title: TABLE_SECTION_TITLES?.classification?.entity,
        value: entityName,
        selectedValues: [`${entityId}`],
      },
    ],
    managed_by: [
      {
        title: TABLE_SECTION_TITLES?.managed_by?.data_owners,
        value: TABLE_SECTION_TITLES?.managed_by?.data_stewards,
        keyValueTags: data?.managed_by?.data_owners?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.data_owner_name || ""} (${
                    item?.data_owner_email || ""
                  })`
                : item?.data_owner_name,
            type: item?.user_type,
            email: item?.data_owner_email || "",
          }))
          ?.filter((item) => item?.name),
        selectedValues: data?.managed_by?.data_owners?.value
          ?.map((item) => item?.data_owner_name || "")
          ?.filter((item) => item),
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isInherited: data?.managed_by?.data_owners?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_owners?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: TABLE_SECTION_TITLES?.managed_by?.system_owner,
        value: "",
        keyValueTags: data?.managed_by?.system_owners?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.system_owner_name || ""} (${
                    item?.system_owner_email || ""
                  })`
                : item?.system_owner_name,
            type: item?.user_type,
            email: item?.system_owner_email || "",
          }))
          ?.filter((item) => item?.name),
        selectedValues: data?.managed_by?.system_owners?.value
          ?.map((item) => item?.system_owner_name || "")
          ?.filter((item) => item),
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isInherited: data?.managed_by?.system_owners?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.system_owners?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: TABLE_SECTION_TITLES?.managed_by?.data_stewards,
        value: "",
        keyValueTags: data?.managed_by?.data_stewards?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.data_steward_name || ""} (${
                    item?.data_steward_email || ""
                  })`
                : item?.data_steward_name,
            type: item?.user_type,
            email: item?.data_steward_email || "",
          }))
          ?.filter((item) => item?.name),
        selectedValues: data?.managed_by?.data_stewards?.value
          ?.map((item) => item?.data_steward_name || "")
          ?.filter((item) => item),
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isInherited: data?.managed_by?.data_stewards?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_stewards?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
    ],
    classification: [
      {
        title: TABLE_SECTION_TITLES?.classification?.format,
        value: data?.classifications?.format || "",
      },
      {
        title: TABLE_SECTION_TITLES?.classification?.sub_format,
        value: data?.classifications?.subformat || "",
      },

      ...classificationData,
    ],
    quality: [
      {
        title: TABLE_SECTION_TITLES?.data_quality?.overall_score,
        value: dqScore || "",
        additionalInfo: {
          statusId: dqStatusId,
          status: dqStatus,
          score: dqScore,
          trend: dqVariance,
        } as DQScoreProps,
      },
      {
        title: TABLE_SECTION_TITLES?.data_quality?.last_updated,
        value: data?.last_refreshed_on,
      },
    ],
    provenance: [
      {
        title: TABLE_SECTION_TITLES?.provinance_data?.scan_frequency,
        value: data?.provenance_scan?.scan_frequency || "--",
      },
    ],
    represents:
      data?.provenance_scan?.provenance?.value?.map((item) => ({
        dbName: item?.parent_name || "",
        tblName: item?.node_name || "",
        tblId: `${item?.node_id || ""}`,
        dbId: `${item?.parent_id || ""}`,
        isRefView: true,
        isTblSelection: true,
      })) || [],
    additionalInfo: [
      ...(data?.additional_info?.value || [])
        ?.filter((item) => item?.Link)
        ?.map((item, index) => ({
          actionId: "embed_link" as Actions,
          id: `embed_link-${index}`,
          value: item?.Link || "",
        })),
      ...(data?.additional_info?.value || [])
        ?.filter((item) => item?.Text)
        ?.map((item, index) => ({
          actionId: "edit" as Actions,
          id: `edit-${index}`,
          value: item?.Text || "",
        })),
    ],
    getAccessInfo: [
      {
        actionId: "",
        id: "",
        value: data?.get_access_info?.value,
      },
    ],
    isDeprecated: data?.published_status_id === "DEP",
    publishStatusId: data?.published_status_id,
    publishStatus: data?.published_status,
    nodeTitle: data?.title?.value || "",
    nodeDesc: data?.description?.description?.value || "",
    title: data?.title?.value || "",
    is_endorsed: !!data?.endorsed_status?.value,
    isEditable: !!data?.is_editable,
    parentId: data?.parent_id || "",
    parentName: data?.parent_name || "",
    physicalName: data?.physical_name || "",
    isDqEnabled: data?.is_dq_enabled || false,
    prominentTags: {
      tags: getProminentTags(classificationData),
      isBoldView: true,
    },
    lineage: getLineagePreviewData(data?.lineage),
    popularityScore: getPopularityScoreData(
      data?.tbl_popularity_id as ScoreType,
      data?.tbl_popularity,
      data?.tbl_popularity_info
    ),
    isWfEnabled: !!data?.is_wf_enabled,
  };
}

export function getParsedDataModelMaxLevel({
  data,
}: AxiosResponse<DataModelMaxLevelResponse>): ReturnTypeOfGetDataModelMaxLevelParser {
  return data;
}

export function getParsedDataModelState({
  data,
}: AxiosResponse<DataModelStateResponse>): ReturnTypeOfGetDataModelStateParser {
  return isArray(data) ? data : [];
}

export function getParsedDataModelWithLevels(
  { data }: AxiosResponse<RelationshipTablesDataResponse>,
  diagramLevel: number,
  tableId: string,
  tableName: string
): ReturnTypeOfRelationshipTablesDataParser {
  const sortedData =
    data?.data?.sort((a, b) => a?.CONS_UNQ_ID - b?.CONS_UNQ_ID) || [];

  const diagramData = sortedData?.length
    ? getErdDiagramNodesAndEdges(sortedData, diagramLevel, tableId)
    : {
        nodes: [
          {
            id: `${tableId}`,
            data: {
              tableName,
            },
            position: { x: 0, y: 0 },
            style: { width: 245, height: 45 },
            type: "customComponent",
          },
        ],
        edges: [],
      };

  return {
    grid_data: getErdGridData(data),
    diagram_data: diagramData,
  };
}

export function getParsedRelationshipColumnsData({
  data,
}: AxiosResponse<RelationshipColumnsDataResponse>): ReturnTypeOfRelationshipColumnsDataParser {
  return data?.map((item) => ({
    table_id: `${item?.TBL_ID || ""}`,
    table_name: item?.TBL_NAME || "",
    table_display_name: item?.TBL_DISPLAY_NAME || "",
    column_id: `${item?.COL_ID || ""}`,
    column_name: item?.COL_NAME || "",
    column_title: item?.COL_TITLE || "",
    column_type: item?.COL_DATA_TYPE_ID || "STR",
    is_col_pk: item?.IS_COL_PK || false,
  }));
}

export function getColumnConfigData({
  data,
}: AxiosResponse<
  ColumnResponseCongigurationType[]
>): ColumnParsedConfigurationType[] {
  return (
    data?.map((item) => {
      return {
        field_id: `${item?.COL_ID}` || "",
        field_name: item?.COL_DISPLAY_NAME || "",
        field_display_name: item?.COL_DISPLAY_NAME || "",
        // display_order: item?.COL_SEQUENCE || 0,
        display_order: item?.COL_DISPLAY_ORDER || 0,
        field_datatype: item?.COL_DATA_TYPE_ID || "STR",
        field_type_id: item?.COL_TYPE_ID || "",
        column_title: item?.COL_TITLE || "",
      };
    }) || []
  );
}

export function getColumnsWithDistributionData({
  data,
}: AxiosResponse<
  ColumnResponseCongigurationType[]
>): FiltersColumnParsedConfigurationType[] {
  const sortedColumns = sortListOnSpecificKeyValue({
    list: data,
    key: "COL_DISPLAY_NAME",
  });

  return (
    sortedColumns?.map((item) => {
      const colDistribution: ColDistributionType = jsonParse(
        item?.COL_DISTRIBUTION || "",
        true
      );
      const colDistributionValues = getObjectKeys(colDistribution?.DIST) || [];

      const notAllowedKeywords = ["null", "blanks"];

      const filteredColDistributionValues = colDistributionValues
        ?.filter((item) => {
          const trimmedValue = item?.trim()?.toLocaleLowerCase();
          return trimmedValue && !notAllowedKeywords?.includes(trimmedValue);
        })
        ?.map((item) => removeEscapeCharForNewLine(item));

      return {
        field_id: `${item?.COL_ID}` || "",
        field_name: item?.COL_DISPLAY_NAME || "",
        field_display_name: item?.COL_TITLE || "",
        display_order: item?.COL_SEQUENCE || 0,
        field_datatype: item?.COL_DATA_TYPE_ID || "STR",
        includes_exclues_exists:
          Boolean(filteredColDistributionValues?.length) || false,
        col_distribution_values: filteredColDistributionValues,
        col_min_value: item?.COL_MIN_VALUE || "",
        col_max_value: item?.COL_MAX_VALUE || "",
        col_blank_count: item?.COL_BLANKS_CNT || 0,
        field_type_id: item?.COL_TYPE_ID || "",
        field_distinct_count: item?.COL_DIST_CNT || 0,
        col_seq: item?.COL_DISPLAY_ORDER || 0,
        is_field_hidden: false,
        //is_field_filterable is required fot this api as all columns are of table and can be filtered so giving it default value of true
        is_field_filterable: true,
        field_type: item?.COL_TYPE || "",
        field_meta_score: item?.COL_METASCORE,
        field_entity_name: item?.COL_ENTITY_NAME,
        field_desc: item?.COL_USER_DESC,
        field_sub_type: item?.COL_SUB_TYPE,
      };
    }) || []
  );
}

export function getTableEditConfigData({
  data,
}: AxiosResponse<RulesEditDrawerResponseType>): RulesEditDrawerParsedDataType {
  const filterConfig = data?.filter?.[0] || [];
  const columnsConfig = data?.col_seq || [];

  const additionalInfo: AdditionalInfoType = jsonParse(
    filterConfig?.ADDITIONAL_INFO || "",
    true
  );
  const filters: FilterResponseType = jsonParse(
    filterConfig?.TBL_FILTER_CONFIG || "",
    true
  );
  const isFilterExists = filters?.filter?.[0]?.condition?.length;

  const parsedFilters = isFilterExists
    ? (filters?.filter || [])?.flatMap((filterCriteriaRow) => {
        const apiToFormParsedData = apiToFormCriteria(
          filterCriteriaRow?.condition,
          []
        );

        return {
          condition: apiToFormParsedData,
          next_operator: filterCriteriaRow?.next_operator,
        };
      })
    : [];

  const columnsParsedData =
    columnsConfig?.map((item, index) => {
      return {
        field_id: `${item?.COL_ID}` || "",
        field_name: item?.COL_NAME || "",
        field_display_name: item?.COL_TITLE || "",
        display_order: item?.COL_DISPLAY_ORDER || 0,
        field_datatype: item?.COL_DATA_TYPE_ID || "STR",
        // col_seq: item?.COL_SEQUENCE || 0,
        col_seq: index + 1,
        field_distinct_count: item?.COL_DIST_CNT || 0,
      };
    }) || [];

  return {
    metric_time: filterConfig?.METRIC_TIME,
    load_profile: additionalInfo?.load_profile || "ALL",
    exception_record_limit: additionalInfo?.exception_record_limit || 0,
    filter_text: filters?.filter_text || "",
    filterCriteria: parsedFilters,
    columns: columnsParsedData,
  };
}
