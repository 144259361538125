import styled from "styled-components";

export const EditPromptModalStyled = styled.div`
  .content-sec {
    padding: 32px;
    max-height: 470px;
    overflow-y: auto;

    .ant-form-item-control {
      width: 100%;
    }
  }

  .ant-form {
    .ant-form-item {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .textarea-field-container > textarea {
              height: 350px !important;
            }
          }
        }
      }
    }
  }
`;
