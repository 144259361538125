import styled from "styled-components";

export const FilterRowAndRemoveButtonWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  .ant-select-multiple .ant-select-selector .ant-select-selection-item {
    height: 26px;
  }
`;

export const FilterRowWithLogicalOperStyled = styled.div`
  gap: 10px;
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 100%;

  .logical-condition-renderer {
    min-width: 80px;

    .text {
      display: inline-block;
      margin-top: 10px;
      margin-left: 10px;
      color: ${({ theme }): string => theme.textColors.GREY_26};
      font-size: 12px;
    }

    .toggle-button-wrapper {
      margin-top: 2.5px;
    }
  }

  .remove-btn {
    margin-top: 10px;
    position: absolute;
    right: 0;
  }
`;

export const FilterInputWrapperStyled = styled.div`
  column-gap: 10px;
  row-gap: 3px;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .column-select-field {
    width: 240px;
    .multiselect-dropdown-dvsum
      .rc-virtual-list-holder
      .ant-select-item-option {
      padding: 5px 10px;
    }

    /* padding: 5px 15px; */
  }

  .filter-select-field {
    width: 180px;
  }

  .date-type-select-field {
    width: 150px;
  }

  .multi-select-values {
    width: 345px;
  }

  .select-field-container {
    width: max-content;
  }

  .input-field-container > input {
    max-width: 210px;
  }

  .input-field-container {
    .ant-picker {
      width: 200px;
    }
  }

  .textarea-field-container {
    width: 85%;

    textarea {
      width: 100%;
      display: flex;
      align-items: center;
      overflow-y: auto;
      /* had to add important because an important is already being used with 120px height , making my ui distorted */
      max-height: 40px !important;
    }
  }
`;
