import { useCallback } from "react";
import { useHistory } from "react-router";

//      <---Components--->
import CoreNodesCircleIcon from "../corenodetypescircleicon";
import Flex from "../flex";

import LinkButton from "../linkbutton";
//      <---Components--->

//      <---Custom Hooks--->
import { useGetAppType, useOpenDrawer } from "../../customhooks";
//      <---Custom Hooks--->

//      <---SVGS--->
import { longDownArrow } from "./lineagepreviewer.svgs";
//      <---SVGS--->

//      <---Styles--->
import {
  LineagePreviewerStyled,
  LineageSingleSecStyled,
  LineageSingleSecWrapperStyled,
} from "./lineagepreviewer.styles";
//      <---Styles--->

//      <---Styles--->
import {
  getDetailpageUrlBasedOnNodeType,
  getDrawerIdBasedOnNodeType,
} from "../../utils";
//      <---Styles--->

//      <---Styles--->
import { LineagePreviewerProps } from "./lineagepreviewer.types";
//      <---Styles--->

const keysOfData: Array<keyof LineagePreviewerProps["data"]> = [
  "level1",
  "level2",
  "level3",
  "level4",
  "level5",
];

function LineagePreviewer(props: LineagePreviewerProps): JSX.Element {
  const { data, isRefView, align, marginBottom } = props;
  const history = useHistory();

  const openDrawer = useOpenDrawer();
  const { isExtOrDesktop } = useGetAppType();

  const filteredKeys = keysOfData?.filter((item) => data?.[item]?.length);

  const onNodeClick = useCallback((id, nodeType, nodeSubType) => {
    if (isRefView) {
      const url = getDetailpageUrlBasedOnNodeType(id, nodeType, nodeSubType);
      url && isExtOrDesktop ? window.open(url) : history.push(url);
    } else {
      const drawerId = getDrawerIdBasedOnNodeType(nodeType, nodeSubType);
      drawerId &&
        openDrawer({
          drawerId,
          visible: true,
          drawerProps: { id },
        });
    }
  }, []);

  return (
    <LineagePreviewerStyled
      align={align}
      className="lineage-previewer"
      marginBottom={marginBottom}
    >
      {filteredKeys?.map((item, index) => {
        const isCurrentNode = data?.[item]?.some((node) => node?.isCurrentNode);

        return (
          <LineageSingleSecWrapperStyled key={`lineage-prviewer-sec${item}`}>
            <LineageSingleSecStyled isCurrent={isCurrentNode}>
              {data?.[item]?.map((nestItem) => (
                <Flex
                  columnGap={5}
                  key={`${nestItem?.id}:${nestItem?.nodeName}:${nestItem?.nodeSubType}:${nestItem?.nodeType}`}
                >
                  <div>
                    <CoreNodesCircleIcon
                      nodeType={nestItem?.nodeType}
                      nodeSubType={nestItem?.nodeSubType}
                      width="18px"
                      height="18px"
                      mode={isCurrentNode ? "secondary" : "primary"}
                      fontSize={nestItem?.nodeType === "DSF" ? "8px" : "10px"}
                    />
                  </div>
                  {isCurrentNode ? (
                    <div title={nestItem?.nodeName} className="current-node">
                      {nestItem?.nodeName}
                    </div>
                  ) : (
                    <LinkButton
                      showEllipsis
                      onClick={(): void =>
                        onNodeClick(
                          nestItem?.id,
                          nestItem?.nodeType,
                          nestItem?.nodeSubType
                        )
                      }
                      title={nestItem?.nodeName}
                    >
                      {nestItem?.nodeName}
                    </LinkButton>
                  )}
                </Flex>
              ))}
            </LineageSingleSecStyled>
            {index + 1 !== filteredKeys?.length && longDownArrow}
          </LineageSingleSecWrapperStyled>
        );
      })}
    </LineagePreviewerStyled>
  );
}

export default LineagePreviewer;
