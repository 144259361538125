import styled from "styled-components";

export const GridActionButtonsStyled = styled.div<{
  isProfilingInProgress?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;

  margin-top: ${({ isProfilingInProgress }): string =>
    isProfilingInProgress ? "22px" : ""};

  .feedback-analytics-act-btns {
    margin-bottom: 17.6px;
    margin-top: 10px;

    .update-status-btn {
      display: flex;
      align-items: center;
      column-gap: 6.4px;
    }

    .ant-dropdown {
      .ant-btn-link {
        color: ${({ theme }): string => theme?.textColors?.GREY_24};
      }
    }
  }

  .left-action-section {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .right-action-section {
    .ant-input-affix-wrapper {
      width: 270px;
      height: 40px;
    }
  }

  .abort-executions-btn {
    display: flex;
    align-items: center;
  }

  .grid-action-btns-wrapper {
    display: flex;
    align-items: center;
    padding: 29.75px 0px;

    button {
      display: flex;
      gap: 5px;
      /* padding: 0px 8.5px; */
      /* align-items: baseline; */
      font-family: OpenSansSemiBold;
      /* border-right: 1px solid ${({ theme }): string =>
        theme.borderColors.GREY_10}; */
      border-radius: 0px;
      line-height: 13px;

      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        border-right: none;
      }
      &:hover,
      :active,
      :focus {
        /* border-right: 1px solid
        ${({ theme }): string => theme.borderColors.GREY_10}; */

        &:last-child {
          border-right: none;
        }
      }

      &.delete-icon {
        svg {
          width: 12px;
          height: 12px;
        }
      }

      &.mass-update-btn {
        svg {
          path {
            fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
          }
        }
      }
    }

    .play-btn-icon,
    .play-btn-icon .ant-btn-link {
      align-items: center;

      svg {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }

  .run-job-btn {
    align-items: center;
    column-gap: 0;

    svg {
      fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
    }
  }

  .delete-btn,
  .delete-btn .ant-btn-link {
    align-items: center;
    color: ${({ theme }): string => theme.textColors.RED_100};
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.RED_100};
      }
    }
  }
`;

export const DropdownMenuWrapper = styled.div`
  .menu-items-wrapper {
    left: 155px;
    top: 88px;
    position: absolute;
    padding: 10px 5px;
    .menu-item {
      div {
        color: ${({ theme }): string => theme.textColors.GREY_210};
      }
    }
  }
`;

export const TagsItemsWrapperStyled = styled.div`
  .add-btn {
    svg {
      circle {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
      path {
        fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
      }
    }

    :hover {
      svg {
        circle {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
        path {
          fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
        }
      }
    }
  }
  .dropdown-icon {
    height: 15px;

    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }
`;

export const RunProfilingButtonItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  .ant-btn {
    display: flex;
    align-items: center;
    column-gap: 0;
  }

  .dropdown-icon {
    margin-left: 5px;
    height: 15px;

    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }

  .run-offline-rule-btn {
    :hover {
      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
      }
    }

    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }
`;
