import styled from "styled-components";

export const DataQualityHistoryChartStyled = styled.div`
  border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
  padding: 28px;

  .dvsum-linechart {
    padding: 0px;
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};

    .redgets-axis-chart-wrapper__xAxis--title {
      color: #959596;
      font-size: 13px;
      margin-right: 12px;
    }
  }
`;
