import { useMemo, useState } from "react";
import { Card } from "antd";

import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  TooltipProps,
} from "recharts";

import RechartHOC from "../recharthoc/recharthoc";
import { BarChartStyled } from "./barchart.styles";

import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";
import { BarChartProps } from "./barchart.types";

import { numberFormatter } from "../../../utils";

import { DEFAULT_MARGIN } from "../defaultmargin";
import { DEFAULT_X_AXIS_LABEL } from "../defaultxaxislabel";

import { DEFAULT_Y_AXIS_LABEL } from "../defaultyaxislabel";
import Customizedaxistick from "../customizedaxistick";
import { DataType } from "../../../parsers/ruleparser/ruleparser.types";

import { CustomTooltipStyled } from "../columnchart/column.styles";
import LinkButton from "../../linkbutton";
import { onBarClickRouteNames } from "../../widgetcard/widgetcard.util";
import { redirectCircledIcon } from "../../../svgs";
import CustomizedLabel from "../customizedlabel/customizedlabel";
import { calculateInterval } from "../customizedlabel/customizedlabel.utils";

const BarChartContent = (
  props: BarChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = 500,
    height = 500,
    data = [],
    clonedDataKeys = [],
    xAxislabel,
    legends,
    margin,
    isShowLegend,
    updatedYAxis,
    isMultipleYAxis,
    hideXAxis = false,
    showCartesianGrid = true,
    hideYAxis = false,
    customLabel = false,
    isStacked,
    onBarClick,
    yAxisFormatter,
    xAxisTickFormatter,
    tooltipLabelFormatter,
    tooltipFormatter,
    isButtonInTooltip = false,
    chartYAxisLeftOffset = 0,
    chartInnerSpaceFromLeft = 30,
    charsCountLimit = 10,
    govViewCategory,
    listOfDataKeys = [],
  } = props;

  const [labelInterval, setLabelInterval] = useState(0);

  const cursorStyle = onBarClick ? "pointer" : "default";

  const [payload, setPayload] = useState<{ y: number; x: number } | undefined>({
    y: 0,
    x: 0,
  });

  const [trigger, setTrigger] = useState(false);

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>): JSX.Element => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipStyled
          onMouseEnter={(): void => setTrigger(true)}
          onMouseLeave={(): void => setTrigger(false)}
        >
          <div className="label-text">
            <p className="label">{`${label}`}</p>
            {onBarClick && (
              <LinkButton
                className="tooltip-button"
                onClick={(e): void => {
                  onBarClick?.(label);
                  e.stopPropagation();
                }}
                tooltipProps={{
                  title: `You will be redirected to the ${
                    onBarClickRouteNames[govViewCategory || ""]
                  } with this filter applied by default.`,
                  placement: "top",
                }}
              >
                {redirectCircledIcon("15px", "14px")}
              </LinkButton>
            )}
          </div>

          <div className="content">
            {payload.map((pld, index) => (
              <div key={index} className="content-row">
                <div style={{ color: pld.color }}>{`${pld.dataKey}: `}</div>

                <div style={{ color: pld.color }}>
                  {numberFormatter(
                    String(Number.isNaN(pld?.value) ? 0 : Number(pld.value))
                  )}
                </div>
              </div>
            ))}
          </div>
        </CustomTooltipStyled>
      );
    }

    return <div />;
  };

  const lengths = useMemo(
    () =>
      data
        .map((obj) => {
          return Object.values(obj)
            .filter((value) => typeof value === "number")
            .map((num) => num?.toString()?.length);
        })
        .flat(),
    [data]
  );

  const totalLength = useMemo(
    () => lengths?.reduce((acc, len) => acc + len, 0),
    [lengths]
  );
  const averageLength = useMemo(
    () => Math.round(totalLength / lengths?.length),
    [totalLength, lengths]
  );

  return (
    <Card className="chart-card">
      <BarChartStyled>
        <ResponsiveContainer
          width={width}
          height={height}
          onResize={(width, height): void => {
            setLabelInterval(
              calculateInterval(
                height * (averageLength > 4 ? 0.5 : 1),
                data?.length
              )
            );
          }}
        >
          <Chart
            data={data}
            margin={{
              ...DEFAULT_MARGIN,
              left: chartYAxisLeftOffset - chartInnerSpaceFromLeft,
              ...margin,
            }}
            layout="vertical"
          >
            {showCartesianGrid && (
              <CartesianGrid
                strokeDasharray="1"
                horizontal={false}
                stroke="#e6e6e6"
              />
            )}
            {isShowLegend && legends}
            <XAxis
              stroke="#8a8c8c"
              axisLine={{ stroke: "#e6e6e6" }}
              tickLine={false}
              type="number"
              tickFormatter={(val): string => {
                return xAxisTickFormatter?.(val) || numberFormatter(val);
              }}
              domain={([min, max]): [number, number] => {
                const updatedMin = min < 0 ? min : 0;
                return [updatedMin, max + max / 4];
              }}
              hide={hideXAxis}
            >
              {xAxislabel && (
                <Label {...{ ...DEFAULT_X_AXIS_LABEL, ...xAxislabel }} />
              )}
            </XAxis>

            {updatedYAxis?.map((item) => (
              <YAxis
                key={`bar-chart-y-axis-${item?.yAxisId}`}
                dataKey={item?.yAxisId}
                axisLine={{ stroke: "#e6e6e6" }}
                tickLine={false}
                stroke="#8a8c8c"
                type="category"
                {...(isMultipleYAxis && {
                  ...item,
                  label: undefined,
                })}
                hide={hideYAxis}
                tickFormatter={(val): string => {
                  return `${item?.prefix || ""}${
                    item?.notApplyFormatting
                      ? val
                      : yAxisFormatter?.(val, item?.yAxisId || "") ||
                        numberFormatter(val)
                  }`;
                }}
                tick={<Customizedaxistick charsCountLimit={charsCountLimit} />}
              >
                {item?.label && (
                  <Label
                    {...{
                      ...DEFAULT_Y_AXIS_LABEL,
                      ...item?.label,
                    }}
                  />
                )}
              </YAxis>
            ))}

            <Tooltip
              wrapperStyle={{
                pointerEvents: "auto",
                zIndex: 12,
              }}
              trigger={
                isButtonInTooltip ? (trigger ? "click" : "hover") : "hover"
              }
              {...(isButtonInTooltip && { content: <CustomTooltip /> })}
              formatter={(val): string => {
                return (
                  tooltipFormatter?.(Number(val), "") ||
                  numberFormatter(`${val}`, true)
                );
              }}
              position={
                !isButtonInTooltip
                  ? undefined
                  : payload
                  ? { y: payload?.y - 20, x: payload?.x }
                  : undefined
              }
              labelFormatter={tooltipLabelFormatter}
            />

            {clonedDataKeys?.map((key) => (
              <Bar
                isAnimationActive={!customLabel}
                {...key}
                barSize={32.8}
                key={`columnchart-datakey-${key?.dataKey}`}
                {...(!isStacked && { minPointSize: 2 })}
                {...(isMultipleYAxis && {
                  yAxisId: key?.dataKey?.toString(),
                })}
                {...(isStacked && { stackId: "a" })}
                {...(customLabel && {
                  label: (props): JSX.Element => (
                    <CustomizedLabel
                      props={{
                        ...props,
                        data,
                        key,
                        isStacked,
                        chartType: "BAR",
                        labelInterval,
                        listOfDataKeys,
                      }}
                    />
                  ),
                })}
                cursor={cursorStyle}
                onMouseEnter={(props): void => {
                  setPayload(props);
                }}
                onMouseLeave={(): void => {
                  setPayload(undefined);
                }}
              />
            ))}
          </Chart>
        </ResponsiveContainer>
      </BarChartStyled>
    </Card>
  );
};

const BarChart = (props: BarChartProps): JSX.Element => {
  return (
    <RechartHOC
      dataKeys={props?.dataKeys}
      yAxis={props?.yAxis}
      showLedgends={props?.showLedgends}
      legendVerticalAlignment={props?.legendVerticalAlignment}
      legendHorizontalAlignment={props?.legendHorizontalAlignment}
    >
      <BarChartContent {...props} />
    </RechartHOC>
  );
};
export default BarChart;
