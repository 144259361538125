import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  FieldValues,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";

import parse from "html-react-parser";

import { useDispatch } from "react-redux";
import { Space } from "antd";
import { useRequestWithMethod } from "../../../../../../../../api";
import { setModal } from "../../../../../../../../reducers/appreducer/appreducer";
import { getEnvVariables } from "../../../../../../../../utils";

import {
  DynamicFieldsJoinProps,
  DynamicModalProps,
} from "../../connectionsettingsdynamicmodal/connectionsettingsdynamicmodal.types";

import { DynamicFormGroupProps } from "../../connectionsettingstabformgroup/connectionsettingstabformgroup.types";
import {
  ActionTypeProps,
  DynamicFormFieldProps,
  DynamicLogEventTypes,
} from "../connectionsettingstabform.types";

import {
  getItemValue,
  handleButtonXHRRequest,
  hideShowGroupOrField,
  setAlertsContentForData,
} from "../connectionsettingstabform.utils";

import LinkButton from "../../../../../../../../components/linkbutton/linkbutton";
import { Button } from "../../../../../../../../components";
import { getIconByType } from "../connectionsettingstabform.renderers";
import {
  connectionSettingsLogger,
  handleDynamicOnFieldChange,
  getTemplateMessageByConfig,
  getResponseCheckData,
} from "../utils";
import { CustomException } from "../../../../../../../../customexception";

type GetActionButtonTypes = {
  form: ReturnType<typeof useForm>;
  actionData?: ActionTypeProps;
  setGroupVisibility?: React.Dispatch<SetStateAction<{ [x: string]: boolean }>>;
  parentFormValuesGetter?: UseFormGetValues<FieldValues>;
  setFormFieldKeys?: React.Dispatch<SetStateAction<{ [x: string]: string }>>;
  successCallback?: () => void;
  parentFormValuesSetter?: UseFormSetValue<FieldValues>;
  setAlertsContent?: DynamicFormGroupProps["setAlertsContent"];
  enableDisableElements?: DynamicFormGroupProps["enableDisableElements"];
  setEnableDisableElements?: DynamicFormGroupProps["setEnableDisableElements"];
  setDialogError?: DynamicFormGroupProps["setDialogError"];
  setFieldLogs?: DynamicFormFieldProps["setFieldLogs"];
  disabled?: boolean;
};

function getDefaultValuesForModal(
  defaultValues: { [x: string]: any } = {},
  defaultValuesConfig?: { [x: string]: DynamicFieldsJoinProps },
  form?: ReturnType<typeof useForm>
): any {
  if (defaultValuesConfig) {
    return {
      ...defaultValues,
      ...Object.keys(defaultValuesConfig)?.reduce(
        (prev, next) => ({
          ...prev,
          [next]: getItemValue(defaultValuesConfig?.[next], form),
        }),
        {}
      ),
    };
  }

  return defaultValues;
}

export const DynamicActionButton = ({
  form,
  actionData,
  setGroupVisibility,
  parentFormValuesGetter,
  setFormFieldKeys,
  successCallback,
  parentFormValuesSetter,
  setAlertsContent,
  enableDisableElements,
  setEnableDisableElements,
  setDialogError,
  setFieldLogs,
  disabled: disabledBtn,
}: GetActionButtonTypes): JSX.Element => {
  const dispatch = useDispatch();
  const onClickConfig = actionData?.onClickConfig;

  const [disabled, setDisabled] = useState<boolean>(disabledBtn ?? false);

  const isDisabled = useMemo(() => {
    return enableDisableElements?.[`${actionData?.id}`] ?? disabledBtn ?? false;
  }, [enableDisableElements?.[`${actionData?.id}`], disabledBtn]);

  const { isLoading, onExecuteRequest } = useRequestWithMethod(
    onClickConfig?.xhrConfig?.method === "POST"
      ? "dynamic_form_url_post"
      : "dynamic_form_url_get",
    undefined,
    false,
    undefined,
    (err) => {
      if (onClickConfig?.xhrConfig) {
        const { logEvents, onFailedConfig } = onClickConfig?.xhrConfig;

        if (onFailedConfig) {
          const { hideShowElements, alertConfig, errorConfig } = onFailedConfig;

          // const responseCheckData =
          //   alertConfig?.responseCheck &&
          //   getAlertResponseCheckData(alertConfig?.responseCheck, err);

          // let newHideShowElements =
          //   (hideShowElements && [...hideShowElements]) || [];

          // if (responseCheckData) {
          //   alertConfig?.responseCheck.forEach((rCheck) => {
          //     newHideShowElements = getFinalAlertResponseCheck(
          //       newHideShowElements,
          //       responseCheckData,
          //       rCheck
          //     );
          //   });
          // }

          hideShowGroupOrField(hideShowElements, setGroupVisibility);

          setAlertsContentForData({ alertConfig, setAlertsContent });

          if (errorConfig) {
            let matchedAny = false;
            errorConfig?.forEach((item) => {
              if (item?.level === "form") {
                if (item?.showOnPass || item?.showOnFail) {
                  const { responseCheck } = item;

                  const result =
                    responseCheck && getResponseCheckData(responseCheck, err);

                  if (
                    result?.length &&
                    item?.showOnPass &&
                    result?.every((item) => item)
                  ) {
                    const message = getTemplateMessageByConfig(
                      item?.message,
                      item?.responseCheck,
                      result,
                      form
                    );

                    const customException = new CustomException({
                      ...err,
                      message: parse(message),
                    });

                    setDialogError && setDialogError(customException);
                    matchedAny = true;
                  }
                }
              }
            });

            if (!matchedAny && setDialogError) {
              setDialogError(err);
            }
          }
        }

        if (!onFailedConfig || !onFailedConfig?.errorConfig) {
          setDialogError && setDialogError(err);
        }

        if (logEvents) {
          const logEventType:
            | DynamicLogEventTypes
            | undefined = logEvents?.failed
            ? "failed"
            : logEvents?.completed
            ? "completed"
            : undefined;

          logEventType &&
            connectionSettingsLogger(
              logEvents,
              setFieldLogs,
              logEventType,
              form,
              err
            );
        }
      }
    }
  );

  const requestUrl = useMemo(() => {
    if (onClickConfig?.xhrConfig?.isInternal) {
      const { REACT_APP_API_DOMAIN } = getEnvVariables();

      return `${REACT_APP_API_DOMAIN}/${onClickConfig?.xhrConfig?.urlEndpoint}`;
    }

    return onClickConfig?.xhrConfig?.urlEndpoint;
  }, [onClickConfig?.xhrConfig]);

  const onClickButton = useCallback(() => {
    if (onClickConfig?.eventHandlerType === "dialog") {
      dispatch(
        setModal({
          modalId: "connection_settings_dynamic_modal",
          visible: true,
          modalTitle: onClickConfig?.dialogConfig?.title || "",
          modalProps: {
            ...onClickConfig?.dialogConfig,
            defaultValues: getDefaultValuesForModal(
              onClickConfig?.dialogConfig?.defaultValues,
              onClickConfig?.dialogConfig?.defaultValuesConfig,
              form
            ),
            parentFormValuesGetter: form?.getValues,
            setFormFieldKeys,
            parentFormValuesSetter: form?.setValue,
            setFieldLogs,
          } as DynamicModalProps,
        })
      );
    } else if (actionData) {
      handleButtonXHRRequest({
        onExecuteRequest,
        requestUrl,
        form,
        actionData,
        setGroupVisibility,
        parentFormValuesGetter,
        setFormFieldKeys,
        successCallback,
        parentFormValuesSetter,
        setAlertsContent,
        setEnableDisableElements,
        setFieldLogs,
      });
    }
  }, [onClickConfig, requestUrl]);

  useEffect(() => {
    if (actionData?.triggerOnLoad) {
      if (actionData?.triggerRequiredFields) {
        !form
          ?.getValues(actionData?.triggerRequiredFields)
          ?.some((item) => item?.length === 0 ?? true) && onClickButton();
      } else {
        onClickButton();
      }
    }
  }, []);

  const { watch } = form;

  useEffect(() => {
    const subscription: any = handleDynamicOnFieldChange(
      watch,
      form,
      actionData?.onFieldsChange,
      setEnableDisableElements,
      setGroupVisibility
    );

    return (): void => subscription?.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (enableDisableElements?.[`${actionData?.id}`]) {
      setDisabled(enableDisableElements?.[`${actionData?.id}`] ?? disabled);
    }
  }, [enableDisableElements?.[`${actionData?.id}`]]);

  return actionData?.type === "link" ? (
    <LinkButton className="dynamic-link-btn" onClick={onClickButton}>
      <Space size={4}>
        {getIconByType(actionData?.iconType)}
        {actionData?.label}
      </Space>
    </LinkButton>
  ) : (
    <Button
      itemID={`${actionData?.id}`}
      onClick={onClickButton}
      loading={isLoading}
      disabled={isDisabled}
      {...(!isLoading &&
        isDisabled &&
        actionData?.disabledTooltipMessage && {
          tooltipProps: { title: actionData?.disabledTooltipMessage },
        })}
    >
      {!isLoading && isDisabled && actionData?.disabledLabel
        ? actionData?.disabledLabel
        : actionData?.label}
    </Button>
  );
};
