import { AxiosResponse } from "axios";

// <---  Constants Start --->
import { TERM_SECTION_TITLES } from "../../constants/labelconstants";
// <---  Constants End --->

// <---  Types Start --->
import { NodeParentalInfoResponse } from "../tablepage/tablepageparser.types";
import { CustomDataSetTag, NodePublishedStatusIds } from "../parser.types";

import { Actions } from "../../components/additionalinfo/additionalinfo.types";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import {
  ActivitiesGroupByDateAccumalatorType,
  ActivitiesParsedDataType,
  BuisinessRulesValues,
  ClassificationReturnType,
  CurrentObj,
  EventType,
  ListOfAllTermsReturnType,
  ParsedTermDocForDetailPage,
  ParsedTermDocForRef,
  ReviewChangesParsedType,
  ReviewChangesResponse,
  StepUser,
  TermDetailAllReplaceTrmDeprecatedAdditionalInfo,
  TermDocResponse,
  TermReplacesAdditionalIno,
  TermType,
  UserActivitiesArrayResponse,
  UserActivityType,
  WorkFlowStepResponse,
  WorkflowResponse,
  WorkflowStepsParsedType,
  WorkflowTemplateParsedType,
  WorkflowTemplateResponse,
} from "./termsparser.types";
// <---  Types End --->

// <---  Utils Start --->
import { getClassificationCustomTags } from "../parserutils";
import {
  getProminentTags,
  getTimeDifference,
  isEqual,
  jsonParse,
  sortObjectsArrayByKeyDescOrder,
  multiLevelSortArrayByKeys,
  sortListOnSpecificKeyValue,
} from "../../utils";

import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";
import { YEAR_MONTH_DATE } from "../../constants";
// <---  Utils End --->

function getBuisenessRulesForTerm(data: TermDocResponse): SectionRowProps[] {
  switch (data?.description?.term_type?.value?.term_type_id) {
    case "CAA":
      return [
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.CATEGORIES,
          moreTags:
            data?.business_rules?.value?.["Categorical Values"]?.map(
              (item) => item?.value
            ) || [],
        },
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.PATTERN,
          moreTags:
            data?.business_rules?.value?.Patterns?.map(
              (item) => item?.value || ""
            ) || [],
        },
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.MANUAL,
          value: data?.business_rules?.value?.Manual || "",
        },
      ];
    case "BSM":
    case "CMM":
      return [
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.VALUE_RANGE,
          value:
            data?.business_rules?.value?.["Value Range"]?.min !== undefined &&
            data?.business_rules?.value?.["Value Range"]?.max !== undefined &&
            data?.business_rules?.value?.["Value Range"]?.min !== null &&
            data?.business_rules?.value?.["Value Range"]?.max !== null
              ? `${data?.business_rules?.value?.["Value Range"]?.min} - ${data?.business_rules?.value?.["Value Range"]?.max}`
              : "",
        },
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.MANUAL,
          value: data?.business_rules?.value?.Manual || "",
        },
      ];
    case "NCA":
      return [
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.PATTERN,
          moreTags:
            data?.business_rules?.value?.Patterns?.map(
              (item) => item?.value || ""
            ) || [],
        },
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.MANUAL,
          value: data?.business_rules?.value?.Manual || "",
        },
      ];
    default:
      return [
        {
          title: TERM_SECTION_TITLES?.BUSINESS_RULES?.MANUAL,
          value: data?.business_rules?.value?.Manual || "",
        },
      ];
  }
}

export function getParsedTermDocForRef({
  data,
}: AxiosResponse<TermDocResponse>): ParsedTermDocForRef {
  const createdObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TERM_SECTION_TITLES?.AUDIT_TRAIL?.CREATED
  );
  const updatedObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TERM_SECTION_TITLES?.AUDIT_TRAIL?.UPDATED
  );

  const classificationData =
    getClassificationCustomTags(data?.classifications || {}) || [];
  return {
    node_desc: data?.description?.definition?.value || "",
    node_title: data?.title?.value || "",
    is_deprecated: data?.published_status_id === "DEP",
    category: data?.parent_name || "",
    domain_name: data?.domain_name || "",
    term_name: data?.term_name || "",
    title: data?.title?.value || "",
    is_endorsed: data?.endorsed_status?.value,
    is_editable: !!data?.is_editable,
    is_wf_enabled: !!data?.is_wf_enabled,
    publish_status: data?.published_status,
    publish_status_id: data?.published_status_id,
    overview_info: [
      {
        title: TERM_SECTION_TITLES?.OVERVIEW?.USABILITY_SCORE,
        value: `${data?.description?.metadata_score?.value || ""}`,
      },
      {
        title: TERM_SECTION_TITLES?.OVERVIEW?.DESCRIPTION,
        value: data?.description?.definition?.value || "",
      },
      {
        title: TERM_SECTION_TITLES?.OVERVIEW?.TERM_TYPE,
        value: data?.description?.term_type?.value?.term_type_name || "",
      },
      {
        title: TERM_SECTION_TITLES?.OVERVIEW?.FORMULA,
        value: data?.description?.term_formula?.value || "",
        isRowVisibleInNormalView:
          data?.description?.term_type?.value?.term_type_id === "CMM",
      },
      {
        title: TERM_SECTION_TITLES?.OVERVIEW?.PREVIOUSLY_KNOWN_AS,
        value: data?.description?.previously_known_as?.value || "",
      },
      {
        title: TERM_SECTION_TITLES?.OVERVIEW?.REPLACES,
        additionalInfo: {
          replaces: data?.description?.replaces?.value?.map((item) => ({
            name: item?.trm_name || "",
            id: `${item?.term_id || ""}`,
          })),
        } as TermReplacesAdditionalIno,
        isRowVisibleInNormalView: !!data?.description?.replaces?.value?.length,
      },
      {
        title: TERM_SECTION_TITLES?.OVERVIEW?.REPLACED_BY,
        additionalInfo: {
          replaces: data?.description?.replaced_by?.value?.map((item) => ({
            name: item?.trm_name || "",
            id: `${item?.term_id || ""}`,
          })),
        } as TermReplacesAdditionalIno,
        isRowVisibleInNormalView: !!data?.description?.replaced_by?.value
          ?.length,
      },
    ],
    managed_by: [
      {
        title: TERM_SECTION_TITLES?.MANAGED_BY?.DATA_OWNERS,
        keyValueTags: data?.managed_by?.data_owners?.value?.map((item) => ({
          name:
            item?.user_type === "USR"
              ? `${item?.data_owner_name || ""} (${
                  item?.data_owner_email || ""
                })`
              : item?.data_owner_name,
          type: item?.user_type,
          email: item?.data_owner_email || "",
        })),
        isInherited: data?.managed_by?.data_owners?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_owners?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: TERM_SECTION_TITLES?.MANAGED_BY?.DATA_STEWARDS,
        keyValueTags: data?.managed_by?.data_stewards?.value?.map((item) => ({
          name:
            item?.user_type === "USR"
              ? `${item?.data_steward_name || ""} (${
                  item?.data_steward_email || ""
                })`
              : item?.data_steward_name,
          type: item?.user_type,
          email: item?.data_steward_email,
        })),
        isInherited: data?.managed_by?.data_stewards?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_stewards?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: TERM_SECTION_TITLES?.MANAGED_BY?.AUDIT_TRAIL,
        value: `${createdObj?.activity || ""} by ${
          createdObj?.user_name || ""
        } ${getTimeDifference(
          utcTOLocalTimeZone(createdObj?.activity_on || "")
        )}:${updatedObj?.activity || ""} by ${
          updatedObj?.user_name || ""
        } ${getTimeDifference(
          utcTOLocalTimeZone(updatedObj?.activity_on || "")
        )}`,
      },
    ],
    clasification: [
      {
        title: TERM_SECTION_TITLES?.CLASSIFICATION?.FUNCTIONAL_AREA,
        value: data?.domain_name || "",
      },
      ...classificationData,
    ],
    additionalInfo: [
      ...(data?.additional_info?.value || [])
        ?.filter((item) => item?.Link)
        ?.map((item, index) => ({
          actionId: "embed_link" as Actions,
          id: `embed_link-${index}`,
          value: item?.Link || "",
        })),
      ...(data?.additional_info?.value || [])
        ?.filter((item) => item?.Text)
        ?.map((item, index) => ({
          actionId: "edit" as Actions,
          id: `edit-${index}`,
          value: item?.Text || "",
        })),
    ],
    represents:
      data?.linked_columns?.linked_columns?.value?.map((item) => ({
        dbName: item?.src_name || "",
        dbId: `${item?.src_id || ""}`,
        tblName: item?.tbl_name || "",
        tblId: `${item?.tbl_id || ""}`,
        colName: item?.col_name || "",
        colId: `${item?.col_id || ""}`,
        isRefView: true,
        isTblSelection: false,
      })) || [],
    buiseness_rules: getBuisenessRulesForTerm(data),
    prominent_tags: {
      tags: getProminentTags(classificationData),
      isBoldView: true,
    },
  };
}

export function getParsedListOfTerm({
  data,
}: AxiosResponse<NodeParentalInfoResponse[]>): ListOfAllTermsReturnType {
  return data?.map((item) => ({
    rootParentName: item?.ROOT_PARENT_NAME || "",
    parentName: item?.PARENT_NAME || "",
    nodeName: item?.NODE_TITLE || "",
    nodeId: `${item?.NODE_ID || ""}`,
    parentId: item?.PARENT_ID || "",
    rootParentId: item?.ROOT_PARENT_ID || "",
    published_status: item?.PUBLISHED_STATUS || "",
    published_status_id: (item?.PUBLISHED_STATUS_ID ||
      "") as NodePublishedStatusIds,
    desc: item?.DESCRIPTION || "",
  }));
}

export function getParsedTermDocForDetailPage({
  data,
}: AxiosResponse<TermDocResponse>): ParsedTermDocForDetailPage {
  const createdObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TERM_SECTION_TITLES?.AUDIT_TRAIL?.CREATED
  );
  const updatedObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === TERM_SECTION_TITLES?.AUDIT_TRAIL?.UPDATED
  );
  return {
    is_deprecated: data?.published_status_id === "DEP",
    is_draft_exist: data?.is_draft_exists,
    is_editable: !!data?.is_editable,
    is_endorsed: data?.endorsed_status?.value,
    is_wf_enabled: data?.is_wf_enabled,
    is_predefined: data?.is_predefined,
    is_approver: data?.is_approver || false,
    category_name: data?.parent_name || "",
    domain_name: data?.domain_name || "",
    term_name: data?.term_name || "",
    endorsed_count: data?.endorsed_count || 0,
    trm_source_id: data?.trm_source_id || "",
    classification: {
      data: getClassificationCustomTags(data?.classifications || {}),
      isEdited: false,
      editable: false,
    },
    additional_info: {
      isEdited: false,
      isEditable: false,
      data: [
        ...(data?.additional_info?.value || [])
          ?.filter((item) => item?.Link)
          ?.map((item, index) => ({
            actionId: "embed_link" as Actions,
            id: `embed_link-${index}`,
            value: item?.Link || "",
          })),
        ...(data?.additional_info?.value || [])
          ?.filter((item) => item?.Text)
          ?.map((item, index) => ({
            actionId: "edit" as Actions,
            id: `edit-${index}`,
            value: item?.Text || "",
          })),
      ],
    },
    represents: {
      isEdited: false,
      isEditable: false,
      data: [
        {
          title: "",
          isEditable: true,
          sources:
            multiLevelSortArrayByKeys(
              data?.linked_columns?.linked_columns?.value,
              ["src_name", "tbl_name", "col_name"]
            )?.map((item) => ({
              dbName: item?.src_name || "",
              dbId: `${item?.src_id || ""}`,
              tblName: item?.tbl_name || "",
              tblId: `${item?.tbl_id || ""}`,
              colName: item?.col_name || "",
              colId: `${item?.col_id || ""}`,
              isRefView: false,
              isTblSelection: false,
            })) || [],
          apiKey: "get_node_parental_info_by_node_type",
          urlParam: ["COL", "TRM"],
          placeHolder: "Select one or more columns",
        },
      ],
    },
    managed_by: {
      isEdited: false,
      editable: false,
      data: [
        {
          title: TERM_SECTION_TITLES?.MANAGED_BY?.DATA_STEWARDS,
          keyValueTags: data?.managed_by?.data_stewards?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_steward_name || ""} (${
                      item?.data_steward_email || ""
                    })`
                  : item?.data_steward_name,
              type: item?.user_type,
              email: item?.data_steward_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_stewards?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_steward_name || ""} (${
                    item?.data_steward_email || ""
                  })`
                : item?.data_steward_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["true", "USR"],
          isInherited: data?.managed_by?.data_stewards?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_stewards?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
          isDataStewardField: true,
        },
        {
          title: TERM_SECTION_TITLES?.MANAGED_BY?.DATA_OWNERS,
          keyValueTags: data?.managed_by?.data_owners?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_owner_name || ""} (${
                      item?.data_owner_email || ""
                    })`
                  : item?.data_owner_name,
              type: item?.user_type,
              email: item?.data_owner_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_owners?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_owner_name || ""} (${
                    item?.data_owner_email || ""
                  })`
                : item?.data_owner_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.data_owners?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_owners?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
        },
        {
          title: TERM_SECTION_TITLES?.MANAGED_BY?.AUDIT_TRAIL,
          value: `${createdObj?.activity || ""} by ${
            createdObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(createdObj?.activity_on || "")
          )}:${updatedObj?.activity || ""} by ${
            updatedObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(updatedObj?.activity_on || "")
          )}`,
          isEditable: true,
        },
      ],
    },
    description: {
      data: [
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.TERM_NAME,
          value: data?.description?.term_name?.value || data?.node_name || "",
          isRowVisibleInNormalView: false,
          isEditable: true,
          type: "input",
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.REPLACED_TERMS_DEPRECATED,
          additionalInfo: {
            is_replace_terms_dep:
              data?.description?.is_replace_terms_dep?.value,
          } as TermDetailAllReplaceTrmDeprecatedAdditionalInfo,
          isRowVisibleInEditView: false,
          isRowVisibleInNormalView: false,
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.USABILITY_SCORE,
          value: "",
          isRowVisibleInEditView: false,
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.DESCRIPTION,
          value: data?.description?.definition?.value || "",
          isEditable: true,
          type: "textarea",
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.TITLE,
          value: data?.title?.value || "",
          isRowVisibleInNormalView: false,
          isEditable: true,
          type: "input",
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.TERM_CATEGORY,
          value: data?.description?.term_category?.value?.category_name || "",
          isRowVisibleInNormalView: false,
          isEditable: true,
          type: "select",
          selectedValues: [
            `${data?.description?.term_category?.value?.category_id || ""}`,
          ],
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.TERM_TYPE,
          value: data?.description?.term_type?.value?.term_type_name || "",
          isEditable: true,
          type: "select",
          selectedValues: [
            data?.description?.term_type?.value?.term_type_id || "",
          ],
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.FORMULA,
          value: data?.description?.term_formula?.value || "",
          isRowVisibleInNormalView:
            data?.description?.term_type?.value?.term_type_id === "CMM",
          isEditable: true,
          type: "input",
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.PREVIOUSLY_KNOWN_AS,
          value: data?.description?.previously_known_as?.value || "",
          isEditable: true,
          type: "input",
          isRowVisibleInNormalView: !!data?.description?.previously_known_as
            ?.value,
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.REPLACES,
          additionalInfo: {
            replaces: data?.description?.replaces?.value?.map((item) => ({
              name: item?.trm_name || "",
              id: `${item?.term_id || ""}`,
            })),
          } as TermReplacesAdditionalIno,
          isRowVisibleInNormalView: !!data?.description?.replaces?.value
            ?.length,
        },
        {
          title: TERM_SECTION_TITLES?.OVERVIEW?.REPLACED_BY,
          additionalInfo: {
            replaces: data?.description?.replaced_by?.value?.map((item) => ({
              name: item?.trm_name || "",
              id: `${item?.term_id || ""}`,
            })),
          } as TermReplacesAdditionalIno,
          isRowVisibleInNormalView: !!data?.description?.replaced_by?.value
            ?.length,
        },
      ],
      isEdited: false,
      editable: false,
    },
    business_rules: {
      "Categorical Values":
        data?.business_rules?.value?.["Categorical Values"] || [],
      "Column Names": data?.business_rules?.value?.["Column Names"] || [],
      "Column Sub-Type": data?.business_rules?.value?.["Column Sub-Type"] || [],
      Functions: data?.business_rules?.value?.Functions || "",
      Manual: data?.business_rules?.value?.Manual || "",
      Patterns: data?.business_rules?.value?.Patterns || [],
      "Primitive Data Type":
        data?.business_rules?.value?.["Primitive Data Type"] || [],
      "Regex Patterns": data?.business_rules?.value?.["Regex Patterns"] || [],
      "Value Range": data?.business_rules?.value?.["Value Range"] || {
        min: "",
        max: "",
      },
      isEditable: false,
      isEdited: false,
    },
    term_type: data?.description?.term_type?.value?.term_type_id || "BSM",
    publish_status_id: data?.published_status_id,
    publish_status: data?.published_status,
  };
}

export function getWorkflowTemplates({
  data,
}: AxiosResponse<WorkflowTemplateResponse[]>): WorkflowTemplateParsedType[] {
  return (
    data?.map((item) => ({
      value: item?.WT_ID || 0,
      label: item?.WT_NAME || "",
    })) || []
  );
}

const showStatusOnTheBasisOfActiveStep = (
  activeStep: WorkFlowStepResponse,
  currentItem: WorkFlowStepResponse
): boolean => {
  if (activeStep?.step_type === "CED") {
    if (currentItem?.step_type === "APR") return false;
  }
  return true;
};

export function getWorkflowSteps({
  data,
}: AxiosResponse<WorkflowResponse[]>): WorkflowStepsParsedType {
  const workflowDetails =
    data?.map((item) => {
      const workflowStepJsonResponse = jsonParse(item?.WF_STEPS || "") || [];

      const wfStepsArray =
        workflowStepJsonResponse?.map((item: WorkFlowStepResponse) => {
          const stepUsers = item?.step_users?.map(
            (item) =>
              ({
                userName: item?.user_name || "",
                userStatus: item?.user_status || "",
                userEmail: item?.user_email || "",
                userId: item?.user_id || 0,
                updatedOn: item?.updated_on || "",
                userAction: item?.user_action || "CRT",
              } || [])
          );

          const createdStep = workflowStepJsonResponse?.find(
            (workflowStep: WorkFlowStepResponse) =>
              workflowStep?.step_type === "CED"
          );

          const activeStep = workflowStepJsonResponse?.find(
            (workflowStep: WorkFlowStepResponse) =>
              workflowStep?.step_status === "INP"
          );

          const createdBy =
            createdStep?.step_users?.length > 1
              ? createdStep?.step_users?.find(
                  (stepUser: StepUser) => stepUser?.updated_on
                )
              : createdStep?.step_users?.[0];

          const isSubmittedForApproval = createdBy?.user_status === "CLS";

          return {
            stepDescription:
              item?.step_type === "CED"
                ? `${isSubmittedForApproval ? "Submitted by" : ""} ${
                    createdBy?.user_name
                  } ${
                    isSubmittedForApproval
                      ? getTimeDifference(
                          utcTOLocalTimeZone(createdBy?.updated_on || "")
                        )
                      : ""
                  }   ${
                    !isSubmittedForApproval
                      ? `(Awaiting ${
                          activeStep?.step_event === "SUB"
                            ? "submission"
                            : "resubmission"
                        })`
                      : ""
                  }`
                : item?.step_type === "APR"
                ? stepUsers
                : item?.step_desc || "",
            stepName: item?.step_name || "",
            stepNum: item?.step_num || 0,
            stepStatus: item?.step_status || "",
            stepType: item?.step_type || "",
            stepEvent: item?.step_event || "",
            stepUsers: stepUsers || [],
            showStepUserStatus: showStatusOnTheBasisOfActiveStep(
              activeStep,
              item
            ),
          };
        }) || [];

      const wfAssignedUsers =
        jsonParse(item?.WF_CURR_ASSIGNED_USERS)?.map(
          (item: string) => `${item}`
        ) || [];

      return {
        workflowAssignedUsers: wfAssignedUsers || [],
        workflowSteps: wfStepsArray || [],
        workflowStatus: item?.WF_STEPS_STATUS || "",
        workflowId: item?.WF_ID || 0,
        workflowTemplateId: item?.WT_ID || 0,
      };
    }) || [];

  return workflowDetails?.[0] || [];
}

const mapBusinessRulesValues = (
  values: BuisinessRulesValues["Categorical Values"] = []
): BuisinessRulesValues["Categorical Values"] =>
  values?.map((item) => ({
    desc: item?.desc || "",
    value: item?.value || "",
  }));

const listOfBuisenessRulesBasedOnType = (
  termType: TermType
): Array<keyof ReviewChangesParsedType[number]["businessRules"]> => {
  switch (termType) {
    case "CAA":
      return ["categories", "patterns", "manual"];
    case "BSM":
    case "CMM":
      return ["valueRange", "manual"];
    case "NCA":
      return ["patterns", "manual"];
    default:
      return ["manual"];
  }
};

export function getTermChangesForReview({
  data,
}: AxiosResponse<ReviewChangesResponse>): ReviewChangesParsedType {
  const definitionOldVal = data?.description?.old_value || "";
  const definitionNewVal = data?.description?.new_value || "";

  const nameOldVal = data?.term_name?.old_value || "";
  const nameNewVal = data?.term_name?.new_value || "";

  const termTitleOldValue = data?.title?.old_value || "";
  const termTileNewValue = data?.title?.new_value || "";

  const termTypeOldValue =
    data?.term_type?.old_value?.value?.term_type_name || "";

  const termTypeNewValue =
    data?.term_type?.new_value?.value?.term_type_name || "";

  const entityOldVal = data?.entity?.old_value || "";
  const entityNewVal = data?.entity?.new_value || "";

  const dataDomainOldVal = data?.data_domain?.old_value || "";
  const dataDomainNewVal = data?.data_domain?.new_value || "";

  const dataStewardsOldValue =
    data?.managed_by?.old_value?.data_stewards?.value?.map(
      (item) => item?.data_steward_name || ""
    ) || [];

  const dataStewardsNewValue =
    data?.managed_by?.new_value?.data_stewards?.value?.map(
      (item) => item?.data_steward_name || ""
    ) || [];

  const dataOwnersOldValue =
    data?.managed_by?.old_value?.data_owners?.value?.map(
      (item) => item?.data_owner_name || ""
    ) || [];

  const dataOwnersNewValue =
    data?.managed_by?.new_value?.data_owners?.value?.map(
      (item) => item?.data_owner_name || ""
    ) || [];

  const systemOwnersOldValue =
    data?.managed_by?.old_value?.system_owners?.value?.map(
      (item) => item?.system_owner_name || ""
    ) || [];

  const systemOwnersNewValue =
    data?.managed_by?.new_value?.system_owners?.value?.map(
      (item) => item?.system_owner_name || ""
    ) || [];

  const linkedColOldVal =
    sortObjectsArrayByKeyDescOrder(
      data?.linked_columns?.old_value?.value,
      "col_id"
    )?.map((item) => {
      return {
        dbName: item?.src_name || "",
        dbId: item?.src_id || "",
        tblName: item?.tbl_name || "",
        tblId: item?.tbl_id || "",
        colName: item?.col_name || "",
        colId: item?.col_id || "",
        isRefView: false,
        isTblSelection: false,
      };
    }) || [];

  const linkedColNewVal =
    sortObjectsArrayByKeyDescOrder(
      data?.linked_columns?.new_value?.value,
      "col_id"
    )?.map((item) => {
      return {
        dbName: item?.src_name || "",
        dbId: item?.src_id || "",
        tblName: item?.tbl_name || "",
        tblId: item?.tbl_id || "",
        colName: item?.col_name || "",
        colId: item?.col_id || "",
        isRefView: false,
        isTblSelection: false,
      };
    }) || [];

  const provenanceNewVal =
    data?.provenance_scan?.new_value?.map((item) => ({
      dbName: item?.parent_name || "",
      dbId: item?.parent_id || "",
      tblName: item?.node_name || "",
      tblId: `${item?.node_id || ""}`,
      isRefView: false,
      isTblSelection: false,
    })) || [];

  const provenanceOldVal =
    data?.provenance_scan?.old_value?.map((item) => ({
      dbName: item?.parent_name || "",
      dbId: item?.parent_id || "",
      tblName: item?.node_name || "",
      tblId: `${item?.node_id || ""}`,
      isRefView: false,
      isTblSelection: false,
    })) || [];

  const additionalInfoTextOldVal =
    data?.additional_info?.old_value
      ?.filter((item) => item?.Text)
      ?.map((nestItem) => nestItem?.Text || "") || [];

  const additionalInfoTextNewVal =
    data?.additional_info?.new_value
      ?.filter((item) => item?.Text)
      ?.map((nestItem) => nestItem?.Text || "") || [];

  const additionalInfoLinkOldVal =
    data?.additional_info?.old_value
      ?.filter((item) => item?.Link)
      ?.map((nestItem) => nestItem?.Link || "") || [];

  const additionalInfoLinkNewVal =
    data?.additional_info?.new_value
      ?.filter((item) => item?.Link)
      ?.map((nestItem) => nestItem?.Link || "") || [];

  const oldCategoriesValues = mapBusinessRulesValues(
    data?.business_rules?.old_value?.["Categorical Values"] || []
  );

  const newCategoriesValues = mapBusinessRulesValues(
    data?.business_rules?.new_value?.["Categorical Values"] || []
  );

  const oldPatternsValues = mapBusinessRulesValues(
    data?.business_rules?.old_value?.Patterns || []
  );

  const newPatternsValues = mapBusinessRulesValues(
    data?.business_rules?.new_value?.Patterns || []
  );

  const oldManualValue = data?.business_rules?.old_value?.Manual || "";
  const newManualValue = data?.business_rules?.new_value?.Manual || "";

  const oldValueRangeValue =
    data?.business_rules?.old_value?.["Value Range"] || {};
  const newValueRangeValue =
    data?.business_rules?.new_value?.["Value Range"] || {};

  const oldCombineValRangeVal = `${oldValueRangeValue?.min || ""}-${
    oldValueRangeValue?.max || ""
  }`;

  const newCombineValRangeVal = `${newValueRangeValue?.min || ""}-${
    newValueRangeValue?.max || ""
  }`;

  const rulesBasedOnOldTermType = listOfBuisenessRulesBasedOnType(
    data?.term_type?.old_value?.value?.term_type_id
  );
  const rulesBasedOnNewTermType = listOfBuisenessRulesBasedOnType(
    data?.term_type?.new_value?.value?.term_type_id
  );

  const uniqueRules = new Set([
    ...rulesBasedOnOldTermType,
    ...rulesBasedOnNewTermType,
  ]);

  const definitionChanges = {
    oldValue: definitionOldVal,
    newValue: definitionNewVal,
    isChanged: !isEqual(definitionOldVal, definitionNewVal),
  };

  const nameChanges = {
    oldValue: nameOldVal,
    newValue: nameNewVal,
    isChanged: !isEqual(nameOldVal, nameNewVal),
  };
  const titleChanges = {
    oldValue: termTitleOldValue,
    newValue: termTileNewValue,
    isChanged: !isEqual(termTitleOldValue, termTileNewValue),
  };

  const termChanges = {
    oldValue: termTypeOldValue,
    newValue: termTypeNewValue,
    isChanged: !isEqual(termTypeOldValue, termTypeNewValue),
  };

  const managedByChanges = {
    dataStewards: {
      oldValue: dataStewardsOldValue,
      newValue: dataStewardsNewValue,
      isChanged: !isEqual(dataStewardsOldValue, dataStewardsNewValue),
    },
    dataOwners: {
      oldValue: dataOwnersOldValue,
      newValue: dataOwnersNewValue,
      isChanged: !isEqual(dataOwnersOldValue, dataOwnersNewValue),
    },
    systemOwners: {
      oldValue: systemOwnersOldValue,
      newValue: systemOwnersNewValue,
      isChanged: !isEqual(systemOwnersOldValue, systemOwnersNewValue),
    },
  };

  const oldValue = data?.classifications?.old_value || [];
  const newValue = data?.classifications?.new_value || [];

  const classificationChanges: ClassificationReturnType[] = Object.values(
    [...oldValue, ...newValue].reduce(
      (current: CurrentObj, obj: CustomDataSetTag) => {
        if (!current?.[obj?.tagset_id]) {
          current[obj?.tagset_id] = {
            ...obj,
            newValue: obj?.value,
            oldValue: current?.[obj?.tagset_id]?.value,
          };
        } else {
          current[obj?.tagset_id] = {
            ...current[obj?.tagset_id],
            newValue: obj?.value,
            ...obj,
            oldValue: current?.[obj?.tagset_id]?.value,
          };
        }
        return current;
      },
      {}
    ) || []
  )?.map((item) => ({
    ...item,
    is_changed: !isEqual(
      item?.oldValue?.map((oldTag) => ({
        tag_desc: oldTag?.tag_desc || "",
        tag_id: oldTag?.tag_id || "",
      })),
      item?.newValue?.map((newTag) => ({
        tag_desc: newTag?.tag_desc || "",
        tag_id: newTag?.tag_id || "",
      }))
    ),
  }));

  const sourcesChanges = {
    isChanged: !isEqual(
      linkedColOldVal?.map((oldVal) => oldVal?.colName),
      linkedColNewVal?.map((newVal) => newVal?.colName)
    ),
    oldValue: linkedColOldVal,
    newValue: linkedColNewVal,
  };

  const provenanceChanges = {
    isChanged: !isEqual(
      provenanceNewVal?.map((oldVal) => oldVal?.tblName),
      provenanceOldVal?.map((newVal) => newVal?.tblName)
    ),
    oldValue: provenanceOldVal,
    newValue: provenanceNewVal,
  };

  const additionalInfoTextChanges = {
    oldValue: additionalInfoTextOldVal,
    newValue: additionalInfoTextNewVal,
    isChanged: !isEqual(additionalInfoTextOldVal, additionalInfoTextNewVal),
  };

  const additionalInfoLinkChanges = {
    oldValue: additionalInfoLinkOldVal,
    newValue: additionalInfoLinkNewVal,
    isChanged: !isEqual(additionalInfoLinkOldVal, additionalInfoLinkNewVal),
  };

  const entityChanges = {
    oldValue: entityOldVal,
    newValue: entityNewVal,
    isChanged: !isEqual(entityNewVal, entityOldVal),
  };

  const dataDomainChanges = {
    oldValue: dataDomainOldVal,
    newValue: dataDomainNewVal,
    isChanged: !isEqual(dataDomainNewVal, dataDomainOldVal),
  };

  return [
    {
      definition: definitionChanges,
      name: nameChanges,
      term: termChanges,
      title: titleChanges,
      dataStewards: managedByChanges?.dataStewards,
      dataOwners: managedByChanges?.dataOwners,
      systemOwners: managedByChanges?.systemOwners,
      sources: sourcesChanges,
      provenance: provenanceChanges,
      classification: classificationChanges,
      additionalInfoLink: additionalInfoLinkChanges,
      additionalInfoText: additionalInfoTextChanges,
      businessRules: {
        categories: {
          oldValue: oldCategoriesValues,
          newValue: newCategoriesValues,
          isChanged: !isEqual(oldCategoriesValues, newCategoriesValues),
          hide: !uniqueRules?.has("categories"),
        },
        patterns: {
          oldValue: oldPatternsValues,
          newValue: newPatternsValues,
          isChanged: !isEqual(oldPatternsValues, newPatternsValues),
          hide: !uniqueRules?.has("patterns"),
        },
        manual: {
          oldValue: oldManualValue,
          newValue: newManualValue,
          isChanged: !isEqual(oldManualValue, newManualValue),
          hide: !uniqueRules?.has("manual"),
        },
        valueRange: {
          oldValue: oldCombineValRangeVal,
          newValue: newCombineValRangeVal,
          isChanged: !isEqual(oldCombineValRangeVal, newCombineValRangeVal),
          hide: !uniqueRules?.has("valueRange"),
        },
      },
      entity: entityChanges,
      dataDomain: dataDomainChanges,
    },
  ];
}

const getActionTitle = (type: EventType): string => {
  const actionTitle: { [key in EventType]?: string } = {
    APR: "Approved",
    SMT: "Submitted For Approval",
    REJ: "Rejected",
    CMT: "Commented",
    UPD: "Edited",
    CRT: "Created",
    APB: "Published",
    PUB: "Published",
    RSL: "Resolved",
    ACK: "Acknowledged",
    EXE: "Executed",
  };

  return actionTitle[type] || "Created";
};

export function getUserActivities({
  data,
}: AxiosResponse<UserActivitiesArrayResponse>): ActivitiesParsedDataType {
  const activitiesData = data?.results || [];
  const lastItem = data?.last_item;

  const hasMoreRecords = !!lastItem?.NODE_SK;

  const sortedActivities = sortListOnSpecificKeyValue({
    list: activitiesData,
    key: "CREATED_ON",
    type: "date",
    sortAscending: false,
  });

  const parsedData =
    sortedActivities?.map((item) => {
      return {
        action_title: getActionTitle(item?.EVENT_TYPE) || "",
        action_desc:
          item?.EVENT_TYPE === "CMT"
            ? `"${item?.NODE_DESC}"`
            : item?.NODE_DESC || "",
        performed_on: item?.CREATED_ON || "",
        action_type: item?.EVENT_TYPE || "CRT",
        node_alert_status: item?.NODE_ALERT_STATUS ? "ALE" : undefined,
        performed_by:
          item?.EVENT_TYPE === "APB"
            ? "DvSum Automation"
            : item?.USER_NAME || "",
        show_description:
          item?.EVENT_TYPE === "SMT" ||
          item?.EVENT_TYPE === "CMT" ||
          item?.EVENT_TYPE === "NEW",
      };
    }) || [];

  const groupedByDate =
    parsedData?.reduce(
      (acc: ActivitiesGroupByDateAccumalatorType, obj: UserActivityType) => {
        const createdOnDate = obj?.performed_on || "";
        const parsedCreatedOn = utcTOLocalTimeZone(
          createdOnDate,
          YEAR_MONTH_DATE
        );
        const parsedCreatedOnData = parsedCreatedOn?.split(" ")?.[0];

        if (!acc[parsedCreatedOnData]) {
          acc[parsedCreatedOnData] = [];
        }
        acc[parsedCreatedOnData].push(obj);
        return acc;
      },
      {}
    ) || [];

  return {
    activities: groupedByDate,
    hasMoreRecords,
  };
}
