import styled from "styled-components";

export const QueryBlockProfilingStyled = styled.div<{ isChatPage?: boolean }>`
  margin-left: ${({ isChatPage }): string => (isChatPage ? "0" : "25.6px")};
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  border-left: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  border-right: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  border-bottom: 1px solid
    ${({ theme }): string => theme?.borderColors?.GREY_56};
  max-height: ${({ isChatPage }): string => (isChatPage ? "700px" : "645px")};
  height: 100%;
`;
