//   <--Library Imports Start-->
import { Modal } from "antd";
import styled from "styled-components";
//   <--Library Imports End-->

export const ModalStyled = styled(Modal)<{
  width?: string;
  isFullScreenModal?: boolean;
  headerHeight?: string;
  isHideHeader?: boolean;
  isDialogBox?: boolean;
}>`
  @media (max-width: 767px) {
    &.ant-modal {
      margin: 0px;
    }
  }

  .ant-modal-content {
    .ant-modal-close {
      pointer-events: none;
      display: ${(props): any => props.isHideHeader && "none"};
      .ant-modal-close-x {
        width: ${(props): string => (props.isDialogBox ? "34px" : "62px")};
        height: ${(props): any => props.headerHeight};
        display: flex;
        align-items: center;
        svg {
          pointer-events: auto;
          path {
            fill: ${({ theme }): string => theme.bgColors.RED_100};
            opacity: 0.5;
          }

          :hover {
            path {
              opacity: unset;
            }
          }
        }
      }
    }

    .ant-modal-header {
      padding: ${(props): string =>
        props.isDialogBox ? "20.8px" : "17px 23px 17px 20px"};
      background: #f1f1f1;
      height: ${(props): any => props.headerHeight};
      display: flex;
      align-items: center;
      display: ${(props): any => props.isHideHeader && "none"};
      .ant-modal-title {
        color: #428bca;
        font-size: 25px;
      }
    }

    .ant-modal-body {
      padding: 0px;
    }

    .ant-modal-footer {
      display: none;
    }
  }

  ${(props): any =>
    props.isFullScreenModal &&
    `
    top: 0 !important;
    max-width: ${props.width && props.width};
    padding: 0px;
    .ant-modal-content {
      margin: 0;
      height:100vh;
    };
  `}
`;

export const ModalFullScreenTitleStyled = styled.div`
  font-size: 25px;
  color: ${({ theme }): string => theme.textColors.BLUE_105};
  padding-top: 4px;
`;

export const AddUserGroupTitleStyled = styled.div`
  font-size: 25px;
  color: ${({ theme }): string => theme.textColors.BLUE_105};
  padding-top: 4px;
`;

export const MediumModalTitleStyled = styled.div`
  font-size: 25px;
  color: ${({ theme }): string => theme.textColors.BLUE_116};
`;
