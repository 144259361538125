import { Input } from "antd";
import styled from "styled-components";

export const TextAreaFieldStyled = styled(Input.TextArea)<{
  width?: string;
  height?: string;
}>`
  padding: 8px 12px;
  width: ${(props): string => props?.width || "100%"};
  height: ${(props): string => props?.height || "28px"} !important;
  border-radius: 0px;
  resize: none;
  color: ${(props): string => props.theme.textColors.BLACK_COLOR};
  font-size: 13px;
  :hover {
    border-color: ${(props): string => props.theme.borderColors.DARK_GREY_15};
    box-shadow: none;
  }

  :focus {
    border-color: ${(props): string => props.theme.borderColors.YELLOW_RD};
    box-shadow: none;
  }

  &:disabled,
  &.ant-input-disabled {
    color: ${({ theme }): string => theme.textColors.DARK_GREY_100};
  }

  &::placeholder {
    white-space: pre-line;
  }
`;

export const InputFieldContainerStyled = styled.div<{
  isMessagePositionAbsolute?: boolean;
}>`
  ${(props): any =>
    props.isMessagePositionAbsolute &&
    `
    position:relative;
    `}

  .error {
    text-align: right;
    color: red;

    ${(props): any =>
      props.isMessagePositionAbsolute &&
      `
    position:absolute;
    right:0;
    `}
  }
`;
