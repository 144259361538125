import styled from "styled-components";

export const ExploreSecStyled = styled.div`
  .explore-btns-sec {
    display: flex;
    flex-direction: column;
    row-gap: 9.6px;

    .explore-btn {
      background: ${({ theme }): string => theme.bgColors.DARK_BLUE_1};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-radius: 4px;
      cursor: pointer;
      font-family: OpenSansSemiBold;

      &.recent-update-btn {
        background: ${({ theme }): string => theme.bgColors.ORANGE_3};
      }

      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
      }
    }
  }
`;
