import { TagPrivacyType } from "../parsers/tagsets/tagsetsparser.types";

export const tagPrivacyMapping = (privacy: TagPrivacyType): string => {
  const tagPrivacyOptions: {
    [key in TagPrivacyType]: string;
  } = {
    NON: "None",
    MSK: "Masked",
    NOA: "No Access",
  };

  return tagPrivacyOptions[privacy];
};
