import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useGetAppState } from ".";
import { setModal, setSecondaryModal } from "../reducers/appreducer/appreducer";

export function useCancelSecondaryModal(): () => void {
  const {
    modal: { modalId, modalProps, modalTitle },
  } = useGetAppState();
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setSecondaryModal({ modalId, modalProps, modalTitle }));
  }, [modalId]);
}
