import { useMemo } from "react";

import { TrainBotFeedbackFormProps } from "../../trainbotfeedbackform.types";

import { TrainbotFeedBackFormInputResponseStyled } from "./trainbotfeedbackforminputsec.styles";
import { getNameInitials, getPostLoginData } from "../../../../../../utils";
import UserNameInitials from "../../../../../../components/usernameinitials";
import Flex from "../../../../../../components/flex";

const TrainBotFeedbackFormInputSec = (
  props: TrainBotFeedbackFormProps
): JSX.Element => {
  const { queryItem } = props;

  const { user_info: userInfo } = getPostLoginData();

  const {
    qry_created_by_first_name: qryCreatedByFirstName = "",
    qry_created_by_last_name: qryCreatedByLastName = "",
    qry_created_by: qryCreatedBy = "",
    header,
  } = queryItem || {};

  const { title = "" } = header || {};

  const { user_first_name: firstName = "", user_last_name: lastName = "" } =
    userInfo || {};

  const memoizedNameInitials = useMemo(
    () =>
      getNameInitials(
        qryCreatedByFirstName || firstName,
        qryCreatedByLastName || lastName
      ),
    [firstName, lastName, qryCreatedByFirstName, qryCreatedByLastName]
  );

  return (
    <TrainbotFeedBackFormInputResponseStyled>
      <div>
        <div className="train-bot-feedback-form-input-res-title">
          Asked Input
        </div>
        <Flex>
          <UserNameInitials
            name={memoizedNameInitials}
            userName={qryCreatedBy}
          />
          <div className="train-bot-feedback-form-input-ques">{title}</div>
        </Flex>
      </div>
    </TrainbotFeedBackFormInputResponseStyled>
  );
};

export default TrainBotFeedbackFormInputSec;
