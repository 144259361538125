import styled from "styled-components";
import { THEME_COLORS } from "../themeprovider/themeprovider.constant";

import {
  CoreNodesCircleIconMode,
  CoreNodesCircleIconProps,
} from "./corenodetypescircleicon.types";

const colorsMapping = (
  theme: typeof THEME_COLORS["light"],
  mode: CoreNodesCircleIconMode = "primary"
): { bgColor: string; color: string } => {
  const config: {
    [key in CoreNodesCircleIconMode]: {
      bgColor: string;
      color: string;
    };
  } = {
    primary: {
      bgColor: theme?.bgColors?.BLUE_56,
      color: theme?.textColors?.WHITE_COLOR,
    },
    secondary: {
      bgColor: theme?.bgColors?.BLACK_70,
      color: theme?.textColors?.WHITE_COLOR,
    },
    tertiary: {
      bgColor: theme?.bgColors?.BLUE_10,
      color: theme?.textColors?.GREY_33,
    },
    quaternary: {
      bgColor: theme?.bgColors?.LIGHT_GREY_22,
      color: theme?.textColors?.GREY_210,
    },
  };

  return config?.[mode];
};

export const CoreNodesCircleIconStyled = styled.div<CoreNodesCircleIconProps>`
  position: relative;
  border-radius: 50%;
  width: ${({ width }): string => width || "25px"};
  height: ${({ height }): string => height || "25px"};
  min-width: ${({ width }): string => width || "25px"};
  min-height: ${({ height }): string => height || "25px"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }): string => fontSize || "14px"};
  background: ${({ mode, theme }): string =>
    colorsMapping(theme, mode)?.bgColor};
  color: ${({ mode, theme }): string => colorsMapping(theme, mode)?.color};
  margin-right: ${({ marginRight }): string => marginRight || ""};
  font-family: OpenSansBold;
`;

export const LetterIconMapping = styled.div<{
  isSvg?: boolean;
  isSelected?: boolean;
}>`
  position: absolute;
  top: ${({ isSvg }): string => (isSvg ? "62%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    path {
      fill: ${({ theme, isSelected }): string => {
        return isSelected ? `${theme?.bgColors?.WHITE_COLOR}` : ``;
      }} !important;
    }
  }
`;
