import styled from "styled-components";

export const RuleTypeDetailsRuleDefinitionSecStyled = styled.div`
  width: 90%;

  .sec-row {
    .main-row {
      margin-bottom: 16px;
      .row-title {
        width: 160px;
      }

      .row-value {
        display: flex;
        align-items: center;
      }

      .tags-list {
        padding: 0px;
      }
    }
  }

  .content-header {
    .title {
      color: ${({ theme }): string => theme.textColors.DARK_BLUE};

      .left-icon {
        cursor: default;
      }
    }
  }

  .left-icon-tooltip {
    max-width: 600px;

    .ant-tooltip-content {
      max-width: 600px;

      .ant-tooltip-inner {
        max-width: 600px;
      }
    }
  }
`;
