import { useCallback } from "react";
import { useHistory } from "react-router";

export function useOpenLinkInNewTab(): (_event: any, _link: string) => void {
  const history = useHistory();
  return useCallback((e, link) => {
    e?.preventDefault();
    history.push(link);
  }, []);
}
