import { Actions } from "../../components/additionalinfo/additionalinfo.types";

import { SectionWithEditableAdditionalinfoProps } from "../../components/sectionwitheditables/sectionwitheditableadditionalinfo/sectionwitheditableadditionalinfo.types";

import { NodeDocCommonResponse } from "../parser.types";

export function parseAdditionalInfoData(
  data: NodeDocCommonResponse["additional_info"]
): SectionWithEditableAdditionalinfoProps["state"]["data"] {
  const values = data?.value || [];
  return [
    ...values
      ?.filter((item) => item?.Link)
      ?.map((item, index) => ({
        actionId: "embed_link" as Actions,
        id: `embed_link-${index}`,
        value: item?.Link || "",
      })),
    ...values
      ?.filter((item) => item?.Text)
      ?.map((item, index) => ({
        actionId: "edit" as Actions,
        id: `edit-${index}`,
        value: item?.Text || "",
      })),
  ];
}
