import styled from "styled-components";

export const ProfilingCountProgressbarStyled = styled.div`
  font-size: 12.8px;
`;

export const ProfilingCountProgressbarTooltipStyled = styled.div`
  .type {
    display: flex;
    .label {
      flex: 1;
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      margin-right: 15px;
    }
    .blank-count {
      color: ${({ theme }): string => theme.textColors.RED_100};
      font-family: OpenSansSemiBold;
    }
    .unique-count {
      color: ${({ theme }): string => theme.textColors.GREEN_COLOR};
      font-family: OpenSansSemiBold;
    }
    .complete-count {
      color: ${({ theme }): string => theme.textColors.LIGHT_BLUE_28};
      font-family: OpenSansSemiBold;
    }
    .total-count {
      font-family: OpenSansSemiBold;
    }
  }
`;
