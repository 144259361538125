import styled from "styled-components";
import LinkButton from "../../../linkbutton/linkbutton";

export const SingleSelectionPopupContentStyled = styled.div<{ width: string }>`
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  border: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  width: max-content;
  min-width: ${({ width }): string => width};
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 10%);

  .opt-separator {
    margin: 7px 10px;
    border: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  }
`;

export const SingleSelectionPopupItemStyled = styled(LinkButton)<{
  isSelected: boolean;
}>`
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  color: ${({ theme, isSelected }): string =>
    isSelected ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_40};

  .status-filter-item-icon {
    display: flex;
    min-width: 20px;
  }

  :hover {
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_25};

    .status-filter-item-icon.stroke {
      svg {
        path {
          stroke: ${({ theme }): string => theme?.bgColors?.BLUE_116};
        }
      }
    }

    .status-filter-item-icon.circle {
      svg {
        path {
          fill: ${({ theme }): string => theme?.bgColors?.BLUE_116};
        }

        circle {
          stroke: ${({ theme }): string => theme?.bgColors?.BLUE_116};
        }
      }
    }

    .status-filter-item-icon.fill {
      svg {
        path {
          fill: ${({ theme }): string => theme?.bgColors?.BLUE_116};
        }
      }
    }
  }

  .count {
    color: ${({ theme }): string => theme?.textColors?.GREY_210};
  }

  .status-filter-item-icon.stroke {
    svg {
      path {
        stroke: ${({ theme, isSelected }): string =>
          isSelected ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.GREY_40};
      }
    }
  }

  .status-filter-item-icon.fill {
    svg {
      path {
        fill: ${({ theme, isSelected }): string =>
          isSelected ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.GREY_40};
      }
    }
  }

  .status-filter-item-icon.circle {
    svg {
      path {
        fill: ${({ theme, isSelected }): string =>
          isSelected ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.GREY_40};
      }

      circle {
        stroke: ${({ theme, isSelected }): string =>
          isSelected ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.GREY_40};
      }
    }
  }
`;
