import { Spin } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCallback, useMemo, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { SelectField } from "../../../../components/formfields";

import {
  DividerStyled,
  ErdEdgeEditWrapperStyled,
  FieldsAndLabelStyled,
  FieldsWrapperStyled,
  FormButtonsWrapper,
  InputFieldWithLabelStyled,
  InputLabel,
  LoadingSpinerStyled,
} from "./erdedgeeditform.styles";

import {
  AddEdgeFormType,
  ApiActionType,
  ErdEdgeApiDataType,
  ErdEdgeEditFormProps,
  InputFieldWithLabelProps,
} from "./erdedgeeditform.types";

import { VerticalDividerStyled } from "../../../../components/dividers/dividers.styles";
import LinkButton from "../../../../components/linkbutton/linkbutton";

import { defaultSourceTargetFields } from "./erdedgeeditform.config";

import {
  lightCrossIcon,
  noAlertExistsIcon,
  plusIconInFilledCircle,
} from "../../../../svgs";

import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import { erdEdgeFormSchema } from "../../../../utils/schemas/edredgeformschema";

import { ToggleButton } from "../../../../components/togglebutton/togglebutton";

import {
  getDataTypeIcon,
  openNotification,
  selectFilterOption,
} from "../../../../utils";

import { useRequestWithMethod } from "../../../../api";
import StateHandler from "../../../../components/statehandler/statehandler";

import { Button } from "../../../../components";
import { useGetAppType, useSetData } from "../../../../customhooks";

import { API_CONFIG } from "../../../../constants/apiconfig";

import {
  CardinalityTextType,
  RelationshipSourceIDType,
  RelationshipStatusIDType,
} from "../../../../parsers/erddiagramparser/erddiagramparser.types";

import {
  RelationshipTablesDataResponse,
  ReturnTypeOfRelationshipColumnData,
} from "../../../../parsers/tablepage/tablepageparser.types";

import Alert from "../../../../components/alert/alert";
import {
  erdGridFieldsData,
  getEdgeInfo,
} from "../../../../parsers/tablepage/tablepageparser.utils";

const RELATIONSHIP_STATUS_OPTIONS = ["1:1", "N:1", "N:N"];

const InputFieldWithLabel = (props: InputFieldWithLabelProps): JSX.Element => {
  const { label = "", children } = props;
  return (
    <InputFieldWithLabelStyled>
      <InputLabel>{label}</InputLabel>
      <div className="input-field">{children}</div>
    </InputFieldWithLabelStyled>
  );
};

const warningIcon = noAlertExistsIcon("12", "12");

const ErdEdgeEditForm = (props: ErdEdgeEditFormProps): JSX.Element => {
  const onSetData = useSetData();

  const [errorMsg, setErrorMsg] = useState("");
  const [srcTargetDataTypeDiffError, setSrcTargetDataTypeDiffError] = useState(
    ""
  );

  const { isExtOrDesktop } = useGetAppType();

  const {
    isEditMode = false,
    handleCancelButtonClick,
    formData,
    refrenceTablesList,
    tableId,
    srcRefColumnData,
    diagramLevel = "1",
    isDataFetching,
    updateEdge,
    linkedRefTableIDs,
    onChangeShouldKeepPrevPos,
    onRelationshipActionSuccess,
    edgesInfo = [],
  } = props;

  const {
    sourceTableId = "",
    referenceTableId = "",
    relationship = "1:N",
    fieldsData = [],
    edgeName = "",
    edgeUniqueId,
  } = formData || {};

  const {
    isLoading: refernceTableLoading,
    error: refernceTableError,
    data: refernceTableData,
    onExecuteRequest: onGetReferenceTablesList,
  } = useRequestWithMethod("get_relationship_columns_data", undefined, true);

  const onErdEdgeSaveSuccess = useCallback(
    (res) => {
      const isError = res?.data?.error_msg || "";

      if (isError) {
        setErrorMsg("Relationship Already exists");
      } else {
        const updatedErdData: RelationshipTablesDataResponse = res?.data;
        handleCancelButtonClick();

        const savedEdgeTableId = `${
          updatedErdData?.data?.find((item) => item?.CONS_UNQ_ID)?.TBL_ID || 0
        }`;

        const currentEdgeUpdatedData = updatedErdData?.data?.filter(
          (item) => Number(item?.CONS_UNQ_ID) === Number(edgeUniqueId)
        );

        // first we are getting all fields for that specific edge
        const edgesFieldData = erdGridFieldsData(currentEdgeUpdatedData);

        // now setting the data back to parsed edge (data req in refs)
        const edgeInfo = currentEdgeUpdatedData?.[0];
        const edgeParsedData = getEdgeInfo(
          edgeInfo,
          edgesFieldData?.[edgeUniqueId]?.fieldsData
        );
        updateEdge?.(edgeParsedData);

        isEditMode && onChangeShouldKeepPrevPos?.();
        onSetData(API_CONFIG.get_data_model_with_levels, updatedErdData, [
          tableId,
          `${diagramLevel}`,
        ]);

        openNotification(
          <SuccessNotificationMessage
            type="success"
            message={
              isEditMode
                ? "Relationship has been updated"
                : "New relationship has been added"
            }
            showSuccess
          />,
          0,
          {
            top: isExtOrDesktop ? 0 : 60,
            className: "desktop-app-center-notification",
            getContainer: (): any => {
              const elements = document?.getElementsByClassName(
                "ant-drawer-content"
              );
              return (elements?.length && elements?.[0]) || document.body;
            },
          }
        );

        onRelationshipActionSuccess(
          `${updatedErdData?.cons_unq_id || 0}`,
          savedEdgeTableId,
          isEditMode ? "edited" : "added"
        );
      }
    },
    [
      tableId,
      edgeUniqueId,
      isEditMode,
      handleCancelButtonClick,
      diagramLevel,
      onRelationshipActionSuccess,
    ]
  );

  const {
    isLoading: erdEdgeSaveLoading,
    error: erdEdgeSaveError,
    onExecuteRequest,
  } = useRequestWithMethod("save_erd_edge", [tableId, diagramLevel], undefined);

  const addEdgeForm = useForm<AddEdgeFormType>({
    defaultValues: isDataFetching
      ? {}
      : {
          source_table: sourceTableId,
          target_table: referenceTableId,
          specify_fields: isEditMode ? [] : [defaultSourceTargetFields],
          relationship_status: relationship,
        },
    resolver: yupResolver(erdEdgeFormSchema),
    mode: "onChange",
  });

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = addEdgeForm;

  const { append, remove, fields } = useFieldArray<AddEdgeFormType>({
    control,
    name: "specify_fields",
  });

  const removeField = useCallback(
    (index: number) => (): void => {
      remove(index);
    },
    [remove, reset]
  );

  const watchedFields = watch();

  const {
    relationship_status: relationshipStatus,
    target_table: targetTable,
    source_table: sourceTable,
    specify_fields: speciyFields,
  } = watchedFields;

  const referenceTableSelectOptions = useMemo(() => {
    return (
      refrenceTablesList
        ?.map((item) => {
          const shouldReferenceTableDisabled =
            Number(item?.table_id) === Number(sourceTable);

          return {
            label: item?.table_name || "",
            value: `${item?.table_id}` || "",
            disabled: shouldReferenceTableDisabled,
          };
        })
        ?.sort((a, b) =>
          a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase())
        ) || []
    );
  }, [refrenceTablesList, linkedRefTableIDs, sourceTable]);

  const allSrcFieldsSelected = useMemo(
    () =>
      edgesInfo
        ?.filter(
          (edgeInfo) =>
            edgeInfo?.sourceTable?.table_id === +sourceTable &&
            edgeInfo?.referenceTable?.table_id === +targetTable
        )
        ?.map((edgeInfo) => {
          return edgeInfo?.fields?.map((field) => field?.source_field_id);
        })
        ?.flat(),
    [edgesInfo, sourceTable, targetTable]
  );

  const sourceTableFields = useMemo(() => {
    return srcRefColumnData?.filter(
      (item) => Number(item?.table_id) === Number(sourceTable)
    );
  }, [srcRefColumnData, sourceTable]);

  const sourceFieldSelectOptions = useMemo(() => {
    return (
      sourceTableFields?.map((fieldItem) => {
        const isMappedSourceField =
          watchedFields?.specify_fields?.find(
            (item) =>
              Number(item?.source_field) === Number(fieldItem?.column_id)
          ) || allSrcFieldsSelected?.includes(Number(fieldItem?.column_id));

        return {
          label: (
            <>
              <span className="name">{fieldItem?.column_title || ""}</span>
              {getDataTypeIcon(fieldItem?.column_type)}
            </>
          ),
          value: `${fieldItem?.column_id}` || "",
          disabled: !!isMappedSourceField,
          textForSearch: fieldItem?.column_title || "",
        };
      }) || []
    )?.sort((a, b) =>
      a?.textForSearch
        ?.toLowerCase()
        ?.localeCompare(b?.textForSearch?.toLowerCase())
    );
  }, [watchedFields, sourceTableFields, allSrcFieldsSelected]);

  const targetTableFields = useMemo(() => {
    return srcRefColumnData?.filter(
      (item) => Number(item?.table_id) === Number(targetTable)
    );
  }, [srcRefColumnData, targetTable]);

  const referenceTableFields: ReturnTypeOfRelationshipColumnData[] = useMemo(() => {
    return isEditMode ? targetTableFields : refernceTableData;
  }, [isEditMode, targetTableFields, refernceTableData]);

  const targetFieldSelectOptions = useMemo(() => {
    return (
      referenceTableFields?.map((fieldItem) => {
        const isMappedTargetField = watchedFields?.specify_fields?.find(
          (item) => Number(item?.target_field) === Number(fieldItem?.column_id)
        );
        return {
          label: (
            <>
              <span className="name">{fieldItem?.column_title || ""}</span>
              {getDataTypeIcon(fieldItem?.column_type)}
            </>
          ),
          value: `${fieldItem?.column_id}` || "",
          disabled: !!isMappedTargetField,
          textForSearch: fieldItem?.column_title || "",
        };
      }) || []
    )?.sort((a, b) =>
      a?.textForSearch
        ?.toLowerCase()
        ?.localeCompare(b?.textForSearch?.toLowerCase())
    );
  }, [referenceTableFields, watchedFields, isEditMode]);

  const calculateRelationShipType = useCallback(
    (relationshipStatusValueInForm): CardinalityTextType => {
      return relationshipStatusValueInForm === "1:N"
        ? "OTM"
        : relationshipStatusValueInForm === "1:1"
        ? "OTO"
        : relationshipStatusValueInForm === "N:1"
        ? "MTO"
        : "MTM";
    },
    []
  );

  const transformFormDataToApiData = useCallback(
    (values: AddEdgeFormType): ErdEdgeApiDataType[] => {
      const apiData = values?.specify_fields?.map((column, colIndex) => {
        const sourceColumnData = sourceTableFields.find((col) => {
          return Number(column?.source_field) === Number(col?.column_id);
        });

        const targetColumnData = targetTableFields.find((col) => {
          return Number(column?.target_field) === Number(col?.column_id);
        });

        const isExistingColumn = formData?.fieldsData?.find(
          (item) => Number(item?.field_id) === Number(column?.field_id)
        );

        return {
          cons_id:
            isEditMode && isExistingColumn
              ? Number(isExistingColumn?.field_id)
              : null,
          cons_unq_id: isEditMode ? Number(edgeUniqueId) : null,
          cons_name: isEditMode ? edgeName : "",
          cons_rel_type_id: calculateRelationShipType(
            values?.relationship_status
          ),
          cons_type_id: "FKH",
          cons_status_id: "CNF" as RelationshipStatusIDType,
          cons_src_id: "USR" as RelationshipSourceIDType,
          cons_col_position: colIndex + 1,
          tbl_id: Number(values?.source_table),
          tbl_name: sourceColumnData?.table_name || "",
          col_id: Number(column?.source_field),
          col_name: sourceColumnData?.column_name || "",
          ref_tbl_id: Number(values?.target_table),
          ref_tbl_name: targetColumnData?.table_name || "",
          ref_col_id: Number(column?.target_field),
          ref_col_name: targetColumnData?.column_name || "",
          action: "UPSERT" as ApiActionType,
        };
      });

      return apiData;
    },
    [targetTableFields, sourceTableFields, isEditMode, tableId, edgeUniqueId]
  );

  const onSubmit = useCallback(
    (values: AddEdgeFormType) => {
      const cons = transformFormDataToApiData(values);

      onExecuteRequest(
        { cons },
        undefined,
        onErdEdgeSaveSuccess,
        "save_erd_edge"
      );
    },
    [transformFormDataToApiData]
  );

  useEffect(() => {
    if (isEditMode && !isDataFetching) {
      append(fieldsData);
    }
  }, []);

  const resetFields = useCallback(() => {
    setValue("specify_fields", [
      { source_field: undefined, target_field: undefined },
    ]);
  }, []);

  const onReferenceTableChange = useCallback(
    (option) => {
      onGetReferenceTablesList(undefined, [tableId, option]);
    },
    [tableId]
  );

  const checkDataTypes = useCallback(() => {
    setSrcTargetDataTypeDiffError("");
    speciyFields?.map((item) => {
      if (item?.source_field && item?.target_field) {
        const sourceOption = sourceTableFields?.find(
          (option) => Number(option?.column_id) === Number(item?.source_field)
        );
        const targetOption = referenceTableFields?.find(
          (option) => Number(option?.column_id) === Number(item?.target_field)
        );
        sourceOption?.column_type !== targetOption?.column_type &&
          setSrcTargetDataTypeDiffError(
            "Source and target field have different data types"
          );
      }
      return "";
    });
  }, [speciyFields, sourceTableFields, referenceTableFields]);

  return (
    <StateHandler
      isModal
      isFetching={erdEdgeSaveLoading}
      error={erdEdgeSaveError}
      errorMsg={errorMsg}
    >
      <ErdEdgeEditWrapperStyled>
        <InputFieldWithLabel label="Source Table">
          <SelectField
            setValue={setValue}
            isAllowClear={false}
            control={control}
            name="source_table"
            placeholder="Select"
            options={referenceTableSelectOptions}
            showSearch
            filterOption={selectFilterOption}
            propOnChange={resetFields}
            disabled
          />
        </InputFieldWithLabel>

        <VerticalDividerStyled height="1px" width="100%" color="#e8e8e8" />

        <InputFieldWithLabel label="Reference Table">
          <SelectField
            allowClear={false}
            placeholder="Select"
            control={control}
            name="target_table"
            setValue={setValue}
            options={referenceTableSelectOptions}
            showSearch
            filterOption={selectFilterOption}
            propOnChange={(option): void => {
              resetFields();
              onReferenceTableChange(option);
            }}
            disabled={isEditMode}
          />
        </InputFieldWithLabel>

        <DividerStyled height="1px" width="100%" />

        <InputFieldWithLabel label="Relationships">
          <ToggleButton
            options={RELATIONSHIP_STATUS_OPTIONS}
            onClick={(option: string): void =>
              setValue("relationship_status", option)
            }
            selectedOption={relationshipStatus}
          />
        </InputFieldWithLabel>

        <DividerStyled height="1px" width="100%" />

        <FieldsAndLabelStyled>
          <div className="lables">
            <InputLabel>Source Fields</InputLabel>
            <InputLabel>Reference Fields</InputLabel>
          </div>

          <FieldsWrapperStyled>
            {fields?.map((sourceTargetField, index) => (
              <div className="field" key={sourceTargetField?.id}>
                <SelectField
                  control={control}
                  name={`specify_fields.${index}.source_field`}
                  placeholder="Select"
                  options={sourceFieldSelectOptions}
                  setValue={setValue}
                  showSearch
                  filterOption={selectFilterOption}
                  isAllowClear={false}
                  propOnChange={checkDataTypes}
                />

                <VerticalDividerStyled
                  width="10px"
                  height="2px"
                  className="vertical-divider"
                />

                <SelectField
                  isAllowClear={false}
                  placeholder="Select"
                  control={control}
                  name={`specify_fields.${index}.target_field`}
                  setValue={setValue}
                  options={refernceTableLoading ? [] : targetFieldSelectOptions}
                  showSearch
                  filterOption={selectFilterOption}
                  propOnChange={checkDataTypes}
                  notFoundContent={
                    refernceTableLoading ? (
                      <LoadingSpinerStyled>
                        Data is being fetched <Spin size="small" />
                      </LoadingSpinerStyled>
                    ) : refernceTableError ? (
                      <LoadingSpinerStyled>
                        No results found
                      </LoadingSpinerStyled>
                    ) : null
                  }
                />
                {fields?.length > 1 && (
                  <LinkButton
                    onClick={removeField(index)}
                    className="remove-field-btn"
                  >
                    {lightCrossIcon("10px", "10px")}
                  </LinkButton>
                )}
              </div>
            ))}
          </FieldsWrapperStyled>

          <LinkButton
            className="add-fields-btn"
            onClick={(): void =>
              append({ source_field: undefined, target_field: undefined })
            }
          >
            {plusIconInFilledCircle("12px", "12px")}
            <span className="text">Add Field</span>
          </LinkButton>
        </FieldsAndLabelStyled>

        {srcTargetDataTypeDiffError && (
          <Alert
            height="35px"
            message={srcTargetDataTypeDiffError}
            type="warning"
            icon={warningIcon}
            showIcon
          />
        )}

        <FormButtonsWrapper>
          <Button
            className="cancel-button"
            onClick={handleCancelButtonClick}
            id="cancel"
          >
            Cancel
          </Button>
          <Button
            className="save-button"
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
            tooltipProps={{
              title: !isValid
                ? "Some fields are not fulfilling the validation"
                : "",
              placement: "topRight",
            }}
          >
            Save Changes
          </Button>
        </FormButtonsWrapper>
      </ErdEdgeEditWrapperStyled>
    </StateHandler>
  );
};

export default ErdEdgeEditForm;
