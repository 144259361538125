// <--- Library Imports Start --->
import styled, { createGlobalStyle } from "styled-components";
// <--- Library Imports End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../utils";
// <--- Utils End --->

export const TableActionsSecStyled = styled.div<{ isVisible: boolean }>`
  .actions-container {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: ${({ theme, isVisible }): string =>
      isVisible ? theme.textColors.BLUE_116 : theme.textColors.GREY_25};
    background: ${({ theme, isVisible }): string =>
      isVisible ? hexToRgbA(theme.bgColors.BLUE_116, 0.1) : "inherit"};
    font-size: 23px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 14px;
    cursor: pointer;
    font-family: OpenSansExtraBold;
  }
`;

export const TooltipGlobalStyle = createGlobalStyle<{
  theme: any;
  isSvgNoHighlight: boolean;
}>`
.tables-action-section-tooltip{
  padding-top:2px;
  .ant-tooltip-content{
    .ant-tooltip-arrow{
      top:-10.071068px;
      right:6px;
      .ant-tooltip-arrow-content{
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px ${({ theme }): string => theme.borderColors.GREY_18};
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        width: 8px;
        height: 8px;
      }
    }
    .ant-tooltip-inner{
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px ${({ theme }): string => theme.borderColors.GREY_18};
      background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
      min-width:98px;
      max-width: 150px;
      padding:10px 0px;
       
        .tooltip-custom-content{
          .item,.matching-status-popover-item{
            height: 30px;
            padding: 0px 13px;
            color:${({ theme }): string => theme.textColors.DARK_GREY};
            font-size: 14px;
            width: 100%;
            border-radius: 0px;
            text-align: left;
            :not(:last-of-type){
            border-bottom: 1px solid  ${({ theme }): string =>
              hexToRgbA(theme?.borderColors?.GREY_18, 0.3)};
           }

            :not(:disabled){
              :hover{
              color:${({ theme }): string => theme.textColors.WHITE_COLOR};
              background-color: ${({ theme }): string =>
                theme.bgColors.BLUE_116};
            }
            
            }

            svg {
              width: 12px;
              height: 12px;
              margin-right: 5px;

              ${({ theme, isSvgNoHighlight }): string =>
                !isSvgNoHighlight
                  ? `
              path {
                         fill: ${theme.bgColors.BLUE_116};
                      }
              `
                  : ""}
            }

            :not(:disabled){
              :hover {
                svg {
                  path {
                    fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                  }
                }
              }
            }

            :disabled {
              svg {
                path {
                  fill: ${({ theme }): string => theme.bgColors.DARK_GREY};
                }
              }
            }
          }

          .matching-status-popover-item{
            :not(:disabled){
              :hover {
                svg {
                  g{
                    stroke:${({ theme }): string => theme.bgColors.BLUE_116};
                    circle{
                      stroke:${({ theme }): string =>
                        theme.bgColors.WHITE_COLOR};
                    }
                  }
                }

                path{
                  stroke:${({ theme }): string => theme.bgColors.WHITE_COLOR};
                }
              }
            }
          }
        }
    }
  }
}
`;
