// <--- Library Imports Start --->
import { Tooltip } from "antd";
import { useCallback, useState } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import DvSumPopover from "../dvsumpopover";
import Conditionalwrapper from "../conditionalwrapper";

import TagDetailsCard from "../tagdetailscard";
// <--- Components End --->

// <--- Constants Start --->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
// <--- Constants End --->

// <--- Styles Start --->
import {
  RemainingTagStyled,
  ProminentTagsListStyled,
  RemainingProminentTagsSecStyled,
  ProminentTagStyled,
} from "./prominenttagslist.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  ProminentTagsProps,
  SingleTagRendrerProps,
  ProminentTagPopoverProps,
} from "./prominenttagslist.types";
// <--- Types End --->

function ProminentTagPopover(props: ProminentTagPopoverProps): JSX.Element {
  const {
    tag,
    children,
    isSelected,
    setIsSelected,
    wrapperCondition,
    showPopoverOnHover = false,
  } = props;

  const onchangeVisibility = useCallback(
    (visible: boolean) => {
      tag?.tagDetails && setIsSelected && setIsSelected(visible);
    },
    [isSelected, setIsSelected, tag]
  );

  return (
    <Conditionalwrapper
      condition={wrapperCondition}
      wrapper={(child): JSX.Element => (
        <DvSumPopover
          content={tag?.tagDetails && <TagDetailsCard {...tag?.tagDetails} />}
          trigger={showPopoverOnHover ? ["hover"] : ["click"]}
          placement="bottomLeft"
          onVisibleChange={onchangeVisibility}
          zIndex={1080}
        >
          {child}
        </DvSumPopover>
      )}
    >
      {children}
    </Conditionalwrapper>
  );
}

export function SingleTagRendrer(props: SingleTagRendrerProps): JSX.Element {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const {
    tag,
    isBoldView,
    isShowOnlyOne,
    tagsType,
    showPopoverOnHover = false,
    isBordered,
  } = props;

  const handleOnClick = useCallback((event: any) => {
    event?.stopPropagation();
  }, []);

  return (
    <ProminentTagPopover
      tag={tag}
      isSelected={isSelected}
      setIsSelected={setIsSelected}
      wrapperCondition={!!tag?.tagDetails}
      showPopoverOnHover={showPopoverOnHover}
    >
      <ProminentTagStyled
        type={tag?.type || tagsType}
        isSelected={tag?.showBorder || isSelected}
        isBoldView={isBoldView}
        isBordered={isBordered}
        isClickable={!!tag?.tagDetails}
        isShowOnlyOne={isShowOnlyOne}
        onClick={handleOnClick}
        className="prominent-tag-wrapper"
      >
        <span title={tag?.name || ""}>{tag?.name || ""}</span>
      </ProminentTagStyled>
    </ProminentTagPopover>
  );
}

function ShowOnlyOneProminentTag(props: ProminentTagsProps): JSX.Element {
  const {
    tags = [],
    isBoldView,
    isShowOnlyOne,
    tagsType,
    areTagsClickable,
    showPopoverOnHover = false,
  } = props;

  const firstTag = tags?.[0];

  const handleClick = useCallback((event: any) => {
    event?.stopPropagation();
  }, []);

  return tags?.length >= 1 ? (
    <>
      <SingleTagRendrer
        tag={firstTag}
        isBoldView={isBoldView}
        isShowOnlyOne={isShowOnlyOne}
        showPopoverOnHover={showPopoverOnHover}
      />
      {tags?.length > 1 && (
        <Tooltip
          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
          placement="bottomLeft"
          title={
            <RemainingProminentTagsSecStyled>
              {tags?.slice(1)?.map((tag) => (
                <ProminentTagPopover
                  tag={tag}
                  wrapperCondition={!!tag?.tagDetails && !!areTagsClickable}
                  showPopoverOnHover={showPopoverOnHover}
                  key={`${tag?.name}-${tag?.id}`}
                >
                  <RemainingTagStyled
                    title={tag?.name || ""}
                    type={tag?.type}
                    isClickable={areTagsClickable || showPopoverOnHover}
                  >
                    {tag?.name || ""}
                  </RemainingTagStyled>
                </ProminentTagPopover>
              ))}
            </RemainingProminentTagsSecStyled>
          }
          trigger={
            areTagsClickable && showPopoverOnHover ? ["hover"] : ["click"]
          }
        >
          <ProminentTagStyled
            type={firstTag?.type || tagsType}
            className="bold-count"
            isClickable={areTagsClickable}
            onClick={handleClick}
            role="button"
          >
            +{tags?.length - 1}
          </ProminentTagStyled>
        </Tooltip>
      )}
    </>
  ) : (
    <div />
  );
}

function ProminentTags(props: ProminentTagsProps): JSX.Element {
  const { tags = [], isShowOnlyOne, showPopoverOnHover = false } = props;

  return (
    <ProminentTagsListStyled
      className="prominent-tags-sec"
      data-testid="prominent-tags-sec"
    >
      {isShowOnlyOne ? (
        <ShowOnlyOneProminentTag {...props} />
      ) : (
        tags?.map((tag) => (
          <SingleTagRendrer
            tag={tag}
            {...props}
            key={`prominent-tags-${tag?.id}-${tag?.name}`}
            showPopoverOnHover={showPopoverOnHover}
          />
        ))
      )}
    </ProminentTagsListStyled>
  );
}

export default ProminentTags;
