import LinkButton from "../../../../../../../../../../../../components/linkbutton/linkbutton";
import { ELEMENT_IDS } from "../../../../../../../../../../../../constants";

import { sendMessageIcon } from "../../../../../../../../../../../../svgs";
import { checkIsChatPage } from "../../../../../../../../../../../../utils";

import {
  GuideMeListItemStyled,
  GuideMeSectionStyled,
  ListItemsWrapper,
} from "./guidemesection.styles";

import { GuideMeSectionProps } from "./guidemesection.types";

const { caddi_gid_me_blk: CADDI_GID_ME_BLK } = ELEMENT_IDS;

const GuideMeSection = (props: GuideMeSectionProps): JSX.Element => {
  const isChatPage = checkIsChatPage();

  const { onClickSampleQuestion, data, index, isExpanded, isAuthor } = props;

  const { summary = "", questions = [] } = data || {};

  return (
    <GuideMeSectionStyled id={`${CADDI_GID_ME_BLK}-${index + 1}`}>
      {!questions?.length ? (
        <p className="no-questions-heading">
          No Questions are available at the moment, Please try again later.
        </p>
      ) : (
        <>
          {isExpanded && <p className="section-heading">{summary}</p>}

          {isExpanded && (
            <ListItemsWrapper data-testid="caddi-guide-me-list">
              {questions?.map((item, nestIndex) => {
                return (
                  <GuideMeListItemStyled
                    key={`guide-me-list-${nestIndex}`}
                    data-testid={`caddi-guide-me-list-item-${nestIndex}`}
                  >
                    {item?.ques_label}
                    {!(isChatPage && !isAuthor) && (
                      <LinkButton
                        className="execute-span"
                        onClick={(): void =>
                          onClickSampleQuestion(item?.ques_label)
                        }
                        elementId={`${CADDI_GID_ME_BLK}-${index + 1}-qst-${
                          nestIndex + 1
                        }`}
                      >
                        (
                        <span className="icon">
                          {sendMessageIcon("10", "10")}
                        </span>
                        Use this )
                      </LinkButton>
                    )}
                  </GuideMeListItemStyled>
                );
              })}
            </ListItemsWrapper>
          )}
        </>
      )}
    </GuideMeSectionStyled>
  );
};

export default GuideMeSection;
