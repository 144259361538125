import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const ElementWithDefaultTooltipStyled = styled.div<{
  width?: string;
  showEllipsis?: boolean;
}>`
  ${({ width }): any => width && `width: ${width};`}
  ${({ showEllipsis }): any => showEllipsis && ellipsis}
`;
