import styled from "styled-components";
import LinkButton from "../linkbutton/linkbutton";

export const ToggleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 8px 0; */
  width: 72px;
`;

export const ToggleButtonStyled = styled(LinkButton)<{
  selected: boolean;
}>`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  span {
    font-size: 12px;
  }

  &:first-child {
    width: 45px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:nth-child(2) {
    width: 38px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &,
  &:disabled,
  &:disabled:hover,
  &:hover,
  &:focus {
    border: solid 1px
      ${({ theme, selected }): string =>
        selected
          ? theme?.borderColors?.BLUE_116
          : theme?.borderColors?.GREY_18};
    background-color: ${({ theme, selected }): string =>
      selected ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.WHITE_COLOR};
    color: ${({ theme, selected }): string =>
      selected ? theme?.textColors?.WHITE_COLOR : theme?.textColors?.GREY_210};
  }
`;
