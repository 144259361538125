import styled from "styled-components";

export const CancelScanModalStyled = styled.div`
  .ant-form {
    .form-wrapper {
      .ant-form-item {
        margin-bottom: 51.2px;
        padding-right: 50.8px;
      }
    }

    .ant-form-item {
      &.form-actions-sec {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            justify-content: space-between;

            .cancel-btns-container {
              display: flex;
            }
          }
        }
      }
    }
  }
`;
