// <--Styles Start-->
import { EndOnboardingTutorialModalStyled } from "./endonboardintutorialmodal.styles";
// <--Styles End-->

// <--Utils Start-->
import { flagCircleSVG, redHandCircleIcon } from "../../../../svgs";
import { EndOnboardingTutorialModalProps } from "./endonboardintutorialmodal.types";
import DvsumHelpAndOnboardingFeedbackSec from "../../../dvsumhelpandonboardingfeedbacksec";
import LinkButton from "../../../linkbutton";
// <--Utils End-->

const EndOnboardingTutorialModal = (
  props: EndOnboardingTutorialModalProps
): JSX.Element => {
  const { id = "", title = "", content = "", isExitTutorialModal, onClose } =
    props || {};

  return (
    <EndOnboardingTutorialModalStyled>
      {isExitTutorialModal ? redHandCircleIcon() : flagCircleSVG()}

      <div className="congrats-txt">{title}</div>
      <div className="end-tutorial-modal-cont">{content}</div>

      <DvsumHelpAndOnboardingFeedbackSec type="Tutorial" id={id} />

      <div className="end-tutorial-modalfooter-info">
        {isExitTutorialModal ? (
          <LinkButton onClick={onClose} width="158px">
            Go Back
          </LinkButton>
        ) : (
          <span />
        )}
      </div>
    </EndOnboardingTutorialModalStyled>
  );
};

export default EndOnboardingTutorialModal;
