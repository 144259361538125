import { APP_ROUTES } from "../constants";

export const checkIsAnalyticsDictionaryPage = (): boolean => {
  const pathname = window?.location?.pathname || "";

  try {
    return APP_ROUTES.private_routes.analytics_dictionary === pathname;
  } catch (e) {
    return false;
  }
};
