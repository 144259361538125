//   <--Library Imports Start-->
import styled from "styled-components";
//   <--Library Imports End-->

export const QuickEditForColConfirmationModalStyled = styled.div`
  .content-sec {
    padding: 32px 20px 51px 25px;
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
  }
`;
