import { useHistory, useParams } from "react-router";
import { useCallback } from "react";

import { ELEMENT_IDS } from "../../../../constants";
import ListingPages from "../../../listingpage/listingpage";

import { AnalyticsTabSyled } from "./analyticstab.styles";
import { AnalyticsTabProps } from "./analyticstab.types";

import { NodeType } from "../../../../app.types";
import { getAnalysisDetailPageUrl } from "../../../../utils";
import { ChatbotAnalyticsViewKeys } from "../..";

const AnalyticsTab = (props: AnalyticsTabProps): JSX.Element => {
  const history = useHistory();
  const { view } = useParams<{
    view?: ChatbotAnalyticsViewKeys;
  }>();

  const { analysisId = "" } = props;

  const selectedView = view === "chats-listing" ? "CHT" : "FBK";

  const onChangeSelectedView = useCallback(
    (view: NodeType) => {
      history?.push(
        getAnalysisDetailPageUrl(
          analysisId,
          "analytics",
          view === "CHT" ? "chats-listing" : "feedback-listing"
        ),
        history?.location?.state
      );
    },
    [analysisId, history?.location?.state]
  );

  return (
    <AnalyticsTabSyled>
      <ListingPages
        key={`caddi-analytics-tab-${selectedView}`}
        type={selectedView}
        parentNodeId={analysisId}
        gridElemId={ELEMENT_IDS.cht_bot_anal_cht_lst_sec}
        onChangeHeaderRightView={onChangeSelectedView}
      />
    </AnalyticsTabSyled>
  );
};

export default AnalyticsTab;
