import { ELEMENT_IDS } from "../../../../../../constants";
import { useWidgetCreationPageContext } from "../../../../../../contexts/widgetcreationcontext";
import { WidgetCreationCurrentViewId } from "../../../../../../contexts/widgetcreationcontext/widgetcreationcontext.types";

import { useCancelModal } from "../../../../../../customhooks";
import {
  createYourOwnWidgetIcon,
  pickFromGalleryWidgetIcon,
} from "../../../../../../svgs";

import Button from "../../../../../button";
import Flex from "../../../../../flex";

import {
  AddWidgetCreateOrPickWrapperStyled,
  AddWidgetsCard,
  WidgetCardsWrapperStyled,
  WidgetFooterWrapperStyled,
} from "./addwidgetcreateorpick.styles";
import { AddWidgetCreateOrPickPropsType } from "./addwidgetcreateorpick.types";

const {
  dash_add_wgt_pck_frm_gallery: DASH_ADD_WGT_PCK_FRM_GALLERY,
  dash_add_wgt_crt_your_own: DASH_ADD_WGT_CRT_YOUR_OWN,
} = ELEMENT_IDS;

const AddWidgetCreateOrPick = (
  props: AddWidgetCreateOrPickPropsType
): JSX.Element => {
  const onCancel = useCancelModal();
  const { state, updateState } = useWidgetCreationPageContext();

  const changeView = (
    selectedViewId: WidgetCreationCurrentViewId
  ) => (): void => {
    const type = selectedViewId === "PFG" ? "PFG" : "COR";
    const isCreateYourOwnWidgetSelected = selectedViewId === "COR";
    updateState({
      ...state,
      currentViewId: selectedViewId,
      selectedWidgetType: type,
      ...(isCreateYourOwnWidgetSelected && { selectedWidgetConfig: undefined }),
    });
  };

  const cardsConfig = [
    {
      title: "Pick from Gallery",
      desc: "Choose from the DvSum widget library",
      onClick: changeView("PFG"),
      icon: pickFromGalleryWidgetIcon(),
      id: DASH_ADD_WGT_PCK_FRM_GALLERY,
    },

    {
      title: "Create your Own",
      desc: "Start from scratch and define a new widget",
      onClick: changeView("WF"),
      icon: createYourOwnWidgetIcon(),
      id: DASH_ADD_WGT_CRT_YOUR_OWN,
    },
  ];

  return (
    <AddWidgetCreateOrPickWrapperStyled>
      <WidgetCardsWrapperStyled>
        {cardsConfig?.map((card) => {
          const { title = "", desc = "", onClick, icon } = card || {};
          return (
            <AddWidgetsCard
              onClick={onClick}
              key={`card-${title}`}
              id={card?.id}
            >
              <Flex direction="column" alignItems="flex-start">
                <div className="add-widget-card-img">{icon}</div>
                <div className="add-widget-card-title">{title}</div>
                <div className="add-widget-card-desc">{desc}</div>
              </Flex>
            </AddWidgetsCard>
          );
        })}
      </WidgetCardsWrapperStyled>
      <WidgetFooterWrapperStyled>
        <Button id="cancel" width="74px" onClick={onCancel}>
          Cancel
        </Button>
      </WidgetFooterWrapperStyled>
    </AddWidgetCreateOrPickWrapperStyled>
  );
};

export default AddWidgetCreateOrPick;
