import styled from "styled-components";

export const DataQualityRuleDetailsBarChartStyled = styled.div`
  .parent-wrapper {
    border: solid 1px
      ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};

    .content-sec {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        width: 100%;
        height: 83px;
        background-color: ${({ theme }): string =>
          theme?.bgColors?.LIGHT_BLUE_25};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 6px;

        .time-travel-sec {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
          font-family: OpenSansSemiBold;

          .time-travel-date {
            margin: 0 7px;
          }

          > .ant-btn-link {
            display: flex;
          }

          .time-travel-sec-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .score-sec {
            display: flex;
            column-gap: 8px;
            color: ${({ theme }): string => theme?.textColors?.GREY_24};
          }

          .history-chart-btn {
            span {
              width: 211px;
              height: 56px;
              display: flex;
              justify-content: center;
              align-items: center;

              transition: background 0.5s;
              margin: 20px;

              &:hover {
                background: ${({ theme }): string =>
                  theme?.bgColors?.LIGHT_BLUE_26};
              }
            }
          }

          .divider {
            height: 56px;
            width: 1px;
            background: ${({ theme }): string =>
              theme?.bgColors?.LIGHT_GREY_51};
          }
        }
      }
    }

    .dvsum-bar-chart {
      background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    }
  }
`;
