import styled from "styled-components";

export const AutoCompleteStyled = styled.div<{
  width?: string;
  marginBottom?: string;
  isShowIcon?: boolean;
}>`
  position: relative;

  .search-icon {
    position: absolute;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    height: 100%;
  }

  .ant-select {
    width: ${({ width }): any => width || "100%"};
    margin-bottom: ${({ marginBottom }): any => marginBottom};
    .ant-select-selector {
      height: 34px;
      border-radius: 4px;
      border-color: ${({ theme }): string => theme.textColors.LIGHT_GREY_40};
      padding-left: ${({ isShowIcon }): string =>
        isShowIcon ? " 30px" : "13px"};

      .ant-select-selection-search {
        ${({ isShowIcon }): string => (isShowIcon ? "padding-left: 22px;" : "")}
        left: 8px;
        .ant-select-selection-search-input {
          padding-bottom: 3px;
          height: 34px;
          color: ${({ theme }): string => theme.textColors.BLACK_12};
        }
      }
      .ant-select-selection-placeholder {
        color: ${({ theme }): string => theme.textColors.LIGHT_GREY_45};
        padding-top: 2px;
      }
    }

    .search-results-dropdown {
      top: 42px !important;
      padding: 0px;
      padding-bottom: 6px;
      padding-top: 6px;

      .rc-virtual-list {
        .rc-virtual-list-holder {
          .rc-virtual-list-holder-inner {
            .ant-select-item-group {
              padding: 0;
            }

            .ant-select-item-option {
              padding: 5px 13px;
            }

            .ant-select-item-option-active,
            .ant-select-item-option-selected {
              background: ${({ theme }): string => theme.bgColors.BLUE_118};
              .ant-select-item-option-content {
                .render-item {
                  color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
                  svg {
                    path {
                      fill: ${({ theme }): string =>
                        theme.bgColors.WHITE_COLOR};
                    }
                  }

                  .core-nodes-circle-icon {
                    background: ${({ theme }): string =>
                      theme.bgColors.WHITE_COLOR};
                    color: ${({ theme }): string => theme.textColors.BLUE_118};
                  }
                }
              }
            }

            .ant-select-item-option-disabled {
              opacity: 0.5;
            }
          }
        }
      }

      .ant-select-item-empty {
        padding: 0px 12px;
        display: flex;
        align-items: center;
        > div {
          padding: 0px;
        }
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${(props): string => props.theme.borderColors.YELLOW_10};
    box-shadow: none;
    outline: none;

    .ant-select-selection-placeholder {
      display: none;
    }

    .ant-select-selection-item {
      color: ${(props): string => props.theme.textColors.BLACK_13};
    }
  }
`;
