import { LinkTabs } from "../tabs";
import { TabsWithRightSecStyled } from "./tabswithrightsec.styles";
import { TabsWithRightSectionProps } from "./tabswithrightsec.types";

function TabsWithRightSection(props: TabsWithRightSectionProps): JSX.Element {
  const { tabs, children } = props;
  return (
    <TabsWithRightSecStyled className="tabs-with-right-sec">
      <LinkTabs {...tabs} />
      {children}
    </TabsWithRightSecStyled>
  );
}

export default TabsWithRightSection;
