import { Button } from "antd";
import styled, { css } from "styled-components";

import { idsType } from "./linktabs.types";

import { hexToRgbA } from "../../../utils";
import { StyledMapping } from "./linktabs.utils";

export const LinkTabsStyled = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(_props): string => hexToRgbA("#707070", 0.1)};
  padding-bottom: 9px;
`;

export const TabButton = styled(Button)<{
  selected: boolean;
  disabled?: boolean;
  btnType: idsType;
}>`
  &.ant-btn-link {
    position: relative;
    color: ${(props): string =>
      props.selected
        ? props.theme.textColors.BLUE_116
        : !props?.disabled
        ? StyledMapping(props.btnType, props.theme).textColor || ""
        : ""};
    font-size: 16px;
    font-weight: ${(props): string => (props.selected ? "bold" : "normal")};
    font-family: ${(props): string =>
      props.selected ? "OpenSansSemiBold" : "OpenSans"};
    padding: 3px 9px 13px 9px;
    transition: unset;

    :first-of-type {
      padding-left: 15px;
    }
    :not(:last-of-type) {
      margin-right: 44px;
    }

    :disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    ${(props): any =>
      props.selected &&
      css`
        &::after {
          position: absolute;
          top: 37px;
          left: 0;
          width: 100%;
          height: 3px;
          background: ${(props): string => props.theme.bgColors.BLUE_116};
          content: "";
        }
      `};

    .custom-tab-icon {
      margin-right: 7px;
      vertical-align: middle;

      svg {
        width: 18.4px;
        path {
          fill: ${(props): string =>
            props.selected
              ? props.theme.textColors.BLUE_116
              : StyledMapping(props.btnType, props.theme).textColor || ""};
        }
      }
    }
  }
`;
