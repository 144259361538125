function CsvStrToArray(text: string): any {
  const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
  const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
  // Return NULL if input string is not well formed CSV string.
  if (!re_valid.test(text)) return null;
  const a = []; // Initialize array to receive values.
  text.replace(
    re_value, // "Walk" the string using replace with callback.
    function (_m0: any, m1: any, m2: any, m3: any) {
      // Remove backslash from \' in single quoted values.
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      // Remove backslash from \" in double quoted values.
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      else if (m3 !== undefined) a.push(m3);
      return ""; // Return empty string.
    }
  );
  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push("");
  return a;
}

export function csvToJSON(csv: any): any[] {
  try {
    if (Array.isArray(csv?.data)) {
      return csv?.data;
    }
    const result = [];
    const lines = csv?.data?.split("\n");
    const headers = lines?.[0]?.split(",");

    for (let i = 1; i < lines?.length; i++) {
      const obj: any = {};
      const currentline = CsvStrToArray(lines?.[i]);

      for (let j = 0; j < headers?.length; j++) {
        obj[headers?.[j]?.trim()] = currentline?.[j]?.replaceAll(`"`, "");
      }

      if (currentline?.length) {
        result.push(obj);
      }
    }

    return result;
  } catch {
    return [];
  }
}
