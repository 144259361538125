import { getDvSumInformativeText } from "../../../../utils";
import { AddRuleFormConfigProps } from "../../addruleform.types";

import { SchemaType, SectionField } from "../../dynamicform/dynamicform.types";

export const addUniquenessForm = ({
  columnSelectOptions,
  existingFilters,
  isEdit,
}: AddRuleFormConfigProps): SchemaType => {
  const {
    dat_dict_dat_qual_add_new_rle_pipln_flt: {
      description: datDictDatQualAddNewRleFndFlt = "",
    } = {},
  } = getDvSumInformativeText();
  return [
    ...((!isEdit && [
      {
        id: "basic",
        type: "section",
        fields: [
          {
            id: "rule_type",
            type: "select",
            name: "rule_type",
            label: "Rule Type",
            required: true,
            disabled: true,
          },
          {
            id: "rule_description",
            type: "text_area",
            name: "rule_description",
            label: "Rule Description",
            placeholder: "Description",
            description: "",
          },
        ],
      } as SectionField,
    ]) ||
      []),
    {
      id: "basic_input",
      type: "section",
      title: "Basic Input",
      fields: [
        {
          id: "columns",
          type: "select",
          name: "columns",
          label: "Column(s)",
          showSearch: true,
          required: true,
          multiSelect: true,
          placeholder: "Select one or more",
          options: columnSelectOptions,
        },
        {
          id: "filter_criteria",
          type: "filter",
          name: "filter_criteria",
          label: "Filter Condition",
          description: datDictDatQualAddNewRleFndFlt,
          existingFilters,
        },
      ],
    },
  ];
};
