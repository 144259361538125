import { useCallback } from "react";
import { AlertStyled } from "../../../../../../../../../../../../components/alert/alert.styles";

import { VerticalDividerStyled } from "../../../../../../../../../../../../components/dividers/dividers.styles";
import { SwitchFieldStyled } from "../../../../../../../../../../../../components/formfields/switchfield/switchfield.styles";

import { infoIcon } from "../../../../../../../../../../../../svgs";
import { QueryGeneralSettingsWrapperStyled } from "./querygeneralsettings.styles";

import { QueryGeneralSettingsType } from "./querygeneralsettings.types";

const QueryGeneralSettings = (props: QueryGeneralSettingsType): JSX.Element => {
  const { isDataFormatted, onChangeQuerySettings, id } = props;

  const updateFormat = useCallback(() => {
    onChangeQuerySettings({
      id,
      type: "readable_format_toggle",
      tab: "general",
      callSaveApi: true,
    });
  }, [id, onChangeQuerySettings]);

  return (
    <QueryGeneralSettingsWrapperStyled>
      <div className="format-btn-container">
        <div className="label-desc-container">
          <div className="label">Human-readable format</div>
          <div className="desc">
            Display numerical data in raw format or in friendly format.
          </div>
        </div>
        <SwitchFieldStyled
          size="small"
          width="40px"
          checked={isDataFormatted}
          onChange={updateFormat}
        />
      </div>

      <VerticalDividerStyled width="100%" height="1px" />

      <div className="alert-container">
        <AlertStyled
          useFlexStart
          type="message"
          icon={infoIcon}
          showIcon
          message="Click on Chart Settings to interact with the
Chart. Click on a particular field to apply field level settings."
        />
      </div>
    </QueryGeneralSettingsWrapperStyled>
  );
};

export default QueryGeneralSettings;
