import { Tooltip } from "antd";
import { useCallback, useState, useMemo } from "react";

import {
  ErdEdgeHeaderStyled,
  ErdEdgeRefViewDrawerStyled,
  TextWithIconStyled,
} from "./erdedgerefdrawer.styles";

import ViewErdEdgeDetails from "./erdedgerefdrawer.views/viewerdedgedetails/viewerdedgedetails";
import ErdEdgeEditForm from "./erdedgerefdrawer.views/erdedgeeditform/erdedgeeditform";

import {
  deleteIcon1,
  editIcon,
  tableRelationshipIcon,
  tickIcon,
} from "../../svgs";

import LinkButton from "../../components/linkbutton/linkbutton";
import ConfirmationMessage from "../../components/confirmationmessage/confirmationmessage";

import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import { openNotification } from "../../utils";

import {
  useCancelDrawer,
  useGetAppState,
  useGetAppType,
  useSetData,
} from "../../customhooks";
import { ErdEdgeRefDrawerProps, Mode } from "./erdedgerefdrawer.types";

import { defaultSourceTargetFields } from "./erdedgerefdrawer.views/erdedgeeditform/erdedgeeditform.config";

import {
  useGetErdEdgeInfo,
  useGetReferneceTablesList,
} from "../../api/erddiagramservice/erddiagramservice";

import StateHandler from "../../components/statehandler/statehandler";
import { useRequestWithMethod } from "../../api";

import { API_CONFIG } from "../../constants/apiconfig";
import { useGetRelationshipColumnsData } from "../../api/tablesservice";
import {
  ErdEdgeInfoType,
  RelationshipTablesDataResponse,
} from "../../parsers/tablepage/tablepageparser.types";
import { isEnterpriseSubscription } from "../../utils/isenterprisesubscription";
import { ENTERPRISE_FEATURE } from "../../pages/tablepage/views/datamodeltab/datamodeltab.constant";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import {
  erdGridFieldsData,
  getEdgeInfo,
} from "../../parsers/tablepage/tablepageparser.utils";

const erdTableRelationshipSvg = tableRelationshipIcon("20", "20");
const deleteButtonSvg = deleteIcon1("12", "12");

export const ErdEdgeRefDrawer = (): JSX.Element => {
  const { drawer } = useGetAppState();
  const onSetData = useSetData();
  const closeDrawer = useCancelDrawer();
  const { isExtOrDesktop } = useGetAppType();

  const isEnterpriseSub = isEnterpriseSubscription();

  const {
    isComingFromMode = "view",
    isEditable = false,
    edgeUniqueId = 0,
    diagramLevel = "1",
    baseTableId = "",
    baseTableSourceId = 0,
    erdEdgeDetails,
    linkedRefTableIDs = [],
    onChangeShouldKeepPrevPos,
    onRelationshipActionSuccess,
    edgesInfo,
  } = (drawer?.drawerProps || {}) as ErdEdgeRefDrawerProps;

  const [mode, setMode] = useState<Mode>(isComingFromMode);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  );

  const isViewMode = mode === "view";
  const isEditMode = mode === "edit";
  const isAddMode = mode === "create";

  const memoizedLevel = useMemo(
    () =>
      diagramLevel === "level_one"
        ? "1"
        : diagramLevel === "level_two"
        ? "2"
        : "3",
    [diagramLevel]
  );

  const {
    parsedData: erdEdgeTableDetails,
    isLoading: erdEdgeTableDetailsLoading,
    error: errorInErdEdgeTableDetails,
  } = useGetErdEdgeInfo(baseTableId, `${edgeUniqueId}`, !!edgeUniqueId);

  const [edge, setEdge] = useState<ErdEdgeInfoType>(erdEdgeDetails);

  const {
    sourceTable,
    referenceTable,
    statusId = "CNF",
    cardinality = "1:N",
    fields = [],
    edgeSourceId = "IDB",
    edgeName = "",
  } = edge || {};

  const { table_id: refTableId = 0 } = referenceTable || {};
  const { table_id: sourceTableId = 0 } = sourceTable || {};

  const {
    parsedData: refrenceTablesList,
    isLoading: refrenceTablesListLoading,
    error: refrenceTablesListError,
  } = useGetReferneceTablesList(
    `${baseTableSourceId}`,
    !isViewMode && !!baseTableSourceId
  );

  const erdTableIds = useMemo(() => {
    return isAddMode ? `${baseTableId} ` : `${sourceTableId},${refTableId}`;
  }, [isAddMode, baseTableId, refTableId, sourceTableId]);

  const {
    parsedData: srcRefColumnData,
    isLoading: srcRefColumnDataLoading,
    error: sourceColumnDataError,
  } = useGetRelationshipColumnsData(
    baseTableId,
    erdTableIds,
    (isAddMode && !!baseTableId) ||
      (!!(baseTableId && refTableId && sourceTableId) && isEditMode)
  );

  const updateEdge = useCallback((updatedEdgeState: ErdEdgeInfoType) => {
    setEdge(updatedEdgeState);
  }, []);

  const onDeleteEdgeSuccess = useCallback(
    (res) => {
      const updatedErdData: RelationshipTablesDataResponse = res?.data;
      onChangeShouldKeepPrevPos?.();
      onSetData(API_CONFIG.get_data_model_with_levels, updatedErdData, [
        baseTableId,
        memoizedLevel,
      ]);
      setShowDeleteConfirmation(false);
      closeDrawer();

      openNotification(
        <SuccessNotificationMessage
          message="Relationship has been deleted"
          showSuccess
        />
      );
    },
    [baseTableId, memoizedLevel]
  );

  const onConfirmEdgeSuccess = useCallback(
    (res) => {
      const updatedErdData: RelationshipTablesDataResponse = res?.data;

      const currentEdgeUpdatedData = updatedErdData?.data?.filter(
        (item) => Number(item?.CONS_UNQ_ID) === Number(edgeUniqueId)
      );

      // first we are getting all fields for that specific edge
      const edgesFieldData = erdGridFieldsData(currentEdgeUpdatedData);

      // now setting the data back to parsed edge (data req in refs)
      const edgeInfo = currentEdgeUpdatedData?.[0];
      const edgeParsedData = getEdgeInfo(
        edgeInfo,
        edgesFieldData?.[edgeUniqueId]?.fieldsData
      );

      updateEdge?.(edgeParsedData);

      onChangeShouldKeepPrevPos?.();

      onSetData(API_CONFIG.get_data_model_with_levels, updatedErdData, [
        baseTableId,
        memoizedLevel,
      ]);
      openNotification(
        <SuccessNotificationMessage
          message="Relationship has been confirmed"
          showSuccess
        />,
        0,
        {
          top: isExtOrDesktop ? 0 : 60,
          className: "desktop-app-center-notification",
          getContainer: (): any => {
            const elements = document?.getElementsByClassName(
              "ant-drawer-content"
            );
            return (elements?.length && elements?.[0]) || document.body;
          },
        }
      );
    },
    [baseTableId, edgeUniqueId, memoizedLevel]
  );

  const {
    isLoading: deleteEdgeLoading,
    error: errorinDeletingEdge,
    onExecuteRequest,
  } = useRequestWithMethod(
    "delete_erd_edge",
    [baseTableId, memoizedLevel],
    true,
    onDeleteEdgeSuccess
  );

  const {
    isLoading: isConfirmEdgeLoading,
    onExecuteRequest: onConfirmEdgeRequest,
  } = useRequestWithMethod(
    "confirm_erd_edge",
    [baseTableId, memoizedLevel],
    true,
    onConfirmEdgeSuccess
  );

  const handleModeChange = useCallback(
    (mode: Mode) => (): void => {
      setMode(mode);
    },
    []
  );

  const handleCancelButtonClick = useCallback(() => {
    isComingFromMode === "view" ? setMode("view") : closeDrawer();
  }, [isComingFromMode]);

  const onShowDeleteConfirmation = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, []);

  const editFormData = useMemo(() => {
    return {
      sourceTableId: isEditMode ? `${sourceTableId}` : `${baseTableId}`,
      referenceTableId: isEditMode ? `${refTableId}` : "",
      relationship: isEditMode ? cardinality : "1:1",
      edgeUniqueId,
      edgeName,
      fieldsData: isEditMode
        ? fields?.map((column) => ({
            field_id: column?.field_id || 0,
            source_field: `${column?.source_field_id || ""}`,
            target_field: `${column?.target_field_id || ""}`,
          }))
        : [defaultSourceTargetFields],
    };
  }, [
    edgeUniqueId,
    sourceTable,
    referenceTable,
    cardinality,
    edgeName,
    isEditMode,
    fields,
    baseTableId,
  ]);

  const isEdgeFromDb = useMemo(() => edgeSourceId === "IDB", [edgeSourceId]);

  const handleDeleteButtonClick = useCallback(() => {
    const cons = [
      {
        cons_unq_id: Number(edge?.edgeUniqueId || 0),
        action: "DELETE",
      },
    ];
    onExecuteRequest({ cons }, undefined, undefined, "delete_erd_edge");
  }, [edge]);

  const handleConfirmButtonClick = useCallback(() => {
    const cons = [
      {
        cons_unq_id: Number(edge?.edgeUniqueId || 0),
        action: "CONFIRM",
      },
    ];
    onConfirmEdgeRequest({ cons }, undefined, undefined, "confirm_erd_edge");
  }, [edge?.edgeUniqueId]);

  return (
    <StateHandler
      isFetching={
        deleteEdgeLoading ||
        srcRefColumnDataLoading ||
        refrenceTablesListLoading
      }
      error={
        errorinDeletingEdge ||
        sourceColumnDataError ||
        refrenceTablesListError ||
        errorInErdEdgeTableDetails
      }
      blankSlate={undefined}
      isModal={
        (isViewMode && deleteEdgeLoading) ||
        (!isViewMode && (refrenceTablesListLoading || srcRefColumnDataLoading))
      }
    >
      <ErdEdgeRefViewDrawerStyled>
        <ErdEdgeHeaderStyled>
          {showDeleteConfirmation ? (
            <ConfirmationMessage
              message="This relationship will be deleted. In future, I'll remember
your input to not generate such relationship. Continue?"
              onOk={handleDeleteButtonClick}
              onCancel={(): void => setShowDeleteConfirmation(false)}
            />
          ) : (
            <>
              <TextWithIconStyled>
                <div className="icon">{erdTableRelationshipSvg}</div>
                <div className="text">
                  {mode === "view"
                    ? "Tables"
                    : mode === "create"
                    ? "Add"
                    : "Edit"}{" "}
                  Relationship
                </div>
              </TextWithIconStyled>
              {isViewMode && isEditable && (
                <Tooltip
                  overlayInnerStyle={{
                    minWidth: "fit-content",
                    maxWidth: "fit-content",
                  }}
                  overlayStyle={{
                    minWidth: "fit-content",
                    maxWidth: "fit-content",
                  }}
                  overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                  title={!isEnterpriseSub ? ENTERPRISE_FEATURE : undefined}
                  placement="bottomLeft"
                >
                  <div className="actions-wrapper">
                    {statusId === "RCM" && (
                      <LinkButton
                        className="edit-action-item"
                        disabled={isConfirmEdgeLoading || !isEnterpriseSub}
                        onClick={handleConfirmButtonClick}
                      >
                        {tickIcon()}Confirm
                      </LinkButton>
                    )}
                    {!isEdgeFromDb && (
                      <>
                        <LinkButton
                          className="edit-action-item"
                          onClick={handleModeChange("edit")}
                          disabled={!isEnterpriseSub}
                        >
                          {editIcon}Edit
                        </LinkButton>
                        <LinkButton
                          className="delete-action-item"
                          onClick={onShowDeleteConfirmation}
                          disabled={!isEnterpriseSub}
                        >
                          {deleteButtonSvg}Delete
                        </LinkButton>
                      </>
                    )}
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </ErdEdgeHeaderStyled>
        {isViewMode ? (
          <ViewErdEdgeDetails
            sourceTable={sourceTable}
            referenceTable={referenceTable}
            sourceTableDesc={
              erdEdgeTableDetails?.sourceTable?.description || ""
            }
            referenceTableDesc={
              erdEdgeTableDetails?.referenceTable?.description || ""
            }
            isLoadingDesc={!!erdEdgeTableDetailsLoading}
            relationShipSource={edgeSourceId}
            cardinality={cardinality}
            fields={fields}
          />
        ) : (
          <ErdEdgeEditForm
            diagramLevel={memoizedLevel}
            tableId={baseTableId}
            refrenceTablesList={refrenceTablesList}
            linkedRefTableIDs={linkedRefTableIDs}
            formData={editFormData}
            isEditMode={isEditMode}
            handleCancelButtonClick={handleCancelButtonClick}
            srcRefColumnData={srcRefColumnData}
            updateEdge={updateEdge}
            isDataFetching={
              srcRefColumnDataLoading || refrenceTablesListLoading
            }
            onChangeShouldKeepPrevPos={onChangeShouldKeepPrevPos}
            onRelationshipActionSuccess={onRelationshipActionSuccess}
            edgesInfo={edgesInfo}
          />
        )}
      </ErdEdgeRefViewDrawerStyled>
    </StateHandler>
  );
};

export default ErdEdgeRefDrawer;
