import styled from "styled-components";

export const PermissionSelectorStyled = styled.div`
  border: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_18};
  border-radius: 6.4px;

  .header {
    display: flex;
    border-bottom: 1px solid
      ${({ theme }): string => theme?.borderColors?.GREY_56};
    padding: 12.8px;

    div {
      color: #4f4f4f;
      font-size: 12px;
      font-family: OpenSansSemiBold;
    }

    .title {
      width: 30%;
    }

    .options {
      display: flex;
      justify-content: space-evenly;
      width: 70%;

      .option {
        width: 60px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    height: 325.6px;
    overflow: auto;
    margin: 9.6px 6.4px 9.6px 0;

    ::-webkit-scrollbar {
      width: 6.4px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
    }

    .item {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding-left: 12.8px;

      .name {
        width: 30%;
      }

      .ant-radio-group {
        width: 70%;
        justify-content: space-evenly;

        .ant-radio-wrapper {
          width: 60px !important;
          justify-content: center;
        }
      }

      &:hover {
        background-color: ${({ theme }): string =>
          theme?.backgroundColors?.LIGHT_BLUE_25 || "#eff3f8"};
      }
    }
  }
`;
