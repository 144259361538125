import { AxiosResponse } from "axios";
import { Edge, MarkerType } from "reactflow";

//                  <--Constants-->
import { THEME_COLORS } from "../../components/themeprovider/themeprovider.constant";
//                  <--Constants-->

//                  <--Types-->
import {
  LineageNode,
  LineageResponse,
  PagesInfoType,
  ReturnTypeOfParsedLineageData,
} from "./lineageparser.types";
import { CustomDataSetTag } from "../parser.types";
//                  <--Types-->

//                  <--Utils-->
import {
  getObjectKeys,
  getTimeDifference,
  jsonParse,
  removeDotfromTagName,
} from "../../utils";
import { getProfilingInfo } from "../../utils/getProfilingInfo";
import { ProminentTagType } from "../../components/genericprominenttag/genericprominenttag.types";
//                  <--Utils-->

const edgeStyles = {
  strokeWidth: 1,
  zIndex: 10,
  stroke: THEME_COLORS.light.textColors.LIGHT_GREY_16,
};

export const edgeCommonProperties = {
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: THEME_COLORS.light.textColors.LIGHT_GREY_16,
    strokeWidth: 2.5,
  },
  type: "floating",
};

function getUniqueDataOfNode(
  data: LineageResponse = [],
  key: keyof LineageResponse[number]
): LineageResponse {
  return [...new Map(data.map((node) => [node[key], node])).values()]?.filter(
    (item) => item?.[key]
  );
}

function createSRCGroupNode(
  srcNodes: LineageResponse,
  id?: string,
  isGroupChildJustDataSources?: boolean
): LineageNode[] {
  return (
    srcNodes?.map((item) => ({
      id: id || `${item?.SRC_ID || ""}`,
      type: "srcGroupNode",
      draggable: true,
      position: { x: 100, y: 300 },
      data: {
        nodeId: `${item?.SRC_ID || ""}`,
        nodeType: "SRC",
        nodeName: item?.SRC_NAME || "",
        level: 1,
        isDataSourceOrReportGroupNode: !!id,
        isGroupChildJustDataSources,
      },
      style: {
        width: 200,
        height: 120,
        border: "0.7px solid #f8f8f8",
        zIndex: -1,
      },
    })) || []
  );
}

function getParsedTags(tagsInfo: string): ProminentTagType[] {
  return jsonParse(tagsInfo)
    ?.map((item: CustomDataSetTag) => {
      return (item?.value || [])
        ?.filter((tag) => tag?.is_prominent)
        ?.map((tag) => ({
          id: `${tag?.tag_id || 0}`,
          name: removeDotfromTagName(tag?.tag_name || ""),
          type: tag?.color_code || "RED",
          tagDetails: {
            tagName: removeDotfromTagName(tag?.tag_name || ""),
            tagsetId: `${item?.tagset_id || 0}`,
            tagsetName: item?.tagset_name || "",
            tagNote: tag?.tag_comments || "",
            tagsetDesc: tag?.tag_desc || "",
            showDetailInfo: true,
            updatedBy: tag?.tag_created_by || "",
            updatedOn: tag?.tag_created_on,
            tagsetPolicyInfo: "",
          },
        }));
    })
    .flat();
}

function createTableNode(
  srcNodes: LineageResponse,
  parentId?: string,
  isDrillDown?: boolean
): LineageNode[] {
  return (
    srcNodes?.map((item) => ({
      id: `${item?.NODE_ID || ""}`,
      type: isDrillDown ? "drillDownTableNode" : "tableNode",
      extent: "parent",
      position: { x: 0, y: 0 },
      parentNode: parentId || `${item?.SRC_ID || ""}`,
      draggable: false,
      data: {
        nodeId: `${item?.NODE_ID || ""}`,
        nodeType: item?.NODE_TYPE,
        nodeSubType: item?.NODE_SUB_TYPE,
        nodeName: item?.NODE_NAME || "",
        lastRefreshed: getTimeDifference(item?.NODE_LAST_REFRESHED_ON || ""),
        recordCount: item?.NODE_RECORD_CNT || "",
        tags: getParsedTags(item?.NODE_TAGS || "") || [],
        level: 2,
      },
      style: { width: 180, height: 50, ...(isDrillDown && { zIndex: -1 }) },
    })) || []
  );
}

function createColumnNodes(columnNodes: LineageResponse): LineageNode[] {
  return (
    columnNodes?.map((item) => ({
      id: `${item?.COL_ID || ""}`,
      type: "columnNode",
      extent: "parent",
      position: { x: 0, y: 0 },
      parentNode: `${item?.NODE_ID || ""}`,
      draggable: false,
      data: {
        nodeId: `${item?.COL_ID || ""}`,
        nodeType: item?.COL_TYPE,
        nodeName: item?.COL_NAME || "",
        recordCount: item?.COL_RECORD_CNT.toString() || "",
        tags: getParsedTags(item?.COL_TAGS || "") || [],
        colProgress: getProfilingInfo(
          item?.COL_RECORD_CNT,
          item?.COL_DIST_CNT,
          item?.COL_BLANK_CNT
        ),
        level: 3,
        parentNodeId: `${item?.NODE_ID || ""}`,
        parentNodeName: item?.NODE_NAME || "",
        parentNodeType: item?.NODE_TYPE,
      },
      style: { width: 180, height: 50 },
    })) || []
  );
}

function createEdge(
  item: LineageResponse[number],
  isDrillDown: boolean
): Edge[] {
  return [
    {
      id: `${item?.PARENT_SRC_ID}:${item?.SRC_ID}`,
      source: `${item?.PARENT_SRC_ID || "--"}`,
      target: `${item?.SRC_ID || "--"}`,
      ...edgeCommonProperties,
      style: { ...edgeStyles, display: "none" },
    },
    {
      id: `${item?.PARENT_NODE_ID}:${item?.NODE_ID}`,
      source: `${item?.PARENT_NODE_ID || "--"}`,
      ...edgeCommonProperties,
      target: `${item?.NODE_ID || "--"}`,
      style: { ...edgeStyles, display: isDrillDown ? "none" : "" },
    },
    item?.COL_TYPE === "COL" || item?.COL_TYPE === "DSF"
      ? {
          id: `${item?.PARENT_COL_ID}:${item?.COL_ID}`,
          source: `${item?.PARENT_COL_ID || "--"}`,
          ...edgeCommonProperties,
          target: `${item?.COL_ID || "--"}`,
          style: { ...edgeStyles },
        }
      : {
          id: `--`,
          source: `--`,
          ...edgeCommonProperties,
          target: `--`,
          style: { ...edgeStyles },
        },
  ].filter(
    (item) =>
      !Number.isNaN(Number(item?.source)) && !Number.isNaN(Number(item?.target))
  );
}

function createEdges(data: LineageResponse, isDrillDown: boolean): Edge[] {
  const edges: Edge[] = data
    .map((item) => {
      return createEdge(item, isDrillDown);
    })
    .flat();
  return Array.from(new Map(edges.map((item) => [item.id, item])).values());
}

function createReportNodesAndEdges(
  data: LineageResponse = []
): { nodes: LineageNode[]; edges: Edge[] } {
  const nodesAndEdges = data?.map((item) => {
    const parsedePages = jsonParse(item?.PAGES_INFO, true) as PagesInfoType;
    const pagesInfo = getObjectKeys(parsedePages);

    return pagesInfo?.map((page) => {
      const currentPage = parsedePages?.[page];

      const node: LineageNode = {
        id: currentPage?.sheet_id || "",
        parentNode: `${item?.NODE_ID || ""}`,
        position: { x: 0, y: 0 },
        type: "columnNode",
        draggable: false,
        data: {
          nodeId: currentPage?.sheet_id || "",
          nodeName: currentPage?.sheet_name || "",
          nodeType: "ATD",
          level: 3,
          isReportPageNode: true,
        },
      };

      const edge: Edge = {
        id: `${item?.PARENT_COL_ID || "--"}:${currentPage?.sheet_id || "--"}`,
        source: `${item?.PARENT_COL_ID || "--"}`,
        target: `${currentPage?.sheet_id || "--"}`,
        style: { ...edgeStyles },
        ...edgeCommonProperties,
      };

      return { node, edge };
    });
  });

  const flatResults = nodesAndEdges?.flat() || [];

  const nodes = flatResults?.map((item) => item?.node);
  const edges = flatResults?.map((item) => item?.edge);

  const uniqueNodes = [
    ...new Map(nodes?.map((item) => [item?.id, item]))?.values(),
  ];

  const uniqueEdges = [
    ...new Map(edges?.map((item) => [item?.id, item]))?.values(),
  ]?.filter((item) => item?.source !== "--" && item?.target !== "--");

  return { nodes: uniqueNodes, edges: uniqueEdges };
}

export function getParsedLineageData({
  data = [],
  config,
}: AxiosResponse<LineageResponse>): ReturnTypeOfParsedLineageData {
  const isDrillDownMode = config?.url?.split("drill-down=")?.[1] === "true";

  const uniqueSources = getUniqueDataOfNode(data, "SRC_ID");
  const uniqueTables = getUniqueDataOfNode(data, "NODE_ID");
  const uniqueColumns = isDrillDownMode
    ? getUniqueDataOfNode(data, "COL_ID")?.filter(
        (item) => item?.NODE_SUB_TYPE !== "RPT"
      )
    : [];

  const reportNodes = isDrillDownMode
    ? data?.filter((item) => item?.NODE_SUB_TYPE === "RPT")
    : [];

  const dsrAndRptNodes = uniqueTables?.filter(
    (item) => item?.NODE_TYPE === "DSR" || item?.NODE_SUB_TYPE === "RPT"
  );

  const remainingNodes = uniqueTables?.filter(
    (item) => item?.NODE_TYPE !== "DSR" && item?.NODE_SUB_TYPE !== "RPT"
  );

  const dsrAndRptNodesSourcesIds = [
    ...new Set(dsrAndRptNodes?.map((item) => item?.SRC_ID)),
  ];

  const seperateRptAndDsrOfSources: {
    [key: string]: { reports: LineageResponse; datasources: LineageResponse };
  } = dsrAndRptNodesSourcesIds?.reduce((prev, next) => {
    return {
      ...prev,
      [next]: {
        reports: dsrAndRptNodes?.filter(
          (item) => item?.SRC_ID === next && item?.NODE_SUB_TYPE === "RPT"
        ),
        datasources: dsrAndRptNodes?.filter(
          (item) =>
            item?.SRC_ID === next &&
            item?.NODE_TYPE === "DSR" &&
            item?.NODE_SUB_TYPE !== "RPT"
        ),
      },
    };
  }, {});

  const dsrAndRptSources = getObjectKeys(seperateRptAndDsrOfSources)?.map(
    (item) => {
      const reportsGroupSourceId = `${item}:${
        seperateRptAndDsrOfSources?.[item]?.reports
          ?.map((rpt) => rpt?.NODE_ID)
          ?.toString() || ""
      }`;
      const datasourcesGroupSourceId = `${item}:${
        seperateRptAndDsrOfSources?.[item]?.datasources
          ?.map((rpt) => rpt?.NODE_ID)
          ?.toString() || ""
      }`;
      return {
        sourceNodes: [
          ...createSRCGroupNode(
            seperateRptAndDsrOfSources?.[item]?.reports?.length
              ? uniqueSources?.filter((src) => `${src?.SRC_ID}` === item)
              : [],
            reportsGroupSourceId
          ),
          ...createSRCGroupNode(
            seperateRptAndDsrOfSources?.[item]?.datasources?.length
              ? uniqueSources?.filter((src) => `${src?.SRC_ID}` === item)
              : [],
            datasourcesGroupSourceId,
            true
          ),
        ],
        tableNodes: [
          ...createTableNode(
            seperateRptAndDsrOfSources?.[item]?.datasources,
            datasourcesGroupSourceId,
            isDrillDownMode
          ),
          ...createTableNode(
            seperateRptAndDsrOfSources?.[item]?.reports,
            reportsGroupSourceId,
            isDrillDownMode
          ),
        ],
        edge: [
          {
            id: `${datasourcesGroupSourceId}:${reportsGroupSourceId}`,
            source: `${datasourcesGroupSourceId || "--"}`,
            target: `${reportsGroupSourceId || "--"}`,
            ...edgeCommonProperties,
            style: { ...edgeStyles, display: "none" },
          },
        ],
      };
    }
  );

  const remainingSources = uniqueSources?.filter(
    (item) => !dsrAndRptNodesSourcesIds?.includes(item?.SRC_ID)
  );

  const dsrAndRptSourceNodes =
    dsrAndRptSources?.map((item) => item?.sourceNodes)?.flat() || [];

  const dsrAndRptSourcesNodes =
    dsrAndRptSources?.map((item) => item?.tableNodes)?.flat() || [];

  const dsrAndRptSourcesEdges =
    dsrAndRptSources?.map((item) => item?.edge)?.flat() || [];

  const sourceNodes = [
    ...createSRCGroupNode(remainingSources),
    ...dsrAndRptSourceNodes,
  ];

  const tableNodes = [
    ...createTableNode(remainingNodes, undefined, isDrillDownMode),
    ...dsrAndRptSourcesNodes,
  ];

  const columnNodes = [...createColumnNodes(uniqueColumns)];
  const edges = [...createEdges(data, isDrillDownMode)];

  const updatedEdges = edges?.map((edge) => {
    const edgePos = {
      source: edge?.source || "",
      target: edge?.target || "",
    };

    const sourcesJustContainingDataSources = dsrAndRptSourceNodes?.filter(
      (src) => {
        return src?.data?.isGroupChildJustDataSources;
      }
    );

    const hadIdChangedSource = sourcesJustContainingDataSources?.find((src) => {
      const id = src?.id?.split(":")?.[0] || "";
      return edge?.target === id;
    });

    if (hadIdChangedSource) {
      const id = hadIdChangedSource?.id?.split(":")?.[0] || "";
      if (edge?.source === id) {
        edgePos.source = hadIdChangedSource?.id || "";
      } else if (edge?.target === id) {
        edgePos.target = hadIdChangedSource?.id || "";
      }
    }

    return {
      ...edge,
      ...edgePos,
    };
  });

  const {
    nodes: reportPagesNodes = [],
    edges: reportPagesEdges = [],
  } = createReportNodesAndEdges(isDrillDownMode ? reportNodes : []);

  const combineNodes =
    [
      ...sourceNodes,
      ...tableNodes,
      ...columnNodes,
      ...reportPagesNodes,
    ]?.filter((item) => item?.id !== item?.parentNode) || [];

  const combineNodesIds = combineNodes?.map((nestItem) => nestItem?.id);

  const validNodes = combineNodes?.filter((item) =>
    item?.extent === "parent"
      ? combineNodesIds?.includes(item?.parentNode || "")
      : true
  );

  const sortedNodes = validNodes?.sort((a, b) =>
    a?.data?.nodeName?.localeCompare(b?.data?.nodeName)
  );

  return {
    edges: [...updatedEdges, ...dsrAndRptSourcesEdges, ...reportPagesEdges],
    nodes: sortedNodes,
  };
}
