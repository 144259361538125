import { useFormContext } from "react-hook-form";

import { SelectWithLabelAndDescStyled } from "./selectwithlabelanddesc.styles";
import { SelectField } from "../formfields";

const SelectWithLabelAndDesc = (props: {
  desc: string;
  onChange?: () => void;
}): JSX.Element => {
  const { desc, onChange } = props;
  const { control, setValue, setError } = useFormContext();

  return (
    <SelectWithLabelAndDescStyled>
      <p className="label">Default Setting</p>
      <p className="desc">{desc}</p>
      <SelectField
        name="default_flag"
        control={control}
        options={[
          {
            label: "Enable",
            value: "enable",
          },
          {
            label: "Disable",
            value: "disable",
          },
        ]}
        isAllowClear={false}
        setValue={setValue}
        placeholder="Select the Option"
        setError={setError}
        width="460px"
        height="44px"
        allowClearLabel="Select the Option"
        propOnChange={onChange}
      />
    </SelectWithLabelAndDescStyled>
  );
};
export default SelectWithLabelAndDesc;
