import styled from "styled-components";

export const KeyValueWrapper = styled.div<{ lastChild?: boolean }>`
  display: grid;
  align-items: flex-start;
  justify-content: start;
  column-gap: 15px;
  grid-template-columns: 150px 1fr;
  padding: ${({ lastChild }): string => (lastChild ? "15px" : "15px 15px 0")};

  border-bottom: ${({ theme, lastChild }): string =>
    lastChild ? `1px solid ${theme?.borderColors?.LIGHT_GREY_51}` : ""};
`;

export const KeyStyled = styled.div`
  font-size: 13.6px;
  color: ${({ theme }): string => theme?.textColors?.GREY_24};
`;

export const ValueStyled = styled.div<{ isBold?: boolean }>`
  font-size: 13.6px;
  color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
  font-family: ${({ isBold }): string => (isBold ? "OpenSansSemiBold" : "")};
  display: flex;
  column-gap: 8px;
  align-items: flex-start;
  word-break: break-word;
  justify-content: flex-start;
  margin-right: 5px;

  .col-type {
    svg {
      min-width: 18px;
      opacity: 0.3;
      margin-top: 5px;
      fill: ${({ theme }): string => theme?.textColors?.GREY_210};
    }
  }
`;

export const NodeEdgeRelationStyled = styled.div<{
  lastChild?: boolean;
}>`
  display: grid;
  grid-template-columns: 150px 1fr;
  column-gap: 15px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 15px;
  border-bottom: 1px solid
    ${({ theme, lastChild }): string =>
      lastChild ? "transparent" : theme?.borderColors?.LIGHT_GREY_51};

  button {
    width: max-content;
  }
`;

export const NodeDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  row-gap: 15px;
  border-bottom: 1px solid
    ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_51};
`;

export const MappedFieldsWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 10px;

  .labels {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
`;

export const FieldWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  row-gap: 10px;
  flex-direction: column;
  width: 100%;
`;

export const FieldStyled = styled.div`
  display: grid;
  grid-template-columns: 175px 35px 175px;
  align-items: center;
  width: 100%;

  .vertical-divider {
    text-align: center;
  }
`;
