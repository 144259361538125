import { useCallback, useState } from "react";

import MetricListItem from "../metriclistitem/metriclistitem";

import {
  FieldLabel,
  InputWithAddButtonWrapper,
  MetricInputContainer,
} from "./addmetricinput.styles";

import { AddMetricInputProps } from "./addmetricinput.types";

import { ListStyled } from "../metriclistitem/metriclistitem.styles";

import { isUserPrefferedPercentageMetricColumn as isMetricAlreadyAdded } from "../../../../../../../../utils";

import { Button } from "../../../../../../../../components";
import SectionBlankSlateText from "../../../../../../../../components/sectionblankslatetext";
import { InputFieldStyled } from "../../../../../../../../components/formfields/inputfield/inputfield.styles";

const AddMetricInput = (props: AddMetricInputProps): JSX.Element => {
  const {
    placeholder,
    label,
    metrics,
    setMetrics,
    restrictedMetrics,
    alternateRestrictedInputMessage,
    onFieldEditButtonClick,
    keyName,
    editModeFields,
  } = props;

  const [metricInputValue, setMetricInputValue] = useState("");
  const [error, setError] = useState("");

  const onMetricInputChange = useCallback(
    (e) => {
      if (error) {
        setError("");
      }

      setMetricInputValue(e.target.value);
    },
    [error]
  );

  const onAddMetric = useCallback((): void => {
    const trimmedValue = metricInputValue?.trim();
    if (
      !isMetricAlreadyAdded(trimmedValue, restrictedMetrics) &&
      !isMetricAlreadyAdded(trimmedValue, metrics)
    ) {
      if (trimmedValue) {
        setMetrics([trimmedValue, ...metrics]);
        setMetricInputValue("");
      }
    } else {
      setError(
        restrictedMetrics?.includes(trimmedValue)
          ? alternateRestrictedInputMessage
          : "The value you are trying to enter or a similar one already exists"
      );
    }
  }, [restrictedMetrics, metrics, metricInputValue]);

  const onMetricDelete = useCallback(
    (metricToDelete: string) => (): void => {
      setMetrics(metrics?.filter((item) => item !== metricToDelete));
    },
    [metrics]
  );

  const onMetricUpdate = useCallback(
    (searchString: string, newText: string): void => {
      if (!restrictedMetrics?.includes(searchString)) {
        setMetrics((prevMetrics): string[] => {
          const index = prevMetrics.indexOf(searchString);
          if (index !== -1) {
            // The searchString was found in the array
            const updatedMetrics = [...prevMetrics];
            updatedMetrics[index] = newText;
            return updatedMetrics;
          }
          // Handle the case where searchString is not found in the array
          return prevMetrics;
        });
      }
    },
    [restrictedMetrics]
  );

  return (
    <MetricInputContainer>
      <FieldLabel>{label}</FieldLabel>
      <InputWithAddButtonWrapper>
        <InputFieldStyled
          onPressEnter={onAddMetric}
          placeholder={placeholder}
          value={metricInputValue}
          onChange={onMetricInputChange}
        />

        <Button onClick={onAddMetric} id="primary" height="34px">
          Add
        </Button>
      </InputWithAddButtonWrapper>
      {!!error && <div className="error">{error}</div>}
      <SectionBlankSlateText isEmpty={!metrics?.length}>
        <ListStyled>
          {metrics?.map((metric) => {
            const metricKey = keyName + metric;
            return (
              <MetricListItem
                keyName={keyName}
                onDelete={onMetricDelete(metric)}
                key={metricKey}
                onUpdate={(updatedText: string): void =>
                  onMetricUpdate(metric, updatedText)
                }
                onFieldEditButtonClick={onFieldEditButtonClick}
                fieldId={metric}
                restrictedMetrics={restrictedMetrics}
                metrics={metrics}
                isEditOpen={editModeFields?.[metricKey]}
              >
                {metric}
              </MetricListItem>
            );
          })}
        </ListStyled>
      </SectionBlankSlateText>
    </MetricInputContainer>
  );
};

export default AddMetricInput;
