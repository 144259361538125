export const columnSequenceBlankSlate = (
  <svg
    width="498"
    height="450"
    viewBox="0 0 498 510"
    fill="none"
    style={{ paddingLeft: "80px", marginBottom: "20px" }}
  >
    <rect x="1" y="0.5" width="496" height="509" rx="7.5" fill="white" />
    <rect x="1" y="0.5" width="496" height="509" rx="7.5" stroke="#C9C9C9" />
    <rect x="20.5" y="31.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <rect x="20.5" y="71.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <rect x="20.5" y="111.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <rect x="20.5" y="151.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <rect x="20.5" y="191.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <rect x="20.5" y="231.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <rect x="20.5" y="271.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <rect x="20.5" y="311.5" width="331" height="17" rx="8.5" fill="#F0F0F0" />
  </svg>
);
