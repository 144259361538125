import { userGroupsIcon, userIcon } from "../../../../../../../../svgs";
import { ShareWithSectionStyled } from "./sharewithsection.styles";

import { ShareWithSectionType } from "./sharewithsection.types";

const ShareWithSection = (props: ShareWithSectionType): JSX.Element => {
  const { shareType, sharedWith } = props;

  return (
    <ShareWithSectionStyled>
      {" "}
      <div
        className="shared-with-container"
        data-testid="analysis-detail-page-overview-sec-shared-with-container"
      >
        <div
          className="label"
          data-testid="analysis-detail-page-overview-shared-with-text"
        >
          Shared with:
        </div>
        {shareType === "LIM" ? (
          <div
            className="users-container"
            data-testid="analysis-detail-page-overview-sec-users-container"
          >
            {sharedWith?.length
              ? sharedWith?.map((user, index) => (
                  <div
                    className="user"
                    key={`analysis-overview-sec-${user?.name}-${index}`}
                    data-testid={`analysis-detail-page-overview-sec-user-${index}`}
                  >
                    {user?.type === "USR" ? userIcon : userGroupsIcon()}
                    {user?.name}
                  </div>
                ))
              : "--"}
          </div>
        ) : shareType === "ALL" ? (
          "Everyone"
        ) : (
          "Private"
        )}
      </div>
    </ShareWithSectionStyled>
  );
};

export default ShareWithSection;
