import { ConditionalDisplayWrapper } from "./conditionaldisplay.styles";
import { ConditionalDisplayProps } from "./conditionaldisplay.types";

const ConditionalDisplay = ({
  condition = true,
  children,
}: ConditionalDisplayProps): JSX.Element => {
  return (
    <ConditionalDisplayWrapper displayOnScreen={condition}>
      {children}
    </ConditionalDisplayWrapper>
  );
};
export default ConditionalDisplay;
