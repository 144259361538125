import { Button } from "antd";
import styled from "styled-components";
import LinkButton from "../../../../linkbutton";

export const ActionButtonStyled = styled(Button)<{ isSelected: boolean }>`
  width: 30px;
  height: 27px;
  padding: 0px;
  background: ${({ theme, isSelected }): string =>
    isSelected ? theme.bgColors.BLUE_116 : theme.bgColors.WHITE_COLOR};
  border: 1px solid
    ${({ theme, isSelected }): string =>
      isSelected
        ? theme.borderColors.BLUE_116
        : theme.borderColors.LIGHT_GREY_15};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  svg {
    path {
      fill: ${({ theme, isSelected }): string =>
        isSelected
          ? theme.bgColors.WHITE_COLOR
          : theme.bgColors.LIGHT_GREY_15} !important;
    }
  }
  :hover,
  :focus,
  :active {
    background: ${({ theme, isSelected }): string =>
      isSelected ? theme.bgColors.BLUE_116 : theme.bgColors.WHITE_COLOR};
    border-color: ${({ theme, isSelected }): string =>
      isSelected
        ? theme.borderColors.BLUE_116
        : theme.borderColors.LIGHT_GREY_15};
  }

  ::after {
    animation: unset;
    transition: unset;
  }
`;

export const CircleActionBtnStyled = styled(LinkButton)<{
  isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  svg {
    circle {
      fill: ${({ theme, isSelected }): string =>
        isSelected ? theme.bgColors.BLUE_116 : theme.bgColors.WHITE_COLOR};
    }
    path {
      fill: ${({ theme, isSelected }): string =>
        isSelected ? theme.bgColors.WHITE_COLOR : theme.bgColors.BLUE_116};
    }
  }
`;

export const DataTabGridViewRightSecStyled = styled.div`
  display: flex;
  align-items: center;

  .action-title {
    font-family: OpenSans;
    color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
    font-weight: normal;
    margin-right: 9px;
  }

  .clear-search-text-icon {
    cursor: pointer;
    display: flex;
    align-items: baseline;
  }
`;
