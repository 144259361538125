import { useCallback } from "react";

//        <---Components-->
import Flex from "../flex";
import LinkButton from "../linkbutton";
//        <---Components-->

//        <---SVGS--->
import { redirectCircledIcon } from "../../svgs";
//        <---SVGS--->

//        <---Types--->
import { LineagesectitlewithiconProps } from "./lineagesectitlewithicon.types";
//        <---Types--->

//        <---Utils--->
import { getLineageDetailpageUrl } from "../../utils";
//        <---Utils--->

function LineageSectionTitleWithIcon(
  props: LineagesectitlewithiconProps
): JSX.Element {
  const { title = "Lineage", isRefView, queryParams, lineageExists } = props;

  const onClick = useCallback(() => {
    const url = getLineageDetailpageUrl(queryParams);

    window.open(url);
  }, [props]);

  return (
    <Flex>
      {title}
      <LinkButton
        marginLeft="8px"
        height={isRefView ? "20px" : "33px"}
        onClick={onClick}
        disabled={!lineageExists}
        tooltipProps={{
          title: !lineageExists && "Lineage doesn't exist",
          placement: "right",
        }}
      >
        {redirectCircledIcon(
          isRefView ? "20" : undefined,
          isRefView ? "20" : undefined
        )}
      </LinkButton>
    </Flex>
  );
}

export default LineageSectionTitleWithIcon;
