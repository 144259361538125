import styled from "styled-components";
import { hexToRgbA } from "../../../../../../utils";

export const ChatBotSectionStyled = styled.div<{ isCollapsed: boolean }>`
  width: 100%;

  .fixed-tabs-sec {
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    padding: 6px 20px;
    position: fixed;
    left: ${(props): string => (props.isCollapsed ? "43px" : "190px")};
    width: ${(props): string =>
      props.isCollapsed ? "calc(100vw - 55px)" : "calc(100vw - 202px)"};
    top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    box-shadow: 0px 4px 8px 0px
      ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};
  }

  .fixed-tabs-sec-trans {
    top: 45px;
    transition: top 0.3s;
  }

  .tabs-sec {
    transition: bottom 0.3s;
  }
`;

export const PageMenuStyled = styled.div<{ isScrolled: boolean }>`
  width: 100%;
  background: ${({ theme }): string => theme.bgColors.GREY_19};
  padding-right: 12.5px;
  display: flex;
  align-items: center;
  margin-bottom: ${({ isScrolled }): string => (isScrolled ? "0px" : "16px")};

  .tabs {
    padding-bottom: 0px;
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
    flex: 1;

    .custom-tab-btn {
      &:after {
        top: 37px;
      }
    }
  }

  .action-bar {
    .ant-btn {
      column-gap: 7px;

      &:not(.ant-btn-link) svg path {
        fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
      }
    }
  }
`;

export const PageContentStyled = styled.div`
  width: 100%;
`;
