export const similarDataAssetsBlankSlate = (
  <svg width="619" height="219" viewBox="0 0 1920 628" fill="none">
    <path
      d="M924.284 1.08228H17.3165C8.35046 1.08228 1.08203 8.35071 1.08203 17.3168V610.417C1.08203 619.383 8.35046 626.652 17.3165 626.652H924.284C933.25 626.652 940.518 619.383 940.518 610.417V17.3168C940.518 8.35071 933.25 1.08228 924.284 1.08228Z"
      stroke="#E1E1E1"
    />
    <path
      d="M1902.68 1.08228H995.715C986.749 1.08228 979.48 8.35071 979.48 17.3168V610.417C979.48 619.383 986.749 626.652 995.715 626.652H1902.68C1911.65 626.652 1918.92 619.383 1918.92 610.417V17.3168C1918.92 8.35071 1911.65 1.08228 1902.68 1.08228Z"
      stroke="#E1E1E1"
    />
    <path
      d="M240.271 71.4319H69.2674C60.8991 71.4319 54.1152 78.2158 54.1152 86.5841C54.1152 94.9524 60.8991 101.736 69.2674 101.736H240.271C248.639 101.736 255.423 94.9524 255.423 86.5841C255.423 78.2158 248.639 71.4319 240.271 71.4319Z"
      fill="#F0F0F0"
    />
    <path
      d="M846.359 329.019H69.2674C60.8991 329.019 54.1152 335.803 54.1152 344.171C54.1152 352.54 60.8991 359.323 69.2674 359.323H846.359C854.727 359.323 861.511 352.54 861.511 344.171C861.511 335.803 854.727 329.019 846.359 329.019Z"
      fill="#F0F0F0"
    />
    <path
      d="M348.501 378.805H69.2674C60.8991 378.805 54.1152 385.589 54.1152 393.957C54.1152 402.325 60.8991 409.109 69.2674 409.109H348.501C356.869 409.109 363.653 402.325 363.653 393.957C363.653 385.589 356.869 378.805 348.501 378.805Z"
      fill="#F0F0F0"
    />
    <path
      d="M846.359 225.118H69.2674C60.8991 225.118 54.1152 231.902 54.1152 240.271C54.1152 248.639 60.8991 255.423 69.2674 255.423H846.359C854.727 255.423 861.511 248.639 861.511 240.271C861.511 231.902 854.727 225.118 846.359 225.118Z"
      fill="#F0F0F0"
    />
    <path
      d="M424.262 188.321C436.217 188.321 445.908 178.629 445.908 166.675C445.908 154.72 436.217 145.029 424.262 145.029C412.307 145.029 402.616 154.72 402.616 166.675C402.616 178.629 412.307 188.321 424.262 188.321Z"
      fill="#F0F0F0"
    />
    <path
      d="M424.262 187.238C435.619 187.238 444.826 178.032 444.826 166.675C444.826 155.318 435.619 146.111 424.262 146.111C412.905 146.111 403.698 155.318 403.698 166.675C403.698 178.032 412.905 187.238 424.262 187.238Z"
      stroke="#F0F0F0"
    />
    <path
      d="M87.6665 201.308C106.196 201.308 121.218 186.286 121.218 167.756C121.218 149.227 106.196 134.205 87.6665 134.205C69.1367 134.205 54.1152 149.227 54.1152 167.756C54.1152 186.286 69.1367 201.308 87.6665 201.308Z"
      fill="#DCDCDC"
    />
    <path
      d="M611.499 142.864H485.952C472.205 142.864 461.06 154.008 461.06 167.756C461.06 181.504 472.205 192.649 485.952 192.649H611.499C625.247 192.649 636.392 181.504 636.392 167.756C636.392 154.008 625.247 142.864 611.499 142.864Z"
      fill="#F0F0F0"
    />
    <path
      d="M348.501 153.687H155.851C147.483 153.687 140.699 158.306 140.699 166.674C140.699 175.042 147.483 179.662 155.851 179.662H348.501C356.869 179.662 363.653 175.042 363.653 166.674C363.653 158.306 356.869 153.687 348.501 153.687Z"
      fill="#DCDCDC"
    />
    <path
      d="M1402.66 188.32C1414.62 188.32 1424.31 178.629 1424.31 166.674C1424.31 154.719 1414.62 145.028 1402.66 145.028C1390.71 145.028 1381.01 154.719 1381.01 166.674C1381.01 178.629 1390.71 188.32 1402.66 188.32Z"
      fill="#F0F0F0"
    />
    <path
      d="M1402.66 187.238C1414.02 187.238 1423.22 178.031 1423.22 166.674C1423.22 155.317 1414.02 146.111 1402.66 146.111C1391.3 146.111 1382.1 155.317 1382.1 166.674C1382.1 178.031 1391.3 187.238 1402.66 187.238Z"
      stroke="#F0F0F0"
    />
    <path
      d="M1066.06 201.308C1084.59 201.308 1099.62 186.286 1099.62 167.756C1099.62 149.227 1084.59 134.205 1066.06 134.205C1047.54 134.205 1032.51 149.227 1032.51 167.756C1032.51 186.286 1047.54 201.308 1066.06 201.308Z"
      fill="#DCDCDC"
    />
    <path
      d="M1589.9 142.864H1464.35C1450.6 142.864 1439.46 154.008 1439.46 167.756C1439.46 181.504 1450.6 192.649 1464.35 192.649H1589.9C1603.65 192.649 1614.79 181.504 1614.79 167.756C1614.79 154.008 1603.65 142.864 1589.9 142.864Z"
      fill="#F0F0F0"
    />
    <path
      d="M1326.9 153.687H1134.25C1125.88 153.687 1119.1 158.306 1119.1 166.674C1119.1 175.042 1125.88 179.662 1134.25 179.662H1326.9C1335.27 179.662 1342.05 175.042 1342.05 166.674C1342.05 158.306 1335.27 153.687 1326.9 153.687Z"
      fill="#DCDCDC"
    />
    <path
      d="M1824.76 225.118H1047.67C1039.3 225.118 1032.51 231.902 1032.51 240.271C1032.51 248.639 1039.3 255.423 1047.67 255.423H1824.76C1833.13 255.423 1839.91 248.639 1839.91 240.271C1839.91 231.902 1833.13 225.118 1824.76 225.118Z"
      fill="#F0F0F0"
    />
    <path
      d="M1824.76 329.019H1047.67C1039.3 329.019 1032.51 335.803 1032.51 344.171C1032.51 352.54 1039.3 359.323 1047.67 359.323H1824.76C1833.13 359.323 1839.91 352.54 1839.91 344.171C1839.91 335.803 1833.13 329.019 1824.76 329.019Z"
      fill="#F0F0F0"
    />
    <path
      d="M1326.9 378.805H1047.67C1039.3 378.805 1032.51 385.589 1032.51 393.957C1032.51 402.325 1039.3 409.109 1047.67 409.109H1326.9C1335.27 409.109 1342.05 402.325 1342.05 393.957C1342.05 385.589 1335.27 378.805 1326.9 378.805Z"
      fill="#F0F0F0"
    />
    <path
      d="M1218.67 71.4319H1047.67C1039.3 71.4319 1032.51 78.2158 1032.51 86.5841C1032.51 94.9524 1039.3 101.736 1047.67 101.736H1218.67C1227.04 101.736 1233.82 94.9524 1233.82 86.5841C1233.82 78.2158 1227.04 71.4319 1218.67 71.4319Z"
      fill="#F0F0F0"
    />
    <path
      d="M350.666 521.668H71.4325C63.0641 521.668 56.2803 528.452 56.2803 536.821C56.2803 545.189 63.0641 551.973 71.4325 551.973H350.666C359.034 551.973 365.818 545.189 365.818 536.821C365.818 528.452 359.034 521.668 350.666 521.668Z"
      fill="#F0F0F0"
    />
    <path
      d="M740.294 521.668H461.06C452.692 521.668 445.908 528.452 445.908 536.821C445.908 545.189 452.692 551.973 461.06 551.973H740.294C748.662 551.973 755.446 545.189 755.446 536.821C755.446 528.452 748.662 521.668 740.294 521.668Z"
      fill="#F0F0F0"
    />
    <path
      d="M264.082 474.047H71.4325C63.0641 474.047 56.2803 478.667 56.2803 487.035C56.2803 495.403 63.0641 500.023 71.4325 500.023H264.082C272.45 500.023 279.234 495.403 279.234 487.035C279.234 478.667 272.45 474.047 264.082 474.047Z"
      fill="#DCDCDC"
    />
    <path
      d="M653.71 474.047H461.06C452.692 474.047 445.908 478.667 445.908 487.035C445.908 495.403 452.692 500.023 461.06 500.023H653.71C662.078 500.023 668.862 495.403 668.862 487.035C668.862 478.667 662.078 474.047 653.71 474.047Z"
      fill="#DCDCDC"
    />
    <path
      d="M1326.9 521.668H1047.67C1039.3 521.668 1032.51 528.452 1032.51 536.821C1032.51 545.189 1039.3 551.973 1047.67 551.973H1326.9C1335.27 551.973 1342.05 545.189 1342.05 536.821C1342.05 528.452 1335.27 521.668 1326.9 521.668Z"
      fill="#F0F0F0"
    />
    <path
      d="M1716.53 521.668H1437.29C1428.93 521.668 1422.14 528.452 1422.14 536.821C1422.14 545.189 1428.93 551.973 1437.29 551.973H1716.53C1724.9 551.973 1731.68 545.189 1731.68 536.821C1731.68 528.452 1724.9 521.668 1716.53 521.668Z"
      fill="#F0F0F0"
    />
    <path
      d="M1240.32 474.047H1047.67C1039.3 474.047 1032.51 478.667 1032.51 487.035C1032.51 495.403 1039.3 500.023 1047.67 500.023H1240.32C1248.68 500.023 1255.47 495.403 1255.47 487.035C1255.47 478.667 1248.68 474.047 1240.32 474.047Z"
      fill="#DCDCDC"
    />
    <path
      d="M1629.94 474.047H1437.29C1428.93 474.047 1422.14 478.667 1422.14 487.035C1422.14 495.403 1428.93 500.023 1437.29 500.023H1629.94C1638.31 500.023 1645.1 495.403 1645.1 487.035C1645.1 478.667 1638.31 474.047 1629.94 474.047Z"
      fill="#DCDCDC"
    />
  </svg>
);
