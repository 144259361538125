import { SelectieldType } from "../components/formfields/selectfield/selectfield.types";
import { SectionRowProps } from "../components/sectionrow";

export function nodesDescriptionEditableViewValueUpdation(
  item: SectionRowProps,
  values: any,
  options?: SelectieldType["options"]
): SectionRowProps {
  switch (item?.type) {
    case "textarea":
    case "input":
      return {
        ...item,
        value: item?.isEditable
          ? values?.[item?.title] ?? item?.value
          : item?.value,
      };

    case "select": {
      const findedOpt = (item?.options || options)?.find(
        (opt) => opt?.value === values?.[item?.title]
      );

      return {
        ...item,
        value: findedOpt?.labelText || findedOpt?.label || "",
        selectedValues: values?.[item?.title] ? [values?.[item?.title]] : [],
      };
    }
    default:
      return item;
  }
}
