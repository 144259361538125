import { useFormContext } from "react-hook-form";
import { useCallback, useState } from "react";

import { TrainbotFeedbackformSubmitSecProps } from "./trainborfeedbackformsubmitsec.types";
import { TrainbotFeedbackFormResponseForm } from "../../trainbotfeedbackform";

import StateHandler from "../../../../../../components/statehandler";
import { FormItemStyled, FormStyled } from "../../../../../../components/form";

import { Button } from "../../../../../../components";
import { checkIsChatPage } from "../../../../../../utils";
import LinkButton from "../../../../../../components/linkbutton/linkbutton";
import Flex from "../../../../../../components/flex";
import ConfirmationPopover from "../../../../../../components/confirmationpopover";

const isChatPage = checkIsChatPage();

const TrainbotFeedbackformSubmitSec = (
  props: TrainbotFeedbackformSubmitSecProps
): JSX.Element => {
  const {
    queryItem,
    handlers,
    toggleRefreshQueryTooltip,
    isEditMode,
    toggleFeedbackSuccessMsg,
    onCrossClick,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { onSaveFeedback, onRemoveFeedback } = handlers || {};

  const { id = "", user_feedback: userFeedback } = queryItem || {};
  const { response } = userFeedback || {};

  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = useFormContext<TrainbotFeedbackFormResponseForm>();

  const onSubmit = useCallback(
    (values: TrainbotFeedbackFormResponseForm) => {
      const isLike = values?.response === "like";

      setIsLoading(true);
      onSaveFeedback?.(
        id,
        {
          ...(isLike
            ? { response: values?.response, comment: values?.comment }
            : { ...values }),
        },
        (): void => {
          setIsLoading(false);
          onCrossClick?.();
          toggleFeedbackSuccessMsg?.();
          !isLike &&
            ["WQ", "WD", "WC"]?.includes(values?.reason || "") &&
            toggleRefreshQueryTooltip?.();
        }
      );
    },
    [id, onSaveFeedback, toggleRefreshQueryTooltip, toggleFeedbackSuccessMsg]
  );

  const onRemoveFeedbackClick = useCallback(() => {
    setIsLoading(true);
    onRemoveFeedback([id], (): void => {
      setIsLoading(false);
      onCrossClick?.();
      toggleFeedbackSuccessMsg?.("Feedback removed successfully!");
    });
  }, [id, onRemoveFeedback, toggleFeedbackSuccessMsg]);

  return (
    <StateHandler isFetching={isLoading} isModal error={false}>
      <FormStyled onFinish={handleSubmit(onSubmit) as any}>
        <FormItemStyled
          className={isChatPage ? "caddi-form-actions-sec" : "form-actions-sec"}
        >
          <Flex
            justifyContent={
              response === "dislike" ? "space-between" : "flex-end"
            }
            width="100%"
          >
            {response === "dislike" && (
              <ConfirmationPopover
                heading="Remove Feedback"
                desc="This action cannot be reversed. Are you sure?"
                placement="topLeft"
                okText="Yes"
                cancelText="No"
                onOk={onRemoveFeedbackClick}
                width="309px"
              >
                <LinkButton id="danger">Remove Feedback</LinkButton>
              </ConfirmationPopover>
            )}

            <Flex>
              <Button id="cancel" width="74px" onClick={onCrossClick}>
                Cancel
              </Button>
              <Button
                id="primary"
                width="74px"
                marginLeft="8px"
                htmlType="submit"
                disabled={isEditMode ? !(isValid && isDirty) : !isValid}
                tooltipProps={{
                  title: isEditMode
                    ? !isDirty
                      ? "You haven't made any change to save"
                      : !isValid
                      ? "Please fill all the required fields"
                      : ""
                    : !isValid
                    ? "Please fill all the required fields"
                    : "",
                  placement: "right",
                  overlayStyle: { minWidth: "fit-content" },
                }}
              >
                Submit Feedback
              </Button>
            </Flex>
          </Flex>
        </FormItemStyled>
      </FormStyled>
    </StateHandler>
  );
};

export default TrainbotFeedbackformSubmitSec;
