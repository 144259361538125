import { SelectFieldOption } from "../../../formfields/selectfield/selectfield.types";
import {
  SubPanelOptionStyled,
  SubPanelOptionWrapperStyled,
  SubPanelWrapperStyled,
} from "./subpanelrenderer.styles";

const SubPanelRenderer = ({
  isSubPanelOpen,
  subpanelHeading,
  selectedOptionSubpanelOptions,
  onSubOptionSelect,
  currentValues,
}: {
  isSubPanelOpen: boolean;
  subpanelHeading: string;
  onSubOptionSelect: (option: string) => () => void;
  currentValues: string[];
  selectedOptionSubpanelOptions?: SelectFieldOption[];
}): JSX.Element => {
  return (
    <SubPanelWrapperStyled isSubPanelOpen={!!isSubPanelOpen}>
      <p className="sub-panel-heading">{subpanelHeading}</p>
      <SubPanelOptionWrapperStyled>
        {selectedOptionSubpanelOptions?.map((option: SelectFieldOption) => {
          const { label = "", value = "" } = option || {};

          return (
            <SubPanelOptionStyled
              key={`${value} `}
              onClick={onSubOptionSelect(value)}
              isSelected={currentValues?.includes(value)}
            >
              {label}
            </SubPanelOptionStyled>
          );
        })}
      </SubPanelOptionWrapperStyled>
    </SubPanelWrapperStyled>
  );
};

export default SubPanelRenderer;
