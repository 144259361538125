export const COLUMN_SECTION_TITLES = {
  information: {
    description: "Definition",
    meta_score: "Meta Score",
    data_type: "Data Type",
    example: "Example",
  },
  classification: {
    entity: "Entity",
    column_type: "Column Type",
    column_sub_type: "Column Sub-type",
    is_cde: "Is CDE",
    functional_area: "Data Domain",
  },
  profiling_information: {
    count: "Count",
    min_max: "Min-Max",
    visual_distribution: "Visual Distribution",
  },
  quality: {
    overall_score: "Overall Score",
    last_updated: "Last Updated",
  },
  data_quality_rules: {
    auto_set: "Auto set",
    validity: "Validity",
  },
};

export const COLUMN_SECTIONS = {
  classification: "Attributes",
  profiling_information: "Profiling Information",
  lineage: "Lineage",
  quality: "Quality",
  data_quality_rules: "Data Quality Rules",
};
