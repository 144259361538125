export const convertToHoursMinutesAndSeconds = (time: number): string => {
  if (typeof time !== "number" || Number.isNaN(time) || time < 0) {
    return "";
  }

  const totalSeconds = Math.round(time * 60);

  if (totalSeconds < 60) {
    return `${totalSeconds} sec`;
  }

  const totalMinutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;

  if (totalMinutes < 60) {
    return `${totalMinutes} min`;
  }

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours} hr ${minutes > 0 ? ` ${minutes} min ` : ""} `;
};
