import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";

export const SharedWithItemStyled = styled.div`
  display: grid;
  grid-template-columns: 32.8px auto 132px;
  align-items: center;
  gap: 11.2px;

  .icon {
    width: 32.8px;
    height: 32.8px;
    border-radius: 50%;
    background: ${({ theme }): string =>
      hexToRgbA(theme.textColors.BLUE_116, 0.1)};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 13.68px;
      height: 14.96px;

      path {
        fill: ${({ theme }): string => theme.textColors.BLUE_116};
      }
    }
  }

  .details {
    font-size: 14px;
    line-height: 22px;

    .name {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    }

    .email {
      color: ${({ theme }): string =>
        hexToRgbA(theme.textColors.BLACK_16, 0.7)};
    }
  }

  .role {
    display: flex;
    justify-content: flex-end;
    text-align: right;

    .owner {
      color: ${({ theme }): string =>
        hexToRgbA(theme.textColors.BLACK_16, 0.4)};
      font-style: italic;
      font-size: 14px;
      margin-right: 3px;
    }

    .actions-container {
      padding-bottom: 0px;
      background: none;
      width: auto;
      font-family: OpenSans;
      font-weight: normal;
      font-size: 14px;

      .role-container {
        display: flex;
        align-items: center;
        gap: 6.8px;
        color: ${({ theme }): string => theme.textColors.BLACK_16};

        svg {
          height: 6px;

          path {
            stroke: ${({ theme }): string => theme.borderColors.BLACK_16};
          }
        }
      }
    }
  }
`;
