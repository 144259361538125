import { NodeType } from "../app.types";
import { columnIcon, databaseTableIcon, termIcon } from "../svgs";

export function getNodeIcon(
  type: NodeType,
  width?: string,
  height?: string
): JSX.Element {
  switch (type) {
    case "TBL":
      return databaseTableIcon(width, height);
    case "TRM":
      return termIcon(width, height);
    case "COL":
      return columnIcon(width, height);
    default:
      return termIcon(width, height);
  }
}
