import { ELEMENT_IDS } from "../../constants";
import { getPageNode } from "../../pages/listingpage/listingpage.utils";
import { dateIcon, runAnalysisIcon } from "../../svgs";
import { getPostLoginData } from "../../utils";

import DropdownButton from "../dropdownbutton/dropdownbutton";
import { RunNodeProfilingButtonStyled } from "./runnodeprofilingbutton.styles";

import { RunNodeProfilingButtonProps } from "./runnodeprofilingbutton.types";

const {
  datdict_detpg_dq_ruldet_rn_rul_btn: DATDICT_DETPG_DQ_RULDET_RN_RUL_BTN,
  datdict_detpg_dq_ruldet_rn_rul_onl_btn: DATDICT_DETPG_DQ_RULDET_RN_RUL_ONL_BTN,
  datdict_detpg_dq_ruldet_rn_rul_ofl_btn: DATDICT_DETPG_DQ_RULDET_RN_RUL_OFL_BTN,
  datdict_detpg_dq_ruldet_rn_rul_btn_cont: DATDICT_DETPG_DQ_RULDET_RN_RUL_BTN_CONT,
} = ELEMENT_IDS;

const runOfflineBtn = dateIcon;
const runOnlineBtn = runAnalysisIcon("21", "21");

const RunNodeProfilingButton = (
  props: RunNodeProfilingButtonProps
): JSX.Element => {
  const {
    buttonTitle,
    buttonIcon,
    buttonType = "text",
    onClickRunProfileOnline,
    onClickRunProfileOffline,
    disabled,
    isNodeProfiled,
    nodeType,
    selectedRowsCount = 0,
  } = props;

  const postLoginData = getPostLoginData();
  const { user_info } = postLoginData;
  const { job_settings } = user_info || {};
  const {
    offline_profiling_limit: offlineProfLinmit = 0,
    online_profiling_limit: onlineProfLimit = 0,
    online_rules_limit: onlineRulesLimit = 0,
    offline_rules_limit: offlineRulesLimit = 0,
  } = job_settings || {};

  const nodeProfiledStatus = isNodeProfiled ?? true;

  const { isColumnPage, isTablePage, isRulePage } = getPageNode(nodeType);

  const onlineProfilingLimit = isRulePage ? onlineRulesLimit : onlineProfLimit;

  const offlineProfilingLimit = isRulePage
    ? offlineRulesLimit
    : offlineProfLinmit;

  const isColumnsPage = nodeType === "COL";

  const isRunOnlineDiable = selectedRowsCount > onlineProfilingLimit;

  const isRunOfflineDiable = selectedRowsCount > offlineProfilingLimit;

  const profilingButtonItems = [
    {
      key: "run-online",
      value: "Run Online",
      icon: runOnlineBtn,
      onClick: onClickRunProfileOnline,
      id: DATDICT_DETPG_DQ_RULDET_RN_RUL_ONL_BTN,
      customClass: "run-online-rule-btn",
      disabled: isRunOnlineDiable,
      tooltipProps: isRunOnlineDiable
        ? {
            title: `Supports a maximum of ${onlineProfilingLimit} records. Deselect some records to run online.`,
          }
        : undefined,
    },

    ...((!isColumnsPage && [
      {
        key: "run-offline",
        value: "Run Offline",
        icon: runOfflineBtn,
        onClick: onClickRunProfileOffline,
        id: DATDICT_DETPG_DQ_RULDET_RN_RUL_OFL_BTN,
        customClass: "run-offline-rule-btn",
        disabled: isRunOfflineDiable,
        tooltipProps: isRunOfflineDiable
          ? {
              title: `Supports a maximum of ${offlineProfilingLimit} records. Deselect some records to run offline.`,
            }
          : undefined,
      },
    ]) ||
      []),
  ];

  return (
    <RunNodeProfilingButtonStyled
      isTextButton={buttonType === "text"}
      isNodeProfiled={nodeProfiledStatus}
      id={DATDICT_DETPG_DQ_RULDET_RN_RUL_BTN_CONT}
    >
      <DropdownButton
        type={buttonType}
        width="150px"
        items={profilingButtonItems}
        overlay={<div />}
        drpdnBtnElementId={DATDICT_DETPG_DQ_RULDET_RN_RUL_BTN}
        dropdownLabelClassName="run-rule-btn"
        disabled={disabled}
        drpdnBtnId={nodeProfiledStatus ? "primary" : "secondary_1"}
      >
        {buttonIcon} {buttonTitle}
      </DropdownButton>
    </RunNodeProfilingButtonStyled>
  );
};

export default RunNodeProfilingButton;
