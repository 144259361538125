import { useMemo } from "react";
// <--Constants Start-->
import { API_CONFIG } from "../../constants/apiconfig";
// <--Constants End-->

// <--Parsers Start-->
import { getParsedOnboardingTutorial } from "../../parsers";

import {
  getParsedDvSumSearchResults,
  releaseOnboardingParser,
} from "../../parsers/helpandonboardingparser";
// <--Parsers End-->

// <--Service Start-->
import { useGetAll } from "../baseservice";
// <--Service End-->

// <--Types Start-->
import { ReturnTypeOfParsedOnboardingTutorial } from "../../parsers/parser.types";
import { CustomReactQueryHookReturnType } from "../services.types";

import {
  ReturnTypeOfDvSumHelpSearchResultsParser,
  ReturntypeOfReleaseOnboardingHelpParser,
} from "../../parsers/helpandonboardingparser/helpandonboardingparser.types";
// <--Types End-->

// <--Utils Start-->
import { getEnvVariables, getPostLoginData } from "../../utils";
import { getModuleLevelAccessInfo } from "../../utils/getModuleLevelAccessInfo";
// <--Utils End-->

const {
  get_tutorial_steps: getTutorialsStep,
  get_dvsum_search_results: getDvsumSearchResults,
} = API_CONFIG;

const {
  REACT_APP_AIR_TABLE_API_KEY,
  REACT_APP_AIR_TABLE_TUTORIAL_TABLE_ID,
  REACT_APP_AIR_TABLE_BASE_KEY,
  REACT_APP_AIR_TABLE_HELP_TABLE_ID,
} = getEnvVariables();

const headers = {
  Authorization: `Bearer ${REACT_APP_AIR_TABLE_API_KEY}`,
};

export const useGetOnboardingTutorial = (
  searchFormula: string
): CustomReactQueryHookReturnType<ReturnTypeOfParsedOnboardingTutorial> => {
  return useGetAll({
    apiConfig: getTutorialsStep,
    parser: getParsedOnboardingTutorial,
    headers: { ...headers },
    params: [
      REACT_APP_AIR_TABLE_BASE_KEY,
      REACT_APP_AIR_TABLE_TUTORIAL_TABLE_ID,
      searchFormula,
    ],
    isReqWithCustomHeader: true,
  });
};

export const useGetdvSumHelpSearchResults = (
  formula: string,
  enabled: boolean,
  isRecentUpdateFeaure?: boolean
): CustomReactQueryHookReturnType<ReturnTypeOfDvSumHelpSearchResultsParser> => {
  return useGetAll({
    apiConfig: getDvsumSearchResults,
    params: [
      REACT_APP_AIR_TABLE_BASE_KEY,
      REACT_APP_AIR_TABLE_HELP_TABLE_ID,
      formula,
    ],
    parser: (res) => getParsedDvSumSearchResults(res, isRecentUpdateFeaure),
    headers: { ...headers },
    isReqWithCustomHeader: true,
    options: { enabled },
  });
};

export const useGetReleaseOnboardingDetails = (): CustomReactQueryHookReturnType<ReturntypeOfReleaseOnboardingHelpParser> => {
  const {
    hasCatalogAccess,
    isDqEnabled,
    isChatEnabled,
  } = getModuleLevelAccessInfo();

  const postLoginData = getPostLoginData();

  const { user_info: userInfo } = postLoginData || {};

  const {
    catalog_access_type: catalogAccessType,
    glossary_access_type: glossaryAccessType,
    admin_access_type: adminAccessType,
  } = userInfo || {};

  const moduleNames = useMemo(
    () =>
      [
        hasCatalogAccess ? "DC" : "",
        isDqEnabled ? "DQ" : "",
        isChatEnabled ? "CADDI" : "",
      ]
        ?.filter(Boolean)
        ?.join(","),
    []
  );
  return useGetAll({
    apiConfig: getDvsumSearchResults,
    params: [
      REACT_APP_AIR_TABLE_BASE_KEY,
      REACT_APP_AIR_TABLE_HELP_TABLE_ID,
      `AND(is_release_onboarding_help=1, FIND(module_name, '${moduleNames}'), OR(FIND('C_${catalogAccessType}', elligible_roles), FIND('G_${glossaryAccessType}', elligible_roles), FIND('A_${adminAccessType}', elligible_roles)))`,
    ],
    parser: releaseOnboardingParser,
    headers: { ...headers },
    isReqWithCustomHeader: true,
    options: { refetchOnMount: false },
  });
};
