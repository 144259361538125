import { useState } from "react";
import { SecurityViewType } from "../../pages/dataimportpage/views/securityview/securityview.types";
import LinkButton from "../linkbutton/linkbutton";
import { DetailDomainInfoStyled } from "./detaildomaininfo.styles";

const listItemsToRender = 5;

export const DetailDomainInfo = (props: SecurityViewType): JSX.Element => {
  const { importDomains = [] } = props;

  const [toggleList, setToggleList] = useState({
    showMore: false,
  });

  return (
    <DetailDomainInfoStyled>
      {importDomains?.length === 1 ? (
        <>
          {importDomains?.map((item) => {
            const categoryArray = item?.CAT_NAME?.split(",") || [];

            return (
              <>
                <ul>
                  {categoryArray
                    ?.slice(
                      0,
                      toggleList?.showMore
                        ? categoryArray?.length
                        : listItemsToRender
                    )
                    .map((listItem, index) => (
                      <li key={`ddi-category-${listItem}-${index}`}>
                        {listItem}
                      </li>
                    ))}
                </ul>
                {categoryArray?.length > listItemsToRender && (
                  <LinkButton
                    onClick={(): void =>
                      setToggleList({
                        ...toggleList,
                        showMore: !toggleList?.showMore,
                      })
                    }
                    marginLeft="5px"
                  >
                    {toggleList?.showMore ? "Show Less" : "Show More"}
                  </LinkButton>
                )}
              </>
            );
          })}
        </>
      ) : (
        <>
          <ul>
            {importDomains
              ?.slice(
                0,
                toggleList?.showMore ? importDomains?.length : listItemsToRender
              )
              ?.map((item, index) => {
                const categoryArray = item?.CAT_NAME?.split(",") || [];
                return (
                  <li key={`ddi-domain-${index}`} className="list-item">
                    <span>{item?.DMN_NAME}</span>
                    <span className="icon">{">"}</span>
                    {categoryArray?.map(
                      (item, index) =>
                        `${item}${
                          index < categoryArray?.length - 1 ? ", " : ""
                        } `
                    )}
                  </li>
                );
              })}
          </ul>

          {importDomains?.length > listItemsToRender && (
            <LinkButton
              onClick={(): void =>
                setToggleList({
                  ...toggleList,
                  showMore: !toggleList?.showMore,
                })
              }
              marginLeft="25px"
            >
              {toggleList?.showMore ? "Show Less" : "Show More"}
            </LinkButton>
          )}
        </>
      )}
    </DetailDomainInfoStyled>
  );
};
