import styled from "styled-components";

// <--- Styled Start --->
import { FlexStyled } from "../../../../../flex";
// <--- Styled End --->

export const RuleDefinitionTooltipStyled = styled(FlexStyled)`
  .tooltip-item {
    .tooltip-item-heading {
      font-size: 13px;
      font-family: "OpenSansBold";
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    }
    .tooltip-item-description {
      font-family: "OpenSans";
      font-weight: normal;
      font-size: 13px;
    }
  }
`;
