import { Tooltip } from "antd";
import { infoIcon } from "../../svgs";
import { TooltipWithIconStyled } from "./tooltipwithicon.styles";
import { TooltipWithIconType } from "./tooltipwithicon.types";

function TooltipWithIcon(props: TooltipWithIconType): JSX.Element {
  const {
    icon,
    placement = "right",
    marginLeft,
    marginTop,
    width,
    minWidth,
    children,
  } = props;

  return (
    <TooltipWithIconStyled
      marginLeft={marginLeft}
      marginTop={marginTop}
      width={width}
      minWidth={minWidth}
    >
      <Tooltip
        {...props}
        placement={placement}
        overlayClassName="white-tooltip"
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
      >
        {!children && (icon || infoIcon)}
        {children}
      </Tooltip>
    </TooltipWithIconStyled>
  );
}

export default TooltipWithIcon;
