import styled from "styled-components";

export const VerticalDividerStyled = styled.div<{
  width?: string;
  height?: string;
  color?: string;
  marginLeft?: string;
  marginRight?: string;
}>`
  height: ${({ height }): string => height || "19px"};
  width: ${({ width }): string => width || "1px"};
  background: ${({ theme, color }): string =>
    color || theme.borderColors.LIGHT_GREY_15};
  ${({ marginLeft }): any => marginLeft && `margin-left: ${marginLeft};`};
  ${({ marginRight }): any => marginRight && `margin-right: ${marginRight};`};
`;

export const HorizontalDividerStyled = styled.div<{
  width?: string;
  height?: string;
  color?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}>`
  height: ${({ height }): string => height || "1px"};
  width: ${({ width }): string => width || "100%"};
  background: ${({ theme, color }): string =>
    color || theme.borderColors.LIGHT_GREY_15};
  ${({ marginTop }): any => marginTop && `margin-top: ${marginTop};`};
  ${({ marginLeft }): any => marginLeft && `margin-left: ${marginLeft};`};
  ${({ marginRight }): any => marginRight && `margin-right: ${marginRight};`};
  ${({ marginBottom }): any =>
    marginBottom && `margin-bottom: ${marginBottom};`};
`;
