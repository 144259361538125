import { SetStateAction, useCallback, useMemo, useState } from "react";

import StateHandler from "../../components/statehandler/statehandler";
import { rulesRefDrawer as rulesRefRendrerConfig } from "./rulerefdrawer.rendrer";

import SectionHeader from "../../components/sectionheader";

import { Section } from "../../components/section";

import {
  chevronRight,
  databaseIcon,
  dateIcon,
  redirectCircledIcon,
} from "../../svgs";

import { RuleRefDrawerStyled } from "./rulerefdrawer.styles";

import { useGetAppState, useGetAppType } from "../../customhooks";

import LinkButton from "../../components/linkbutton";
import { ELEMENT_IDS } from "../../constants";

import SuggestAnEditButton from "../../components/suggestaneditbutton/suggestaneditbutton";
import NodeRefPageHOC from "../../components/noderefpagehoc/noderefpagehoc";

import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";
import { NodeRefPageHOCEmbededProps } from "../../components/noderefpagehoc/noderefpagehoc.types";

import { TermRefDrawerProps } from "./rulerefdrawer.types";
import StyledLink from "../../components/styledlink/styledlink";

import {
  getRuleDetailPageUrl,
  getSourceDetailPageUrl,
  getTableDetailPageUrl,
} from "../../utils";
import { RULE_SECTIONS } from "../../constants/labelconstants/ruleconfig";

import { ruleRefBlankSlate } from "../../blankslates/rulerefblankslate";
import { ruleDetailPageTabsValues } from "../../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.types";

import { useGetRuleDocForRefPage } from "../../api/ruleservice";
import { SingleTagRendrer } from "../../components/prominenttagslist/prominenttagslist";
import Flex from "../../components/flex";

const titleSecProps = {
  className: "col-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const {
  busgls_detpg_ref_ttl: BUSGLS_DETPG_REF_TTL,
  busgls_detpg_ref_goto_btn: BUSGLS_DETPG_REF_GOTO_BTN,
} = ELEMENT_IDS;

function DrawerContent(
  props: NodeRefPageHOCEmbededProps & {
    id?: string;
  }
): JSX.Element {
  const { drawer } = useGetAppState();

  const { isExtOrDesktop } = useGetAppType();

  const { id, isDrawer, editViewComp, onClickEdit } = props;

  const { drawerProps = {} } = drawer || {};

  const { id: drawerPropsId } = (drawerProps || {}) as TermRefDrawerProps;

  const nodeId = id || drawerPropsId;

  const { parsedData, isLoading, error } = useGetRuleDocForRefPage(
    nodeId,
    "RLS",
    "PUB"
  );

  const toUrl = useMemo(() => {
    const url = getRuleDetailPageUrl(nodeId, ruleDetailPageTabsValues?.data);

    return url;
  }, [drawerProps, nodeId]);

  const ruleRefRendrer = useMemo(() => {
    return rulesRefRendrerConfig;
  }, []);

  const isRuleAlerting = parsedData?.ruleAlertStatus === "ALE";

  const { sourceName, sourceId, tableId, tableName } = parsedData || {};

  const { sourceUrl, tableUrl } = useMemo(() => {
    return {
      sourceUrl: getSourceDetailPageUrl(String(sourceId)),
      tableUrl: getTableDetailPageUrl(String(tableId)),
    };
  }, [parsedData, sourceId, tableId]);

  const source = useMemo(
    () => [
      {
        icon: databaseIcon,
        title: sourceName,
        link: sourceUrl,
      },
      {
        icon: (
          <CoreNodesCircleIcon
            nodeType="TBL"
            width="16px"
            height="16px"
            fontSize="12px"
          />
        ),
        title: tableName,
        link: tableUrl,
      },
    ],
    [sourceName, tableName, sourceUrl, tableUrl]
  );

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={ruleRefBlankSlate}
    >
      <RuleRefDrawerStyled>
        <div className="rule-ref-header">
          <div className="rule-header-breadcrums">
            <Flex alignItems="center">
              {source.map((item, index) => (
                <div key={`${item?.title}-${index}`} className="header-item">
                  <div className="item-icon">{item?.icon}</div>

                  <StyledLink
                    to={item.link}
                    target={isExtOrDesktop ? "_blank" : "_self"}
                    title={item?.title}
                    cutomClassName="item-title"
                  >
                    <div title={item?.title} className="item-title">
                      {item?.title}
                    </div>
                  </StyledLink>

                  {index + 1 < source?.length && (
                    <div className="right-icon"> {chevronRight}</div>
                  )}
                </div>
              ))}
            </Flex>
          </div>
          <div className="header_row_2" id={BUSGLS_DETPG_REF_TTL}>
            <SectionHeader
              icon={<CoreNodesCircleIcon nodeType="RLS" />}
              title={parsedData?.ruleName}
              subTitle=""
              rightIcon={
                <>
                  {parsedData?.isRuleScheduled && (
                    <div className="scheduled-icon">{dateIcon}</div>
                  )}

                  <LinkButton height="33px" disabled={!!editViewComp}>
                    <StyledLink
                      to={toUrl}
                      target={isExtOrDesktop ? "_blank" : "_self"}
                      cutomClassName="term-redirect-icon"
                      elementId={BUSGLS_DETPG_REF_GOTO_BTN}
                    >
                      {redirectCircledIcon("28px", "28px")}
                    </StyledLink>
                  </LinkButton>
                </>
              }
              nodePriority={parsedData?.rulePriority}
              marginBottom="8px"
            />

            {!!parsedData?.ruleAlertStatus && (
              <SingleTagRendrer
                tag={{
                  name: isRuleAlerting ? "Alerting" : "Healthy",
                  type: isRuleAlerting ? "LRD" : "LGR",
                }}
                isBoldView
              />
            )}
          </div>
        </div>
        {editViewComp || (
          <>
            <div className="rule-ref-section definition-sec">
              <Section
                secRowData={parsedData?.description?.map((item) => ({
                  ...item,
                  customJSX: ruleRefRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>
            <div className="rule-ref-section  ">
              <Section
                secTitle={{
                  title: RULE_SECTIONS?.status,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.status?.map((item) => ({
                  ...item,
                  customJSX: ruleRefRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>

            <div className="rule-ref-section  ">
              <Section
                secTitle={{
                  title: RULE_SECTIONS?.definition,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.definition?.map((item) => ({
                  ...item,
                  customJSX: ruleRefRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>
          </>
        )}
      </RuleRefDrawerStyled>
    </StateHandler>
  );
}

const RuleRefDrawer = (props: {
  id?: string;
  isDrawer?: boolean;
  setShowParentGoBack?: React.Dispatch<SetStateAction<boolean>>;
  onClickSuggest?: () => void;
}): JSX.Element => {
  return (
    <NodeRefPageHOC
      isDrawer={props?.isDrawer}
      setShowParentGoBack={props?.setShowParentGoBack}
    >
      <DrawerContent {...props} />
    </NodeRefPageHOC>
  );
};

export default RuleRefDrawer;
