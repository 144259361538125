// <--- Import Statements Start --->
import { Tooltip } from "antd";
// <--- Import Statements End --->

// <--- Constants Start --->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
// <--- Constants End --->

// <--- Styles Start --->
import { ActionButtonStyled } from "../detailpagesdatatab/views/views.components/gridviewrightsection/gridviewrightsection.styles";
import { GridToggleButtonStyled } from "./gridtogglebutton.styles";
// <--- Styles End --->

// <--- Types Start --->
import { GridToggleButtonProps } from "./gridtogglebutton.types";
// <--- Types End --->

const GridToggleButton = (props: GridToggleButtonProps): JSX.Element => {
  const {
    content = [],
    selectedItem = "",
    onChangeView,
    isDisableButtons = false,
  } = props;

  return (
    <GridToggleButtonStyled>
      <div className="action-title">View:</div>
      <>
        {content?.map((item) => (
          <Tooltip
            key={item?.id}
            title={item?.tooltip || ""}
            overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
          >
            <ActionButtonStyled
              type="primary"
              onClick={(): void => onChangeView?.(item?.id)}
              isSelected={!isDisableButtons && item?.id === selectedItem}
              disabled={item?.disabled || isDisableButtons}
            >
              {item?.icon}
            </ActionButtonStyled>
          </Tooltip>
        ))}
      </>
    </GridToggleButtonStyled>
  );
};

export default GridToggleButton;
