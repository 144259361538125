// <--- Import Statements Start --->
import { AxiosResponse } from "axios";
// <--- Import Statements End --->

// <--- Types Start --->
import {
  DetailsGridResponseType,
  ReturnTypeOfDetailsGridParser,
  ReturnTypeOfSummaryTilesParser,
  SummaryTilesResponse,
  DataQualityHistoryChartResponse,
  DQRuleDetailsResponseType,
  ReturnTypeOfDataQualityHistoryChartParser,
  ReturnTypeOfDQRuleDetailsParser,
  DataQualityRuleHistoryBarChartResponse,
  ReturnTypeOfRuleHistoryBarChartParser,
  DQSummaryParsedDataType,
  DQRuleDefinitionConfig,
  ListTypeDQRuleType,
  RuleExecutionResults,
  DataQualityDownloadRuleResponse,
  ReturnTypeOfDownloadRuleParser,
  RuleExpUrlsResponse,
} from "./dataqualityparser.types";

import { DQRuleCategories } from "..";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
// <--- Types End --->

// <--- Utils Start --->
import { parseDQActionDetails } from "./dataqualityparser.utils";
import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";

import {
  dqActionStatusMapping,
  dqRuleStatusMapping,
  jsonParse,
  getObjectKeys,
} from "../../utils";
// <--- Utils End --->

export function getParsedSummaryTiles({
  data,
}: AxiosResponse<SummaryTilesResponse>): ReturnTypeOfSummaryTilesParser[] {
  const summaryTilesIds = getObjectKeys(data) as Array<DQRuleCategories>;

  const parsedData: ReturnTypeOfSummaryTilesParser[] = summaryTilesIds?.map(
    (id) => {
      const {
        NAME = "",
        SCORE = 0,
        DRIFT_VAR = 0,
        ALERT_CNT = 0,
        ORDER = 0,
        RULE_CNT = 0,
      } = data?.[id] || {};

      return {
        id,
        order: ORDER,
        heading: {
          text: `${Math.round(SCORE)}`,
          trend: DRIFT_VAR > 0 ? "UP" : DRIFT_VAR < 0 ? "DOWN" : "CENTER",
          trendTooltip: DRIFT_VAR
            ? `Change: ${Math.abs(Math.round(DRIFT_VAR))}%`
            : "No change",
        },
        info: {
          alertCount: ALERT_CNT,
          ruleCount: RULE_CNT,
          type: NAME,
        },
      };
    }
  );

  const sortedData = [...parsedData]?.sort((a, b) => a?.order - b?.order);

  return sortedData;
}

export function getParsedDetailsGridData({
  data,
}: AxiosResponse<DetailsGridResponseType[]>): ReturnTypeOfDetailsGridParser[] {
  return (
    data?.map((item) => {
      return {
        appliedTo: {
          nodeId: `${item?.NODE_ID}` || "",
          nodeType: item?.NODE_TYPE || "TBL",
          text: item?.NODE_NAME,
        },
        ruleType: { ruleId: item?.RULE_TYPE_ID, name: item?.RULE_TYPE || "" },
        ruleCategory: {
          categoryId: item?.RULE_CAT_ID,
          categoryName: item?.RULE_CAT || "",
        },
        description: item?.RULE_DESC || "",
        dqScore: {
          statusId: item?.RULE_EXEC_STATUS_ID,
          status: item?.RULE_EXEC_STATUS || "",
          score: item?.RULE_SCORE,
          trend: item?.RULE_PCT_SCORE_CHANGE,
        },
        alertType: {
          name: item?.RULE_ALERT,
          id: item?.RULE_ALERT_ID,
          type: "RED",
        },
        actionStatus: {
          ...dqActionStatusMapping(
            item?.RULE_ACTION_STATUS_ID,
            item?.RULE_ACTION_STATUS
          ),
          actionStatusDetails: [
            ...parseDQActionDetails(item?.RULE_ACTION_DETAILS),
          ],
        },
        ruleStatus: {
          ...dqRuleStatusMapping(item?.RULE_STATUS_ID, item?.RULE_STATUS),
        },
        hasAlerts: item?.IS_RULE_HAS_ALERTS || false,
      };
    }) || []
  );
}

export function parsedDataQualityHistoryChartData({
  data = [],
}: AxiosResponse<DataQualityHistoryChartResponse>): ReturnTypeOfDataQualityHistoryChartParser {
  return [
    {
      name: "DQ Score",
      color: "#ff980e",
      strokeWidth: 1.5,
      curve: "curveMonotoneX",
      data: data?.map((el) => {
        const dqSummary: DQSummaryParsedDataType =
          jsonParse(el?.DQ_SUMMARY, true) || {};
        return {
          x: new Date(el?.LAST_SCANNED_ON).getTime(),
          y: Math.round(dqSummary?.SCORE || 0),
        };
      }),
    },
  ];
}

export function parsedDataOfRuleDetails({
  data = [],
}: AxiosResponse<
  DQRuleDetailsResponseType[]
>): ReturnTypeOfDQRuleDetailsParser {
  const dataDrivenTabs = data?.map((item) => ({
    key: item?.RULE_TYPE_ID || "",
    value: item?.RULE_TYPE || "",
  }));

  return {
    tabs: [{ key: "ALL", value: "All" }, ...dataDrivenTabs],
    details: data?.reduce((prev, next) => {
      const {
        RULE_CONFIG = "",
        RULE_TYPE_ID,
        RULE_TYPE = "",
        RULE_EXEC_STATUS_ID,
        RULE_SCORE = "",
        RULE_EXEC_STATUS,
        RULE_PCT_SCORE_CHANGE = "",
        RULE_ID = "",
        RULE_HIST_ID = "",
        RULE_ACTION_STATUS = "",
        RULE_ACTION_STATUS_ID = "",
        RULE_ACTION_DETAILS = "",
        RULE_ALERT = "",
        RULE_EXP_URLS = "",
      } = next || {};

      const ruleDetailsUrls = jsonParse(
        RULE_EXP_URLS,
        true
      ) as RuleExpUrlsResponse;

      const parsedRuleConfig = jsonParse(
        RULE_CONFIG,
        true
      ) as DQRuleDefinitionConfig;

      const { DQ_TH, DRIFT } = parsedRuleConfig;

      const { FAILURE = 0, WARNING = 0 } = DQ_TH || {};
      const { DIR, REF_FOR_CALC, VAR = 0 } = DRIFT || {};

      const itemValueBasedOnAlertType = (): any => {
        const createListOfTagsWhereStatusTrue = (
          list: ListTypeDQRuleType = []
        ): string[] =>
          list
            ?.filter((item) => item?.STATUS)
            ?.map((item) => item?.VALUE || "");

        switch (RULE_TYPE_ID) {
          case "CVR":
            return {
              value: `${parsedRuleConfig?.CVR?.MIN || ""} - ${
                parsedRuleConfig?.CVR?.MAX || ""
              }`,
            };
          case "TDD":
            return {
              value: `${parsedRuleConfig?.TDD?.MIN || ""} - ${
                parsedRuleConfig?.TDD?.MAX || ""
              }`,
            };
          case "CCV":
            return {
              tags: createListOfTagsWhereStatusTrue(parsedRuleConfig?.CCV),
            };
          case "CPV":
            return {
              tags: createListOfTagsWhereStatusTrue(parsedRuleConfig?.CPV),
            };
          case "CDF":
            return {
              tags: createListOfTagsWhereStatusTrue(parsedRuleConfig?.CDF),
            };
          default:
            return "";
        }
      };

      return {
        ...prev,
        [RULE_TYPE_ID]: {
          rule_id: RULE_ID,
          rule_action_status: RULE_ACTION_STATUS,
          rule_action_status_id: RULE_ACTION_STATUS_ID,
          rule_action_details: [...parseDQActionDetails(RULE_ACTION_DETAILS)],
          rule_hist_id: RULE_HIST_ID,
          rule_alert: RULE_ALERT,
          dq_score_details: {
            statusId: RULE_EXEC_STATUS_ID,
            status: RULE_EXEC_STATUS || "",
            score: RULE_SCORE,
            trend: RULE_PCT_SCORE_CHANGE,
            isShowChange: true,
            showEmptyState: true,
          },
          rule_defination: [
            ...(!["TSD", "CUQ", "CCP"].includes(RULE_TYPE_ID)
              ? [
                  {
                    title: RULE_TYPE,
                    ...itemValueBasedOnAlertType(),
                  },
                ]
              : []),
            {
              title: "DQ Alert",
              value: `Failure: ${FAILURE}% - Warning: ${WARNING}%`,
            },
            { title: "Drift Direction", value: DIR },
            {
              title: "Drift Calculation",
              value:
                REF_FOR_CALC === "PRV"
                  ? "Compare to Previous"
                  : REF_FOR_CALC === "MAG"
                  ? "Moving average"
                  : "",
            },
            { title: "Drift Alert", value: `${VAR}%` },
          ] as SectionRowProps[],
          rule_details: {
            download_csv_url: ruleDetailsUrls?.full_data,
            sample_data_url: ruleDetailsUrls?.sample_data,
          },
        } as ReturnTypeOfDQRuleDetailsParser["details"]["CCP"],
      };
    }, {}),
  };
}

export function getParsedRuleDetailsBarChartData({
  data = [],
}: AxiosResponse<DataQualityRuleHistoryBarChartResponse>): ReturnTypeOfRuleHistoryBarChartParser {
  const {
    NEXT_RULE_HIST_ID = "",
    PREV_RULE_HIST_ID = "",
    RULE_EXEC_DATE = "",
    RULE_EXEC_RESULTS = "",
    RULE_EXEC_STATUS = "",
    RULE_PCT_SCORE_CHANGE = "",
    RULE_SCORE = "",
    RULE_EXEC_STATUS_ID,
    RULE_EXP_URLS = "",
  } = data?.[0] || {};

  const ruleDetailsUrls = jsonParse(RULE_EXP_URLS, true) as RuleExpUrlsResponse;

  const parsedResults = jsonParse(RULE_EXEC_RESULTS) as RuleExecutionResults;
  return {
    chart_data: [
      {
        color: "#328db5",
        barWidth: 0.6,
        data: (
          parsedResults?.map((item) => ({
            x: item?.CNT || 0,
            y: item?.VALUE || "",
            color: item?.STATUS ? "#328db5" : "#d3212c",
          })) || []
        )?.sort((a, b) => b?.x - a?.x),
        name: "Series 1",
      },
    ],
    dq_score: {
      statusId: RULE_EXEC_STATUS_ID,
      status: RULE_EXEC_STATUS || "",
      score: RULE_SCORE,
      trend: RULE_PCT_SCORE_CHANGE,
      showEmptyState: true,
    },
    date: utcTOLocalTimeZone(RULE_EXEC_DATE),
    next_id: `${NEXT_RULE_HIST_ID}`,
    prev_id: `${PREV_RULE_HIST_ID}`,
    rule_details: {
      download_csv_url: ruleDetailsUrls?.full_data,
      sample_data_url: ruleDetailsUrls?.sample_data,
    },
  };
}

export function getParsedDownloadRuleLink({
  data,
}: AxiosResponse<DataQualityDownloadRuleResponse>): ReturnTypeOfDownloadRuleParser {
  return data || "";
}
