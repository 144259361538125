import { useController } from "react-hook-form";
import { TimePickerFieldStyled } from "./timepickerfield.styles";
import { TimePickerFieldProps } from "./timepickerfield.types";
import TimePicker from "../../timepicker/timepicker";

const TimePickerField = ({
  name = "",
  control,
  defaultValue,
  ...props
}: TimePickerFieldProps): JSX.Element => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <TimePickerFieldStyled>
      <TimePicker
        {...inputProps}
        {...props}
        ref={ref}
        defaultValue={defaultValue}
      />
      <div className="error">{error?.message}</div>
    </TimePickerFieldStyled>
  );
};

export default TimePickerField;
