import LinkButton from "../../../../../../components/linkbutton";
import {
  arrowLeftSecondary,
  arrowRightSecondary,
} from "../../../../../../svgs";

import { EntitiesSectionPaginationProps } from "./entitiessectionpagination.types";

import { EntitiesSectionPaginationStyled } from "./entitiessectionpagination.styles";

const EntitiesSectionPagination = (
  props: EntitiesSectionPaginationProps
): JSX.Element => {
  const { currentPage, totalPages, prevPage, nextPage } = props;

  return (
    <EntitiesSectionPaginationStyled>
      Review Results {currentPage} of {totalPages}
      <span className="pagination-btn-container">
        <LinkButton
          onClick={prevPage}
          disabled={currentPage <= 1}
          className="pagination-btn"
        >
          {arrowLeftSecondary("5", "10")}
        </LinkButton>
        <LinkButton
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className="pagination-btn"
        >
          {arrowRightSecondary("5", "10")}
        </LinkButton>
      </span>
    </EntitiesSectionPaginationStyled>
  );
};

export default EntitiesSectionPagination;
