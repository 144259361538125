import { HighlightedCircleBackroundStyled } from "./highlightedcirclebackround.styles";
import { HighlightedCircleBackroundProps } from "./highlightedcirclebackround.types";

const HighlightedCircleBackround = (
  props: HighlightedCircleBackroundProps
): JSX.Element => {
  const { children, ...resProps } = props;
  return (
    <HighlightedCircleBackroundStyled {...resProps}>
      {children}
    </HighlightedCircleBackroundStyled>
  );
};

export default HighlightedCircleBackround;
