import styled from "styled-components";

export const ChartDebugConfigStyled = styled.div`
  margin-bottom: 16px;

  .title {
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
  }

  .content-wrapper {
    background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
    margin-top: 10px;

    div:first-child {
      padding-top: 20px;
    }

    div:last-child {
      padding-bottom: 20px;
    }

    div {
      padding: 0 20px;
    }

    .chart-debug-config-item {
      .key {
        font-family: OpenSansSemiBold;
      }
    }
  }
`;
