import styled from "styled-components";

export const FullWidthRow = styled.div`
  .row-access-info {
    margin: 1em 0;
    div {
      display: flex;
      align-items: center;  
    }
  }
`;
