import styled from "styled-components";

export const GridToggleButtonStyled = styled.div`
  display: flex;
  align-items: center;

  .action-title {
    font-family: OpenSans;
    color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
    font-weight: normal;
    margin-right: 9px;
  }

  svg {
    fill: ${({ theme }): string => theme.bgColors.LIGHT_GREY_15};
  }
`;
