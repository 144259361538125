import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { v4 as uuidv4 } from "uuid";
import LinkButton from "../linkbutton/linkbutton";

import { deleteIcon, plusIconInFilledCircle } from "../../svgs";
import {
  CriteriaAndConditionWrapperStyled,
  CriteriaAndRemoveButtonWrapperStyled,
  CriteriaContainerStyled,
  EmptyFilterCriteriaContainerStyled,
  FilterCriteriaStyled,
  SpecifyCriteriaFieldsContentStyled,
} from "./filtercriteria.styles";

import {
  AddCriteria,
  CriteriaProps,
  HandleAddFilterConditionType,
  HandleInputChangeValue,
  LogiacalOperatorType,
  onResetFilterRowValues,
  DUMMY_FILTER_CRITERIA,
} from "./filtercriteria.types";

import { AndOrToggleButton } from "../andortogglebutton/andortogglebutton";

import {
  getDataTypeIcon,
  getObjectKeys,
  sortListOnSpecificKeyValue,
  sortObjectsArrayByKey,
} from "../../utils";
import { ColumnNameAndTypeWrapper } from "../../drawerviews/ruleslistingeditdrawer/ruleslistingeditdrawer.styles";
import { HorizontalDividerStyled } from "../dividers/dividers.styles";

import { AddNewButton, FilterRow } from "./filtercriteria.components";
import { useGetIncExcFieldData } from "../../api/listingsservice";
import Flex from "../flex/flex";

const removeRowBtn = deleteIcon;
const addNewCriteriaBtnIcon = plusIconInFilledCircle("12", "12");

const FilterCriteria = ({
  name,
  parsedFields = [],
  parsedFilters,
  isEditMode,
  existingFilters,
  nodeType,
  isFromGovViews,
  onChangeFilterCriteria,
  parentNodeId = "",
  isReadMode,
}: CriteriaProps): JSX.Element => {
  const { control, setValue, watch } = useFormContext<AddCriteria>();

  const [showIncExcLoading, setShowIncExcLoading] = useState(false);
  const [showSpinnerInIncExl, setShowSpinnerInIncExl] = useState(false);

  const { fields, append } = useFieldArray<AddCriteria>({
    control,
    name,
  });

  const watchFilterCriteria = watch(name);

  const fieldsForSelect = useMemo(() => {
    return (
      parsedFields
        ?.filter(
          (field) => !field?.is_field_hidden && field?.is_field_filterable
        )
        ?.map((field) => ({
          key: `${field?.field_id}`,
          value: `${field?.field_id}`,
          labelText: field?.field_display_name,
          label: (
            <ColumnNameAndTypeWrapper>
              <span className="icon">
                {getDataTypeIcon(field?.field_datatype)}
              </span>
              <span className="column-name">{field?.field_display_name}</span>
            </ColumnNameAndTypeWrapper>
          ),
        })) || []
    );
  }, [parsedFields]);

  const filtersForSelectForColumns = useCallback(
    (criteriaIndex, filterRowIndex: number) => {
      const columnId =
        fields?.[criteriaIndex]?.condition?.[filterRowIndex]?.column;

      const fieldId = Number.parseInt(columnId || "");
      const field = parsedFields?.find(
        (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
      );

      const fieldDataType = field?.field_datatype || "";
      const shouldIcludesExcludesOptionShown =
        field?.includes_exclues_exists || false;

      const filtersArray =
        parsedFilters?.filter((parsedFilter) => {
          const hideIncExlForDateField =
            ["INC", "NIN"]?.includes(parsedFilter?.value) &&
            ["DTE", "DTM", "DT"]?.includes(fieldDataType);

          const hideIncExlIfFieldIsNotAttr =
            ["INC", "NIN"]?.includes(parsedFilter?.value) &&
            field?.is_attribute === false;

          // DONT SHOW INCLUDES/EXCLUDES OPTION when no col distribution values
          const hideIncExlIfFieldHasNoDist =
            ["INC", "NIN"]?.includes(parsedFilter?.value) &&
            !shouldIcludesExcludesOptionShown;

          // DONT SHOW INCLUDES/EXCLUDES OPTION FOR DATE FIELD
          const hideIncExlIfFieldIsStr =
            fieldDataType !== "STR" && parsedFilter?.value === "WCM";

          const filterConditionsForGovViews =
            hideIncExlIfFieldIsNotAttr ||
            hideIncExlIfFieldIsStr ||
            hideIncExlForDateField;

          const filterConditionsForCriteria =
            hideIncExlIfFieldHasNoDist ||
            hideIncExlIfFieldIsStr ||
            hideIncExlForDateField;

          if (
            (isFromGovViews && filterConditionsForGovViews) ||
            (!isFromGovViews && filterConditionsForCriteria)
          )
            return false;

          return true;
        }) || [];

      const sortedFilters = sortListOnSpecificKeyValue({
        list: filtersArray,
        key: "label",
      });

      return sortedFilters;
    },
    [parsedFilters, fields, parsedFields, isFromGovViews]
  );

  const filtersForSelect = useCallback(
    (criteriaIndex, filterRowIndex: number) => {
      const columnId =
        fields?.[criteriaIndex]?.condition?.[filterRowIndex]?.column;

      const fieldId = Number.parseInt(columnId || "");
      const field = parsedFields?.find(
        (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
      );

      const fieldDataType = field?.field_datatype || "";

      const filtersArray =
        parsedFilters?.filter((parsedFilter) => {
          const addIncExclOption =
            parsedFilter?.elligibleTypes?.includes("ATR") ||
            parsedFilter?.elligibleTypes?.includes("BOL");
          return (
            parsedFilter?.elligibleTypes?.includes(fieldDataType) ||
            (field?.is_attribute ? addIncExclOption : false)
          );
        }) || [];

      const sortedFilters = sortListOnSpecificKeyValue({
        list: filtersArray,
        key: "label",
      });

      return sortedFilters;
    },
    [parsedFilters, fields, parsedFields, isFromGovViews]
  );

  const valuesForIncludesExcludesInput = useCallback(
    (criteriaIndex: number, filterRowIndex: number) => {
      const columnId =
        fields?.[criteriaIndex]?.condition?.[filterRowIndex]?.column;
      const fieldId = Number.parseInt(columnId || "");
      const field = parsedFields?.find(
        (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
      );

      const distributionValues = field?.col_distribution_values || [];

      const sortedDistributionValues =
        distributionValues?.sort((a, b) =>
          a?.toLowerCase()?.localeCompare(b?.toLowerCase())
        ) || [];

      return sortedDistributionValues || [];
    },
    [parsedFilters, fields, parsedFields]
  );

  const onHandleFilterRowValues: HandleInputChangeValue = useCallback(
    (
      criteriaRowIndex,
      filterRowIndex = 0,
      updatedObject,
      type,
      selectFieldOption,
      isSingleSelect
    ) => {
      const fieldObj = { ...updatedObject };
      const isField = type === "field";
      const isFilter = type === "filter";

      const updatedCriteriaFilterRowData = [
        ...watchFilterCriteria?.[criteriaRowIndex]?.condition,
      ];

      if (isField) {
        const fieldId = Number.parseInt(updatedObject?.column || "");
        const field = parsedFields?.find(
          (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
        );

        fieldObj.data_type = field?.field_datatype;
        fieldObj.filter = "";
      } else if (isFilter) {
        const filter = parsedFilters?.find(
          (filter) => filter?.value === `${updatedObject?.filter}`
        );
        if (["INC", "NIN"].includes(filter?.value || ""))
          setShowSpinnerInIncExl(true);

        fieldObj.date_type = updatedObject?.date_type || "REL";
      }

      if (selectFieldOption && isSingleSelect)
        fieldObj.columnName = selectFieldOption?.[0]?.labelText;

      fieldObj.valuesList = selectFieldOption?.map((item) => item?.key || "");

      updatedCriteriaFilterRowData[filterRowIndex] = fieldObj;

      setValue(
        `${name}.${criteriaRowIndex}.condition.${filterRowIndex}` as const,
        fieldObj,
        { shouldValidate: true, shouldDirty: true }
      );

      onChangeFilterCriteria?.();
    },
    [
      parsedFields,
      parsedFilters,
      name,
      watchFilterCriteria,
      onChangeFilterCriteria,
    ]
  );

  const onFilterConditionLogicalOperatorChange = useCallback(
    (criteriaRowIndex: number, selectedValue: string): void => {
      const updatedCriteria = watchFilterCriteria?.map(
        (criteriaItem, criteriaIndex) =>
          criteriaRowIndex === criteriaIndex
            ? {
                ...criteriaItem,
                condition: criteriaItem?.condition?.map((filterCond, i) => {
                  return { ...filterCond, next_operation: selectedValue };
                }),
              }
            : criteriaItem
      );

      setValue(name, updatedCriteria);
      onChangeFilterCriteria?.();
    },
    [name, watchFilterCriteria]
  );

  const onFilterConditionGroupLogicalOperatorChange = useCallback(
    (selectedValue: string): void => {
      const updatedCriteria = watchFilterCriteria?.map((criteria, index) => {
        return {
          ...criteria,
          next_operator: selectedValue,
        };
      });

      setValue(name, updatedCriteria);
      onChangeFilterCriteria?.();
    },
    [watchFilterCriteria, onChangeFilterCriteria]
  );

  const resetInBetweenFilterRowValues = useCallback(
    (criteriaIndex: number, filterIndex: number) => {
      const nameOfCriteria = `${name}.${criteriaIndex}.condition.${filterIndex}` as const;

      setValue(`${nameOfCriteria}.date_value`, undefined);
      setValue(`${nameOfCriteria}.second_date_value`, undefined);
      setValue(`${nameOfCriteria}.value`, undefined);
      setValue(`${nameOfCriteria}.second_value`, undefined);
      setValue(`${nameOfCriteria}.values_list`, undefined);
    },
    [name]
  );

  const onResetFilterRowValues: onResetFilterRowValues = useCallback(
    (params) => {
      const { criteriaRowIndex, filterRowIndex, typeOfInputField } = params;

      const isFilterSelection = typeOfInputField === "filter";
      const isCriteriaSelection = typeOfInputField === "criteriaType";

      const nameOfCriteria = `${name}.${criteriaRowIndex}.condition.${filterRowIndex}` as const;

      if (isCriteriaSelection) {
        setValue(`${nameOfCriteria}.column`, undefined);
        setValue(`${nameOfCriteria}.filter`, undefined);
        setValue(`${nameOfCriteria}.sql_expression`, undefined);
      }
      !isFilterSelection && setValue(`${nameOfCriteria}.filter`, undefined);
      resetInBetweenFilterRowValues(criteriaRowIndex, filterRowIndex);
    },
    [name, resetInBetweenFilterRowValues]
  );

  useEffect(() => {
    if ((isEditMode || isReadMode) && !fields?.length) {
      const copiedArray = window.structuredClone(existingFilters);
      // copied the existing filterr into another valriable

      append(copiedArray);
    }
  }, []);

  const handleAddFilterConditionGroup: HandleAddFilterConditionType = useCallback(
    (addConditionType): void => {
      const isAnyFilterCriteriaAlreadyExists = watchFilterCriteria?.length > 0;

      const newCondition = {
        condition: [
          {
            ...DUMMY_FILTER_CRITERIA,
            filter_type: addConditionType,
          },
        ],
        next_operator: "AND",
      };

      const addConditionGroup = isAnyFilterCriteriaAlreadyExists
        ? [...watchFilterCriteria, newCondition]
        : [newCondition];

      setValue(name, addConditionGroup, {
        shouldDirty: true,
        shouldValidate: true,
      });
      onChangeFilterCriteria?.();
      setShowIncExcLoading(false);
    },
    [watchFilterCriteria, name, onChangeFilterCriteria]
  );

  const handleAddFilterCondition: HandleAddFilterConditionType = useCallback(
    (criteriaType, criteriaIndex = 0): void => {
      const currentFilters =
        watchFilterCriteria?.[criteriaIndex]?.condition || [];

      const rowId = uuidv4();

      const updatedFilters = watchFilterCriteria?.map((item, index) => {
        if (index === criteriaIndex) {
          const currentFiltersLength = currentFilters?.length || 0;

          const currentCriteriaGrpLogicalOperator =
            item?.condition?.[0]?.next_operation;

          return {
            condition: [
              ...currentFilters,
              {
                ...DUMMY_FILTER_CRITERIA,
                filter_type: criteriaType,
                next_operation: currentCriteriaGrpLogicalOperator || "AND",
                sql_expression: undefined,
                rowId,
              },
            ],
            next_operator: item?.next_operator || "AND",
          };
        }
        return item;
      });

      setValue(name, updatedFilters);
      onChangeFilterCriteria?.();
    },
    [watchFilterCriteria, name, onChangeFilterCriteria]
  );

  const handleRemoveFilterCriteria = useCallback(
    (criteriaRowIndex: number) => (): void => {
      setValue(
        name,
        watchFilterCriteria?.filter(
          (_, criteriaIndex) => criteriaIndex !== criteriaRowIndex
        ),
        {
          shouldDirty: true,
        }
      );
      onChangeFilterCriteria?.();
    },
    [name, watchFilterCriteria, onChangeFilterCriteria]
  );

  const handleRemoveFilterRow = useCallback(
    (criteriaIndex: number, filterIndex: number) => (): void => {
      const isOnlyOneFilterRow =
        watchFilterCriteria?.[criteriaIndex]?.condition?.length === 1;

      if (isOnlyOneFilterRow) handleRemoveFilterCriteria(criteriaIndex)();
      else {
        const updatedCriteria = watchFilterCriteria?.map(
          (criteriaItem, criteriaRowIndex) =>
            criteriaRowIndex === criteriaIndex
              ? {
                  ...criteriaItem,
                  condition: criteriaItem?.condition?.filter(
                    (_, i) => i !== filterIndex
                  ),
                }
              : criteriaItem
        );

        setValue(name, updatedCriteria, { shouldDirty: true });
      }
      onChangeFilterCriteria?.();
    },
    [
      name,
      watchFilterCriteria,
      onChangeFilterCriteria,
      handleRemoveFilterCriteria,
    ]
  );

  const incExcFieldConfig = useMemo(() => {
    const fieldsWithIncludesExcludesSelectedAsFilter =
      watchFilterCriteria?.flatMap((fieldItem) => {
        return fieldItem?.condition?.filter((item) => {
          return ["INC", "NIN"]?.includes(item?.filter || "") && item?.column;
        });
      }) || [];

    return (
      fieldsWithIncludesExcludesSelectedAsFilter
        ?.map((item) => {
          const fieldId = Number.parseInt(item?.column || "");
          const field = parsedFields?.find(
            (parsedField) => Number(parsedField?.field_id) === Number(fieldId)
          );

          return {
            params: [
              field?.field_name || "",
              field?.field_id_field || "null",
              nodeType,
              field?.delimiter || "",
              parentNodeId,
            ],
            isDelimiter: field?.delimiter !== "",
            field_name: field?.field_name || "",
            field_id: `${field?.field_id || ""}`,
          };
        })
        ?.filter((nestItem) => nestItem?.field_id && nestItem?.field_name) || []
    );
  }, [watchFilterCriteria, parsedFields, nodeType]);

  const {
    isLoading: isLoadingGetFieldData,
    isFetching,
    error: errorGetFieldData,
    parsedData,
  } = useGetIncExcFieldData(incExcFieldConfig, !!isFromGovViews);

  useEffect(() => {
    const isFieldsValuesExist = getObjectKeys(parsedData)?.length;
    if (isEditMode && !isFetching && showIncExcLoading) {
      setShowIncExcLoading(false);
      setShowSpinnerInIncExl(false);
    } else if (isFetching && !isFieldsValuesExist) {
      setShowIncExcLoading(true);
    }
  }, [isFetching]);

  const isFetchingInEditMode =
    showIncExcLoading && !showSpinnerInIncExl ? isFetching : false;

  const AddConditionButton = isFromGovViews ? (
    <LinkButton
      onClick={(): void => handleAddFilterConditionGroup("CND")}
      className="add-condition-button"
    >
      {addNewCriteriaBtnIcon} Add Condition Group
    </LinkButton>
  ) : (
    <AddNewButton
      buttonTitle="Add Condition Group"
      handleAddButtonClick={handleAddFilterConditionGroup}
    />
  );
  return (
    <CriteriaContainerStyled>
      <SpecifyCriteriaFieldsContentStyled className="specify-criteria-fields">
        {fields?.length ? (
          fields?.map((criteria, criteriaRowIndex) => (
            <CriteriaAndConditionWrapperStyled
              key={`criteria-row-index-${criteria?.id}`}
            >
              <CriteriaAndRemoveButtonWrapperStyled>
                <FilterCriteriaStyled>
                  {criteria?.condition?.map((filterRow, filterRowIndex) => {
                    const filters = (isFromGovViews
                      ? filtersForSelect
                      : filtersForSelectForColumns)(
                      criteriaRowIndex,
                      filterRowIndex
                    );

                    const inputValues = valuesForIncludesExcludesInput(
                      criteriaRowIndex,
                      filterRowIndex
                    );

                    const valuesSelect = inputValues?.map((item) => ({
                      key: item,
                      value: item,
                      label: item,
                    }));

                    const valuesSelectGovViews =
                      parsedData?.[filterRow?.column || ""] || [];

                    return (
                      <FilterRow
                        key={`filter-row-index-${filterRow?.rowId}`}
                        filterRow={filterRow}
                        criteriaRowIndex={criteriaRowIndex}
                        filterRowIndex={filterRowIndex}
                        onRemoveFilterRow={handleRemoveFilterRow}
                        name={name}
                        onHandleFilterRowValues={onHandleFilterRowValues}
                        onResetFilterRowValues={onResetFilterRowValues}
                        columns={fieldsForSelect}
                        filters={filters || []}
                        inputValues={
                          isFromGovViews ? valuesSelectGovViews : valuesSelect
                        }
                        isErrorInFetchingInputValues={!!errorGetFieldData}
                        isInputValuesFetching={isLoadingGetFieldData}
                        isInputValuesFetchingInEditMode={isFetchingInEditMode}
                        onFilterConditionLogicalOperatorChange={
                          onFilterConditionLogicalOperatorChange
                        }
                        isReadMode={isReadMode}
                      />
                    );
                  })}

                  {!isReadMode ? (
                    isFromGovViews ? (
                      <LinkButton
                        onClick={(): void =>
                          handleAddFilterCondition("CND", criteriaRowIndex)
                        }
                        className="add-condition-button"
                      >
                        {addNewCriteriaBtnIcon} Add Condition
                      </LinkButton>
                    ) : (
                      <AddNewButton
                        buttonTitle="Add Condition"
                        handleAddButtonClick={(criteriaType): void => {
                          handleAddFilterCondition(
                            criteriaType,
                            criteriaRowIndex
                          );
                        }}
                      />
                    )
                  ) : (
                    <span />
                  )}
                </FilterCriteriaStyled>

                {!isReadMode && (
                  <LinkButton
                    className="remove-btn"
                    onClick={handleRemoveFilterCriteria(criteriaRowIndex)}
                    data-testid={`remove-filter-group-${criteriaRowIndex}`}
                  >
                    {removeRowBtn}
                  </LinkButton>
                )}
              </CriteriaAndRemoveButtonWrapperStyled>
              {criteriaRowIndex < fields?.length - 1 && (
                <AndOrToggleButton
                  disabled={isReadMode}
                  selectedOption={criteria?.next_operator || "AND"}
                  options={["AND", "OR"]}
                  onClick={(option): void => {
                    onFilterConditionGroupLogicalOperatorChange(
                      option as LogiacalOperatorType
                    );
                  }}
                />
              )}
            </CriteriaAndConditionWrapperStyled>
          ))
        ) : (
          <EmptyFilterCriteriaContainerStyled>
            <p className="no-filters">No filter conditions are applied.</p>
            <HorizontalDividerStyled />

            {!isReadMode && AddConditionButton}
          </EmptyFilterCriteriaContainerStyled>
        )}
      </SpecifyCriteriaFieldsContentStyled>
      {fields?.length >= 1 && !isReadMode && AddConditionButton}
    </CriteriaContainerStyled>
  );
};

export default FilterCriteria;
