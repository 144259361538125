export const replaceAll = (
  text: string,
  substring: string,
  replacement: string
): string => {
  try {
    return text?.replace(new RegExp(substring, "g"), replacement);
  } catch {
    return text || "";
  }
};
