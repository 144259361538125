import styled from "styled-components";
import { ellipsis } from "../../../../../styles/app.styles";
import { hexToRgbA } from "../../../../../utils";

export const ColumnSampleDataViewStyled = styled.div<{
  isEmpty: boolean;
  isColumnDataEmpty: boolean;
  isGatewayRunning: boolean;
}>`
  .table-container {
    .ag-root-wrapper {
      border: 1px solid ${({ theme }): string => theme.borderColors.GREY_35};
      .ag-header-viewport {
        .ag-header-cell {
          background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_5};
          display: unset;
          padding: 0px;
          padding-left: 0px !important;
          border-right: 1px solid
            ${({ theme }): string => theme.borderColors.GREY_35};
        }

        .ag-header-container {
          .ag-header-row {
            height: 210px !important;
          }
        }
      }
      .ag-body-viewport {
        min-height: ${({ isColumnDataEmpty, isEmpty }): string =>
          !isColumnDataEmpty && isEmpty ? "250px" : "120px"};
        .ag-row {
          .ag-cell {
            border-right: 1px solid
              ${({ theme }): string => theme.borderColors.GREY_35};
          }

          :first-of-type {
            border-top: 1px solid
              ${({ theme }): string => theme.borderColors.GREY_35};
          }
        }
      }

      .ag-overlay {
        .ag-overlay-panel {
          .ag-overlay-wrapper {
            padding-top: ${({ isColumnDataEmpty, isEmpty }): string =>
              !isColumnDataEmpty && isEmpty ? "200px" : "0px"};

            .ag-overlay-no-rows-center {
              display: ${({ isGatewayRunning }): string =>
                isGatewayRunning ? "" : "none"};
            }
          }
        }
      }
    }
  }

  .sec-blank-slate {
    min-height: 331px;
    font-family: OpenSansSemiBold;
  }
`;

export const HeaderRenderersStyled = styled.div`
  cursor: default;
  .sample-data-header {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    .header-title {
      display: flex;
      align-items: center;
      .rendrer-column-name {
        max-width: 100%;
        ${ellipsis}
      }
      .data-type-icon {
        svg {
          margin-right: 7px;
          min-height: 10px;
          max-height: 13px;
          path {
            fill: ${({ theme }): string => theme.bgColors.LIGHT_GREY_5};
          }
        }
      }

      .rendrer-column-name {
        cursor: pointer;
      }

      color: ${({ theme }): string => theme.textColors.BLUE_116};

      font-family: OpenSansSemiBold;
      margin-bottom: 8px;
      padding-top: 11px;

      > svg:last-of-type {
        margin-left: 9px;
      }

      .header-rendrer-icon {
        margin-left: 9px;
        svg {
          width: 13px;
          height: 13px;
        }
      }
    }
    .desc {
      font-size: 11px;
      min-height: 33px;
      height: 33px;
      color: ${({ theme }): string => hexToRgbA(theme.textColors.GREY_25, 0.8)};
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
      margin-bottom: 9px;
    }
  }

  .column-sample-data-profiling-tooltip {
    width: 100%;
  }

  .header-column-chart {
    .ant-card-bordered {
      background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_5};
      border: none;

      .ant-card-body {
        padding: 0px;
      }
    }
  }
`;
