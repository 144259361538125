import styled from "styled-components";

export const PieChartStyled = styled.div`
  width: 100%;
  .ant-card {
    .ant-card-body {
      padding: 0px;
    }
  }

  .recharts-pie-label-text {
    font-family: "OpenSansBold";
    font-size: 14px;
    fill: #000000;
  }
`;
