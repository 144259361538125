import { checkDataTypeOfColumn, getPostLoginData } from "../../utils";
import { ColTypeIds, ColumnResponse } from "./columnparsers.types";

export const computeColTypeID = (column: ColumnResponse): ColTypeIds => {
  const { user_info: userInfo } = getPostLoginData();

  const { non_metric_keywords: nonMetricKeywords } = userInfo || {};

  const {
    col_names_keywords_for_non_metric: colNamesKeywordsForNonMetric = [],
    ending_col_names_for_non_metric: endingColNamesForNonMetric = [],
  } = nonMetricKeywords || {};

  const regexStr = `\\b(?:${colNamesKeywordsForNonMetric.join("|")})\\b`;

  const regex = new RegExp(regexStr, "i");

  const regexForEndingKeywords = new RegExp(
    `.*(${endingColNamesForNonMetric?.join("|")})$`,
    "i"
  );

  const { isStringColumn, isDateColumn, isNumberCol } = checkDataTypeOfColumn(
    column?.COL_DATA_TYPE_ID
  );

  return isStringColumn
    ? "ATR"
    : isDateColumn
    ? "TIM"
    : isNumberCol
    ? regex?.test(column?.COL_NAME?.replaceAll(/_/g, " ")) ||
      regexForEndingKeywords?.test(column?.COL_NAME?.replaceAll(/_/g, " "))
      ? "ATR"
      : "MET"
    : "N/A";
};
