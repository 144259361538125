import { GovernanceViewCategoryType } from "../../../../contexts/widgetcreationcontext/widgetcreationcontext.types";
import { ReturnTypeOfRefParser } from "../../../../parsers";
import {
  getPostLoginData,
  getUserPermissions,
  isJsonString,
  jsonParse,
} from "../../../../utils";
import { ChartType } from "../../../../utils/getcharticon";
import { getModuleLevelAccessInfo } from "../../../../utils/getModuleLevelAccessInfo";
import { ChartAdhocInfoType } from "./widgetcreationwizard.comp/addwidgetform/addwidgetform.types";

export const getChartAdhocInfo = (
  chartTypeRefs: ReturnTypeOfRefParser[],
  currentChartType: ChartType
): ChartAdhocInfoType => {
  const selectedChartInfo = chartTypeRefs?.find(
    (item) => item?.id === currentChartType
  );

  const selectedChartAdhocInfo = selectedChartInfo?.adhocInfo;

  const parsedAdhocInfo = jsonParse(selectedChartAdhocInfo || "", true);
  return parsedAdhocInfo;
};

const filterCategoriesByPermission = (
  categories: ReturnTypeOfRefParser[]
): ReturnTypeOfRefParser[] => {
  const {
    hasCatalogAccess: isCatalogEnabled,
    hasGlossaryAccess: isGlossaryEnabled,
    isChatEnabled,
    isDqEnabled,
  } = getModuleLevelAccessInfo();

  const {
    is_account_admin: isAccountAdmin = false,
    is_glossary_admin: isGlossaryAdmin = false,
    is_catalog_admin: isCatalogAdmin = false,
    is_user_admin: isUserAdmin = false,
    has_no_catalog_access: hasNoCatalogAccess,
    has_no_glossary_access: hasNoGlossaryAccess,
  } = getUserPermissions();

  const isAdminAtAnyLevel =
    isAccountAdmin || isGlossaryAdmin || isCatalogAdmin || isUserAdmin;

  const hasCatalogAccess = isCatalogEnabled && !hasNoCatalogAccess;
  const hasGlossaryAccess = isGlossaryEnabled && !hasNoGlossaryAccess;
  const hasDqAccess = isDqEnabled && !hasNoCatalogAccess;
  const hasChatAccess = isChatEnabled && !hasNoCatalogAccess;
  const hasAdminAccess = isAdminAtAnyLevel && !hasNoCatalogAccess;

  const categoryPermissions: Record<string, boolean> = {
    RLH: hasDqAccess,
    RLS: hasDqAccess,
    BOT: hasChatAccess,
    QRY: hasChatAccess,
    JOB: hasAdminAccess,
    SCH: hasAdminAccess,
    TBL: hasCatalogAccess,
    COL: hasCatalogAccess,
    DSR: hasCatalogAccess,
    ANL: hasCatalogAccess,
    TRM: hasGlossaryAccess,
    CHT: false,
    FBK: false,
  };

  return categories?.filter((category) => {
    const hasPermission = categoryPermissions[category?.id];

    return hasPermission ?? true;
  });
};

export const getGovViewListWithWidgets = (
  govViews: ReturnTypeOfRefParser[],
  widgets: ReturnTypeOfRefParser[]
): GovernanceViewCategoryType[] => {
  const allowedCategories = filterCategoriesByPermission(govViews) || [];

  return (
    allowedCategories?.map((govCat) => {
      const filteredWidgets =
        widgets?.filter((wid) => {
          const widInfo = jsonParse(wid?.adhocInfo);
          return widInfo?.gov_view_category === govCat?.id;
        }) || [];

      return {
        name: govCat?.name,
        displayOrder: govCat?.displayOrder,
        id: govCat?.id,
        widgets: [...filteredWidgets],
      };
    }) || []
  );
};
