import { API_CONFIG } from "../../constants/apiconfig";
import {
  getParsedSPMetadataLink,
  getParsedSSO,
  getParsedSSOSettings,
} from "../../parsers/accountsettings/accountsettingsparser";
import {
  SPMetadataUrlParserReturnType,
  SSOSettingsParserReturnType,
} from "../../parsers/accountsettings/accountsettingsparser.types";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_all_sso: getSSO,
  get_sso_settings: getSSOSettings,
  get_sp_metadata_url: getSPMetadataUrl,
} = API_CONFIG;

export function useGetAllSSO(): CustomReactQueryHookReturnType {
  return useGetAll({
    apiConfig: getSSO,
    parser: getParsedSSO,
  });
}

export const useGetSSOSettings = (): CustomReactQueryHookReturnType<SSOSettingsParserReturnType> => {
  return useGetAll<SSOSettingsParserReturnType>({
    apiConfig: getSSOSettings,
    parser: getParsedSSOSettings,
  });
};

export const useGetSPMetadataUrl = (): CustomReactQueryHookReturnType<SPMetadataUrlParserReturnType> => {
  return useGetAll<SPMetadataUrlParserReturnType>({
    apiConfig: getSPMetadataUrl,
    parser: getParsedSPMetadataLink,
  });
};