import { useCallback, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import {
  FeedbackButtonStyled,
  TrainbotFeedbackAddFeedbackFormStyled,
} from "./trainbotfeedbackaddfeedbackform.styles";

import { TrainbotFeedbackFormResponseForm } from "../../trainbotfeedbackform";
import Flex from "../../../../../../components/flex";

import { checkIsChatPage } from "../../../../../../utils";
import { TrainbotFeedbackAddtionalFeedbackFormProps } from "./trainbotfeedbackaddfeedbackform.types";

const COMMON_SET_VALUE_PROPS = {
  shouldValidate: true,
  shouldTouch: true,
};

const isChatPage = checkIsChatPage();

const TrainbotFeedbackAddtionalFeedbackForm = (
  props: TrainbotFeedbackAddtionalFeedbackFormProps
): JSX.Element => {
  const { errorCase = false, isAuthor, doesSqlExists = true } = props;

  const {
    watch,
    setValue,
    trigger,
    control,
  } = useFormContext<TrainbotFeedbackFormResponseForm>();

  const { reason } = watch();

  const onchangeReason = useCallback(
    (reason: TrainbotFeedbackFormResponseForm["reason"]) => (): void => {
      // This logic is to fix validation when train_sql is not visible
      if (!["WD", "WC"]?.includes(reason || "")) {
        setValue("train_sql", "");
      }

      setValue("reason", reason, COMMON_SET_VALUE_PROPS);
    },
    [setValue]
  );

  useEffect(() => {
    trigger?.();
  }, [reason]);

  const isReadOnlyMode = isChatPage && !isAuthor;

  return (
    <TrainbotFeedbackAddFeedbackFormStyled
      isReasonSelected={!!reason}
      isChatPage={isChatPage}
    >
      <Flex
        direction="column"
        alignItems={isChatPage ? "flex-start" : "center"}
      >
        <div className="train-bot-add-feedback-title">
          Please provide additional feedback
        </div>
        <Flex>
          {errorCase && (
            <Controller
              control={control}
              name="reason"
              render={(): JSX.Element => (
                <FeedbackButtonStyled
                  isActive={reason === "WQ" || reason === "ND"}
                  onClick={onchangeReason(doesSqlExists ? "WQ" : "ND")}
                  disabled={isReadOnlyMode}
                >
                  {doesSqlExists
                    ? "Didn't generate a valid query"
                    : "Didn't get the data"}
                </FeedbackButtonStyled>
              )}
            />
          )}
          {!errorCase && (
            <>
              <Controller
                control={control}
                name="reason"
                render={(): JSX.Element => (
                  <>
                    <FeedbackButtonStyled
                      isActive={reason === "WD" || reason === "WC"}
                      onClick={onchangeReason("WD")}
                      disabled={isReadOnlyMode}
                    >
                      Didn’t get the right data
                    </FeedbackButtonStyled>
                    {(doesSqlExists || isChatPage) && (
                      <FeedbackButtonStyled
                        isActive={reason === "WV"}
                        onClick={onchangeReason("WV")}
                        disabled={isReadOnlyMode}
                      >
                        Didn’t visualize properly
                      </FeedbackButtonStyled>
                    )}
                    <FeedbackButtonStyled
                      isActive={reason === "OTH"}
                      onClick={onchangeReason("OTH")}
                      disabled={isReadOnlyMode}
                    >
                      Other
                    </FeedbackButtonStyled>
                  </>
                )}
              />
            </>
          )}
        </Flex>
      </Flex>
    </TrainbotFeedbackAddFeedbackFormStyled>
  );
};

export default TrainbotFeedbackAddtionalFeedbackForm;
