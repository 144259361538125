import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../components";
import { InputField, SelectField } from "../../components/formfields";

import { useGetCurrentIPAddress } from "../../api/accountsettingsservice";

import { AddItemFormProps, AddItemFormType } from "./additemform.types";

import { AddItemFormStyled } from "./additemform.styles";
import { FormItemStyled, FormStyled } from "../../components/form";
import { selectFilterOption } from "../../utils";
import { hasAdministrationEditAccess } from "../../utils/getadminfinegrainaccesslist";

const AddItemForm = (props: AddItemFormProps): JSX.Element => {
  const {
    label,
    placeholder,
    schema,
    enableCurrentIP = false,
    onSubmit: onFormSubmit,
    labelDescription,
    options,
    allowAdding = true,
  } = props;

  const { control, handleSubmit, setValue, reset } = useForm<AddItemFormType>({
    defaultValues: {
      value: "",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { isLoading, refetch } = useGetCurrentIPAddress();

  const onSubmit = useCallback(
    (values) => {
      onFormSubmit(values);
      reset();
    },
    [onFormSubmit]
  );

  const getCurrentIPAddress = useCallback(async () => {
    const res: any = await refetch();
    setValue("value", res?.data?.data?.IPv4);
  }, []);

  return (
    <AddItemFormStyled>
      <FormStyled
        isItemColumnLayout
        paddingLeft="0px"
        onFinish={handleSubmit(onSubmit) as any}
      >
        <FormItemStyled label={label}>
          {labelDescription && (
            <div className="label-desc">{labelDescription}</div>
          )}
          <div className="form-row">
            {options ? (
              <SelectField
                className="form-item-select"
                control={control}
                name="value"
                placeholder={placeholder}
                setValue={setValue}
                options={options}
                showSearch
                filterOption={selectFilterOption}
                isAllowClear={false}
              />
            ) : (
              <InputField
                className="form-item"
                control={control}
                name="value"
                placeholder={placeholder}
              />
            )}

            {hasAdministrationEditAccess("account_settings") && (
              <Button
              htmlType="submit"
              height="34px"
              disabled={!allowAdding}
              // TODO:get tooltip from props
              tooltipProps={{
                title: !allowAdding
                  ? "Data Security Admins can be added only by Account Owners"
                  : undefined,
                placement: "topLeft",
              }}
            >
              Add
            </Button>
            )}

            {enableCurrentIP && (
              <Button
                onClick={getCurrentIPAddress}
                role="button"
                height="34px"
                disabled={isLoading}
              >
                Get Current
              </Button>
            )}
          </div>
        </FormItemStyled>
      </FormStyled>
    </AddItemFormStyled>
  );
};

export default AddItemForm;
