import styled from "styled-components";

export const DataImportSubmittedModalStyled = styled.div`
  height: 262.4px;
  width: 509.6px;

  .header {
    padding: 16px 25.6px;
    display: flex;
    align-items: center;
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};

    .header-text {
      font-size: 19.2px;
      color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      font-weight: 600;
      margin-left: 9.6px;
    }
  }

  .body {
    height: 140.8px;
    color: ${({ theme }): string => theme?.textColors?.GREY_40};
    padding: 32px 25.6px;
    font-size: 13.6px;
    width: 458.4px;
  }

  .footer {
    height: 60px;
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_25};
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 9.6px;
    padding-right: 19.2px;
  }
`;
