import { useCallback, useState } from "react";
import LinkButton from "../../../../../../components/linkbutton";
import { crossIcon } from "../../../../../../svgs";
import { AddCommentSec, Comment } from "./commentssec.comp";
import { MOCK_COMMENTS } from "./commentssec.constants";
import { CommentsSecStyled } from "./commentssec.styles";
import { CommentsSecProps } from "./commentssec.types";

const CommentsSec = (props: CommentsSecProps): JSX.Element => {
  const { onCommentCrossClick } = props;

  const [isShowReplyBox, setIsShowReplyBox] = useState<boolean | string>(false);

  const onReplyClick = useCallback(
    (id: string) => {
      isShowReplyBox === id
        ? setIsShowReplyBox(!isShowReplyBox)
        : setIsShowReplyBox(id);
    },
    [isShowReplyBox]
  );

  const onAddClick = useCallback((comment: string, parentId?: string) => {
    console.log(comment, parentId);
  }, []);

  return (
    <CommentsSecStyled>
      <div className="header">
        <div className="heading">Comments</div>
        <LinkButton onClick={onCommentCrossClick}>
          {crossIcon("11.2px", "11.2px")}
        </LinkButton>
      </div>

      <div className="content-sec">
        <div className="comments-list">
          {MOCK_COMMENTS?.map((comment) => {
            return (
              <Comment
                key={`ssa-comment-sec-${comment?.id}`}
                comment={{ ...comment }}
                isShowReplyBox={isShowReplyBox}
                onReplyClick={onReplyClick}
                onAddClick={onAddClick}
              />
            );
          })}
        </div>
      </div>

      <div className="add-comment-wrapper">
        <AddCommentSec onAddClick={onAddClick} />
      </div>
    </CommentsSecStyled>
  );
};

export default CommentsSec;
