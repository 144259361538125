import { SelectStyled } from "./selectdropdown.styles";
import { SelectFace } from "./selectdropdown.types";

const { Option } = SelectStyled;

const SelectDropdown = (props: SelectFace): React.ReactElement => {
  const { options } = props;
  return (
    <SelectStyled
      {...props}
      optionLabelProp="label"
      getPopupContainer={(trigger): HTMLElement =>
        trigger.parentNode as HTMLElement
      }
      dropdownClassName="multiselect-dropdown-dvsum"
      notFoundContent={
        <div className="no-result-fount-content">No results match </div>
      }
      hideDropdown={!options?.length}
      multipleMode={props.mode === "multiple"}
      className="dvsum-select-field"
    >
      {options?.map((option) => (
        <Option
          value={option.value}
          label={option.label}
          key={option.label + option.value}
        >
          {option.label}
        </Option>
      ))}
    </SelectStyled>
  );
};

export { SelectDropdown };
