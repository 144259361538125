import styled from "styled-components";

export const AnalysisDetailPageSavedContextsSecStyled = styled.div<{
  isChatPage: boolean;
}>`
  height: ${({ isChatPage }): string =>
    isChatPage ? "100vh" : "calc(100vh - 45px)"};
  position: sticky;
  top: ${({ isChatPage }): string => (isChatPage ? "0" : "45px")};
  border-left: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  z-index: 11;
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};

  .saved-contexts-sec-wrapper {
    position: relative;
    height: 100%;

    .header-cross-btn {
      position: absolute;
      right: 16px;
      top: 24px;
      display: flex;
    }
  }
`;
