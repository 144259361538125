import { useGetAppState } from "../../../../customhooks";
import Flex from "../../../flex";
import { ExpandedViewModalStyled } from "./expandedviewmodal.styles";
import { ExpandedViewModalPropType } from "./expandedviewmodal.types";

const ExpandedViewModal = (): JSX.Element => {
  const { modal: { modalProps = {} } = {} } = useGetAppState();

  const { expandedComponent } = modalProps as ExpandedViewModalPropType;

  return (
    <ExpandedViewModalStyled>
      <Flex>{expandedComponent}</Flex>
    </ExpandedViewModalStyled>
  );
};

export default ExpandedViewModal;
