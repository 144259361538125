type ProcessTypes = "filter" | "map";

function getProcessedData(
  data: any,
  keys: string[] = [],
  processType: ProcessTypes,
  compareValue?: string
): any {
  if (processType === "filter") {
    // if (typeof compareValue === "undefined") {
    //   throw new Error("Value to compare is not defined.");
    // }

    return data?.filter(
      (item: any) => compareValue && item[`${keys[0]}`] === compareValue
    );
  }

  return data?.map((item: any) => item[`${keys[0]}`]);
}

export function getPatternedDataByType(
  data: any,
  keys: string[] = [],
  chars: string[] = [],
  processType: ProcessTypes = "map",
  compareValue?: string
): any {
  if (keys.length) {
    if (chars[0] === ".") {
      return getPatternedDataByType(
        data?.[`${keys[0]}`],
        keys.slice(1),
        chars.slice(1),
        processType,
        compareValue
      );
    }
    if (chars[0] === ">") {
      return getPatternedDataByType(
        getProcessedData(data, keys, processType, compareValue),
        keys.slice(1),
        chars.slice(1),
        processType,
        compareValue
      );
    }
  }

  return data;
}
