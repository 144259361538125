import styled from "styled-components";

export const DeleteTagsFormStyled = styled.div`
  .ant-form {
    .form-content {
      padding: 24.8px 42.36px;

      .confirmation-text {
        background: ${({ theme }): string => theme.bgColors.LIGHT_BROWN_1};
        color: ${({ theme }): string => theme.textColors.BROWN_1};
        font-size: 13px;
        margin-bottom: 15px;
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid ${({ theme }): string => theme.borderColors.BROWN_1};
      }

      .ant-form-item {
        padding-left: 0px;

        .ant-form-item-control {
          width: 495px;

          .form-item-with-inline-info {
            .ant-form-item-control-input-content {
              display: flex;
              align-items: center;
              column-gap: 8px;
            }
          }
        }
      }
    }
  }
`;
