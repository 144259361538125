// <--- Library Imports Start --->
import axios from "axios";
import { Auth } from "aws-amplify";

import { useCallback, useState } from "react";
import { QueryFunctionContext, QueryKey } from "react-query";

// import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
// <--- Library Imports End --->

// <--- Constants Start --->
import { API_CONFIG } from "./constants/apiconfig";
import { LOCAL_STORAGE_CONSTANTS } from "./constants/localstorageconstants";
// <--- Constants End --->

// <--- Types Start --->
import {
  ApiMethods,
  OnExecuteRequestType,
  UseRequestWithMethod,
  UseRequestWithMethodState,
} from "./app.types";
// <--- Types End --->

// <--- Utils Start --->
import { CustomException } from "./customexception";
import { getEnvVariables } from "./utils/envvariables";
import {
  checkIsFDAMode,
  setAuthenticationInfoInLocalStorage,
  setCoginitoAuthInfoIntoLocalStorage,
} from "./utils";
// <--- Utils End --->

const dvSumAxios = axios;

const {
  REACT_APP_X_API_KEY,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
} = getEnvVariables();

export const REQUEST_HEADERS = {
  "x-api-key": REACT_APP_X_API_KEY,
};

export function appendQueryParamInUrl(str: string, arr: any[]): string {
  const splitStr = str?.split("url_param") || [];
  return splitStr.map((param, index) => `${param}${arr[index] || ""}`).join("");
}

export function fetchRequestWithoutHeaders(
  method?: ApiMethods
): (_params: QueryFunctionContext<QueryKey>) => Promise<unknown> {
  const source = axios.CancelToken.source();
  const promise = ({
    queryKey,
  }: QueryFunctionContext<QueryKey>): Promise<unknown> => {
    return dvSumAxios({
      timeout: 30000,
      url: queryKey?.[0] as string,
      method: method || "GET",
    })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  promise.cancel = (): void => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}

export function fetchRequestWithCustomHeaders(
  method?: ApiMethods,
  headers?: any
): (_params: QueryFunctionContext<QueryKey>) => Promise<unknown> {
  const source = axios.CancelToken.source();
  const promise = ({
    queryKey,
  }: QueryFunctionContext<QueryKey>): Promise<unknown> => {
    return dvSumAxios({
      timeout: 30000,
      url: queryKey?.[0] as string,
      method: method || "GET",
      headers,
    })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  promise.cancel = (): void => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}

export async function refreshTToken(): Promise<any> {
  const isFDAMode = checkIsFDAMode();

  try {
    if (isFDAMode) {
      return dvSumAxios(API_CONFIG?.refresh_auth_token?.url, {
        method: "POST",
        data: new URLSearchParams({
          grant_type: "refresh_token",
          client_id: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
          refresh_token:
            localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.refresh_token) || "",
        }),
      }).then((res) => {
        setCoginitoAuthInfoIntoLocalStorage(res);
      });
    }

    const currentUser = await Auth.currentAuthenticatedUser();

    const currentSession = currentUser?.signInUserSession;

    return new Promise((resolve) => {
      currentUser?.refreshSession(
        currentSession?.refreshToken,
        (_err: any, session: any) => {
          const { accessToken = {}, refreshToken = {}, idToken = {} } =
            session || {};
          setAuthenticationInfoInLocalStorage({
            accessToken: accessToken?.jwtToken,
            username: accessToken?.payload?.username,
            idToken: idToken?.jwtToken,
            email: idToken?.payload?.email,
            refreshToken: refreshToken?.token,
            expTime: accessToken?.payload?.exp,
          });

          resolve(true);
        }
      );
    });
  } catch {
    return new Promise((resolve) => {
      resolve(true);
    });
  }
}

async function checkTokenExpiredOrNot(
  config: {
    [key: string]: any;
  },
  includeAdditionalHeaders?: boolean
): Promise<any> {
  const expiryTime = localStorage.getItem(
    LOCAL_STORAGE_CONSTANTS?.exp_time_in_milli_seconds
  );
  if (
    Number(expiryTime) <= new Date().getTime() &&
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.access_token)
  ) {
    await refreshTToken();
    return dvSumAxios({
      ...config,
      headers: {
        ...config?.headers,
        ...(includeAdditionalHeaders && {
          Authorization: localStorage.getItem(
            LOCAL_STORAGE_CONSTANTS?.access_token
          ),
        }),
      },
    });
  }
  return dvSumAxios(config);
}

async function fetchRequest(
  api: string,
  method?: ApiMethods,
  headers?: any
): Promise<unknown> {
  const updatedHeaders = {
    ...REQUEST_HEADERS,
    ...headers,
    Authorization: localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.access_token),
  };

  return checkTokenExpiredOrNot(
    {
      timeout: 30000,
      url: api,
      method: method || "GET",
      headers: { ...updatedHeaders },
    },
    true
  )
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
}

export function fetchRequestWithoutParam(
  headers?: any,
  method?: ApiMethods
): (_params: QueryFunctionContext<string>) => Promise<unknown> {
  const source = axios.CancelToken.source();
  const promise = ({
    queryKey,
  }: QueryFunctionContext<string>): Promise<unknown> => {
    return fetchRequest(queryKey?.[0], method, headers);
  };

  promise.cancel = (): void => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}

async function requestWithMethod(
  url: string,
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  body: any,
  stateChanger: (_data: any, _error: any) => void,
  param?: any[],
  onSuccess?: (_response?: any) => void,
  onFailure?: (_error?: any) => void,
  getData?: boolean,
  headers?: any,
  includeAdditionalHeaders?: boolean,
  parser?: (_response?: any) => any,
  cancelToken?: any
): Promise<void> {
  try {
    const updatedUrl = param ? appendQueryParamInUrl(url, [...param]) : url;
    const updatedHeaders = {
      ...(includeAdditionalHeaders && REQUEST_HEADERS),
      ...headers,
      ...(includeAdditionalHeaders && {
        Authorization: localStorage.getItem(
          LOCAL_STORAGE_CONSTANTS?.access_token
        ),
      }),
    };

    const response = await checkTokenExpiredOrNot(
      {
        url: updatedUrl,
        method,
        data: body,
        timeout: 30000,
        headers: { ...updatedHeaders },
        cancelToken,
      },
      includeAdditionalHeaders
    );
    onSuccess && onSuccess(response);

    stateChanger(
      getData
        ? parser
          ? parser(response)
          : response?.data
        : response?.data?.message,
      false
    );
  } catch (error) {
    stateChanger(undefined, error);
    onFailure && onFailure(error);
  }
}

export function useRequestWithMethod(
  apiName: keyof typeof API_CONFIG,
  urlParam?: any[],
  getData?: boolean,
  onSuccess?: (_response?: any) => void,
  onFailure?: (_error?: any) => void,
  headers?: any,
  includeAdditionalHeaders = true
): UseRequestWithMethod {
  const [state, setState] = useState<UseRequestWithMethodState>();

  const stateChanger = useCallback(
    (data, error) => {
      setState({ isLoading: false, error, data });
    },
    [state]
  );

  const onExecuteRequest: OnExecuteRequestType = useCallback(
    (
      body?: any,
      updatedUrlParam?: any[],
      updatedOnSuccess?: (_response?: any) => void,
      updatedApiName?: keyof typeof API_CONFIG,
      updatedOnFailure?: (_error: any) => void,
      parser?: (_response?: any) => any,
      updatedHeaders?: any,
      cancelToken?: any
    ) => {
      const config = API_CONFIG[updatedApiName || apiName];
      const updatedUrl = config.url as string;
      setState((st) => ({ ...st, isLoading: true }));
      requestWithMethod(
        updatedUrl,
        config.method,
        body,
        stateChanger,
        updatedUrlParam || urlParam,
        updatedOnSuccess || onSuccess,
        updatedOnFailure || onFailure,
        getData,
        updatedHeaders || headers,
        includeAdditionalHeaders,
        config?.parser || parser,
        cancelToken
      );
    },
    [state, apiName, urlParam, headers, onSuccess, onFailure]
  );

  return { ...state, onExecuteRequest };
}

// export async function sendWebSocketJsonMessageWithToken(
//   sendJsonMessage: SendJsonMessage,
//   jsonMessage: {
//     action: "sendmessage" | "getconnectionId";
//     message: string | Object;
//   }
// ): Promise<any> {
//   const expiryTime = localStorage.getItem(
//     LOCAL_STORAGE_CONSTANTS?.exp_time_in_milli_seconds
//   );

//   if (
//     Number(expiryTime) <= new Date().getTime() &&
//     localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.access_token)
//   ) {
//     await refreshTToken();

//     sendJsonMessage({
//       ...jsonMessage,
//       Authorization: localStorage.getItem(
//         LOCAL_STORAGE_CONSTANTS?.access_token
//       ),
//     });

//     return;
//   }

//   sendJsonMessage({
//     ...jsonMessage,
//     Authorization: localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.access_token),
//   });
// }

const errorHandler = (error: any): CustomException => {
  if (error?.response?.status === 401) {
    // localStorage.clear();
    // window.location.href = APP_ROUTES.auth_routes.login;
  }
  throw new CustomException(error);
};

dvSumAxios.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export default dvSumAxios;
