import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { useGetAppState } from "../../../../customhooks";
import Dynamicform from "../../dynamicform";

import { AddRulesetFormStyled } from "./addrulesetform.styles";

import AddRulesetFormGrid from "./addrulesetform.comp/addrulesetformgrid/addrulesetformgrid";
import {
  AddRulesetFormType,
  MultipleRuleCheckType,
  MultipleRulesetDataType,
} from "./addrulesetform.types";

import { addRuleSetForm } from "./addrulesetform.config";
import { GetAddRuleFormContentProps } from "../../addruleform.types";

import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";
import { FilterCriteriaType } from "../../../../components/filtercriteria/filtercriteria.types";

import { rulesetFormSchema } from "../../../../utils/schemas/ruleschemas/rulesetschema";
import { getObjectKeys } from "../../../../utils";

const AddRulesetForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    columnsData,
    onAddRule,
    sourceId,
    tableId,
    ruleCategoryId,
    formId,
    ruleConfig,
    isEdit = false,
    propsOnCancel,
    onUpdateRule,
    rulesRef,
    srcTypeId,
  } = props;
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const { id = "", ruleType = "Ruleset" } = modalProps;

  const {
    filter_cond,
    filter_text,
    ref_filter_cond,
    ref_filter_text,
    rule_desc,
    rule_type,
    ...initialRuleset
  } = ruleConfig || {};

  const [
    multipleRulesetChecks,
    setMultipleRulesetChecks,
  ] = useState<MultipleRuleCheckType>(initialRuleset || {});

  const form = useForm<AddRulesetFormType>({
    defaultValues: {
      id,
      rule_type: ruleType,
      rule_description: "",
      is_filter_criteria_active: isEdit,
      ruleset_check: multipleRulesetChecks,
      filter_criteria: [],
    },
    mode: "onChange",
    resolver: yupResolver(rulesetFormSchema),
  });

  const { setValue } = form;

  const onSubmit = useCallback(
    (data: AddRulesetFormType) => {
      const criteria: FilterCriteriaType = data?.filter_criteria || [];

      const parsedfilterCriteria = filterCriteriaApiData(criteria, columnsData);

      const multipleRulesetChecksKeys = getObjectKeys(multipleRulesetChecks);

      const desc = data?.rule_description;

      const multipleRulesetChecksWithParsedfilters: MultipleRuleCheckType = {};

      multipleRulesetChecksKeys?.map((item) => {
        const currentColumnLocalFilterCriteria =
          multipleRulesetChecks?.[item]?.local_filters || [];

        if (multipleRulesetChecks?.[item]?.col_id) {
          multipleRulesetChecksWithParsedfilters[item] = {
            ...multipleRulesetChecks?.[item],
            col_filter: filterCriteriaApiData(
              currentColumnLocalFilterCriteria,
              columnsData
            ),
            saved_filters_length: currentColumnLocalFilterCriteria?.length,
          };
        }

        return "";
      });

      const parsedColIds =
        Object.values(multipleRulesetChecks)
          ?.map((item) => (item as MultipleRulesetDataType)?.col_id || 0)
          ?.filter((colId) => colId !== 0) || [];

      const updatedRuleConfig = {
        ...multipleRulesetChecksWithParsedfilters,
        rule_type: formId,
        rule_desc: desc,
        col_ids: parsedColIds || [],
        filter_cond: parsedfilterCriteria,
      };

      const addNewRulePayload = {
        col_ids: parsedColIds,
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: desc,
        src_id: Number(sourceId),
        tbl_id: Number(tableId),
        rule_priority: "NRM",
        rule_def_config: updatedRuleConfig,
        filter: parsedfilterCriteria,
      };

      isEdit ? onUpdateRule?.(updatedRuleConfig) : onAddRule(addNewRulePayload);
    },
    [multipleRulesetChecks, isEdit, tableId, sourceId, columnsData]
  );

  const onChangeMultipleRulesetChecks = useCallback(
    (finalRulesOnColumn: MultipleRuleCheckType) => {
      setMultipleRulesetChecks((st) => {
        setValue("ruleset_check", { ...st, ...finalRulesOnColumn });

        return {
          ...st,
          ...finalRulesOnColumn,
        };
      });
    },
    []
  );

  const initialFilters = ruleConfig?.filter_cond;

  return (
    <AddRulesetFormStyled>
      <FormProvider {...form}>
        <Dynamicform
          schema={addRuleSetForm(initialFilters, isEdit)}
          onSubmit={onSubmit}
          columnsData={columnsData}
          isEdit={isEdit}
          propsOnCancel={propsOnCancel}
        />

        <AddRulesetFormGrid
          columnsData={columnsData}
          onChangeMultipleRulesetChecks={onChangeMultipleRulesetChecks}
          rulesOnColumn={multipleRulesetChecks}
          tableId={tableId}
          isEdit={isEdit}
          rulesRef={rulesRef}
          srcTypeId={srcTypeId}
        />
      </FormProvider>
    </AddRulesetFormStyled>
  );
};

export default AddRulesetForm;
