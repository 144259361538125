import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { useGetAllDataSourceTypes } from "../../api/sourcesservice";
import { Button } from "../../components";

import { FormItemStyled, FormStyled } from "../../components/form";
import StateHandler from "../../components/statehandler/statehandler";

import { useCancelModal, useGetAppState } from "../../customhooks";
import { SourceTypeParserReturnType } from "../../parsers/sources/sourcesparser.types";

import { sortObjectsArrayByKey } from "../../utils";
import { EnableSourceFormStyled } from "./enablesourceform.styles";

import SearchableSourcesContainer from "./searchablesourcescontainer/searchablesourcescontainer";
import { useRequestWithMethod } from "../../api";

const EnableSourceForm = (): JSX.Element => {
  const [selectedSources, setSelectedSources] = useState<string[]>([]);

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const { id = 0, enable_source_types = [] } = modalProps as {
    id: number;
    enable_source_types: string[];
  };

  const onCancel = useCancelModal();

  const { handleSubmit } = useForm();

  const {
    parsedData: parsedSourceTypes,
    isLoading: isLoadingSourceTypes,
    error: errorGettingSourceTypes,
  } = useGetAllDataSourceTypes();

  const sortedSourceTypes = useMemo(
    () =>
      sortObjectsArrayByKey(
        parsedSourceTypes,
        "displayOrder"
      ) as SourceTypeParserReturnType[],
    [parsedSourceTypes]
  );

  const enabledSources = useMemo(
    () =>
      sortedSourceTypes?.filter((item) =>
        enable_source_types?.includes(item.id)
      ),
    [sortedSourceTypes, enable_source_types]
  );

  const supportedSources = useMemo(
    () =>
      sortedSourceTypes?.filter(
        (item) => !enable_source_types?.includes(item.id)
      ),
    [sortedSourceTypes, enable_source_types]
  );

  const onSuccess = useCallback(() => {
    onCancel();
  }, [modalProps]);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "enable_source_type",
    undefined,
    true,
    onSuccess
  );

  const onClickSourceType = useCallback(
    (sourceType: SourceTypeParserReturnType, selected: boolean) => {
      setSelectedSources((st) => [
        ...st?.filter((s) => s !== sourceType.id),
        ...((selected && [sourceType.id]) || []),
      ]);
    },
    [selectedSources]
  );

  const onSubmit = useCallback(() => {
    onExecuteRequest({
      saws_id: id,
      requested_src_type: selectedSources,
    });
  }, [selectedSources, id]);

  return (
    <StateHandler
      isFetching={isLoading || isLoadingSourceTypes}
      error={error || errorGettingSourceTypes}
      isModal
    >
      <EnableSourceFormStyled>
        <FormStyled
          paddingLeft="334px"
          onFinish={handleSubmit(onSubmit) as any}
          isItemColumnLayout
        >
          <div className="scroll-sec">
            <SearchableSourcesContainer
              className="a-section"
              headerTitle="Enabled"
              headerDesc="List of already enabled sources"
              sources={enabledSources}
              disableSearch={isLoadingSourceTypes}
              isSourceSelectable={false}
            />
            <SearchableSourcesContainer
              className="a-section"
              headerTitle="Support"
              headerDesc="Select sources to enable and download to Gateway"
              showSelectedCounter
              sources={supportedSources}
              selectedSources={selectedSources}
              disableSearch={isLoadingSourceTypes}
              onClickSourceType={onClickSourceType}
            />
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={selectedSources.length === 0}
            >
              Upgrade
            </Button>
          </FormItemStyled>
        </FormStyled>
      </EnableSourceFormStyled>
    </StateHandler>
  );
};

export default EnableSourceForm;
