import { useHistory } from "react-router";
import { ICellRendererParams } from "ag-grid-community";
import { SyntheticEvent, useCallback } from "react";

import { CloneRuleSummaryModalGridColumnsType } from "./clonerulesummarymodalgrid.type";
import {
  RuleNameRendererStyled,
  RuleStatusRendererStyled,
} from "./clonerulesummarymodalgrid.styles";

import LinkButton from "../../../../../linkbutton/linkbutton";

import { crossIcon, solidSuccessTickIcon } from "../../../../../../svgs";
import { TableProps } from "../../../../../aggridtable/aggridtable.types";
import {
  agGridStringComparator,
  getRuleDetailPageUrl,
} from "../../../../../../utils";

const errorIcon = crossIcon("15", "15");

export const RulesGridColumns: TableProps<CloneRuleSummaryModalGridColumnsType>["tableColumns"] = [
  {
    field: "ruleName",
    headerName: "Original Rule",
    width: 220,
    comparator: agGridStringComparator,
  },
  {
    field: "fromSource",
    headerName: "From Source",
    width: 220,
    comparator: agGridStringComparator,
  },
  {
    field: "clonedRuleName",
    headerName: "Cloned Rule",
    width: 200,
    comparator: agGridStringComparator,
  },
  {
    field: "toSource",
    headerName: "To Source",
    width: 220,
    comparator: agGridStringComparator,
  },
  {
    field: "desc",
    headerName: "Description",
    minWidth: 350,
    flex: 1,
    comparator: agGridStringComparator,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    minWidth: 220,
    flex: 1,
    comparator: agGridStringComparator,
  },
];

export const RuleNameRenderer = (props: ICellRendererParams): JSX.Element => {
  const { column, value = "" } = props;
  const params = column?.getColDef?.()?.cellRendererParams || {};

  const id = params?.isClonedRule ? props?.data?.clonedRuleId : props?.data?.id;

  const history = useHistory();

  const onNodeClick = useCallback(
    (e: SyntheticEvent) => {
      e?.stopPropagation();
      const url = getRuleDetailPageUrl(id);
      history?.push(url);
    },
    [id]
  );

  return (
    <RuleNameRendererStyled>
      <LinkButton
        onClick={onNodeClick}
        title={value}
        fontSize="14px"
        className="name-renderer"
      >
        {value}
      </LinkButton>
    </RuleNameRendererStyled>
  );
};

export const RuleStatusRenderer = (props: ICellRendererParams): JSX.Element => {
  return (
    <RuleStatusRendererStyled>
      {props?.value === "PSS" ? (
        <div className="status-success">{solidSuccessTickIcon}</div>
      ) : (
        <div className="status-error">{errorIcon}</div>
      )}
    </RuleStatusRendererStyled>
  );
};
