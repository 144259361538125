import { InformativeTextStyled } from "./informativetext.styles";
import { InformativeTextProps } from "./informativetext.types";

const InformativeText = ({
  desc = "",
  marginBottom = "",
  marginTop = "",
  width = "",
}: InformativeTextProps): JSX.Element => (
  <InformativeTextStyled
    marginBottom={marginBottom}
    marginTop={marginTop}
    width={width}
    className="informative-text"
  >
    {desc}
  </InformativeTextStyled>
);

export default InformativeText;
