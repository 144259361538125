import { useGetAppState } from ".";

type GetAppTypeTypes = {
  isExtWindow: boolean;
  isWebApp: boolean;
  isDesktopApp: boolean;
  isExtOrDesktop: boolean;
};

export function useGetAppType(): GetAppTypeTypes {
  const { appType } = useGetAppState();

  return {
    isExtWindow: appType === "EXT",
    isWebApp: appType === "WA",
    isDesktopApp: appType === "DKT",
    isExtOrDesktop: appType === "EXT" || appType === "DKT",
  };
}
