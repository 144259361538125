export const deleteViewConfirmationText =
  "Are you sure you want to delete the selected view?";

export const QUERY_PARAMS = {
  NODE_ID: "node-id",
  SELECTED_FILTER: "selected-filter",
  COLUMN_VALUE: "column-value",
  COLUMN_NAME: "column-name",
  COLUMN_ID: "column-id",
  WIDGET_ID: "widget-id",
};

export const LISTING_PAGE_TAB_FILTER_VALUES = {
  ALL: "all",
  NEWLY_ADDED_COUNT: "newly_added_count",
  INCOMPLETE_COUNT: "incomplete_count",
  DUPLICATE_COUNT: "duplicate_count",
  ASSIGNED_TO_COUNT: "assigned_to_count",
  RECENTLY_REFRESHED_COUNT: "recently_refreshed_count",
  RECENTLY_EXECUTED_COUNT: "recently_executed_count",
  ALERTS_AVAILABLE_COUNT: "alerts_available_count",
  USER_ACTIVITY_COUNT: "user_activity_count",
  DAILY_JOBS: "daily_jobs",
  WEEKLY_JOBS: "weekly_jobs",
  MONTHLY_JOBS: "monthly_jobs",
  RUNNING_JOBS: "running_count",
  SCHEDULED_JOBS: "scheduled_count",
  COMPLETED_JOBS: "completed_count",
  ABORTED_JOBS: "aborted_count",
  TOTAL_QUESTIONS_COUNT: "total_questions",
  TOTAL_FEEDBACK_COUNT: "total_feedback",
  USERS_COUNT: "users_count",
  APPROVED_COUNT: "approved_count",
  REJECTED_COUNT: "rejected_count",
  TO_REVIEW_COUNT: "to_review_count",
  SAVED_COUNT: "bookmarked_count",
};

export const LISTING_PAGE_TAGSET_NAME = {
  DATA_CLASSIFICATION: "Data Classification",
  SENSITIVE_TAG: "Sensitive",
};

export const PROGRESS_BAR_RULE_EXECUTION_STAT = 80;
