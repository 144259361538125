import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";

export const DetailedViewStyled = styled.div<{ isCategoryTutorial: boolean }>`
  .heading {
    font-size: 19.2px;
    font-family: OpenSansSemiBold;
    margin-bottom: 12.8px;
  }

  .thumbnail {
    cursor: ${({ isCategoryTutorial }): string =>
      isCategoryTutorial ? "default" : "pointer"};
    position: relative;

    img {
      width: 100%;
      max-height: 240px;
    }

    .thumbnail-img-icon {
      position: absolute;
    }

    .expand-icon {
      bottom: 9.6px;
      right: 9.6px;
      height: 25.6px;
      width: 25.6px;
      background: ${({ theme }): string =>
        hexToRgbA(theme.bgColors.GREY_40, 0.6)};
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .play-video-icon {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .desc {
    margin-top: 16px;

    .ant-btn-link {
      color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
      font-family: OpenSansSemiBold;
    }
  }

  .open-tutorial-btn {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 19.2px;
  }
`;
