const DVSUM_DOMAINS = [
  "localhost",
  "www.app-dev.dvsum.ai",
  "www.app.dvsum.ai",
  "team-c.d3o8ilwvunv51j.amplifyapp.com",
  "team-a.d1xg0habth6joj.amplifyapp.com",
  "team-b.d2jfuy3yplhya7.amplifyapp.com",
  "team-d.d2bmfpvouywf3g.amplifyapp.com",
];

export const checkIsFDAMode = (): boolean => {
  try {
    return !DVSUM_DOMAINS?.some(
      (domain) => domain === window?.location?.hostname
    );
  } catch {
    return true;
  }
};
