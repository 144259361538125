import { useMemo } from "react";

import { useElementSize } from "usehooks-ts";
import { Virtuoso } from "react-virtuoso";

import {
  ReviewCaddiFeedbacksModalListItemStyled,
  ReviewCaddiFeedbacksModalListStyled,
} from "./reviewcaddifeedbacksmodallist.styled";

import {
  ReviewCaddiFeedbacksModalListItemProps,
  ReviewCaddiFeedbacksModalListProps,
} from "./reviewcaddifeedbacksmodallist.types";

import { getTimeDifferenceInLocalTimeZone } from "../../../../../../utils";
import { thumbsDownWithBgSvg, thumbsUpWithBgSvg } from "../../../../../../svgs";

const thumbsDownWithBgIcon = thumbsDownWithBgSvg("24px", "24px");
const thumbsUpWithBgIcon = thumbsUpWithBgSvg("24px", "24px");

const ReviewCaddiFeedbacksModalListItem = (
  props: ReviewCaddiFeedbacksModalListItemProps
): JSX.Element => {
  const {
    feedbackType,
    question = "",
    chatTime = "",
    isSelected = false,
    onClick,
  } = props;

  return (
    <ReviewCaddiFeedbacksModalListItemStyled
      isSelected={isSelected}
      onClick={onClick}
    >
      <div
        className="feedback-icon"
        data-testid={`review-caddi-feedback-modal-list-thumbs-${
          feedbackType === "Approved" ? "up" : "down"
        }`}
      >
        {feedbackType === "Approved"
          ? thumbsUpWithBgIcon
          : thumbsDownWithBgIcon}
      </div>

      <div className="content-sec">
        <div className="question">{question}</div>
        <div className="time">{getTimeDifferenceInLocalTimeZone(chatTime)}</div>
      </div>
    </ReviewCaddiFeedbacksModalListItemStyled>
  );
};

const ReviewCaddiFeedbacksModalList = (
  props: ReviewCaddiFeedbacksModalListProps
): JSX.Element => {
  const { reviewFeedbacksList = [] } = props;

  const [elemSizeRef, size] = useElementSize();

  const virtuosoHeight = size?.height - 70;

  const selectedItemIndex = useMemo(
    (): number => reviewFeedbacksList?.findIndex((item) => item?.isSelected),
    [reviewFeedbacksList]
  );

  return (
    <ReviewCaddiFeedbacksModalListStyled
      ref={elemSizeRef}
      virtuosoHeight={virtuosoHeight}
    >
      <div className="list-desc">Click to view details</div>

      <div className="list-wrapper">
        <Virtuoso
          data={reviewFeedbacksList}
          itemContent={(index, item): JSX.Element => (
            <ReviewCaddiFeedbacksModalListItem
              key={index + item?.id}
              {...item}
            />
          )}
          className="feedbacks-virtual-list"
          initialTopMostItemIndex={{
            align: "start",
            index: selectedItemIndex,
          }}
        />
      </div>
    </ReviewCaddiFeedbacksModalListStyled>
  );
};

export default ReviewCaddiFeedbacksModalList;
