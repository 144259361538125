import { Tabs } from "antd";
import styled from "styled-components";

const { TabPane } = Tabs;

export const LeftTabsStyled = styled.div<{
  height?: string;
  minHeight?: string;
}>`
  &.dvsum-tabs-left-container {
    .left-tabs-header {
      align-items: center;
      min-height: auto;
      padding-bottom: 19.5px;
    }
    .dvsum-tabs-left {
      height: ${({ height }): string => height || "auto"};
      border: 1px solid rgba(112, 112, 112, 0.1);

      .ant-tabs-nav,
      .ant-tabs-content-holder {
        padding-top: 18.5px;
      }

      .ant-tabs-content-holder {
        .ant-tabs-content.ant-tabs-content-left {
          .ant-tabs-tabpane {
            padding-left: 56.5px;
            padding-bottom: 18.5px;
            padding-right: 20px;
          }
        }
      }

      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          &:after {
            box-shadow: none;
          }
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              font-size: 15px;
              font-family: OpenSans;
              color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
              padding: 8.86px 22px;
              width: 218.5px;

              &.ant-tabs-tab-active {
                background-color: ${({ theme }): string =>
                  theme.bgColors.BLUE_23};
                font-family: OpenSansSemiBold;

                div {
                  color: ${({ theme }): string => theme.textColors.BLUE_116};
                }
              }
            }

            .ant-tabs-ink-bar {
              right: unset;
              width: 4px;
              background: ${({ theme }): string => theme.bgColors.DARK_BLUE};
            }
          }
        }
      }
    }
  }
`;

export const LeftTabPaneStyled = styled(TabPane)``;
