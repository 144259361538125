import styled from "styled-components";

export const TableViewDrawerStyled = styled.div`
  .table-view-header {
    margin: 15px 20px 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heading {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-size: 13.6px;
      color: ${({ theme }): string => theme?.textColors?.GREY_40};
      margin: 0;

      .bold {
        font-family: OpenSansSemiBold;
      }
    }
  }

  .table-data {
    margin: 15px;
    height: 100%;
  }

  .gateway-check-with-child {
    bottom: 30%;

    .gateway-status-checking-wrapper {
      width: 550px;
    }
  }
`;
