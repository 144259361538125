import styled from "styled-components";

export const ConfirmationFormModalStyled = styled.div`
  .modal-form-content-container {
    padding: 31px 27px 37px 28px;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  }

  .ant-form .form-actions-sec {
    padding: 14px 18px 14px 18px;
  }
`;
