import { DataUser } from "../forms/addglossarydomainform/addglossarydomainform.types";
import { UserOrUsersGroupParsedType } from "../parsers/tablepage/tablepageparser.types";

export const getUniqueUsersForGlossary = (
  users: UserOrUsersGroupParsedType[],
  formValues: string[],
  relType: string,
  nodeType: string
): DataUser[] => {
  return formValues
    ? users
        ?.filter((user) => formValues?.indexOf(user?.label) >= 0)
        ?.map((item: UserOrUsersGroupParsedType) => {
          const { id: userId, type: userType } = item;

          return {
            user_id: Number.parseInt(userId || ""),
            user_type: userType || "USR",
            nodeRelType: relType,
            nodeType,
          };
        })
    : [];
};
