import { useState } from "react";
import { TimeCounterStyled } from "./timecounter.styles";
import { TimeCounterProps } from "./timecounter.types";
import { getTimeDiffInLocalMmss } from "../../utils";
import { useCustomPolling } from "../../customhooks";

const TimeCounter = (props: TimeCounterProps): JSX.Element => {
  const { startingPoint = "", stopCount = false } = props;

  const [counter, setCounter] = useState("00:00");

  if (!stopCount) {
    useCustomPolling(
      () => {
        setCounter(getTimeDiffInLocalMmss(startingPoint));
      },
      1000,
      []
    );
  }

  return <TimeCounterStyled>{counter}</TimeCounterStyled>;
};

export default TimeCounter;
