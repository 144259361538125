export const usersTableSVG = (
  <svg width="100%" height="100%" viewBox="0 0 1457.44 141.5">
    <defs>
      <style>{`.cls-1{fill:none;stroke:#707070;opacity:.1}.cls-3{fill:#4c4c4c}.cls-4{font-size:14px;font-family:OpenSansSemiBold,Open Sans;font-weight:600}.cls-5{fill:#f0f0f0}`}</style>
    </defs>
    <g
      id="Group_2467"
      data-name="Group 2467"
      transform="translate(-266.125 -253)"
    >
      <path
        id="Line_414"
        data-name="Line 414"
        className="cls-1"
        transform="translate(259.125 408)"
        d="M0 0h1612"
      />
      <path
        id="Line_397"
        data-name="Line 397"
        className="cls-1"
        transform="translate(259.125 353.5)"
        d="M0 0h1612"
      />
      <g
        id="Group_1798"
        data-name="Group 1798"
        transform="translate(18 -304)"
        style={{ opacity: 0.8 }}
      >
        <circle
          id="Ellipse_287"
          data-name="Ellipse 287"
          className="cls-3"
          cx="3"
          cy="3"
          r="3"
          transform="translate(1815 628)"
        />
        <circle
          id="Ellipse_288"
          data-name="Ellipse 288"
          className="cls-3"
          cx="3"
          cy="3"
          r="3"
          transform="translate(1823 628)"
        />
        <circle
          id="Ellipse_289"
          data-name="Ellipse 289"
          className="cls-3"
          cx="3"
          cy="3"
          r="3"
          transform="translate(1831 628)"
        />
      </g>
      <g id="Group_2466" data-name="Group 2466">
        <g id="Group_2102" data-name="Group 2102" transform="translate(-52)">
          <text id="Role" className="cls-4" transform="translate(326 286)">
            <tspan x="0" y="0">
              First Name
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1270"
          data-name="Rectangle 1270"
          className="cls-5"
          width="87"
          height="15"
          rx="7.5"
          transform="translate(274 320)"
        />
        <rect
          id="Rectangle_1495"
          data-name="Rectangle 1495"
          className="cls-5"
          width="87"
          height="15"
          rx="7.5"
          transform="translate(274 373)"
        />
      </g>
      <g id="Group_2465" data-name="Group 2465" transform="translate(-8)">
        <g id="Group_2102-2" data-name="Group 2102" transform="translate(113)">
          <text
            id="Role-2"
            data-name="Role"
            className="cls-4"
            transform="translate(326 286)"
          >
            <tspan x="0" y="0">
              Last Name
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1496"
          data-name="Rectangle 1496"
          className="cls-5"
          width="87"
          height="15"
          rx="7.5"
          transform="translate(439 373)"
        />
        <rect
          id="Rectangle_1487"
          data-name="Rectangle 1487"
          className="cls-5"
          width="87"
          height="15"
          rx="7.5"
          transform="translate(439 320)"
        />
      </g>
      <g id="Group_2464" data-name="Group 2464" transform="translate(-9)">
        <g id="Group_2378" data-name="Group 2378" transform="translate(-34)">
          <text
            id="Created_By"
            data-name="Created By"
            className="cls-4"
            transform="translate(629 286)"
          >
            <tspan x="0" y="0">
              Email
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1497"
          data-name="Rectangle 1497"
          className="cls-5"
          width="210"
          height="15"
          rx="7.5"
          transform="translate(595 373)"
        />
        <rect
          id="Rectangle_1488"
          data-name="Rectangle 1488"
          className="cls-5"
          width="210"
          height="15"
          rx="7.5"
          transform="translate(595 320)"
        />
      </g>
      <g id="Group_2458" data-name="Group 2458" transform="translate(-752)">
        <g id="Group_2099" data-name="Group 2099" transform="translate(410)">
          <text
            id="Created_By-2"
            data-name="Created By"
            className="cls-4"
            transform="translate(1227 286)"
          >
            <tspan x="0" y="0">
              User Group
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1494"
          data-name="Rectangle 1494"
          className="cls-5"
          width="210"
          height="15"
          rx="7.5"
          transform="translate(1637 320)"
        />
        <rect
          id="Rectangle_1503"
          data-name="Rectangle 1503"
          className="cls-5"
          width="210"
          height="15"
          rx="7.5"
          transform="translate(1637 373)"
        />
      </g>
      <g id="Group_2463" data-name="Group 2463" transform="translate(368)">
        <g id="Group_2100" data-name="Group 2100" transform="translate(-657)">
          <text
            id="Modified_By"
            data-name="Modified By"
            className="cls-4"
            transform="translate(1473 286)"
          >
            <tspan x="0" y="0">
              Status
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1489"
          data-name="Rectangle 1489"
          className="cls-5"
          width="65"
          height="15"
          rx="7.5"
          transform="translate(816 320)"
        />
        <rect
          id="Rectangle_1498"
          data-name="Rectangle 1498"
          className="cls-5"
          width="65"
          height="15"
          rx="7.5"
          transform="translate(816 373)"
        />
      </g>
      <g id="Group_2461" data-name="Group 2461" transform="translate(202)">
        <g id="Group_2100-3" data-name="Group 2100" transform="translate(-366)">
          <text
            id="Modified_By-3"
            data-name="Modified By"
            className="cls-4"
            transform="translate(1473 286)"
          >
            <tspan x="0" y="0">
              Modified By
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1491"
          data-name="Rectangle 1491"
          className="cls-5"
          width="170"
          height="15"
          rx="7.5"
          transform="translate(1107 320)"
        />
        <rect
          id="Rectangle_1500"
          data-name="Rectangle 1500"
          className="cls-5"
          width="170"
          height="15"
          rx="7.5"
          transform="translate(1107 373)"
        />
      </g>
      <g id="Group_2460" data-name="Group 2460" transform="translate(259)">
        <g id="Group_2100-4" data-name="Group 2100" transform="translate(-183)">
          <text
            id="Modified_By-4"
            data-name="Modified On"
            className="cls-4"
            transform="translate(1473 286)"
          >
            <tspan x="0" y="0">
              Modified On
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1492"
          data-name="Rectangle 1492"
          className="cls-5"
          width="206"
          height="15"
          rx="7.5"
          transform="translate(1290 320)"
        />
        <rect
          id="Rectangle_1501"
          data-name="Rectangle 1501"
          className="cls-5"
          width="206"
          height="15"
          rx="7.5"
          transform="translate(1290 373)"
        />
      </g>
      <rect
        id="Rectangle_1279"
        data-name="Rectangle 1279"
        className="cls-5"
        width="40"
        height="15"
        rx="7.5"
        transform="translate(1825 320)"
      />
      <rect
        id="Rectangle_1502"
        data-name="Rectangle 1502"
        className="cls-5"
        width="40"
        height="15"
        rx="7.5"
        transform="translate(1825.307 373)"
      />
    </g>
  </svg>
);
