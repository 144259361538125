import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { hexToRgbA } from "../../utils";

export const TermRefDrawerStyled = styled.div<{ hasPromintntTags: boolean }>`
  padding-bottom: 2px;

  .term-ref-header {
    padding: 32px 44px 22px 31px;

    .header_row_2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;

      .suggest-edit-btn {
        margin-top: 5px;
      }

      .node-title {
        font-size: 15px;
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        width: 100%;
        ${ellipsis}
        opacity: 0.8;
        margin-bottom: ${({ hasPromintntTags }): string =>
          hasPromintntTags ? "16px" : ""};
        padding-left: 39px;
      }

      .prominent-tags-sec {
        padding-left: 39px;
      }

      .section-header {
        flex: 1;

        .info {
          margin: 0;
          margin-right: 0px;

          .info-title {
            ${ellipsis}
            max-width:400px;
            line-height: unset;
          }
        }

        .deprecate-icon {
          display: flex;
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }

        .term-redirect-icon {
          svg {
            margin-right: 0px;
            margin-left: 12.5px;
          }
        }
      }
    }

    .header_row_3 {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      font-size: 15px;

      > span {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      }
    }

    .term-header-breadcrums {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      .item-icon {
        margin-right: 6px;
        display: flex;
      }
      .item-title {
        font-size: 16px;
        color: ${({ theme }): string => theme.textColors.GREY_34};
        ${ellipsis}
      }
      .right-icon {
        margin: 0px 11px;
        display: flex;
      }
    }
  }

  .term-ref-section,
  .first-type {
    margin-top: 34px;
    padding: 29px 29px 0 29px;
    border-top: 1px solid
      ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};

    .col-ref-sec-titile {
      min-height: unset;
      .title {
        font-size: 17px;
      }
    }

    .sec-row {
      margin-bottom: 9px;
      .main-row {
        .row-title {
          min-width: 217px;
        }
        .row-value {
          .replace-rendrer {
            color: ${({ theme }): string => theme.textColors.DARK_BLUE};
            display: flex;
            align-items: center;
            gap: 10px;
            > span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .managedby-section {
    > div {
      .section-vertical {
        .title {
          width: 217px;
        }

        .value-sec,
        .value-sec-custom-jsx {
          width: 59%;
        }
      }
    }
  }
  .first-type {
    margin-top: 0px;
  }
`;
