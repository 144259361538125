// <--- Components Start --->
import DQScore from "../../components/dqscore";
// <--- Components End --->

// <--- SVGs Start --->

// <--- SVGs End --->

// <--- Types Start --->
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { DQScoreProps } from "../../components/dqscore/dqscore.types";
// <--- Types End --->

// <--- Utils Start --->
import { getTermDetailPageUrl, getTimeDifference } from "../../utils";
import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";

import { TABLE_SECTION_TITLES } from "../../constants/labelconstants";
import StyledLink from "../../components/styledlink/styledlink";

import { useGetAppType } from "../../customhooks";
// <--- Utils End --->

const {
  overview: { audit_trail: AUDIT_TRAIL },
  data_quality: { last_updated: LAST_UPDATED, overall_score: OVERALL_SCORE },
  classification: { entity: ENTITY },
} = TABLE_SECTION_TITLES;

const EntityRendrer = (item: SectionRowProps): JSX.Element => {
  const { isExtOrDesktop } = useGetAppType();
  return item?.value ? (
    <StyledLink
      to={getTermDetailPageUrl(`${item?.selectedValues?.[0]}`)}
      target={isExtOrDesktop ? "_blank" : "_self"}
    >
      {item?.value}
    </StyledLink>
  ) : (
    <div>--</div>
  );
};

export const tableRefRenderer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [AUDIT_TRAIL]: (item) => (
    <div>
      {item?.value?.split(":")?.map((item) => (
        <div className="trail-item" key={`audit-trail-${item}`}>
          {item}
        </div>
      ))}
    </div>
  ),
  [OVERALL_SCORE]: (item) => (
    <div className="flex-col">
      <div className="flex gap-1 flex-start items-center">
        <DQScore {...(item?.additionalInfo as DQScoreProps)} showEmptyState />
      </div>
    </div>
  ),
  [ENTITY]: (item) => <EntityRendrer {...item} />,
};

export const tableRefCustomCompRendrer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [LAST_UPDATED]: (item) => (
    <div className="last-updated-static-text">
      {item?.value
        ? `Last updated ${getTimeDifference(
            utcTOLocalTimeZone(item?.value || "")
          )}`
        : "--"}
    </div>
  ),
};
