import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../constants";
import { dataSourceDetailPageTabsValues } from "../pages/datasourcedetailpage/datasourcedetailpage.constants";

export function getDataSourceDetailPageUrl(id: string): string {
  const recentlyVisitedTab =
    localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.recent_visited_datasource_detail_page_tab
    ) || dataSourceDetailPageTabsValues.overview;

  return `${APP_ROUTES.private_routes.data_sources}/${id}/${recentlyVisitedTab}`;
}
