import { Skeleton } from "antd";
import styled from "styled-components";

export const SkeltonButtonBlankSlate = styled(Skeleton.Button)<{
  width?: string;
  height?: string;
  marginTop?: string;
}>`
  .ant-skeleton-button {
    margin-top: ${({ marginTop }): string => marginTop || ""};
    height: ${({ height }): string => height || "10px"};
    width: ${({ width }): string => width || "64px"};
    vertical-align: middle;
  }
`;
