export const longDownArrow = (
  <svg width="9" height="33.939" viewBox="0 0 9 33.939">
    <g data-name="Group 3040">
      <path
        data-name="Path 2919"
        d="M19328.34 1456.8h-30.072"
        transform="rotate(-90 8938.02 10390.32)"
        style={{ fill: "none", stroke: "#bdbec4" }}
      />
      <path
        data-name="Polygon 7"
        d="M4.5 0 9 8H0z"
        transform="rotate(-180 4.5 16.97)"
        style={{ fill: "#bdbec4" }}
      />
    </g>
  </svg>
);
