import { AxiosResponse } from "axios";

import { RefResponseType, ReturnTypeOfRefParser } from "./refparser.types";

export function getRefParsedData({
  data,
}: AxiosResponse<RefResponseType[]>): ReturnTypeOfRefParser[] {
  return (data || [])?.map((item) => ({
    type: item?.REF_TYPE || "",
    id: item?.REF_ID || "",
    name: item?.REF_NAME || "",
    description: item?.REF_DESC || "",
    displayOrder: item?.REF_DISPLAY_ORDER,
    adhocInfo: item?.REF_ADHOC_INFO || "",
  }));
}
