import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const GuidelinesTabWrapperStyled = styled.div`
  margin-top: 25px;

  .bulk-del-btn {
    display: flex;
    align-items: center;
    column-gap: 6.4px;
    margin-bottom: 14.4px;
  }
`;

export const GuidelinesTabStyled = styled.div`
  border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

  .ag-root-wrapper {
    border: none;
  }

  .ag-header {
    border-bottom: 1px solid #e6e6e6;
  }

  .ag-header-row {
    font-weight: unset;
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme?.textColors?.GREY_40};
  }

  .guideline-type-cell {
    .ag-cell-wrapper {
      height: 62%;
    }
  }

  .ag-row-hover {
    :hover {
      background: ${({ theme }): string => theme.bgColors.BLUE_23};
    }
  }

  .ag-row-selected {
    background: ${({ theme }): string => theme.bgColors.BLUE_23};
    :hover {
      background: ${({ theme }): string => theme.bgColors.BLUE_23};
    }
  }

  .ag-theme-alpine {
    .ag-selection-checkbox {
      margin-right: 20px;
    }

    .ag-layout-auto-height {
      .ag-center-cols-clipper {
        min-height: fit-content;
      }
    }
  }

  .ag-checkbox-input-wrapper {
    margin-right: 10px;
    font-size: 13px;
    line-height: 13px;
    width: 13px;
    height: 13px;
    margin-top: 1px;
  }

  .ag-checkbox-input-wrapper:focus-within {
    box-shadow: none;
  }

  .ag-checkbox-input-wrapper.ag-checked {
    ::after {
      color: ${({ theme }): string => theme?.bgColors?.BLUE_116};
      background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
    }
  }
`;

export const TypeRendrerStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }): string => theme?.textColors?.GREY_40};

  .type-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 5px;
      margin-top: 3px;

      path {
        fill: ${({ theme }): string => theme?.bgColors?.BLUE_116};
      }
    }
  }
`;

export const GuidelineRendrerStyled = styled.div<{ isConcept: boolean }>`
  color: ${({ theme }): string => theme?.textColors?.GREY_40};
  line-height: ${({ isConcept }): number => (isConcept ? 1.5 : 2)};
  margin-top: ${({ isConcept }): string => (isConcept ? "11px" : "8px")};

  .concept-sec {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
    white-space: break-spaces;
    word-break: break-all;
  }

  > div:not(.concept-sec) {
    ${ellipsis}
  }
  .sql-query-preview-container {
    .query-wrapper {
      padding: 0;
      background: transparent;

      ${ellipsis}

      .query-line {
        color: ${({ theme }): string => theme?.textColors?.GREY_40};
        display: inline;
        margin-right: 5px;
      }
    }
  }
`;

export const UpdatedOnRendrerStyled = styled.div`
  color: ${({ theme }): string => theme?.textColors?.GREY_40};
`;

export const GuidelinesBlankstateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .blankstate-content {
    height: 400px;
    width: 400px;
    margin-top: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 50%;
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_25};

    svg {
      height: 26.4px;
      width: 18.4px;

      path {
        fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }

    .heading {
      font-size: 19.2px;
      font-family: OpenSansSemiBold;
      margin-top: 9.6px;
      margin-bottom: 4px;
    }

    .desc {
      width: 352px;
      margin-bottom: 19.2px;
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
    }
  }
`;

export const ActionRendrerStyled = styled.div`
  .delete-cnp-loading {
    margin-left: 9px;
  }

  .ant-btn-link {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.edit-btn {
      justify-content: flex-start;
      width: 18px;
    }
  }
`;
