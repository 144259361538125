import Dragger from "antd/lib/upload/Dragger";
import { closeIconInCircle, uploadIcon2 } from "../../svgs";
import { FilePreviewerStyled } from "./filepreviewer.styles";
import { FilePreviewerProps } from "./filepreviewer.types";

function FilePreviewer(props: FilePreviewerProps): JSX.Element {
  const { isAdded, file, onChange, onRemove, id = "" } = props;
  return (
    <FilePreviewerStyled isAdded={isAdded} file={file}>
      {file ? (
        <div className="img-preview-container">
          {onRemove && (
            <div
              className="img-cross-icon"
              role="button"
              onClick={(): void => {
                onRemove(id);
              }}
            >
              {closeIconInCircle()}
            </div>
          )}
          <img
            src={typeof file === "string" ? file : URL.createObjectURL(file)}
            className="preview-img"
          />
        </div>
      ) : (
        <Dragger
          fileList={[]}
          className="additional-info-uploader"
          beforeUpload={onChange}
        >
          {uploadIcon2()}
        </Dragger>
      )}
    </FilePreviewerStyled>
  );
}

export default FilePreviewer;
