import styled from "styled-components";
import { hexToRgbA } from "../../utils";

export const DvsumHelpDrawerStyled = styled.div`
  background: ${({ theme }): string => theme.borderColors.DARK_BLUE_20};
  color: ${({ theme }): string => theme.textColors.WHITE_COLOR};

  .header {
    min-height: 61px;
    padding: 15.2px 19.2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid
      ${({ theme }): string => hexToRgbA(theme.textColors.WHITE_COLOR, 0.2)};
    margin-bottom: 19.2px;

    .cross-icon {
      cursor: pointer;
      svg {
        fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
      }
    }
  }

  .content-sec {
    padding: 0 19.2px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 126px);
    max-height: calc(100vh - 126px);
    overflow: auto;

    .title {
      font-size: 12px;
      opacity: 0.8;
      font-family: OpenSansSemiBold;
      margin-bottom: 12.8px;
    }

    .article-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: white;
      padding: 4px 6px;

      svg {
        path {
          stroke: ${({ theme }): string =>
            theme.textColors.WHITE_COLOR} !important;
        }
      }

      &:hover {
        background: ${({ theme }): string => theme.bgColors.DARK_BLUE_1};
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      background: white;
      opacity: 0.2;
      transform: rotate(-180deg);
      margin: 11.2px 0;
    }

    .top-sec {
      .search-wrapper {
        margin-bottom: 25.6px;

        .search-input {
          border-radius: 3.2px;

          .ant-input {
            font-size: 12px;
          }

          .search-cross-icon {
            cursor: pointer;

            svg {
              path {
                fill: ${({ theme }): string => theme.textColors.GREY_18};
              }
            }
          }

          svg {
            path {
              fill: ${({ theme }): string => theme.textColors.BLUE_116};
            }
          }
        }
      }

      .ant-collapse {
        background: transparent;
        border: none;

        .ant-collapse-item {
          border-bottom: 1px solid
            ${({ theme }): string =>
              hexToRgbA(theme.textColors.WHITE_COLOR, 0.2)};

          .ant-collapse-header {
            padding-left: 0;
            font-family: OpenSansSemiBold;
            color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
            font-size: 12px;

            .ant-collapse-arrow {
              right: 8px;

              svg {
                transform: rotate(-90deg);
                fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
              }
            }

            .count-bubble {
              font-size: 9.6px;
              font-family: OpenSansBold;
              background: ${({ theme }): string => theme.bgColors.DARK_BLUE_1};
              padding: 4px 8px;
              margin-left: 6.4px;
              border-radius: 50%;
            }
          }

          .ant-collapse-content {
            color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
            background: transparent;
            border-top: 1px solid
              ${({ theme }): string =>
                hexToRgbA(theme.textColors.WHITE_COLOR, 0.2)};

            .ant-collapse-content-box {
              padding: 12.4px 0;
            }
          }

          &.ant-collapse-item-active {
            border: none;
          }
        }
      }
    }

    .bottom-sec {
      margin-top: 40px;
      .more-content-sec {
        margin-top: 16px;
        .thumbnails-sec-wrapper {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
`;
