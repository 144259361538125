// <--- Library Imports Start --->
import { useEffect, useMemo, useState, useCallback } from "react";

import { Tooltip } from "antd";
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Custom Hooks Start --->
import { useGetData, useSetData } from "../../../../../../customhooks";
// <--- Custom Hooks End --->

// <--- Constants Start --->
import { API_CONFIG } from "../../../../../../constants/apiconfig";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../constants";

// <--- Constants End --->

// <--- Components Start --->
import StaticTextRendrer from "../../../../../statictextrendrer";

import { DQRuleActionsForm } from "./dqruleactions.components";
import TableActionsSec from "../../../../../tableactionssec";

import DQRuleActionStatus from "../../../../../dqruleactionstatus";
import SuccessNotificationMessage from "../../../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

import StateHandler from "../../../../../statehandler";
import { SkeltonButtonBlankSlate } from "../../../../../skeltonblankslate";
// <--- Components End --->

// <--Services Start-->
import { useRequestWithMethod } from "../../../../../../api";
import { useGetRefByType } from "../../../../../../api/refservice";
// <--Services End-->

// <--- Styles Start --->
import {
  DQRuleActionsFormOverlayStyled,
  DQRuleActionsWrapperStyled,
} from "./dqruleactions.styles";
// <--- Styles End --->

// <--- Types Start --->
import { DQRuleActionsProps, SelectedActionType } from "./dqruleactions.types";

import {
  DetailsGridResponseType,
  DQRuleDetailsResponseType,
} from "../../../../../../parsers";

// <--- Types End --->

//  <--Utils Start-->
import {
  dqActionStatusMapping,
  getUserPermissions,
  openNotification,
  sortObjectsArrayByKey,
} from "../../../../../../utils";

import { dqRuleActionsMenuIconMapping } from "./dqruleactions.utils";
import { getPostLoginData } from "../../../../../../utils/getpostlogindata";
//  <--Utils End-->

const {
  get_details_grid_data: getDetailsGridData,
  get_dq_rule_details: getDqRuleDetails,
} = API_CONFIG;

const DQRuleActions = ({
  nodeId = "",
  ruleId = "",
  actionStatusId,
  actionStatus = "",
  ruleActionDetails = [],
  dqRulesgridNodeId,
  nodeType,
}: DQRuleActionsProps): JSX.Element => {
  const [actionsFormVisible, setActionsFormVisible] = useState(false);

  const [selectedAction, setSelectedAction] = useState<SelectedActionType>({});

  const {
    id: selectedActionId = "",
    name: selectedActionName = "",
  } = selectedAction;

  const {
    is_account_admin: isAccountAdmin,
    is_catalog_admin: isCatalogAdmin,
    is_catalog_editor: isCatalogEditor,
  } = getUserPermissions();

  const showDQRuleActionsMenu =
    isAccountAdmin || isCatalogAdmin || isCatalogEditor;

  const defaultResolutionAction =
    selectedAction?.id === "RSL" && ruleActionDetails[0]?.id === "INP"
      ? ruleActionDetails[0]?.resolution_action_id
      : "";

  const onSetData = useSetData();
  const onGetData = useGetData();

  const reset = useCallback(() => {
    setActionsFormVisible(false);
    setSelectedAction({});
  }, []);

  const onSuccess = useCallback(
    (res) => {
      const rulesGridData = onGetData(getDetailsGridData, [
        dqRulesgridNodeId,
      ]) as AxiosResponse<DetailsGridResponseType[]>;
      if (rulesGridData && dqRulesgridNodeId) {
        const findedObj = rulesGridData?.data?.filter(
          (item) => `${item?.RULE_ID}` === `${ruleId}`
        );

        const updatedFindedObj: DetailsGridResponseType = {
          ...findedObj?.[0],
          RULE_ACTION_STATUS_ID: selectedActionId,
          RULE_ACTION_STATUS: selectedActionName,
          RULE_ACTION_DETAILS: JSON.stringify(res?.data),
        };

        onSetData(
          getDetailsGridData,
          { ...updatedFindedObj },
          [dqRulesgridNodeId],
          { action: "UPDATE", key: "RULE_ID" }
        );
      }

      const rulesDetails = onGetData(getDqRuleDetails, [
        nodeId,
        nodeType,
      ]) as AxiosResponse<DQRuleDetailsResponseType[]>;

      const findedRuleDetailsObj = rulesDetails?.data?.filter(
        (item) => `${item?.RULE_ID}` === `${ruleId}`
      );

      const updatedFindedRuleDetailsObj: DQRuleDetailsResponseType = {
        ...findedRuleDetailsObj?.[0],
        RULE_ACTION_STATUS_ID: selectedActionId,
        RULE_ACTION_STATUS: selectedActionName,
        RULE_ACTION_DETAILS: JSON.stringify(res?.data),
      };

      onSetData(
        getDqRuleDetails,
        updatedFindedRuleDetailsObj,
        [nodeId, nodeType],
        { action: "UPDATE", key: "RULE_ID" }
      );

      openNotification(
        <SuccessNotificationMessage message="Status has been updated" />
      );

      reset?.();
    },
    [selectedAction]
  );

  const {
    onExecuteRequest,
    isLoading: statusUpdateIsLoading,
    error: statusUpdateError,
  } = useRequestWithMethod(
    "update_dq_rule_action_status",
    [nodeId, ruleId],
    true,
    onSuccess
  );

  useEffect(() => {
    if (selectedActionId) {
      if (selectedActionId === "OPN") {
        const { user_info: userInfo } = getPostLoginData();
        onExecuteRequest({
          rule_action: {
            action_status: selectedActionId,
            action_on: new Date(),
            action_by: userInfo?.user_name,
          },
        });
      } else {
        setActionsFormVisible(true);
      }
    }
  }, [JSON.stringify(selectedAction)]);

  const {
    parsedData: actionsParsedData,
    isLoading: actionsIsLoading,
    error: actionsError,
  } = useGetRefByType("DQ_ACTION_STATUS");

  const sortedActionStatuses = useMemo(
    () => sortObjectsArrayByKey(actionsParsedData, "displayOrder"),
    [actionsParsedData]
  );

  const actionStatusOptions = sortedActionStatuses
    ?.filter((sortedActionStatus) => {
      if (actionStatusId === "ASN") {
        return (sortedActionStatus?.id as SelectedActionType) !== "ASN";
      }

      return (sortedActionStatus?.id as SelectedActionType) !== "RSN";
    })
    ?.map((actionStatus) => {
      const tempActionStatusId: SelectedActionType["id"] =
        (actionStatus?.id as SelectedActionType["id"]) || "ASN";

      return {
        name: actionStatus?.name,
        icon: dqRuleActionsMenuIconMapping(tempActionStatusId),
        onClick: (): void =>
          setSelectedAction({
            id: tempActionStatusId,
            name: actionStatus?.name,
          }),
        disabled: actionStatus?.id === actionStatusId,
      };
    });

  return (
    <DQRuleActionsWrapperStyled
      actionsFormVisible={actionsFormVisible}
      data-testid={`dq-rule-actions-component-${nodeId}`}
    >
      {actionsFormVisible && <DQRuleActionsFormOverlayStyled />}
      {actionStatusId ? (
        <DQRuleActionStatus
          data-testid={`dq-rule-actions-action-status-${nodeId}`}
          status={actionStatus}
          statusId={actionStatusId}
          type={dqActionStatusMapping(actionStatusId, actionStatus)?.type}
          ruleActionDetails={ruleActionDetails}
        />
      ) : (
        <StaticTextRendrer
          text="No action required"
          fontSize="14px"
          margin="2px 5px 0px 0px"
        />
      )}
      {showDQRuleActionsMenu && (
        <Tooltip
          destroyTooltipOnHide
          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
          getPopupContainer={(trigger): HTMLElement =>
            trigger?.parentNode as HTMLElement
          }
          title={
            actionsFormVisible ? (
              <DQRuleActionsForm
                data-testid={`dq-rule-actions-component-rule-actions-form-${nodeId}`}
                selectedAction={selectedAction}
                onCancel={(): void => reset()}
                isLoading={!!statusUpdateIsLoading}
                error={statusUpdateError}
                onExecuteRequest={onExecuteRequest}
                defaultResolutionAction={defaultResolutionAction}
              />
            ) : undefined
          }
          trigger={[]}
          placement="bottomRight"
          onVisibleChange={(visiblility): void =>
            setActionsFormVisible(visiblility)
          }
          visible={actionsFormVisible}
        >
          <StateHandler
            isFetching={actionsIsLoading}
            error={actionsError}
            blankSlate={<SkeltonButtonBlankSlate width="25px" height="25px" />}
          >
            <div>
              <TableActionsSec
                isSvgNoHighlight
                data={actionStatusOptions}
                data-testid={`dq-rule-actions-menu-${nodeId}`}
              />
            </div>
          </StateHandler>
          <StateHandler
            isModal={statusUpdateIsLoading && selectedActionId === "OPN"}
            isFetching={statusUpdateIsLoading && selectedActionId === "OPN"}
            error={statusUpdateError}
          >
            <div />
          </StateHandler>
        </Tooltip>
      )}
    </DQRuleActionsWrapperStyled>
  );
};

export default DQRuleActions;
