import { memo, useMemo } from "react";
import { CustomizedPieLabelPropsType } from "./customizedpielabel.types";
import { numberFormatter } from "../../../utils";

const RADIAN = Math.PI / 180;

const CustomizedPieLabel = (
  props: CustomizedPieLabelPropsType
): JSX.Element | null => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    index,
    name,
    value,
    fill,
    isZero,
    chartInExpandedView,
    data,
    dataKey,
    labelInterval,
  } = props;

  const avgVal = useMemo(
    () =>
      data?.reduce((sum, val) => sum + val?.[dataKey] || 0, 0) / data?.length,
    [data, dataKey]
  );

  const valArr = useMemo(() => data.map((item) => item?.[dataKey]), [
    data,
    dataKey,
  ]);
  const minVal = Math.min(...valArr);
  const maxVal = Math.max(...valArr);

  let shouldRender = true;

  if (data?.length < 10) {
    if ((isZero ? 0 : value) <= Math.max(Math.ceil(minVal / maxVal), 1)) {
      shouldRender = false;
    }
  } else if (data?.length > 250) {
    if (
      index % labelInterval !== 0 ||
      (isZero ? 0 : value) <= Math.max(Math.ceil(minVal / maxVal), 1) + 1
      // (isZero ? 0 : value) < avgVal
    ) {
      shouldRender = false;
    }
  } else if (
    (isZero ? 0 : value) < avgVal ||
    (isZero ? 0 : value) <= Math.max(Math.ceil(minVal / maxVal), 1) + 1
  ) {
    shouldRender = false;
  }

  if (!shouldRender) return null;

  const isRightSide = midAngle <= 90 || midAngle >= 270;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 0) * cos;
  const sy = cy + (outerRadius + 0) * sin;
  const mx = cx + (outerRadius + 5) * cos;
  const my = cy + (outerRadius + 5) * sin;
  const ex = mx + (isRightSide ? 1 : -1) * 22;
  const ey = my;

  const textAnchor = isRightSide ? "start" : "end";

  const truncatedName =
    name.length > 25 && !chartInExpandedView ? `${name.slice(0, 25)}...` : name;

  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (isRightSide ? 1 : -1) * 8}
        y={ey}
        dy={4}
        textAnchor={textAnchor}
        fill="#333"
        fontSize="11px"
      >
        <tspan>{truncatedName}</tspan>
        <title>
          {name} ({isZero ? "0" : numberFormatter(String(value))})
        </title>
        <tspan style={{ fill: "#999" }}>
          {" "}
          ({isZero ? "0" : numberFormatter(String(value))})
        </tspan>
      </text>
    </g>
  );
};

export default memo(CustomizedPieLabel);
