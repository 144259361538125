import { pipe, pluck, uniq, length, map, zipObj } from "ramda";

export const getDistinctCount = (
  data: Object[],
  keys: string[]
): { [key: string]: number } => {
  try {
    const getCount = (prop: any): any =>
      pipe(
        pluck(prop), // Extract the values of the specified property
        uniq, // Get the unique values
        length // Count the unique values
      )(data);

    // Calculate distinct counts for each property
    const distinctCounts = map(getCount, keys);

    // Use the distinctCounts array to get the counts for each property
    return zipObj(keys, distinctCounts);
  } catch {
    return {};
  }
};

// Define an array of properties you want to count
