export const isNumberInRange = (
  num: number | string,
  min: number | string,
  max: number | string,
  includeEndNum?: boolean
): boolean => {
  try {
    const typeNum = Number(num);
    const typeMin = Number(min);
    const typeMax = Number(max);
    return (
      typeNum >= typeMin &&
      (includeEndNum ? typeNum <= typeMax : typeNum <= typeMax)
    );
  } catch {
    return false;
  }
};
