import StateHandler from "../statehandler/statehandler";
import { PageHeaderStyled } from "./pageheader.styles";
import { PageHeaderProps } from "./pageheader.types";

export const PageHeaderContent = (props: PageHeaderProps): JSX.Element => {
  const { leftComponent, rightComponent } = props;

  return (
    <>
      <div className="left">{leftComponent}</div>
      <div className="right">{rightComponent}</div>
    </>
  );
};

function PageHeader(props: PageHeaderProps): JSX.Element {
  const { stateHandlerProps, headerHeight } = props;
  return (
    <PageHeaderStyled
      className="page-header"
      headerHeight={headerHeight}
      {...props}
    >
      {stateHandlerProps ? (
        <StateHandler {...stateHandlerProps}>
          <PageHeaderContent {...props} />
        </StateHandler>
      ) : (
        <PageHeaderContent {...props} />
      )}
    </PageHeaderStyled>
  );
}

export default PageHeader;
