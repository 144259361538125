import { MyPreferencesModalStyled } from "./mypreferencesmodal.styles";
import { ChatPreferencesView } from "../../../../pages/mysettingspage";

const MyPreferencesModal = (): JSX.Element => {
  return (
    <MyPreferencesModalStyled>
      <ChatPreferencesView isCallingInModal />
    </MyPreferencesModalStyled>
  );
};

export default MyPreferencesModal;
