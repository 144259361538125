import { Switch } from "antd";
import styled from "styled-components";
import { SwitchFieldStyledProps } from "./switchfield.types";

export const SwitchFieldWrapperStyled = styled.div<{ isLabelBold?: boolean }>`
  display: flex;
  column-gap: 9.6px;

  .label-desc {
    max-width: 400px;

    .label {
      font-family: ${({ isLabelBold }): string =>
        isLabelBold ? "OpenSansSemiBold" : "OpenSans"};
    }

    .desc {
      font-size: 12px;
      color: ${({ theme }): string => theme.textColors.GREY_26};
    }
  }
`;

export const SwitchFieldStyled = styled(Switch)<SwitchFieldStyledProps>`
  background-color: ${({ theme }): string => theme.bgColors.DARK_GREY_20};
  width: ${({ width }): string => width || "51px"};
  height: ${({ height }): string => height || "20px"};
  margin-top: ${({ isLabelOrProp }): string => (isLabelOrProp ? "3px" : "0px")};

  .ant-switch-handle {
    width: 15px;
    height: 15px;
    left: 3px;
    top: 2.5px;
  }

  &.ant-switch {
    background-color: ${({ theme, bgColorActive }): string =>
      bgColorActive ? theme.bgColors.BLUE_116 : ""};
  }

  .ant-switch-inner {
    font-family: "OpenSansSemiBold";
    margin-left: 18px;
  }

  &.ant-switch-checked {
    background-color: ${({ theme }): string => theme.bgColors.BLUE_116};

    .ant-switch-handle {
      left: calc(100% - 15px - 2.5px);
    }

    .ant-switch-inner {
      margin-left: 6px;
      margin-right: 18px;
    }
  }
`;

export const SwitchContainerStories = styled.div`
  display: flex;
  gap: 20px;
`;
