import styled from "styled-components";

export const DefinitionActionBarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .save-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
