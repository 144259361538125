// <--- Import Statements Start --->
import styled from "styled-components";
// <--- Import Statements End --->

// <--- Styles Start --->
import { ellipsis } from "../../styles/app.styles";
// <--- Styles End --->

// <--- Types Start --->
import { GenericProminentTagStyledProps } from "./genericprominenttag.types";
// <--- Types End --->

// <--- Utils Start --->
import { prominentTagsColorMapping } from "../../utils/prominenttagscolormapping";
// <--- Utils End --->

export const GenericProminentTagStyled = styled.div<GenericProminentTagStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  min-width: 0px;
  width: fit-content;

  > span {
    max-width: 180px;
    ${ellipsis};
  }

  background: ${({ theme, type }): string =>
    prominentTagsColorMapping(theme)[type]?.bgColor};
  color: ${({ theme, type }): string =>
    prominentTagsColorMapping(theme)[type]?.textColor};
  border: 1px solid
    ${({ theme, type }): string =>
      prominentTagsColorMapping(theme)[type]?.borderColor};

  font-family: ${({ isBoldView }): string =>
    isBoldView ? "OpenSansSemiBold" : ""};
  border-radius: ${({ isBoldView }): string => (isBoldView ? "23px" : "3px")};
  height: ${({ isBoldView }): string => (isBoldView ? "28px" : "24px")};
  font-size: ${({ isBoldView }): string => (isBoldView ? "14px" : "12px")};
`;

export const RemainingTagsCountStyled = styled(GenericProminentTagStyled)`
  font-family: "OpenSansBold";
`;
