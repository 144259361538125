import styled from "styled-components";

export const AddContextViewStyled = styled.div`
  .header {
    padding: 18px 16px;
    font-family: "OpenSansSemiBold";
    border-bottom: 1px solid
      ${({ theme }): string => theme?.borderColors?.GREY_56};

    .back-btn {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }

  .content {
    padding: 16px;

    .title-sec {
      margin-bottom: 16px;

      .heading {
        font-family: "OpenSansSemiBold";
      }

      .desc {
        font-size: 12px;
        color: ${({ theme }): string => theme?.borderColors?.GREY_24};
      }
    }

    .textarea-field-container {
      .error {
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }

  .add-content-view-action-sec {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    column-gap: 9.6px;
  }
`;
