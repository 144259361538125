// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants";
// <--- Constants End --->

// <--- Types Start --->
import { ShareLevelId } from "../components/sharelevelicon/sharelevelicon.types";
// <--- Types End --->

// <--- Utils Start --->
import { AnalysisUserType } from "../parsers";
import { getPostLoginData } from "./getpostlogindata";
// <--- Utils End --->

export const isAnalysisDetailPageAccessible = (params: {
  authorEmail: string;
  sharedWithUsers: AnalysisUserType;
  shareLevelId: ShareLevelId;
}): boolean => {
  const { authorEmail, sharedWithUsers, shareLevelId } = params;

  const { user_info: userInfo = {} } = getPostLoginData();

  const loggedInUserEmail =
    localStorage?.getItem(LOCAL_STORAGE_CONSTANTS?.email) || "";

  const isAuthorLoggedIn =
    authorEmail?.toLocaleLowerCase() === loggedInUserEmail?.toLocaleLowerCase();

  const isUserExistsInList = sharedWithUsers?.find(
    (item) =>
      item?.email?.toLocaleLowerCase() ===
      loggedInUserEmail?.toLocaleLowerCase()
  );

  const isSharedUserHasEditAccess = sharedWithUsers?.find(
    (item) =>
      item?.email?.toLocaleLowerCase() ===
        loggedInUserEmail?.toLocaleLowerCase() &&
      item?.accessType === "USER_EDITOR"
  );

  const isUserExistsInSharedGroup = sharedWithUsers
    ?.filter(
      (item) => item?.type === "UGP" && item?.accessType === "USER_EDITOR"
    )
    ?.map((item) => item?.id)
    ?.includes(`${userInfo?.user_group_id}`);

  const isAccessibleToLimited =
    isAuthorLoggedIn ||
    !!isSharedUserHasEditAccess ||
    (isUserExistsInSharedGroup && !isUserExistsInList);

  switch (shareLevelId) {
    case "NON":
      return isAuthorLoggedIn;
    case "LIM":
      return isAccessibleToLimited;
    case "ALL":
    default:
      return true;
  }
};
