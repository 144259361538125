import styled from "styled-components";
import LinkButton from "../../../../../../components/linkbutton/linkbutton";

export const CatalogGridActionsWrapperStyled = styled.div`
  .ant-dropdown-menu {
    width: 250px;
  }

  .all-exceptions-btn {
    display: flex;
    align-items: center;
    column-gap: 22px;
    padding: 20px 9px;
    :hover {
      background-color: transparent;
      color: ${({ theme }): string => theme.textColors.BLUE_116};
    }
  }

  .dropdown-icon {
    height: 15px;

    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }

  .update-schedule-btn,
  .activate-job-btn {
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }

    :hover {
      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
      }
    }
  }

  .deactivate-job-btn {
    svg {
      width: 13px;
      height: 14px;
    }
  }
`;

export const ThreeDotMenuButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;

  .action-icon {
    height: 16px;
  }

  svg {
    circle {
      fill: ${(props): string => props.theme.bgColors.BLUE_116};
    }
  }

  :hover,
  :active {
    background: ${({ theme }): string => theme.bgColors.BLUE_23};
  }
`;
