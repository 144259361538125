import LinkButton from "../../../../../../../../components/linkbutton/linkbutton";
import { SHOW_RELATED_DATASETS_BTN_TEXT } from "../../analysisdetailpageexplorersec.constants";
import { RelatedButtonStyled } from "./showrelateddatasetbutton.styles";
import { ShowRelatedDatasetButtonType } from "./showrelateddatasetbutton.types";

const ShowRelatedDatasetButton = (
  props: ShowRelatedDatasetButtonType
): JSX.Element => {
  const { isShowRelatedDatasets, onClickShowRelatedDatasets, disabled } = props;
  return (
    <RelatedButtonStyled className="dataset-wrapper">
      <LinkButton
        className={`related-datasets-btn ${
          isShowRelatedDatasets
            ? "hide-related-datasets-btn"
            : "show-related-datasets-btn"
        }`}
        onClick={onClickShowRelatedDatasets}
        data-testid="analysis-detail-page-explorer-sec-table-browser-sec-related-datasets-btn"
        disabled={disabled}
      >
        {SHOW_RELATED_DATASETS_BTN_TEXT}
      </LinkButton>
    </RelatedButtonStyled>
  );
};

export default ShowRelatedDatasetButton;
