import styled from "styled-components";
import { Button } from "..";

export const SuggestAnEditButtonStyled = styled(Button)`
  column-gap: 6px;
  svg {
    path {
      fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    }
  }
`;
