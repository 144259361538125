import {
  barrChartIcon,
  columnChartIcon,
  donutChartIcon,
  kpiChartIcon,
  lineChartIcon,
  stackedBarChartIcon,
  stackedColumnChartIcon,
} from "../svgs";

export type ChartType = "COL" | "BAR" | "DNT" | "KPI" | "LIN" | "STB" | "STC";

export function getChartIcon(chartType: ChartType): JSX.Element {
  const chartIcons = {
    COL: columnChartIcon(),
    BAR: barrChartIcon(),
    DNT: donutChartIcon(),
    KPI: kpiChartIcon(),
    LIN: lineChartIcon(),
    STB: stackedBarChartIcon(),
    STC: stackedColumnChartIcon(),
  };

  return chartIcons[chartType];
}

export function getChartImage(chartType: ChartType): JSX.Element {
  const chartImg = {
    COL: <img src="/assets/chartimages/columnchart.png" alt="column-chart" />,
    BAR: <img src="/assets/chartimages/barchart.png" alt="bar-chart" />,
    DNT: <img src="/assets/chartimages/donutchart.png" alt="donut-chart" />,
    KPI: <img src="/assets/chartimages/kpichart.png" alt="kpi-chart" />,
    LIN: <img src="/assets/chartimages/linechart.png" alt="line-chart" />,
    STB: (
      <img
        src="/assets/chartimages/stackbarchart.png"
        alt="stacked-bar-chart"
      />
    ),
    STC: (
      <img
        src="/assets/chartimages/stackcolumnchart.png"
        alt="stacked-column-chart"
      />
    ),
  };

  return chartImg[chartType];
}
