import { numberFormatter } from "../../utils";
import { HorizontalDividerStyled } from "../dividers/dividers.styles";
import MultiStatusProgressbar from "../multistatusprogressbar";
import { ProgressBarItem } from "../multistatusprogressbar/multistatusprogressbar.types";
import {
  ProfilingCountProgressbarStyled,
  ProfilingCountProgressbarTooltipStyled,
} from "./profilingcountprogressbar.styles";
import { ProfilingCountProgressbarTypes } from "./profilingcountprogressbar.types";

function ProfilingCountProgressbar(
  props: ProfilingCountProgressbarTypes
): JSX.Element {
  const { data } = props;

  const findValueOfProfiling = (
    type: ProgressBarItem["type"]
  ): ProgressBarItem["valueDetail"] =>
    data?.find((item: ProgressBarItem) => item?.type === type)?.valueDetail;

  const complete = findValueOfProfiling("success") || "";
  const unique = findValueOfProfiling("warning") || "";
  const blank = findValueOfProfiling("error") || "";

  const total =
    (Number(complete) || 0) + (Number(unique) || 0) + (Number(blank) || 0);

  return (
    <ProfilingCountProgressbarStyled data-testid="profiling-count-progress-bar-wrapper">
      <MultiStatusProgressbar
        {...props}
        data={data}
        tooltipProps={{
          title: (
            <ProfilingCountProgressbarTooltipStyled>
              <div className="type">
                <div className="label">Total:</div>
                <div className="total-count">{numberFormatter(`${total}`)}</div>
              </div>
              <HorizontalDividerStyled marginBottom="3px" marginTop="3px" />
              <div className="type">
                <div className="label">Empty or Null:</div>
                <div className="blank-count">{numberFormatter(blank)}</div>
              </div>
              <div className="type">
                <div className="label">Unique:</div>
                <div className="unique-count">{numberFormatter(unique)}</div>
              </div>
              <div className="type">
                <div className="label">Complete:</div>
                <div className="complete-count">
                  {numberFormatter(complete)}
                </div>
              </div>
            </ProfilingCountProgressbarTooltipStyled>
          ),
          placement: "right",
        }}
      />
    </ProfilingCountProgressbarStyled>
  );
}

export default ProfilingCountProgressbar;
