import { AxiosResponse } from "axios";
import { useQueries } from "react-query";
import {
  appendQueryParamInUrl,
  fetchRequestWithoutHeaders,
  fetchRequestWithoutParam,
} from "../../api";
import { KeyValuePairType, NodeType } from "../../app.types";
import { API_CONFIG } from "../../constants/apiconfig";
import {
  CurrentNodeParserReturnType,
  getParsedCurrentNode,
  GovernanceViewsParserReturnType,
  getParsedGovernanceViewFilters,
  GovernanceViewFiltersParserReturnType,
  getParsedGovernanceViewFields,
  GovernanceViewFieldsParserReturnType,
  GovernanceViewFieldDataParserReturnType,
  getParsedFieldData,
  getParsedIsViewFavourite,
} from "../../parsers";
import {
  AllGovernanceViewsParserReturnType,
  BotEngagementsReturnType,
  BotTopUsersReturnType,
  IsViewFavouriteParserReturnType,
  MassEditableParsedFields,
  SchedulersListingParsedType,
} from "../../parsers/listingpagesparser/listingpagesparser.types";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

import {
  getParsedBotEngagements,
  getParsedBotTopUsers,
  getParsedAllGovernanceViews,
  getParsedCategorisedGovernanceViews,
  getParsedSchedulersList,
} from "../../parsers/listingpagesparser/listingpagesparser";

const {
  get_current_node: getCurrentNode,
  get_governance_views: getGovernanceViews,
  get_criteria_filters: getGovernanceViewFilters,
  get_criteria_fields: getGovernanceViewFields,
  get_field_data: getFieldData,
  get_field_data_with_delimiter: getFieldDataWithDelimiter,
  get_mass_editable_fields: getMassEditableFields,
  get_mass_data_classification_fields: getMassDataClassificationFields,
  get_social_node: getSocialNode,
  get_schedulers: getScheculersListing,
  get_bot_engagements: getBotEngagements,
  get_bot_top_users: getBotTopUsers,
} = API_CONFIG;

export function useGetCurrentNode(
  nodeType: string,
  nodeId: string,
  onSuccess: (_response: any) => void
): CustomReactQueryHookReturnType<CurrentNodeParserReturnType> {
  return useGetAll({
    apiConfig: getCurrentNode,
    params: [nodeType, nodeId],
    parser: getParsedCurrentNode,
    options: { onSuccess },
  });
}

export function useGetCategorisedGovernanceViews(
  node_type: string,
  nodeId: string
): CustomReactQueryHookReturnType<GovernanceViewsParserReturnType> {
  return useGetAll({
    apiConfig: getGovernanceViews,
    params: [node_type, nodeId],
    parser: getParsedCategorisedGovernanceViews,
  });
}

export function useGetAllGovernanceViews(
  node_type: string,
  enabled: boolean
): CustomReactQueryHookReturnType<AllGovernanceViewsParserReturnType> {
  return useGetAll({
    apiConfig: getGovernanceViews,
    params: [node_type],
    parser: getParsedAllGovernanceViews,
    options: { enabled },
  });
}

export function useGetGovernanceViewFilters(
  moduleType: string
): CustomReactQueryHookReturnType<GovernanceViewFiltersParserReturnType[]> {
  return useGetAll({
    apiConfig: getGovernanceViewFilters,
    params: [moduleType],
    parser: getParsedGovernanceViewFilters,
  });
}

export function useGetGovernanceViewFields(
  node_type: string,
  config: string,
  enable: boolean
): CustomReactQueryHookReturnType<GovernanceViewFieldsParserReturnType[]> {
  return useGetAll({
    apiConfig: getGovernanceViewFields,
    params: [node_type, config],
    parser: getParsedGovernanceViewFields,
    options: {
      enabled: enable,
    },
  });
}

export function useGetFieldData(
  field_name: string,
  field_id: string,
  node_type: string
): CustomReactQueryHookReturnType<GovernanceViewFieldDataParserReturnType[]> {
  return useGetAll({
    apiConfig: getFieldData,
    params: [field_name, field_id, node_type],
    parser: getParsedFieldData,
  });
}

export function useGetIncExcFieldData(
  listOfFilters: {
    params: string[];
    isDelimiter: boolean;
    field_name: string;
    field_id: string;
  }[],
  enabled: boolean
): {
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
  error: any;
  parsedData: { [key: string]: GovernanceViewFieldDataParserReturnType[] };
} {
  const fetcher = fetchRequestWithoutParam();
  const apiRequest = listOfFilters?.map((item) => {
    return {
      queryKey: appendQueryParamInUrl(
        item?.isDelimiter ? getFieldDataWithDelimiter.url : getFieldData.url,
        item?.params
      ),
      queryFn: fetcher as any,
    };
  });

  const config = useQueries(enabled ? apiRequest : []);

  const isAllRequestGotSuccessed = config?.every(
    (con) => con?.status === "success"
  );

  const parsedData = isAllRequestGotSuccessed
    ? config?.reduce((prev, next) => {
        const data = (next?.data || {}) as AxiosResponse;
        const fieldName =
          (data as AxiosResponse)?.config?.url
            ?.split("field_name=")?.[1]
            ?.split("&")?.[0] || "";

        const fieldId =
          listOfFilters?.find((filter) => filter?.field_name === fieldName)
            ?.field_id || "";

        return { ...prev, [fieldId]: getParsedFieldData(data) };
      }, {})
    : {};

  return {
    isLoading: config?.some((con) => con?.isLoading),
    isFetching: config?.some((con) => con?.isFetching),
    isFetched: config?.every((con) => con?.isFetched),
    error: config?.some((con) => con?.error),
    parsedData,
  };
}

export function useGetMassEditableFields(
  nodeType: NodeType,
  isShowTags?: boolean
): CustomReactQueryHookReturnType<MassEditableParsedFields> {
  return useGetAll({
    apiConfig: getMassEditableFields,
    params: [nodeType, isShowTags ? "true" : ""],
  });
}

export function useGetMassDataClassificationFields(
  nodeType: NodeType,
  isShowTags?: boolean
): CustomReactQueryHookReturnType<MassEditableParsedFields> {
  return useGetAll({
    apiConfig: getMassDataClassificationFields,
    params: [nodeType, isShowTags ? "true" : ""],
  });
}

export function useGetFieldData999Values(
  fields: string[],
  node_id: string,
  enabled: boolean
): KeyValuePairType<any> {
  const fetcher = fetchRequestWithoutHeaders("GET");
  const apiRequest = fields?.map((field) => {
    const api = appendQueryParamInUrl(API_CONFIG?.get_field_data_999?.url, [
      node_id,
      field,
    ]);

    return {
      queryKey: api,
      queryFn: fetcher,
    };
  });

  const config = useQueries(enabled ? apiRequest : []);

  return config;
}

export function useGetIsViewFavourite(
  node_id: number,
  enabled?: boolean
): CustomReactQueryHookReturnType<IsViewFavouriteParserReturnType> {
  return useGetAll({
    apiConfig: getSocialNode,
    params: [`${node_id}`],
    parser: getParsedIsViewFavourite,
    options: { enabled: enabled || false },
  });
}

export function useGetSchedulersList(
  node_type: string
): CustomReactQueryHookReturnType<SchedulersListingParsedType[]> {
  return useGetAll({
    apiConfig: getScheculersListing,
    params: [node_type],
    parser: getParsedSchedulersList,
    options: { enabled: !!node_type },
  });
}
// export function useGetBotEngagements(
//   gov_id: string,
//   node_id: string
// ): CustomReactQueryHookReturnType<BotEngagementsReturnType> {
//   return useGetAll({
//     apiConfig: getBotEngagements,
//     params: [gov_id, node_id],
//     parser: getParsedBotEngagements,
//   });
// }

// export function useGetBotTopUsers(
//   gov_id: string,
//   node_id: string
// ): CustomReactQueryHookReturnType<BotTopUsersReturnType> {
//   return useGetAll({
//     apiConfig: getBotTopUsers,
//     params: [gov_id, node_id],
//     parser: getParsedBotTopUsers,
//   });
// }
