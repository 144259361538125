// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Constants Start --->
import { ellipsis } from "../../styles/app.styles";
// <--- Constants End --->

// <--- Styles Start --->
import { GenericProminentTagStyled } from "../genericprominenttag/genericprominenttag.styles";
// <--- Styles End --->

// <--- Types Start --->
import { ProminentTagsType } from "../genericprominenttag/genericprominenttag.types";
import { ProminentTagsStyledProps } from "./prominenttagslist.types";
// <--- Types End --->

// <--- Utils Start --->
import { prominentTagsColorMapping } from "../../utils/prominenttagscolormapping";
// <--- Utils End --->

export const ProminentTagsListStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
`;

export const RemainingProminentTagsSecStyled = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

export const RemainingTagStyled = styled.div<{
  type: ProminentTagsType;
  isClickable?: boolean;
}>`
  color: ${({ theme, type }): string =>
    prominentTagsColorMapping(theme, undefined, true)[type]?.textColor};
  cursor: ${({ isClickable }): string => (isClickable ? "pointer" : "default")};

  ${ellipsis}
`;

export const ProminentTagPreviewer = styled.div<{ type: ProminentTagsType }>`
  width: 16px;
  height: 16px;
  background: ${({ theme, type }): string =>
    prominentTagsColorMapping(theme)[type]?.checkboxBgColor};
  box-shadow: 0 3px 4px 0 rgba(177, 177, 177, 0.16);
  margin-top: 3px;
  margin-left: 8px;
  border: solid 2px #e2f7df;
`;

export const ProminentTagStyled = styled(
  GenericProminentTagStyled
)<ProminentTagsStyledProps>`
  cursor: ${({ isClickable }): string => (isClickable ? "pointer" : "")};
  border: 1px solid
    ${({ theme, type, isSelected, isBordered }): string =>
      prominentTagsColorMapping(theme, isSelected, undefined, isBordered)[type]
        ?.borderColor};

  ${({ isShowOnlyOne }): string => (isShowOnlyOne ? "font-size: 11.2px;" : "")};

  ${({ isDisabled }): string =>
    isDisabled
      ? `
        opacity: 0.5;
        cursor: not-allowed;
      `
      : ""}

  &.bold-count {
    font-family: OpenSansBold;
  }
`;
