import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const ListItemsViewStyled = styled.div`
  .list-view-popover {
    .ant-popover-content {
      box-shadow: 0 2px 4px rgb(30 30 100 / 25%);
      border-color: #bcd4e5;
      width: 338px;
      .ant-popover-inner {
        .ant-popover-inner-content {
          padding: 0px;
          .content {
            .scroll-sec {
              max-height: 295px;
              overflow-y: auto;
              .sec-title {
                padding: 10px 0px;
                color: ${({ theme }): string => theme.textColors.BLACK_10};
                font-size: 20px;
                font-family: OpenSansBold;
                border-bottom: 1px solid #e7e7e7;
                .sec-title-inner {
                  padding: 4px 30px;
                  ${ellipsis}
                }
              }

              .category-items-list {
                padding: 10px 0px;
                border-bottom: 1px solid #e7e7e7;
                color: ${({ theme }): string => theme.textColors.BLACK_10};
                .category-title {
                  ${ellipsis}
                  padding-left: 15px;
                  font-size: 14px;
                  font-family: "OpenSansSemiBold";
                  color: ${({ theme }): string => theme.textColors.BLACK_10};
                }
                .category-item {
                  height: 33px;
                  padding: 0px 31.6px;
                  font-size: 14px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  position: relative;
                  color: ${({ theme }): string => theme.textColors.GREY_24};
                  width: 100%;

                  > span {
                    ${ellipsis}
                  }
                  svg {
                    margin-right: 11px;
                    g {
                      path {
                        fill: ${({ theme }): string =>
                          theme.bgColors.DARK_GREY_10};
                      }
                    }
                  }

                  :hover {
                    background: ${({ theme }): string =>
                      theme.bgColors.BLUE_27};
                    color: ${({ theme }): string =>
                      theme.textColors.WHITE_COLOR};
                    svg {
                      g {
                        path {
                          fill: ${({ theme }): string =>
                            theme.bgColors.WHITE_COLOR};
                          stroke: ${({ theme }): string =>
                            theme.bgColors.WHITE_COLOR};
                        }
                      }
                      path {
                        fill: ${({ theme }): string =>
                          theme.bgColors.WHITE_COLOR};
                        stroke: none;
                      }
                    }
                  }
                }

                .selected {
                  color: ${({ theme }): string => theme.textColors.BLUE_116};
                }

                .category-item-selected-icon {
                  position: absolute;
                  left: 16px;
                  svg {
                    margin-right: 0px;
                  }
                }
              }

              ::-webkit-scrollbar {
                width: 15px;
              }
              ::-webkit-scrollbar-thumb {
                background: #c1c1c1;
                background-clip: padding-box;
                border: 4px solid transparent;
                border-radius: 8px;
              }
            }
            .footer {
              padding: 8.8px;
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                display: inherit;
                flex-direction: row;
                gap: 6.8px;
                align-items: center;
                justify-content: center;
                color: ${({ theme }): string => theme.textColors.BLUE_105};
                font-size: 14px;
                font-family: OpenSansSemiBold;

                svg {
                  path {
                    fill: ${({ theme }): string => theme.textColors.BLUE_105};
                  }

                  .plus-circle-cls-1 {
                    stroke: ${({ theme }): string => theme.textColors.BLUE_105};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
