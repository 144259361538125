import moment from "moment";
import { ColumnProfilingInfo } from "../../../../../../../../../parsers";

import {
  FilterModelType,
  QueryBlockGridFilter,
} from "../../../../../../../analysisdetailpage.types";

import { TransformedFilterFromFilterModelType } from "../queryblock.types";
import {
  formatProfilingDate,
  splitProfilingRangeVal,
} from "../../../../../../../analisisdetailpage.utils";
import { queryResultsGridHumanReadableFormating } from "./queryresults/queryresults.utils";
import { getChatPrefrencesData } from "../../../../../../../../../utils/getchatprefrencesdata";

const TYPE_MAPPER: { [key in FilterModelType]: string } = {
  blanks: "Blanks",
  inRange: "In Range",
  nonBlanks: "Filled",
  equals: "Equals",
  set: "Set",
};

export const transformColumnToFilterModel = (
  column: ColumnProfilingInfo,
  colFilterModel: QueryBlockGridFilter,
  val: string = "",
  type?: FilterModelType,
  isRemovingFilter?: boolean
): QueryBlockGridFilter => {
  const {
    data_type: dataType,
    column_display_name: columnDisplayName = "",
    column_name: columnName = "",
  } = column || {};

  const { filter_model: filterModal } = colFilterModel || {};
  const { values = [] } = filterModal || {};

  const { max, min } = splitProfilingRangeVal(val);
  const dateFormat = getChatPrefrencesData()?.date_format;

  switch (dataType) {
    case "DEC":
    case "INT":
    case "NUM": {
      const typedMinNumber = Number(min);
      const typedMaxNumber = Number(max);

      const isSameNumber = typedMinNumber === typedMaxNumber || (min && !max);
      return {
        colm_name: columnName,
        col_display_name: columnDisplayName,
        operator: TYPE_MAPPER[type || isSameNumber ? "equals" : "inRange"],
        col_data_type: dataType,
        filter_model: isRemovingFilter
          ? undefined
          : type
          ? {
              filterType: "number",
              type,
            }
          : isSameNumber
          ? {
              filter: typedMinNumber,
              filterType: "number",
              type: "equals",
            }
          : {
              filter: typedMinNumber,
              filterTo: typedMaxNumber || typedMinNumber,
              filterType: "number",
              type: "inRange",
            },
      };
    }
    case "DTE":
    case "DTM":
    case "DT":
    case "TIM": {
      const isSame = min === max || (min && !max);
      return {
        colm_name: columnName,
        col_display_name: columnDisplayName,

        operator: TYPE_MAPPER[type || isSame ? "equals" : "inRange"],
        col_data_type: dataType,
        filter_model: isRemovingFilter
          ? undefined
          : type
          ? {
              filterType: "date",
              type,
            }
          : isSame
          ? {
              dateFrom: `${moment(min, dateFormat)?.format(
                "YYYY-MM-DD HH:mm:ss"
              )}`,
              dateTo: null,
              filterType: "date",
              type: "equals",
            }
          : {
              dateFrom: `${moment(min, dateFormat)?.format(
                "YYYY-MM-DD HH:mm:ss"
              )}`,
              dateTo: `${moment(max || min, dateFormat)?.format(
                "YYYY-MM-DD HH:mm:ss"
              )}`,
              filterType: "date",
              type: "inRange",
            },
      };
    }
    default: {
      const updatedVal = values?.includes(val)
        ? values?.filter((item: string) => item !== val)
        : [val, ...values]?.filter((item) => item);

      return {
        colm_name: columnName,
        col_display_name: columnDisplayName,
        operator: type ? TYPE_MAPPER[type] : "Includes",
        col_data_type: dataType,
        filter_model: isRemovingFilter
          ? undefined
          : type
          ? {
              filterType: "set",
              values: type === "blanks" ? ([null] as any) : undefined,
              type,
            }
          : updatedVal?.length
          ? {
              filterType: "set",
              values: updatedVal,
            }
          : undefined,
      };
    }
  }
};

export const transformFilterModelToListOfFilters = (
  filModel: QueryBlockGridFilter,
  isDataFormatted?: boolean,
  isMetricCol?: boolean
): Array<TransformedFilterFromFilterModelType> => {
  const {
    col_data_type: colDataType,
    col_display_name: colDisplayName = "",
    operator = "",
    filter_model: filterModel,
    colm_name: colName = "",
  } = filModel ?? {};

  const {
    filter = "",
    filterTo = "",
    dateFrom = "",
    dateTo = "",
    values = [],
    type,
  } = filterModel ?? {};

  const isBlankExists = type === "blanks" || type === "nonBlanks";
  const isEquals = type === "equals";
  const decimals = `${filter}`?.split(".")?.[1]?.length;
  switch (colDataType) {
    case "DEC":
    case "INT":
    case "NUM":
      return isBlankExists
        ? [
            {
              org_col_name: colName,
              name: colDisplayName,
              operator: "",
              val: TYPE_MAPPER[type || "blanks"],
            },
          ]
        : [
            {
              org_col_name: colName,
              name: colDisplayName,
              operator,
              val: isEquals
                ? isMetricCol
                  ? `${queryResultsGridHumanReadableFormating({
                      number: Number(filter),
                      dataType: colDataType,
                      isDataFormatted: !!isDataFormatted,
                      decimals,
                    })}`
                  : `${filter}`
                : `${
                    isMetricCol
                      ? queryResultsGridHumanReadableFormating({
                          number: Number(filter),
                          dataType: colDataType,
                          isDataFormatted: !!isDataFormatted,
                          decimals,
                        })
                      : filter
                  } - ${
                    isMetricCol
                      ? queryResultsGridHumanReadableFormating({
                          number: Number(filterTo),
                          dataType: colDataType,
                          isDataFormatted: !!isDataFormatted,
                          decimals,
                        })
                      : filterTo
                  }`,
            },
          ]?.filter((obj) => obj?.val !== " - ");
    case "DTE":
    case "DTM":
    case "DT":
    case "TIM":
      return isBlankExists
        ? [
            {
              org_col_name: colName,
              name: colDisplayName,
              operator: "",
              val: TYPE_MAPPER[type || "blanks"],
            },
          ]
        : [
            {
              org_col_name: colName,
              name: colDisplayName,
              operator,
              val: isEquals
                ? `${formatProfilingDate(dateFrom)}`
                : `${formatProfilingDate(dateFrom)} - ${formatProfilingDate(
                    dateTo || ""
                  )}`,
            },
          ]?.filter((obj) => obj?.val !== " - ");
    default:
      return isBlankExists
        ? [
            {
              org_col_name: colName,
              name: colDisplayName,
              operator: "",
              val: TYPE_MAPPER[type || "blanks"],
            },
          ]
        : values?.map((val: string) => ({
            org_col_name: colName,
            name: colDisplayName,
            operator,
            val,
          }));
  }
};

export const updateFilterModelToClearFilter = (
  filter: QueryBlockGridFilter,
  val?: string
): QueryBlockGridFilter => {
  const { col_data_type: colDataType, filter_model: filterModel } =
    filter || {};

  const { type, values = [] } = filterModel || {};
  const isBlankOrIsNonBlank = type === "blanks" || type === "nonBlanks";

  switch (colDataType) {
    case "DEC":
    case "INT":
    case "NUM":
      return {
        ...filter,
        filter_model: undefined,
      };
    case "DTE":
    case "DTM":
    case "DT":
    case "TIM":
      return {
        ...filter,
        filter_model: undefined,
      };
    default:
      return {
        ...filter,
        filter_model: isBlankOrIsNonBlank
          ? undefined
          : values?.filter((item: string) => item !== val)?.length
          ? {
              filterType: "set",
              values: values?.filter((item: string) => item !== val),
            }
          : undefined,
      };
  }
};
