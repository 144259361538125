import styled from "styled-components";

export const DvsumHelpItemCardStyled = styled.div`
  cursor: pointer;
  max-width: 179px;

  .thumbnail-wrapper {
    position: relative;
    max-width: 179.8px;
  }

  img {
    height: 112px;
    width: 179.8px;
    margin-bottom: 8px;
  }

  .video-duration {
    font-size: 9.6px;
    opacity: 0.8;
  }

  .gif-tag {
    position: absolute;
    bottom: 13px;
    right: 6px;
    background: rgba(34, 42, 45, 0.6);
    color: white;
    font-size: 9.6px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17.6px;
    width: 28px;
  }

  .video-tag {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  :hover {
    img {
      opacity: 0.8;
    }
  }
`;
