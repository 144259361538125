import { SetStateAction, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import parse from "html-react-parser";

import {
  appendQueryParamInUrl,
  useRequestWithMethod,
} from "../../../../../../../api";

import {
  InputField,
  RadioGroupField,
  SelectField,
  TextAreaField,
} from "../../../../../../../components/formfields";

import { SourcesParserReturnType } from "../../../../../../../parsers/sources/sourcesparser.types";

import {
  ActionTypeProps,
  ActionTypes,
  DataSourceFormMetaDataProps,
  DynamicFormFieldProps,
  DynamicLogEventTypes,
} from "./connectionsettingstabform.types";

import { plusIcon } from "../../../../../../../svgs";
import { getEnvVariables } from "../../../../../../../utils";

import {
  getAlertContentData,
  handleXHRRequest,
  hideShowGroupOrField,
  reloadGroupOrFields,
} from "./connectionsettingstabform.utils";

import { LogsContainerWrapperStyled } from "./connectionsettingstabform.styles";
import Alert from "../../../../../../../components/alert/alert";
import { DynamicFormGroupProps } from "../connectionsettingstabformgroup/connectionsettingstabformgroup.types";
import { DynamicActionButton } from "./renderers";
import {
  connectionSettingsLogger,
  getFieldCheckData,
  getFilterDataByPattern,
} from "./utils";

export function GetInputField({
  fieldMetaData,
  formControl,
  id = "",
}: DynamicFormFieldProps): JSX.Element {
  return (
    <InputField
      name={fieldMetaData?.name}
      control={formControl}
      placeholder={fieldMetaData?.label}
      isPasswordField={fieldMetaData?.inputType === "password"}
      disabled={fieldMetaData?.disabled}
      width={fieldMetaData?.width || "333px"}
      height="34px"
      autoComplete="off"
      {...(fieldMetaData?.inputType === "number" && { type: "number" })}
      {...(fieldMetaData?.inputType === "password" && {
        readOnly: true,
        onFocus: (event): void =>
          event?.currentTarget?.removeAttribute("readOnly"),
      })}
      {...(id && { id })}
    />
  );
}

export function GetRadioGroupField({
  fieldMetaData,
  formControl,
  gap,
}: DynamicFormFieldProps & { gap?: number }): JSX.Element {
  return (
    <RadioGroupField
      name={fieldMetaData?.name}
      control={formControl}
      direction="row"
      gap={gap}
      disabled={fieldMetaData?.disabled}
      options={(fieldMetaData?.inputValue as [])?.map(
        (item: { label: string; value: string; key?: string }) => ({
          ...item,
          key: `key-${fieldMetaData?.name}-${item?.label}`,
        })
      )}
    />
  );
}

function GetSelectField({
  fieldMetaData,
  formControl,
  setValue,
  form,
  setFormFieldKeys,
  setFieldLogs,
  setGroupVisibility,
  disabledValues,
  setDisabledValues,
}: DynamicFormFieldProps): JSX.Element {
  const { REACT_APP_API_DOMAIN } = getEnvVariables();
  const { optionsConfig } = fieldMetaData;

  const { isLoading, error, onExecuteRequest, data } = useRequestWithMethod(
    "dynamic_form_url_get",
    undefined,
    true
  );

  const options = useMemo(() => {
    if (optionsConfig?.options) {
      return optionsConfig?.options;
    }

    if (!isLoading && data) {
      let responseOptions = data?.body;

      if (optionsConfig?.filterOptionValues) {
        const { filterOptionValues } = optionsConfig;
        if (filterOptionValues?.filterType === "xhr") {
          const databaseValue = form?.getValues(
            filterOptionValues?.elementName
          );

          responseOptions = getFilterDataByPattern(
            data,
            filterOptionValues?.filterValuePattern,
            databaseValue
          );
        }
      }

      const disabledValuesFinal: string[] =
        disabledValues?.[fieldMetaData?.name] ??
        optionsConfig?.disabledValues
          ?.map((item) => {
            if (typeof item === "string") {
              return item;
            }

            return form?.getValues(item?.elementRefs);
          })
          ?.flat() ??
        [];

      return responseOptions?.map((item: any) => ({
        key: `key-conn-op-${fieldMetaData?.name}-${item?.Name}`,
        value: item?.Name || undefined,
        label: item?.Name || "",
        id: `${item?.Name || ""}`,
        disabled: disabledValuesFinal?.includes(item?.Name),
      }));
    }

    return undefined;
  }, [
    data,
    optionsConfig?.options,
    isLoading,
    optionsConfig?.filterOptionValues,
    disabledValues,
  ]);

  useEffect(() => {
    // if selected value is not defined in options then set it to undefined
    const flag = isLoading ?? true;
    if (!flag) {
      const values = form?.getValues(fieldMetaData?.name);
      const optionExist =
        options?.some((item: any) => item?.value === values) ?? false;
      if (values && !optionExist) {
        // setValue(fieldMetaData?.name, undefined, {
        //   shouldValidate: true,
        //   shouldDirty: true,
        // });
        setValue(fieldMetaData?.name, undefined);
      }
    }
    form?.trigger(fieldMetaData?.name);
  }, [options, isLoading]);

  useEffect(() => {
    // print logs
    if (!error && optionsConfig?.xhrConfig?.logEvents) {
      const { loading, completed } = optionsConfig?.xhrConfig?.logEvents;
      if (setFieldLogs) {
        const logEventType: DynamicLogEventTypes | undefined =
          loading && isLoading
            ? "loading"
            : completed && !isLoading && data
            ? "completed"
            : undefined;
        connectionSettingsLogger(
          optionsConfig?.xhrConfig?.logEvents,
          setFieldLogs,
          logEventType,
          form
        );
        // const dataToAppend =
        //   loading && isLoading
        //     ? { ...loading }
        //     : completed && !isLoading && data
        //     ? { ...completed }
        //     : undefined;

        // if (dataToAppend) {
        //   setFieldLogs((st) => [
        //     ...st,
        //     ...((dataToAppend && [getItemValue(dataToAppend, form)]) || []),
        //   ]);
        // }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (optionsConfig?.isRemoteData || optionsConfig?.xhrConfig) {
      const xhrConfig = optionsConfig?.xhrConfig;
      const initialUrl = xhrConfig?.isInternal
        ? `${REACT_APP_API_DOMAIN}/`
        : "";

      const urlParamsValues = form?.getValues(xhrConfig?.urlParamFields || []);
      const url = appendQueryParamInUrl(
        `${initialUrl}${optionsConfig?.xhrConfig?.urlEndpoint}`,
        urlParamsValues || []
      );

      handleXHRRequest({ onExecuteRequest, requestUrl: url, form, xhrConfig });
    }
  }, []);

  const onChange = useCallback(
    (optionValue) => {
      if (optionsConfig?.onChangeConfig) {
        const {
          hideShowElements,
          reloadElements,
          disableFieldValues,
        } = optionsConfig?.onChangeConfig;

        hideShowGroupOrField(hideShowElements, setGroupVisibility);
        reloadGroupOrFields(reloadElements, setFormFieldKeys);
        setDisabledValues &&
          setDisabledValues((st) => ({
            ...st,
            ...disableFieldValues?.elementRefs?.reduce(
              (prev, curr) => ({
                ...prev,
                ...(optionValue && { [`${curr}`]: [optionValue] }),
              }),
              {}
            ),
          }));
      }
    },
    [fieldMetaData, optionsConfig?.onChangeConfig]
  );

  return (
    <SelectField
      showSearch={fieldMetaData?.optionsConfig?.showSearch}
      name={fieldMetaData?.name}
      control={formControl}
      setValue={setValue}
      options={options || []}
      disabled={fieldMetaData?.disabled || isLoading}
      defaultValue={fieldMetaData?.defaultValue}
      placeholder={
        fieldMetaData?.placeHolder || `Select ${fieldMetaData?.label}`
      }
      width="333px"
      height="34px"
      loading={isLoading}
      allowClear={!!fieldMetaData?.allowClear}
      {...(optionsConfig?.onChangeConfig && {
        propOnChange: onChange,
      })}
    />
  );
}

function GetTextArea({
  fieldMetaData,
  formControl,
}: DynamicFormFieldProps): JSX.Element {
  return (
    <TextAreaField
      control={formControl}
      name={fieldMetaData?.name}
      width="333px"
      maxLength={1000}
      placeholder={fieldMetaData?.label}
      disabled={fieldMetaData?.disabled}
    />
  );
}

export function GetFormField({
  fieldMetaData,
  formControl,
  setValue,
  form,
  setFormFieldKeys,
  fieldLogs,
  setFieldLogs,
  alertsContent,
  disabledValues,
  setDisabledValues,
  setAlertsContent,
}: DynamicFormFieldProps): JSX.Element {
  switch (fieldMetaData?.inputType) {
    case "text":
    case "password":
      return (
        <GetInputField
          fieldMetaData={fieldMetaData}
          formControl={formControl}
        />
      );
    case "textarea":
      return (
        <GetTextArea fieldMetaData={fieldMetaData} formControl={formControl} />
      );
    case "radio":
      return (
        <GetRadioGroupField
          fieldMetaData={fieldMetaData}
          formControl={formControl}
        />
      );
    case "select":
      return (
        <GetSelectField
          fieldMetaData={fieldMetaData}
          formControl={formControl}
          setValue={setValue}
          form={form}
          setFormFieldKeys={setFormFieldKeys}
          setFieldLogs={setFieldLogs}
          disabledValues={disabledValues}
          setDisabledValues={setDisabledValues}
          setAlertsContent={setAlertsContent}
        />
      );
    case "logger":
      return (
        <LogsContainerWrapperStyled className="logs-container-wrapper">
          <ul className="logs-container">
            {fieldLogs
              ?.filter((item) => item && item?.length)
              ?.map((log, index) => (
                <li key={`logger-key-${index}`}>{parse(log)}</li>
              ))}
          </ul>
        </LogsContainerWrapperStyled>
      );
    case "alert": {
      let alertData = alertsContent && alertsContent[`${fieldMetaData?.name}`];

      if (!alertData && fieldMetaData?.alertConfig) {
        const checkedData =
          fieldMetaData?.alertConfig?.fieldsCheck &&
          getFieldCheckData(fieldMetaData?.alertConfig?.fieldsCheck, form);

        if (checkedData?.length) {
          alertData = getAlertContentData(fieldMetaData?.alertConfig);
        }
      }

      return (
        <>
          {alertData && (
            <Alert
              className="alert-field"
              type={`${alertData?.type || "error"}`}
              message={`${alertData?.message}`}
              {...(alertData?.description && {
                description: `${alertData?.description}`,
              })}
            />
          )}
        </>
      );
    }
    default:
      return <InputField name={fieldMetaData?.name} control={formControl} />;
  }
}

export function GetFieldAction(props: {
  groupProps: DataSourceFormMetaDataProps;
  form: ReturnType<typeof useForm>;
  data?: SourcesParserReturnType;
  actionType: ActionTypes;
  actionData?: ActionTypeProps;
  setGroupVisibility?: React.Dispatch<SetStateAction<{ [x: string]: boolean }>>;
  setFormFieldKeys?: React.Dispatch<SetStateAction<{ [x: string]: string }>>;
  setAlertsContent?: DynamicFormGroupProps["setAlertsContent"];
  enableDisableElements?: DynamicFormGroupProps["enableDisableElements"];
  setEnableDisableElements?: DynamicFormGroupProps["setEnableDisableElements"];
  setDialogError?: DynamicFormGroupProps["setDialogError"];
  setFieldLogs?: DynamicFormGroupProps["setFieldLogs"];
}): JSX.Element {
  switch (props?.actionType) {
    case "button":
      return (
        <DynamicActionButton
          {...props}
          disabled={props?.actionData?.disabled}
        />
      );
    default:
      return <div />;
  }
}

export function getIconByType(type?: string): JSX.Element {
  if (type === "add") {
    return plusIcon("10", "10");
  }

  return <></>;
}
