import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { SelectField, InputField } from "../../../../components/formfields";
import { ELEMENT_IDS } from "../../../../constants";
import { MassEditableParsedField } from "../../../../parsers/listingpagesparser/listingpagesparser.types";
import { MassUpdateFormItemStyled } from "./massupdateformitem.styles";
import { transformDataToOptions } from "./massupdateformitem.utils";

const {
  allcat_lst_lst_mass_upd_attr_fld: ALLCAT_LST_LST_MASS_UPD_ATTR_FLD,
  allcat_lst_lst_mass_upd_attr_value: ALLCAT_LST_LST_MASS_UPD_ATTR_VALUE,
} = ELEMENT_IDS;

export function MassUpdateFormItem(
  field: MassEditableParsedField
): JSX.Element {
  const { onClear } = field;
  const { control, setError, setValue } = useFormContext();
  const fieldContent = useMemo(() => {
    switch (field.field_type) {
      case "dropdown":
        return (
          <SelectField
            onClear={(): void => {
              onClear?.(field);
            }}
            listHeight={250}
            allowClear
            width="348px"
            isAllowClear={false}
            showSearch
            control={control}
            name={field?.field_name}
            options={transformDataToOptions(field?.field_data || [])?.map(
              (item, index) => ({
                ...item,
                id: `${ALLCAT_LST_LST_MASS_UPD_ATTR_VALUE?.replace(
                  "*",
                  `${field?.field_index}`
                )?.replace("**", `${index + 1}`)}`,
              })
            )}
            setError={setError}
            setValue={setValue}
            virtual
            filterOption={(input: string, option: any): boolean =>
              option?.labelText
                ?.toString()
                .toLowerCase()
                .includes(input?.toString().toLowerCase())
            }
            placeholder="Select value"
            isUseGlobalStyle
          />
        );
      case "multi-select-dropdown":
        return (
          <SelectField
            listHeight={250}
            allowClear
            maxHeight="150px"
            width="348px"
            showSearch
            control={control}
            name={field?.field_name}
            options={transformDataToOptions(field?.field_data || [])}
            setError={setError}
            setValue={setValue}
            mode="multiple"
            virtual
            filterOption={(input: string, option: any): boolean =>
              option?.labelText
                ?.toString()
                .toLowerCase()
                .includes(input?.toString().toLowerCase())
            }
            placeholder="Select value"
            onClear={(): void => {
              onClear?.(field);
            }}
            isUseGlobalStyle
          />
        );

      default:
        return (
          <InputField
            control={control}
            name={field?.field_name}
            width="348px"
          />
        );
    }
  }, [field]);
  return (
    <MassUpdateFormItemStyled>
      <div
        id={`${ALLCAT_LST_LST_MASS_UPD_ATTR_FLD?.replace(
          "*",
          `${field?.field_index}`
        )}`}
      >
        {fieldContent}
      </div>
    </MassUpdateFormItemStyled>
  );
}
