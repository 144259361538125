import { useCallback } from "react";
import { useGetData } from ".";
import { appendQueryParamInUrl } from "../api";
import { queryClient } from "../App";
import { API_CONFIG, API_CONFIG_TYPE } from "../constants/apiconfig";
import { isArray } from "../utils";

export function useSetData(): (
  _apiConfig: API_CONFIG_TYPE,
  _data: object & { [key: string]: any },
  _params?: string[],
  _additionalOptions?: {
    action: "CREATE" | "UPDATE" | "DELETE";
    key?: string;
  }
) => void {
  const onGetData = useGetData();

  return useCallback(
    (
      apiConfig: API_CONFIG_TYPE,
      data: object & { [key: string]: any },
      params?: string[],
      additionalOptions?: {
        action: "CREATE" | "UPDATE" | "DELETE";
        key?: string;
      }
    ) => {
      const previousData = onGetData(apiConfig, params);
      const api = appendQueryParamInUrl(apiConfig?.url, params ?? []);

      let updatedResponse;

      if (!additionalOptions) {
        if (!isArray(data)) {
          updatedResponse = { ...data };
        } else if (isArray(data)) {
          updatedResponse = data;
        }
      } else {
        const { action, key = "" } = additionalOptions;

        if (action === "CREATE") {
          // make it through a props
          if (apiConfig?.url === API_CONFIG?.user_activities?.url) {
            const prev = previousData?.data;
            const updated = { ...prev, results: [...prev?.results, data] };

            updatedResponse = updated;
          } else {
            updatedResponse = [...(previousData?.data || []), data || {}];
          }
        } else if (action === "DELETE") {
          updatedResponse = previousData?.data?.filter(
            (item: { [key: string]: any }) => item?.[key] !== data?.id
          );
        } else {
          const filteredRecords = previousData?.data?.map(
            (item: { [key: string]: any }) =>
              item[key] === data[key] ? data : item
          );

          updatedResponse = filteredRecords;
        }
      }

      queryClient.setQueryData(api, {
        ...previousData,
        data: updatedResponse,
      });
    },
    []
  );
}
