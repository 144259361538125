// <--- Constants Start --->
import { THEME_COLORS } from "../components/themeprovider/themeprovider.constant";
// <--- Constants End --->

// <--- Types Start --->
import { ProminentTagsType } from "../components/genericprominenttag/genericprominenttag.types";
// <--- Types End --->

export const prominentTagsColorMapping = (
  theme: typeof THEME_COLORS["light"],
  selected?: boolean,
  isShowOnlyOne?: boolean,
  isBordered?: boolean
): {
  [key in ProminentTagsType]: {
    bgColor: string;
    textColor: string;
    borderColor: string;
    checkboxBgColor: string;
  };
} => {
  return {
    LRD: {
      bgColor: theme?.bgColors?.RED_101,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.RED_100
          : theme?.borderColors?.RED_101,
      textColor: theme?.textColors?.RED_100,
      checkboxBgColor: theme?.textColors?.RED_100,
    },
    RED: {
      bgColor: theme?.bgColors?.RED_102,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.RED_100
          : theme?.borderColors?.RED_101,
      textColor: theme?.textColors?.RED_100,
      checkboxBgColor: theme?.textColors?.RED_100,
    },
    DRD: {
      bgColor: theme?.bgColors?.RED_20,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.RED_100
          : theme?.borderColors?.RED_101,
      textColor: isShowOnlyOne
        ? theme?.bgColors?.RED_20
        : theme?.textColors?.WHITE_COLOR,
      checkboxBgColor: theme?.textColors?.RED_100,
    },
    LBL: {
      bgColor: theme?.bgColors?.BLUE_112,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.BLUE_116
          : theme?.borderColors?.BLUE_111,
      textColor: theme?.textColors?.BLUE_123,
      checkboxBgColor: theme?.textColors?.BLUE_116,
    },
    BLU: {
      bgColor: theme?.bgColors?.BLUE_113,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.BLUE_116
          : theme?.borderColors?.BLUE_111,
      textColor: theme?.textColors?.BLUE_123,
      checkboxBgColor: theme?.textColors?.BLUE_116,
    },
    DBL: {
      bgColor: theme?.bgColors?.BLUE_123,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.BLUE_116
          : theme?.borderColors?.BLUE_111,
      textColor: isShowOnlyOne
        ? theme?.bgColors?.BLUE_123
        : theme?.textColors?.WHITE_COLOR,
      checkboxBgColor: theme?.textColors?.BLUE_116,
    },
    LGR: {
      bgColor: theme?.bgColors?.GREEN_12,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.GREEN_11
          : theme?.borderColors?.GREEN_12,
      textColor: theme?.textColors?.GREEN_11,
      checkboxBgColor: theme?.textColors?.GREEN_11,
    },
    GRN: {
      bgColor: theme?.bgColors?.GREEN_13,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.GREEN_11
          : theme?.borderColors?.GREEN_12,
      textColor: theme?.textColors?.GREEN_11,
      checkboxBgColor: theme?.textColors?.GREEN_11,
    },
    DGR: {
      bgColor: theme?.bgColors?.GREEN_11,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.GREEN_11
          : theme?.borderColors?.GREEN_12,
      textColor: isShowOnlyOne
        ? theme?.bgColors?.GREEN_11
        : theme?.textColors?.WHITE_COLOR,
      checkboxBgColor: theme?.textColors?.GREEN_11,
    },
    GRY: {
      bgColor: theme?.bgColors?.LIGHT_GREY_51,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.GREY_24
          : theme?.borderColors?.LIGHT_GREY_51,
      textColor: theme?.textColors?.GREY_24,
      checkboxBgColor: theme?.textColors?.GREY_24,
    },
    LGRY: {
      bgColor: theme?.bgColors?.LIGHT_GREY_22,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.GREY_56
          : theme?.borderColors?.LIGHT_GREY_22,
      textColor: theme?.textColors?.GREY_24,
      checkboxBgColor: theme?.textColors?.GREY_24,
    },
    YLW: {
      bgColor: theme?.bgColors?.YELLOW_11,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.YELLOW_COLOR
          : theme?.borderColors?.YELLOW_11,
      textColor: theme?.textColors?.YELLOW_COLOR,
      checkboxBgColor: theme?.textColors?.YELLOW_COLOR,
    },
    ORG: {
      bgColor: theme?.bgColors?.ORANGE_2,
      borderColor:
        selected || isBordered
          ? theme?.textColors?.LIGHT_ORANGE_2
          : theme?.borderColors?.ORANGE_2,
      textColor: theme?.textColors?.LIGHT_ORANGE_2,
      checkboxBgColor: theme?.textColors?.LIGHT_ORANGE_2,
    },
  };
};
