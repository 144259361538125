import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequestWithMethod } from "../../api";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { SelectField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";
import { API_CONFIG } from "../../constants/apiconfig";
import { DeleteUserFormStyled } from "./deleteuserform.styles";
import { deleteUserSchema } from "../../utils/schemas/userschemas";
import { UsersGridColumnsType } from "../../pages/userspage/userspage.types";
import { UsersResponseType } from "../../parsers/users/usersparser.types";
import { useCancelModal, useGetAppState } from "../../customhooks";
import {
  sortObjectsArrayByKey,
  updateArrayWithSameSequence,
} from "../../utils";
import { SelectFieldOption } from "../../components/formfields/selectfield/selectfield.types";

const api = API_CONFIG?.get_all_users?.url;

function DeleteUserForm(): JSX.Element {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: { assign_user_to: undefined },
    mode: "onChange",
    resolver: yupResolver(deleteUserSchema),
  });
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    id = "",
    firstName = "",
    lastName,
  } = modalProps as UsersGridColumnsType;

  const queryClient = useQueryClient();
  const data = queryClient?.getQueryData(api) as any;

  const userOptions: SelectFieldOption[] = useMemo(() => {
    return sortObjectsArrayByKey(
      data?.data
        ?.filter(
          (item: UsersResponseType) =>
            item?.USER_ID !== id && item?.USER_STATUS === "ACT"
        )
        ?.map((item: UsersResponseType) => ({
          label: `${item?.USER_FIRST_NAME} ${item?.USER_LAST_NAME} (${item?.USER_EMAIL})`.trim(),
          value: item?.USER_ID,
        })),
      "label"
    );
  }, [data?.data]);

  const onCancel = useCancelModal();

  const onSuccess = useCallback(
    (response) => {
      if (response?.data?.length) {
        queryClient?.setQueryData(api, (oldData: any) => {
          return {
            ...oldData,
            data: updateArrayWithSameSequence(
              oldData.data,
              "USER_ID",
              response?.data[0]?.USER_ID,
              response?.data[0]
            ),
          };
        });
      }

      onCancel();
    },
    [modalProps]
  );

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "delete_user",
    [id],
    true,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    onExecuteRequest({ newUserId: values?.assign_user_to });
  }, []);

  return (
    <StateHandler isModal isFetching={isLoading} error={error}>
      <DeleteUserFormStyled>
        <FormStyled
          isItemColumnLayout
          paddingLeft="110px"
          onFinish={handleSubmit(onSubmit) as any}
        >
          <div className="content-sec">
            <div className="static-text">
              You must assign responsibilities of{" "}
              {
                <span className="bold">
                  {" "}
                  {firstName}
                  {lastName && ` ${lastName}`}
                </span>
              }{" "}
              to another user.
            </div>
            <FormItemStyled label="Assign to" required marginBottom="60px">
              <SelectField
                control={control}
                setValue={setValue}
                options={userOptions || []}
                name="assign_user_to"
                width="396px"
                height="45.36px"
                aria-multiselectable={false}
                placeholder="Select"
                isAllowClear={false}
              />
            </FormItemStyled>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button
              id="primary"
              width="78px"
              height="39px"
              htmlType="submit"
              disabled={!isValid}
            >
              Delete
            </Button>
            <Button
              id="primary"
              width="78px"
              height="39px"
              marginLeft="5px"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DeleteUserFormStyled>
    </StateHandler>
  );
}

export default DeleteUserForm;
