import styled from "styled-components";

export const ConnectionSettingsDynamicModalStyled = styled.div`
  .form-body {
    margin: 50px 0px 70px;
  }

  .form-actions-sec {
    .ant-form-item-control-input-content {
      column-gap: 8px;
    }
  }
`;
