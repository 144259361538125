import { Tooltip } from "antd";
import { useCallback } from "react";
import { useController } from "react-hook-form";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../constants";
import {
  InputFieldContainerStyled,
  TextAreaFieldStyled,
} from "./textareafield.styles";
import { TextAreaFieldType } from "./textareafield.types";

function TextAreaField({
  name = "",
  control,
  isMessagePositionAbsolute,
  propOnChange,
  tooltipProps,
  showError = true,
  ...props
}: TextAreaFieldType): JSX.Element {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = useCallback(
    (event) => {
      inputProps?.onChange(event);
      propOnChange && propOnChange(event);
    },
    [inputProps, propOnChange]
  );

  return (
    <Tooltip
      {...tooltipProps}
      overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} `}
      overlay={undefined}
    >
      <InputFieldContainerStyled
        className="textarea-field-container"
        isMessagePositionAbsolute={isMessagePositionAbsolute}
      >
        <TextAreaFieldStyled
          {...props}
          {...inputProps}
          ref={ref}
          onChange={handleChange}
        />
        {showError && <div className="error">{error?.message}</div>}
      </InputFieldContainerStyled>
    </Tooltip>
  );
}

export default TextAreaField;
