import { useCallback } from "react";
import { Button } from "../../../../components";
import { DvsumHelpDrawerDetailModalProps } from "../../../../components/modalwrapper/modals/dvsumhelpdrawerdetailmodal/dvsumhelpdrawerdetailmodal.types";
import ShowMoreOrLessText from "../../../../components/showmoreorlesstext";
import { useGetAppState, useOpenModal } from "../../../../customhooks";
import {
  expandToFullScreenIcon,
  playVideoIcon,
  redirectIcon,
} from "../../../../svgs";
import { openOnboardingTutorial } from "../../../../utils";
import { DetailedViewStyled } from "./detailedview.styles";
import { DetailedViewProps } from "./detailedview.types";

const DetailedView = (props: DetailedViewProps): JSX.Element => {
  const { selectedCategory } = props;

  const openModal = useOpenModal();

  const { modal } = useGetAppState();

  const isCategoryTutorial = selectedCategory?.category === "Tutorial";

  const handleExpandItem = useCallback(() => {
    openModal({
      visible: true,
      modalId: "dvsum_help_drawer_detail_modal",
      modalTitle: selectedCategory?.item?.title,
      modalProps: {
        item: selectedCategory?.item,
        type: selectedCategory?.category,
      } as DvsumHelpDrawerDetailModalProps,
    });
  }, [selectedCategory]);

  return (
    <DetailedViewStyled isCategoryTutorial={isCategoryTutorial}>
      <div className="heading">{selectedCategory?.item?.title}</div>

      <div
        className="thumbnail"
        role="button"
        onClick={!isCategoryTutorial ? handleExpandItem : undefined}
      >
        {modal?.visible ? (
          <img
            src={selectedCategory?.item?.thumbnail_image_url}
            alt="thumbnail"
          />
        ) : (
          <img
            src={
              selectedCategory?.item?.article_type === "Quick Tip"
                ? selectedCategory?.item?.gif_attachment?.[0]?.url
                : selectedCategory?.item?.thumbnail_image_url
            }
            alt="thumbnail"
          />
        )}

        {selectedCategory?.item?.article_type === "Quick Tip" && (
          <div className="expand-icon thumbnail-img-icon">
            {expandToFullScreenIcon("14.4", "14.4")}
          </div>
        )}
        {selectedCategory?.item?.article_type === "Video" && (
          <div className="play-video-icon thumbnail-img-icon">
            {playVideoIcon("51.2", "51.2")}
          </div>
        )}
      </div>

      {selectedCategory?.item?.description && (
        <div className="desc">
          <ShowMoreOrLessText
            text={selectedCategory?.item?.description || ""}
            textLimit={80}
          />
        </div>
      )}

      {selectedCategory?.item?.article_type === "Tutorial" && (
        <Button
          id="link"
          icon={redirectIcon("9.6", "9.6")}
          className="open-tutorial-btn"
          onClick={(): void => {
            openOnboardingTutorial({
              id: selectedCategory?.item?.id,
              startUrl: selectedCategory?.item?.tutuorial_start_url || "",
              assetId: selectedCategory?.item?.asset_id,
              totalSteps: selectedCategory?.item?.total_steps,
            });
          }}
        >
          Open Tutorial
        </Button>
      )}
    </DetailedViewStyled>
  );
};

export default DetailedView;
