import { DATE_TIME_COMBINE_FORMAT } from "../../constants";

import { blueTagIcon, linkEmbedIcon, notesIcon, userIcon } from "../../svgs";

import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";
import { TagDetailsCardStyled } from "./tagdetailscard.styles";

import { TagDetailsCardProps } from "./tagdetailscard.types";

function TagDetailsCard(props: TagDetailsCardProps): JSX.Element {
  const {
    tagName = "",
    tagNote = "",
    tagsetDesc = "",
    tagsetName = "",
    updatedBy = "",
    updatedOn = "",
    showDetailInfo = true,
    tagsetPolicyInfo = "",
  } = props;

  return (
    <TagDetailsCardStyled showDetailInfo={showDetailInfo}>
      <div className="tag-details-card-row-1">
        <div className="tag-icon">{blueTagIcon()}</div>
        <div className="tag-name" title={tagName}>
          {tagName}
        </div>
        (
        <div className="tagset-name" title={tagsetName}>
          {tagsetName}
        </div>
        )
      </div>
      <div className="tag-details-card-row-2" title={tagsetDesc}>
        {tagsetDesc || "--"}
      </div>
      {showDetailInfo && (
        <>
          <div className="tag-details-card-row-3">
            {userIcon}By <span className="updated-by">{updatedBy || "--"}</span>{" "}
            on {utcTOLocalTimeZone(updatedOn, DATE_TIME_COMBINE_FORMAT) || "--"}
          </div>
          {tagNote && (
            <div className="tag-details-card-row-4">
              <div className="notes-icon">{notesIcon()}</div>
              <div className="notes" title={tagNote}>
                {tagNote}
              </div>
            </div>
          )}
          {tagsetPolicyInfo && (
            <div className="tag-details-card-row-4">
              <div className="notes-icon">{linkEmbedIcon("11.2", "11.2")}</div>
              <div className="notes" title={tagsetPolicyInfo}>
                {tagsetPolicyInfo}
              </div>
            </div>
          )}
        </>
      )}
    </TagDetailsCardStyled>
  );
}

export default TagDetailsCard;
