import { useCallback, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "..";
import Statehandler from "../statehandler";

import { useRequestWithMethod } from "../../api";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants";

import { disLikeIcon, likeIcon } from "../../svgs";
import { exitOnboardinTutorial, getEnvVariables } from "../../utils";

import { helpFeedbackFormSchema } from "../../utils/schemas";
import { TextAreaField } from "../formfields";

import { DvsumHelpAndOnboardingFeedbackSecStyled } from "./dvsumhelpandonboardingfeedbacksec.styles";
import { DvsumHelpAndOnboardingFeedbackSecProps } from "./dvsumhelpandonboardingfeedbacksec.types";
import { useCancelModal } from "../../customhooks";

const {
  REACT_APP_AIR_TABLE_BASE_KEY,
  REACT_APP_AIR_TABLE_HELP_FEEDBACK_TABLE_ID,
  REACT_APP_AIR_TABLE_API_KEY,
} = getEnvVariables();

const DvsumHelpAndOnboardingFeedbackSec = (
  props: DvsumHelpAndOnboardingFeedbackSecProps
): JSX.Element => {
  const cancelModal = useCancelModal();

  const { id = "", type = "" } = props;

  const [action, setAction] = useState<"Upvote" | "Downvote" | "">("");

  const feedbackRef = useRef<any>(null);

  const {
    control,
    watch,
    formState: { isValid },
  } = useForm<{ comment: string }>({
    defaultValues: { comment: "" },
    resolver: yupResolver(helpFeedbackFormSchema),
    mode: "onChange",
  });

  const { comment = "" } = watch();

  const isDetailsModal = type !== "Tutorial";
  const exitModal = isDetailsModal ? cancelModal : exitOnboardinTutorial;

  const { onExecuteRequest, data, isLoading, error } = useRequestWithMethod(
    "create_feedback_req",
    [REACT_APP_AIR_TABLE_BASE_KEY, REACT_APP_AIR_TABLE_HELP_FEEDBACK_TABLE_ID],
    true,
    exitModal,
    undefined,
    { Authorization: `Bearer ${REACT_APP_AIR_TABLE_API_KEY}` },
    false
  );

  const onSubmit = useCallback(() => {
    if (action) {
      onExecuteRequest({
        fields: {
          "user-id":
            localStorage?.getItem(LOCAL_STORAGE_CONSTANTS?.email) || "",
          "help-asset-id": [id],
          "user-action": action,
          comment,
          url: window.location.href,
        },
      });
    } else {
      exitModal();
    }
  }, [comment, action, id]);

  const onChangeAction = useCallback(
    (updAction) => {
      setTimeout(() => feedbackRef?.current?.scrollIntoView?.(), 300);

      setAction(updAction);
    },
    [action]
  );

  return (
    <DvsumHelpAndOnboardingFeedbackSecStyled isDetailsModal={isDetailsModal}>
      <Statehandler isFetching={isLoading} error={error} isModal>
        <div className="feedback-sec">
          {data?.fields ? (
            <div className="feedback-text">Thank you for your feedback!</div>
          ) : (
            <>
              <div className="feedback-label">Was this {type} helpful?</div>
              <div className="feedback-vote-sec">
                <div className="vote-btns">
                  <Button
                    id="link"
                    icon={likeIcon()}
                    className={action === "Upvote" ? "selected" : ""}
                    onClick={(): void => onChangeAction("Upvote")}
                  >
                    Yes, thanks!
                  </Button>
                  <Button
                    id="link"
                    icon={disLikeIcon()}
                    className={action === "Downvote" ? "selected" : ""}
                    onClick={(): void => onChangeAction("Downvote")}
                  >
                    Not really
                  </Button>
                </div>

                {action === "Downvote" && (
                  <TextAreaField
                    control={control}
                    className="comment-field"
                    placeholder="Your comment will help us improve! (Optional)"
                    name="comment"
                    height="78px"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Statehandler>
      <div ref={feedbackRef} />
      <Button className="submit-btn" onClick={onSubmit} disabled={!isValid}>
        {!!action && "Submit Feedback and"} {`Exit ${type}`}
      </Button>
    </DvsumHelpAndOnboardingFeedbackSecStyled>
  );
};

export default DvsumHelpAndOnboardingFeedbackSec;
