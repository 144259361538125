import styled from "styled-components";

export const SearchableSourcesContainerStyled = styled.div<{
  isSourceSelectable: boolean;
}>`
  padding: 20px 34px 20px 20.8px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  .no-source-message {
    opacity: 0.5;
  }
  .section-modal-title-bar {
    display: flex;
    padding-top: 38px;

    .section-modal-title {
      font-size: 21px;
      font-family: OpenSans;
      color: ${({ theme }): string => theme.textColors.BLUE_116};
    }

    .section-modal-title-close {
      button {
        display: block;

        .ant-modal-close-x {
          height: 90px;
          width: 52px;
        }
      }
    }
  }

  .section-header {
    display: flex;
    align-items: flex-end;

    .left-bar {
      flex: 1;
      font-size: 14px;
      font-family: OpenSans;
      color: ${({ theme }): string => theme.textColors.GREY_24};

      .left-title-wrapper {
        width: fit-content;

        .left-title-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          align-items: baseline;

          > div:first-of-type {
            font-size: 18px;
          }

          .left-title-header-counter {
            font-size: 13px;
            font-family: OpenSans;
          }
        }

        .left-title-desc {
          font-size: 13px;
        }
      }
    }

    .right-bar {
      .ant-input-prefix {
        margin-right: 8px;
      }
    }
  }

  .section-body {
    /* height: 354px; */
    .sources-container {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      overflow: auto;
      max-height: calc(100vh - 250px);

      .source-tile {
        height: 120px;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: auto;
        transition: all 200ms linear;
        border: 1px solid transparent;
        border-radius: 12px;

        &.selected {
          border: 1px solid #edf3f9;
          background: #f9fbfd;
          position: relative;

          .ant-checkbox {
            position: absolute;
            top: 6px;
            left: 6px;

            .ant-checkbox-inner {
              width: 13px;
              height: 13px;

              :after {
                width: 4px;
                height: 7px;
              }
            }
          }
        }

        ${({ isSourceSelectable }): any =>
          isSourceSelectable &&
          `:hover {
          box-shadow: 0 2px 8px 0 rgba(124, 140, 240, 0.4);
          border: solid 1px rgba(66, 139, 202, 0.4);
          border-radius: 12px;
          cursor: pointer;
        }`}
      }
    }
  }
`;
