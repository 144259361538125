//             <---Styles--->
import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
//             <---Styles--->

//             <---Types--->
import { AlignLineageItems } from "./lineagepreviewer.types";
//             <---Types--->

export const LineagePreviewerStyled = styled.div<{
  align?: AlignLineageItems;
  marginBottom?: string;
}>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
  padding: 20px;
  /* align-items: ${({ align }): string =>
    align === "start"
      ? "flex-start"
      : align === "last"
      ? "flex-end"
      : "center"}; */
  align-items: center;
  justify-content: center;
  min-height: 200px;
  margin-bottom: ${({ marginBottom }): string => marginBottom || ""};
`;

export const LineageSingleSecStyled = styled.div<{ isCurrent?: boolean }>`
  width: 220px;
  padding: 14px 35px 14px 14px;
  border-radius: 4px;
  border: solid 1px ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_5};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  font-family: ${({ isCurrent }): string => (isCurrent ? "OpenSansBold" : "")};

  .current-node {
    ${ellipsis}
  }
`;

export const LineageSingleSecWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
