import styled from "styled-components";

export const PopularityScoreDetailCardStyled = styled.div`
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  min-width: 330.4px;
  padding: 22.4px;
  border: 1.5px solid ${({ theme }): string => theme.borderColors.BLUE_116};
  border-radius: 6.4px;

  .title-section {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-family: "OpenSansSemiBold";
    font-size: 16px;
    line-height: 19.2px;
  }

  .used-details-section {
    line-height: 19.2px;
    font-size: 14px;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};

    .bold {
      font-family: "OpenSansBold";
    }
  }

  .never-used-section {
    color: ${({ theme }): string => theme.textColors.GREY_24};
    font-style: italic;
    font-size: 14px;
    line-height: 19.2px;
  }

  .top-users-section {
    margin-top: 18.4px;
    padding-top: 12.8px;
    border-top: 1px solid
      ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};

    .heading {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_120};
      font-size: 12px;
    }

    .top-users-list {
      .user-row {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        font-size: 14px;
      }
    }
  }
`;
