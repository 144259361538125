import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import {
  TrainbotFeedbackAddtionalFeedbackForm,
  TrainbotFeedbackformSubmitSec,
  TrainBotFeedbackTrainMeSec,
} from "./trainbotfeedbackform.components";

import { TrainBotFeedbackFormStyled } from "./trainbotfeedbackform.styles";

import { TrainBotFeedbackFormProps } from "./trainbotfeedbackform.types";
import { UserFeedackOnQuery } from "../..";

import { trainbotFeedbackFormSchema } from "../../../../utils/schemas";
import LinkButton from "../../../../components/linkbutton";

import { crossIcon } from "../../../../svgs";
import { checkIsChatPage } from "../../../../utils";

export type TrainbotFeedbackFormResponseForm = UserFeedackOnQuery;

const isChatPage = checkIsChatPage();

const TrainBotFeedbackForm = (
  props: TrainBotFeedbackFormProps
): JSX.Element => {
  const {
    queryItem,
    handlers,
    toggleFeedbackSuccessMsg,
    toggleRefreshQueryTooltip,
    isEditMode = false,
    errorCase = false,
    onCrossClick,
    isAuthor,
    itemToVisualize,
    srcTypeId,
    gatewayId = "",
  } = props;

  const { user_feedback: userFeedback, query } = queryItem || {};

  const {
    comment = "",
    train_sql: trainQuery = "",
    reason: propsReason,
    train_me: trainMe = "",
  } = userFeedback || {};

  const { sql_query: sqlQuery = "" } = query || {};

  const trainbotFeedbackForm = useForm<TrainbotFeedbackFormResponseForm>({
    defaultValues: {
      comment,
      train_sql: trainQuery || "",
      train_me: trainMe || "",
      reason: errorCase ? "WQ" : propsReason || "WD",
      response: "dislike",
    },
    mode: "onChange",
    resolver: yupResolver(trainbotFeedbackFormSchema(isChatPage)),
  });

  const { watch } = trainbotFeedbackForm;
  const { reason: watchedReason } = watch();

  const isReadOnlyMode = isChatPage && !isAuthor;

  return (
    <TrainBotFeedbackFormStyled
      isReasonSelected={!!watchedReason}
      isChatPage={isChatPage}
      isOtherReasonOnBot={!isChatPage && watchedReason === "OTH"}
    >
      <div className="train-bot-feedback-close">
        <LinkButton onClick={onCrossClick}>{crossIcon("16", "16")}</LinkButton>
      </div>
      <FormProvider {...trainbotFeedbackForm}>
        <div className="train-bot-feedback-form-body-wrapper">
          <div className="train-bot-feedback-form-body">
            <TrainbotFeedbackAddtionalFeedbackForm
              errorCase={errorCase}
              isAuthor={isAuthor}
              doesSqlExists={!!sqlQuery}
            />

            <TrainBotFeedbackTrainMeSec
              queryItem={queryItem}
              handlers={handlers}
              isAuthor={isAuthor}
              itemToVisualize={itemToVisualize}
              srcTypeId={srcTypeId}
              gatewayId={gatewayId}
            />
          </div>
        </div>
        {!isReadOnlyMode && (!isChatPage || !!watchedReason) && (
          <TrainbotFeedbackformSubmitSec
            queryItem={queryItem}
            handlers={handlers}
            toggleRefreshQueryTooltip={toggleRefreshQueryTooltip}
            toggleFeedbackSuccessMsg={toggleFeedbackSuccessMsg}
            isEditMode={isEditMode}
            onCrossClick={onCrossClick}
          />
        )}
      </FormProvider>
    </TrainBotFeedbackFormStyled>
  );
};

export default TrainBotFeedbackForm;
