import { Tooltip } from "antd";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { IconWithSelectedStateStyled } from "./iconselectedstate.styles";
import { IconWithSelectedStateProps } from "./iconselectedstate.types";

function IconWithSelectedState(props: IconWithSelectedStateProps): JSX.Element {
  const { tooltipProps = { title: "" } } = props;
  return (
    <Tooltip {...tooltipProps} overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}>
      <IconWithSelectedStateStyled {...props}>
        {props?.children}
      </IconWithSelectedStateStyled>
    </Tooltip>
  );
}

export default IconWithSelectedState;
