// <--Library Imports Start-->
import { useHistory } from "react-router";
import { createPopper } from "@popperjs/core";

import Confetti from "react-confetti";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// <--Library Imports End-->

// <--Components Start-->
import Statehandler from "../statehandler";
import { ModalStyled } from "../modalwrapper/modalwrapper.styles";
import { EndOnboardingTutorialModal } from "../modalwrapper/modals";
// <--Components End-->

// <--Constants Start-->
import { ONBOARDING_TUTORIAL_PARAMS } from "./onboardingtutorial.constant";
// <--Constants End-->

// <--Custom Hooks Start-->
import {
  useCancelDrawer,
  useCustomPolling,
  useDebounce,
  useQueryParams,
  useWindowSize,
} from "../../customhooks";
// <--Custom Hooks eEnd-->

// <--Services Start-->
import { useGetOnboardingTutorial } from "../../api/helpandonboardingservice";
// <--Services End-->

// <--Styles Start-->
import {
  OnboardingModeGlobalStyled,
  OnboardingTutorialStyled,
} from "./onboardingtutorial.styles";
// <--Styles End-->

// <--SVGS Start-->
import { crossIcon, cursorSvg, typeSVG } from "../../svgs";
// <--SVGS End-->

// <--Types Start-->
import { ReturnTypeOfParsedOnboardingTutorial } from "../../parsers/parser.types";
import { EndOnboardingTutorialModalProps } from "../modalwrapper/modals/endonboardintutorialmodal/endonboardintutorialmodal.types";
import { openOnboardingTutorial } from "../../utils";

// <--Types End-->

const cursorSVG = cursorSvg();

const {
  id: ID,
  start_url: START_URL,
  asset_id: ASSET_ID,
  total_steps: TOTAL_STEPS,
} = ONBOARDING_TUTORIAL_PARAMS;

const DEF_MOD_DTL = { id: "", content: "", title: "" };

function OnboardingTutorial(): JSX.Element {
  //States
  const history = useHistory();
  const params = useQueryParams();

  const onCancelDrawer = useCancelDrawer();

  const [
    onboardingTutorialSteps,
    setOnboardingTutorialSteps,
  ] = useState<ReturnTypeOfParsedOnboardingTutorial>([]);

  const [itemExists, setItemExists] = useState(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [modalDetails, setModalDetails] = useState<{
    type?: "exit" | "finish";
    visible: boolean;
    details: EndOnboardingTutorialModalProps;
  }>({
    visible: false,
    details: DEF_MOD_DTL,
  });
  const [width = 0, height = 0] = useWindowSize();

  const [id] = useState(params?.get(ID) || "");
  const [startUrl] = useState(params?.get(START_URL) || "");
  const [assetId] = useState(params?.get(ASSET_ID) || "");
  const [totalSteps] = useState(Number(params?.get(TOTAL_STEPS)) || "");

  const [elemVal, setElemVal] = useState();

  const elemValDebounce = useDebounce(elemVal, 500);

  //refs
  const itemExistsRef = useRef(false);

  // Variables
  const currentItem = onboardingTutorialSteps?.find((url) => !url.visited);

  //memos
  const currentElement = useMemo(
    () => document.getElementById(currentItem?.step_element_id || ""),
    [currentItem, itemExists, itemExistsRef]
  );

  //Services
  const { parsedData = [], isLoading, error } = useGetOnboardingTutorial(
    assetId ? `tutorialid=${assetId}` : ``
  );

  //Callbacks
  const popperInstance = (): any => {
    const state = createPopper(
      document.querySelector(`#${currentItem?.step_element_id}`) as any,
      document.querySelector("#onboarding-popover") as any,
      {
        placement: currentItem?.tooltip_placement || "right-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: currentItem?.tooltip_offset || [0, 10],
            },
          },
        ],
      }
    );

    return state;
  };

  const onChangeEvent = useCallback(
    (e) => {
      if (currentItem?.required_field) {
        const val = e?.target?.value;

        setElemVal(val);
      }
    },
    [currentItem]
  );

  useEffect(() => {
    if (elemVal) {
      setOnboardingTutorialSteps((st) =>
        st?.map((stItem) => ({
          ...stItem,
          show_next_btn:
            stItem?.step_num === currentItem?.step_num
              ? true
              : stItem?.show_next_btn,
        }))
      );
    } else {
      setOnboardingTutorialSteps((st) =>
        st?.map((stItem) => ({
          ...stItem,
          show_next_btn:
            stItem?.step_num === currentItem?.step_num
              ? false
              : stItem?.show_next_btn,
        }))
      );
    }
  }, [elemValDebounce]);

  const onClickElement = useCallback(() => {
    if (
      (itemExists && itemExistsRef?.current) ||
      currentItem?.step_action === "info"
    ) {
      if (currentItem?.is_open_new_tutorial_in_new_tab) {
        openOnboardingTutorial({
          assetId: currentItem?.next_tutorial_asset_id,
          id: currentItem?.next_tutorial_id,
          startUrl: currentItem?.next_tutorial_start_url_from_id,
          totalSteps: currentItem?.next_tutorial_total_steps_from_id,
        });
        window.close();
      }
      currentElement?.removeEventListener?.("input", onChangeEvent);

      setOnboardingTutorialSteps((st) => {
        const updatedList = st.map((item) => ({
          ...item,
          visited:
            item?.step_num === currentItem?.step_num ? true : item?.visited,
        }));

        const nextElement = updatedList?.find((item) => !item?.visited);

        if (currentItem?.remove_next_element_id_from_dom) {
          document
            ?.getElementById(nextElement?.step_element_id || "")
            ?.removeAttribute("id");
        }

        if (currentItem?.close_drawer_on_next) {
          onCancelDrawer();
        }

        const nextElementIExists = currentItem?.set_next_elem_visibility_false
          ? false
          : !!document?.getElementById(nextElement?.step_element_id || "");

        setItemExists(nextElementIExists);

        itemExistsRef.current = nextElementIExists;

        return updatedList;
      });

      // popperInstance?.()?.destroy();
    }
  }, [onboardingTutorialSteps, currentItem, currentElement, popperInstance]);

  const onClickCancel = useCallback(() => {
    setModalDetails({
      type: "exit",
      visible: true,
      details: {
        id,
        title: "Exit the tutorial?",
        content:
          "Looks like you are getting the hang of this already. You can always take the tutorial again by accessing help.",
      },
    });
  }, []);

  const onClickFinish = useCallback(() => {
    setShowConfetti(true);
    setItemExists(false);
    setIsFinished(true);

    itemExistsRef.current = false;

    document
      ?.getElementById(currentItem?.step_element_id || "")
      ?.removeAttribute("id");

    setModalDetails({
      type: "finish",
      visible: true,
      details: {
        id,
        title: "Congratulations",
        content:
          "You've got the basics down. Now it's time to start using your data catalog.",
      },
    });

    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  }, [currentItem]);

  const onCloseModal = useCallback(() => {
    setModalDetails({ visible: false, details: DEF_MOD_DTL });
  }, []);

  //Custom Hooks
  useCustomPolling(
    () => {
      if (!itemExists || !itemExistsRef?.current) {
        if (
          document.getElementById(currentItem?.step_element_id || "")
            ?.offsetWidth
        ) {
          setItemExists(true);
          itemExistsRef.current = true;
          currentItem?.scroll_to_element &&
            document
              .getElementById(currentItem?.step_element_id || "")
              ?.scrollIntoView({ behavior: "smooth" });
        } else {
          setItemExists(false);
          itemExistsRef.current = false;
        }
      }
    },
    300,
    [itemExists, currentItem, currentElement, itemExistsRef?.current]
  );

  // useEffects
  useEffect(() => {
    itemExists && itemExistsRef?.current && popperInstance?.();
  }, [itemExists, currentItem, itemExistsRef?.current]);

  useEffect(() => {
    if (!isLoading && !error) {
      setOnboardingTutorialSteps(parsedData);
      history.push(startUrl);
    }
  }, [isLoading, error]);

  // useEffect(() => {
  //   if (itemExists && !currentItem?.visited && !itemExistsRef?.current) {
  //     currentElement?.removeEventListener("click", onClickElement);
  //   }
  // }, [itemExists, itemExistsRef?.current]);

  useEffect(() => {
    if (
      currentItem?.step_action === "form-field" &&
      itemExists &&
      itemExistsRef?.current &&
      currentElement
    ) {
      currentElement?.addEventListener("input", onChangeEvent);
    } else if (
      itemExists &&
      currentElement &&
      currentItem?.step_action === "button" &&
      itemExistsRef?.current
    ) {
      currentElement.addEventListener("click", onClickElement);
    }
  }, [currentItem, itemExists, itemExistsRef?.current]);

  const nextBtn = (
    <div
      className="onboarding-popup-action-btn"
      role="button"
      onClick={(): void => {
        currentItem?.element_to_click_on_next &&
          document
            ?.getElementById(currentItem?.element_to_click_on_next)
            ?.click();

        onClickElement();
      }}
    >
      Next
    </div>
  );

  return (
    <Statehandler isFetching={isLoading} error={error} isModal>
      <>
        {showConfetti && (
          <Confetti
            width={width}
            height={height}
            style={{ zIndex: 20000001, position: "fixed" }}
          />
        )}
        <ModalStyled
          visible={modalDetails?.visible}
          zIndex={2000000}
          width="650px"
          centered
          destroyOnClose
          closable={false}
        >
          <EndOnboardingTutorialModal
            {...modalDetails?.details}
            onClose={onCloseModal}
            isExitTutorialModal={modalDetails?.type === "exit"}
          />
        </ModalStyled>
        <OnboardingModeGlobalStyled
          id={currentItem?.step_element_id || ""}
          allowEvents={
            currentItem?.step_action !== "info" &&
            currentItem?.step_action !== "finish" &&
            !modalDetails?.visible
          }
          padding={currentItem?.step_element_padding || ""}
          margin={currentItem?.step_element_margin || ""}
          isPlaceLeftArrowTop={!!currentItem?.is_place_left_arrow_top}
          transformElement={!currentItem?.step_element_id}
          item={currentItem}
        />
        {(itemExists && !isLoading && !isFinished) ||
        (!currentItem?.step_element_id &&
          currentItem &&
          !isLoading &&
          !isFinished) ? (
          <OnboardingTutorialStyled id="onboarding-popover">
            <div className="onboarding-popup">
              <div className="onboarding-popup-header">
                <div className="onboarding-popup-header-left">
                  Step {currentItem?.step_num} of{" "}
                  {totalSteps || parsedData?.length}
                </div>
                <div
                  className="onboarding-popup-header-right"
                  role="button"
                  onClick={onClickCancel}
                >
                  {crossIcon()}
                </div>
              </div>
              <div className="onboarding-popup-title">
                {currentItem?.step_title}
              </div>
              <div className="onboarding-popup-desc">
                {currentItem?.step_desc}
              </div>
              {currentItem?.step_action === "info" ? (
                nextBtn
              ) : currentItem?.step_action === "finish" ? (
                <div
                  className="onboarding-popup-action-btn"
                  role="button"
                  onClick={onClickFinish}
                >
                  Finish
                </div>
              ) : currentItem?.show_next_btn ? (
                nextBtn
              ) : (
                <div className="onboarding-popup-footer">
                  {currentItem?.step_action === "button" ? cursorSVG : typeSVG}
                  {currentItem?.step_action_text}
                  <span className="bold-text">
                    {currentItem?.step_action_bold_text}
                  </span>
                  {currentItem?.step_action_suffix_text}
                </div>
              )}
            </div>
            {currentItem?.step_element_id && (
              <div id="arrow" data-popper-arrow />
            )}
          </OnboardingTutorialStyled>
        ) : (
          <div />
        )}
      </>
    </Statehandler>
  );
}
export default OnboardingTutorial;
