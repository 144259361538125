import { Link } from "react-router-dom";
import { MenuItemStyled, MenuStyled } from "./dropdownmenu.styles";
import { DropdownMenuProps } from "./dropdownmenu.types";
import LinkButton from "../linkbutton/linkbutton";

function DropdownMenu(props: DropdownMenuProps): JSX.Element {
  const {
    menu = [],
    height = "max-content",
    isDisabled,
    width = "172px",
  } = props;

  return (
    <MenuStyled
      width={width}
      height={height}
      isDisabled={isDisabled}
      className="menu"
    >
      {menu?.map((item, index) => (
        <MenuItemStyled
          isDisabled={item?.isDisabled}
          className="menu-item"
          key={`dropdown-item-${index}`}
        >
          {item?.link ? (
            <Link to={item?.link} key={item?.key}>
              <div>{item?.label}</div>
            </Link>
          ) : (
            <LinkButton
              onClick={(): void => {
                item?.onClick?.();
              }}
              key={item?.key}
              disabled={item?.isDisabled}
              className="menu-item-button"
            >
              <span className="text">{item?.label}</span>
            </LinkButton>
          )}
        </MenuItemStyled>
      ))}
    </MenuStyled>
  );
}

export default DropdownMenu;
