import React, { SetStateAction } from "react";
import {
  FieldValues,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";

import { API_CONFIG } from "../../../../../../../constants/apiconfig";
import { openNotification } from "../../../../../../../utils";
import { DynamicFieldsJoinProps } from "../connectionsettingsdynamicmodal/connectionsettingsdynamicmodal.types";
import { DynamicFormGroupProps } from "../connectionsettingstabformgroup/connectionsettingstabformgroup.types";

import {
  ActionTypeProps,
  DynamicAlertConfigProps,
  DynamicElementsProps,
  DynamicEnableDisableElementsProps,
  DynamicFormFieldProps,
  DynamicHideShowElementsProps,
  DynamicLogEventTypes,
  DynamicOnCompletedActionConfigProps,
  DynamicXhrConfigProps,
  DynamicXhrConfigReqDataProps,
} from "./connectionsettingstabform.types";

import {
  connectionSettingsLogger,
  getFieldCheckData,
  getHideShowElementsForResponseCheck,
  getResponseCheckData,
} from "./utils";

import { getTemplateMessageByConfig } from "./utils/getTemplateMessageByConfig.util";

function hideOrShowElements(
  config: DynamicHideShowElementsProps,
  elements?: Array<HTMLElement>
): void {
  elements?.forEach((elem) => {
    elem.style.display = config?.show ? "" : "none";
  });
}

function enableOrDisableElements(
  config: DynamicEnableDisableElementsProps,
  elements?: Array<HTMLElement>
): void {
  if (config?.disable === undefined) {
    return;
  }

  elements?.forEach((elem) => {
    if (config?.disable) {
      elem.setAttribute("disabled", "true");
    } else {
      elem.removeAttribute("disabled");
    }
  });
}

export function getAlertContentData(
  alertConfig: DynamicOnCompletedActionConfigProps["alertConfig"],
  checkData?: string[]
): {
  type: DynamicAlertConfigProps["alertType"];
  message: string;
  description: string;
} {
  return {
    type: alertConfig?.alertType || "error",
    message:
      typeof alertConfig?.message === "string"
        ? alertConfig?.message
        : getTemplateMessageByConfig(
            alertConfig?.message,
            alertConfig?.responseCheck,
            checkData
          ),
    description:
      typeof alertConfig?.description === "string"
        ? alertConfig?.description
        : getTemplateMessageByConfig(
            alertConfig?.description,
            alertConfig?.responseCheck,
            checkData
          ),
  };
}

export function setAlertsContentForData({
  alertConfig,
  setAlertsContent,
  checkData,
}: {
  alertConfig?: DynamicOnCompletedActionConfigProps["alertConfig"];
  setAlertsContent?: DynamicFormGroupProps["setAlertsContent"];
  checkData?: string[];
}): void {
  if (alertConfig) {
    const alertContent = getAlertContentData(alertConfig, checkData);

    setAlertsContent &&
      setAlertsContent((st) => ({
        ...st,
        ...alertConfig?.alertFields?.reduce(
          (prev, curr) => ({
            ...prev,
            [`${curr}`]: {
              ...{
                type: alertConfig?.alertType,
                message: alertConfig?.message,
              },
              ...(alertContent?.description && {
                description: alertContent?.description,
              }),
            },
          }),
          {}
        ),
      }));
  }
}

export function hideShowGroupOrField(
  hideShowElements: DynamicHideShowElementsProps[] = [],
  setGroupVisibility?: React.Dispatch<SetStateAction<{ [x: string]: boolean }>>,
  toggleOption = false
): void {
  hideShowElements?.forEach((config) => {
    if (config?.elementsType === "group") {
      config?.elementsRef?.forEach((ref) => {
        // const elem = document.getElementById(`${ref}`);

        setGroupVisibility &&
          setGroupVisibility((st) => ({
            ...st,
            [ref]: toggleOption ? !config?.show : config?.show,
          }));
      });
    } else if (config?.elementsType === "fields") {
      if (config?.getElementBy === "id") {
        hideOrShowElements(
          config,
          config?.elementsRef?.reduce(
            (prev: Array<HTMLElement>, curr: string) => {
              const elem = document.getElementById(`${curr}`);
              return elem ? [...prev, elem] : prev;
            },
            []
          )
        );
      } else if (config?.getElementBy === "attr") {
        const elements = config?.elementsRef?.reduce(
          (prev: any[], curr: string) => {
            const elem = document.querySelector(`${curr}`);
            return elem ? [...prev, elem] : prev;
          },
          []
        );
        hideOrShowElements(config, elements || []);
      } else {
        hideOrShowElements(
          config,
          Array.from(document.getElementsByName(`${config.elementsRef}`))
        );
      }
    }
  });
}

export function enableDisableFormElements(
  elementsConfigs: DynamicEnableDisableElementsProps[] = [],
  state?: React.Dispatch<SetStateAction<{ [x: string]: boolean }>>,
  toggleOption = false
): void {
  // state = setEnableDisableElements
  elementsConfigs?.forEach((config) => {
    if (config?.elementsType === "group") {
      config?.elementsRef?.forEach((ref) => {
        state &&
          state((st) => ({
            ...st,
            [ref]: toggleOption ? !config?.disable : config?.disable,
          }));
      });
    } else if (config?.elementsType === "fields") {
      if (!config?.getElementBy) {
        config?.elementsRef?.forEach((ref) => {
          state &&
            state((st) => ({
              ...st,
              [ref]: toggleOption ? !config?.disable : config?.disable,
            }));
        });

        return;
      }

      if (config?.getElementBy === "id") {
        enableOrDisableElements(
          config,
          config?.elementsRef?.reduce(
            (prev: Array<HTMLElement>, curr: string) => {
              const elem = document.getElementById(`${curr}`);
              return elem ? [...prev, elem] : prev;
            },
            []
          )
        );
      } else if (config?.getElementBy === "attr") {
        const elements = config?.elementsRef?.reduce(
          (prev: any[], curr: string) => {
            const elem = document.querySelector(`${curr}`);
            return elem ? [...prev, elem] : prev;
          },
          []
        );
        enableOrDisableElements(config, elements || []);
      } else {
        enableOrDisableElements(
          config,
          Array.from(document.getElementsByName(`${config.elementsRef}`))
        );
      }
    }
  });
}

export function reloadFormElements(
  elementsToReload: string[] = [],
  setFormFieldKeys?: React.Dispatch<SetStateAction<{ [x: string]: string }>>
): void {
  if (setFormFieldKeys) {
    setFormFieldKeys((st) => ({
      ...st,
      ...elementsToReload?.reduce(
        (prev, next) => ({
          ...prev,
          [`${next}`]: `key-conn-form-${next}-${new Date().getTime()}`,
        }),
        {}
      ),
    }));
  }
}

export function reloadGroupOrFields(
  reloadElements: DynamicElementsProps[] = [],
  setFormFieldKeys?: React.Dispatch<SetStateAction<{ [x: string]: string }>>
): void {
  reloadElements?.forEach((config) => {
    if (config?.elementsType === "group") {
      config?.elementsRef?.forEach(() => {
        // setGroupVisibility &&
        //   setGroupVisibility((st) => ({
        //     ...st,
        //     [ref]: config?.show,
        //   }));
      });
    } else if (config?.elementsType === "fields") {
      reloadFormElements(config?.elementsRef, setFormFieldKeys);
    }
  });
}

export function getRequestParams(
  dataFields?: string[],
  formDataFields?: string[],
  paramsAliases?: string[]
): any {
  return {
    ...dataFields?.reduce((o: { [key in string]: any }, v, i) => {
      if (formDataFields) {
        o[paramsAliases?.length ? paramsAliases[i] : v] = formDataFields[i];
      }
      return o;
    }, {}),
  };
}

export function getRequestParamsFromForm(
  formValuesGetter?: UseFormGetValues<FieldValues>,
  config?: DynamicXhrConfigReqDataProps
): any {
  const data = { ...config?.defaultData };

  if (config?.dataFields) {
    const values = formValuesGetter && formValuesGetter(config?.dataFields);

    return {
      ...data,
      ...getRequestParams(
        config?.dataFields,
        values,
        config?.dataFieldsAliases
      ),
    };
  }

  return data;
}

export function getXhrConfigRequestData(
  form?: ReturnType<typeof useForm>,
  xhrConfig?: DynamicXhrConfigReqDataProps,
  parentFormValuesGetter?: UseFormGetValues<FieldValues>
): { [key in string]: any } {
  const data = {
    ...(getRequestParamsFromForm(form?.getValues, xhrConfig) || {}),
    ...((xhrConfig?.parentDataFields &&
      getRequestParamsFromForm(parentFormValuesGetter, {
        dataFields: xhrConfig?.parentDataFields || [],
        dataFieldsAliases: xhrConfig?.parentDataFieldsAliases || [],
      })) ||
      {}),
  };

  if (xhrConfig?.nestedData) {
    return {
      ...data,
      ...Object.keys(xhrConfig?.nestedData)?.reduce(
        (prevObj, nextKey) => ({
          ...prevObj,
          ...(xhrConfig?.nestedData?.[`${nextKey}`] && {
            [`${nextKey}`]: getXhrConfigRequestData(
              form,
              xhrConfig?.nestedData?.[`${nextKey}`],
              parentFormValuesGetter
            ),
          }),
        }),
        {}
      ),
    };
  }

  return data;
}

type onExecuteRequestType = (
  _body?: any,
  _updatedUrlParam?: any[],
  _updatedOnSuccess?: (_response?: any) => void,
  _updatedApiName?: keyof typeof API_CONFIG,
  _updatedOnFailure?: (_error: any) => void,
  _parser?: (_response?: any) => any,
  _updatedHeaders?: any
) => void;

function updateElementsValues(
  updateElementsConfig?: {
    sourceFields?: string[];
    destFields?: string[];
  },
  form?: ReturnType<typeof useForm>,
  parentFormValuesSetter?: UseFormSetValue<FieldValues>
): void {
  if (updateElementsConfig) {
    if (
      updateElementsConfig?.sourceFields?.length ===
      updateElementsConfig?.destFields?.length
    ) {
      form
        ?.getValues(updateElementsConfig?.sourceFields || [])
        ?.forEach((value, index) => {
          updateElementsConfig?.destFields?.[index] &&
            parentFormValuesSetter &&
            parentFormValuesSetter(
              updateElementsConfig?.destFields?.[index],
              value,
              {
                shouldValidate: true,
                shouldDirty: true,
              }
            );
        });
    } else {
      throw new Error("Source and destination fields count mismatched.");
    }
  }
}

export function handleXHRRequest({
  onExecuteRequest,
  requestUrl,
  form,
  xhrConfig,
  setGroupVisibility,
  parentFormValuesGetter,
  parentFormValuesSetter,
  setFormFieldKeys,
  successCallback,
  setAlertsContent,
  setEnableDisableElements,
  setFieldLogs,
}: {
  onExecuteRequest: onExecuteRequestType;
  requestUrl?: string;
  form?: ReturnType<typeof useForm>;
  xhrConfig?: DynamicXhrConfigProps;
  setGroupVisibility?: React.Dispatch<SetStateAction<{ [x: string]: boolean }>>;
  parentFormValuesGetter?: UseFormGetValues<FieldValues>;
  parentFormValuesSetter?: UseFormSetValue<FieldValues>;
  setFormFieldKeys?: React.Dispatch<SetStateAction<{ [x: string]: string }>>;
  successCallback?: () => void;
  setAlertsContent?: DynamicFormGroupProps["setAlertsContent"];
  setEnableDisableElements?: React.Dispatch<
    SetStateAction<{ [x: string]: boolean }>
  >;
  setFieldLogs?: DynamicFormFieldProps["setFieldLogs"];
}): void {
  xhrConfig &&
    xhrConfig?.logEvents?.loading &&
    connectionSettingsLogger(
      xhrConfig?.logEvents,
      setFieldLogs,
      "loading",
      form
    );

  onExecuteRequest(
    getXhrConfigRequestData(form, xhrConfig?.bodyData, parentFormValuesGetter),
    [requestUrl],
    (response) => {
      if (xhrConfig?.onSuccessConfig) {
        const {
          onSuccessConfig: {
            hideShowElements,
            reloadElements,
            updateElements,
            alertConfig,
            enableDisableElements,
          },
        } = xhrConfig;

        const checkedData =
          (alertConfig?.responseCheck &&
            getResponseCheckData(alertConfig?.responseCheck, response?.data)) ||
          (alertConfig?.fieldsCheck &&
            getFieldCheckData(alertConfig?.fieldsCheck, form));

        let newHideShowElements =
          (hideShowElements && [...hideShowElements]) || [];

        (alertConfig?.fieldsCheck ?? alertConfig?.responseCheck)?.forEach(
          (rCheck) => {
            newHideShowElements = getHideShowElementsForResponseCheck(
              newHideShowElements,
              checkedData,
              rCheck
            );
          }
        );

        hideShowGroupOrField(newHideShowElements, setGroupVisibility);
        reloadGroupOrFields(reloadElements, setFormFieldKeys);
        updateElementsValues(updateElements, form, parentFormValuesSetter);

        alertConfig?.responseCheck?.forEach((rCheck) => {
          if (rCheck.onCheckPass?.notifyConfig) {
            openNotification(
              rCheck.onCheckPass?.notifyConfig?.message,
              rCheck.onCheckPass?.notifyConfig?.duration,
              undefined,
              rCheck.onCheckPass?.notifyConfig?.type
            );
          }
        });

        enableDisableFormElements(
          enableDisableElements,
          setEnableDisableElements
        );

        setAlertsContentForData({
          alertConfig,
          setAlertsContent,
          checkData: checkedData,
        });

        successCallback && successCallback();
      }

      if (xhrConfig?.logEvents) {
        const logEventType: DynamicLogEventTypes | undefined = xhrConfig
          ?.logEvents?.success
          ? "success"
          : xhrConfig?.logEvents?.completed
          ? "completed"
          : undefined;

        logEventType &&
          connectionSettingsLogger(
            xhrConfig?.logEvents,
            setFieldLogs,
            logEventType,
            form,
            response
          );
      }
    },
    undefined,
    undefined,
    undefined,
    getXhrConfigRequestData(form, xhrConfig?.headerData, parentFormValuesGetter)
  );
}

export function handleButtonXHRRequest({
  onExecuteRequest,
  requestUrl,
  form,
  actionData,
  setGroupVisibility,
  parentFormValuesGetter,
  setFormFieldKeys,
  successCallback,
  parentFormValuesSetter,
  setAlertsContent,
  setEnableDisableElements,
  setFieldLogs,
}: {
  onExecuteRequest: onExecuteRequestType;
  requestUrl?: string;
  form?: ReturnType<typeof useForm>;
  actionData?: ActionTypeProps;
  setGroupVisibility?: React.Dispatch<SetStateAction<{ [x: string]: boolean }>>;
  parentFormValuesGetter?: UseFormGetValues<FieldValues>;
  setFormFieldKeys?: React.Dispatch<SetStateAction<{ [x: string]: string }>>;
  successCallback?: () => void;
  parentFormValuesSetter?: UseFormSetValue<FieldValues>;
  setAlertsContent?: DynamicFormGroupProps["setAlertsContent"];
  setEnableDisableElements?: React.Dispatch<
    SetStateAction<{ [x: string]: boolean }>
  >;
  setFieldLogs?: DynamicFormFieldProps["setFieldLogs"];
}): void {
  handleXHRRequest({
    onExecuteRequest,
    requestUrl,
    form,
    xhrConfig: {
      ...(actionData?.onClickConfig?.xhrConfig ||
        ({} as DynamicXhrConfigProps)),
      ...(actionData?.onSuccessConfig && {
        onSuccessConfig: actionData?.onSuccessConfig,
      }),
    },
    setGroupVisibility,
    parentFormValuesGetter,
    parentFormValuesSetter,
    setFormFieldKeys,
    successCallback,
    setAlertsContent,
    setEnableDisableElements,
    setFieldLogs,
  });
}

export const getItemValue = (
  itemValueConfig?: DynamicFieldsJoinProps,
  form?: ReturnType<typeof useForm>
): any => {
  const { fieldsJoiner, valueTemplate, valueTemplatePlaceholder } =
    itemValueConfig || ({} as DynamicFieldsJoinProps);

  if (
    !itemValueConfig?.fields?.length &&
    !itemValueConfig?.valueTemplatePlaceholder
  ) {
    return valueTemplate;
  }

  const value = form
    ?.getValues(itemValueConfig?.fields || [])
    ?.join(fieldsJoiner ?? "");

  if (value && valueTemplate && valueTemplatePlaceholder) {
    return valueTemplate?.replace(valueTemplatePlaceholder, value);
  }

  return value;
};
