import { Key } from "react";
import { DataNode } from "antd/lib/tree";

import { SpecifyCriteriaType } from "../../../../../components/criteriaselection/criteriaselection.types";
import { ModuleSettingsReturnType } from "../../../../../parsers/modulesettingparser/modulesettingparser.types";

import {
  ColumnDataType,
  GovernanceViewFieldsParserReturnType,
} from "../../../../../parsers";

export type AddModuleSettingsFormType = {
  is_active: boolean;
  is_finegrain_enabled: boolean;
  default_flag: string;
  term_criteria_enabled: boolean;
  table_criteria_enabled: boolean;
  column_criteria_enabled: boolean;
  specify_term_criteria: SpecifyCriteriaType[];
  specify_table_criteria: SpecifyCriteriaType[];
  specify_column_criteria: SpecifyCriteriaType[];
};

export type FieldResponse = {
  field_id: number;
  field_name: string;
  field_display_name: string;
  field_id_field: string;
  field_datatype: ColumnDataType;
  display_order: number;
  is_attribute: boolean;
  delimiter: string;
  is_field_hidden: boolean;
};

export type FilterResponse = {
  value: string;
  label: string;
  elligibleTypes: string[];
};

export type TabType = "governance" | "data-quality" | "chat-with-data";
export type TabTypeId = "chat" | "gov" | "dq";

export type ModuleSettingsFormTypes = {
  tabType: TabType;
  tabId: TabTypeId;
};

export type ModuleSettingsFormContentProps = {
  activeTab: TabTypeId;
  settings: ModuleSettingsReturnType;
  termFields: GovernanceViewFieldsParserReturnType[];
  tableFields: GovernanceViewFieldsParserReturnType[];
  columnFields: GovernanceViewFieldsParserReturnType[];
  filterFields: FilterResponse[];

  catalogTreeData?: DataNode[];
  catalogSourceData?: DataNode[];
  catalogDestinationData?: DataNode[];
  glossaryTreeData?: DataNode[];
  glossarySourceData?: DataNode[];
  glossaryDestinationData?: DataNode[];

  updateSourceData?: (_data: DataNode[]) => void;
  updateDestinationData?: (_data: DataNode[]) => void;

  onResetTreeData: () => void;
};

export type ModuleSettingsConfig = Array<{
  value: string;
  key: TabType;
}>;

export const defaultCriteria = {
  column: undefined,
  filter: undefined,
  data_type: "",
  value: "",
  second_value: "",
  date_type: "",
  date_value: undefined,
  second_date_value: undefined,
  values_list: undefined,
};

export type ClickedSchemaType = {
  title: string;
  sourceId: string;
  key: Key;
  isSourceType: boolean;
  resolve: (_value: void | PromiseLike<void>) => void;
};

export type ClickedDomainType = {
  id: string;
  key: Key;
  isSourceType: boolean;
  resolve: (_value: void | PromiseLike<void>) => void;
};
