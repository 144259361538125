import { Dropdown } from "antd";
import styled from "styled-components";

import { hexToRgbA } from "../../utils";
import LinkButton from "../linkbutton/linkbutton";

export const ButtonActionMenuStyled = styled.div``;

export const DropDownWrapperStyled = styled.div<{ isVisible: boolean }>`
  .ant-dropdown {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .actions-container {
    background: ${({ theme, isVisible }): string =>
      isVisible ? hexToRgbA(theme.bgColors.BLUE_116, 0.1) : "inherit"};
  }
`;

export const DropDownButtonStyled = styled(Dropdown)`
  border: unset;
  .dropdown-icon {
    svg {
      width: 6px;
      height: 6px;
    }
  }
`;

export const ThreeDotMenuButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;

  .action-icon {
    height: 16px;
  }

  svg {
    circle {
      fill: ${(props): string => props.theme.bgColors.BLUE_116};
    }
  }
`;

export const ActionItemStyled = styled(LinkButton)`
  height: 40px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  width: 100%;

  :hover {
    background: ${({ theme }): string => theme.bgColors.BLUE_116};

    span {
      color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    }
  }
`;
