import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { RadioGroupField } from "../../../components/formfields";

import {
  ScheduleSummaryStyled,
  SectionContainerStyled,
} from "../addnewjobform.styles";
import { AddNewJobFormType } from "../addnewjobform.types";

import { ScanningInfoForm } from "../../../components";

import { getDvSumInformativeText } from "../../../utils";
import { AddNewJobViaExecuteApi } from "../addnewjobform.comp";
import { getScanSummary } from "../../../pages/sourcedetailpage/tabs/settingstab/tabs/generalsettingstab/generalsettingstabform/generalsettingstabform.util";

import { AddJobScheduleFormPropsType } from "./addjobscheduleform.types";

export const AddJobScheduleForm = (
  props: AddJobScheduleFormPropsType
): JSX.Element => {
  const { control, watch } = useFormContext<AddNewJobFormType>();
  const { jobCategory, scheduleType } = watch();

  const { savedJobId, onGenerateApiUrl, generatingApiUrl, isEdit } = props;

  const {
    dat_dict_dat_qual_add_new_job_schedule_type: {
      description: datDictDatQualAddNewJobScheduleType = "",
    } = {},
    dat_dict_dat_qual_add_new_job_execute_via_api: {
      description: datDictDatQualAddNewJobExecuteViaApi = "",
    } = {},
    dat_dict_dat_qual_add_new_job_schedule: {
      description: datDictDatQualAddNewJobSchedule = "",
    } = {},
  } = getDvSumInformativeText();

  const SCHEDULE_TYPE = [
    {
      label: "Schedule",
      value: "SCH",
      key: "ends-key-1",
      labelDesc: datDictDatQualAddNewJobScheduleType,
    },
    {
      label: "Execute via API",
      value: "API",
      key: "ends-key-2",
      labelDesc: datDictDatQualAddNewJobExecuteViaApi,
    },
  ];

  const scheduleSummary = useMemo(
    () =>
      getScanSummary({
        ...watch(),
      }),

    [watch()]
  );

  return (
    <SectionContainerStyled marginTop="0px">
      {jobCategory === "RLS" && (
        <>
          <SectionContainerStyled marginTop="0">
            <span className="section-title">Schedule Type</span>

            <RadioGroupField
              direction="column"
              control={control}
              name="scheduleType"
              options={SCHEDULE_TYPE}
              style={{ width: "340px" }}
              labelDescWidth="500px"
              disabled={!!savedJobId}
            />
          </SectionContainerStyled>
        </>
      )}

      {scheduleType === "SCH" ? (
        <SectionContainerStyled>
          <span className="section-title">Scheduling Information</span>
          <span className="section-desc">
            {datDictDatQualAddNewJobSchedule}
          </span>

          <ScanningInfoForm invokedFrom="JOB" />
        </SectionContainerStyled>
      ) : (
        <SectionContainerStyled>
          <span className="section-title">API Information</span>
          <AddNewJobViaExecuteApi
            onGenerateApiUrl={onGenerateApiUrl}
            generatingApiUrl={generatingApiUrl}
            jobId={savedJobId}
            isEdit={isEdit}
          />
        </SectionContainerStyled>
      )}

      {scheduleType === "SCH" && (
        <ScheduleSummaryStyled>
          Schedule Summary: {scheduleSummary}
        </ScheduleSummaryStyled>
      )}
    </SectionContainerStyled>
  );
};
