import { Tooltip } from "antd";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { ButtonCustomProps } from "./button.interfaces";
import { ButtonStyled } from "./button.styles";

export function Button(props: ButtonCustomProps): JSX.Element {
  const {
    children,
    id = "primary",
    tooltipProps = { title: "" },
    elementId = "",
  } = props;
  const { overlayClassName } = tooltipProps;

  return (
    <Tooltip
      {...tooltipProps}
      overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} ${overlayClassName ?? ""}`}
    >
      <ButtonStyled {...props} id={elementId} btnType={id}>
        {children}
      </ButtonStyled>
    </Tooltip>
  );
}
