//     <--BlankSlates Start-->
import { tableRecordCountHistoryChartBlankSlate } from "../../blankslates";
//     <--BlankSlates End-->

//     <--Components Start-->
import { LineChart } from "../recharts";
import StateHandler from "../statehandler";
//     <--Components End-->

//     <--Service Start-->
import { useGetTableRecordCountHistory } from "../../api/columnsservice";
//     <--Service End-->

//     <--Styles Start-->
import { TableRecordCountHistoryChartStyled } from "./tablerecordcounthistorychart.styles";
//     <--Styles End-->

//     <--Types Start-->
import { TableRecordCountHistoryChartProps } from "./tablerecordcounthistorychart.types";
//     <--Types End-->

//     <--Utils Start-->
import { numberFormatter } from "../../utils";
import { ChartYAxisType } from "../recharts/columnchart/columnchart.types";
//     <--Utils End-->

export const YAxis: ChartYAxisType = [
  {
    label: {
      value: "",
      offset: -35,
    },
    orientation: "left",
    yAxisId: "name",
    prefix: "",
    suffix: "",
    hide: false,
    domain: [0, "auto"],
  },
];

const TableRecordCountHistoryChart = (
  props: TableRecordCountHistoryChartProps
): JSX.Element => {
  const {
    tableId = "",
    width = "740px",
    height = "",
    blankSlateWidth = "",
  } = props;

  const { parsedData = [], isLoading, error } = useGetTableRecordCountHistory(
    tableId
  );

  return (
    <TableRecordCountHistoryChartStyled>
      <StateHandler
        isFetching={isLoading}
        error={error}
        blankSlate={tableRecordCountHistoryChartBlankSlate(blankSlateWidth)}
      >
        <LineChart
          data={parsedData}
          showLedgends
          dataKeys={[{ dataKey: "Record Count", fill: "#428BCA" }]}
          yAxis={YAxis}
          margin={{
            bottom: 0,
          }}
          height={450}
          width={700}
        />
      </StateHandler>
    </TableRecordCountHistoryChartStyled>
  );
};

export default TableRecordCountHistoryChart;
