import { useCallback } from "react";
import { useQueryClient } from "react-query";

import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { useRequestWithMethod } from "../../api";

import StateHandler from "../../components/statehandler/statehandler";
import { API_CONFIG } from "../../constants/apiconfig";

import { RootState } from "../../store";
import { useCancelModal } from "../../customhooks";

import { DeleteSourceFormStyled } from "./deletesourceform.styles";
import ConfirmationFormModal from "../../components/confirmationformmodal/confirmationformmodal";

import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../../constants";
import { SourcesResponseType } from "../../parsers/sources/sourcesparser.types";

import { solidSuccessTickIcon } from "../../svgs";
import { openNotification } from "../../utils";
import { getGlobalFiltersData } from "../../utils/getglobalfiltersdata";
import { GlobalFiltersData } from "../../parsers/globalfiltersparser/globalfiltersparser.types";

const api = API_CONFIG?.get_all_data_sources?.url;

function DeleteSourceForm(): JSX.Element {
  const history = useHistory();

  const globalFiltersData = getGlobalFiltersData();
  const { selected_filters: selectedFilters } = globalFiltersData || {};
  const { source: sourceGlobalFilters } = selectedFilters || {};
  const { filters_list: sourcesGlobalFiltersList = [] } =
    sourceGlobalFilters || {};

  const { modalProps = {} } = useSelector(
    (state: RootState) => state?.appreducer?.modal
  );

  const {
    id = "",
    sourceName = "",
    isFileUploadSrcType = false,
  } = modalProps as {
    id: string;
    sourceName: string;
    isFileUploadSrcType: boolean;
  };

  const queryClient = useQueryClient();

  const onCancel = useCancelModal();

  const onSuccess = useCallback(
    (response) => {
      if (response?.data) {
        const data = queryClient?.getQueryData(api) as any;

        if (data) {
          queryClient?.setQueryData(api, () => {
            return {
              ...data,
              data: data?.data?.filter(
                (item: SourcesResponseType) => `${item?.SRC_ID}` !== id
              ),
            };
          });
        }

        const updatedSourceGlobalFiltersList = sourcesGlobalFiltersList?.filter(
          (filter) => filter?.id.toString() !== id
        );

        const updatedGlobalFilersData: GlobalFiltersData = {
          ...globalFiltersData,
          selected_filters: {
            ...selectedFilters,
            source: {
              ...sourceGlobalFilters,
              filters_list: updatedSourceGlobalFiltersList,
            },
          },
        };

        localStorage.setItem(
          LOCAL_STORAGE_CONSTANTS?.global_filters,
          JSON.stringify(updatedGlobalFilersData || {})
        );

        onCancel();

        openNotification(
          <div>
            <span className="margin-right-8">{solidSuccessTickIcon} </span>
            <span>
              <span className="bolder-text">{sourceName}</span> has been deleted
              successfully.
            </span>
          </div>
        );

        history.push(`${APP_ROUTES.private_routes.sources}`);
      }
    },
    [modalProps, globalFiltersData, id]
  );

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "delete_source",
    [id],
    true,
    onSuccess
  );

  const onSubmit = useCallback(() => {
    onExecuteRequest({
      s3_folder_name: isFileUploadSrcType ? sourceName : "",
    });
  }, [modalProps]);

  return (
    <StateHandler isModal isFetching={isLoading} error={error}>
      <DeleteSourceFormStyled>
        <ConfirmationFormModal
          okText="Delete"
          onOk={onSubmit}
          onCancel={onCancel}
        >
          If you delete the source, all the catalog objects associated with this
          source will be deleted. Are you sure you want to delete?
        </ConfirmationFormModal>
      </DeleteSourceFormStyled>
    </StateHandler>
  );
}

export default DeleteSourceForm;
