export const highlightTextInMarkdown = (inputString: string): string => {
  const greenPattern = /:green-background\[(.*?)\]/g;
  const redPattern = /:red-background\[(.*?)\]/g;
  const bluePattern = /:blue-background\[(.*?)\]/g;
  const orangePattern = /:orange-background\[(.*?)\]/g;
  // Replace the patterns with span elements
  let highlightedString = inputString.replace(
    greenPattern,
    '<span style="background-color: rgba(33, 195, 84, 0.1);padding:0.125em 0.25em; border-radius:0.25rem;">$1</span>'
  );

  highlightedString = highlightedString.replace(
    redPattern,
    '<span style="background-color: rgba(255, 43, 43, 0.1);padding: 0.125em 0.25em; border-radius:0.25rem;">$1</span>'
  );

  highlightedString = highlightedString.replace(
    bluePattern,
    '<span style="background-color: rgba(28, 131, 225, 0.1);padding: 0.125em 0.25em; border-radius:0.25rem;">$1</span>'
  );

  highlightedString = highlightedString.replace(
    orangePattern,
    '<span style="background-color: rgba(255, 227, 18, 0.1);padding: 0.125em 0.25em; border-radius:0.25rem;">$1</span>'
  );

  return highlightedString;
};
