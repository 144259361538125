import Dragger from "antd/lib/upload/Dragger";
import { blankFileIcon, cloudyUploadIcon, rightAngle } from "../../svgs";
import CustomProgressBar from "../customprogresbar";
import { FileUploaderStyled } from "./fileuploader.styles";
import { FileUploaderProps } from "./fileuploader.types";

function FileUploader(props: FileUploaderProps): JSX.Element {
  const {
    additionalInfo,
    status,
    onChange,
    file,
    completionProgress = 50,
    customClass = "",
  } = props;

  return (
    <FileUploaderStyled className={customClass} hasFile={file}>
      {file ? (
        <div className="progress-sec">
          <div className="left">
            <div className="file-icon">{blankFileIcon()}</div>
            <div className="file-name" title={file?.file?.name}>
              {file?.file?.name}
            </div>
          </div>
          <div className="right">
            <div className="file-status">{status}</div>
            <CustomProgressBar
              width="364px"
              height="8px"
              value={completionProgress}
            />
          </div>
        </div>
      ) : (
        <>
          <Dragger
            {...props}
            beforeUpload={(_info: any): boolean => {
              onChange && onChange(file);
              return false;
            }}
          >
            <div className="desc-sec">
              {rightAngle()} Drop files
              <span className="action__text__1">to upload</span>
              <span className="action__text__2">(or click)</span>
            </div>
            {cloudyUploadIcon()}
          </Dragger>
          <div className="additional-info">{additionalInfo}</div>
        </>
      )}
    </FileUploaderStyled>
  );
}

export default FileUploader;
