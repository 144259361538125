import styled from "styled-components";
import { SimpleTagListProps } from "./simpletaglist.types";

export const SimpleTagListStyled = styled.div<{
  tagsDirection?: SimpleTagListProps["tagsDirection"];
}>`
  display: flex;
  flex-direction: ${({ tagsDirection }): string => tagsDirection || "row"};
  column-gap: 5px;
  row-gap: 5px;

  > div {
    margin-right: 0px;
  }

  .bold-text {
    .content {
      font-family: OpenSansBold;
    }
  }
`;
