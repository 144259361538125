export const TERM_SECTION_TITLES = {
  OVERVIEW: {
    DESCRIPTION: "Definition",
    TERM_TYPE: "Term Type",
    TITLE: "Long Name",
    TERM_CATEGORY: "Sub-Domain",
    FORMULA: "Formula",
    PREVIOUSLY_KNOWN_AS: "Previously known as",
    REPLACES: "Replaces",
    REPLACED_BY: "Replaced By",
    REPLACED_TERMS_DEPRECATED: "All Replaces Term Deprecated",
    USABILITY_SCORE: "Usability Score",
    TERM_NAME: "Short Name",
  },
  BUSINESS_RULES: {
    CATEGORIES: "Categories",
    PATTERN: "Pattern",
    MANUAL: "Manual",
    VALUE_RANGE: "Value Range",
    CATEGORICAL_VALUES: "Categorical Values",
    COLUMN_NAMES: "Column Names",
    COLUMN_SUB_TYPE: "Column Sub-Type",
    PRIMITIVE_DATA_TYPE: "Primitive Data Type",
    REGEX_PATTERNS: "Regex Patterns",
  },
  MANAGED_BY: {
    DATA_OWNERS: "Data Owner",
    DATA_STEWARDS: "Data Steward",
    AUDIT_TRAIL: "Audit Trail",
  },
  CLASSIFICATION: {
    FORMAT: "Format",
    SUB_FORMAT: "Sub-Format",
    FUNCTIONAL_AREA: "Data Domain",
  },
  AUDIT_TRAIL: { CREATED: "Created", UPDATED: "Updated" },
};

export const TERM_SECTIONS = {
  description: "Description",
  managed_by: "Managed By",
  business_rules: "Business Rules",
  linked_columns: "Represents (Linked Columns)",
  usability_score: "Usability Score",
  classification: "Attributes",
  additional_info: "Additional Info",
};
