import { AdditionalResourcesSecStyled } from "./additionalresourcessec.styles";

import {
  checkedListIcon,
  codeIcon,
  commentIcon,
  giftIcon,
  guideBookIcon,
  playIcon,
  redirectIcon,
} from "../../../../svgs";

import { getEnvVariables } from "../../../../utils";
import { onMessageSupportClick } from "./additionalresourcessec.utills";

const { REACT_APP_HELP_CENTER } = getEnvVariables();

const AdditionalResourcesSec = (): JSX.Element => {
  return (
    <AdditionalResourcesSecStyled>
      <div className="title">Additional Resources</div>
      <div className="resources-sec">
        <div
          className="resource-item"
          role="button"
          onClick={onMessageSupportClick}
        >
          <div className="resource-content">
            {commentIcon()} Message support{" "}
          </div>
        </div>
        <div className="divider" />
        <div className="resource-item resource-item-disabled" role="button">
          <div className="resource-content">
            {checkedListIcon()} Onboarding tips{" "}
          </div>
        </div>
        <div className="divider" />
        <a
          className="resource-item"
          href={REACT_APP_HELP_CENTER}
          target="_blank"
          rel="noreferrer"
        >
          <div className="resource-content">{guideBookIcon()} Help center </div>{" "}
          {redirectIcon("10", "10")}
        </a>
        <div className="divider" />
        <div className="resource-item resource-item-disabled">
          <div className="resource-content">{playIcon()} Webinars </div>{" "}
          {redirectIcon("10", "10")}
        </div>
        <div className="divider" />
        <div className="resource-item resource-item-disabled">
          <div className="resource-content">{giftIcon()} What's new </div>{" "}
          {redirectIcon("10", "10")}
        </div>
        <div className="divider" />
        <div className="resource-item resource-item-disabled">
          <div className="resource-content">
            {codeIcon()} API documentation{" "}
          </div>{" "}
          {redirectIcon("10", "10")}
        </div>
      </div>
    </AdditionalResourcesSecStyled>
  );
};

export default AdditionalResourcesSec;
