import { CheckboxStyled } from "./checkbox.styles";
import { CheckboxProps } from "./checkbox.types";

function Checkbox(props: CheckboxProps): JSX.Element {
  const { children, width = "", height = "", mode = "primary" } = props;
  return (
    <CheckboxStyled {...props} width={width} height={height} mode={mode}>
      {children}
    </CheckboxStyled>
  );
}
export default Checkbox;
