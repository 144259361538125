import styled from "styled-components";

export const BusinessNameEditInfoMessageStyled = styled.div`
  margin-top: 10px;

  div {
    gap: 6px;

    .iwt-icon {
      svg {
        height: 11px;

        path {
          fill: ${({ theme }): string => theme.textColors.GREY_COLOR_12};
        }
      }
    }

    .iwt-icon-text {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      font-style: italic;
      font-size: 13px;
      opacity: 0.7;
    }
  }
`;
