import styled from "styled-components";

export const EmptyTableStyled = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 50%;
  background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_25};

  .heading {
    font-size: 19.2px;
    font-family: OpenSansSemiBold;
    margin-top: 9.6px;
    margin-bottom: 9.6px;
  }

  .desc {
    font-size: 14px;
    color: ${({ theme }): string => theme.textColors.BLACK_10};
    margin: 15px;
  }

  .ant-btn {
    font-weight: 600;

    .action-icon {
      font-size: 17.6px;
      font-family: OpenSansExtraBold;
      margin-right: 4px;
    }
  }
`;

export const EmptySlateStoryBookStyled = styled.div`
  display: flex;
  justify-content: center;
`;
