import { AxiosResponse } from "axios";
import { jsonParse } from "../../utils";

import {
  APIKeyParserReturnType,
  APIKeyResponse,
  CurrentIPAddressParserReturnType,
  CurrentIPAddressResponse,
  FirewallEnforcedParserReturnType,
  FirewallEnforcedResponse,
  SAWSDownloadLinkParserReturnType,
  SAWSDownloadLinkResponse,
  SAWSParserReturnType,
  SAWSResponse,
  SPMetadataUrlParserReturnType,
  SPMetadataUrlReponse,
  SSOParserReturnType,
  SSOResponse,
  SSOSettingsParserReturnType,
  SSOSettingsResponse,
  SubscriptionsParserReturnType,
  SubscriptionsResponse,
} from "./accountsettingsparser.types";

export const getParsedCurrentIPAddress = ({
  data,
}: AxiosResponse<CurrentIPAddressResponse>): CurrentIPAddressParserReturnType => {
  return { ipAddress: data?.IPv4 };
};

export function getParsedSubscriptions({
  data,
}: AxiosResponse<SubscriptionsResponse[]>): SubscriptionsParserReturnType[] {
  return data?.map((item) => {
    const tierInfo =
      item?.ACT_TIER_INFO !== ""
        ? jsonParse(item?.ACT_TIER_INFO, true)
        : { amount: "", perks: [] };

    return {
      id: item?.ACT_TIER_ID || "",
      header: {
        title: item?.ACT_TIER_NAME || "",
        description: tierInfo?.amount || "",
        subDescription: !tierInfo?.amount.includes("Your Plan")
          ? "(Billed Anually)"
          : undefined,
      },
      body: {
        perks: tierInfo?.perks || [],
      },
      isActivePlan: item?.IS_ACTIVE_PLAN,
    };
  });
}

export function getParsedAPIKey({
  data,
}: AxiosResponse<APIKeyResponse>): APIKeyParserReturnType {
  return {
    api_key: data?.API_KEY || "",
  };
}

export function getParsedIpAddresses({
  data,
}: AxiosResponse<string[]>): string[] {
  return data || [];
}

export function getParsedDomains({ data }: AxiosResponse<string[]>): string[] {
  return data;
}

export const getParsedSAWS = ({
  data = [],
}: AxiosResponse<SAWSResponse[]>): SAWSParserReturnType[] => {
  return (
    data?.map((item) => {
      const sourceTypes =
        item?.SAWS_SOURCE_TYPE !== "" ? jsonParse(item?.SAWS_SOURCE_TYPE) : [];

      return {
        id: item?.SAWS_ID || 0,
        web_service: item?.SAWS_NAME || "",
        host_name: item?.SAWS_HOSTNAME || "",
        port: `${item?.SAWS_PORT}` || "",
        communication_key: item?.SAWS_COMMUNICATION_KEY || "",
        enable_source_types: sourceTypes,
        version: `${item.SAWS_VERSION}` || "",
        creation_date: item?.CREATED_ON || "",
        status: item?.SAWS_STATUS === "ACT" ? true : false || false,
        saws_status: item?.SAWS_STATUS,
        enable_saws_alert: item?.IS_SAWS_ALERTS_ENABLED || false,
        notify_admins: item?.IS_NOTIFY_ADMINS_ENABLED || false,
        action: "",
        is_on_prem_saws: item?.IS_ON_PREM_SAWS || false,
        gateway_timezone: item?.SAWS_TIMEZONE_ID || "",
        gateway_timezone_offset: item?.SAWS_TIMEZONE_OFFSET || 0,
      };
    }) || []
  );
};

export const getParsedSSO = ({
  data,
}: AxiosResponse<SSOResponse[]>): SSOParserReturnType[] => {
  return (
    data?.map((item) => {
      return {
        name: item?.provider_name || "",
        identifiers: item?.identifier || "",
        idpMetadata: item?.idp_public_link || "",
        idpS3Url: item?.idp_file_s3_link || "",
        attributes: item?.attributes || [],
      };
    }) || []
  );
};

export const getParsedFirewallEnforced = ({
  data,
}: AxiosResponse<FirewallEnforcedResponse>): FirewallEnforcedParserReturnType => {
  return {
    isFirewallEnforced: data?.IS_ACCOUNT_FIREWALL_ENFORCED || false,
  };
};

export const getParsedSSOSettings = ({
  data,
}: AxiosResponse<SSOSettingsResponse>): SSOSettingsParserReturnType => {
  return {
    ssoMode: data?.ACCOUNT_SSO_MODE || "",
    isSSOActive: data?.IS_ACCOUNT_SSO_ACTIVE || false,
  };
};

export const getParsedDownloadLink = ({
  data,
}: AxiosResponse<SAWSDownloadLinkResponse>): SAWSDownloadLinkParserReturnType => {
  return { downloadLink: data?.download_link || "" };
};

export const getParsedSPMetadataLink = ({
  data,
}: AxiosResponse<SPMetadataUrlReponse[]>): SPMetadataUrlParserReturnType => {
  const record = data?.filter((item) => item?.KEY?.includes("cognitopool"));

  return {
    spMetadataKey: record?.[0]?.KEY || "",
    metadataDownloadLink: record?.[0]?.VALUE || "",
  };
};
