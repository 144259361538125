import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";
import { hexToRgbA } from "../../../utils";

export const TermRenderTitleForSelectStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
  padding: 6px 13px 0 13px;
`;

export const TermRenderItemForSelectStyled = styled.div<{ isBold?: boolean }>`
  &.render-item {
    display: flex;
    align-items: center;
    color: ${({ theme }): string => theme.textColors.BLACK_13};

    .node-name,
    .parent-name {
      margin: 0 8px;
      ${ellipsis}
    }
    .right-icon {
      margin-right: 8px;
      height: 15px;
      svg {
        path {
          fill: #b8bbc3;
        }
      }
    }

    svg {
      path {
        fill: ${({ theme }): string => hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
      }
    }
  }
`;
