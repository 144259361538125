import { fileIcon } from "../../../../../../svgs";
import { ExcelSourceFileEmptyStateStyled } from "./excelsourcefilesemptystate.styles";
import { Button } from "../../../../../../components";

function ExcelSourceFileEmptyState(): JSX.Element {
  return (
    <ExcelSourceFileEmptyStateStyled>
      {fileIcon()}
      <div className="heading-static-text">Add files</div>
      <div className="heading-static-desc">
        No file exists in this folder so far
      </div>
      <Button width="116px" height="40px">
        Upload files
      </Button>
    </ExcelSourceFileEmptyStateStyled>
  );
}

export default ExcelSourceFileEmptyState;
