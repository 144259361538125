import { GridApi } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useState } from "react";

import { AgGridPaginationStyled } from "./aggridpagination.styles";
import LinkButton from "../../linkbutton/linkbutton";

import {
  doubleArrowLeft,
  singleArrowLeft,
  singleArrowRight,
  doubleArrowRight,
} from "../../../svgs";

import { Input } from "../../inputs";
import { SelectDropdown } from "../../selectdropdowns";

import { numberFormatter } from "../../../utils";
import { useCustomPolling } from "../../../customhooks";

const AgGridPagination = (props: {
  gridApi: GridApi;
  pageSizes: string[];
  onPageSizeChange: (_pageSize: number, _gridApi: GridApi) => void;
}): JSX.Element => {
  const { gridApi, pageSizes, onPageSizeChange } = props;

  const [, setTriggerState] = useState(new Date());
  const [paginationRowCount, setPaginationRowCount] = useState<number>(0);

  const pageSize = gridApi?.paginationGetPageSize();
  const currentPage = gridApi?.paginationGetCurrentPage() + 1;
  const totalPages = gridApi?.paginationGetTotalPages();

  const [pageNumer, setPageNumer] = useState<number>(1);

  const changeState = useCallback(() => {
    setTriggerState(new Date());
  }, []);

  const onInnerPageSizeChanged = useCallback(
    (innerPageSize) => {
      changeState();
      gridApi?.paginationSetPageSize(Number(innerPageSize));

      onPageSizeChange && onPageSizeChange(Number(innerPageSize), gridApi);
    },
    [gridApi]
  );

  const changePage = useCallback((page) => {
    changeState();

    if (!Number.isNaN(Number(page))) {
      setPageNumer(Number(page));
    } else {
      setPageNumer(1);
    }
  }, []);

  const onEnter = useCallback((): void => {
    changeState();

    const updatedPageNumber = pageNumer > totalPages ? totalPages : pageNumer;
    setPageNumer(updatedPageNumber);

    gridApi?.paginationGoToPage(updatedPageNumber - 1);
  }, [pageNumer]);

  const onPageNavBtnHandler = useCallback(
    (pageNavType) => {
      changeState();

      switch (pageNavType) {
        case "first":
          gridApi?.paginationGoToFirstPage();
          break;
        case "last":
          gridApi?.paginationGoToLastPage();
          break;
        case "next":
          gridApi?.paginationGoToNextPage();
          break;
        case "prev":
          gridApi?.paginationGoToPreviousPage();
          break;
        default:
          break;
      }
    },
    [gridApi]
  );

  const startsFrom: number = useMemo(() => {
    if (currentPage === 1 && !paginationRowCount) {
      return 0;
    }

    return (currentPage - 1) * pageSize + 1;
  }, [currentPage, pageSize, paginationRowCount]);

  const isFirstPage = useMemo(() => {
    return currentPage <= 1;
  }, [currentPage]);

  const isLastPage = useMemo(() => {
    return currentPage >= totalPages;
  }, [currentPage, totalPages]);

  const displayedRowsCount = useMemo(() => {
    if (currentPage === 1 && !paginationRowCount) {
      return 0;
    }

    const count = currentPage * pageSize;

    if (count > gridApi?.paginationGetRowCount()) {
      return gridApi?.paginationGetRowCount() || count;
    }

    return count;
  }, [gridApi, currentPage, pageSize, paginationRowCount]);

  useEffect(() => {
    setPageNumer(paginationRowCount ? currentPage : 0);
  }, [currentPage, paginationRowCount]);

  //it's a quick fix but I'll check about it proper fix. how to get latest value instantly without polling
  useCustomPolling(
    () => {
      if (gridApi) setPaginationRowCount(gridApi?.paginationGetRowCount() || 0);
    },
    500,
    [gridApi]
  );

  return (
    <AgGridPaginationStyled className="ag-pagination-panel">
      <div className="ag-paging-row-summary-panel" aria-hidden="true">
        <div>View</div>
        <div className="ag-paging-row-summary-panel-number">{startsFrom}</div>
        <div>-</div>
        <div className="ag-paging-row-summary-panel-number">
          {displayedRowsCount}
        </div>
        <div>of</div>
        <div className="ag-paging-row-summary-panel-number">
          {numberFormatter(`${paginationRowCount}`)}
        </div>
      </div>

      <div className="ag-paging-page-summary-panel">
        <LinkButton
          aria-label="First Page"
          className={`ag-paging-button first-btn${
            isFirstPage ? " ag-disabled" : ""
          }`}
          onClick={(): void => onPageNavBtnHandler("first")}
        >
          {doubleArrowLeft("11px", "12px")}
        </LinkButton>
        <LinkButton
          className={`ag-paging-button prev-btn${
            isFirstPage ? " ag-disabled" : ""
          }`}
          aria-label="Previous Page"
          onClick={(): void => onPageNavBtnHandler("prev")}
        >
          {singleArrowLeft("7px", "12px")}
        </LinkButton>

        <div className="paging-divider" />

        <div className="ag-paging-description" aria-hidden="true">
          <div>Page</div>
          <div className="ag-paging-number">
            <Input
              type="text"
              value={pageNumer}
              onChange={(e): void => changePage(e.target.value)}
              onPressEnter={onEnter}
            />
          </div>
          <div>of</div>
          <div>{numberFormatter(`${totalPages}`)}</div>
        </div>

        <div className="paging-divider" />
        <LinkButton
          className={`ag-paging-button next-btn${
            isLastPage ? " ag-disabled" : ""
          }`}
          aria-label="Next Page"
          onClick={(): void => onPageNavBtnHandler("next")}
        >
          {singleArrowRight("7px", "12px")}
        </LinkButton>
        <LinkButton
          className={`ag-paging-button last-btn${
            isLastPage ? " ag-disabled" : ""
          }`}
          aria-label="Last Page"
          onClick={(): void => onPageNavBtnHandler("last")}
        >
          {doubleArrowRight("11px", "12px")}
        </LinkButton>

        <div className="ag-paging-number">
          <SelectDropdown
            value={pageSize}
            onChange={onInnerPageSizeChanged}
            height="25px"
            options={pageSizes.map((op: string): any => ({
              label: op,
              value: op,
            }))}
          />
        </div>
      </div>
    </AgGridPaginationStyled>
  );
};

export default AgGridPagination;
