export const deleteListingTermsConfirmationText =
  "Are you sure you want to delete selected Term(s)?";

export const deleteOverviewTermConfirmationText =
  "Are you sure you want to delete this Term?";

export const singleRecordDeleteNote = "Default terms cannot be deleted";

export const multipleRecordsDeleteNote =
  "Out of all selected terms, default terms would not be deleted";
