import { useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import Dynamicform from "../../dynamicform";

import { addBlanksForm } from "./addblanksform.config";
import { addBlanksSchema } from "../../../../utils/schemas";

import { AddBlanksFormType } from "./addblanksform.types";
import { GetAddRuleFormContentProps } from "../../addruleform.types";

import { blanksFormDataToApiData } from "./addblanksform.utils";
import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";

const AddBlanksForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    columnSelectOptions,
    columnsData,
    sourceId,
    tableId,
    onAddRule,
    onUpdateRule,
    ruleCategoryId,
    formId,
    propsOnCancel,
    isEdit = false,
    ruleConfig,
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const { col_id: colId, rule_desc: desc = "" } = ruleConfig || {};

  const form = useForm<AddBlanksFormType>({
    defaultValues: {
      id: "blanks",
      rule_type: "Blanks",
      rule_description: desc,
      column: colId ? String(colId) : "",

      is_filter_criteria_active: isEdit,
      filter_criteria: [],
    },
    resolver: yupResolver(addBlanksSchema),
    mode: "onChange",
  });

  const blankFormConfig = addBlanksForm({
    columnSelectOptions,
    existingFilters: initialFilters,
    isEdit,
  });

  const onSubmit = useCallback(
    (values: AddBlanksFormType) => {
      const rule_config = blanksFormDataToApiData(values);

      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const selectedColumnId = Number(values?.column);

      const updatedDesc = values?.rule_description;

      const ruleConfig = {
        ...rule_config,
        rule_type: formId,
        rule_desc: updatedDesc,
      };

      const addRulePayload = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: updatedDesc,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        rule_def_config: ruleConfig,
        col_ids: [selectedColumnId],
        filter: filterCondition,
      };

      const modifyRulePayload = {
        ...ruleConfig,
        filter_cond: filterCondition,
        col_ids: [selectedColumnId],
      };

      isEdit ? onUpdateRule?.(modifyRulePayload) : onAddRule(addRulePayload);
    },
    [tableId, sourceId, columnsData, onAddRule]
  );

  return (
    <FormProvider {...form}>
      <Dynamicform
        schema={blankFormConfig}
        onSubmit={onSubmit}
        columnsData={columnsData}
        propsOnCancel={propsOnCancel}
        isEdit={isEdit}
      />
    </FormProvider>
  );
};

export default AddBlanksForm;
