import styled from "styled-components";
import { QueryChartSettingsTagVariantType } from "./querychartsettingstag.types";
import { ellipsis } from "../../../../../../../../../../../../../../styles/app.styles";

export const QueryChartSettingsTagStyled = styled.div<{
  variant: QueryChartSettingsTagVariantType;
  disabled: boolean;
  showAddAxisButton: boolean;
}>`
  display: flex;
  padding: 12.8px 16px;
  margin-bottom: 6.4px;
  background: ${({ theme, variant }): string =>
    variant === "BLU" ? "#e2f0fd" : theme?.bgColors?.LIGHT_GREY_22};
  border-radius: 52px;
  pointer-events: ${({ disabled }): string => (disabled ? "none" : "")};
  cursor: ${({ showAddAxisButton }): string =>
    showAddAxisButton ? "default" : "pointer"};
  width: 100%;

  .tag-left-sec {
    display: flex;
    column-gap: 6px;
    align-items: center;
    min-width: 50%;
    width: 100%;

    .ant-btn-link {
      &.column-name {
        display: flex;
        align-items: center;
        line-height: 15px;
        ${ellipsis};

        span {
          max-width: 100%;
          ${ellipsis};

          :hover {
            text-decoration: underline;
          }
        }
      }
    }

    .grid-sort-icon {
      height: 20px;
    }

    .data-type-icon {
      display: flex;
      align-items: center;
      svg {
        max-height: 12px;
        path {
          fill: ${({ theme, disabled }): string =>
            disabled
              ? theme?.textColors?.GREY_18
              : theme?.textColors?.GREY_210};
        }
      }
    }
  }

  .tag-right-sec {
    display: flex;
    column-gap: 4px;
    align-items: center;
    justify-content: flex-end;

    .table-actions-sec {
      .actions-container {
        padding-bottom: 0px;
      }
    }

    .axis-number-tag {
      padding: 4px 8px;
      border-radius: 25px;
      background-color: rgba(60, 154, 240, 0.15);
      color: ${({ theme }): string => theme?.textColors?.BLUE_123};
      font-family: OpenSansBold;
      font-size: 10px;
    }

    .add-axis-button {
      display: flex;
    }
  }
`;
