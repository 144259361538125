// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localstorageconstants";
// <--- Constants End --->

// <--- Types Start --->
import { ChatPrefrencesType } from "../parsers";
// <--- Types End --->

// <--- Utils Start --->
import { getChatPrefrencesData } from "./getchatprefrencesdata";
// <--- Utils End --->

export function updateChatPrefrencesData(
  updatedData: ChatPrefrencesType
): void {
  const chatPrefrencesData = getChatPrefrencesData();
  localStorage.setItem(
    LOCAL_STORAGE_CONSTANTS?.chat_prefrences,
    JSON.stringify({
      ...chatPrefrencesData,
      ...updatedData,
    } as ChatPrefrencesType)
  );
}
