import { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dynamicform from "../../dynamicform";
import { AddMetricFormType } from "./addmetricform.types";

import { addMetricForm } from "./addmetricform.config";
import { addMetricSchema } from "../../../../utils/schemas";

import { GetAddRuleFormContentProps } from "../../addruleform.types";

import { metricFormDataToApiData } from "./addmetricform.utils";
import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";

import { SelectFieldOption } from "../../../../components/formfields/selectfield/selectfield.types";

const AddMetricForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    columnSelectOptions,
    columnsData,
    sourceId,
    tableId,
    onAddRule,
    onUpdateRule,
    ruleCategoryId,
    formId,
    isEdit = false,
    ruleConfig,
    propsOnCancel,
    metricTypes = [],
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const {
    rule_desc: desc = "",
    col_id: colId,
    metric_type: metricType = "AVG",
  } = ruleConfig || {};

  const methods = useForm<AddMetricFormType>({
    defaultValues: {
      id: "metric",
      rule_type: "Metric",
      rule_description: desc,
      column: colId ? String(colId) : "",
      metric_type: metricType,
      is_filter_criteria_active: isEdit,
      filter_criteria: [],
    },
    resolver: yupResolver(addMetricSchema),
    mode: "onChange",
  });

  const decimalAndIntegersColumns: SelectFieldOption[] =
    columnSelectOptions?.filter(
      (col) => col?.type === "DEC" || col?.type === "INT"
    ) || [];

  const parsedMetricTypesOptions = useMemo(
    () =>
      metricTypes
        ?.filter((val) => val?.id?.trim() !== "CNT" && val?.id?.trim() !== "CD")
        .map((metric) => ({
          key: `metric-type-option-${metric?.id}`,
          value: `${metric?.id}` || "",
          label: metric?.name || "",
        })),
    [metricTypes]
  );

  const addMetricFormConfig = addMetricForm({
    columnSelectOptions: decimalAndIntegersColumns,
    existingFilters: initialFilters,
    isEdit,
    parsedMetricTypesOptions,
  });

  const onSubmit = useCallback(
    (values: AddMetricFormType) => {
      const rule_config = metricFormDataToApiData(values);

      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const selectedColumnId = Number(values?.column);

      const desc = values?.rule_description;

      const ruleConfig = {
        ...rule_config,
        rule_type: formId,
        rule_desc: desc,
      };

      const modifyRulePayload = {
        ...ruleConfig,
        filter_cond: filterCondition,
        col_ids: [selectedColumnId],
      };

      const dataToSubmit = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: desc,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        rule_def_config: ruleConfig,
        col_ids: [selectedColumnId],
        filter: filterCondition,
      };

      isEdit
        ? onUpdateRule?.(modifyRulePayload)
        : onAddRule({ ...dataToSubmit });
    },
    [tableId, sourceId, columnsData, onAddRule]
  );

  return (
    <FormProvider {...methods}>
      <Dynamicform
        schema={addMetricFormConfig}
        onSubmit={onSubmit}
        columnsData={columnsData}
        isEdit={isEdit}
        propsOnCancel={propsOnCancel}
      />
    </FormProvider>
  );
};

export default AddMetricForm;
