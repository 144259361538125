import { FiltersColumnParsedConfigurationType } from "../../parsers/tablepage/tablepageparser.types";

export const parsedFields: FiltersColumnParsedConfigurationType[] = [
  {
    display_order: 27,
    field_datatype: "STR",
    field_id: 227,
    field_name: "wf_curr_assigned_names",
    field_display_name: "Assigned To",
    is_attribute: true,
    field_id_field: "wf_curr_assigned_users",
    delimiter: ",",
    is_field_hidden: false,
  },
  {
    display_order: 4,
    field_datatype: "STR",
    field_id: 203,
    field_name: "dmn_name",
    field_display_name: "Data Domain",
    is_attribute: true,
    field_id_field: "dmn_id",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 12,
    field_datatype: "STR",
    field_id: 212,
    field_name: "data_owners",
    field_display_name: "Data Owners",
    is_attribute: true,
    field_id_field: "data_owner_ids",
    delimiter: ",",
    is_field_hidden: false,
  },
  {
    display_order: 11,
    field_datatype: "STR",
    field_id: 211,
    field_name: "data_stewards",
    field_display_name: "Data Stewards",
    is_attribute: true,
    field_id_field: "data_steward_ids",
    delimiter: ",",
    is_field_hidden: false,
  },
  {
    display_order: 7,
    field_datatype: "STR",
    field_id: 207,
    field_name: "trm_desc",
    field_display_name: "Definition",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 14,
    field_datatype: "STR",
    field_id: 214,
    field_name: "has_owner",
    field_display_name: "Has Data Owner",
    is_attribute: true,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 13,
    field_datatype: "STR",
    field_id: 213,
    field_name: "has_steward",
    field_display_name: "Has Data Steward",
    is_attribute: true,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 19,
    field_datatype: "NUM",
    field_id: 218,
    field_name: "trm_id",
    field_display_name: "ID",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: true,
  },
  {
    display_order: 21,
    field_datatype: "BOL",
    field_id: 220,
    field_name: "is_editable",
    field_display_name: "IS_EDITABLE",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: true,
  },
  {
    display_order: 18,
    field_datatype: "STR",
    field_id: 224,
    field_name: "updated_by",
    field_display_name: "Last Updated By",
    is_attribute: true,
    field_id_field: "updated_by_id",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 17,
    field_datatype: "DT",
    field_id: 217,
    field_name: "last_updated_on",
    field_display_name: "Last Updated On",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 10,
    field_datatype: "STR",
    field_id: 210,
    field_name: "linked_columns",
    field_display_name: "Linked Columns",
    is_attribute: true,
    field_id_field: "",
    delimiter: ",",
    is_field_hidden: false,
  },
  {
    display_order: 2,
    field_datatype: "STR",
    field_id: 206,
    field_name: "trm_title",
    field_display_name: "Long Name",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 9,
    field_datatype: "STR",
    field_id: 209,
    field_name: "trm_prvsly_known_as",
    field_display_name: "Previously Known as",
    is_attribute: false,
    field_id_field: "",
    delimiter: ",",
    is_field_hidden: false,
  },
  {
    display_order: 20,
    field_datatype: "STR",
    field_id: 219,
    field_name: "trm_status_id",
    field_display_name: "STATUS_ID",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: true,
  },
  {
    display_order: 1,
    field_datatype: "STR",
    field_id: 201,
    field_name: "trm_name",
    field_display_name: "Short Name",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 16,
    field_datatype: "STR",
    field_id: 216,
    field_name: "trm_status",
    field_display_name: "Status",
    is_attribute: true,
    field_id_field: "trm_status_id",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 3,
    field_datatype: "STR",
    field_id: 202,
    field_name: "cat_name",
    field_display_name: "Sub-Domain",
    is_attribute: true,
    field_id_field: "cat_id",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 5,
    field_datatype: "STR",
    field_id: 204,
    field_name: "cat_priority",
    field_display_name: "Sub-Domain Priority",
    is_attribute: true,
    field_id_field: "cat_priority_id",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 15,
    field_datatype: "STR",
    field_id: 215,
    field_name: "tags",
    field_display_name: "Tags",
    is_attribute: true,
    field_id_field: "tag_ids",
    delimiter: ",",
    is_field_hidden: false,
  },
  {
    display_order: 8,
    field_datatype: "STR",
    field_id: 208,
    field_name: "trm_formula",
    field_display_name: "Term Formula",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 22,
    field_datatype: "STR",
    field_id: 221,
    field_name: "trm_source",
    field_display_name: "Term Source",
    is_attribute: true,
    field_id_field: "trm_source_id",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 6,
    field_datatype: "STR",
    field_id: 205,
    field_name: "trm_sub_type",
    field_display_name: "Term Type",
    is_attribute: true,
    field_id_field: "trm_sub_type_id",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 25,
    field_datatype: "NUM",
    field_id: 225,
    field_name: "trm_metascore",
    field_display_name: "Usability Score",
    is_attribute: false,
    field_id_field: "",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 26,
    field_datatype: "STR",
    field_id: 226,
    field_name: "wf_curr_step_name",
    field_display_name: "Workflow Status",
    is_attribute: true,
    field_id_field: "wf_curr_step_type",
    delimiter: "",
    is_field_hidden: false,
  },
  {
    display_order: 24,
    field_datatype: "STR",
    field_id: 223,
    field_name: "prominent_tags",
    field_display_name: "prominent_tags",
    is_attribute: true,
    field_id_field: "prominent_tags",
    delimiter: ",",
    is_field_hidden: true,
  },
  {
    display_order: 23,
    field_datatype: "STR",
    field_id: 222,
    field_name: "tag_ids",
    field_display_name: "tag_ids",
    is_attribute: true,
    field_id_field: "tag_ids",
    delimiter: ",",
    is_field_hidden: true,
  },
];

export const parsedFilters = [
  {
    value: "BLK",
    label: "Is Empty",
  },
  {
    value: "NBL",
    label: "Not Empty",
  },
  {
    value: "EQL",
    label: "Equals",
  },
  {
    value: "NEQ",
    label: "Does not equal",
  },
  {
    value: "LTH",
    label: "Less Than",
  },
  {
    value: "LTE",
    label: "Less Than Equal To",
  },
  {
    value: "GTH",
    label: "Greater Than",
  },
  {
    value: "GTE",
    label: "Greater Than Equal To",
  },
  {
    value: "BTW",
    label: "In Between",
  },
  {
    value: "NBT",
    label: "Not Between",
  },
  {
    value: "WCM",
    label: "Wildcard Match",
  },
  {
    value: "INC",
    label: "Includes",
  },
  {
    value: "NIN",
    label: "Excludes",
  },
];
