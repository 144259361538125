import LinkButton from "../../components/linkbutton";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import StyledLink from "../../components/styledlink/styledlink";
import { DATA_SOURCE_SECTION_TITLES } from "../../constants/labelconstants";

import { useGetAppType } from "../../customhooks";
import { getTermDetailPageUrl } from "../../utils";

const {
  managed_by: managedBy,
  overview,
  classification: { entity: ENTITY },
} = DATA_SOURCE_SECTION_TITLES;

const EntityRendrer = (item: SectionRowProps): JSX.Element => {
  const { isExtOrDesktop } = useGetAppType();
  return item?.value ? (
    <StyledLink
      to={getTermDetailPageUrl(`${item?.selectedValues?.[0]}`)}
      target={isExtOrDesktop ? "_blank" : "_self"}
    >
      {item?.value}
    </StyledLink>
  ) : (
    <div>--</div>
  );
};

export const dataSourceRevViewRendrer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [overview.node_url]: (item) => {
    return (
      <LinkButton
        href={item?.value}
        target="_blank"
        showEllipsis
        title={item?.value}
        isTextUnderLine
        paddingRight="25px"
      >
        {item?.value}
      </LinkButton>
    );
  },
  [managedBy?.audit_trail]: (item) => {
    return item?.value ? (
      <>
        {item?.value?.split(":")?.map((nestItem) => (
          <div key={nestItem}>{nestItem}</div>
        ))}
      </>
    ) : (
      <span>--</span>
    );
  },
  [ENTITY]: (item) => <EntityRendrer {...item} />,
};
