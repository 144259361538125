function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (_children?: JSX.Element) => JSX.Element;
  children?: JSX.Element;
}): JSX.Element {
  return condition ? wrapper(children) : children ?? <div />;
}

export default ConditionalWrapper;
