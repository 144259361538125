import { FilterCriteriaType } from "../../../../../../components/filtercriteria/filtercriteria.types";

import { RuleTypeId } from "../../../../../../parsers/ruleparser/ruleparser.types";
import {
  FilterCriteriaParsedType,
  FiltersColumnParsedConfigurationType,
} from "../../../../../../parsers/tablepage/tablepageparser.types";

import {
  apiToFormCriteria,
  filterCriteriaApiData,
} from "../../../../../addgovernanceviewform/addgovernanceviewform.utils";
import { MultipleRuleCheckType } from "../../addrulesetform.types";

export const addFiltersObjectInColumnRule = (
  filterValues: FilterCriteriaType,
  columnId: number,
  ruleType: RuleTypeId,
  rulesOnColumn: MultipleRuleCheckType,
  parsedFields: FiltersColumnParsedConfigurationType[]
): {
  [key: string]: MultipleRuleCheckType[RuleTypeId];
} => {
  const existingSelectedColumnRule = rulesOnColumn?.[`${ruleType}_${columnId}`];

  const parsedFilters = filterCriteriaApiData(filterValues, parsedFields);

  const ruleWithFilters = {
    ...existingSelectedColumnRule,
    col_filter: parsedFilters,
  };

  const updatedRule = {
    [`${ruleType}_${columnId}`]: ruleWithFilters,
  };

  return updatedRule;
};

export const convertApiFiltersToUIData = (
  colFilters?: FilterCriteriaType
): FilterCriteriaParsedType => {
  const isFilterExists = colFilters?.[0]?.condition?.length;

  const parseExistingFilters = isFilterExists
    ? (colFilters || [])?.flatMap((filterCriteriaRow) => {
        const apiToFormParsedData = apiToFormCriteria(
          filterCriteriaRow?.condition,
          []
        );

        return {
          condition: apiToFormParsedData,
          next_operator: filterCriteriaRow?.next_operator || "AND",
        };
      })
    : [];

  return parseExistingFilters;
};
