// <--Library Imports Start-->
import { useEffect, useRef } from "react";
// <--Library Imports End-->

export function useCustomPolling(
  callback: any,
  delay: number,
  dependencies: any[]
): void {
  const savedCallback: React.MutableRefObject<undefined | any> = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick(): any {
      savedCallback.current();
    }
    if (delay !== null) {
      tick();
      const id = setInterval(tick, delay);
      return (): any => clearInterval(id);
    }
    return undefined;
  }, [delay, ...dependencies]);
}
