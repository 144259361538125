import styled from "styled-components";

export const GatewayInstallerModalBodyStyled = styled.div`
  max-width: 660px;
  min-height: 261px;
  padding: 46px 55px 39px 55px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};

  > div {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  /* .section-header {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    font-family: OpenSansSemiBold;

    .title-container {
      display: flex;
      flex-direction: column;
      row-gap: 7px;

      .title {
        font-size: 21px;
      }

      .description {
        font-size: 14px;
      }
    }
  } */

  .ant-card {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    font-family: OpenSansSemiBold;

    .ant-card-body {
      padding: 0px;
      .ant-card-meta {
        .ant-card-meta-detail {
          .ant-card-meta-title {
            font-size: 21px;
            color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
            margin-bottom: 0px;
          }
          .ant-card-meta-description {
            font-size: 14px;
            color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
          }
        }
      }
    }

    &.success-dialog {
      & + .info {
        margin-top: 14px;
      }
      .ant-card-body {
        .ant-card-meta {
          .ant-card-meta-detail {
            .ant-card-meta-title,
            .ant-card-meta-description {
              font-size: 15px;
            }
            .ant-card-meta-description {
              font-family: OpenSans;
            }
          }
        }
      }
    }
  }

  .download-btn-container {
    button {
      display: flex;
      column-gap: 8px;
      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
      }
    }
  }

  .action-buttons-container {
    column-gap: 6px;
    margin-top: 26px;
  }

  .ant-card.success-dialog + .info + .action-buttons-container {
    margin-top: 13px;
  }
`;
