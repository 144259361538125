import { useCallback } from "react";

import AgGridTable from "../aggridtable";

import { AgGridWithoutPaginationProps } from "./aggridwithoutpagination.types";

function AgGridWithoutPagination<T>(
  props: AgGridWithoutPaginationProps<T>
): JSX.Element {
  const { tableProps, gridSortProps } = props;
  const { sortState, setSortState } = gridSortProps || {};

  const onSortChange = useCallback(
    (event: any) => {
      if (gridSortProps?.onSortChange) {
        gridSortProps?.onSortChange(event);
      } else {
        const colConfig = event?.columnApi?.columnController?.columnsForQuickFilter?.find(
          (item: any) => item?.sort
        );

        const colName =
          colConfig?.colDef?.headerName ||
          colConfig?.colDef?.colId ||
          colConfig?.colDef?.field;
        const sortDirection = colConfig?.sort;

        setSortState &&
          setSortState({
            sortColName: colConfig ? colName : sortState?.sortColName,
            sortType: colConfig ? sortDirection : null,
          });
      }
    },
    [gridSortProps, sortState]
  );

  return (
    <div>
      <AgGridTable
        {...tableProps}
        pagination={false}
        tableColumns={tableProps?.tableColumns?.map((item) => ({
          ...item,
          ...{
            sort:
              sortState?.sortColName === (item?.headerName || item?.colId)
                ? sortState?.sortType
                : undefined,
          },
        }))}
        onSortChanged={onSortChange}
      />
    </div>
  );
}

export default AgGridWithoutPagination;
