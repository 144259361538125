import { SelectFieldOption } from "../../../../components/formfields/selectfield/selectfield.types";
import LinkButton from "../../../../components/linkbutton/linkbutton";

import { FilterCriteriaParsedType } from "../../../../parsers/tablepage/tablepageparser.types";
import { getDvSumInformativeText } from "../../../../utils";

import {
  FieldBase,
  SchemaType,
  SectionField,
} from "../../dynamicform/dynamicform.types";
import { PatternSelectStyled } from "./adddataformatform.styles";

export const addDataFormatForm = ({
  isFromRuleSet,
  columnSelectOptions,
  patternSelectOptions,
  existingFilters,
  selectedOptionDesc,
  onCreateNewPattern,
  isEdit,
}: {
  isFromRuleSet?: boolean;
  // please define a type
  columnSelectOptions: SelectFieldOption[];
  patternSelectOptions: Array<{
    label: string;
    options: SelectFieldOption[];
  }>;
  existingFilters?: FilterCriteriaParsedType | undefined;
  selectedOptionDesc: string;
  onCreateNewPattern: () => void;
  isEdit: boolean;
}): SchemaType => {
  // const onOpenModal = useOpenModal();

  const {
    dat_dict_dat_qual_add_new_rle_daf_flt: {
      description: datDictDatQualAddNewRleDafFlt = "",
    } = {},
  } = getDvSumInformativeText();

  return [
    ...((!isFromRuleSet &&
      !isEdit && [
        {
          id: "basic",
          type: "section",
          fields: [
            {
              id: "rule_type",
              type: "select",
              name: "rule_type",
              label: "Rule Type",
              required: true,
              disabled: true,
            },
            {
              id: "rule_description",
              type: "text_area",
              name: "rule_description",
              label: "Rule Description",
              placeholder: "Description",
              description: "",
            },
          ],
        } as SectionField,
      ]) ||
      []),

    {
      id: "basic_input",
      type: "section",
      title: isFromRuleSet ? "" : "Basic Input",
      fields: [
        ...((!isFromRuleSet && [
          {
            id: "column",
            type: "select",
            name: "column",
            label: "Column",
            showSearch: true,
            required: true,
            placeholder: "Select",
            options: columnSelectOptions,
            shouldAddDescInLabel: true,
            tooltipPlacement: "right",
            tooltipClassName: "column-tooltip-content",
          } as FieldBase,
        ]) ||
          []),
        {
          id: "pattern",
          type: "select",
          name: "pattern",
          label: "Pattern",
          showSearch: true,
          placeholder: "Select",
          required: true,
          options: patternSelectOptions,
          // ...(!isFromRuleSet && {
          description: (
            <PatternSelectStyled>
              The column will be checked based on the selected pattern from the
              below list. If you want to add check based on a new pattern please{" "}
              <LinkButton
                className="create-new-job-btn"
                onClick={onCreateNewPattern}
              >
                create a custom pattern.
              </LinkButton>
            </PatternSelectStyled>
          ),
          // }),
          selectedOptionDes: selectedOptionDesc,
          shouldAddDescInLabel: true,
          isGroupedOpt: true,
        } as FieldBase,
        // {
        //   id: "filter_criteria",
        //   type: "filter",
        //   name: "filter_criteria",
        //   label: "Filter Condition",
        //   description: datDictDatQualAddNewRleDafFlt,
        //   existingFilters,
        // },
        ...((!isFromRuleSet && [
          {
            id: "filter_criteria",
            type: "filter",
            name: "filter_criteria",
            label: "Filter Condition",
            description: datDictDatQualAddNewRleDafFlt,
            existingFilters,
          } as FieldBase,
        ]) ||
          []),
      ],
    },
  ];
};
