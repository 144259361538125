export const helpingText = {
  firstSection: [
    {
      title: "Configure your identity provider",
      description:
        "You will need to set up a connection (or connector) for DvSum with your IDP. Many providers we work  with are as under:",
      points: ["ADFS", "Microsoft Azure", "Okta", "OneLogin"],
    },
    {
      title: "Set up SAML SSO for DvSum",
      description: "Once you've configured your identity provider (IDP) then:",
      points: [
        "Download and configure DvSum Service Provider SAML File into your IDP.",
        "Now provide your IDP SAML file for configuration on SP side.",
      ],
    },
  ],
  secondSection: [
    {
      title: "Service Provider Configurations",
      description:
        "Use this metadata file and upload it to your IDP configuration.",
    },
    {
      title: "Identity Provider Configurations",
      description: "Download a metadata file and upload it here.",
      points: [
        {
          title: "Provider Name",
          description:
            "Give a unique provider name in case you have multiple IDP's.",
        },
        {
          title: "Attributes",
          description:
            "If is not auto-populated , please find this information from your metadata that you uploaded in IPC and put it here.",
        },
      ],
    },
  ],
};
