import { getPostLoginData } from "./getpostlogindata";

export const getModuleLevelAccessInfo = (): {
  hasCatalogAccess: boolean;
  isChatEnabled: boolean;
  hasGlossaryAccess: boolean;
  isDqEnabled: boolean;
} => {
  const { user_info: userInfo = {} } = getPostLoginData();

  const hasCatalogAccess = userInfo?.catalog_access_type !== "NOA";
  const hasGlossaryAccess = userInfo?.glossary_access_type !== "NOA";
  const isChatEnabled = userInfo?.is_chat_enabled || false;

  const isDqEnabled = userInfo?.is_dq_enabled || false;

  return { hasCatalogAccess, isChatEnabled, hasGlossaryAccess, isDqEnabled };
};
