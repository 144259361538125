import styled from "styled-components";

export const MultiSelectionCheckboxesPopupStyled = styled.div<{
  isVisible: boolean;
}>`
  svg {
    transform: ${({ isVisible }): string =>
      isVisible ? `scale(1, -1)` : `scale(1, 1)`};
    transition: transform 0.4s ease-in-out;

    path {
      stroke: ${({ theme, isVisible }): string =>
        isVisible ? theme?.bgColors?.GREY_210 : theme?.bgColors?.GREY_210};
    }
  }

  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  width: max-content;
  min-width: 89px;
  height: 24px;
  border-radius: 37px;

  .multi-selection-checkbox-title {
    display: flex;
    align-items: center;
    gap: 4px;

    .seleted-options-labels {
      font-family: OpenSansSemiBold;
      color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
    }
  }

  border: 1px solid
    ${({ theme, isVisible }): string =>
      isVisible
        ? theme?.borderColors?.YELLOW_RD
        : theme?.borderColors?.GREY_56};

  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};

  color: ${({ theme, isVisible }): string =>
    isVisible ? theme?.textColors?.GREY_24 : theme?.textColors?.GREY_24};
`;
