import { Control, FieldValues, UseFormSetValue } from "react-hook-form";
import { SelectField } from "../formfields";
import { range } from "../../utils";

export const getAfterFieldForFrequencyType = (
  frequencyType: string,
  control: Control<FieldValues, object>,
  setValue: UseFormSetValue<FieldValues>
): JSX.Element => {
  switch (frequencyType) {
    case "DLY":
      return (
        <>
          <label>after every</label>
          <SelectField
            control={control}
            setValue={setValue}
            isAllowClear={false}
            name="interval"
            width="70px"
            height="34px"
            options={range(1, 30)?.map((key) => ({
              label: `${key}`,
              value: `${key}`,
            }))}
          />
          <label>days</label>
        </>
      );
    case "WLY":
      return (
        <>
          <label>after every</label>
          <SelectField
            control={control}
            setValue={setValue}
            isAllowClear={false}
            name="interval"
            width="70px"
            height="34px"
            options={range(1, 7)?.map((key) => ({
              label: `${key}`,
              value: `${key}`,
            }))}
          />
          <label>weeks</label>
        </>
      );
    case "MLY":
      return (
        <>
          <label>after every</label>
          <SelectField
            control={control}
            setValue={setValue}
            isAllowClear={false}
            name="interval"
            width="70px"
            height="34px"
            options={range(1, 12)?.map((key) => ({
              label: `${key}`,
              value: `${key}`,
            }))}
          />
          <label>months</label>
        </>
      );
    default:
      return <div />;
  }
};
