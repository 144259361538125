import { useMemo } from "react";
import { Spin } from "antd";

import {
  GatewayLoadinBannerWithChildren,
  GatewayStatusCheckingWrapperBannerWithChildren,
  GatewayStatusCheckingWrapperStyled,
  GatewayStatusCheckingWrapperWithChildren,
} from "./gatewaystatuscheckingwrapper.styles";
import { GatewayStatusCheckingWrapperTypes } from "./gatewaystatuscheckingwrapper.types";

import Flex from "../../../../../../components/flex";

import LinkButton from "../../../../../../components/linkbutton/linkbutton";

import { retryIcon } from "../../../../../../svgs";

import ContactSupportButton from "../../../../../../components/contactsupportbutton/contactsupportbutton";
import { useGatewayConRetry } from "../../../../../../customhooks/usegatewayretry";
import { useGetCurrentGateway } from "../../../../../../customhooks";

const retrySvg = retryIcon();

const LoadingContent = ({ height = "" }: { height: string }): JSX.Element => (
  <GatewayStatusCheckingWrapperStyled
    height={height}
    className="gateway-status-checking-wrapper"
  >
    <Flex direction="column" rowGap={15} customClass="flex-wrapper">
      <div className="loading-text">
        Checking Data Source connection through DvSum Gateway...
      </div>
      <Spin size="small" />
    </Flex>
  </GatewayStatusCheckingWrapperStyled>
);

const FailureContent = ({
  host = "",
  isOnpremiseGateway,
  port = "",
  onRetryGatewayCon,
  height = "",
  message,
}: {
  host: string;
  port: string;
  onRetryGatewayCon: () => void;
  isOnpremiseGateway: boolean;
  height?: string;
  message: string;
}): JSX.Element => (
  <GatewayStatusCheckingWrapperStyled
    height={height}
    className="gateway-status-checking-wrapper"
  >
    <div className="failure-text">
      <div>
        {message}.{" "}
        {isOnpremiseGateway ? (
          <>
            Check your connection
            <LinkButton
              marginLeft="4px"
              marginRight="4px"
              href={`https://${host}:${port}/`}
              target="_blank"
            >
              here
            </LinkButton>
            or contact your Gateway administrator and confirm Gateway is up and
            running.
          </>
        ) : (
          <Flex justifyContent="center" customClass="contact-wrapper">
            Please Contact
            <ContactSupportButton />
          </Flex>
        )}
      </div>

      <Flex justifyContent="center" columnGap={3}>
        <LinkButton onClick={onRetryGatewayCon} className="retry-btn">
          {retrySvg} Retry
        </LinkButton>
      </Flex>
    </div>
  </GatewayStatusCheckingWrapperStyled>
);
const GatewayStatusCheckingWrapper = (
  props: GatewayStatusCheckingWrapperTypes
): JSX.Element => {
  const {
    children,
    height = "",
    type,
    gatewayId = "",
    message = "Sorry cannot connect to the Gateway and unable to load data",
  } = props;

  const {
    isFetchingGatewaysList,
    currentGateway,
    isGatewayRunning,
  } = useGetCurrentGateway(gatewayId);

  const { onRetryGatewayCon } = useGatewayConRetry(gatewayId);

  const {
    host = "",
    port = "",
    isPingcheckInProgress = false,
    isOnpremiseGateway = false,
    isCloudGateway = false,
  } = currentGateway || {};

  const isLoading = isCloudGateway
    ? false
    : isFetchingGatewaysList || isPingcheckInProgress;

  const content = (): JSX.Element => {
    switch (type) {
      case "loading":
        return isLoading ? <LoadingContent height={height} /> : children;
      case "error":
        return currentGateway?.id && !isGatewayRunning ? (
          <FailureContent
            host={host}
            port={port}
            isOnpremiseGateway={isOnpremiseGateway}
            onRetryGatewayCon={onRetryGatewayCon}
            height={height}
            message={message}
          />
        ) : (
          children
        );
      case "error_with_children":
        return isLoading ? (
          <>
            {children}
            <GatewayStatusCheckingWrapperWithChildren className="gateway-check-with-child">
              <LoadingContent height={height} />
            </GatewayStatusCheckingWrapperWithChildren>
          </>
        ) : !isGatewayRunning && !isLoading ? (
          <>
            {children}
            <GatewayStatusCheckingWrapperWithChildren className="gateway-check-with-child">
              <FailureContent
                host={host}
                port={port}
                isOnpremiseGateway={isOnpremiseGateway}
                onRetryGatewayCon={onRetryGatewayCon}
                message={message}
              />
            </GatewayStatusCheckingWrapperWithChildren>
          </>
        ) : (
          children
        );

      case "error_banner":
        return isLoading ? (
          <GatewayLoadinBannerWithChildren>
            <LoadingContent height={height} />
            {children}
            <div />
          </GatewayLoadinBannerWithChildren>
        ) : !isGatewayRunning && !isLoading ? (
          <>
            {children}
            <GatewayStatusCheckingWrapperBannerWithChildren className="gateway-banner-check-with-child">
              <FailureContent
                host={host}
                port={port}
                isOnpremiseGateway={isOnpremiseGateway}
                onRetryGatewayCon={onRetryGatewayCon}
                message={message}
              />
            </GatewayStatusCheckingWrapperBannerWithChildren>
          </>
        ) : (
          children
        );
      default:
        return isLoading ? (
          <LoadingContent height={height} />
        ) : isGatewayRunning ? (
          children
        ) : (
          <FailureContent
            host={host}
            port={port}
            isOnpremiseGateway={isOnpremiseGateway}
            onRetryGatewayCon={onRetryGatewayCon}
            height={height}
            message={message}
          />
        );
    }
  };

  return isCloudGateway ? children : content();
};

export default GatewayStatusCheckingWrapper;
