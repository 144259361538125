import styled from "styled-components";

export const BackToTopButtonStyled = styled.div`
  .back-to-top {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    background: ${({ theme }): string => theme.bgColors.BLUE_116};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-back-top {
    right: 99px;
  }
`;
