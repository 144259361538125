import { API_CONFIG } from "../../constants/apiconfig";

import {
  getParsedDomainsList,
  getParsedSourcesList,
} from "../../parsers/treetransferparser/treetransferparser";

import {
  ReturnTypeOfDomainsList,
  ReturnTypeOfSourcesList,
} from "../../parsers/treetransferparser/treetransferparser.types";

import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const { get_nodes_list: getNodesList } = API_CONFIG;

export function useGetSourcesList(
  enabled: boolean,
  moduleType: string
): CustomReactQueryHookReturnType<ReturnTypeOfSourcesList> {
  return useGetAll({
    apiConfig: getNodesList,
    parser: getParsedSourcesList,
    params: ["SRC", "true", moduleType],
    options: {
      enabled,
    },
  });
}

export function useGetDomainsList(
  enabled: boolean
): CustomReactQueryHookReturnType<ReturnTypeOfDomainsList> {
  return useGetAll({
    apiConfig: getNodesList,
    parser: getParsedDomainsList,
    params: ["DMN", "true", ""],
    options: { enabled },
  });
}
