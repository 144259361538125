import { useMemo } from "react";

import { Button } from "../../../../components";
import LinkButton from "../../../../components/linkbutton";
import { deleteIcon1, editPencilIcon, tagsIcon } from "../../../../svgs";

import { PageHeaderProps } from "./pageheader.types";

import { getTimeDifference } from "../../../../utils";

import { PageHeaderStyled } from "./pageheader.styles";
import Statictextrendrer from "../../../../components/statictextrendrer";
import { ELEMENT_IDS } from "../../../../constants";
import { useGetAppState } from "../../../../customhooks";
import { hasAdministrationEditAccess } from "../../../../utils/getadminfinegrainaccesslist";

const {
  dataorg_tagset_dtl_pg_edt_btn: DATAORG_TAGSET_DTL_PG_EDT_BTN,
  dataorg_tagset_dtl_pg_save_btn: DATAORG_TAGSET_DTL_PG_SAVE_BTN,
} = ELEMENT_IDS;

const unsavedChangesMessage =
  "You have unsaved changes. Please save or cancel those changes.";

const noChangesMadeMessage = "You haven't made any changes";

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const {
    title,
    created_by: createdBy,
    created_on: createdOn = "",
    updated_by: updatedBy,
    updated_on: updatedOn = "",
    actionsState,
    isLoading,
    handleEdit,
    handleDone,
    handleDelete,
    handleCancel,
    isEditable,
    isDeleteable = true,
    hasUserPermissionToEdit = true,
  } = props;

  const { isOnboardingMode } = useGetAppState();

  const isEditing: boolean = useMemo(() => {
    return actionsState?.information?.isEdit || actionsState?.tags?.isEdit;
  }, [actionsState]);

  const isChanged: boolean = useMemo(() => {
    return (
      actionsState?.information?.isChanged || actionsState?.tags?.isChanged
    );
  }, [actionsState]);

  return (
    <PageHeaderStyled isEditMode={actionsState?.isEdit || false}>
      <div className="left">
        <div className="page-name">
          {tagsIcon("18", "18")} {title}{" "}
          {!isEditable && (
            <Statictextrendrer text="(This tagset cannot be modified)" />
          )}
        </div>

        <div className="created-by">
          Created by {createdBy} {getTimeDifference(createdOn)}
        </div>

        <div className="updated-by">
          Updated by {updatedBy} {getTimeDifference(updatedOn)}
        </div>
      </div>
      {isEditable && hasAdministrationEditAccess("tags") && (
        <div className="right">
          {isDeleteable && !actionsState?.isEdit && (
            <LinkButton
              icon={deleteIcon1("11", "14")}
              className="delete-btn"
              onClick={handleDelete}
            >
              Delete
            </LinkButton>
          )}

          {actionsState?.isEdit && (
            <Button id="cancel" onClick={handleCancel} disabled={isLoading}>
              Cancel
            </Button>
          )}

          {!actionsState?.isEdit ? (
            <Button
              id="primary"
              className="edit-btn"
              onClick={handleEdit}
              elementId={DATAORG_TAGSET_DTL_PG_EDT_BTN}
              disabled={!hasUserPermissionToEdit}
              tooltipProps={{
                title: !hasUserPermissionToEdit
                  ? "Only Data Security Admins are allowed to edit this page"
                  : "",
                placement: "topRight",
              }}
            >
              {editPencilIcon("11", "11")} Edit
            </Button>
          ) : (
            <Button
              id="primary"
              onClick={handleDone}
              disabled={
                isOnboardingMode ? false : isEditing || !isChanged || isLoading
              }
              tooltipProps={{
                title: isEditing
                  ? unsavedChangesMessage
                  : !isChanged
                  ? noChangesMadeMessage
                  : undefined,
                placement: "bottomRight",
              }}
              elementId={DATAORG_TAGSET_DTL_PG_SAVE_BTN}
            >
              Done
            </Button>
          )}
        </div>
      )}
    </PageHeaderStyled>
  );
};

export default PageHeader;
