import { InlineLoaderStyled } from "./inlineloader.styles";

const InlineLoader = (): JSX.Element => {
  return (
    <InlineLoaderStyled
      data-testid="inline-loader-main-wrapper"
      className="inline-loader"
    >
      <img
        src="/assets/giffs/inlineloader.gif"
        data-testid="inline-loader-gif"
      />
    </InlineLoaderStyled>
  );
};

export default InlineLoader;
