import styled from "styled-components";

export const GatewayRetryButtonStyled = styled.div`
  &.gateway-retry-btn {
    .ant-btn {
      display: none;

      svg {
        width: 20px;
        height: 20px;
        margin-left: 0;

        path {
          fill: revert-layer;
        }
      }
    }
  }
`;
