import styled from "styled-components";

export const DeleteRecordsStyled = styled.div`
  font-size: 14px;
  color: ${({ theme }): string => theme.textColors.GREY_40};

  .message-content {
    padding: 40px 30px;
  }

  .note {
    padding-left: 30px;
    padding-bottom: 40px;

    div {
      gap: 6px;
    }
  }

  .modal-actions-sec {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    background-color: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
    border-top: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
    padding: 8px 18px 8px 18px;
  }
`;
