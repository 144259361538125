import { useForm } from "react-hook-form";
import { setCharAt } from "../../../../../../../../utils";
import {
  DynamicAlertDataCheck,
  DynamicValueTemplateProps,
} from "../connectionsettingstabform.types";

export function getTemplateMessageByConfig(
  templateConfig?: string | DynamicValueTemplateProps,
  responseCheckConfig?: DynamicAlertDataCheck[],
  responseCheckData?: string[],
  form?: ReturnType<typeof useForm>
): any {
  if (!templateConfig) {
    return undefined;
  }

  if (typeof templateConfig === "string") {
    return templateConfig;
  }

  if (templateConfig?.value) {
    return templateConfig?.value;
  }

  if (templateConfig?.valueTemplate) {
    let value = "";
    const placeHolder =
      templateConfig?.valueTemplatePlaceholder || "#placeholder";

    const dataForValue = templateConfig?.fields?.length
      ? form?.getValues(templateConfig?.fields)
      : responseCheckConfig && responseCheckData?.length
      ? responseCheckData
      : undefined;

    if (dataForValue) {
      value = dataForValue?.join(", ") || "";
      if (dataForValue?.length > 1) {
        value = setCharAt(value, value.lastIndexOf(","), " and");
      }
    }
    // if (templateConfig?.fields) {
    //   form?.getValues(templateConfig?.fields);
    // } else if (responseCheckConfig && responseCheckData?.length) {
    //   value = responseCheckData?.join(", ") || "";
    //   value = setCharAt(value, value.lastIndexOf(","), " and");
    // }

    return (
      (responseCheckData?.length &&
        templateConfig?.valueTemplate?.replace(placeHolder, value)) ||
      undefined
    );
  }

  return undefined;
}

// function getAlertDescriptionByConfig(
//   alertConfig?: DynamicOnCompletedActionConfigProps["alertConfig"],
//   responseCheckData?: string[]
// ): any {
//   if (!alertConfig || !alertConfig?.description) {
//     return undefined;
//   }

//   const { description: descriptionConfig } = alertConfig;

//   if (descriptionConfig?.value) {
//     return descriptionConfig?.value;
//   }

//   if (descriptionConfig?.valueTemplate) {
//     let value = "";
//     const placeHolder =
//       descriptionConfig?.valueTemplatePlaceholder || "#placeholder";

//     if (alertConfig?.responseCheck && responseCheckData?.length) {
//       value = responseCheckData?.join(", ") || "";
//       value = setCharAt(value, value.lastIndexOf(","), " and");
//     }

//     return (
//       (responseCheckData?.length &&
//         descriptionConfig?.valueTemplate?.replace(placeHolder, value)) ||
//       undefined
//     );
//   }

//   return undefined;
// }
