import { getPostLoginData } from "./getpostlogindata";
import { getChatPrefrencesData } from "./getchatprefrencesdata";
import { isUserPrefferedAmountMetricColumn } from "./isuserprefferedamountmetriccolumn";

export const isDvsumPrefferedPercentageMetricColumn = (
  name: string
): boolean => {
  const { user_info: userInfo } = getPostLoginData();

  const { metric_format: metricFormat } = getChatPrefrencesData();

  const {
    cols_to_ignored_dvsum_defaut_logic: colsToIgnoredDvsumDefautLogic = [],
  } = metricFormat || {};

  const { percentage_identifier: dvSumProvidedPercentageIdentifier = [] } =
    userInfo?.non_metric_keywords || {};

  const regexForPercentage = new RegExp(
    `(${dvSumProvidedPercentageIdentifier?.join("|")})`,
    "i"
  );

  try {
    const nameWithoutUnderscore = name
      ?.toLocaleLowerCase()
      ?.replaceAll(/_/g, " ");

    const listWithoutUnderScore = colsToIgnoredDvsumDefautLogic?.map((item) =>
      item?.toLocaleLowerCase()?.replaceAll(/_/g, " ")
    );

    return (
      regexForPercentage?.test(nameWithoutUnderscore) &&
      !!dvSumProvidedPercentageIdentifier?.length &&
      !listWithoutUnderScore?.includes(nameWithoutUnderscore) &&
      !isUserPrefferedAmountMetricColumn(name)
    );
  } catch {
    return false;
  }
};
