// <--Library Imports Start-->
import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
// <--Library Imports End-->

// <--Components Start-->
import DvSumFallBackSpinner from "../../../../../../../../components/dvsumfallbackspinner";
import LinkButton from "../../../../../../../../components/linkbutton";

import StateHandler from "../../../../../../../../components/statehandler";
import { SSAAddQueryModalProps } from "../../../../../../../../components/modalwrapper/modals/ssaaddquerymodal/ssaaddquerymodal.types";
// <--Components End-->

// <--Custom Hooks Start-->
import {
  useOpenDrawer,
  useOpenModal,
} from "../../../../../../../../customhooks";
// <--Custom Hooks End-->

// <--Parsers Start-->
import {
  ColumnProfilingInfo,
  ColumnResponse,
  getTableColumsDetails,
} from "../../../../../../../../parsers";
// <--Parsers End-->

// <--Service Start-->
import { useGetQueryTableFilterColumns } from "../../../../../../../../api/analysisservice";
// <--Service End-->

// <--SVGS Start-->
import {
  eyeIcon,
  minusIcon,
  plusIcon,
  tickIconGreen,
} from "../../../../../../../../svgs";
// <--SVGS End-->

// <--Utils Start-->
import { getObjectKeys, getObjectLength } from "../../../../../../../../utils";
import { filterQueryFiltersWhereFilterModelsExists } from "../../../../../../analisisdetailpage.utils";
// <--Utils End-->

// <--Types Start-->
import {
  QueryBlockGridFilter,
  QueryBoxFiltersType,
} from "../../../../../../analysisdetailpage.types";

import { StringKeyValuePairType } from "../../../../../../../../app.types";
import { TableItemProps } from "./tableitem.types";
import { TableAdded } from "../../analysisdetailpageexplorersec.styles";
import { TableViewDrawerType } from "../../../../../../../../drawerviews/tableviewdrawer/tableviewdrawer.types";
// <--Types End-->

const TableItem = (props: TableItemProps): JSX.Element => {
  const onOpenModal = useOpenModal();
  const openDrawer = useOpenDrawer();

  const [showSuccessSpan, setShowSuccessSpan] = useState(false);

  const {
    id = 0,
    title = "",
    isPinned = false,
    queries = [],
    onAddQuery,
    updatePinnedTablesList,
    isCmgFrmPinned = false,
    name = "",
  } = props;

  //displaying purpose removing schema name in case of table browser
  const updatedTitle = title?.split(".")?.slice(-1)?.[0] || title;

  const onGetQueryTableFilterColumnsSuccess = useCallback(
    (res: AxiosResponse<ColumnResponse[]>) => {
      const allQueryFilters: Array<QueryBoxFiltersType> = queries?.map(
        (query) => query?.filters?.applied_filters
      );

      const parsedColumns: Array<ColumnProfilingInfo> =
        getTableColumsDetails(res)?.sample_data_header_rendrer || [];

      const parsedColNamesList: Array<string> =
        parsedColumns?.map((resCol) => resCol?.column_name || "") || [];

      const parsedColNamesWithDisplayName: StringKeyValuePairType =
        parsedColumns?.reduce(
          (prev: StringKeyValuePairType, next: ColumnProfilingInfo) => ({
            ...prev,
            [next?.column_name || ""]: next?.column_display_name || "",
          }),
          {}
        ) || {};

      const selectedFilters: Array<QueryBoxFiltersType> =
        allQueryFilters
          ?.map((queryFilter: QueryBoxFiltersType) => {
            const queryFilterKeysList: Array<string> =
              getObjectKeys(queryFilter || {}) || [];

            const lowerCaseQueryFilterKeysList: Array<string> =
              queryFilterKeysList?.map(
                (key: string) => key?.toLocaleLowerCase() || ""
              ) || [];

            const queryFilterWithLowercaseColName: QueryBoxFiltersType =
              queryFilterKeysList?.reduce(
                (prev: QueryBoxFiltersType, next: string) => ({
                  ...prev,
                  [next?.toLocaleLowerCase() || ""]: queryFilter?.[next] || {},
                }),
                {}
              ) || {};

            const selectedParsedColNames: Array<string> =
              parsedColNamesList?.filter((parsedColName: string) =>
                lowerCaseQueryFilterKeysList?.includes(
                  parsedColName?.toLocaleLowerCase() || ""
                )
              ) || [];

            const selectedQueryFilters: QueryBoxFiltersType =
              selectedParsedColNames?.reduce(
                (prev: QueryBoxFiltersType, next: string = "") => {
                  const updatedFilter: QueryBlockGridFilter = {
                    ...(queryFilterWithLowercaseColName?.[
                      next?.toLocaleLowerCase()
                    ] || {}),
                    colm_name: next,
                    col_display_name: parsedColNamesWithDisplayName[next] || "",
                  };

                  return {
                    ...prev,
                    [next]: updatedFilter || {},
                  };
                },
                {}
              ) || {};

            return selectedQueryFilters;
          })
          ?.filter((item) =>
            getObjectLength(filterQueryFiltersWhereFilterModelsExists(item))
          ) || [];

      if (getObjectLength(selectedFilters)) {
        onOpenModal({
          modalId: "ssa_add_query_modal",
          visible: true,
          modalTitle: "Apply Filters",
          modalProps: {
            table: title,
            columns: parsedColumns,
            filters: selectedFilters?.reverse(),
            onAddQueryClick: (selectedFilters: QueryBoxFiltersType) => {
              onAddQuery?.(`${id}`, title, selectedFilters, name);
              if (!isPinned) {
                updatePinnedTablesList({
                  id,
                  title,
                  name,
                });
              }
            },
          } as SSAAddQueryModalProps,
        });
      } else {
        onAddQuery?.(`${id}`, title, {}, name);
        if (!isPinned) {
          updatePinnedTablesList({
            id,
            title,
            name,
          });
        }
      }
    },
    [queries, id, title, onAddQuery, updatePinnedTablesList, isPinned, name]
  );

  const { error, isFetching } = useGetQueryTableFilterColumns(
    `${id}`,
    false,
    onGetQueryTableFilterColumnsSuccess
  );

  const changeTableView = useCallback((tableView) => {
    // setShowExpandedTableview((st) => !st);
    openDrawer({
      drawerId: "table_view",
      visible: true,
      isMaximized: tableView,
      drawerProps: {
        tableId: `${id}`,
        tableName: title,
        changeTableView,
      } as TableViewDrawerType,
    });
  }, []);

  const handleViewTable = useCallback(() => {
    openDrawer({
      drawerId: "table_view",
      visible: true,
      isMaximized: false,
      drawerProps: {
        tableId: `${id}`,
        tableName: title,
        changeTableView,
      } as TableViewDrawerType,
    });
  }, []);

  const handlePinTable = useCallback(
    (isAdded?: boolean) => {
      if (isAdded) {
        setShowSuccessSpan(true);
        setTimeout(() => {
          setShowSuccessSpan(false);
        }, 2000);
      }

      updatePinnedTablesList(
        {
          id,
          title,
          name,
        },
        isAdded
      );
    },
    [id, title, name, updatePinnedTablesList]
  );

  const onOpenRef = useCallback(() => {
    openDrawer({ drawerId: "table_ref", visible: true, drawerProps: { id } });
  }, [id]);

  return (
    <>
      <StateHandler
        isFetching={isFetching}
        error={error}
        blankSlate={<DvSumFallBackSpinner />}
      >
        <div />
      </StateHandler>
      <span
        className={`table-title-wrapper ${
          isCmgFrmPinned ? "pinned-table-title-wrapper" : ""
        }`}
        data-testid="table-item-title-wrapper"
      >
        <span
          className="title"
          title={title}
          role="button"
          onClick={onOpenRef}
          data-testid="table-item-title-text"
        >
          {updatedTitle}
        </span>

        <div className="action-btns" data-testid="table-item-action-btns-sec">
          {showSuccessSpan ? (
            <TableAdded>
              <span className="icon">{tickIconGreen()}</span>
              <span className="text">Table Added for Chat</span>
            </TableAdded>
          ) : (
            <>
              <LinkButton
                className={isPinned ? "unpin-icon" : "pin-icon"}
                onClick={(): void => handlePinTable(!isPinned)}
                tooltipProps={{
                  title: isPinned ? "Remove Table" : "Add Table For Chat",
                  placement: "top",
                  overlayClassName: "analysis-pinned-tables-dvsum-tooltip",
                }}
                data-testid="table-item-pin-unpin-btn"
              >
                {isPinned ? minusIcon("25", "6") : plusIcon("11.2", "11.2")}
              </LinkButton>

              <LinkButton
                className="view-icon"
                onClick={handleViewTable}
                tooltipProps={{ title: "View this table", placement: "top" }}
                data-testid="table-item-add-btn"
              >
                {eyeIcon("16", "16")}
              </LinkButton>
            </>
          )}
        </div>
      </span>
    </>
  );
};

export default TableItem;
