import styled from "styled-components";

export const AnalysisDetailPageTopicSectionStyled = styled.div<{
  isVisible?: boolean;
}>`
  display: flex;
  flex-direction: column;
  row-gap: 11.2px;
  max-height: 130px;
  overflow: auto;

  height: ${({ isVisible }): string => (isVisible ? "130px" : "0")};
  border: ${({ theme, isVisible }): string =>
    isVisible
      ? `solid 1px${theme.borderColors?.GREY_56}`
      : `solid 1px${theme.borderColors?.WHITE_COLOR}`};
  transition: all 0.5s linear 0s;

  .subtitle {
    font-size: 12px;
    color: ${({ theme }): string => theme.textColors?.GREY_210};
    padding: 15px 19.2px 0 19.2px;
  }

  .topics-list-container {
    display: flex;
    column-gap: 9.2px;
    flex-wrap: wrap;
    gap: 12px;
    padding: 0 19.2px 15px 19.2px;
  }

  .topic-btn {
    font-family: "OpenSansSemiBold";
    color: ${({ theme }): string => theme.textColors?.GREY_24};
    background: ${({ theme }): string => theme.bgColors?.WHITE_COLOR};
    border: solid 1.5px ${({ theme }): string => theme.borderColors?.GREY_56};
    border-radius: 4px;
    min-width: 108px;
  }

  .selected-topic-btn {
    color: ${({ theme }): string => theme.textColors?.WHITE_COLOR};
    background: ${({ theme }): string => theme.bgColors?.BLUE_116};
  }
`;
