import { NodeResponseTagsetConfig } from "../parsers/parser.types";
import { getObjectKeys } from "./getobjectkeys";

export function getTagsetNameFromTagsetConfig(
  tagsetConfig: NodeResponseTagsetConfig
): string[] {
  return getObjectKeys(tagsetConfig)
    ?.map((item) => tagsetConfig?.[item])
    ?.map((item) => item?.tagset_name);
}
