import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const TransferContainerStyled = styled.div<{
  enableLevelOfAccess: boolean;
}>`
  &.destination-transfer-container {
    .transfer-box-container {
      .ant-tree {
        &.ant-tree-block-node {
          height: 334px;
        }
      }
    }
  }

  .transfer-box-container {
    border-radius: 8px;
    border: solid 1px #c9c9c9;
    padding: 9.6px;
    padding-bottom: 0;
    height: 408px;
    width: 369.6px;
    margin-top: 6.4px;

    .search-container {
      margin-bottom: 12.8px;

      .ant-input-prefix {
        margin-right: 6.4px;

        svg {
          path {
            fill: #c9c9c9;
          }
        }
      }

      .ant-input {
        font-size: 13px;
      }
    }

    .level-of-access-container {
      .label {
        font-size: 12px;
        font-family: OpenSansSemiBold;
      }
    }

    .ant-tree {
      &.ant-tree-block-node {
        height: ${({ enableLevelOfAccess }): string =>
          enableLevelOfAccess ? "274px" : "334px"};
        overflow: auto;
      }

      .ant-tree-list {
        .ant-tree-treenode-switcher-open {
          svg {
            path {
              fill: #428bca;
            }
          }
        }
      }

      .ant-tree-treenode {
        &:has(.parent-title-container) {
          &:not(.ant-tree-treenode-switcher-open, .ant-tree-treenode-switcher-close) {
            margin-left: 24px;
          }
        }

        .ant-tree-title {
          ${ellipsis}
          max-width: 80%;

          .parent-title-container {
            .parent-title {
              ${ellipsis}
              max-width: 90%;
            }

            .children-count {
              color: ${({ theme }): string => theme?.textColors?.GREY_210};
            }
          }

          .level-of-access {
            color: ${({ theme }): string => theme?.textColors?.GREY_210};
          }
        }

        .ant-tree-indent-unit-start,
        .ant-tree-indent-unit-end,
        .ant-tree-indent-unit {
          ::before {
            border-right: 1px solid
              ${({ theme }): string => theme?.borderColors?.BLUE_116};
            content: "";
            position: absolute;
            top: 0;
            right: 12px;
            bottom: -4px;
            display: block;
          }
        }

        .ant-tree-switcher-noop {
          display: none;
        }

        .ant-tree-node-content-wrapper-normal,
        .ant-tree-node-content-wrapper,
        .ant-tree-icon__customize {
          display: flex;
          align-items: center;
        }

        .ant-tree-node-content-wrapper {
          max-width: 85%;

          .ant-tree-iconEle {
            img {
              max-width: 20px;
              max-height: 20px;
            }
          }
        }

        .ant-tree-node-selected {
          background: ${({ theme }): string => theme?.borderColors?.BLUE_116};

          .ant-tree-icon__customize {
            .core-nodes-circle-icon {
              background: #fff;

              .letter-icon-mapping {
                color: ${({ theme }): string => theme?.borderColors?.BLUE_116};
              }
            }
          }

          .ant-tree-title {
            color: #fff;
            .parent-title-container {
              .children-count {
                color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
              }
            }

            .level-of-access {
              color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
            }
          }
        }
      }
    }
  }
`;

export const TreeTransferStyled = styled.div`
  display: flex;
  align-items: center;

  .transfer-btns-container {
    margin: 0 26px;
    display: flex;
    flex-direction: column;
    row-gap: 10.4px;

    .ant-btn-link {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #cbcbcb;
      border-radius: 4px;
      height: 28px;
      width: 28px;

      svg {
        height: 11.68px;
        width: 5.84px;

        path {
          stroke: #4f4f4f;
        }
      }
    }
  }
`;
