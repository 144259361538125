import { API_CONFIG } from "../../constants/apiconfig";
import { getReferenceTablesList } from "../../parsers/erddiagramparser/erddiagramparser";
import { ReferenceTablesListReturnType } from "../../parsers/erddiagramparser/erddiagramparser.types";
import { ErdEdgeInfoType } from "../../parsers/tablepage/tablepageparser.types";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

export function useGetReferneceTablesList(
  sourceId: string,
  enabled: boolean
): CustomReactQueryHookReturnType<ReferenceTablesListReturnType> {
  return useGetAll({
    apiConfig: API_CONFIG?.get_reference_table,
    params: [sourceId],
    parser: getReferenceTablesList,
    options: { enabled },
  });
}

export function useGetErdEdgeInfo(
  tableId: string,
  edgeId: string,
  enabled: boolean
): CustomReactQueryHookReturnType<ErdEdgeInfoType> {
  return useGetAll({
    apiConfig: API_CONFIG?.get_erd_edge_info,
    params: [tableId, edgeId],
    options: { enabled },
  });
}
