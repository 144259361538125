import { AxiosResponse } from "axios";

import {
  JobDocumentParsedType,
  JobDocumentResponse,
  JobScheduleDocumentParsedType,
  JobExecutionHistoryDataParsedType,
  JobExecutionHistoryDataResponse,
  JobRefDocumentParsedType,
} from "./jobparser.types";
import { jsonParse, utcTOLocalTimeZone } from "../../utils";

import { calculateTimeDifference, getJobScheduleInfo } from "./jobparser.utils";
import {
  MONTH_DAY_YEAR_HOUR_MINUTE,
  MONTH_DAY_YEAR_HOUR_MINUTE_SECOND,
} from "../../constants";

export function getParsedJobRefDocument({
  data,
}: AxiosResponse<JobDocumentResponse>): JobRefDocumentParsedType {
  const nodes = data?.rules || data?.tables;

  const attributes = data?.tags || [];

  const attrNames = attributes?.map((item) => item?.tag_name);

  const internalUsersRecipients = data?.internal_recipients?.users || [];
  const internalGroupsRecipients = data?.internal_recipients?.user_groups || [];

  const internalRecipientsData = [
    ...internalUsersRecipients,
    ...internalGroupsRecipients,
  ];

  return {
    jobName: data?.node_name || "",
    jobDefId: data?.node_id,
    jobCategory: data?.job_category_id || "RLS",
    jobCreatedOnNodes:
      nodes?.map((item) => {
        return {
          name: item?.node_title,
          id: item?.node_id,
        };
      }) || [],
    jobCreatedOnTags: attrNames || [],

    description: data?.node_desc || "",
    lastExecutionOn: data?.last_job_exec_on || "",
    nextExecutionOn: data?.next_job_exec_on || "",
    internalRecipients:
      internalRecipientsData?.map((user) => {
        return {
          name: user?.node_name,
          id: user?.node_id,
          type: user?.node_type,
        };
      }) || [],
    externalRecipients: data?.external_recipients || [],
    jobScheduleInfo: getJobScheduleInfo(data?.job_def_config?.value),
  };
}

export function getParsedJobDocument({
  data,
}: AxiosResponse<JobDocumentResponse>): JobDocumentParsedType {
  const nodes = data?.rules || data?.tables;

  const attributes = data?.tags || [];

  const attrNames = attributes?.map((item) => item?.tag_name);

  const jobCreationInfo = data?.audit_trail?.find(
    (item) => item?.activity === "Created"
  );

  const jobEditInfo = data?.audit_trail?.find(
    (item) => item?.activity === "Updated"
  );

  const internalUsersRecipients = data?.internal_recipients?.users || [];
  const internalGroupsRecipients = data?.internal_recipients?.user_groups || [];
  const externalRecipients =
    data?.job_def_config?.value?.email_recipients?.external || [];

  const internalRecipientsData = [
    ...internalUsersRecipients,
    ...internalGroupsRecipients,
  ];

  return {
    jobMetaDataInfo: {
      jobId: `${data?.node_id}` || "",
      jobName: data?.node_name || "",
      jobCategory: data?.job_category_id || "RLS",
      //REFACTOR THISSS

      jobCreatedOnNodes:
        nodes?.map((item) => {
          return {
            name: item?.node_title,
            id: item?.node_id,
            parent_name: item?.parent_name || "",
          };
        }) || [],

      jobCreatedOnTags:
        data?.tags?.map((item) => ({
          name: item?.tag_name,
          id: item?.tag_id,
        })) || [],

      description: data?.node_desc || "",
      jobDesc: data?.job_def_config?.value?.job_desc || "",
      summary: "Weekly on all days at 8:00 PM",
      lastExecutionOn: data?.last_job_exec_on || "",
      nextExecutionOn: data?.next_job_exec_on || "",
      selectionType: data?.job_def_config?.value?.is_job_via_tag
        ? "based_on_attributes"
        : "select_individually",
      createdBy: jobCreationInfo?.user_name,
      createdOn: jobCreationInfo?.activity_on,
      editedBy: jobEditInfo?.user_name,
      editedOn: jobEditInfo?.activity_on,
      isJobActive: data?.is_active || false,
      isJobEditable: data?.is_editable || false,
      jobTypeId: data?.job_type_id || "SCH",
    },

    jobStats: {
      noOfRuns: data?.execution_cnt,
      avgRunTime: data?.avg_exec_time_in_mins,
    },

    jobNotificationInfo: {
      sendEmailOnAlerts: data?.job_def_config?.value?.send_email_on_alerts,
      sendScheduleEmailCompletion:
        data?.job_def_config?.value?.job_completion_email,
      internalRecipients:
        internalRecipientsData?.map((user) => {
          return {
            name: user?.node_name,
            id: user?.node_id,
            type: user?.node_type,
          };
        }) || [],

      externalRecipients,
    },

    jobScheduleInfo: getJobScheduleInfo(data?.job_def_config?.value),
  };
}

export function getParsedJobScheduleDocument({
  data,
}: AxiosResponse<any>): JobScheduleDocumentParsedType {
  // please remove any
  const parsedData = data?.[0];
  const schSummaryData = jsonParse(parsedData?.SCH_SUMMARY);
  const sawsDetail = jsonParse(parsedData?.SAWS_DETAILS);

  return {
    jobCategory: parsedData?.JOB_CATEGORY,
    jobCategoryId: parsedData?.JOB_CATEGORY_ID,
    jobScheduleStartedDes:
      // "Job started by Barkha at 11/20/2023 08:00:00 PM PT",
      `Job started ${
        parsedData?.CREATED_BY && `by ${parsedData?.CREATED_BY}`
      } at ${utcTOLocalTimeZone(
        parsedData?.SCH_START_DATE,
        MONTH_DAY_YEAR_HOUR_MINUTE_SECOND
      )}`,
    jobScheduleDes:
      // "Took 40 seconds and completed at 11/20/2023 08:00:40 PM PT",
      `Took ${calculateTimeDifference(
        schSummaryData?.job_summary?.start_date,
        schSummaryData?.job_summary?.sch_end_date
      )} and completed at ${utcTOLocalTimeZone(
        parsedData?.SCH_END_DATE,
        MONTH_DAY_YEAR_HOUR_MINUTE_SECOND
      )}`,
    emailSentOn: utcTOLocalTimeZone(
      // schSummaryData?.job_summary?.sch_end_date,
      parsedData?.SCH_END_DATE,
      MONTH_DAY_YEAR_HOUR_MINUTE
      // "MM/DD/YYYY hh:mm:ss A"
    ),
    emailSentTo: schSummaryData?.email_recipients,
    jobScheduleName: schSummaryData?.job_summary?.job_id,
    // jobType: schSummaryData?.job_summary?.sch_status,
    jobType: parsedData?.SCH_STATUS,
    jobTypeId: parsedData?.SCH_STATUS_ID,
    // schStatus: parsedData?.SCH_STATUS,
    jobId: schSummaryData?.job_summary?.job_id,
    schName: parsedData?.SCH_NAME,
    createdOnTime: parsedData?.SCH_START_DATE,
    sawsTimezoneId: sawsDetail[0]?.saws_timezone_id,
    sawsTimezoneOffset: sawsDetail[0]?.saws_timezone_offset,
    jobName: parsedData?.JOB_NAME,

    // emails: ["emmaengineer@dvsum.com", "engrsgroup@dvsum.com"],
    // emails: schSummaryData?.email_recipients,
    validationResult: "",
    isOnlineJob: parsedData?.IS_JOB_INTERNAL || false,
    ruleSummary: schSummaryData?.rule_summary?.map((summary: any) => ({
      rule_id: summary?.rule_id,
      node_id: summary?.node_id,
      rule_description: summary?.rule_desc,
      result: summary?.rule_result,
      r_score: summary?.rule_score,
      status: summary?.rule_status,
      system_comments: summary?.rule_remarks,
      insights: summary?.rule_insights,
      rule_exp_url: summary?.rule_exp_url || "",
    })),
    tblSummary: schSummaryData?.tbl_summary?.map((summary: any) => ({
      source_name: summary?.src_name,
      table_name: summary?.tbl_name,
      record_count: summary?.tbl_record_cnt,
      num_of_rules: summary?.tbl_rule_cnt,
      r_score: summary?.tbl_quality_score,
      node_id: summary?.node_id,
      src_id: summary?.src_id,
    })),
  };
}

export function getJobExecutionHistoryParsedData({
  data,
}: AxiosResponse<JobExecutionHistoryDataResponse>): JobExecutionHistoryDataParsedType {
  const execHistory = data?.data || [];

  return {
    history:
      execHistory?.map((item) => {
        return {
          scheduleId: item?.SCH_NAME || "",
          scheduleType: item?.SCH_TYPE || "",
          status: item?.SCH_STATUS || "",
          statusId: item?.SCH_STATUS_ID || "",
          startTime: item?.SCH_START_DATE || "",
          endTime: item?.SCH_END_DATE || "",
          noOfRecipients: `${item?.USERS_CNT}` || "",
          noOfNodes: `${item?.NODES_CNT}` || "",
          id: `${item?.SCH_ID}` || "",
        };
      }) || [],
    totalRows: data?.total_pages || 0,
    totalCount: data?.total_count,
  };
}
