import styled from "styled-components";

export const ChatPreferencesViewStyled = styled.div<{ isChatPage: boolean }>`
  display: ${({ isChatPage }): string => (isChatPage ? "block" : "flex")};
  justify-content: space-between;

  .form-wrapper {
    width: 100%;

    .form-section {
      margin-bottom: 51.2px;

      .form-item-label-wrapper {
        width: 369.6px;

        .default-answer-output-field {
          .ant-switch {
            margin-top: 0;
          }

          .label-desc {
            font-size: 13px;
            color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
          }
        }
      }

      .date-format-wrapper {
        .ant-form-item-control {
          width: 100%;
        }
      }

      .ant-radio-group {
        .ant-radio-wrapper {
          width: 185px;
        }
      }
    }
  }

  .action-sec {
    display: flex;
    column-gap: 9.6px;

    ${({ isChatPage, theme }): string =>
      isChatPage
        ? `
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: ${theme.bgColors.LIGHT_GREY_22};
        justify-content: end;
        align-items: center;
        padding: 15px 25px;
    `
        : ""}
  }
`;

export const SecTitleStyled = styled.div<{ marginBottom?: string }>`
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  font-size: 16px;
  font-family: OpenSansSemiBold;
  margin-bottom: ${({ marginBottom }): string => marginBottom || "16px"};
`;

export const SecDescStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.GREY_26};
  font-size: 12px;
  margin: 6.4px 0px 19.2px 0px;
  width: 608px;
`;
