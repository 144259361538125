import { useState } from "react";
import { numberFormatter } from "../../utils";
import { CustomCountPaginationProps } from "./customcountpagination.types";
import { AgGridCountPaginationStyled } from "./customcountpagination.styles";
import { useCustomPolling } from "../../customhooks";

const CustomCountPagination = (
  props: CustomCountPaginationProps
): JSX.Element => {
  const { gridApi } = props;
  const [paginationRowCount, setPaginationRowCount] = useState<number>(0);

  useCustomPolling(
    () => {
      if (gridApi) setPaginationRowCount(gridApi?.getDisplayedRowCount() || 0);
    },
    500,
    [gridApi]
  );

  return (
    <AgGridCountPaginationStyled className="ag-count-pagination-panel">
      <div className="count-label">Total Records: </div>
      <div className="count">{numberFormatter(`${paginationRowCount}`)}</div>
    </AgGridCountPaginationStyled>
  );
};

export default CustomCountPagination;
