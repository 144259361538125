import { useCallback, useState } from "react";

import { InputField } from "../../../../components/formfields";

import { InputFieldType } from "../../../../components/formfields/inputfield/inputfield.types";

import { StyledInputField } from "./inputfield.styles";

const InputFieldRegistration = (props: InputFieldType): JSX.Element => {
  const { placeholder, value } = props;

  const [isFocus, setIsFocus] = useState(false);

  const onChangeFocus = useCallback(() => {
    setIsFocus(!isFocus);
  }, [isFocus]);

  return (
    <StyledInputField
      isFocus={isFocus}
      hasValue={!!value}
      onFocus={onChangeFocus}
      onBlur={onChangeFocus}
    >
      <InputField {...props} placeholder="" />

      <span className="floating-label">{placeholder}</span>
    </StyledInputField>
  );
};

export default InputFieldRegistration;
