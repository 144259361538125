export const containsDelimitersInRefDictData = (
  str: string,
  delimiters: string[]
): boolean => {
  try {
    return delimiters?.some((delimiter) => str?.includes(delimiter));
  } catch {
    return false;
  }
};
