import { DataNode } from "antd/lib/tree";
import { UserGroupsParserReturnType } from "../../parsers";
import { UsersParserReturnType } from "../../parsers/users/usersparser.types";
import { InitialTreeDataType } from "./addnewjobform.types";

export const getInitialTreeDataForUsersandGroups = (
  users: UsersParserReturnType[],
  groups: UserGroupsParserReturnType[]
): InitialTreeDataType[] => {
  return [
    {
      id: "group-data",
      title: "Groups",
      key: "group-data",
      nodeType: "UGP",
      subDomainsCount: groups?.length,
      children: groups?.map((group) => ({
        key: `group-data-${group?.id}`,
        id: `${group?.id}`,
        title: group?.group_name,
        children: [],
        nodeType: "UGP",
      })),
      isLeaf: false,
      childrenCount: groups?.length,
    },
    {
      id: "user-data",
      title: "Users",
      key: "user-data",
      nodeType: "USR",
      subDomainsCount: users?.length,
      children: users?.map((user) => ({
        key: `user-data-${user?.id}`,
        id: `${user?.id}`,
        title: `${user?.firstName} ${user?.lastName}`,
        children: [],
        nodeType: "USR",
      })),
      isLeaf: false,
      childrenCount: users?.length,
    },
  ];
};

export const compareArrays = (
  arr1: number[] | string[],
  arr2: number[] | string[]
): boolean => {
  const arr1Sorted = arr1 && arr1.length ? arr1?.slice()?.sort() : arr1;
  const arr2Sorted = arr2 && arr2.length ? arr2?.slice()?.sort() : arr2;

  return !!(
    arr1Sorted &&
    arr2Sorted &&
    arr1Sorted.length === arr2Sorted.length &&
    arr1Sorted.every((value, index) => value === arr2Sorted[index])
  );
};
