import { Button } from "../../../../components";
import { ELEMENT_IDS } from "../../../../constants";
import { shareConceptSvg } from "../../../../svgs";
import { getDvSumInformativeText } from "../../../../utils";
import { GuidelinesBlankstateStyled } from "./guidelinestab.styles";
import { GuidelinesBlankstateProps } from "./guidelinestab.types";

export const GuidelinesBlankstate = (
  props: GuidelinesBlankstateProps
): JSX.Element => {
  const { onAddGuidelineClick } = props;

  const { ssa_cnp_blnkst: ssaCnpBlnkst } = getDvSumInformativeText();
  return (
    <GuidelinesBlankstateStyled>
      <div className="blankstate-content">
        {shareConceptSvg}
        <div className="heading">Add concepts for your bot</div>
        <div className="desc">{ssaCnpBlnkst?.description}</div>
        <Button
          onClick={onAddGuidelineClick}
          elementId={ELEMENT_IDS.cht_bot_trng_add_cnp_btn}
        >
          Add Concept
        </Button>
      </div>
    </GuidelinesBlankstateStyled>
  );
};
