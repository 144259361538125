import { Tooltip } from "antd";

import {
  DVSUM_TOOLTIP_CLASS_NAME,
  TERM_SECTION_TITLES,
  WORKFLOW_TOOLTIP_LABEL,
} from "../../constants";

import ProminentTags from "../prominenttagslist";
import Showmore from "../showmore";

import Showmoreorlesstext from "../showmoreorlesstext";
import { SimpleTag } from "../tags";

import { SectionRowStyled } from "./sectionrow.styles";
import { SectionRowProps } from "./sectionrow.types";
import { workflowIcon } from "../../svgs";

function SectionRow(props: SectionRowProps): JSX.Element {
  const { isRowVisibleInNormalView = true, isWorkflowEnabled } = props;

  return (
    <SectionRowStyled className={`sec-row ${props?.className || ""}`}>
      <>
        {isRowVisibleInNormalView &&
          (props?.customRowComp ? (
            props?.customRowComp
          ) : (
            <div className="main-row">
              <div className="row-title">{props?.title}:</div>
              {props?.customJSX ? (
                <div className="row-value">{props.customJSX || "--"}</div>
              ) : props?.tags || props?.prominentTags ? (
                <div className="row-value tags">
                  {props?.tags?.length || props?.prominentTags?.tags?.length ? (
                    <>
                      {!!props?.prominentTags?.tags?.length && (
                        <ProminentTags {...props?.prominentTags} />
                      )}
                      {!!props?.tags?.length &&
                        props?.tags?.map((item: string, index) => (
                          <SimpleTag
                            width="120px"
                            key={`sectionrow-${item}-${index}`}
                            height="28px"
                          >
                            {item}
                          </SimpleTag>
                        ))}
                    </>
                  ) : (
                    <div className="row-value">--</div>
                  )}
                </div>
              ) : props?.moreTags ? (
                <div className="row-value tags">
                  {props?.moreTags?.length ? (
                    <>
                      <SimpleTag width="120px">
                        {props?.moreTags?.[0]}
                      </SimpleTag>
                      {props?.moreTags?.length >= 2 && (
                        <Tooltip
                          title={
                            <div>
                              {props?.moreTags
                                ?.slice(1, props?.moreTags?.length)
                                ?.map((item) => (
                                  <div key={`moretags-${item}`}>{item}</div>
                                ))}
                            </div>
                          }
                          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                          placement="right"
                        >
                          <SimpleTag minWidth="30px">
                            <span className="more-tags-count">
                              +{props?.moreTags?.length - 1}
                            </span>
                          </SimpleTag>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <div className="row-value">--</div>
                  )}
                </div>
              ) : props?.prominentTags ? (
                props?.prominentTags?.tags?.length ? (
                  <ProminentTags {...props?.prominentTags} />
                ) : (
                  <div className="row-value">--</div>
                )
              ) : props?.value ? (
                <div className="row-value multiline-text" title={props?.value}>
                  <Showmoreorlesstext text={props?.value} />
                  {isWorkflowEnabled &&
                    props?.title ===
                      TERM_SECTION_TITLES?.CLASSIFICATION?.FUNCTIONAL_AREA && (
                      <Tooltip
                        overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                        title={WORKFLOW_TOOLTIP_LABEL}
                        placement="right"
                      >
                        <span className="name-icon-group">
                          {workflowIcon("18", "18")}
                        </span>
                      </Tooltip>
                    )}
                </div>
              ) : props?.stringValues ? (
                <div className="row-value">
                  <Showmore data={props?.stringValues ?? []} limit={3} />
                </div>
              ) : (
                <div className="row-value">--</div>
              )}
            </div>
          ))}
        {props?.subList && (
          <div className="sublist">
            <div className="sublist-row-sec">
              {props?.subList?.map((subItem) => (
                <div
                  className="sublist-row"
                  key={`key-${subItem.title}-${subItem.value}`}
                >
                  <div className="sublist-title">{subItem?.title}</div>
                  {subItem?.customJSX ? (
                    <div className="sublist-value">
                      {subItem.customJSX || "--"}
                    </div>
                  ) : subItem?.tags ? (
                    <div className="sublist-value tags">
                      {subItem?.tags?.map((item: string, index) => (
                        <SimpleTag
                          width="120px"
                          key={`sectionsublist-${item}-${index}`}
                        >
                          {item}
                        </SimpleTag>
                      ))}
                    </div>
                  ) : subItem?.value ? (
                    <div
                      className="sublist-value multiline-text"
                      title={subItem?.value}
                    >
                      <Showmoreorlesstext text={subItem?.value} />
                    </div>
                  ) : subItem?.stringValues ? (
                    <div className="sublist-value">
                      <Showmore data={subItem?.stringValues ?? []} limit={3} />
                    </div>
                  ) : (
                    <div className="sublist-value">--</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    </SectionRowStyled>
  );
}

export default SectionRow;
