import { Button } from "../../../../components";
import Flex from "../../../../components/flex";
import { ELEMENT_IDS } from "../../../../constants";

import { DELETE_CONFIRMATION_TEXT } from "./deleteviewtooltip.constants";

import { DeleteViewTooltipStyled } from "./deleteviewtooltip.styles";

import { DeleteViewTooltipProps } from "./deleteviewtooltip.types";

const {
  allcat_lst_edi_view_del_confirm: ALLCAT_LST_EDI_VIEW_DEL_CONFIRM,
} = ELEMENT_IDS;

const DeleteViewTooltip = (props: DeleteViewTooltipProps): JSX.Element => {
  const {
    handleCancel,
    handleDelete,
    confirmationText = "",
    btnText = "",
    title = "",
  } = props;
  return (
    <DeleteViewTooltipStyled>
      <div className="heading">{title || "Delete"}</div>
      <div className="body">{confirmationText}</div>
      <Flex justifyContent="flex-end" columnGap={10}>
        <Button id="cancel" width="72px" height="32px" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          id="primary"
          width="72px"
          height="32px"
          onClick={handleDelete}
          elementId={ALLCAT_LST_EDI_VIEW_DEL_CONFIRM}
        >
          {btnText}
        </Button>
      </Flex>
    </DeleteViewTooltipStyled>
  );
};

export default DeleteViewTooltip;
