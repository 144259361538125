export function getItemKeyAndSymbolOfPattern(
  readPattern: string
): [string[], string[]] {
  let startIndex = 0;
  const itemKeys: string[] = [];
  const chars: string[] = [];

  [...readPattern]?.forEach((chr, index) => {
    if (chr === ">" || chr === ".") {
      if (index > 0) {
        itemKeys.push(readPattern.slice(startIndex, index));
      }
      chars.push(chr);
      startIndex = index + 1;
    }

    if (index === readPattern.length - 1) {
      itemKeys.push(readPattern.slice(startIndex, readPattern.length));
    }
  });

  return [itemKeys, chars];
}
