export const checkStringIncludesSubString = (
  cmpString: string,
  subString: string
): boolean => {
  try {
    return cmpString
      ?.toLocaleLowerCase()
      ?.includes(subString?.toLocaleLowerCase());
  } catch {
    return false;
  }
};
