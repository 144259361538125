import styled from "styled-components";
import Alert from "../../../../../../../components/alert/alert";

export const ConnectionSettingsTabFormStyled = styled.div`
  .ant-form {
    .form-alert-item {
      margin-bottom: 0px !important;

      .ant-form-item-control {
        .ant-form-item-control-input {
          min-height: 0px;
          .ant-form-item-control-input-content {
            .ant-alert {
              margin-bottom: 18px;
            }
          }
        }
      }
    }

    .dotted-connection-line-wrapper {
      position: relative;
    }

    .cloud-saws-ip-container {
      margin-bottom: 24px;

      .cloud-saws-ip-label {
        margin-bottom: 6.4px;
      }

      .copy-field-wrapper {
        width: 333px;
        margin-top: 8px;
      }
    }
  }
  .not-connected-ds-cb {
    span:not(.ant-checkbox) {
      font-size: 14px;
      font-family: OpenSans;
    }
  }
  .ant-form-item {
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          display: flex;
          align-items: baseline;
          column-gap: 18.1px;

          .ant-select-open.ant-select-show-search {
            .ant-select-selection-item {
              color: ${({ theme }): string => theme.textColors.GREY_101};
            }
          }

          button:not(.dynamic-link-btn) {
            position: relative;
            bottom: 2px;
          }

          .dynamic-link-btn {
            font-family: OpenSansSemiBold;
          }

          .ant-radio-group {
            .ant-radio-wrapper {
              width: auto;
              min-width: 100px;
              max-width: 170px;
            }
          }

          .tooltip-with-icon {
            svg {
              width: 6.3px;
              height: 13.8px;
            }
          }
        }
      }
    }
  }

  .add-src-helping-info {
    display: flex;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    justify-content: end;
    height: 100%;
  }
`;

export const CustomAlertStyled = styled(Alert)`
  &.ant-alert {
    background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_15};
    width: 323px;
    .ant-alert-content {
      .ant-alert-message,
      .ant-alert-description {
        font-size: 16px;
        color: ${({ theme }): string => theme.textColors.BLUE_116};
      }

      .ant-alert-message {
        font-family: OpenSansBold;
        display: flex;
        column-gap: 4px;
        align-items: center;
      }

      .ant-alert-description {
        font-family: OpenSans;
      }
    }
  }

  &.fixed-custom-alert {
    position: fixed;
    top: 62px;
    right: 46px;
    width: 323px;
  }
`;

export const LogsContainerWrapperStyled = styled.div`
  &.logs-container-wrapper {
    min-height: 100px;
    max-height: 200px;
    background: ${({ theme }): string => theme.bgColors?.BLUE_11};
    border: 1px solid ${({ theme }): string => theme.borderColors.GREY_50};
    overflow-y: auto;
    width: 333px;

    .logs-container {
      padding-top: 15px;
      padding-left: 30px;
      padding-right: 8px;
      font-size: 13px;

      li::marker {
        color: ${({ theme }): string => theme.bgColors.LIGHT_GREY};
      }
    }
  }
`;

export const DottedConnectionLine = styled.div<{ height?: string }>`
  position: absolute;
  top: 12px;
  border: dashed 1px #cfcece;
  width: 7px;
  height: ${({ height }): string => height || "35px"};
  border-right: none;
  left: -14px;
`;
