import styled from "styled-components";

export const AddNewJobViaExecuteApiStyled = styled.div``;

export const ApiInformationContentStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 50px;
  margin-bottom: 30px;
`;

export const ApiInformationLeftContentStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 15px;

  .label {
    color: ${({ theme }): string => theme.textColors.GREY_24};
    font-size: 13.6px;
    display: block;
    margin-top: 15px;
  }

  .button-spninner-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 15px;
    background-color: #f6f6f6;
    width: 520px;
    padding: 13px 16px;

    button {
      display: flex;
      align-items: center;
      column-gap: 8px;
      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
      }
    }
  }
`;

export const ApiInformationRightContentStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 10px;

  background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
  width: 550px;
  max-height: 550px;
  overflow-y: auto;
  /* position: absolute; */

  ::-webkit-scrollbar {
    width: 8px;
  }
`;

export const ApiInformationStepContentStyled = styled.div`
  padding: 26px 30px;
  display: flex;
  align-items: flex-start;
  row-gap: 15px;
  flex-direction: column;
  width: 100%;

  :first-child {
    border-bottom: 1px solid
      ${({ theme }): string => theme.borderColors.GREY_56};
  }

  .step-name {
    color: ${({ theme }): string => theme.textColors.GREY_40};
    font-family: OpenSansSemiBold;
    font-size: 13.6px;
  }

  .request-headers-info {
    display: flex;
    column-gap: 4px;

    .request-type {
      color: ${({ theme }): string => theme.textColors.GREY_40};
      font-size: 13.6px;
    }

    .request-url {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      font-size: 13.6px;
    }
  }

  .request-body {
    display: grid;
    width: 100%;
    grid-template-columns: 130px 1fr;
    row-gap: 10px;

    .request-body-left {
      color: ${({ theme }): string => theme.textColors.GREY_40};
      font-family: OpenSansSemiBold;
      font-size: 13.6px;
    }

    .request-body-right {
      color: ${({ theme }): string => theme.textColors.GREY_40};
      font-size: 13.6px;
    }
  }

  .response {
    color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    p {
      margin-bottom: 2px;
    }
  }
`;

export const RequestBodyStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
  font-size: 13.6px;

  .url {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 13.6px;
  }
`;
