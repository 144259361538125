import { useState } from "react";
import Flex from "../flex";

import { DropdownOptionWithHoverIconStyled } from "./dropdownoptionwithhovericon.styles";
import { DropdownOptionWithHoverIconOptions } from "./dropdownoptionwithhovericon.types";
import LinkButton from "../linkbutton";

const DropdownOptionWithHoverIcon = (
  props: DropdownOptionWithHoverIconOptions
): JSX.Element => {
  const { icon, label, onClickIcon } = props;
  const [hover, setHover] = useState(false);

  const onChangeHover = (onMouseEnter: boolean) => (): void => {
    setHover(onMouseEnter);
  };

  return (
    <DropdownOptionWithHoverIconStyled
      onHover={hover}
      onMouseEnter={onChangeHover(true)}
      onMouseLeave={onChangeHover(false)}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <div>{label}</div>
        {hover && (
          // <div
          //   role="button"
          //   className="icon-button"
          //   onClick={(): void => onClickIcon?.()}

          // >
          //   {icon}
          // </div>
          <LinkButton
            className="icon-button"
            onClick={(): void => onClickIcon?.()}
          >
            {icon}
          </LinkButton>
        )}
      </Flex>
    </DropdownOptionWithHoverIconStyled>
  );
};

export default DropdownOptionWithHoverIcon;
