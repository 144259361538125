import { LineProps } from "recharts";
import { Key, useCallback, useMemo } from "react";

import {
  ColumnChart,
  LineChart,
  TileView,
} from "../../../../../../../../../../../../components/recharts";

import { DVSUM_COLOR_PALLETE } from "../../../../../../../../../../../../components/recharts/dvsumcolorpallete";

import { X_AXIS_DATA_KEY } from "../../../../../../../../../../../../constants";

import { QueryResultsChartStyled } from "./queryresultschart.styles";

import { QueryResultsChartProps } from "./queryresultschart.types";

import {
  formatPercentage,
  getEnvVariables,
  getObjectKeys,
  getObjectValues,
  isAmountMetricColumn,
  isNull,
  isNumber,
  isPercentageMetricColumn,
  jsonStringify,
  transformDashedTextToCapitalizedText,
} from "../../../../../../../../../../../../utils";

import { AnalysisPageQueryBoxChartConfig } from "../../../../../../../../../../analysisdetailpage.types";
import { queryResultsGridHumanReadableFormating } from "../../queryresults.utils";

import { KeyValuePairType } from "../../../../../../../../../../../../app.types";
import { formatProfilingDate } from "../../../../../../../../../../analisisdetailpage.utils";
import { calculateElementSize } from "../../../../../../../../../../../../utils/calculateelementsize";

function transformData(
  data: AnalysisPageQueryBoxChartConfig["data"]
): LineProps["data"] {
  return data?.labels?.map((label: string, index: number) => {
    const obj: { [key: string]: string | number } = {
      name: isNull(label) ? "null" : label,
    };
    data?.datasets?.forEach((dataset: { label: string; data: number[] }) => {
      obj[transformDashedTextToCapitalizedText(dataset?.label)] = Number(
        dataset?.data[index]
      );
    });
    return obj;
  });
}

const QueryResultsChart = (props: QueryResultsChartProps): JSX.Element => {
  const {
    isDataFormatted = true,
    isChatPage = false,
    chartConfig,
    isChartSettingsVisible = false,
    prefrences = {},
    defSortKey = "",
    defSortdirection,
    shouldDoDefaultSorting,
    metricFormat = [],
    alreadyPercFrmtedCols = [],
  } = props;

  const {
    chart_type: chartType = "N/A",
    data = { datasets: [], labels: [] },
    tile_data: tileData,
    chart_x_axis: chartXAxisLabel = "",
    chart_y_axises: chartYAxises = [],
    is_stacked_chart: isStackedChart,
    date_aggregated_by: dateAggregatedBy = "",
    has_no_y_axes: hasNoYAxes,
    sort_info_of_y_axis: sortInfoOfYAxis,
    is_default_settings: isDefaultSettings,
  } = chartConfig || {};

  const { direction: yAxisSortDir, name: yAxisSortFieldName = "" } =
    sortInfoOfYAxis || {};

  const {
    title: tileTitle = "",
    count: tileCount = 0,
    data_type: dataType = "NUM",
  } = tileData || {};

  const { REACT_APP_DISTINCT_COUNT_FOR_ATTRIBUTE } = getEnvVariables();
  const updatedChartXAxisLabel = useMemo(
    () => transformDashedTextToCapitalizedText(chartXAxisLabel),
    [chartXAxisLabel]
  );

  const updatedTileTitle = useMemo(
    () => transformDashedTextToCapitalizedText(tileTitle),
    [tileTitle]
  );

  const { showDollarSignOnTile, showPercentageSignOnTile } = useMemo(
    () => ({
      showDollarSignOnTile: isAmountMetricColumn(tileTitle),
      showPercentageSignOnTile: isPercentageMetricColumn(tileTitle),
    }),
    [tileTitle, prefrences]
  );

  const {
    dataKeys,
    transformedData,
    dataKeysMaxVal,
    dataKeysMinVal,
  } = useMemo(() => {
    const transformedDataWithMaxLimit = (transformData(data) || [])?.slice(
      0,
      // additional check to prevent chart from breaking, if large data is passed to chart
      chartType === "Column" ? REACT_APP_DISTINCT_COUNT_FOR_ATTRIBUTE : 150
    );

    const transformedData = transformedDataWithMaxLimit?.map(
      (item: KeyValuePairType<Key>) => {
        const keys = getObjectKeys(item)?.filter(
          (item) => item !== X_AXIS_DATA_KEY
        );
        //Currently this key is column name but after bader's refactoring It'll be a column display name
        const percentageColumns = keys?.filter((key) =>
          isPercentageMetricColumn(key)
        );
        const updatedValues = percentageColumns?.reduce(
          (prev, next) => ({
            ...prev,
            [next]: formatPercentage(
              +item?.[next],
              alreadyPercFrmtedCols?.includes(next)
            ),
          }),
          {}
        );
        return { ...item, ...updatedValues };
      }
    );

    const transformedDaatCopy = [...transformedData];

    const sortedTransformedData = shouldDoDefaultSorting
      ? transformedDaatCopy?.sort(
          (a: KeyValuePairType<number>, b: KeyValuePairType<number>) => {
            return defSortdirection === "asc"
              ? a?.[defSortKey] - b?.[defSortKey]
              : b?.[defSortKey] - a?.[defSortKey];
          }
        )
      : sortInfoOfYAxis?.name && sortInfoOfYAxis?.direction
      ? transformedDaatCopy?.sort(
          (a: KeyValuePairType<number>, b: KeyValuePairType<number>) => {
            const beautifyText = transformDashedTextToCapitalizedText(
              yAxisSortFieldName
            );
            return sortInfoOfYAxis?.direction === "asc"
              ? a?.[beautifyText] - b?.[beautifyText]
              : b?.[beautifyText] - a?.[beautifyText];
          }
        )
      : transformedData;

    const dataKeys = getObjectKeys(sortedTransformedData?.[0])
      ?.filter((item) => item !== X_AXIS_DATA_KEY)
      ?.map((nestItem, index) => ({
        dataKey: nestItem || "",
        fill: DVSUM_COLOR_PALLETE[index] || "",
      }));

    const dataKeysMaxVal: { [key: string]: number } = dataKeys?.reduce(
      (prev, next) => {
        const filteredData = sortedTransformedData
          ?.map((item: any) => Number(item?.[next?.dataKey]))
          ?.filter((nestItem: number) => nestItem);
        return {
          ...prev,
          [next?.dataKey]: filteredData?.length ? Math.max(...filteredData) : 0,
        };
      },
      {}
    );

    const dataKeysMinVal: { [key: string]: number } = dataKeys?.reduce(
      (prev, next) => {
        const filteredData = sortedTransformedData
          ?.map((item: any) => Number(item?.[next?.dataKey]))
          ?.filter((nestItem: number) => isNumber(nestItem));

        return {
          ...prev,
          [next?.dataKey]: filteredData?.length ? Math.min(...filteredData) : 0,
        };
      },
      {}
    );

    return {
      transformedData: sortedTransformedData,
      dataKeys,
      dataKeysMaxVal,
      dataKeysMinVal,
    };
  }, [
    jsonStringify([
      data,
      yAxisSortDir,
      yAxisSortFieldName,
      prefrences,
      chartType,
      defSortKey,
      defSortdirection,
      shouldDoDefaultSorting,
      metricFormat,
    ]),
  ]);

  const isRotateXAxis = transformedData?.length > 6;

  const chartFormatter = useCallback(
    (isYDomain?: boolean) => (val: number, yAxis: string): string => {
      const findedYAxis = chartYAxises?.find(
        (item) => transformDashedTextToCapitalizedText(item?.name) === yAxis
      );

      return queryResultsGridHumanReadableFormating({
        number: val,
        ...(isYDomain && { minNum: dataKeysMaxVal?.[yAxis] }),
        dataType: findedYAxis?.data_type,
        isDataFormatted,
        decimals: prefrences?.[findedYAxis?.name || ""]?.decimals,
      });
    },
    [dataKeysMaxVal, isDataFormatted, chartYAxises, prefrences]
  );

  const { yAxisForChart, rightOffset, leftOffset } = useMemo(() => {
    const leftYaxis = chartYAxises?.filter(
      (item) => item?.orientation === "left"
    );
    const rightYaxis = chartYAxises?.filter(
      (item) => item?.orientation === "right"
    );

    const leftYaxisMaxVals: KeyValuePairType<number> = leftYaxis?.reduce(
      (prev, next) => ({
        ...prev,
        [next?.name]: dataKeysMaxVal?.[
          transformDashedTextToCapitalizedText(next?.name)
        ],
      }),
      {}
    );

    const rightYaxisMaxVals: KeyValuePairType<number> = rightYaxis?.reduce(
      (prev, next) => ({
        ...prev,
        [next?.name]: dataKeysMaxVal?.[
          transformDashedTextToCapitalizedText(next?.name)
        ],
      }),
      {}
    );

    const leftYaxisMinVals: KeyValuePairType<number> = leftYaxis?.reduce(
      (prev, next) => ({
        ...prev,
        [next?.name]: dataKeysMinVal?.[
          transformDashedTextToCapitalizedText(next?.name)
        ],
      }),
      {}
    );

    const rightYaxisMinVals: KeyValuePairType<number> = rightYaxis?.reduce(
      (prev, next) => ({
        ...prev,
        [next?.name]: dataKeysMinVal?.[
          transformDashedTextToCapitalizedText(next?.name)
        ],
      }),
      {}
    );

    const rightYaxisMaxVal = Math.max(
      ...(getObjectValues(rightYaxisMaxVals) as number[])
    );

    const leftYaxisMaxVal = Math.max(
      ...(getObjectValues(leftYaxisMaxVals) as number[])
    );

    const rightYAxisMinVal = Math.min(
      ...(getObjectValues(rightYaxisMinVals) as number[])
    );
    const leftYAxisMinVal = Math.min(
      ...(getObjectValues(leftYaxisMinVals) as number[])
    );

    const maxLeftYaxisPropertyName =
      getObjectKeys(leftYaxisMaxVals)?.filter(
        (item) => leftYaxisMaxVals?.[item] === leftYaxisMaxVal
      )?.[0] || "";

    const maxRightYaxisPropertyName =
      getObjectKeys(rightYaxisMaxVals)?.filter(
        (item) => rightYaxisMaxVals?.[item] === rightYaxisMaxVal
      )?.[0] || "";

    const leftOffset = Math.max(
      calculateElementSize(
        queryResultsGridHumanReadableFormating({
          number: leftYaxisMaxVal,
          isDataFormatted,
          decimals: prefrences?.[maxLeftYaxisPropertyName]?.decimals,
          dataType: "DEC",
        }),
        { fontSize: "14px" }
      )?.clientWidth ?? 0,
      50
    );

    const rightOffset = Math.max(
      calculateElementSize(
        queryResultsGridHumanReadableFormating({
          number: rightYaxisMaxVal,
          isDataFormatted,
          decimals: prefrences?.[maxRightYaxisPropertyName]?.decimals,
          dataType: "DEC",
        }),
        { fontSize: "14px" }
      )?.clientWidth ?? 0,
      10
    );

    return {
      yAxisForChart: chartYAxises
        ?.map((item) => {
          return {
            ...item,
            hide:
              isStackedChart && chartType === "Column"
                ? false
                : item?.orientation === "left"
                ? item?.name !== maxLeftYaxisPropertyName &&
                  !!maxLeftYaxisPropertyName
                : item?.name !== maxRightYaxisPropertyName &&
                  !!maxRightYaxisPropertyName,
          };
        })
        ?.map((yAxis) => {
          const beautifyLabel =
            transformDashedTextToCapitalizedText(yAxis?.name) || "";
          const showDollarSignYAxis = isAmountMetricColumn(
            yAxis?.display_name || yAxis?.name
          );
          const showPercentageSignYAxis = isPercentageMetricColumn(
            yAxis?.display_name || yAxis?.name
          );
          const otherYAxisHasSameOrientation = chartYAxises?.some(
            (item) =>
              item?.orientation === yAxis?.orientation &&
              item?.name !== yAxis?.name
          );

          return {
            label: {
              value: otherYAxisHasSameOrientation ? "" : beautifyLabel,
              offset:
                yAxis?.orientation === "left"
                  ? -(leftOffset - 15)
                  : rightOffset + 40,
            },
            orientation: yAxis?.orientation || "left",
            yAxisId: beautifyLabel,
            prefix: showDollarSignYAxis ? "$" : "",
            suffix: showPercentageSignYAxis ? "%" : "",
            hide: yAxis?.hide,
            ...(yAxis?.hide && {
              maxYDomain:
                yAxis?.orientation === "right"
                  ? rightYaxisMaxVal
                  : yAxis?.orientation === "left"
                  ? leftYaxisMaxVal
                  : undefined,
            }),
            ...(!yAxis?.hide && {
              minYDomain:
                yAxis?.orientation === "right"
                  ? rightYAxisMinVal
                  : yAxis?.orientation === "left"
                  ? leftYAxisMinVal
                  : undefined,
              maxYDomain:
                yAxis?.orientation === "right"
                  ? rightYaxisMaxVal
                  : yAxis?.orientation === "left"
                  ? leftYaxisMaxVal
                  : undefined,
            }),
          };
        }),
      leftOffset,
      rightOffset,
    };
  }, [
    jsonStringify([
      chartYAxises,
      dataKeysMaxVal,
      dataKeysMinVal,
      prefrences,
      isDataFormatted,
      isStackedChart,
      chartType,
      metricFormat,
    ]),
  ]);

  const tooltipLabelandXAxisTickFormatter = useCallback(
    (val: string): string => {
      return dateAggregatedBy === "Aggregated to day"
        ? formatProfilingDate(val)
        : val;
    },
    [dateAggregatedBy]
  );

  return (
    <QueryResultsChartStyled
      isChatPage={isChatPage}
      isQuerySettingsVisible={isChartSettingsVisible}
    >
      {chartType === "Column" ? (
        <ColumnChart
          isTiltedXAxisTicks={isRotateXAxis}
          data={transformedData}
          dataKeys={hasNoYAxes ? [] : dataKeys}
          barCategoryGap={0}
          barGap={0}
          margin={{ left: leftOffset, bottom: 40, right: rightOffset }}
          xAxislabel={{ value: updatedChartXAxisLabel, offset: -30 }}
          // showYAxisLabels={chartYAxisOrientation !== chartY2AxisOrientation}
          yAxis={yAxisForChart}
          isStacked={isStackedChart}
          tooltipFormatter={chartFormatter()}
          yAxisFormatter={chartFormatter(true)}
        />
      ) : chartType === "Line" ? (
        <LineChart
          isTiltedXAxisTicks={isRotateXAxis}
          data={transformedData}
          dataKeys={hasNoYAxes ? [] : dataKeys}
          width="100%"
          margin={{ left: leftOffset, bottom: 40, right: rightOffset }}
          xAxisLabel={{
            value: `${updatedChartXAxisLabel} ${
              dateAggregatedBy ? `(${dateAggregatedBy})` : ""
            }`,
            offset: -30,
          }}
          yAxis={yAxisForChart}
          tooltipFormatter={chartFormatter()}
          yAxisFormatter={chartFormatter(true)}
          tooltipLabelFormatter={tooltipLabelandXAxisTickFormatter}
          xAxisTickFormatter={tooltipLabelandXAxisTickFormatter}
        />
      ) : chartType === "Tile" ? (
        <TileView
          count={tileCount}
          labelText={updatedTileTitle}
          prefix={showDollarSignOnTile ? "$" : ""}
          suffix={showPercentageSignOnTile ? "%" : ""}
          dataType={dataType}
          isDataFormatted={isDataFormatted}
        />
      ) : (
        <div />
      )}
    </QueryResultsChartStyled>
  );
};

export default QueryResultsChart;
