import * as yup from "yup";

export const descriptionSchema = yup
  .string()
  ?.trim()
  ?.test(
    "no-more-than-1000-chars",
    "Description should be less than 1000 words",
    (currentField) => {
      const currentFieldLength = currentField?.length || 0;
      if (currentFieldLength > 1000) return false;
      return true;
    }
  );
