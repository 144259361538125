import { KeyValuePairType } from "../../../../app.types";
import { getObjectKeys } from "../../../../utils";

export const isSaveDisabled = (
  dirtyFields: KeyValuePairType<object>
): boolean => {
  const dirtyFieldsKeys = getObjectKeys(dirtyFields);

  return !dirtyFieldsKeys.includes("shared_with_list");
};
