import { eyeIcon } from "../../svgs";
import LinkButton from "../linkbutton/linkbutton";
import { ToggleEyeIconStyled } from "./toggleeyeicon.styles";
import { ToggleEyeIconType } from "./toggleeyeicon.types";

const ToggleEyeIcon = (props: ToggleEyeIconType): JSX.Element => {
  const {
    filledIcon = false,
    onClick,
    customClass = "",
    text = "",
    cutomIconClass = "",
    tooltipProps,
  } = props;

  return (
    <ToggleEyeIconStyled className="toggle-eye-icon-wrapper">
      <LinkButton
        tooltipProps={tooltipProps}
        onClick={onClick}
        className={`${filledIcon ? "selected" : ""} ${customClass}`}
      >
        <span className={cutomIconClass || "show-icon"}>{eyeIcon()}</span>
        {text && <span>{text}</span>}
      </LinkButton>
    </ToggleEyeIconStyled>
  );
};

export default ToggleEyeIcon;
