import styled from "styled-components";

export const RunNodeProfilingButtonStyled = styled.div<{
  isTextButton: boolean;
  isNodeProfiled?: boolean;
}>`
  display: flex;
  align-items: center;
  column-gap: 20px;

  .ant-btn {
    display: flex;
    align-items: center;
    column-gap: 0;
  }

  .dropdown-icon {
    svg {
      width: 6px;
      height: 8px;
    }

    svg {
      path {
        fill: ${({ theme, isTextButton }): string =>
          isTextButton
            ? theme.textColors.DARK_BLUE
            : theme.textColors.WHITE_COLOR};
      }
    }
  }

  .run-offline-rule-btn {
    :hover {
      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        }
      }
    }

    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }
    }
  }

  .run-rule-btn {
    display: flex;
    align-items: center;

    span {
      color: ${({ theme, isTextButton }): string =>
        isTextButton
          ? theme.textColors.BLUE_116
          : theme.textColors.WHITE_COLOR};
    }

    svg {
      fill: ${({ theme, isNodeProfiled, isTextButton }): string =>
        isNodeProfiled || isTextButton
          ? theme.textColors.DARK_BLUE
          : theme.textColors.ORANGE_505};
    }
  }
`;
