import { Tooltip } from "antd";
import { useState } from "react";

import { DeleteViewTooltip } from "..";
import LinkButton from "../../../../components/linkbutton";

import { DVSUM_TOOLTIP_CLASS_NAME, ELEMENT_IDS } from "../../../../constants";

import {
  AddViewActionButtonsStyled,
  ScreenOverlay,
} from "./addviewactionbuttons.styles";

import { cloneIcon, deleteIcon } from "../../../../svgs";

import { AddViewActionButtonsProps } from "./addviewactionbuttons.types";
import { DELETE_CONFIRMATION_TEXT } from "../deleteviewtooltip/deleteviewtooltip.constants";

const {
  allcat_lst_edi_view_del_btn: ALLCAT_LST_EDI_VIEW_DEL_BTN,
  allcat_lst_edi_view_clone_btn: ALLCAT_LST_EDI_VIEW_CLONE_BTN,
} = ELEMENT_IDS;

const AddViewActionButtons = (
  props: AddViewActionButtonsProps
): JSX.Element => {
  const {
    delete: { onClick: handleDeleteView, disabled: isDeleteDisabled },
    clone: { onClick: handleCloneView, disabled: isCloneDisabled = false },
  } = props;

  const [isDeleteTooltipVisible, setIsDeleteTooltipVisible] = useState<boolean>(
    false
  );

  return (
    <AddViewActionButtonsStyled justifyContent="flex-end" columnGap={24}>
      {isDeleteTooltipVisible && <ScreenOverlay />}
      <LinkButton
        className="clone-button"
        onClick={handleCloneView}
        disabled={isCloneDisabled}
        elementId={ALLCAT_LST_EDI_VIEW_CLONE_BTN}
      >
        {cloneIcon} Clone
      </LinkButton>
      <Tooltip
        overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
        getPopupContainer={(trigger): HTMLElement =>
          trigger?.parentNode as HTMLElement
        }
        title={
          <DeleteViewTooltip
            handleCancel={(): void => setIsDeleteTooltipVisible(false)}
            handleDelete={handleDeleteView}
            confirmationText={DELETE_CONFIRMATION_TEXT}
            btnText="Delete View"
          />
        }
        trigger={["click"]}
        placement="bottomRight"
        onVisibleChange={(visiblility): void =>
          setIsDeleteTooltipVisible(visiblility)
        }
        visible={isDeleteTooltipVisible}
      >
        <LinkButton
          className="delete-button"
          onClick={(): void => setIsDeleteTooltipVisible(true)}
          disabled={isDeleteDisabled}
          elementId={ALLCAT_LST_EDI_VIEW_DEL_BTN}
        >
          {deleteIcon} Delete
        </LinkButton>
      </Tooltip>
    </AddViewActionButtonsStyled>
  );
};

export default AddViewActionButtons;
