import { useMemo } from "react";
import { UseQueryResult } from "react-query";

// <--- Blankslates Start --->
import { ruleDetailsGridBlankslate } from "../../../../../../../../blankslates/ruledetailsmodalblankslate";
// <--- Blankslates End --->

// <--- Components Start --->
import AgGridTable from "../../../../../../../aggridtable";
import StateHandler from "../../../../../../../statehandler";

import Flex from "../../../../../../../flex";
import TooltipWithIcon from "../../../../../../../tooltipwithicon";
// <--- Components End --->

// <--- Services Start --->
import { useGetDQRuleDetailCSV } from "../../../../../../../../api/dataqualityservice";
// <--- Services End --->

// <--- Styles Start --->
import { DQRuleDetailGridSectionStyled } from "./dqruledetailsgridsection.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { infoIcon } from "../../../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  DQRuleDetailsGridColumnType,
  DQRuleDetailsGridSectionProps,
  DQTableColumnsType,
} from "./dqruledetailsgridsection.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  csvToJSON,
  getObjectKeys,
  getObjectValues,
  removeIndex,
} from "../../../../../../../../utils";
// <--- Utils End --->

const DQRuleDetailsGridSection = (
  props: DQRuleDetailsGridSectionProps
): JSX.Element => {
  const { timeTravelDate, csvUrl, nodeName } = props;

  const { data: sampleData, isLoading } = useGetDQRuleDetailCSV(
    csvUrl,
    !!csvUrl
  );

  const rowData = useMemo(() => {
    return csvToJSON(sampleData as UseQueryResult) || [];
  }, [sampleData]);

  const tableColumns: DQTableColumnsType = useMemo(() => {
    const columnsKeys = getObjectKeys(rowData?.[0] || {});
    const columnIndex = columnsKeys?.findIndex((key) => key === nodeName);
    const sortedColumns =
      columnIndex >= 0
        ? [nodeName, ...removeIndex(columnsKeys, columnIndex)]
        : [...columnsKeys];

    return (
      [...sortedColumns]?.map((column: string, index: number) => ({
        field: column,
        headerName: column,
        minWidth: 168,
        flex: 1,
        ...(index === 0 ? { sort: "asc" } : {}),
      })) || []
    );
  }, [rowData, nodeName]);

  const isGridEmpty = useMemo(() => {
    const data = getObjectValues(rowData?.[0]);

    return rowData?.length > 1 ? false : data?.every((item) => !item);
  }, [rowData]);

  return (
    <DQRuleDetailGridSectionStyled>
      <Flex alignItems="center" columnGap={10} customClass="time-travel-sec">
        <div className="time-travel-date">Run Date: {timeTravelDate}</div>
        <TooltipWithIcon
          className="tooltip-with-icon"
          marginLeft="0px"
          marginTop="-3px"
          minWidth="330px"
          icon={infoIcon}
          title="This Run Date is same as date selected from Time Travel section."
        />
      </Flex>

      <StateHandler
        error={false}
        isFetching={isLoading}
        blankSlate={ruleDetailsGridBlankslate}
      >
        <AgGridTable<DQRuleDetailsGridColumnType>
          tableColumns={tableColumns}
          rowData={
            isGridEmpty ? [] : rowData?.slice(0, rowData?.length - 1) || []
          }
        />
      </StateHandler>
    </DQRuleDetailGridSectionStyled>
  );
};

export default DQRuleDetailsGridSection;
