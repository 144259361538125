import { Tooltip } from "antd";
import { useCallback } from "react";

import ConfidenceScore from "../../../../components/confidencescore";
import LinkButton from "../../../../components/linkbutton";

import ShowMoreOrLessText from "../../../../components/showmoreorlesstext";
import { aiSuggestIcon, userIconOutlined } from "../../../../svgs";

import { MatchingEntitiesGridProps } from "./matchingentitiesgrid.types";
import { MatchingEntitiesGridStyled } from "./matchingentitiesgrid.styles";
import { useOpenDrawer } from "../../../../customhooks";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../constants";

function MatchingEntitiesGrid(props: MatchingEntitiesGridProps): JSX.Element {
  const { fields, onUpdate } = props;
  const openDrawer = useOpenDrawer();

  const onEntityClick = useCallback(
    (id) => {
      openDrawer({
        drawerId: "term_ref",
        drawerProps: { id },
        visible: true,
        zIndex: 1001,
      });
    },
    [props]
  );
  return (
    <MatchingEntitiesGridStyled>
      <div className="matching-entity-grid-header">
        <div>Matching Entity</div>
        <div>Matching Score</div>
        <div>Match Source</div>
        <div>Samples</div>
        <div>Action</div>
      </div>
      <div className="matching-entity-grid-body">
        {fields?.map((field) => (
          <div key={field?.id} className="matching-entity-row">
            <LinkButton
              title={field?.matching_entity}
              className="entity-cell"
              onClick={(): void => onEntityClick(field?.matching_entity_id)}
            >
              {field?.matching_entity}
            </LinkButton>
            <div>
              <ConfidenceScore score={field?.matching_score} />
            </div>
            <div className="match-source-cell">
              <Tooltip
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                title={
                  field?.match_source === "AAI"
                    ? "Matched by AI"
                    : "Matched by User"
                }
                placement="left"
              >
                {field?.match_source === "AAI"
                  ? aiSuggestIcon("19", "19")
                  : userIconOutlined("17", "19")}
              </Tooltip>
            </div>
            <div>
              <span title={field?.samples}>
                <ShowMoreOrLessText text={field?.samples} textLimit={60} />
              </span>
            </div>
            <div>
              <LinkButton
                className={`select-btn ${
                  field?.selected ? "btn-selected" : "btn-not-selected"
                }`}
                onClick={(): void =>
                  onUpdate(field?.matching_entity_id, !field?.selected)
                }
              >
                {field?.selected ? "Selected" : "Select"}
              </LinkButton>
            </div>
          </div>
        ))}
      </div>
    </MatchingEntitiesGridStyled>
  );
}

export default MatchingEntitiesGrid;
