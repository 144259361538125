// <--- Components Start --->
import CoreNodesCircleIcon from "../../../../components/corenodetypescircleicon";
// <--- Components End --->

// <--- SVGs Start --->
import { chevronRight, databaseIcon } from "../../../../svgs";
// <--- SVGs End --->

// <--- Styles Start --->
import { BreadcrumSectionStyled } from "./breadcrumsection.styles";
// <--- Styles End --->

// <--- Types Start --->
import { BreadcrumSectionProps } from "./breadcrumsection.types";
// <--- Types End --->

const BreadcrumSection = (props: BreadcrumSectionProps): JSX.Element => {
  const { databaseName, tableName, columnName } = props;

  return (
    <BreadcrumSectionStyled>
      {databaseName && (
        <>
          <div className="breadcrum-item">
            <span className="item-icon">{databaseIcon}</span> {databaseName}
          </div>
          {(tableName || columnName) && <div>{chevronRight}</div>}
        </>
      )}
      {tableName && (
        <>
          <div className="breadcrum-item">
            <CoreNodesCircleIcon nodeType="TBL" /> {tableName}
          </div>
          {columnName && <div>{chevronRight}</div>}
        </>
      )}
      {columnName && (
        <div className="breadcrum-item">
          <CoreNodesCircleIcon nodeType="COL" /> {columnName}
        </div>
      )}
    </BreadcrumSectionStyled>
  );
};

export default BreadcrumSection;
