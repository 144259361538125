import styled from "styled-components";

export const MySettingsPageStyled = styled.div`
  padding: 27px 41px;

  .header {
    margin-bottom: 16px;

    .heading-sec {
      display: flex;
      align-items: flex-end;
      column-gap: 6.4px;
      font-weight: 600;
      margin-bottom: 9.6px;

      .icon {
        svg {
          height: 20px;
          width: 20px;
          path {
            fill: ${({ theme }): string => theme.textColors.BLUE_116};
          }
        }
      }

      .heading {
        font-size: 19.2px;
        color: ${({ theme }): string => theme.textColors.BLUE_116};
      }
    }
  }

  .content-sec {
    .dvsum-tabs-left {
      min-height: 636.8px;
    }
  }
`;
