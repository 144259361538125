import styled from "styled-components";

export const SummarizeSecStyled = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  .summary-sec-title {
    font-family: OpenSansSemiBold;
  }
`;
