export const tooltipItems = [
  {
    key: "dq-alert",
    heading: "DQ Alert",
    description:
      "Configuration of Alerts using score can be done with this, If the rule score is less than Failure, then DQ Failure Alert will be generated, and if the score is between Failure and Warning, then DQ Warning Alert will be generated, else no alert.",
  },
  {
    key: "drift-direction",
    heading: "Drift Direction",
    description:
      "Configuration of Alert using the direction of score can be done with this, if score moves more the mentioned threshold level in the direction specified, then that will create the Alert.",
  },
  {
    key: "drift-calculation",
    heading: "Drift Calculation",
    description:
      "The reference value used for calculating Drift direction will be fetched either from previous execution or from moving average based on the selection.",
  },
  {
    key: "drift-alert",
    heading: "Drift Alert",
    description:
      "The percentage of Change allowed can be configured in this field. If the score changes more than that, then DQ Alert will be generated.",
  },
];
