import { AppState } from "../../reducers/appreducer/appreducer.types";

export const FULL_SCREEN_MODAL_IDS: AppState["modal"]["modalId"][] = [
  "train_bot_feedback_modal",
  "add_user_group",
  "edit_user_group",
  "add_user_role",
  "edit_user_role",
  "add_user",
  "edit_user",
  "add_sso",
  "edit_sso",
  "add_glossary_domain",
  "edit_glossary_domain",
  "add_glossary_category",
  "edit_glossary_category",
  "add_governance_view",
  "edit_governance_view",
  "clone_governance_view",
  "rule_type_detail_modal",
  "train_bot_share_concept_modal",
  "my_preferences_modal",
  "review_caddi_feedbacks_modal",
  "add_rule",
  "clone_rule_summary_modal",
  "add_new_job_modal",
  "widget_creation_wizard",
  "expanded_view",
  // "resolve_modal",
];

export const FULL_SCREEN_MODAL_IDS_FOR_EXT_DKT: AppState["modal"]["modalId"][] = [
  "suggest_an_edit_form",
  "create_suggestion_form",
  "mark_as_deprecate_suggestion",
];

export const CENTERED_SCREEN_MODAL_IDS: AppState["modal"]["modalId"][] = [
  "product_tour",
  "session_expired_modal",
  "quick_edit_for_col_confirmation",
  "data_import_submitted_modal",
  "import_fields_mapping_confirmation_modal",
  "subscription_deleted_modal",
];
