import styled from "styled-components";

export const DataClassififcationStyled = styled.div`
  font-size: 14px;
  color: ${({ theme }): string => theme.textColors.GREY_40};

  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;

    .dvsum-select-field {
      width: 400px;
    }

    .tag-select-label {
      font-size: 14px;
      font-family: OpenSansSemiBold;
      display: block;
      margin-bottom: 5px;
    }

    .tag-select-desc {
      font-size: 12px;
      margin: 5px 0 8px;
      display: block;
    }

    .tag-select-option-desc {
      font-size: 12px;
      margin: 10px 0;
      display: block;
    }
  }

  .modal-actions-sec {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    background-color: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
    border-top: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_BLUE_45};
    padding: 8px 18px 8px 18px;
  }
`;
