export const areObjectsEqual = (obj1: Object, obj2: Object): boolean => {
  try {
    const obj1Json = JSON.stringify(obj1);
    const obj2Json = JSON.stringify(obj2);

    return obj1Json === obj2Json;
  } catch (e) {
    return false;
  }
};
