import { useFormContext } from "react-hook-form";
import { Divider, Spin } from "antd";

import { useCallback, useEffect, useMemo, useState } from "react";
import { lightCrossIcon, redirectIcon } from "../../../svgs";

import Flex from "../../flex";
import LinkButton from "../../linkbutton/linkbutton";

import SelectField from "../selectfield/selectfield";
import {
  DescriptionWrapperStyled,
  HierarchyFieldStyled,
  InputLabel,
  OpenDictView,
  SpinnerLoaderStyled,
  VerticalDividerStyled,
} from "./dictionaryfield.styles";

import { HierarchyFieldProps } from "./dictionaryfield.types";
import InputField from "../inputfield/inputfield";

import { getDataTypeIcon, selectFilterOption } from "../../../utils";
import { DictionaryMappingConfigType } from "../../../parsers/ruleparser/ruleparser.types";

import { RefDictSpecifyFieldsType } from "../../../forms/addruleform/addfoundationaldqform/addvaluerangeform/addvaluerangeform.types";
import { ColumnNameAndTypeStyled } from "../../../forms/addruleform/dynamicform/dynamicform.styles";

import StyledLink from "../../styledlink/styledlink";
import { APP_ROUTES } from "../../../constants";
import { getReferenceDictionaryDetailPageUrl } from "../../../utils/getreferencedictionarydetailpageurl";

const DictionaryField = (props: HierarchyFieldProps): JSX.Element => {
  const {
    width = "",
    options = [],
    name,
    dictListKeys = [],
    dictListLoading,
    dictListValues,
    isSelectedDictionaryHierarchial = false,
    columns,
    intialSpecifyFields,
    onChange,
    isInEditMode,
  } = props;

  const {
    control,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useFormContext();

  const {
    specify_fields: specifyRefDictFieldValues,
    dictionary: selectedDictionary,
  } = watch();

  const fieldsToRender = isInEditMode
    ? intialSpecifyFields?.length
    : dictListKeys?.length;

  const lengthOfDictListValues = dictListValues?.length || 0;

  const [fieldsData, setFieldsData] = useState<DictionaryMappingConfigType>([]);

  const columnSelectOptions = useMemo(() => {
    return (
      columns?.map((column) => ({
        key: `column-option-${column?.field_id}`,
        value: `${column?.field_id}`,
        label: (
          <ColumnNameAndTypeStyled>
            <span className="icon">
              {getDataTypeIcon(column?.field_datatype)}
            </span>
            <span className="label">{column?.field_display_name}</span>
          </ColumnNameAndTypeStyled>
        ),
        labelText: column?.field_display_name as string,
      })) || []
    );
  }, [columns]);

  const removeField = useCallback(
    (index: number) => (): void => {
      const filteredFieldsData = fieldsData?.filter(
        (_, curerntFieldIndex) => curerntFieldIndex !== index
      );

      setFieldsData(filteredFieldsData);

      const updateRefDictOnIndexDeletion = specifyRefDictFieldValues?.filter(
        (currentField: RefDictSpecifyFieldsType, curerntFieldIndex: number) =>
          curerntFieldIndex !== index
      );

      setValue("specify_fields", updateRefDictOnIndexDeletion);
    },
    [fieldsData, specifyRefDictFieldValues]
  );

  useEffect(() => {
    const filteredDistKeys = dictListKeys?.slice(0, fieldsToRender);
    setFieldsData(filteredDistKeys);
  }, [dictListKeys, fieldsToRender]);

  const refdictionaryListingUrl =
    APP_ROUTES.private_routes.reference_dictionary;

  const refdictionaryPageUrl = selectedDictionary
    ? getReferenceDictionaryDetailPageUrl(`${selectedDictionary}`)
    : refdictionaryListingUrl;

  const isAllFieldsArePopulated = specifyRefDictFieldValues?.every(
    (item: { source_field: string }) => item?.source_field
  );

  // because we want to show error only once
  const isErrorExists =
    !isValid && isAllFieldsArePopulated && errors?.specify_fields?.length;

  return (
    <HierarchyFieldStyled>
      <SelectField
        control={control}
        setValue={setValue}
        options={options || []}
        name={name}
        placeholder="Select"
        width="512px"
        propOnChange={onChange}
        isAllowClear={false}
        allowClear={false}
        showArrow
        showSearch
        filterOption={selectFilterOption}
      />

      <OpenDictView>
        <StyledLink to={refdictionaryPageUrl} target="_blank">
          Open reference dictionary{" "}
        </StyledLink>

        {redirectIcon("8", "8")}
      </OpenDictView>

      <>
        {isSelectedDictionaryHierarchial && !!fieldsData?.length && (
          <>
            <Flex>
              <InputLabel className="first-label">Source Fields</InputLabel>
              <InputLabel className="second-label">Reference Fields</InputLabel>
            </Flex>
            {fieldsData?.slice(0, fieldsToRender)?.map((field, index) => (
              <Flex
                key={`field-${field?.toString()}`}
                justifyContent="space-between"
                customClass="fields-content"
              >
                <SelectField
                  control={control}
                  setValue={setValue}
                  options={columnSelectOptions || []}
                  name={`specify_fields.${index}.source_field`}
                  placeholder="Select"
                  showSearch
                  width="235px"
                  isAllowClear={false}
                  allowClear={false}
                  filterOption={selectFilterOption}
                />

                <VerticalDividerStyled
                  width="10px"
                  height="2px"
                  className="vertical-divider"
                />

                <InputField
                  control={control}
                  name={`specify_fields.${index}.reference_field_name`}
                  disabled
                  width="235px"
                />

                {fieldsData?.length > 1 && (
                  <LinkButton
                    onClick={removeField(index)}
                    className="remove-field-btn"
                  >
                    {lightCrossIcon("10px", "10px")}
                  </LinkButton>
                )}
              </Flex>
            ))}

            {isErrorExists && (
              <Flex justifyContent="flex-end">
                <span className="error">
                  At least one source field must be selected column
                </span>
              </Flex>
            )}
          </>
        )}

        {!!dictListKeys?.length && (
          <DescriptionWrapperStyled width={width}>
            {dictListLoading ? (
              <SpinnerLoaderStyled>
                <Spin size="small" />
              </SpinnerLoaderStyled>
            ) : (
              <>
                <div>
                  Hierarchy:
                  {dictListKeys?.map(
                    (field, fieldIndex) =>
                      ` ${
                        field?.DATA_HEADER_CD
                          ? field?.DATA_HEADER_CD
                          : field?.DATA_HEADER_VALUE
                      } ${fieldIndex < dictListKeys?.length - 1 ? ">" : ""} `
                  )}
                </div>
                <Divider className="divider" />
                <Flex columnGap={16} alignItems="flex-start">
                  {dictListKeys?.map((field) => {
                    const { DATA_HEADER_VALUE = "", DATA_HEADER_CD = "" } =
                      field || {};
                    return (
                      <Flex
                        direction="column"
                        key={`hierarchy-field-${field?.TBL_FIELD_CD}`}
                        alignItems="flex-start"
                      >
                        <div>
                          {DATA_HEADER_CD}
                          {DATA_HEADER_VALUE && `(${DATA_HEADER_VALUE})`}
                        </div>

                        <div>
                          {dictListValues
                            ?.slice(0, 5)
                            ?.map((dictMapping, dictIndex) => {
                              const dictionaryCode =
                                dictMapping?.[
                                  field?.DATA_HEADER_CD as keyof typeof dictMapping
                                ];

                              const dictionaryDescription =
                                dictMapping?.[
                                  field?.DATA_HEADER_VALUE as keyof typeof dictMapping
                                ] || "";

                              return (
                                <div
                                  key={`dict-mapping-key-values-${dictIndex}`}
                                >
                                  {dictionaryCode}
                                  {dictionaryDescription &&
                                    ` (${dictionaryDescription})`}
                                  <br />
                                </div>
                              );
                            })}
                          {lengthOfDictListValues > 5 && "..."}
                        </div>
                      </Flex>
                    );
                  })}
                </Flex>
              </>
            )}
          </DescriptionWrapperStyled>
        )}
      </>
    </HierarchyFieldStyled>
  );
};

export default DictionaryField;
