import styled from "styled-components";

export const ReviewCaddiFeedbacksModalStyled = styled.div`
  height: calc(100vh - 56px);
  overflow: auto;
  display: flex;

  .review-caddi-feedback-content-sec {
    width: 75%;
    border-left: 1px solid #e8e8e8;
  }

  iframe {
    border: none;
    height: calc(100% - 89px);
    width: 100%;
  }
`;
