import {
  AddUniqueValuesFormApiDataType,
  AddUniqueValuesFormType,
} from "./adduniquevaluesform.types";

export const uniquevaluesFormDataToApiData = (
  formData: AddUniqueValuesFormType
): AddUniqueValuesFormApiDataType => {
  return {
    col_ids: formData?.columns?.map((column) => Number(column)),
  };
};
