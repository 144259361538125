export const exitOnboardinTutorial = (): void => {
  try {
    window.close();
    // if (localStorage?.getItem("window")) {
    //   console.log("clickes");
    //   // eslint-disable-next-line no-eval
    //   const func = eval(localStorage?.getItem("window") || "");
    //   func?.();

    // }
  } catch (e) {
    console.log("", e);
  }
};
