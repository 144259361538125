import { useCallback, useMemo } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import Checkbox from "../../../checkbox";
import { MultiSelectioncheckBoxPopupContentStyled } from "./multiselectioncheckboxpopupcontent.styles";

import { MultiSelectioncheckBoxPopupContentProps } from "./multiselectioncheckboxpopupcontent.types";

const MultiSelectioncheckBoxPopupContent = (
  props: MultiSelectioncheckBoxPopupContentProps
): JSX.Element => {
  const {
    options = [],
    isEveryItemselected = false,
    isAnyItemSelected = false,
    onChange,
  } = props;

  const allOption = useMemo(
    () => options?.find((option) => option?.isAllOption),
    [options?.length]
  );

  const onCheckboxClick = useCallback(
    (clickedOption) => (e: CheckboxChangeEvent): void => {
      const isChecked = e?.target?.checked;

      if (clickedOption?.isAllOption) {
        onChange?.(isChecked ? [clickedOption?.value] : []);
      } else {
        const updatedOptions = options?.map((option) =>
          option?.value === clickedOption?.value
            ? { ...option, checked: isChecked }
            : option
        );

        const isAllOptionsWithoutAllChecked = updatedOptions
          ?.filter((option) => !option?.isAllOption)
          ?.every((option) => option?.checked);

        const selectedOptionsList = isAllOptionsWithoutAllChecked
          ? [allOption?.value || ""]
          : updatedOptions
              ?.filter((option) => option?.checked && !option?.isAllOption)
              ?.map((option) => option?.value);

        onChange?.(selectedOptionsList);
      }
    },
    [onChange, options]
  );

  return (
    <MultiSelectioncheckBoxPopupContentStyled>
      {options?.map((option, index) => {
        return (
          <>
            <div
              className="multi-selection-checkboxes-item"
              key={`${option?.value}-${index}`}
            >
              <Checkbox
                width="16px"
                height="16px"
                checked={
                  (option?.isAllOption && isAnyItemSelected) || option?.checked
                }
                {...(option?.isAllOption && {
                  indeterminate: isAnyItemSelected && !isEveryItemselected,
                })}
                onChange={onCheckboxClick(option)}
              >
                <div className="option-label-container">
                  <div className="label">
                    <span>{option?.label}</span>

                    {option?.labelSuffix && (
                      <span className="label-suffix">
                        {option?.labelSuffix}
                      </span>
                    )}
                  </div>
                  <div className="count">{`(${option?.count})`}</div>
                </div>
              </Checkbox>
            </div>
            {option?.isAllOption && <div className="horizontal-divider" />}
          </>
        );
      })}
    </MultiSelectioncheckBoxPopupContentStyled>
  );
};

export default MultiSelectioncheckBoxPopupContent;
