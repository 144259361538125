export const DEFAULT_ADMIN_ACCESS_ENABLED_OBJS = [
  {
    name: "Account Settings",
    value: "account_settings",
    url: "/account-settings",
    selectedValue: "NOA",
  },
  {
    name: "Tags",
    value: "tags",
    url: "/tagsets",
    selectedValue: "NOA",
  },
  {
    name: "Data Import",
    value: "data_import",
    url: "/data-import",
    selectedValue: "NOA",
  },
  {
    name: "Data Sources",
    value: "data_sources",
    url: "/sources",
    selectedValue: "NOA",
  },
  {
    name: "User Groups",
    value: "user_groups",
    url: "/user-groups",
    selectedValue: "NOA",
  },
  {
    name: "User Roles",
    value: "user_roles",
    url: "/user-roles",
    selectedValue: "NOA",
  },
  {
    name: "Users",
    value: "users",
    url: "/users",
    selectedValue: "NOA",
  },
  {
    name: "Data Domains",
    value: "data_domains",
    url: "/data_domains",
    selectedValue: "NOA",
  },
  {
    name: "Data Sub-Domains",
    value: "data_sub_domains",
    url: "/sub_domains",
    selectedValue: "NOA",
  },
  {
    name: "Jobs",
    value: "jobs",
    url: "/jobs",
    selectedValue: "NOA",
  },
  {
    name: "Job Executions",
    value: "job_executions",
    url: "/executions",
    selectedValue: "NOA",
  },
  {
    name: "Reference Dictionary",
    value: "reference_dictionary",
    url: "/reference-dictionary",
    selectedValue: "NOA",
  },
  {
    name: "Usage Analytics",
    value: "usage_analytics",
    url: "/analytics",
    selectedValue: "NOA",
  },
];
