import styled from "styled-components";

export const QueryResultsChartStyled = styled.div<{
  isChatPage: boolean;
  isQuerySettingsVisible: boolean;
}>`
  position: relative;
  margin-top: ${({ isChatPage }): string => (isChatPage ? "0px" : "0px")};
  width: 100%;
  overflow-x: hidden;
  max-width: 99%;

  .ant-card-body {
    width: 100%;
    overflow-x: hidden;

    .recharts-legend-wrapper {
      left: 91px;
    }
  }
`;
