// <--- Import Statements Start --->
import { useCallback, useMemo, useState } from "react";
// <--- Import Statements End --->

// <--- Components Start --->
import SectionWithBody from "../../../../components/sectionwithbody";
import MatchingEntitiesGrid from "../matchingentitiesgrid/matchingentitiesgrid";

import { EntitiesSectionPagination } from "./matchingentitiessection.components";
// <--- Components End --->

// <--- Styles Start --->
import { MatchingEntitiesSectionStyled } from "./matchingentitiessection.styles";
// <--- Styles End --->

// <--- Types Start --->
import { MatchingEntitiesSectionProps } from "./matchingentitiessection.types";
// <--- Types End --->

const MatchingEntitiesSection = (
  props: MatchingEntitiesSectionProps
): JSX.Element => {
  const { fields, onUpdate } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 2;
  const totalPages = Math.ceil(fields?.length / pageSize) || 1;

  const nextPage = useCallback(() => {
    setCurrentPage((s) => s + 1);
  }, []);
  const previousPage = useCallback(() => {
    setCurrentPage((s) => s - 1);
  }, []);

  const paginatedFields = useMemo(() => {
    const start = pageSize * (currentPage - 1);
    const end = pageSize * currentPage;
    return fields?.slice(start, end);
  }, [fields, currentPage]);

  return (
    <MatchingEntitiesSectionStyled>
      <SectionWithBody
        headingProps={{
          title: "Matching Entities",
          className: "matching-entities-section",
          fontSize: "17px",
          fontWeight: "normal",
          rightSec: (
            <EntitiesSectionPagination
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={previousPage}
            />
          ),
          isShowIcon: false,
        }}
      >
        <MatchingEntitiesGrid fields={paginatedFields} onUpdate={onUpdate} />
      </SectionWithBody>
    </MatchingEntitiesSectionStyled>
  );
};

export default MatchingEntitiesSection;
