export const ANL_DEF_PAR_KEYS = {
  PROPERTY: "Property",
  VALUE: "Value",
  DETAILS: "Details",
};

export const ANL_DEF_PAR_KEYS_LIST = [
  ANL_DEF_PAR_KEYS.PROPERTY,
  ANL_DEF_PAR_KEYS.VALUE,
  ANL_DEF_PAR_KEYS.DETAILS,
];
