import { Input } from "antd";
import styled from "styled-components";
import { InputProps } from "./inputs.types";

export const SearchInputStyled = styled(Input.Search)<InputProps>`
  width: ${(props): string => props?.width || "100%"};

  .ant-input {
    padding: 5px 4px;
    color: ${(props): string => props.theme.textColors.BLACK_COLOR};
    height: ${(props): string => props?.height || "34px"};
    :hover {
      border-color: ${(props): string => props.theme.borderColors.DARK_GREY_15};
      box-shadow: none;
    }
    :focus {
      border-color: ${(props): string => props.theme.borderColors.YELLOW_RD};
      color: ${(props): string => props.theme.textColors.BLACK_COLOR};
      box-shadow: none;
    }
  }
  .ant-input-disabled {
    background: ${(props): string => props.theme.bgColors.WHITE_COLOR};
    opacity: 0.5;
    border: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_GREY_40};
    color: ${(props): string => props.theme.textColors.BLACK_17};
  }
  .ant-input-group-addon {
    background: ${(props): string => props.theme.bgColors.BLUE_25};
    opacity: ${(props): any => props?.disabled && "0.5"};
    cursor: ${(props): any => props?.disabled && "not-allowed"};
    button {
      background: ${(props): string => props.theme.bgColors.BLUE_25};
      border: none;
      .anticon-search {
        color: ${(props): string => props.theme.textColors.WHITE_COLOR};
      }

      :hover {
        background: ${(props): string => props.theme.bgColors.BLUE_25};
      }

      ::after {
        animation: unset;
      }
    }
  }
`;

export const TextAreaStyled = styled(Input.TextArea)<InputProps>`
  .ant-input {
    width: ${(props): string => props?.width || "100%"};
    height: ${(props): string => props?.height || "28px"};
    border-radius: 5px;
    color: ${(props): string => props.theme.textColors.BLACK_COLOR};

    :focus {
      border-color: ${(props): string => props.theme.borderColors.YELLOW_RD};
      color: ${(props): string => props.theme.textColors.BLACK_COLOR};
      box-shadow: none;
    }
    :hover {
      border-color: ${(props): string => props.theme.borderColors.DARK_GREY_20};
      box-shadow: none;
    }
  }
`;

export const InputStyled = styled(Input)<InputProps>`
  &.ant-input,
  &.ant-input-affix-wrapper {
    width: ${(props): string => props?.width || "100%"};
    height: ${(props): string => props?.height || "34px"};
    border: 1px solid
      ${(props): string => props.theme.borderColors.LIGHT_GREY_40};
    border-radius: 5px;
    color: ${(props): string => props.theme.textColors.BLACK_COLOR};
    font-size: 13px;
    :not(:disabled) {
      :hover {
        border-color: ${(props): string =>
          props.theme.borderColors.DARK_GREY_15};
        box-shadow: none;
      }

      :focus {
        border-color: ${(props): string => props.theme.borderColors.YELLOW_RD};
        color: ${(props): string => props.theme.textColors.BLACK_COLOR};
        box-shadow: none;
      }
    }

    input {
      font-size: ${(props): any => props?.fontSize || "16px"};

      ::placeholder {
        font-size: 13px;
      }
    }
  }

  &.ant-input-affix-wrapper-focused {
    border-color: ${(props): string =>
      props.theme.borderColors.YELLOW_RD} !important;
    color: ${(props): string => props.theme.textColors.BLACK_COLOR};
    box-shadow: none;
  }
`;
