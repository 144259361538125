import styled, { FlattenSimpleInterpolation, css } from "styled-components";

const loadingCss = css`
  @keyframes loading {
    0% {
      left: 0;
      width: 1px;
    }
    50% {
      left: 40%;
      width: 20%;
    }
    100% {
      left: 100%;
      width: 1px;
    }
  }
`;

export const commonCssForLoading = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
`;

const fullScreenLoadingStyle = (
  isSideBarCollapsed?: boolean,
  isChatPage?: boolean
): FlattenSimpleInterpolation => css`
  position: fixed;
  height: 100vh;
  left: ${isSideBarCollapsed ? "43px" : "190px"};
  top: ${isChatPage ? "" : "45px"};
`;

export const LoadingStyledForModal = styled.div<{
  isFullScreenLoading?: boolean;
  isSideBarCollapsed?: boolean;
  isChatPage?: boolean;
}>`
  ${commonCssForLoading};
  height: 100%;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  ${({
    isFullScreenLoading,
    isSideBarCollapsed,
    isChatPage,
  }): FlattenSimpleInterpolation | string =>
    isFullScreenLoading
      ? fullScreenLoadingStyle(isSideBarCollapsed, isChatPage)
      : ""}
`;

export const blankSlateAnimationCss = css`
  ::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.2) 100%
    );
    animation: loading 0.8s infinite;
  }
`;

export const BlankSlateLoading = styled.div<{ showAnimation?: boolean }>`
  position: relative;
  ${loadingCss}
  ${({ showAnimation }): any => showAnimation && blankSlateAnimationCss}
`;

export const SpinnerLoading = styled.div<{
  showAnimation?: boolean;
  spinnerHeight?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ spinnerHeight }): string => spinnerHeight || "100vh"};
`;

export const AlertContainerStyled = styled.div`
  .ant-alert {
    /* position: absolute; */
    padding: 4px 14px;
    font-size: 16px;
    background: ${({ theme }): string => theme.bgColors.RED_13};
    border: 1px solid ${({ theme }): string => theme.borderColors.RED_13};
    .ant-alert-content {
      .ant-alert-message {
        color: ${({ theme }): string => theme.textColors.RED_12};
        /* font-size: 16px; */
      }
    }
    .ant-alert-close-icon {
      top: 4px;
      svg {
        path {
          color: ${({ theme }): string => theme.textColors.RED_12};
        }
      }
    }
  }
`;

export const BlankSlateLoadingForModal = styled.div`
  position: relative;
  ${loadingCss}
  ${blankSlateAnimationCss}
`;

export const PageLoadingStyled = styled.p`
  &.simple-component-error-alert {
    position: fixed;
    max-width: 310px;
    opacity: 1;
    top: 77px;
    right: 50%;
    transform: translate(50%, 0%);
    z-index: 100;
    padding: 11px 47px 12px 17px;
    color: ${({ theme }): string => theme.textColors.RED_12};
    background: ${({ theme }): string => theme.bgColors.RED_13};
    font-size: 14px;
    box-shadow: 0 3px 6px 0 rgba(217, 63, 60, 0.24);
    border-radius: 4px;
    overflow-wrap: break-word;
    white-space: normal;
    text-align: left;

    .ant-btn {
      position: absolute;
      right: 16px;
      top: 10px;
      padding: 0;
      svg {
        width: 12px;
        path {
          fill: ${({ theme }): string => theme.textColors.RED_12};
          stroke: ${({ theme }): string => theme.textColors.RED_12};
        }
      }
    }
  }
`;

export const EmptyTableContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
