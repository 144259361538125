import { AxiosResponse } from "axios";
import {
  ErdEdgeIntoResponseType,
  ReferenceTablesListResponse,
  ReferenceTablesListReturnType,
} from "./erddiagramparser.types";
import { ErdEdgeInfoType } from "../tablepage/tablepageparser.types";

export function getReferenceTablesList({
  data = [],
}: AxiosResponse<ReferenceTablesListResponse>): ReferenceTablesListReturnType {
  return (
    data?.map((table) => {
      return {
        table_id: table?.NODE_ID || 0,
        table_name: table?.NODE_TITLE || "",
      };
    }) || []
  );
}

export function getErdEdgeInfo({
  data = [],
}: AxiosResponse<ErdEdgeIntoResponseType[]>): ErdEdgeInfoType {
  const edgeFieldsData = data?.map((field) => {
    return {
      field_id: field?.CONS_ID || 0,
      source_field_id: field?.COL_ID || 0,
      source_field_name: field?.COL_NAME || "",
      target_field_id: field?.REF_COL_ID || 0,
      target_field_name: field?.REF_COL_NAME || "",
      field_postion: Number(field?.CONS_COL_POSITION) || 0,
      source_col_type: field?.COL_DATA_TYPE_ID || "STR",
      target_col_type: field?.REF_COL_TYPE || "STR",
    };
  });

  const edge = data?.[0] || {};

  const edgeInfo = {
    sourceTable: {
      table_id: edge?.TBL_ID || 0,
      table_name: edge?.TBL_NAME || "",
      description: edge?.TBL_USER_DESC || "",
    },
    referenceTable: {
      table_id: edge?.REF_TBL_ID || 0,
      table_name: edge?.REF_TBL_NAME || "",
      description: edge?.REF_TBL_DESC || "",
    },

    fields: edgeFieldsData || [],

    status: edge?.CONS_STATUS || "confirmed",
    statusId: edge?.CONS_STATUS_ID || "CNF",
    edgeSourceId: edge?.CONS_SRC_ID || "",
    cardinality: edge?.CONS_REL_TYPE || "1:1",
    edgeUniqueId: edge?.CONS_UNQ_ID || 0,
    edgeName: edge?.CONS_NAME || "",
    sourceId: edge?.SRC_ID || 0,
  };

  return edgeInfo;
}
