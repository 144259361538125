import styled from "styled-components";

export const RangeInputStyled = styled.div`
  display: flex;

  .left-sec,
  .right-sec {
    border: solid 1px ${({ theme }): string => theme?.borderColors?.GREY_18};
    background-color: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    height: 40px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-top: 4px;
    }
  }
  .left-sec {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .right-sec {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .input-sec {
    .precision-input {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: unset;
      border-left: unset;
      border-right: unset;
      height: 40px;
      width: 50px;
      text-align: center;
      font-family: OpenSansSemiBold;
    }
  }
`;
