import { X_AXIS_DATA_KEY } from "../constants";
import {
  formatProfilingDate,
  splitProfilingRangeVal,
} from "../pages/analysisdetailpage/analisisdetailpage.utils";

import { ColumnChartDataType, ColumnDataType } from "../parsers";
import { COLUMN_VISUAL_DIST_DATA_KEY } from "../parsers/columnparsers";

import { checkDataTypeOfColumn } from "./checkdatatypeofcolumn";
import { isDateInRange } from "./isdateinrange";

import { isNumberInRange } from "./isnumberinrange";

export const getProfilingChartData = (
  colDataType: ColumnDataType,
  chartData: ColumnChartDataType,
  selectedCell?: {
    value: string;
    index?: number;
    col_id?: string;
  },
  isAddMode?: boolean
): {
  numberChartDataProf: ColumnChartDataType;
  dateChartProf: ColumnChartDataType;
  stringChartDataProf: ColumnChartDataType;
} => {
  const { isDateColumn, isNumberCol, isStringColumn } = checkDataTypeOfColumn(
    colDataType || "BLB"
  );

  const defaultObject = {
    [X_AXIS_DATA_KEY]: isNumberCol
      ? `${parseFloat(selectedCell?.value || "")}`
      : isDateColumn
      ? formatProfilingDate(selectedCell?.value || "")
      : selectedCell?.value || "",

    [COLUMN_VISUAL_DIST_DATA_KEY]: 1,
    fill: "#4894ca",
    isCustomData: true,
  };

  const numberChartData = isNumberCol
    ? chartData?.filter((item) => {
        const { max = 0, min, isBothExists } = splitProfilingRangeVal(
          item?.[X_AXIS_DATA_KEY]
        );
        return isBothExists
          ? isNumberInRange(selectedCell?.value || "", min, max)
          : Number(selectedCell?.value) === Number(min);
      })
    : [];

  const numberChartDataProf = isNumberCol
    ? isAddMode && numberChartData?.length
      ? chartData
      : isAddMode && !numberChartData?.length
      ? [...chartData, defaultObject]
      : numberChartData?.length
      ? numberChartData
      : [defaultObject]
    : [];

  const dateChart = isDateColumn
    ? chartData?.filter((item) => {
        const { max, min, isBothExists } = splitProfilingRangeVal(
          item?.[X_AXIS_DATA_KEY]
        );
        return isBothExists
          ? isDateInRange(
              formatProfilingDate(selectedCell?.value || ""),
              formatProfilingDate(min),
              formatProfilingDate(max)
            )
          : formatProfilingDate(selectedCell?.value || "") ===
              formatProfilingDate(min);
      })
    : [];

  const dateChartProf = isDateColumn
    ? isAddMode && dateChart?.length
      ? chartData
      : isAddMode && !dateChart?.length
      ? [...chartData, defaultObject]
      : dateChart?.length
      ? dateChart
      : [defaultObject]
    : [];

  const stringChartData = isStringColumn
    ? chartData?.filter(
        (item) => item?.[X_AXIS_DATA_KEY] === selectedCell?.value
      )
    : [];

  const stringChartDataProf = isStringColumn
    ? isAddMode && stringChartData?.length
      ? chartData
      : isAddMode && !stringChartData?.length
      ? [...chartData, defaultObject]
      : stringChartData?.length
      ? stringChartData
      : [defaultObject]
    : [];

  return {
    numberChartDataProf,
    dateChartProf,
    stringChartDataProf,
  };
};
