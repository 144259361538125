export function updateArrayWithSameSequence(
  arr: any[],
  keyName: string,
  compareValue: string,
  updatedObject: Object
): any[] {
  const currentItem = arr.filter(
    (item: any) => item[keyName] === compareValue
  )[0];
  const currentItemIndex = arr?.findIndex(
    (item: any) => item[keyName] === compareValue
  );

  return [
    ...arr.slice(0, currentItemIndex),
    { ...currentItem, ...updatedObject },
    ...arr.slice(currentItemIndex + 1, arr?.length),
  ];
}
