import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";

export const TransferCompStyled = styled.div`
  display: flex;
  .source {
    width: 120px;
    overflow: hidden;
    margin-left: -3px;
  }

  .name {
    width: 140px;
    overflow: hidden;
    margin-right: 20px;
  }

  .arrow {
    margin-right: 5px;
    margin-left: 5px;
    color: ${({ theme }): string => theme.textColors.GREY_34};
  }

  .text {
    ${ellipsis}
  }
`;

export const RightTitleWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;

  .selection-count {
    margin-left: 4px;
    color: ${({ theme }): string => theme.textColors.GREY_18};
  }
`;
