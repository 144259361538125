// <--- Styles Start --->
import styled from "styled-components";
// <--- Styles End --->

export const AnalysisDetailPageHeaderStyled = styled.div<{
  sideBarPosition: "collapsed" | "not-collapsed";
}>`
  .header-content-sec {
    padding: 14.4px 20px 0;
  }
`;
