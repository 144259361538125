import { ChartDebugConfigStyled } from "./chartdebugconfig.styles";

import { ChartDebugConfigProps } from "./chartdebugconfig.types";

const ChartDebugConfig = (props: ChartDebugConfigProps): JSX.Element => {
  const { chartConfig, colPreprocessInfo } = props;

  const {
    attribute_count: attributeCount,
    chart_type: chartType,
    chart_x_axis: chartXAxis,
    chart_y_axises: chartYAxises = [],
    column_count: columnCount,
    datetime_count: datetimeCount,
    metric_count: metricCount,
    row_count: rowCount,
    chart_x2_axis_side_by_side: chartX2AxisSideBySide,
    attribute_columns: attributeColumns = [],
    datetime_columns: dateTimeColumns = [],
    metric_columns: metricColumns = [],
    ist_metric_median: istMetricMedian,
    second_metric_median: secondMetricMedian,
    ratio_of_ist_second_metric: ratioOfFirstSecondMetric,
    date_aggregated_by: dateAggregatedBy = "",
    max_date: maxDate = "",
    min_date: minDate = "",
    data,
  } = chartConfig || {};

  const { sorted_data_by: sortedDataBy } = colPreprocessInfo || {};

  return (
    <ChartDebugConfigStyled>
      <div className="title">Chart Debug Config</div>

      <div className="content-wrapper">
        {!!rowCount && (
          <div className="chart-debug-config-item">
            <span className="key">Row Count: </span>
            <span className="value">{rowCount}</span>
          </div>
        )}

        {!!columnCount && (
          <div className="chart-debug-config-item">
            <span className="key">Column Count: </span>
            <span className="value">{columnCount}</span>
          </div>
        )}

        {!!metricCount && (
          <div className="chart-debug-config-item">
            <span className="key">Metric Count: </span>
            <span className="value">
              {metricCount} (
              {metricColumns?.map((col) => col?.column_name)?.join(", ")})
            </span>
          </div>
        )}

        {!!attributeCount && (
          <div className="chart-debug-config-item">
            <span className="key">Attribute Count: </span>
            <span className="value">
              {attributeCount} (
              {attributeColumns?.map((col) => col?.column_name)?.join(", ")})
            </span>
          </div>
        )}

        {!!datetimeCount && (
          <div className="chart-debug-config-item">
            <span className="key">Date Time Count: </span>
            <span className="value">
              {datetimeCount} (
              {dateTimeColumns?.map((col) => col?.column_name)?.join(", ")})
            </span>
          </div>
        )}

        {!!sortedDataBy && (
          <div className="chart-debug-config-item">
            <span className="key">Sorted Data By: </span>
            <span className="value">{sortedDataBy}</span>
          </div>
        )}

        {!!chartType && (
          <div className="chart-debug-config-item">
            <span className="key">Chart Type: </span>
            <span className="value">{chartType}</span>
          </div>
        )}

        {!!chartXAxis && (
          <div className="chart-debug-config-item">
            <span className="key">Chart X Axis: </span>
            <span className="value">{chartXAxis}</span>
          </div>
        )}

        {!!chartX2AxisSideBySide && (
          <div className="chart-debug-config-item">
            <span className="key">Chart X2 Axis Side By Side: </span>
            <span className="value">{chartX2AxisSideBySide}</span>
          </div>
        )}

        {!!chartYAxises?.length &&
          chartYAxises?.map((yAxis, index) => (
            <div
              className="chart-debug-config-item"
              key={`list-of-yaxis-${yAxis?.name}`}
            >
              <span className="key">Chart Y{index + 1} Axis: </span>
              <span className="value">{yAxis?.name}</span>
            </div>
          ))}

        {!!istMetricMedian && (
          <div className="chart-debug-config-item">
            <span className="key">Ist metric median: </span>
            <span className="value">{istMetricMedian}</span>
          </div>
        )}

        {!!secondMetricMedian && (
          <div className="chart-debug-config-item">
            <span className="key">Second metric median: </span>
            <span className="value">{secondMetricMedian}</span>
          </div>
        )}

        {!!ratioOfFirstSecondMetric && (
          <div className="chart-debug-config-item">
            <span className="key">Ratio of first and second metric: </span>
            <span className="value">{ratioOfFirstSecondMetric}</span>
          </div>
        )}

        {!!minDate && (
          <div className="chart-debug-config-item">
            <span className="key">Min Date: </span>
            <span className="value">{minDate}</span>
          </div>
        )}

        {!!maxDate && (
          <div className="chart-debug-config-item">
            <span className="key">Max Date: </span>
            <span className="value">{maxDate}</span>
          </div>
        )}

        {!!dateAggregatedBy && (
          <div className="chart-debug-config-item">
            <span className="key">Date Aggregted By: </span>
            <span className="value">{dateAggregatedBy}</span>
          </div>
        )}

        {!!data?.labels?.length && (
          <div className="chart-debug-config-item">
            <span className="key">Data Points Count In Chart: </span>
            <span className="value">{data?.labels?.length}</span>
          </div>
        )}
      </div>
    </ChartDebugConfigStyled>
  );
};

export default ChartDebugConfig;
