import styled from "styled-components";

export const StepLineChartStyled = styled.div`
  .legend-wrapper {
    margin-left: unset;
  }
`;

export const ToolTipContentWrapperStyled = styled.div<{ isAlerting?: boolean }>`
  display: flex;
  align-items: flex-star;
  justify-content: flex-start;
  row-gap: 8px;
  flex-direction: column;

  background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  border: 1px solid
    ${({ theme, isAlerting }): string =>
      isAlerting ? theme.bgColors.RED : theme.bgColors.GREY_30};
  padding: 12px;
`;
