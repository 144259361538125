import styled from "styled-components";

export const FieldLabel = styled.div`
  color: ${({ theme }): string => theme.textColors.GREY_40};
  font-size: 14px;
  font-family: OpenSansSemiBold;
  margin: 10px 0px;
`;

export const MetricInputContainer = styled.div`
  width: 45%;
  max-width: 420px;

  .error {
    font-size: 10px;
    color: ${({ theme }): string => theme.textColors.RED_12};
    text-align: left;
    font-family: OpenSansSemiBold;
    margin: 5px 0px;
  }
`;

export const InputWithAddButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .input-field-container {
    width: 100%;
    margin-right: 12px;
  }
`;
