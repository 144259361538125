import { useCallback } from "react";
import { useHistory } from "react-router";
import { useRequestWithMethod } from "../../../../api";

import { useCancelModal, useGetAppState } from "../../../../customhooks";
import Button from "../../../button";

import Flex from "../../../flex";
import StateHandler from "../../../statehandler/statehandler";

import { CancelScheduleModalStyled } from "./cancelschedulemodal.styles";
import { CancelScheduleModalPropsType } from "./cancelschedulemodal.types";

const CancelScheduleModal = (): JSX.Element => {
  const onCancel = useCancelModal();
  const history = useHistory();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    schId,
    isFromListing,
    propsOnSuccess,
    actionType,
  } = modalProps as CancelScheduleModalPropsType;

  const isAbortAction = actionType === "ABR";
  const isCancelAction = actionType === "CNL";

  const onSucess = useCallback((response): void => {
    onCancel();
    propsOnSuccess?.(response);
  }, []);

  const {
    onExecuteRequest: onExecuteRequestCancel,
    error: errorCancel,
    isLoading: isLoadingCancel,
  } = useRequestWithMethod("cancel_run_profiling", undefined, true, onSucess);

  const onScheduleCancel = (): void => {
    const jobConfig = {
      sch_ids: schId,
      sch_status: isCancelAction ? "CANCEL" : "ABORT",
    };
    onExecuteRequestCancel([jobConfig]);
  };

  // WE NEED TO REFACTOR THIS COMP LOGIC

  return (
    <StateHandler isFetching={isLoadingCancel} error={errorCancel} isModal>
      <CancelScheduleModalStyled>
        <div className="description">
          <div>
            {isFromListing
              ? `This will ${
                  isAbortAction ? "abort" : "cancel"
                } the selected executions. Records that have already been used will still show as refreshed, however remaining records will not be refreshed. Are you sure you want to proceed?`
              : `This will ${
                  isAbortAction ? "abort" : "cancel"
                } the execution. Are you sure you want to proceed?`}
          </div>
        </div>
        <div className="action-sec">
          <Flex justifyContent="flex-end">
            <Button id="cancel" width="74px" onClick={onCancel}>
              Never Mind
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              onClick={onScheduleCancel}
            >
              {isAbortAction ? "Abort" : "Cancel"}
            </Button>
          </Flex>
        </div>
      </CancelScheduleModalStyled>
    </StateHandler>
  );
};

export default CancelScheduleModal;
