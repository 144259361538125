// <--- Import Statements Start --->
import { yupResolver } from "@hookform/resolvers/yup";
import { Tooltip } from "antd";

import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { useQueryClient } from "react-query";
// // <--- Import Statements End --->

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";
// <--- Constants End --->

// <--- Components Start --->
import { Button } from "../../components";
import {
  CheckboxField,
  InputField,
  SelectField,
  TextAreaField,
} from "../../components/formfields";

import Transfer from "../../components/transfer";
import StateHandler from "../../components/statehandler/statehandler";

import MultiSelectUsersField from "../../components/formfields/multiselectusersfield";
// <--- Components End --->

// <--- Custom Hooks Start --->
import { useCancelModal, useGetAppState } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Services Start --->
import { useRequestWithMethod } from "../../api";
import { useGetUserAndGroups } from "../../api/tablesservice";

import { useGetAllGlossaryCategoriesForDomains } from "../../api/glossarycategoriesservice/glossarycategoriesservice";
// <--- Services End --->

// <--- Styles Start --->
import { FormItemStyled, FormStyled } from "../../components/form";
import {
  AddGlossaryDomainFormStyled,
  CheckboxTooltipWrapperStyled,
  WorkflowFieldsWrapperStyled,
} from "./addglossarydomainform.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { infoIcon } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  TranferStateType,
  TransferRecordType,
} from "../../components/transfer/transfer.types";

import {
  AddGlossaryDomainFormProps,
  AddGlossaryDomainType,
} from "./addglossarydomainform.types";

import { GlossaryDomainsGridColumnType } from "../../pages/glossarydomainspage/glossarydomainspage.types";
import { NodeType } from "../../app.types";
// <--- Types End --->

// <--- Utils Start --->
import { addGlossaryDomainSchema } from "../../utils/schemas/glossarydomainschemas";
import {
  getUniqueUsersForGlossary,
  getNodeName,
  getPostLoginData,
} from "../../utils";
import { getGlobalFiltersData } from "../../utils/getglobalfiltersdata";
import { GlobalFiltersData } from "../../parsers/globalfiltersparser/globalfiltersparser.types";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants";
import { useGetWorkflowTemplates } from "../../api/termservice/termservice";
import { CheckboxWrapperStyled } from "../../components/form/form.styles";
import {
  DATA_QUALITY_NOT_ENABLED,
  DOMAIN_GOVERNANCE_NOT_ENABLED,
  ENABLE_WORKFLOW_TOOLTIP,
  GOVERNANCE_NOT_ENABLED_ON_NEW_DOMAINS,
} from "../../constants/formconstants";
// <--- Utils End --->

const AddGlossaryDomainForm = (
  props: AddGlossaryDomainFormProps
): JSX.Element => {
  const { isEditMode } = props;

  const globalFiltersData = getGlobalFiltersData();
  const { user_info: userInfo = {} } = getPostLoginData();

  const {
    is_gov_enabled_for_new_dmn: isGovernanceEnabledForNewDomain = false,
    is_dq_enabled: isDqEnabled,
  } = userInfo;

  const { selected_filters: selectedFilters } = globalFiltersData || {};
  const { domain: domainGlobalFilters } = selectedFilters || {};
  const { filters_list: domainsGlobalFiltersList = [] } =
    domainGlobalFilters || {};

  const {
    parsedData: parsedGlossaryCategories = [],
    isLoading: isLoadingCategories,
    error: errorCategories,
  } = useGetAllGlossaryCategoriesForDomains();

  const {
    parsedData: parsedAdminEditorUsers,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useGetUserAndGroups(true, true, true);

  const {
    parsedData: parsedUsers,
    isLoading: isLoadingUsersAndGroups,
    error: errorUsersAndGroups,
  } = useGetUserAndGroups(true);

  const {
    parsedData: workflowTemplates,
    isLoading: workflowTemplateLoading,
    error: errorWorkflowTemplate,
  } = useGetWorkflowTemplates();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const onCancel = useCancelModal();
  const queryClient = useQueryClient();

  const {
    id = 0,
    name = "",
    description = "",
    data_owners_obj: dataOwnersObj = [],
    data_stewards_obj: dataStewardsObj = [],
    is_workflow_enabled: isWorkflowEnabled = false,
    is_dq_workflow_enabled: isDqWorkflowEnabled = false,
    data_approvers_obj: dataApproversObj = [],
    workflow_id: workflowId,
    categories = [],
    domainSettings,
  } = modalProps as GlossaryDomainsGridColumnType;

  const { is_gov_enabled: isGovEnabled = false } = domainSettings || {};

  const dataOwners = useMemo(() => {
    return dataOwnersObj?.map((user) =>
      getNodeName(user?.name, user?.email, user?.type as NodeType)
    );
  }, [dataOwnersObj]);

  const dataStewards = useMemo(() => {
    return dataStewardsObj?.map((user) =>
      getNodeName(user?.name, user?.email, user?.type as NodeType)
    );
  }, [dataStewardsObj]);

  const dataApprovers = useMemo(() => {
    return (
      dataApproversObj?.map((user) =>
        getNodeName(user?.name, user?.email, user?.type as NodeType)
      ) || []
    );
  }, [dataApproversObj]);

  const parsedCategories = useMemo(() => {
    return parsedGlossaryCategories?.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }, [parsedGlossaryCategories]);

  const categoryIds = useMemo(() => {
    return categories?.map((category) => `${category?.id}`);
  }, [categories]);

  const [state, setState] = useState<TranferStateType>({
    data: parsedCategories,
    targetKeys: isEditMode ? categoryIds : [],
  });

  const workflowOptions = useMemo(() => {
    return workflowTemplates?.length
      ? [
          {
            label: "Select Workflow",
            value: "",
            options: workflowTemplates,
          },
        ]
      : [];
  }, [workflowTemplates]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    setError,
    watch,
  } = useForm<AddGlossaryDomainType>({
    defaultValues: {
      name,
      description,
      data_owners: isEditMode || !!dataOwners.length ? dataOwners : undefined,
      data_stewards:
        isEditMode || !!dataStewards.length ? dataStewards : undefined,
      enable_workflow: isEditMode ? isWorkflowEnabled : undefined,
      is_dq_workflow_enabled: isEditMode ? isDqWorkflowEnabled : undefined,
      approvers: isEditMode || !!dataApprovers.length ? dataApprovers : [],
      wt_id: isEditMode ? workflowId || undefined : undefined,
    },
    resolver: yupResolver(addGlossaryDomainSchema),
    mode: "onChange",
  });

  const { enable_workflow: enableWorkflow } = watch();

  const categoryName = watch("name");
  const excludeApprovers = watch("data_stewards");
  const excludeDataStewards = watch("approvers");

  // APIs Integration
  const onSuccess = useCallback(() => {
    const api = API_CONFIG?.get_all_glossary_domains?.url;
    queryClient.invalidateQueries(api, { fetching: false });

    if (isEditMode) {
      const updatedDomainGlobalFiltersList = domainsGlobalFiltersList?.map(
        (filter) => ({
          ...filter,
          name: filter?.id === id ? categoryName : filter?.name,
        })
      );

      const updatedGlobalFilersData: GlobalFiltersData = {
        ...globalFiltersData,
        selected_filters: {
          ...selectedFilters,
          domain: {
            ...domainGlobalFilters,
            filters_list: updatedDomainGlobalFiltersList,
          },
        },
      };

      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS?.global_filters,
        JSON.stringify(updatedGlobalFilersData || {})
      );
    }

    onCancel();
  }, [modalProps, categoryName, globalFiltersData, id]);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode ? "update_glossary_domain" : "add_glossary_domain",
    isEditMode ? [id] : undefined,
    true,
    onSuccess
  );

  // Callbacks
  const onSubmit = useCallback(
    (values) => {
      const dataOwners = getUniqueUsersForGlossary(
        parsedUsers,
        values?.data_owners,
        "USER_OWNER",
        "DMN"
      );

      const dataStewards = getUniqueUsersForGlossary(
        parsedAdminEditorUsers,
        values?.data_stewards,
        "USER_STEWARD",
        "DMN"
      );

      const dataApprovers = getUniqueUsersForGlossary(
        parsedUsers,
        values?.approvers,
        "USER_APPROVER",
        "DMN"
      );

      onExecuteRequest({
        name: String(values?.name)?.trim() || "",
        description: values?.description || "",
        ownerData: dataOwners || [],
        stewardData: dataStewards || [],
        approverData: values?.enable_workflow ? dataApprovers || [] : undefined,
        wt_id: values?.enable_workflow ? values?.wt_id : undefined,
        catIds: state?.targetKeys?.map((item) => `'${item}'`)?.join(",") || "",
        dq_wf_enbled: values?.is_dq_workflow_enabled || false,
      });
    },
    [state, parsedUsers, parsedAdminEditorUsers]
  );

  const transferData = useMemo(() => {
    return parsedGlossaryCategories.map((item: TransferRecordType) => ({
      ...item,
      description: state.targetKeys?.includes(item?.key)
        ? categoryName
        : categoryIds?.includes(item?.key) &&
          !state.targetKeys?.includes(item?.key)
        ? "Categories"
        : item?.description,
    }));
  }, [categoryName, parsedCategories, state.targetKeys]);

  const isWorkflowDisabled = useMemo(
    () =>
      (!isEditMode && !isGovernanceEnabledForNewDomain) ||
      (isEditMode && !isGovEnabled),
    [isGovEnabled, isEditMode]
  );

  return (
    <StateHandler
      error={
        errorCategories ||
        errorUsers ||
        errorUsersAndGroups ||
        error ||
        errorWorkflowTemplate
      }
      isFetching={
        isLoadingCategories ||
        isLoadingUsers ||
        isLoadingUsersAndGroups ||
        isLoading ||
        workflowTemplateLoading
      }
      isModal
    >
      <AddGlossaryDomainFormStyled>
        <FormStyled
          paddingLeft="334px"
          onFinish={handleSubmit(onSubmit) as any}
          isItemColumnLayout
          className="add-glossary-form"
        >
          <div className="scroll-sec">
            <FormItemStyled label="Name" required>
              <InputField control={control} name="name" />
            </FormItemStyled>

            <FormItemStyled label="Description" required>
              <TextAreaField control={control} name="description" />
            </FormItemStyled>

            <FormItemStyled label="Data Owner">
              <MultiSelectUsersField
                name="data_owners"
                control={control}
                setValue={setValue}
                setError={setError}
                width="333px"
                height="34px"
                maxHeight="165px"
                placeholder="Select one or more users"
                allowClear
              />
            </FormItemStyled>

            <FormItemStyled label="Data Steward">
              <MultiSelectUsersField
                placeholder="Select one or more users"
                name="data_stewards"
                control={control}
                setValue={setValue}
                setError={setError}
                width="333px"
                height="34px"
                maxHeight="165px"
                allowClear
                onlyUsersWithAdminAndEditorRole
                onlyUsers
                excludeUsers={excludeDataStewards}
              />
            </FormItemStyled>

            {/* <CheckboxWrapperStyled>
              <CheckboxField
                name="enable_workflow"
                control={control}
                className="enable-workflow"
                mode="primary"
                disabled={isWorkflowDisabled}
              >
                Enable Workflow
              </CheckboxField>
              <Tooltip
                title={
                  isWorkflowDisabled
                    ? isEditMode
                      ? DOMAIN_GOVERNANCE_NOT_ENABLED
                      : GOVERNANCE_NOT_ENABLED_ON_NEW_DOMAINS
                    : ENABLE_WORKFLOW_TOOLTIP
                }
                placement="right"
                overlayClassName="checkbox-tooltip"
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentNode as HTMLElement
                }
              >
                {infoIcon}
              </Tooltip>
            </CheckboxWrapperStyled> */}
            <CheckboxTooltipWrapperStyled>
              <Tooltip
                title={
                  isWorkflowDisabled
                    ? isEditMode
                      ? DOMAIN_GOVERNANCE_NOT_ENABLED
                      : GOVERNANCE_NOT_ENABLED_ON_NEW_DOMAINS
                    : undefined
                }
                placement="rightTop"
                overlayClassName="domain-checkbox-tooltip"
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentNode as HTMLElement
                }
              >
                <CheckboxWrapperStyled className="extended-checkbox">
                  <CheckboxField
                    name="enable_workflow"
                    control={control}
                    className="enable-workflow"
                    mode="primary"
                    disabled={isWorkflowDisabled}
                  >
                    Enable Metadata Workflow
                  </CheckboxField>

                  <div className="checkbox-desc">
                    Require approval before publishing updates.
                  </div>
                </CheckboxWrapperStyled>
              </Tooltip>
            </CheckboxTooltipWrapperStyled>

            {enableWorkflow && (
              <WorkflowFieldsWrapperStyled>
                <FormItemStyled label="Workflow" required>
                  <SelectField
                    isAllowClear={false}
                    name="wt_id"
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    options={workflowOptions}
                    placeholder="Select"
                    showSearch
                    disabled={isWorkflowDisabled}
                    filterOption={(input: string, option: any): boolean =>
                      option?.label
                        ?.toString()
                        .toLowerCase()
                        .includes(input?.toString().toLowerCase())
                    }
                  />
                </FormItemStyled>

                <FormItemStyled label="Approvers" required>
                  <MultiSelectUsersField
                    placeholder="Select one or more users"
                    name="approvers"
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    width="333px"
                    height="34px"
                    maxHeight="165px"
                    allowClear
                    onlyUsers
                    excludeUsers={excludeApprovers}
                    disabled={isWorkflowDisabled}
                  />
                </FormItemStyled>
              </WorkflowFieldsWrapperStyled>
            )}

            <CheckboxTooltipWrapperStyled>
              <Tooltip
                title={!isDqEnabled ? DATA_QUALITY_NOT_ENABLED : undefined}
                placement="rightTop"
                overlayClassName="domain-checkbox-tooltip"
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentNode as HTMLElement
                }
              >
                <CheckboxWrapperStyled className="extended-checkbox">
                  <CheckboxField
                    name="is_dq_workflow_enabled"
                    control={control}
                    className="enable-workflow"
                    mode="primary"
                    disabled={!isDqEnabled}
                  >
                    Enable Data Quality Workflow
                  </CheckboxField>

                  <div className="checkbox-desc">
                    Require that alerts be acknowledged and resolved.
                  </div>
                </CheckboxWrapperStyled>
              </Tooltip>
            </CheckboxTooltipWrapperStyled>

            <FormItemStyled
              label="Choose Sub-Domains"
              paddingLeft="334px"
              marginBottom="62px"
            >
              <Transfer
                state={{ ...state, data: transferData }}
                setState={setState}
                leftTile={
                  <div className="transfer-left-tilte">
                    Available
                    <Tooltip
                      title="This category cannot be added since it is 
                    already existing in any other data domain."
                      placement="right"
                      getPopupContainer={(trigger): HTMLElement =>
                        trigger.parentNode as HTMLElement
                      }
                      overlayClassName="available-filters-tooltip"
                    >
                      {infoIcon}
                    </Tooltip>
                  </div>
                }
                rightTile="Assigned"
              />
            </FormItemStyled>
          </div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              height="39px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddGlossaryDomainFormStyled>
    </StateHandler>
  );
};

export default AddGlossaryDomainForm;
