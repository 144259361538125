import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../../constants/formconstants";

import { FilterCriteriaSchema } from "../filtercriteriaschema";
import { descriptionSchema } from "./ruledescriptionschema";

import { checkDataTypeOfColumn } from "../../checkdatatypeofcolumn";
import { RefDictSpecifyFieldsType } from "../../../forms/addruleform/addfoundationaldqform/addvaluerangeform/addvaluerangeform.types";

export const addValueRangeSchema = yup.object().shape({
  rule_type: yup.string()?.trim().required(REQUIRED_MESSAGE),
  rule_description: descriptionSchema,

  column: yup.string()?.trim().required(REQUIRED_MESSAGE),

  specify: yup.string()?.trim().required(REQUIRED_MESSAGE),

  define: yup.string()?.trim().required(REQUIRED_MESSAGE),

  min_value_type: yup.string()?.required(REQUIRED_MESSAGE),

  max_value_type: yup.lazy((value, originalObject) => {
    const specifyComparisonType = originalObject.parent.specify;

    const isRangeValue = specifyComparisonType === "RV";

    return isRangeValue
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  min_value: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.min_value_type;
    const specifyComparisonMaxTypeValue = originalObject.parent.max_value_type;
    const specifyComparisonType = originalObject.parent.specify;

    const isAbsoluteAndSingleValue =
      specifyComparisonType === "SV" && specifyComparisonTypeValue === "ABS";

    const isRangeAndAbsoluteValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "ABS";

    const isMaxValueAbs = specifyComparisonMaxTypeValue === "ABS";

    const columnDataType = originalObject.parent.column_data_type;

    const {
      isDateColumn: isDateTypeColumn,
      isNumberCol: isNumberTypeColumn,
    } = checkDataTypeOfColumn(columnDataType);

    const isSingleOrRangeAbsValue =
      isAbsoluteAndSingleValue || isRangeAndAbsoluteValue;

    if (isSingleOrRangeAbsValue) {
      // checking validation on date and number
      if (isSingleOrRangeAbsValue && isDateTypeColumn) {
        const oldDate = new Date(value);
        const earlierDate = new Date(originalObject?.parent?.max_value);
        return yup
          .string()
          .required(REQUIRED_MESSAGE)
          .test("less than or not", "Min < Max", () => {
            return isMaxValueAbs ? oldDate < earlierDate : true;
          });
      }
      if (isSingleOrRangeAbsValue && isNumberTypeColumn) {
        return yup
          .string()
          .required(REQUIRED_MESSAGE)
          ?.matches(
            /^-?\d+(\.\d{1,48})?$/,
            "Must be a valid number with up to 48 decimal places."
          )
          ?.test("less than or not", "Min < Max", () => {
            return isMaxValueAbs
              ? Number(originalObject?.parent?.max_value) > Number(value)
              : true;
          });
      }

      return yup.string().required(REQUIRED_MESSAGE);
    }

    return yup.string();
  }),

  max_value: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.max_value_type;
    const specifyComparisonType = originalObject.parent.specify;
    const specifyComparisonMaxTypeValue = originalObject.parent.min_value_type;

    const isMinValueAbs = specifyComparisonMaxTypeValue === "ABS";

    const isRangeAndAbsValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "ABS";

    const columnDataType = originalObject.parent.column_data_type;

    const {
      isDateColumn: isDateTypeColumn,
      isNumberCol: isNumberTypeColumn,
    } = checkDataTypeOfColumn(columnDataType);

    if (isRangeAndAbsValue) {
      if (isDateTypeColumn) {
        const oldDate = new Date(originalObject?.parent?.min_value);
        const earlierDate = new Date(value);
        return yup
          .string()
          .required(REQUIRED_MESSAGE)
          .test("greater than or not", "Max > Min", () => {
            return isMinValueAbs ? oldDate < earlierDate : true;
          });
      }
      if (isNumberTypeColumn) {
        return yup
          .string()
          .required(REQUIRED_MESSAGE)
          ?.matches(
            isNumberTypeColumn ? /^-?\d+(\.\d{1,48})?$/ : /.*/,
            "Must be a valid number with up to 48 decimal places."
          )
          .test("less than or not", "Max > Min", () => {
            return isMinValueAbs
              ? Number(value) > Number(originalObject?.parent?.min_value)
              : true;
          });
      }
      return yup.string()?.required(REQUIRED_MESSAGE);
    }

    return yup.string();
  }),

  min_oper: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.min_value_type;
    const specifyComparisonType = originalObject.parent.specify;

    const isRefAndSingleValue =
      specifyComparisonType === "SV" && specifyComparisonTypeValue === "REF";

    const isRefAndRangeValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "REF";

    return isRefAndSingleValue || isRefAndRangeValue
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  max_oper: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.max_value_type;
    const specifyComparisonType = originalObject.parent.specify;

    const isRefAndRangeValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "REF";

    return isRefAndRangeValue
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  min_column_id: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.min_value_type;
    const specifyComparisonType = originalObject.parent.specify;

    const isRefAndSingleValue =
      specifyComparisonType === "SV" && specifyComparisonTypeValue === "REF";

    const isRefAndRangeValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "REF";

    return isRefAndSingleValue || isRefAndRangeValue
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  max_column_id: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.max_value_type;
    const specifyComparisonType = originalObject.parent.specify;

    const isRefAndRangeValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "REF";

    return isRefAndRangeValue
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  min_expr: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.min_value_type;
    const specifyComparisonType = originalObject.parent.specify;

    const isExpAndSingleValue =
      specifyComparisonType === "SV" && specifyComparisonTypeValue === "EXP";

    const isExpAndRangeValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "EXP";

    return isExpAndSingleValue || isExpAndRangeValue
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  max_expr: yup.lazy((value, originalObject) => {
    const specifyComparisonTypeValue = originalObject.parent.max_value_type;
    const specifyComparisonType = originalObject.parent.specify;

    const isExpAndRangeValue =
      specifyComparisonType === "RV" && specifyComparisonTypeValue === "EXP";

    return isExpAndRangeValue
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  dictionary: yup.lazy((value, originalObject) => {
    const specifyComparisonType = originalObject.parent.specify;
    const isRangeDict = specifyComparisonType === "RD";
    return isRangeDict
      ? yup.string()?.required(REQUIRED_MESSAGE)
      : yup.string();
  }),

  specify_fields: yup.lazy((value, originalObject) => {
    const specifyComparisonType = originalObject.parent.specify;
    const selectedColumn = originalObject.parent.column;
    const selectedFields = originalObject.parent.specify_fields;
    const isRefDictHierarchial = originalObject.parent.is_ref_dict_hierarchial;

    const isRefDict = specifyComparisonType === "RD";
    return isRefDict && isRefDictHierarchial
      ? yup.array().of(
          yup.object().shape({
            source_field: yup
              .string()
              .required(REQUIRED_MESSAGE)
              .test("contains-column", "", (value) => {
                return (
                  selectedFields?.some(
                    (item: RefDictSpecifyFieldsType) =>
                      Number(item?.source_field) === Number(selectedColumn)
                  ) ?? false
                );
              }),
            reference_field_name: yup.string().required(REQUIRED_MESSAGE),
          })
        )
      : yup.mixed();
  }),

  select_values: yup.lazy((value, originalObject) => {
    const specifyComparisonType = originalObject.parent.specify;

    const isFieldDict = specifyComparisonType === "FD";

    return isFieldDict
      ? yup.array().of(yup.string()).min(1, REQUIRED_MESSAGE)
      : yup.array();
  }),

  is_filter_criteria_active: yup.boolean(),

  filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),
});
