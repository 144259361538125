import { useCallback } from "react";

import { Button } from "..";
import { APP_ROUTES } from "../../constants";
import { useCancelModal } from "../../customhooks";
import { FormItemStyled, FormStyled } from "../form";
import { SessionExpiredModalStyled } from "./sessionexpiredmodal.styles";

function SessionExpiredModal(): JSX.Element {
  const onCancelModal = useCancelModal();

  const onOk = useCallback(() => {
    localStorage.clear();
    onCancelModal();
    window.location.href = APP_ROUTES.auth_routes.login;
  }, []);

  return (
    <SessionExpiredModalStyled>
      <div className="desc">
        Your session has been expired. Press ok to login again.
      </div>
      <FormStyled>
        <FormItemStyled label="" className="form-actions-sec">
          <Button id="cancel" width="82px" onClick={onOk}>
            Ok
          </Button>
        </FormItemStyled>
      </FormStyled>
    </SessionExpiredModalStyled>
  );
}

export default SessionExpiredModal;
