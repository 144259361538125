import styled from "styled-components";

export const EditQueryQuestionFormStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .textarea-field-container {
    width: 99%;

    .ant-input {
      height: 39.6px;
    }
  }
  .cross-icon-wrapper {
    height: 25px;
    margin-top: 20.5px;
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;
    }
  }
`;
