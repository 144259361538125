import { Tooltip } from "antd";
import { useMemo } from "react";
import { NodeType } from "../../app.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { NodePublishedStatusIds } from "../../parsers/parser.types";
import {
  aiSuggestIcon,
  alertIcon,
  deprecateIcon,
  filledSuccessTickInCircle,
  modifyingStatus,
  newKeywordIcon,
  waitingStatus,
} from "../../svgs";
import { NodeStatusRendrerStyled } from "./nodestatusrendrer.styles";

function NodeStatusRendrer(props: {
  status: NodePublishedStatusIds;
  className?: string;
  tooltipTitle?: string;
  nodeType?: NodeType;
}): JSX.Element {
  const { status = "", className = "", tooltipTitle = "", nodeType } = props;
  const combinedClassName = `node-status-rendrer ${className}`;

  const statusIcon = useMemo(() => {
    switch (status) {
      case "DEP":
        return deprecateIcon();
      case "SUG":
        return aiSuggestIcon();
      case "NEW":
        return newKeywordIcon("20", "7");
      case "PUB":
        return filledSuccessTickInCircle("21px", "21px");
      case "DFT":
        return nodeType === "TRM" ? waitingStatus("21px", "21px") : <div />;
      case "MOD":
        return modifyingStatus("21px", "21px");
      case "NAN":
        return alertIcon();
      default:
        return <div />;
    }
  }, [props]);

  return (
    <Tooltip
      overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
      title={tooltipTitle}
      placement="right"
    >
      <NodeStatusRendrerStyled className={combinedClassName}>
        {statusIcon}
      </NodeStatusRendrerStyled>
    </Tooltip>
  );
}

export default NodeStatusRendrer;
