export const sumTupleElement = (tupleString: string): number => {
  try {
    // Check if the string contains parentheses
    const hasParentheses =
      tupleString?.includes("(") && tupleString?.includes(")");

    // Split the string into an array of elements based on presence of parentheses
    const elements = hasParentheses
      ? tupleString?.slice(1, -1)?.split(",")
      : tupleString?.split(",");

    // Convert each element to a number and sum them up
    const sum = elements?.reduce((acc, curr) => {
      return acc + parseInt(curr.trim(), 10);
    }, 0);

    return sum;
  } catch {
    return 0;
  }
};
