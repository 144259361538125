// <--- Library Imports Start --->
import { Col, Spin, Row } from "antd";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import {
  AboutThePromptSec,
  QueryResultsChart,
  QueryResultsGrid,
  SummarizeSec,
  GuideMeSection,
  QueryResultsHeader,
  QueryErrorSection,
  QueryMenuSec,
} from "./queryresults.comp";

import QueryBlocksLoadingSlateWrapper from "../../../querblocksloadingstate";

import UserNameInitials from "../../../../../../../../../../components/usernameinitials";
import LinkButton from "../../../../../../../../../../components/linkbutton/linkbutton";
// <--- Components End --->

// <--- Styles Start --->

import {
  QueryActionAndSettingsWrapperStyled,
  QueryExplanationStyled,
  QueryFiltersWrapperStyled,
  QueryResultsAndActionWrapperStyled,
  QueryResultsFiltersStyled,
  QueryResultsStyled,
} from "./queryresults.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import {
  crossIcon,
  dvSumnLogo,
  infoIcon,
  settingsIcon,
  transparentDvSumLogo,
} from "../../../../../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { QueryResultItemProps, QueryResultsProps } from "./queryresults.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  CCQ_CHAT_GPT_FAILURE_ERROR,
  CCQ_SQL_COMPILATION_ERROR,
  GATEWAY_DOWN_ERROR,
  GENERIC_CCQ_QUERY_BLOCK_ERROR_MESSAGE,
  GUIDE_ME_QUESTIONS_ERROR,
  PROMPT_SUMMARY_ERROR,
  REST_SOURCE_ERROR_MESSAGE,
  START_EXECUTION_ERROR,
} from "../../../../../../../../analysisdetalpage.constants";

import { isQuestionRefreshing } from "./queryresults.utils";

import {
  checkIsChatPage,
  checkIsViewOnlyChatQuestionPage,
  checkSourceType,
  getTimeDifferenceInLocalTimeZone,
  markDownTextToJSX,
  parseStringToHtml,
} from "../../../../../../../../../../utils";

import {
  ELEMENT_IDS,
  HOUR_MIN_SEC_MONTH_NAME_DAY_YEAR,
} from "../../../../../../../../../../constants";
import { ContentPreviewer } from "../../../../../../../../../../components";

import {
  checkQueryType,
  filterQueryFiltersWhereFilterModelsExists,
} from "../../../../../../../../analisisdetailpage.utils";

import { VerboseLogsForm } from "../../../../../../../../../../forms";
import QueryChartActions from "../querychartactions/querychartactions";

import { VerticalDividerStyled } from "../../../../../../../../../../components/dividers/dividers.styles";
import TagsBasedGridFilters from "../../../../../../../../../../components/tagsbasedgridfilters/tagsbasedgridfilters";

import { TagsBasedGridFilter } from "../../../../../../../../../../components/tagsbasedgridfilters";
import { transformFilterModelToListOfFilters } from "../queryblock.comp.utils";

import { QueryExplanationType } from "../../../../../../../../analysisdetailpage.types";

import { QueryBlockProps } from "../../queryblock.types";
import {
  useGetCurrentGateway,
  useOpenDrawer,
} from "../../../../../../../../../../customhooks";

import TrainBotFeedbackForm from "../../../../../../../trainbotfeedbackform";
import QueryBlockSettings from "../queryblocksettings/queryblocksettings";

import { useGetCaddiNegFeedbackProps } from "../../../../../../../../useGetCaddiNegFeedbackProps";
// <--- Utils End --->

const {
  caddi_qry_rslt_1: CADDI_QRY_RSLT_1,
  caddi_qry_log_sec: CADDI_QRY_LOG_SEC,
  caddi_qry_code_prvw_sec: CADDI_QRY_CODE_PRVW_SEC,
  caddi_qry_exp_sec: CADDI_QRY_EXP_SEC,
  cadd_query_menu_sec: CADDI_QUERY_MENU_SEC,
} = ELEMENT_IDS;

const QueryExplanation = ({
  tablesUsedInAnswer = [],
  queryExplanation = "",
  onCancel,
  id = "",
  sortingOrderDesc = "",
}: {
  tablesUsedInAnswer: QueryBlockProps["queryItem"]["results"]["tables_used_in_answer"];
  queryExplanation: QueryExplanationType;
  onCancel?: (id: string, e: React.MouseEvent<HTMLElement>) => void;
  id: string;
  sortingOrderDesc: string;
}): JSX.Element => {
  const openDrawer = useOpenDrawer();
  const isChatPage = checkIsChatPage();

  // const filteredExpl = queryExplanation?.filter(
  //   (expl) => !/Sorting Order:/i.test(expl?.heading)
  // );

  // const updatedExpl: QueryExplanationType = sortingOrderDesc
  //   ? [
  //       ...filteredExpl?.slice(0, filteredExpl?.length - 1),
  //       { heading: "Sorting Order:", desc: sortingOrderDesc },
  //       ...filteredExpl?.slice(filteredExpl?.length - 1),
  //     ]
  //   : queryExplanation;

  const onTableClick = useCallback(
    (id: string) => (): void => {
      openDrawer({ drawerId: "table_ref", visible: true, drawerProps: { id } });
    },
    []
  );

  const explanationJSX = useMemo(() => {
    if (typeof queryExplanation === "string") {
      const queryExplanationJsxString = markDownTextToJSX(queryExplanation);

      let explanationWithClickableTableNames = queryExplanationJsxString;

      tablesUsedInAnswer?.forEach((table) => {
        explanationWithClickableTableNames = explanationWithClickableTableNames?.replaceAll(
          `<li>${table?.name}`,
          `<li class="tbl-name ${table?.id}-${id}">${table?.name}`
        );
      });

      return parseStringToHtml(explanationWithClickableTableNames);
    }

    return queryExplanation?.map((item) => (
      <div
        key={`query-expl-${item?.heading}-${item?.desc}`}
        className="query-expl-item-wrapper"
      >
        <span className="query-expl-title">{item?.heading}</span>

        {item?.heading?.includes("Tables included in the Query") &&
        tablesUsedInAnswer?.length ? (
          <ul>
            {tablesUsedInAnswer?.map((tbl, index) => (
              <li key={`query-used-tables-${tbl?.id}-${id}-${index}`}>
                <div
                  className="tbl-name"
                  key={`train-bot-query${tbl?.id}-${tbl?.name}`}
                  role="button"
                  onClick={onTableClick(tbl?.id)}
                >
                  {tbl?.name}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <span className="query-expl">{item?.desc}</span>
        )}
      </div>
    ));
  }, [queryExplanation, tablesUsedInAnswer?.length, onTableClick]);

  useLayoutEffect(() => {
    tablesUsedInAnswer?.forEach((table) => {
      Array.from(document.getElementsByClassName(`${table?.id}-${id}`)).forEach(
        (item) => {
          item.addEventListener("click", onTableClick(table?.id));
        }
      );
    });

    return (): void => {
      tablesUsedInAnswer?.forEach((table) => {
        Array.from(
          document.getElementsByClassName(`${table?.id}-${id}`)
        ).forEach((item) => {
          item.removeEventListener("click", onTableClick(table?.id));
        });
      });
    };
  }, [explanationJSX]);

  return queryExplanation?.length && explanationJSX ? (
    <QueryExplanationStyled isChatPage={isChatPage}>
      <div className="sec-head">
        <div className="sec-heading">Explanation</div>
        <LinkButton
          onClick={(e): void => {
            onCancel?.(id, e);
          }}
        >
          {crossIcon("15", "15")}
        </LinkButton>
      </div>
      <div className="query-expl-sec">{explanationJSX}</div>
    </QueryExplanationStyled>
  ) : (
    <div />
  );
};

const QueryResultItem = (props: QueryResultItemProps): JSX.Element => {
  const [renderedGridRows, setRenderedGridRows] = useState<number>();

  const {
    handlers,
    queryItem,
    hasUserAccessToEdit = false,
    analysisSrcTypeId,
    doesChildExists,
  } = props;

  const {
    defSortKey,
    defSortdirection,
    shouldDoDefaultSorting,
    gridDefaultSortModel,
    alreadyPercFrmtedCols,
  } = useGetCaddiNegFeedbackProps(
    queryItem,
    handlers,
    undefined,
    undefined,
    true
  );

  const {
    id = "",
    header,
    results,
    type,
    sub_type: subType,
    error,
    is_loading: isLoading = false,
    settings,
    filters,
  } = queryItem || {};

  const {
    is_maximize_query_block: isMaximizeQueryBlock = false,
    result_type: resultType = "chart",
  } = header || {};

  const {
    prefrences,
    visible: isQuerySetingsVisible = false,
    is_data_formatted: isDataFormatted,
    metric_format: metricFormat,
    tab: activeTab,
  } = settings || {};

  const { transiant_filters: transiantFilters = {} } = filters || {};

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const isChatPage = checkIsChatPage();

  const isChartVisible = resultType === "chart";
  const isGridVisible = resultType === "grid";
  const isPivotVisible = resultType === "pivot";

  const {
    last_refreshed: lastRefreshed = "",
    data = [],
    record_count: recordCount = 0,
    columns = [],
    chart_config: chartConfig,
  } = results || {};

  const {
    onToggleResultsViewQueryBox,
    onChangeQuerySettings,
    onClearOrAddSingleTransientFilter,
    onClearAllTransientFilter,
  } = handlers || {};

  const errorMessageRegexForRST = new RegExp(
    `^(${REST_SOURCE_ERROR_MESSAGE})$`,
    "i"
  );

  const isNoRecords = useMemo(
    () =>
      type === "CCQ" &&
      subType !== "GME" &&
      subType !== "TRM" &&
      !!lastRefreshed &&
      !recordCount,
    [type, subType, recordCount, lastRefreshed, isLoading]
  );

  useEffect(() => {
    if (!renderedGridRows) {
      setRenderedGridRows(data?.length);
    }
  }, [data?.length]);

  const onChangeRenderedGridRows = useCallback((rows: number) => {
    setRenderedGridRows(rows ?? 0);
  }, []);

  const mappedTransiantFilters: TagsBasedGridFilter[] = useMemo(() => {
    return filterQueryFiltersWhereFilterModelsExists(transiantFilters)?.map(
      (colFilter) => {
        const isMetricCol =
          columns?.find((col) => col?.column_name === colFilter?.colm_name)
            ?.col_type_id === "MET";

        const listOfMappedColFilters = transformFilterModelToListOfFilters(
          colFilter,
          isDataFormatted,
          isMetricCol
        );

        const isEqualsTypeFilter = colFilter?.filter_model?.type === "equals";

        const filterColumnName = listOfMappedColFilters?.[0]?.name || "";
        const filterColOperator = listOfMappedColFilters?.[0]?.operator || "";

        const mappedValues: TagsBasedGridFilter = {
          filter_name: filterColumnName,
          val: listOfMappedColFilters?.map((item) => item?.val || "") || [],
          oprator: isEqualsTypeFilter ? "" : filterColOperator,
          onClear: (): void => {
            onClearOrAddSingleTransientFilter?.(id, {
              ...transiantFilters?.[colFilter?.colm_name],
              filter_model: undefined,
            });
          },
        };
        return mappedValues;
      }
    );
  }, [transiantFilters, id, isDataFormatted, columns]);

  const isFilterExists = useMemo(() => !!mappedTransiantFilters?.length, [
    mappedTransiantFilters,
  ]);

  const onSettingsIconClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      onChangeQuerySettings({
        id,
        e,
        type: "settings_toggle",
      });
    },
    [id, onChangeQuerySettings]
  );

  return (
    <QueryResultsAndActionWrapperStyled
      isChatPage={isChatPage}
      showBorder={!doesChildExists}
    >
      <Row>
        <div className="query-view-actions-filter-wrapper">
          <span className="results-keyword">
            {queryItem?.results?.sec_title || "Results"}
          </span>
        </div>
        {errorMessageRegexForRST.test(error?.formattedMsg) ? (
          <QueryErrorSection
            errorMessage={error?.formattedMsg}
            queryItem={queryItem}
          />
        ) : (
          <>
            <Col span={isQuerySetingsVisible && isRestAPISource ? 18 : 24}>
              {!isNoRecords && !!columns?.length && (
                <QueryActionAndSettingsWrapperStyled
                  isChartView={isChartVisible}
                >
                  <>
                    <LinkButton
                      className="chart-settings"
                      tooltipProps={{
                        title: "Show Settings",
                      }}
                      onClick={(e): void => onSettingsIconClick(e)}
                    >
                      {settingsIcon}
                    </LinkButton>

                    <VerticalDividerStyled height="8px" />
                  </>

                  <div className="chart-actions-wrapper">
                    <QueryChartActions
                      queryItem={queryItem}
                      onToggleResultsViewQueryBox={onToggleResultsViewQueryBox}
                    />
                  </div>
                </QueryActionAndSettingsWrapperStyled>
              )}

              <QueryFiltersWrapperStyled>
                {!!mappedTransiantFilters?.length && !isPivotVisible && (
                  <QueryResultsFiltersStyled
                    data-testid="analysis-detail-page-query-block-query-results-content-tags-based-grid-filters-wrapper"
                    isChatPage={isChatPage}
                  >
                    <TagsBasedGridFilters
                      filters={[...mappedTransiantFilters]}
                      label="Filter:"
                      onClearAll={
                        hasUserAccessToEdit || isChatPage
                          ? (): void => onClearAllTransientFilter?.(id)
                          : undefined
                      }
                    />
                  </QueryResultsFiltersStyled>
                )}
              </QueryFiltersWrapperStyled>

              {chartConfig && isChartVisible && (
                <QueryResultsChart
                  isChartSettingsVisible={isQuerySetingsVisible}
                  isDataFormatted={isDataFormatted}
                  isChatPage={isChatPage}
                  chartConfig={chartConfig}
                  prefrences={prefrences}
                  defSortKey={defSortKey}
                  defSortdirection={defSortdirection}
                  shouldDoDefaultSorting={shouldDoDefaultSorting}
                  alreadyPercFrmtedCols={alreadyPercFrmtedCols}
                  metricFormat={metricFormat}
                />
              )}

              {isGridVisible && (
                <QueryResultsGrid
                  key={`${queryItem?.id}-${
                    isMaximizeQueryBlock ? "maximized" : "normal"
                  }-query-block-grid`}
                  queryItem={queryItem}
                  handlers={handlers}
                  onChangeRenderedGridRows={onChangeRenderedGridRows}
                  isFiltersExist={isFilterExists}
                  defaultSortModel={gridDefaultSortModel}
                  analysisSrcTypeId={analysisSrcTypeId}
                />
              )}

              {isPivotVisible && (
                <QueryResultsGrid
                  queryItem={queryItem}
                  handlers={handlers}
                  onChangeRenderedGridRows={onChangeRenderedGridRows}
                  isFiltersExist={isFilterExists}
                  analysisSrcTypeId={analysisSrcTypeId}
                />
              )}
            </Col>

            {isQuerySetingsVisible && isRestAPISource && (
              <Col span={6}>
                <QueryBlockSettings
                  chartConfig={chartConfig}
                  handlers={handlers}
                  queryItem={queryItem}
                  activeTab={activeTab}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </QueryResultsAndActionWrapperStyled>
  );
};
const QueryResultsContent = (props: QueryResultsProps): JSX.Element => {
  const {
    handlers,
    queryItem,
    hasUserAccessToEdit = false,
    isAppliedfiltersChanged,
    onUseSampleQuestionClick,
    index = 0,
    isSavedContextsSecVisible = false,
    totalItems = 0,
    queryContext = [],
    queries,
    analysisSrcTypeId,
    isAuthor = false,
    gatewayId = "",
  } = props;

  const { isGatewayRunning } = useGetCurrentGateway(gatewayId);

  const isViewOnlyChatQuestionPage = checkIsViewOnlyChatQuestionPage();

  const {
    id = "",
    header,
    results,
    type,
    sub_type: subType,
    query,
    error,
    is_loading: isLoading = false,
    loader_type: loaderType,
    is_expanded: isExpanded,
    loading_step: loadingStep,
    is_loading_verbose_logs: isLoadingVerboseLogs = false,
    settings,
    qry_status: qryStatus,
    qry_created_on: qryCreatedOn = "",
    user_feedback: userFeedback,
    is_newly_added_question: isNewlyAddedQuestion,
    is_focused: isFocused = false,
  } = queryItem || {};

  const {
    is_sql_query_visible: isSqlQueryVisible = false,
    is_maximize_query_block: isMaximizeQueryBlock = false,
    is_profiled: isProfiled = false,
    result_type: resultType = "chart",
    is_verbose_visible: isQueryVerboseVisible = false,
    is_explanation_visible: isExplanationVisible = false,
  } = header || {};

  const { visible: isQuerySetingsVisible = false } = settings || {};

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const { sql_query: sqlQuery = "" } = query || {};

  const {
    isAboutPrompt,
    isGuideMeSec,
    isConceptQuery,
    isSummary,
    isTermQuery,
  } = checkQueryType(type, subType);

  const isChatPage = checkIsChatPage();
  const isChartVisible = resultType === "chart";

  const {
    data = [],
    summary = "",
    summary_response_keys: summaryResponseKeys = [],
    prompt_summary: promptSummary,
    guide_me_data: guideMeData,
    chart_config: chartConfig,
    is_caddi_sample_data: isCaddiSampleData = false,
    query_explanation: queryExplanation = "",
    verbose_response: verboseResponse = [],
    tables_used_in_answer: tablesUsedInAnswer = [],
    questiont_answer: questiontAnswer = "",
  } = results || {};

  const {
    isShowFeedbackForm,
    isShowFeedbackSuccessMsg,
    feedbackSuccessMsg,
    showRefreshQueryTooltip,
    setShowRefreshQueryTooltip,
    errorCase,
    toggleFeedbackFormVisibility,
    toggleFeedbackSuccessMsg,
    sortingOrderText,
    onDislikeClick,
  } = useGetCaddiNegFeedbackProps(
    queryItem,
    handlers,
    queries,
    analysisSrcTypeId,
    undefined,
    true,
    gatewayId
  );

  const loadingSlateRef = useRef<HTMLDivElement>(null);
  const codeViewRef = useRef<HTMLDivElement>(null);
  const explanationRef = useRef<HTMLDivElement>(null);
  const logRef = useRef<HTMLDivElement>(null);

  const {
    onRefreshSingleAnalysis,
    onShowSampleData,
    updateQueryLoaderStep,
    onShowVerboseIconClick,
    onToggleCodeView,
    onToggleQueryContextView,
    onToggleQueryExplanation,
  } = handlers || {};

  const errorMessageRegexToNotShowFeedbackOption = new RegExp(
    `^(${PROMPT_SUMMARY_ERROR})$`,
    "i"
  );

  // const errorMessageRegexForCCQ = new RegExp(
  //   `^(${GENERIC_CCQ_QUERY_BLOCK_ERROR_MESSAGE}|${CCQ_SQL_COMPILATION_ERROR}|${GATEWAY_DOWN_ERROR}|${GUIDE_ME_QUESTIONS_ERROR}|${START_EXECUTION_ERROR}|${CCQ_CHAT_GPT_FAILURE_ERROR})$`,
  //   "i"
  // );

  const showFeedbackSec = useMemo(() => {
    return type === "CCQ" && !isLoading;
  }, [type, isLoading]);

  const sqlQueryComp = useMemo(() => {
    return (
      (hasUserAccessToEdit || isChatPage) &&
      sqlQuery &&
      isSqlQueryVisible && (
        <ContentPreviewer
          title="Query"
          sqlQuery={sqlQuery}
          onClose={(): void => onToggleCodeView(id)}
        />
      )
    );
  }, [hasUserAccessToEdit, isChatPage, sqlQuery, isSqlQueryVisible]);

  const isShowLoaderAfterAnswer = useMemo(
    () =>
      isQuestionRefreshing(isLoading, loaderType, loadingStep) &&
      loadingStep === 3 &&
      qryStatus !== "RT",
    [isLoading, loaderType, loadingStep, qryStatus]
  );

  const {
    childrenResults,
    isAnyChildLoading,
    doesChildExists,
    isAnyChildLoaderTypeEqlRelPrevQryData,
    doesDataExists,
  } = useMemo(() => {
    const results = queries?.filter(
      (qryItem) => qryItem?.parent_query_id === queryItem?.id
    );

    const childrenResults = results?.length ? results : [queryItem];

    return {
      childrenResults,
      isAnyChildLoading: results?.some((qryItem) => qryItem?.is_loading),
      doesChildExists: !!results?.length,
      isAnyChildLoaderTypeEqlRelPrevQryData: results?.some(
        (res) => res?.loader_type === "reloading_prev_queried_data"
      ),
      doesDataExists: childrenResults?.some(
        (child) => child?.results?.data?.length
      ),
    };
  }, [queries, queryItem]);

  const isShowGrid = !(isRestAPISource && !doesDataExists);

  const callOnRefreshSingleAnalysisWithRef = useCallback(() => {
    toggleFeedbackFormVisibility?.(undefined, false);
    onRefreshSingleAnalysis(id, undefined, undefined, loadingSlateRef);
  }, [
    onRefreshSingleAnalysis,
    id,
    loadingSlateRef,
    toggleFeedbackFormVisibility,
  ]);

  const isLastItem = index === totalItems - 1;

  return (
    <QueryResultsStyled
      data-testid="analysis-detail-page-query-block-query-results-main-wrapper"
      isChatPage={isChatPage}
      isMaximizeQueryBlock={isMaximizeQueryBlock}
      isProfiled={isProfiled}
      isQuerySetingsVisible={isQuerySetingsVisible}
      isChartVisible={!!chartConfig && isChartVisible}
      isMaximizedQuery={isMaximizeQueryBlock}
      isSavedContextsSecVisible={isSavedContextsSecVisible}
      isFocused={isFocused}
      errorCase={errorCase}
      isExpanded={isExpanded}
      isConceptQuery={isConceptQuery}
      isAuthor={isAuthor}
      isViewOnlyChatQuestionPage={isViewOnlyChatQuestionPage}
    >
      {qryStatus === "RT" && isLoading && loadingStep === 3 && (
        <div className="query-retry-overlay">
          <Spin />
          {queryItem?.qry_retry_count > 1
            ? `Hmmm, still a bit tricky. Don't worry, I'm learning and giving it a final shot!`
            : "I have detected an error during query processing. Hold on a second, trying again..."}
        </div>
      )}
      {isAppliedfiltersChanged && (
        <div
          className="notification-div"
          data-testid="analysis-detail-page-query-block-query-results-refresh-query-notification-div"
        >
          <div
            className="icon"
            data-testid="analysis-detail-page-query-block-query-results-notification-div-icon"
          >
            {infoIcon}
          </div>
          <div
            className="text"
            data-testid="analysis-detail-page-query-block-query-results-notification-text"
          >
            A table filter has been removed, to save this change{" "}
            <LinkButton
              onClick={(): void => onRefreshSingleAnalysis?.(id)}
              data-testid="analysis-detail-page-query-block-query-results-notification-refresh-btn"
            >
              Refresh the query
            </LinkButton>
          </div>
        </div>
      )}
      <div
        className="query-results-collapse"
        data-testid="analysis-detail-page-query-block-query-results-collapse-main-wrapper"
      >
        <div
          className="query-results-collapse-header"
          data-testid="analysis-detail-page-query-block-query-results-collapse-header"
        >
          <UserNameInitials
            icon={isChatPage ? transparentDvSumLogo("20", "20") : dvSumnLogo}
          />
          <QueryBlocksLoadingSlateWrapper
            isLoading={
              ((isLoading && loaderType === "fetching_results") ||
                (isLoading && isCaddiSampleData)) &&
              loaderType !== "reloading_prev_queried_data"
            }
            {...(!isNewlyAddedQuestion && { text: "Refreshing Insights" })}
          >
            <div
              className="queryresult-header-section"
              data-testid="analysis-detail-page-query-block-query-results-header-section"
            >
              <div id={`${CADDI_QRY_RSLT_1}-${index + 1}`}>
                <div className="results-action-wrapper">
                  {!isSummary &&
                    !isAboutPrompt &&
                    !isGuideMeSec &&
                    !isConceptQuery &&
                    !isRestAPISource && (
                      <QueryResultsHeader
                        isTermQuery={isTermQuery}
                        queryItem={queryItem}
                        onUpdateLoadingStep={updateQueryLoaderStep}
                        onToggleQueryContextView={onToggleQueryContextView}
                      />
                    )}
                </div>
                {isExpanded && (
                  <>
                    <div id={`${CADDI_QRY_LOG_SEC}-${id}`} ref={logRef} />
                    {isQueryVerboseVisible && !!verboseResponse?.length && (
                      <VerboseLogsForm
                        verboseResponse={verboseResponse}
                        isChatPage={isChatPage}
                        onShowVerboseIconClick={(): void =>
                          onShowVerboseIconClick(id)
                        }
                        isLoading={isLoadingVerboseLogs}
                        analysisSrcTypeId={analysisSrcTypeId}
                      />
                    )}
                  </>
                )}

                <div
                  id={`${CADDI_QRY_CODE_PRVW_SEC}-${id}`}
                  ref={codeViewRef}
                />
                {isExpanded && sqlQueryComp}

                {isRestAPISource && !isShowLoaderAfterAnswer && !isAboutPrompt && (
                  //due to inline flex it was taking more space even when no answer were exists
                  <span className={questiontAnswer ? "workflow-msg-sec" : ""}>
                    {!isLoading && questiontAnswer && (
                      <div className="label">Troubleshooting Analysis</div>
                    )}
                    <QueryResultsHeader
                      isTermQuery={isTermQuery}
                      queryItem={queryItem}
                      onUpdateLoadingStep={updateQueryLoaderStep}
                      onToggleQueryContextView={onToggleQueryContextView}
                    />
                    {!isLoading && questiontAnswer && (
                      <div className="spacing-after-workflow-mesage" />
                    )}
                  </span>
                )}

                <div id={`${CADDI_QRY_EXP_SEC}-${id}`} ref={explanationRef} />
                {isExpanded && isExplanationVisible && (
                  <QueryExplanation
                    tablesUsedInAnswer={tablesUsedInAnswer}
                    queryExplanation={queryExplanation}
                    id={id}
                    onCancel={onToggleQueryExplanation}
                    sortingOrderDesc={sortingOrderText}
                  />
                )}
                <div className="loader-wrapper" ref={loadingSlateRef} />
                {/* {isPromptVisible && isSqlQueryVisible && (
                        <ChartDebugConfig
                          chartConfig={chartConfig}
                          colPreprocessInfo={colPreprocessInfo}
                        />
                      )} */}

                {isShowLoaderAfterAnswer && (
                  <QueryBlocksLoadingSlateWrapper
                    isLoading={isLoading}
                    {...(!isNewlyAddedQuestion && {
                      text: "Refreshing Insights",
                    })}
                    qryCreatedOn={qryCreatedOn}
                  >
                    <div />
                  </QueryBlocksLoadingSlateWrapper>
                )}
                {errorMessageRegexToNotShowFeedbackOption.test(
                  error?.formattedMsg
                ) && !isLoading ? (
                  <QueryErrorSection
                    errorMessage={error?.formattedMsg}
                    queryItem={queryItem}
                  />
                ) : type === "CCQ" && error?.formattedMsg && !isLoading ? (
                  <QueryErrorSection
                    errorMessage={error?.formattedMsg}
                    queryItem={queryItem}
                    showFeedbackSec
                    onDislikeClick={onDislikeClick}
                    isAuthor={isAuthor}
                  />
                ) : error?.formattedMsg && isRestAPISource && !isLoading ? (
                  <QueryErrorSection
                    errorMessage={error?.formattedMsg}
                    queryItem={queryItem}
                    showFeedbackSec
                    onDislikeClick={onDislikeClick}
                    isAuthor={isAuthor}
                  />
                ) : isGuideMeSec ? (
                  <QueryBlocksLoadingSlateWrapper
                    isLoading={isLoading}
                    text="Generating Questions"
                  >
                    <GuideMeSection
                      onClickSampleQuestion={onUseSampleQuestionClick}
                      data={guideMeData}
                      index={index}
                      isExpanded={isExpanded}
                      isAuthor={isAuthor}
                    />
                  </QueryBlocksLoadingSlateWrapper>
                ) : isSummary ? (
                  <QueryBlocksLoadingSlateWrapper
                    isLoading={isLoading}
                    text="Generating Summary"
                  >
                    <SummarizeSec
                      summary={summary}
                      summaryResponseKeys={summaryResponseKeys}
                      isExpanded={isExpanded}
                    />
                  </QueryBlocksLoadingSlateWrapper>
                ) : isAboutPrompt ? (
                  <QueryBlocksLoadingSlateWrapper
                    isLoading={isLoading}
                    text="Generating Info About Bot"
                  >
                    <AboutThePromptSec
                      data={promptSummary}
                      onShowSampleData={onShowSampleData}
                      index={index}
                      isExpanded={isExpanded}
                      isAuthor={isAuthor}
                    />
                  </QueryBlocksLoadingSlateWrapper>
                ) : isConceptQuery ? (
                  <div className="concept-query">
                    Got it. I’ll remember this when relevant questions are
                    asked.
                  </div>
                ) : (
                  isGatewayRunning && (
                    <QueryBlocksLoadingSlateWrapper
                      isLoading={
                        doesChildExists
                          ? isAnyChildLoading
                          : isLoading &&
                            loaderType === "reloading_prev_queried_data"
                      }
                      {...((loaderType === "reloading_prev_queried_data" ||
                        isAnyChildLoaderTypeEqlRelPrevQryData) && {
                        text: "Reloading previously queried data",
                      })}
                    >
                      <>
                        {isExpanded ? (
                          (loaderType === "fetching_paginated_results"
                            ? true
                            : (!isLoading && !isTermQuery) ||
                              (!isLoading && isCaddiSampleData)) &&
                          (isShowGrid ? (
                            <div
                              className={
                                doesChildExists
                                  ? "multi-results-sec-cont-border"
                                  : ""
                              }
                            >
                              {childrenResults?.map((childQueryItem) => (
                                <QueryResultItem
                                  {...props}
                                  queryItem={childQueryItem}
                                  key={childQueryItem?.id}
                                  doesChildExists={doesChildExists}
                                />
                              ))}
                            </div>
                          ) : !questiontAnswer &&
                            !isLoading &&
                            !isAnyChildLoading ? (
                            <div>
                              Couldn't get data, try asking the question another
                              way.
                            </div>
                          ) : (
                            <div />
                          ))
                        ) : (
                          <div />
                        )}
                      </>
                    </QueryBlocksLoadingSlateWrapper>
                  )
                )}

                {!isLoading && (
                  <div className="query-results-bottom-sec">
                    <div
                      className="bottom-sec-left"
                      id={isLastItem ? CADDI_QUERY_MENU_SEC : ""}
                    >
                      <QueryMenuSec
                        index={index}
                        totalItems={totalItems}
                        errorCase={errorCase}
                        queryItem={queryItem}
                        handlers={handlers}
                        showFeedbackSec={showFeedbackSec}
                        queryContext={queryContext}
                        isAppliedfiltersChanged={isAppliedfiltersChanged}
                        isShowFeedbackSuccessMsg={isShowFeedbackSuccessMsg}
                        feedbackSuccessMsg={feedbackSuccessMsg}
                        hasUserAccessToEdit={hasUserAccessToEdit}
                        isShowFeedbackForm={isShowFeedbackForm}
                        toggleFeedbackSuccessMsg={toggleFeedbackSuccessMsg}
                        toggleFeedbackFormVisibility={
                          toggleFeedbackFormVisibility
                        }
                        callOnRefreshSingleAnalysisWithRef={
                          callOnRefreshSingleAnalysisWithRef
                        }
                        onDislikeClick={onDislikeClick}
                        showRefreshQueryTooltip={showRefreshQueryTooltip}
                        setShowRefreshQueryTooltip={setShowRefreshQueryTooltip}
                        analysisSrcTypeId={analysisSrcTypeId}
                        codeViewRef={codeViewRef}
                        explanationRef={explanationRef}
                        logRef={logRef}
                        isAuthor={isAuthor}
                        isGatewayRunning={isGatewayRunning}
                      />
                    </div>

                    <div className="created-at">
                      Refreshed {getTimeDifferenceInLocalTimeZone(qryCreatedOn)}
                    </div>
                  </div>
                )}

                {isChatPage && isShowFeedbackForm && (
                  <div className="caddi-feedback-form">
                    <div className="arrow" />
                    <TrainBotFeedbackForm
                      queryItem={queryItem}
                      handlers={handlers}
                      toggleFeedbackSuccessMsg={toggleFeedbackSuccessMsg}
                      isEditMode={userFeedback?.response === "dislike"}
                      onCrossClick={toggleFeedbackFormVisibility}
                      errorCase={errorCase}
                      isAuthor={isAuthor}
                    />
                  </div>
                )}
              </div>
            </div>
          </QueryBlocksLoadingSlateWrapper>
        </div>
      </div>
    </QueryResultsStyled>
  );
};

const QueryResults = (props: QueryResultsProps): JSX.Element => {
  return <QueryResultsContent {...props} />;
};
export default QueryResults;
