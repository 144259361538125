import styled from "styled-components";

import { hexToRgbA } from "../../utils";

export const DetailDomainInfoStyled = styled.div`
  .list-item {
    font-size: 13px;

    ::marker {
      color: ${({ theme }): string =>
        hexToRgbA(theme?.textColors?.DARK_GREY_10, 0.5)};
    }

    .icon {
      margin: 0 0.4rem;
      font-size: 12px;
      font-weight: normal;
      color: #c3c3c3;
    }
  }
`;
