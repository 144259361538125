export const glossaryDomainsTableBlankSlate = (
  <svg width="100%" height="141" viewBox="0 0 1614.5 141">
    <defs>
      <style>
        {`
            .cls-1{
              font-size: 14.4px;
              font-family: OpenSansSemiBold, OpenSans;
              font-weight: 600;
            }
              
            .cls-2{
              fill: none;
              stroke: #707070;
              opacity: .1;
            }
            
            .cls-3{
              fill: #f0f0f0;
            }
          `}
      </style>
    </defs>
    <g
      id="Group_2669"
      data-name="Group 2669"
      transform="translate(-259.125 -267)"
    >
      <text id="Role" className="cls-1" transform="translate(274 286)">
        <tspan x="0" y="0">
          Name
        </tspan>
      </text>
      <text
        id="Role-2"
        data-name="Role"
        className="cls-1"
        transform="translate(470 286)"
      >
        <tspan x="0" y="0">
          Description
        </tspan>
      </text>
      <text
        id="Modified_By"
        data-name="Modified By"
        className="cls-1"
        transform="translate(1586 286)"
      >
        <tspan x="0" y="0">
          Updated On
        </tspan>
      </text>
      <text
        id="Modified_By-2"
        data-name="Modified By"
        className="cls-1"
        transform="translate(1393 286)"
      >
        <tspan x="0" y="0">
          Updated By
        </tspan>
      </text>
      <path
        id="Line_397"
        data-name="Line 397"
        className="cls-2"
        transform="translate(261.625 353.5)"
        d="M0 0h1612"
      />
      <path
        id="Line_414"
        data-name="Line 414"
        className="cls-2"
        transform="translate(259.125 407.5)"
        d="M0 0h1612"
      />
      <text
        id="Created_By"
        data-name="Created By"
        className="cls-1"
        transform="translate(962 286)"
      >
        <tspan x="0" y="0">
          Data Owner
        </tspan>
      </text>
      <text
        id="Created_By-2"
        data-name="Created By"
        className="cls-1"
        transform="translate(1177 286)"
      >
        <tspan x="0" y="0">
          Data Steward
        </tspan>
      </text>
      <text id="Description" className="cls-1" transform="translate(727 286)">
        <tspan x="0" y="0">
          Sub-domains
        </tspan>
      </text>
      <rect
        id="Rectangle_1493"
        data-name="Rectangle 1493"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(274 320)"
      />
      <rect
        id="Rectangle_1507"
        data-name="Rectangle 1507"
        className="cls-3"
        width="173"
        height="15"
        rx="7.5"
        transform="translate(470 320)"
      />
      <rect
        id="Rectangle_1509"
        data-name="Rectangle 1509"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(727 320)"
      />
      <rect
        id="Rectangle_1511"
        data-name="Rectangle 1511"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(962 320)"
      />
      <rect
        id="Rectangle_1513"
        data-name="Rectangle 1513"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(1177 320)"
      />
      <rect
        id="Rectangle_1515"
        data-name="Rectangle 1515"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(1394 320)"
      />
      <rect
        id="Rectangle_1517"
        data-name="Rectangle 1517"
        className="cls-3"
        width="183"
        height="15"
        rx="7.5"
        transform="translate(1587 320)"
      />
      <rect
        id="Rectangle_1519"
        data-name="Rectangle 1519"
        className="cls-3"
        width="26"
        height="15"
        rx="7.5"
        transform="translate(1822 320)"
      />
      <rect
        id="Rectangle_1505"
        data-name="Rectangle 1505"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(274 374)"
      />
      <rect
        id="Rectangle_1506"
        data-name="Rectangle 1506"
        className="cls-3"
        width="173"
        height="15"
        rx="7.5"
        transform="translate(470 374)"
      />
      <rect
        id="Rectangle_1508"
        data-name="Rectangle 1508"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(727 374)"
      />
      <rect
        id="Rectangle_1510"
        data-name="Rectangle 1510"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(962 374)"
      />
      <rect
        id="Rectangle_1512"
        data-name="Rectangle 1512"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(1177 374)"
      />
      <rect
        id="Rectangle_1514"
        data-name="Rectangle 1514"
        className="cls-3"
        width="115"
        height="15"
        rx="7.5"
        transform="translate(1394 374)"
      />
      <rect
        id="Rectangle_1516"
        data-name="Rectangle 1516"
        className="cls-3"
        width="183"
        height="15"
        rx="7.5"
        transform="translate(1587 374)"
      />
      <rect
        id="Rectangle_1518"
        data-name="Rectangle 1518"
        className="cls-3"
        width="26"
        height="15"
        rx="7.5"
        transform="translate(1822 374)"
      />
    </g>
  </svg>
);
