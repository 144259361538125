export const DATE_TIME_COMBINE_FORMAT = "Pp";

export const YEAR_MONTH_DATE = "MM/dd/yy";
export const MONTH_DATE_YEAR = "MM/dd/yyyy";
export const DATE_MONTH_YEAR = "dd/MM/yyyy";

export const YEAR_MONTH_DATE_HOUR = "yyyy/MM/dd, hh:00 a";
export const MONTH_DAY_YEAR_HOUR_MINUTE = "MM/dd/yyyy hh:mm a";
export const DAY_MONTH_YEAR_HOUR_MINUTE = "dd/MM/yyyy hh:mm a";
export const MONTH_DAY_YEAR_HOUR_MINUTE_SECOND = "MM/dd/yyyy hh:mm:ss a z";

export const MONTH_NAME_DAY_YEAR_HOUR_MIN_SEC = "MMM dd, yyyy, hh:mm:ss a";
export const HOUR_MIN_SEC_MONTH_NAME_DAY_YEAR = "hh:mm:ss a, MMM dd, yyyy";

export const MOMENT_DATE_FORMATS = {
  "DD MMM, YYYY": "DD MMM, YYYY",
  "DD MMMM, YYYY": "DD MMMM, YYYY",
  "DD-MM-YYYY": "DD-MM-YYYY",
  "DD/MM/YY": "DD/MM/YY",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "MM-DD-YYYY": "MM-DD-YYYY",
  "MM/DD/YYYY": "MM/DD/YYYY",
  "MMM D, YYYY": "MMM D, YYYY",
  "YYYY-MM-DD": "YYYY-MM-DD",
  "DD-MM-YYYY HH:mm:ss": "DD-MM-YYYY HH:mm:ss",
  "DD/MM/YYYY HH:mm:ss": "DD/MM/YYYY HH:mm:ss",
  "MM-DD-YYYY HH:mm:ss": "MM-DD-YYYY HH:mm:ss",
  "MM/DD/YYYY HH:mm:ss": "MM/DD/YYYY HH:mm:ss",
  "YYYY_MM_DD HH:mm:ss": "YYYY_MM_DD HH:mm:ss",
  "YYYY-MM-DD HH:mm:ss": "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss[Z]": "YYYY-MM-DDTHH:mm:ss[Z]",
  "MM-DD-YYYY HH:mm:ss A": "MM-DD-YYYY HH:mm:ss A",
  // MM_DD_YYYY: "MM_DD_YYYY",
  // DD_MM_YYYY: "DD_MM_YYYY",
  // YYYY_MM_DD: "YYYY_MM_DD",
  // "YYYY-MM-DD HH:mm:ss": "YYYY-MM-DD HH:mm:ss",
  // "DD/MM/YY HH:mm:ss": "DD/MM/YY HH:mm:ss",
  // "MM/DD/YYYY HH:mm:ss": "MM/DD/YYYY HH:mm:ss",
  // "MM-DD-YYYY HH:mm:ss": "MM-DD-YYYY HH:mm:ss",
  // "DD/MM/YYYY HH:mm:ss": "DD/MM/YYYY HH:mm:ss",
  // "DD-MM-YYYY HH:mm:ss": "DD-MM-YYYY HH:mm:ss",
  // "YYYY/MM/DD HH:mm:ss": "YYYY/MM/DD HH:mm:ss",
  // "MMM DD, YYYY HH:mm:ss": "MMM DD, YYYY HH:mm:ss",
  // "DD MMM, YYYY HH:mm:ss": "DD MMM, YYYY HH:mm:ss",
  // "MMMM DD, YYYY HH:mm:ss": "MMMM DD, YYYY HH:mm:ss",
  // "DD MMMM, YYYY HH:mm:ss": "DD MMMM, YYYY  HH:mm:ss",
  // "DD_MM_YY HH:mm:ss": "DD_MM_YY HH:mm:ss",
  // "MM_DD_YYYY HH:mm:ss": "MM_DD_YYYY HH:mm:ss",
  // "DD_MM_YYYY HH:mm:ss": "DD_MM_YYYY HH:mm:ss",
  // "YYYY_MM_DD HH:mm:ss": "YYYY_MM_DD HH:mm:ss",
};
