import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";
import { hexToRgbA } from "../../../../utils";

export const TagsGridStyled = styled.div<{
  isShowBorder: boolean;
  isClassificationTagset: boolean;
}>`
  width: 100%;
  font-size: 14px;
  max-height: 310px;
  overflow-y: auto;

  .grid-row {
    display: grid;
    grid-template-columns: ${({ isClassificationTagset }): string =>
      isClassificationTagset ? "1fr 2fr 1fr 1fr 1fr" : "1fr 2fr 1fr 1fr"};
    gap: 30px;
    padding: 12px 0px;
    border-bottom: ${({ isShowBorder, theme }): string =>
      isShowBorder
        ? `1px solid ${hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.1)}`
        : "none"};

    &:last-child {
      border-bottom: none;
    }

    div {
      height: 22px;

      ${ellipsis};
    }

    .tags-grid-prominent-tag-container {
      display: flex;
      column-gap: 8px;

      .generic-prominent-tag-container {
        height: 22px;
        width: 48px;

        .generic-prominent-tag-name {
          font-size: 12px;
        }
      }
    }
  }

  .grid-header {
    font-family: OpenSansSemiBold;
    border-bottom: none;
  }

  .no-tags-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 69px 0px;
    border-top: 1px solid
      ${({ theme }): string => hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.1)};

    .no-tags-icon {
      svg {
        path {
          fill: ${({ theme }): string => theme.bgColors.GREY_COLOR_12};
        }
      }
    }

    .no-tags-text {
      font-size: 14px;
    }
  }
`;
