import { Tooltip } from "antd";
import { useCallback, useState } from "react";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { ModalStyled } from "../../../../../../components/modalwrapper/modalwrapper.styles";

import {
  AddPatternModalFormType,
  AddPatternModalProps,
} from "./addpatternmodal.types";

import {
  FooterWrapperStyled,
  HeaderWrapper,
  ModalWrapperStyled,
} from "./addpatternmodal.styles";

import FormItemLabel from "../../../../../../components/form/formitemlabel/formitemlabel";
import {
  InputField,
  TextAreaField,
} from "../../../../../../components/formfields";

import { FormItemStyled, FormStyled } from "../../../../../../components/form";
import { Button } from "../../../../../../components";

import { useRequestWithMethod } from "../../../../../../api";
import Statehandler from "../../../../../../components/statehandler";

import { openNotification } from "../../../../../../utils";
import SuccessNotificationMessage from "../../../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";

import { chevronRight } from "../../../../../../svgs";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../constants";
import { DeleteViewTooltip } from "../../../../../addgovernanceviewform/addgovernanceviewform.components";

import LinkButton from "../../../../../../components/linkbutton/linkbutton";
import { useSetData } from "../../../../../../customhooks";

import { API_CONFIG } from "../../../../../../constants/apiconfig";
import { GetPatternsResponseType } from "../../../../../../parsers/ruleparser/ruleparser.types";
import { addPatternSchema } from "../../../../../../utils/schemas/ruleschemas/addpatternschema";

const AddPatternModal = (props: AddPatternModalProps): JSX.Element => {
  const {
    isModalVisible = false,
    onHandleCancel,
    isFromRuleSet = false,
    patternConfig,
    propsOnDeleteSuccess,
    propsOnAddSuccess,
    propsOnEditSuccess,
  } = props;

  const onSetData = useSetData();

  const [isDeleteTooltipVisible, setIsDeleteTooltipVisible] = useState<boolean>(
    false
  );

  const addPatternForm = useForm<AddPatternModalFormType>({
    defaultValues: {
      name: patternConfig?.name || "",
      regex: patternConfig?.regex || "",
      description: patternConfig?.description || "",
    },
    resolver: yupResolver(addPatternSchema),
    mode: "onChange",
  });

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
    reset: onResetForm,
  } = addPatternForm;

  const onAddPatternSuccess = useCallback((response, newlyAddedPattern) => {
    openNotification(
      <SuccessNotificationMessage message="New pattern created successfully." />
    );
    onSetData(API_CONFIG.get_pattern, response?.data);

    const currentSelectedOption: GetPatternsResponseType = response?.data?.find(
      (item: GetPatternsResponseType) => item?.REF_NAME === newlyAddedPattern
    );

    const selectedPattern =
      String(currentSelectedOption?.REF_ID) ||
      String(currentSelectedOption?.REF_SEQ_ID);

    propsOnAddSuccess?.(selectedPattern);
  }, []);

  const onDeletePatternSuccess = useCallback(
    (response) => {
      openNotification(
        <SuccessNotificationMessage message="Pattern deleted." />
      );
      onResetForm();
      onSetData(API_CONFIG.get_pattern, response?.data);
      propsOnDeleteSuccess?.();
    },
    [propsOnDeleteSuccess]
  );

  const onEditPatternSuccess = useCallback((response) => {
    openNotification(
      <SuccessNotificationMessage message="Pattern updated successfully." />
    );
    onResetForm();
    onSetData(API_CONFIG.get_pattern, response?.data);
    propsOnEditSuccess?.();
  }, []);

  const {
    onExecuteRequest,
    isLoading: updatePatternLoading,
    error: updatePatternError,
  } = useRequestWithMethod("add_pattern", undefined, true);

  const {
    onExecuteRequest: onDeletePattern,
    isLoading: deletePatternLoading,
    error: deletePatternError,
  } = useRequestWithMethod(
    "delete_pattern",
    undefined,
    true,
    onDeletePatternSuccess
  );

  const {
    onExecuteRequest: onEditPattern,
    isLoading: editPatternLoading,
    error: editPatternError,
  } = useRequestWithMethod(
    "update_pattern",
    undefined,
    true,
    onEditPatternSuccess
  );

  const onSave = useCallback(
    (values) => {
      const nameOfPattern = values?.name;
      const finalObj = {
        daf_name: nameOfPattern,
        daf_desc: values?.description,
        daf_regexp: values?.regex,
      };
      patternConfig
        ? onEditPattern(finalObj, [patternConfig?.refSeqId])
        : onExecuteRequest(finalObj, undefined, (res) =>
            onAddPatternSuccess(res, nameOfPattern)
          );
    },
    [patternConfig, onAddPatternSuccess]
  );

  const onHandleCancelModal = (): void => {
    onHandleCancel?.();
    onResetForm();
  };

  const onHandleDeletePattern = useCallback(() => {
    onDeletePattern(undefined, [patternConfig?.refSeqId]);
    setIsDeleteTooltipVisible(false);
  }, [patternConfig]);

  return (
    <ModalStyled
      width="750px"
      visible={isModalVisible}
      onCancel={onHandleCancelModal}
      title={
        isFromRuleSet ? (
          <HeaderWrapper>
            Data Format Check {chevronRight} Custom Pattern
          </HeaderWrapper>
        ) : (
          "Custom Pattern"
        )
      }
    >
      <Statehandler
        isFetching={
          updatePatternLoading || deletePatternLoading || editPatternLoading
        }
        error={updatePatternError || deletePatternError || editPatternError}
        isModal
      >
        <ModalWrapperStyled>
          <FormStyled layout="vertical" height="100%">
            <FormItemLabel
              label="Name"
              key="name"
              width="394px"
              required
              marginTop="47px"
            >
              <InputField
                control={control}
                name="name"
                placeholder="Name"
                width="394px"
              />
            </FormItemLabel>

            <FormItemLabel
              label="Regex"
              key="regex"
              width="394px"
              required
              marginTop="26px"
            >
              <InputField
                control={control}
                name="regex"
                placeholder="Regex"
                width="394px"
              />
            </FormItemLabel>

            <FormItemLabel
              label="Description"
              key="description"
              width="394px"
              marginTop="26px"
            >
              <TextAreaField
                control={control}
                name="description"
                width="394px"
                placeholder="Description"
              />
            </FormItemLabel>
          </FormStyled>

          <FormItemStyled className="form-actions-sec">
            <FooterWrapperStyled>
              <div className="delete-action">
                {patternConfig?.name && (
                  <Tooltip
                    overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} delete-confirmation-tooltip`}
                    getPopupContainer={(trigger): HTMLElement =>
                      trigger?.parentNode as HTMLElement
                    }
                    title={
                      <DeleteViewTooltip
                        handleCancel={(): void =>
                          setIsDeleteTooltipVisible(false)
                        }
                        handleDelete={onHandleDeletePattern}
                        confirmationText="The pattern will be deleted along with all the details. Are you sure you want to delete it? "
                        btnText="Delete Pattern"
                      />
                    }
                    trigger={["click"]}
                    placement="topLeft"
                    onVisibleChange={(visiblility): void =>
                      setIsDeleteTooltipVisible(visiblility)
                    }
                    visible={isDeleteTooltipVisible}
                  >
                    <LinkButton
                      className="delete-button"
                      onClick={(): void => setIsDeleteTooltipVisible(true)}
                    >
                      Delete Pattern
                    </LinkButton>
                  </Tooltip>
                )}
              </div>

              <div className="save-cancel-action">
                <Button id="cancel" width="74px" onClick={onHandleCancelModal}>
                  {isFromRuleSet ? "Go Back" : "Cancel"}
                </Button>
                <Button
                  id="primary"
                  width="74px"
                  marginLeft="8px"
                  htmlType="submit"
                  onClick={handleSubmit(onSave)}
                  disabled={!isValid}
                >
                  {isFromRuleSet
                    ? patternConfig
                      ? "Update"
                      : "Create"
                    : "Save"}
                </Button>
              </div>
            </FooterWrapperStyled>
          </FormItemStyled>
        </ModalWrapperStyled>
      </Statehandler>
    </ModalStyled>
  );
};

export default AddPatternModal;
