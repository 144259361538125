import { Card } from "antd";
import { useCallback } from "react";

import { Button } from "../../components";
import { APP_ROUTES, ELEMENT_IDS } from "../../constants";
import { useCancelModal } from "../../customhooks";
import { productTourIcon } from "../../svgs";
import { updatePostLoginUserInfoData } from "../../utils";

import { ProductTourFormStyled } from "./producttourform.styles";

const { userjrn_onbrd_prdtour_btn: USERJRN_ONBRD_PRDTOUR_BTN } = ELEMENT_IDS;

function ProductTourForm(): JSX.Element {
  const onCancel = useCancelModal();

  const onTakeTour = useCallback(() => {
    updatePostLoginUserInfoData({ is_user_firstlogin: false });
    onCancel();
  }, []);

  const onSkip = useCallback(() => {
    updatePostLoginUserInfoData({ is_user_firstlogin: false });
    onCancel();
  }, []);

  return (
    <ProductTourFormStyled>
      <Card bordered={false}>
        {productTourIcon()}
        <div className="product-tour">Product Tour</div>
        <div className="product-tour-desc">
          Take a quick tour to learn about the core features and how to use
          DvSum Data Intelligence
        </div>
        <div className="product-tour-act">
          <Button height="43px" width="116px" id="cancel" onClick={onSkip}>
            Skip for Now
          </Button>
          <Button
            height="43px"
            width="116px"
            id="primary_1"
            marginLeft="14px"
            onClick={onTakeTour}
            className="take-tour-btn"
            href={APP_ROUTES.private_routes.product_tour}
            target="_blank"
            elementId={USERJRN_ONBRD_PRDTOUR_BTN}
          >
            Take the Tour
          </Button>
        </div>
      </Card>
    </ProductTourFormStyled>
  );
}

export default ProductTourForm;
