import {
  AddBlanksFormApiDataType,
  AddBlanksFormType,
} from "./addblanksform.types";

// NO NEED FOR THIS

export const blanksFormDataToApiData = (
  formData: AddBlanksFormType
): AddBlanksFormApiDataType => {
  return {
    col_id: Number(formData?.column),
  };
};
