import sqlFormatter from "@sqltools/formatter";

const KEYWORDS = ["SELECT", "FROM", "WHERE", "GROUP BY", "ORDER BY", "LIMIT"];

const HELPING_KEYWORDS = [
  "AND",
  "AS",
  "BETWEEN",
  "LEFT JOIN",
  "INNER JOIN",
  "OUTER JOIN",
  "RIGHT JOIN",
];

const OPERATION_KEYWORD = ["SUM", "AVG"];

export const convertQueryToHtmlQuery = (sqlQuery = ""): string => {
  try {
    return sqlFormatter
      .format(sqlQuery)
      .split("\n")
      .map((line: string) => {
        let coloredLine = line;

        KEYWORDS?.map((item) => {
          const keywordRegex = new RegExp(`${item}`, "gi");
          if (coloredLine?.match(keywordRegex)) {
            coloredLine = coloredLine?.replaceAll(
              keywordRegex,
              (matchedValue: string) => {
                return `<span class="query-reserved-word">${matchedValue}</span>`;
              }
            );
          }
          return item;
        });

        HELPING_KEYWORDS?.map((helpingKeyword) => {
          const helpingWordRegex = new RegExp(` ${helpingKeyword} `, "gi");

          if (coloredLine?.match(helpingWordRegex)) {
            coloredLine = coloredLine?.replace(
              helpingWordRegex,
              (matchedValue: string) => {
                return `<span class="helping-word">${matchedValue}</span>`;
              }
            );
          }
          return helpingKeyword;
        });

        OPERATION_KEYWORD?.map((operationalKeyword) => {
          const operationalWordRegex = new RegExp(
            `${operationalKeyword}`,
            "gi"
          );
          if (coloredLine?.match(operationalWordRegex)) {
            coloredLine = coloredLine?.replaceAll(
              `${operationalKeyword}(`,
              () => {
                return `<span class="operation-word">${operationalKeyword}</span>(`;
              }
            );
          }
          return operationalKeyword;
        });

        const valueReg = /'(.*?)'/g;
        coloredLine = coloredLine?.replaceAll(
          valueReg,
          (matchedValue: string) => {
            return `<span class="value-statement">${matchedValue}</span>`;
          }
        );

        return `<div class="query-line">${coloredLine || ""}</div>`;
      })
      .join("");
  } catch {
    return "";
  }
};
