import styled from "styled-components";

export const DefinitionTabStyled = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;

  .col {
    width: 45%;
  }

  .chat-bot-explorer-sec {
    margin-left: 25px;
    border: ${({ theme }): string =>
      ` 1px solid ${theme?.borderColors?.GREY_56}`};
  }
`;

export const DefinitionFormStyled = styled.div`
  width: 100%;
  padding: 10px 20px 10px 0;
  .desc {
    max-width: 100%;
  }
  /**/

  .dvsum-tooltip {
    padding: 0;
  }

  .select-tables {
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    font-size: 16px;
    margin-bottom: 3px;
    padding-left: 25px;
  }

  .select-tables-desc {
    padding-left: 25px;
    font-size: 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    margin-bottom: 8px;
    display: block;
  }
  .checkbox-desc {
    font-size: 12px;
    padding-top: 5px;
    padding-left: 25px;
  }
`;

export const CheckboxWrapperStyled = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }): string => flexDirection || "column"};
  gap: 5px;
  padding-left: 0;

  .label {
    font-family: OpenSansSemiBold;
    font-size: 13.6px;
  }
`;

export const HeadingStyled = styled.div<{ marginBottom?: string }>`
  width: 100%;
  font-family: OpenSansSemiBold;
  padding-bottom: 10px;
  font-size: 17.6px;
  border-bottom: ${({ theme }): string =>
    ` 1px solid ${theme?.borderColors?.LIGHT_GREY_51}`};
  color: ${({ theme }): string => theme?.textColors?.BLUE_116};
  margin-bottom: ${({ marginBottom }): string => marginBottom || "0"};
`;

export const ChatBotDetailStyled = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  .table-listing {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 5px;
    flex-direction: column;
  }

  .label {
    font-size: 13.6px;
  }

  .table-count {
    font-size: 13.6px;
    font-family: OpenSansSemiBold;
    margin-bottom: 0;
  }

  .table-bullets {
    width: 6.4px;
    height: 40px;
    border-left: 1px solid rgb(207, 206, 206);
    border-bottom: 1px solid rgb(207, 206, 206);
  }

  .tables-list {
    position: relative;

    .pinned-tables:first-child {
      height: 30px;
    }

    div:first-child {
      .table-bullets {
        height: 20px;
      }
    }

    div.definition-tables-bullets {
      height: 40px;
      align-items: center;
      top: 20px;
    }
  }

  .empty-text {
    font-size: 13.6px;
  }
`;

export const TableNameWithNodeIcon = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
`;
