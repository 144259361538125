import styled from "styled-components";

export const InformationModalStyled = styled.div`
  .modal-content {
    height: max-content;
    padding: 100px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-direction: column;

    .icon {
      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.RED};
        }
      }
    }

    .text-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 8px;
      gap: 5px;

      .title {
        font-size: 24px;
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        margin-bottom: 0;
      }

      .desc {
        font-size: 16px;
        color: ${({ theme }): string => theme.textColors.GREY_40};
        text-align: center;
        max-width: 80%;
      }
    }

    .actions-wrapper {
      display: flex;
      justify-content: center;
      gap: 8px;
      flex-direction: column;
      align-items: center;

      .contact {
        padding: 8px 20px;
        background-color: ${({ theme }): string => theme.bgColors.BLUE_116};
        border: 1px solid ${({ theme }): string => theme.borderColors.BLUE_116};
        color: ${({ theme }): string => theme.borderColors.WHITE_COLOR};
      }

      .logout {
        padding: 8px 20px;
        width: 115px;
        font-size: 16px;
        font-family: OpenSansSemiBold;
        color: ${({ theme }): string => theme.borderColors.BLUE_116};
      }
    }
  }
`;
