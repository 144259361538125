export const sourcesTableBlankSlate = (
  <svg width="100%" height="141.5">
    <defs>
      <style>{`.cls-1{fill:none;stroke:#707070;opacity:.1}.cls-3{fill:#4c4c4c}.cls-4{font-size:14px;font-family:OpenSansSemiBold,Open Sans;font-weight:600}.cls-5{fill:#f0f0f0}`}</style>
    </defs>
    <g
      id="Group_2467"
      data-name="Group 2467"
      transform="translate(-266.125 -253)"
    >
      <path
        id="Line_414"
        data-name="Line 414"
        className="cls-1"
        transform="translate(259.125 408)"
        d="M0 0h1612"
      />
      <path
        id="Line_397"
        data-name="Line 397"
        className="cls-1"
        transform="translate(259.125 353.5)"
        d="M0 0h1612"
      />
      <g id="Group_2466" data-name="Group 2466">
        <g id="Group_2102" data-name="Group 2102" transform="translate(-47)">
          <text id="Role" className="cls-4" transform="translate(326 286)">
            <tspan x="0" y="0">
              Source Name
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1216"
          data-name="Rectangle 1216"
          className="cls-5"
          width="224"
          height="15"
          rx="7.5"
          transform="translate(280 320)"
        />
        <rect
          id="Rectangle_1495"
          data-name="Rectangle 1495"
          className="cls-5"
          width="224"
          height="15"
          rx="7.5"
          transform="translate(280 373)"
        />
      </g>
      <g id="Group_2465" data-name="Group 2465" transform="translate(228)">
        <g id="Group_2102-2" data-name="Group 2102" transform="translate(113)">
          <text
            id="Role-2"
            data-name="Role"
            className="cls-4"
            transform="translate(326 286)"
          >
            <tspan x="0" y="0">
              Source Type
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1487"
          data-name="Rectangle 1487"
          className="cls-5"
          width="198.4"
          height="15"
          rx="7.5"
          transform="translate(439 320)"
        />
        <rect
          id="Rectangle_1496"
          data-name="Rectangle 1496"
          className="cls-5"
          width="198.4"
          height="15"
          rx="7.5"
          transform="translate(439 373)"
        />
      </g>
      <g id="Group_2464" data-name="Group 2464" transform="translate(410)">
        <g id="Group_2378" data-name="Group 2378" transform="translate(-34)">
          <text
            id="Created_By"
            data-name="Created By"
            className="cls-4"
            transform="translate(629 286)"
          >
            <tspan x="0" y="0">
              Owner
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1488"
          data-name="Rectangle 1488"
          className="cls-5"
          width="173"
          height="15"
          rx="7.5"
          transform="translate(595 320)"
        />
        <rect
          id="Rectangle_1497"
          data-name="Rectangle 1497"
          className="cls-5"
          width="173"
          height="15"
          rx="7.5"
          transform="translate(595 373)"
        />
      </g>
      <g id="Group_2458" data-name="Group 2458" transform="translate(-332)">
        <g id="Group_2099" data-name="Group 2099" transform="translate(410)">
          <text
            id="Created_By-2"
            data-name="Created By"
            className="cls-4"
            transform="translate(1227 286)"
          >
            <tspan x="0" y="0">
              Last Scanned
            </tspan>
          </text>
        </g>
        <rect
          id="Rectangle_1494"
          data-name="Rectangle 1494"
          className="cls-5"
          width="160"
          height="15"
          rx="7.5"
          transform="translate(1637 320)"
        />
        <rect
          id="Rectangle_1503"
          data-name="Rectangle 1503"
          className="cls-5"
          width="160"
          height="15"
          rx="7.5"
          transform="translate(1637 373)"
        />
      </g>
    </g>
  </svg>
  // <svg width="100%" height="100%" viewBox="0 0 1800 288">
  //   <g data-name="Group 2663">
  //     <path
  //       data-name="Line 397"
  //       fill="none"
  //       stroke="#707070"
  //       opacity=".1"
  //       d="M4.169 86.5h2688.206M0 195.5h2688.206M0 249.5h2688.206"
  //     />
  //     <path
  //       data-name="Line 414"
  //       fill="none"
  //       stroke="#707070"
  //       opacity=".1"
  //       d="M0 140.5h2688.206"
  //     />
  //     <g data-name="Group 2662" transform="translate(-259.125 -267)">
  //       <text
  //         transform="translate(274 286)"
  //         fontSize="14"
  //         fontFamily="OpenSansSemiBold, Open Sans"
  //         fontWeight="600"
  //         data-name="Group 2658"
  //       >
  //         <tspan x="0" y="0">
  //           Source Name
  //         </tspan>
  //       </text>
  //       <rect
  //         data-name="Rectangle 1378"
  //         width="391"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(274 321)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1523"
  //         width="391"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(274 373)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1524"
  //         width="391"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(274 427)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1525"
  //         width="391"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(274 483)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1526"
  //         width="391"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(274 540)"
  //         fill="#f0f0f0"
  //       />
  //     </g>
  //     <g data-name="Group 2659" transform="translate(-259.125 -267)">
  //       <g data-name="Group 2100">
  //         <text
  //           data-name="Modified By"
  //           transform="translate(2491 286)"
  //           fontSize="14"
  //           fontFamily="OpenSansSemiBold, Open Sans"
  //           fontWeight="600"
  //         >
  //           <tspan x="0" y="0">
  //             Last Scanned
  //           </tspan>
  //         </text>
  //       </g>
  //       <rect
  //         data-name="Rectangle 1537"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(2491 321)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1538"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(2491 371)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1539"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(2491 425)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1540"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(2491 485)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1541"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(2491 539)"
  //         fill="#f0f0f0"
  //       />
  //     </g>
  //     <g data-name="Group 2660" transform="translate(-247.125 -267)">
  //       <g data-name="Group 2099">
  //         <text
  //           data-name="Created By"
  //           transform="translate(1778 286)"
  //           fontSize="14"
  //           fontFamily="OpenSansSemiBold, Open Sans"
  //           fontWeight="600"
  //         >
  //           <tspan x="0" y="0">
  //             Owner
  //           </tspan>
  //         </text>
  //       </g>
  //       <rect
  //         data-name="Rectangle 1532"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1778 321)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1533"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1778 371)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1534"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1778 425)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1535"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1778 485)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1536"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1778 539)"
  //         fill="#f0f0f0"
  //       />
  //     </g>
  //     <g data-name="Group 2661" transform="translate(-226.125 -267)">
  //       <text
  //         transform="translate(1061 286)"
  //         fontSize="14"
  //         fontFamily="OpenSansSemiBold, Open Sans"
  //         fontWeight="600"
  //         data-name="Group 2657"
  //       >
  //         <tspan x="0" y="0">
  //           Source Type
  //         </tspan>
  //       </text>
  //       <rect
  //         data-name="Rectangle 1527"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1057 321)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1528"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1057 373)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1531"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1057 483)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1529"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1057 427)"
  //         fill="#f0f0f0"
  //       />
  //       <rect
  //         data-name="Rectangle 1530"
  //         width="276"
  //         height="15"
  //         rx="7.5"
  //         transform="translate(1057 537)"
  //         fill="#f0f0f0"
  //       />
  //     </g>
  //   </g>
  // </svg>
);

export const excelSourceFilesGridBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 1784.5 397.906">
    <g data-name="Group 2674">
      <text
        transform="translate(15 19)"
        style={{
          fontSize: "18px",
          fontFamily: "OpenSansSemiBold",
          fontWeight: 600,
        }}
        data-name="Group 2668"
      >
        <tspan x="0" y="0">
          File Name
        </tspan>
      </text>
      <g data-name="Group 2099">
        <text
          data-name="Created By"
          transform="translate(970 19)"
          style={{
            fontSize: "18px",
            fontFamily: "OpenSansSemiBold",
            fontWeight: 600,
          }}
        >
          <tspan x="0" y="0">
            Updated By
          </tspan>
        </text>
        <g data-name="Group 2730" transform="translate(696 -266.094)">
          <rect
            data-name="Rectangle 1506"
            width="133"
            height="15"
            rx="7.5"
            transform="translate(274 319)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1521"
            width="133"
            height="15"
            rx="7.5"
            transform="translate(274 374)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1522"
            width="133"
            height="15"
            rx="7.5"
            transform="translate(274 426)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1523"
            width="133"
            height="15"
            rx="7.5"
            transform="translate(274 483)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="133"
            height="15"
            rx="7.5"
            transform="translate(274 537)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="133"
            height="15"
            rx="7.5"
            transform="translate(274 592)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="133"
            height="15"
            rx="7.5"
            transform="translate(274 649)"
            style={{ fill: "#f0f0f0" }}
          />
        </g>
      </g>
      <path
        data-name="Line 397"
        transform="translate(2.5 86.5)"
        style={{ fill: "none", stroke: "#707070", opacity: ".1" }}
        d="M0 0h1782"
      />
      <path
        data-name="Line 397"
        transform="translate(0 195.5)"
        style={{ fill: "none", stroke: "#707070", opacity: ".1" }}
        d="M0 0h1782"
      />
      <path
        data-name="Line 397"
        transform="translate(0 249.5)"
        style={{ fill: "none", stroke: "#707070", opacity: ".1" }}
        d="M0 0h1782"
      />
      <path
        data-name="Line 397"
        transform="translate(0 305.5)"
        style={{ fill: "none", stroke: "#707070", opacity: ".1" }}
        d="M0 0h1782"
      />
      <path
        data-name="Line 397"
        transform="translate(0 361)"
        style={{ fill: "none", stroke: "#707070", opacity: ".1" }}
        d="M0 0h1782"
      />
      <path
        data-name="Line 414"
        transform="translate(0 140.5)"
        style={{ fill: "none", stroke: "#707070", opacity: ".1" }}
        d="M0 0h1782"
      />
      <g data-name="Group 2669">
        <text
          transform="translate(505 19)"
          style={{
            fontSize: "18px",
            fontFamily: "OpenSansSemiBold",
            fontWeight: 600,
          }}
          data-name="Group 2101"
        >
          <tspan x="0" y="0">
            Updated On
          </tspan>
        </text>
        <g data-name="Group 2731" transform="translate(231 -266.094)">
          <rect
            data-name="Rectangle 1506"
            width="233"
            height="15"
            rx="7.5"
            transform="translate(274 319)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1521"
            width="233"
            height="15"
            rx="7.5"
            transform="translate(274 374)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1522"
            width="233"
            height="15"
            rx="7.5"
            transform="translate(274 426)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1523"
            width="233"
            height="15"
            rx="7.5"
            transform="translate(274 483)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="233"
            height="15"
            rx="7.5"
            transform="translate(274 537)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="233"
            height="15"
            rx="7.5"
            transform="translate(274 592)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="233"
            height="15"
            rx="7.5"
            transform="translate(274 649)"
            style={{ fill: "#f0f0f0" }}
          />
        </g>
      </g>
      <g data-name="Group 2670">
        <g data-name="Group 2100">
          <text
            data-name="Modified By"
            transform="translate(1390 19)"
            style={{
              fontSize: "18px",
              fontFamily: "OpenSansSemiBold",
              fontWeight: 600,
            }}
          >
            <tspan x="0" y="0">
              Size
            </tspan>
          </text>
        </g>
        <g data-name="Group 2730" transform="translate(1116 -266.094)">
          <rect
            data-name="Rectangle 1506"
            width="63"
            height="15"
            rx="7.5"
            transform="translate(274 319)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1521"
            width="63"
            height="15"
            rx="7.5"
            transform="translate(274 374)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1522"
            width="63"
            height="15"
            rx="7.5"
            transform="translate(274 426)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1523"
            width="63"
            height="15"
            rx="7.5"
            transform="translate(274 483)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="63"
            height="15"
            rx="7.5"
            transform="translate(274 537)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="63"
            height="15"
            rx="7.5"
            transform="translate(274 592)"
            style={{ fill: "#f0f0f0" }}
          />
          <rect
            data-name="Rectangle 1524"
            width="63"
            height="15"
            rx="7.5"
            transform="translate(274 649)"
            style={{ fill: "#f0f0f0" }}
          />
        </g>
      </g>
      <g data-name="Group 2730" transform="translate(-259 -266.094)">
        <rect
          data-name="Rectangle 1506"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 319)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1521"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 374)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1522"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 426)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1523"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 483)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1524"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 537)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1524"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 592)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1524"
          width="133"
          height="15"
          rx="7.5"
          transform="translate(274 649)"
          style={{ fill: "#f0f0f0" }}
        />
      </g>
      <g data-name="Group 2730" transform="translate(1457.702 -266.094)">
        <rect
          data-name="Rectangle 1506"
          width="16"
          height="15"
          rx="7.5"
          transform="translate(274.298 319)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1521"
          width="16"
          height="15"
          rx="7.5"
          transform="translate(274.298 374)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1522"
          width="16"
          height="15"
          rx="7.5"
          transform="translate(274.298 426)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1523"
          width="16"
          height="15"
          rx="7.5"
          transform="translate(274.298 483)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1524"
          width="16"
          height="15"
          rx="7.5"
          transform="translate(274.298 537)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1524"
          width="16"
          height="15"
          rx="7.5"
          transform="translate(274.298 592)"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          data-name="Rectangle 1524"
          width="16"
          height="15"
          rx="7.5"
          transform="translate(274.298 649)"
          style={{ fill: "#f0f0f0" }}
        />
      </g>
    </g>
  </svg>
);
