import { useCallback, useEffect, useMemo } from "react";
import { TypingAnimation } from "../../../../../../../../../../../../components";

import {
  OnToggleQueryContextView,
  UpdateQueryLoaderStep,
} from "../../../../../../queryblocklisting.types";

import { QueryBlockProps } from "../../../../queryblock.types";

import {
  QueryBlockResultSectionStyled,
  UsedContextsListStyled,
} from "./queryresultsheader.styles";

import QueryBlocksLoadingSlateWrapper from "../../../../../querblocksloadingstate/queryblocksloadingstate";

import {
  MarkdownContainerStyled,
  WhiteSpacePreLineStyled,
} from "../../../../../../../../../../../../styles/app.styles";
import { showTypingAnimation } from "../../queryresults.utils";

import LinkButton from "../../../../../../../../../../../../components/linkbutton/linkbutton";
import {
  ContextType,
  QueryExplanationType,
} from "../../../../../../../../../../analysisdetailpage.types";
import { parseStringToHtml } from "../../../../../../../../../../../../utils";

const UsedContextsList = ({
  queryId,
  contextsList,
  isShowContextsList,
  onToggleQueryContextView,
}: {
  queryId: string;
  contextsList: Array<ContextType>;
  isShowContextsList: boolean;
  onToggleQueryContextView: OnToggleQueryContextView;
}): JSX.Element => {
  return (
    <UsedContextsListStyled>
      <div className="subtitle">
        I'm also using {contextsList?.length || ""} saved context(s) to generate
        this insight.{" "}
        <LinkButton onClick={(e): void => onToggleQueryContextView(queryId, e)}>
          {isShowContextsList ? "Hide" : "Show"} Contexts
        </LinkButton>
      </div>

      {isShowContextsList && (
        <ul className="contexts-list-wrapper">
          {contextsList?.map((context) => (
            <li className="context-item" key={context?.id}>
              {context?.title}
            </li>
          ))}
        </ul>
      )}
    </UsedContextsListStyled>
  );
};

const WithOutAnimation = ({
  onCompleteion,
  questiontAnswer,
  id = "",
  queryContext,
  isShowContextsList = false,
  onToggleQueryContextView,
}: {
  onCompleteion: () => void;
  questiontAnswer: string;
  id: string;
  queryContext: Array<ContextType>;
  isShowContextsList: boolean;
  onToggleQueryContextView: OnToggleQueryContextView;
}): JSX.Element => {
  useEffect(() => {
    onCompleteion?.();
  }, []);

  return (
    <>
      <MarkdownContainerStyled>
        {parseStringToHtml(questiontAnswer)}
      </MarkdownContainerStyled>
      {!!queryContext?.length && (
        <UsedContextsList
          queryId={id}
          contextsList={queryContext}
          isShowContextsList={isShowContextsList}
          onToggleQueryContextView={onToggleQueryContextView}
        />
      )}
    </>
  );
};

const QueryResultsHeader = ({
  isChatPage = false,
  queryItem,
  onUpdateLoadingStep,
  isTermQuery,
  onToggleQueryContextView,
}: {
  isChatPage?: boolean;
  queryItem: QueryBlockProps["queryItem"];
  onUpdateLoadingStep: UpdateQueryLoaderStep;
  isTermQuery: boolean;
  onToggleQueryContextView: OnToggleQueryContextView;
}): JSX.Element => {
  const {
    results,
    loading_step: loadingStep = 1,
    id,
    is_loading: isLoading,
    loader_type: loaderType,
    header,
    error,
    is_newly_added_question: isNewlyAddedQuestion,
    qry_created_on: qryCreatedOn = "",
  } = queryItem || {};

  const {
    questiont_answer: questiontAnswer = "",
    is_caddi_sample_data: isCaddiSampleData = false,
    formatted_answer: formattedAnswer = "",
  } = results || {};

  const {
    query_context: queryContext = [],
    is_show_contexts_list: isShowContextsList = false,
  } = header || {};

  const onCompleteion = useCallback(() => {
    onUpdateLoadingStep?.(id, 3);
  }, [onUpdateLoadingStep, id]);

  const isShowTypingAnimation = useMemo(
    () => showTypingAnimation(loaderType, loadingStep),
    [isLoading, loaderType, loadingStep]
  );

  useEffect(() => {
    (isTermQuery || !isLoading) && questiontAnswer && onCompleteion();
  }, []);

  return (
    <QueryBlockResultSectionStyled
      data-testid="analysis-detail-page-query-block-query-results-sec"
      isChatPage={isChatPage}
      isZeroMargin={
        ((isLoading || error?.formattedMsg) && !questiontAnswer) ||
        (!isShowTypingAnimation && !questiontAnswer && !queryContext?.length)
      }
    >
      {isCaddiSampleData ? (
        <span>Here is the data</span>
      ) : isShowTypingAnimation ? (
        <>
          {!questiontAnswer ? (
            <QueryBlocksLoadingSlateWrapper
              isLoading={isLoading}
              {...(!isNewlyAddedQuestion && { text: "Refreshing Insights" })}
              qryCreatedOn={qryCreatedOn}
            >
              <div />
            </QueryBlocksLoadingSlateWrapper>
          ) : (
            <TypingAnimation
              text={formattedAnswer}
              onComplete={onCompleteion}
              nextToText={
                <>
                  {!!queryContext?.length && (
                    <UsedContextsList
                      queryId={id}
                      contextsList={queryContext}
                      isShowContextsList={isShowContextsList}
                      onToggleQueryContextView={onToggleQueryContextView}
                    />
                  )}
                </>
              }
              showNextToText={loadingStep === 3}
              streamCompleted={loadingStep === 3}
            />
          )}
        </>
      ) : (
        <WithOutAnimation
          onCompleteion={onCompleteion}
          questiontAnswer={formattedAnswer}
          id={id}
          queryContext={queryContext}
          isShowContextsList={isShowContextsList}
          onToggleQueryContextView={onToggleQueryContextView}
        />
      )}
    </QueryBlockResultSectionStyled>
  );
};
export default QueryResultsHeader;
