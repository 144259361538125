import { getEnvVariables } from "../../utils/envvariables";

const {
  REACT_APP_REDIRECT_SIGN_OUT,
  REACT_APP_REDIRECT_SIGN_IN,
  REACT_APP_COGNITO_UI_DOMAIN,
} = getEnvVariables();

export const awsAuth = {
  domain: REACT_APP_COGNITO_UI_DOMAIN,
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin",
  ],
  redirectSignIn: REACT_APP_REDIRECT_SIGN_IN,
  redirectSignOut: REACT_APP_REDIRECT_SIGN_OUT,
  responseType: "code",
};
