export const TABLE_SECTION_TITLES = {
  overview: {
    definition: "Definition",
    title: "Business Name",
    usability_score: "Usability Score",
    audit_trail: "Audit Trail",
    file_path: "File Path",
  },
  managed_by: {
    data_owners: "Data Owner",
    data_stewards: "Data Steward",
    system_owner: "System Owner",
  },
  classification: {
    format: "Format",
    sub_format: "Sub-Format",
    functional_area: "Data Domain",
    entity: "Entity",
  },
  audit_trail: {
    created: "Created",
    updated: "Updated",
  },
  data_quality: {
    overall_score: "Overall Score",
    last_updated: "Last Updated",
  },
  provinance_data: {
    table_and_sources: "Tables & Sources",
    scan_frequency: "Scan Frequency",
  },
};

export const TABLE_DATA_TAB_SECTION_TITLES = {
  profiling_information: {
    record_count: "Record Count",
    last_scanned_on: "Last Scanned On",
    last_updated_on: "Last Updated On",
    file_size: "File Size",
    file_count: "File Count",
  },
  related_tables: {
    functional_area: "Data Domain",
    last_refreshed_on: "Last Refreshed On",
  },
};

export const TABLE_SECTIONS = {
  description: "Description",
  managed_by: "Managed By",
  linked_columns: "Represents (Linked Columns)",
  classification: "Attributes",
  additional_info: "Additional Info",
  get_access_info: "Get Access Info",
  similar_tables: "Similar Tables",
};

export const TABLE_USABILITY_SCORE_TITLES = {
  definition: TABLE_SECTION_TITLES?.overview?.definition,
  functional_area: TABLE_SECTION_TITLES?.classification?.functional_area,
  ownership: "Ownership",
  tags: "Tags",
  provenance: "Provenance is specified",
  access_info: "Get Access Info is specified",
};
