import { idsType, StyledMappingReturnType } from "./linktabs.types";

export const StyledMapping = (
  key: idsType,
  theme: any
): StyledMappingReturnType => {
  const mappingId = {
    primary: {
      textColor: theme.textColors.BLACK_60,
    },
    default: {
      textColor: theme.textColors.BLACK_COLOR,
    },
  };

  return mappingId[key];
};
