import { TableColumns } from "../../../../components/aggridtable";

import { agGridStringComparator } from "../../../../utils";
import { BotGuidelineType } from "../../../../parsers";

export const BotGuidelinesColumns: TableColumns<BotGuidelineType> = [
  {
    field: "type",
    headerName: "Type",
    width: 160,
    minWidth: 160,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellClass: "guideline-type-cell",
    comparator: agGridStringComparator,
  },

  {
    field: "guideline",
    headerName: "Training",
    flex: 1,
    comparator: agGridStringComparator,
  },
  {
    field: "updated_by",
    headerName: "Updated By",
    width: 200,
    minWidth: 200,
    comparator: agGridStringComparator,
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    width: 200,
    minWidth: 200,
    sort: "desc",
  },
  { field: "action_rend", headerName: "Actions", width: 120, sortable: false },
];
