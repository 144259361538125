export function isJsonString(str: string): boolean {
  try {
    if (str === '""') return false;

    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
