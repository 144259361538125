import { API_CONFIG } from "../../constants/apiconfig";
import {
  getSocialNodeActivities,
  getSocialShareList,
  getSocialShareListForAnalysis,
} from "../../parsers/socialnode/socialnodeparser";
import {
  ShareListParserType,
  SocialNodeActivitiesParserType,
} from "../../parsers/socialnode/socialnodeparser.types";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

export function useGetSocialNode(
  nodeId: string
): CustomReactQueryHookReturnType<SocialNodeActivitiesParserType> {
  return useGetAll({
    apiConfig: API_CONFIG.get_social_node,
    params: [nodeId],
    parser: getSocialNodeActivities,
  });
}

export function useGetSocialShareList(
  nodeId: string,
  nodeType: string
): CustomReactQueryHookReturnType<ShareListParserType[]> {
  return useGetAll({
    apiConfig: API_CONFIG.get_share_list,
    params: [nodeId, nodeType],
    parser:
      nodeType === "ANL" ? getSocialShareListForAnalysis : getSocialShareList,
  });
}
