import styled from "styled-components";

import { ellipsis } from "../../../../styles/app.styles";

export const MatchingEntitiesGridStyled = styled.div`
  .matching-entity-grid-header {
    display: grid;
    grid-template-columns: 177.6px 134px 119px 336px 1fr;
    gap: 3px;
    background: ${({ theme }): string =>
      `linear-gradient(to bottom, ${theme.bgColors.LIGHT_GREY_41}, ${theme.bgColors.LIGHT_BLUE})`};

    > div {
      padding: 11px 10px 14px 10px;
      color: ${({ theme }): string => theme.textColors.LIGHT_GREY_24};
      font-family: "OpenSansSemiBold", "OpenSans";
    }
  }

  .matching-entity-grid-body {
    border: 1px solid ${({ theme }): string => theme.borderColors.GREY_35};

    .matching-entity-row {
      display: grid;
      align-items: center;
      grid-template-columns: 177.6px 134px 119px 336px 1fr;
      gap: 3px;
      border-bottom: 1px solid
        ${({ theme }): string => theme.borderColors.GREY_35};

      :last-child {
        border-bottom: none;
      }

      > div {
        padding: 11px 10px 14px 10px;
      }

      .select-btn {
        padding: 2px 12px;
        width: fit-content;
        height: 28px;
        cursor: pointer;
        min-width: 80px;
      }

      .match-source-cell {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .entity-cell {
        padding-left: 10px;
        text-align: left;
        display: flex;
        > span {
          ${ellipsis}
        }
      }

      .btn-not-selected,
      .btn-selected {
        border-radius: 13px;
        border: 1px solid ${({ theme }): string => theme.borderColors.DARK_BLUE};
        color: ${({ theme }): string => theme.textColors.DARK_BLUE};
      }

      .btn-selected {
        color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
        background-color: ${({ theme }): string => theme.bgColors.DARK_BLUE};
      }
    }
  }
`;
