import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import LinkButton from "../../../../components/linkbutton";
import SectionBlankSlateText from "../../../../components/sectionblankslatetext";
import { deleteIcon1, plusIconInCircleOutlined } from "../../../../svgs";

import { useOpenModal } from "../../../../customhooks";

import { EditableGridProps } from "./editablegrid.types";
import { TagGridFormType } from "../tagssection/tagssection.types";

import { updateSelectedTagsIds, allSelected } from "./editablegrid.utils";
import { removeIndex } from "../../../../utils";

import { tagsetLabels } from "../../tagsetsdetailpage.constants";

import { EditableGridStyled } from "./editablegrid.styles";
import { CheckboxFieldStyled } from "../../../../components/formfields/checkboxfield/checkboxfield.styles";
import { ProminentTagsType } from "../../../../components/genericprominenttag/genericprominenttag.types";
import { EditableGridRow } from "./editablegrid.components";

const LABELS = tagsetLabels.tagsetdetail.tags;

const EditableGrid = (props: EditableGridProps): JSX.Element => {
  const { isClassificationTagset = false, setState } = props;

  // Custom Hooks
  const onOpenModal = useOpenModal();

  // States
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [
    openedProminentTagsCard,
    setOpenedProminentTagsCard,
  ] = useState<number>();

  // Form
  const { control, setValue, watch } = useFormContext<TagGridFormType>();
  const { fields, prepend, remove, update } = useFieldArray<TagGridFormType>({
    control,
    name: "allTags",
  });

  // Callbacks
  // Add new row
  const handleAddRow = useCallback(() => {
    prepend({
      tag_id: -1,
      tag: "",
      description: "",
      prominent_tag_id: "GRY",
      privacy: "NON",
      usage_count: 0,
    });
  }, []);

  // Delete single row
  const handleDeleteRow = useCallback(
    (index: number) => {
      const tagToDelete = fields?.filter(
        (_, rowIndex) => rowIndex === index
      )?.[0];

      if (tagToDelete?.tag_id === -1) {
        // Newly added tag. Hard delete.
        remove(index);
      } else if (tagToDelete?.usage_count === 0) {
        // Tag coming from BE but has usage count 0. Hard delete but tell BE.
        const {
          tag_id,
          tag,
          description,
          prominent_tag_id,
          privacy,
          usage_count,
        } = tagToDelete;
        setState((s) => ({
          ...s,
          deleted_tags: [
            ...s?.deleted_tags,
            {
              tag_id,
              tag,
              description,
              prominent_tag_id,
              privacy,
              usage_count,
            },
          ],
        }));
        const selectedTagIndexInArr = selectedTags?.findIndex(
          (tagId) => tagId === tag_id
        );
        setSelectedTags(() => [
          ...removeIndex([...selectedTags], selectedTagIndexInArr),
        ]);
        remove(index);
      } else {
        // Tag coming from BE but usage count is not 0. Merge and Delete or Hard delete.
        onOpenModal({
          modalId: "delete_tags",
          visible: true,
          modalTitle: "Delete Tags",
          modalProps: {
            allTags: [...fields],
            selectedTagsId: [tagToDelete?.tag_id],
            setState,
            setSelectedTags,
            update,
            remove,
          },
        });
      }
    },
    [selectedTags, fields]
  );

  // Delete multiple rows
  const handleDeleteRows = useCallback(() => {
    onOpenModal({
      modalId: "delete_tags",
      visible: true,
      modalTitle: "Delete Tags",
      modalProps: {
        allTags: [...fields],
        selectedTagsId: [...selectedTags],
        setState,
        setSelectedTags,
        remove,
        update,
      },
    });
  }, [selectedTags, fields]);

  // Merge tags
  const handleMergeTags = useCallback(() => {
    const selected_tags = [
      ...(fields?.filter((tag) => selectedTags?.includes(tag?.tag_id)) || []),
    ];

    onOpenModal({
      modalId: "merge_tags",
      visible: true,
      modalTitle: "Merge Tags",
      modalProps: {
        allTags: [...fields],
        selected_tags,
        selectedTagsId: [...selectedTags],
        setState,
        setSelectedTags,
        remove,
        update,
      },
    });
  }, [selectedTags, fields]);

  // Select all tags
  const handleSelectAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const isChecked = e?.target?.checked || false;

      setSelectedTags([...allSelected(isChecked, [...fields])]);
    },
    [fields]
  );

  // Select single tag
  const handleSelectTag = useCallback((e: CheckboxChangeEvent, id: number) => {
    const isChecked = e?.target?.checked || false;

    setSelectedTags((s) => [...updateSelectedTagsIds(isChecked, id, [...s])]);
  }, []);

  // Memoized Variables
  const isSelectAll = useMemo(() => {
    const tagsWithIds = [
      ...(fields?.filter((tag) => tag?.tag_id !== -1) || []),
    ];

    return (
      tagsWithIds?.length > 0 && selectedTags?.length === tagsWithIds?.length
    );
  }, [selectedTags, fields]);

  const isSelectAllDisabled = useMemo(() => {
    return fields?.filter((tag) => tag?.tag_id !== -1)?.length === 0;
  }, [fields]);

  const getSelectedProminentTag = (index: number): ProminentTagsType =>
    watch()?.allTags?.[index]?.prominent_tag_id;

  const onProminentTagsCardItemClick = useCallback(
    (e, index: number, prominentTag: ProminentTagsType) => {
      e?.stopPropagation();
      setValue(`allTags.${index}.prominent_tag_id`, prominentTag);
      setOpenedProminentTagsCard(-1);
    },
    [setValue]
  );

  const onProminentTagsCardVisibleChange = useCallback(
    (visible: boolean, index: number) => {
      setOpenedProminentTagsCard(visible ? index : -1);
    },
    []
  );

  const renderGridRows = useMemo(() => {
    return fields?.map((item, index: number) => {
      const selectedProminentTag = getSelectedProminentTag(index);

      return (
        <EditableGridRow
          key={`tagsets-detail-page-editable-grid-row-${item?.id}`}
          index={index}
          isClassificationTagset={isClassificationTagset}
          field={item}
          openedProminentTagsCard={openedProminentTagsCard}
          setOpenedProminentTagsCard={setOpenedProminentTagsCard}
          selectedTags={selectedTags}
          selectedProminentTag={selectedProminentTag}
          handleSelectTag={handleSelectTag}
          control={control}
          setValue={setValue}
          handleDeleteRow={handleDeleteRow}
          onProminentTagsCardItemClick={onProminentTagsCardItemClick}
          onProminentTagsCardVisibleChange={onProminentTagsCardVisibleChange}
        />
      );
    });
  }, [
    fields,
    selectedTags,
    openedProminentTagsCard,
    setOpenedProminentTagsCard,
    isClassificationTagset,
    control,
    setValue,
    handleDeleteRow,
    handleSelectTag,
    getSelectedProminentTag,
    onProminentTagsCardItemClick,
    onProminentTagsCardVisibleChange,
  ]);

  return (
    <EditableGridStyled
      isClassificationTagset={isClassificationTagset}
      isScrollExists={fields?.length > 6}
    >
      <div className="grid-wrapper">
        <div className="action-buttons-row">
          <LinkButton onClick={handleAddRow}>
            {plusIconInCircleOutlined("14.4", "14.4")} Add
          </LinkButton>
          <LinkButton
            disabled={selectedTags?.length <= 1}
            onClick={handleMergeTags}
          >
            {plusIconInCircleOutlined("14.4", "14.4")} Merge
          </LinkButton>
          <LinkButton
            className="delete-btn"
            disabled={selectedTags?.length <= 1}
            onClick={handleDeleteRows}
          >
            {deleteIcon1("13.4", "14.4")} Delete
          </LinkButton>
        </div>

        <div className="grid">
          <div className="grid-header">
            <div className="column first-header-column">
              <CheckboxFieldStyled
                onChange={handleSelectAll}
                defaultChecked={false}
                checked={isSelectAll}
                disabled={isSelectAllDisabled}
              />
              <div>{LABELS?.name}</div>
            </div>
            <div className="column">{LABELS?.description}</div>
            <div className="column">{LABELS?.prominent_tag}</div>
            {isClassificationTagset && (
              <div className="column">{LABELS?.privacy}</div>
            )}
            <div className="column action-column">{LABELS?.action}</div>
          </div>
          <div className="grid-content">
            <SectionBlankSlateText isEmpty={!fields?.length}>
              <>{renderGridRows}</>
            </SectionBlankSlateText>
          </div>
        </div>
      </div>
    </EditableGridStyled>
  );
};

export default EditableGrid;
