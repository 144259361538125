import styled from "styled-components";

export const AddCountFormStyled = styled.div<{
  maxHeight?: string;
  isFromRuleSet?: boolean;
  isRulesetOrCustomquery?: boolean;
}>`
  padding-left: ${({ isFromRuleSet, isRulesetOrCustomquery }): string =>
    isFromRuleSet ? "20%" : isRulesetOrCustomquery ? "104px" : "265px"};

  height: 100%;
  min-height: 80vh;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  max-height: calc(100vh - 110px);
  overflow-y: auto;

  .form-actions-sec {
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .AddRulesetFormGridWrapperStyled {
    margin-left: -240px;
    width: 93.4vw;
    /* width: 100%; */
  }

  .modal-loading-state {
    position: fixed;
  }
`;
