// <--Library Imports Start-->
import styled, { createGlobalStyle } from "styled-components";
// <--Library Imports End-->

import { TutorialStep } from "../../parsers/helpandonboardingparser/helpandonboardingparser.types";
import { pulseAnimationCss } from "../../styles/app.styles";

export const OnboardingTutorialStyled = styled.div`
  .onboarding-popup {
    .onboarding-popup-header {
      display: flex;
      align-items: center;

      .onboarding-popup-header-left {
        flex: 1;
        font-size: 12px;
        color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
      }

      .onboarding-popup-header-right {
        cursor: pointer;
        pointer-events: all;
        svg {
          width: 12px;
          height: 12px;
          path {
            fill: white;
          }
        }
      }
    }

    .onboarding-popup-title {
      margin: 10px 0 6px 0;
      color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
      font-size: 16px;
      font-family: OpenSansSemiBold;
    }

    .onboarding-popup-desc {
      color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
      margin-bottom: 13px;
    }

    .onboarding-popup-footer {
      display: flex;
      align-items: center;
      padding: 14px;
      background-color: #3b7296;
      color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};

      .bold-text {
        font-family: OpenSansSemiBold;
        margin: 0 4px;
      }

      svg {
        margin-right: 10px;
      }
    }

    .onboarding-popup-action-btn {
      float: right;
      pointer-events: all;
      cursor: pointer;
      width: 60px;
      min-height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
      font-size: 16px;
      font-family: OpenSansSemiBold;
    }
  }
`;

export const OnboardingModeGlobalStyled = createGlobalStyle<{
  id: string;
  allowEvents: boolean;
  padding: string;
  margin: string;
  isPlaceLeftArrowTop: boolean;
  transformElement: boolean;
  item?: TutorialStep;
}>`
  #onboarding-popover {
    width: 400px;
    padding: 18px 16px 24px 24px;
    border-radius: 2px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    background-color: #2e6589;
    z-index: 10001;
    position: ${({ transformElement }): string =>
      transformElement ? "fixed !important" : "absolute"};
    top: ${({ transformElement }): string =>
      transformElement ? "50% !important" : ""};
    left: ${({ transformElement }): string =>
      transformElement ? "50% !important" : ""};
    right: ${({ transformElement }): string =>
      transformElement ? "unset !important" : ""};
    bottom: ${({ transformElement }): string =>
      transformElement ? "unset !important" : ""};
    transform: ${({ transformElement }): string =>
      transformElement ? "translate(-50%, -50%) !important" : ""};
      transform: translate(0, -100%);
  transition: opacity 0.3s, transform 0.3s;

  }

  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #2e6589;
  }

  #arrow {
    visibility: hidden;
    transition: transform 0.3s;
  }

  #arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }

  #onboarding-popover[data-popper-placement^="top"] > #arrow {
    bottom: -4px;
  }

  #onboarding-popover[data-popper-placement^="bottom"] > #arrow {
    top: -4px;
  }

  #onboarding-popover[data-popper-placement^="left"] > #arrow {
    right: -4px;
  }

  #onboarding-popover[data-popper-placement^="right"] > #arrow {
    left: -4px;
  }

  div,
  p,
  li,
  a,
  span,
  button {
    pointer-events: none;
  }

  .ant-modal-close-x {
    svg {
      pointer-events: none !important;
    }
  }

  .ant-drawer {
    .ant-drawer-mask {
      pointer-events: none !important;
    }

    .ant-drawer-body {
      pointer-events: all;
    }
  }

  #${({ id }): string => id} {
    pointer-events: ${({ allowEvents }): string =>
      allowEvents ? "all" : "none"};
    padding: ${({ padding }): string => padding || ""};
    margin: ${({ margin }): string => margin || ""};
    border-radius: 8px;
    z-index: 10;
    a,
    span,
    li,
    div,
    button,
    p {
      pointer-events: ${({ allowEvents }): string =>
        allowEvents ? "all" : "none"};
    }



${pulseAnimationCss}
  }

  ${({ item }): string =>
    item?.is_allow_dropdown_menu
      ? `
    .ant-select-item-option {
      pointer-events: all;
      cursor: pointer;
      div,
      span {
        pointer-events: all;
        cursor: pointer;
      }
    }
`
      : `
    .ant-select-item-option {
      pointer-events: none;
      cursor: auto;
      div,
      span {
        pointer-events: none;
        cursor: auto;
      }
    }
    `}
`;
