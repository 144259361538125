//  <--Library Imports Start-->
import { useMemo, memo, useEffect } from "react";
import { useIsFirstRender } from "usehooks-ts";
//  <--Library Imports End-->

//  <--Components Start-->
import AgGridTable from "../../../../../components/aggridtable";
//  <--Components End-->

//  <--Constants Start-->
import { ELEMENT_IDS } from "../../../../../constants";
//  <--Constants End-->

//  <--Rendrer Start-->
import {
  HeaderRenderers,
  StringTextEscapeCharRenderer,
} from "./columnsampledata.rendrer";
//  <--Rendrer End-->

//  <Service Start-->
import { useGetTableDataTabData } from "../../../../../api/columnsservice";
//  <--Service End-->

//  <--Styles Start-->
import { ColumnSampleDataViewStyled } from "./columnsampledata.styles";
//  <--Styles End-->

//  <--Types Start-->
import {
  ColSampleDataHeaderCompParams,
  ColumnSampleDataType,
} from "./columnsampledata.types";

import { TableColumns } from "../../../../../components/aggridtable/aggridtable.types";
import StateHandler from "../../../../statehandler/statehandler";

import { tableViewBlankSlate } from "../../../../../blankslates/tableviewblankslate";
import SectionBlankSlateText from "../../../../sectionblankslatetext/sectionblankslatetext";

import { GatewayStatusCheckingWrapper } from "../../../../../pages/analysisdetailpage/analysisdetailpage.views/analysisdetailpagecontentsec/analysisdetailpagecontentsec.components";
import { maskString } from "../../../../../utils";

import { useGetCurrentGateway } from "../../../../../customhooks";
import ConditionalWrapper from "../../../../conditionalwrapper/conditionalwrapper";
//  <--Types End-->

const {
  datdict_detpg_data_smpdat_dtgrid: DATDICT_DETPG_DATA_SMPDAT_DTGRID,
} = ELEMENT_IDS;

function ColumnSampleData(props: ColumnSampleDataType): JSX.Element {
  const {
    tableId,
    isShowChart,
    isUsingOutsideTableDetailPage = false,
    refreshSampleData = false,
  } = props;

  const isFirstRender = useIsFirstRender();

  const { parsedData, isLoading, error } = useGetTableDataTabData(
    tableId,
    true,
    true
  );

  const {
    sample_data_header_rendrer: sampleDataHeaderRendrer = [],
    sampleDataError,
    gatewayId = "",
    isSampleDataLoading,
    refetchSampleData,
  } = parsedData || {};

  useEffect(() => {
    if (!isFirstRender) refetchSampleData();
  }, [refreshSampleData]);

  const { isGatewayRunning } = useGetCurrentGateway(gatewayId);

  const columns: TableColumns<any> = useMemo(() => {
    const headerCompParams: ColSampleDataHeaderCompParams = {
      headerconfig: sampleDataHeaderRendrer,
      isShowChart,
    };

    return (
      sampleDataHeaderRendrer?.map((item) => ({
        headerName: item?.column_display_name || "",
        field: item?.column_name || "",
        minWidth: 219,
        headerComponentParams: headerCompParams,
        flex: 1,
        ...(item?.is_col_masked && {
          valueGetter: (event: any): any => {
            return maskString(event?.data?.[item?.column_name]);
          },
        }),

        ...(!item?.is_col_masked && {
          cellRenderer: "StringTextEscapeCharRenderer",
        }),
      })) || []
    );
  }, [isShowChart, parsedData]);

  const showLoading =
    isSampleDataLoading ||
    isLoading ||
    (isUsingOutsideTableDetailPage ? isLoading : false);

  return (
    <StateHandler
      isFetching={showLoading}
      showSpinner={showLoading}
      blankSlate={tableViewBlankSlate}
      error={isGatewayRunning ? sampleDataError || error : false}
      isShowBlankSlate={false}
      spinnerHeight="400px"
    >
      <ColumnSampleDataViewStyled
        isEmpty={!parsedData?.columnSampleData?.length}
        isColumnDataEmpty={!columns?.length}
        id={DATDICT_DETPG_DATA_SMPDAT_DTGRID}
        isGatewayRunning={!!isGatewayRunning}
      >
        <SectionBlankSlateText
          isEmpty={!columns?.length}
          text="This table has no columns and sample data to show"
        >
          <ConditionalWrapper
            condition={!parsedData?.columnSampleData?.length}
            wrapper={(children): JSX.Element => (
              <GatewayStatusCheckingWrapper
                type="error_with_children"
                gatewayId={gatewayId}
              >
                <>{children}</>
              </GatewayStatusCheckingWrapper>
            )}
          >
            <AgGridTable
              theme="ag-theme-alpine table-container"
              rowData={parsedData?.columnSampleData || []}
              defaultColDef={{
                resizable: true,
              }}
              rowHeight={43}
              {...(!isUsingOutsideTableDetailPage && {
                maxHeight: "calc(100vh - 326px)",
              })}
              headerHeight={isShowChart ? 210 : 47}
              domLayout="autoHeight"
              tableColumns={columns}
              frameworkComponents={{
                agColumnHeader: HeaderRenderers,
                StringTextEscapeCharRenderer,
              }}
              suppressCellSelection
              isTableWidthfitTowindowSize={false}
              suppressFieldDotNotation
              pagination={!!parsedData?.columnSampleData?.length}
            />
          </ConditionalWrapper>
        </SectionBlankSlateText>
      </ColumnSampleDataViewStyled>
    </StateHandler>
  );
}

export default memo(ColumnSampleData);
