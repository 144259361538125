import { Suspense } from "react";
import { RouteProps, Redirect, Route } from "react-router-dom";
import DvSumFallBackSpinner from "../../components/dvsumfallbackspinner";

const AuthRoute = ({
  children,
  isLoggedIn,
  ...rest
}: RouteProps & { isLoggedIn: boolean }): JSX.Element => {
  return (
    <Route
      {...rest}
      render={({ location }): JSX.Element | React.ReactNode =>
        !isLoggedIn ? (
          <Suspense fallback={<DvSumFallBackSpinner />}>{children}</Suspense>
        ) : (
          <Redirect to={{ pathname: "/", state: location }} />
        )
      }
    />
  );
};

export default AuthRoute;
