import { TextStyled } from "./text.styles";
import { TextProps } from "./text.types";

const Text = (props: TextProps): JSX.Element => {
  const {
    fontSize = 14,
    isBold = false,
    children,
    isPrimary = true,
    spacing = {},
  } = props;

  const { bottom = 0, left = 0, right = 0, top = 0 } = spacing;

  return (
    <TextStyled
      fontSize={fontSize}
      isBold={isBold}
      isPrimary={isPrimary}
      spacing={{ bottom, left, right, top }}
    >
      {children}
    </TextStyled>
  );
};

export default Text;
