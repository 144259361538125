import { AxiosResponse } from "axios";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import {
  appendQueryParamInUrl,
  useRequestWithMethod,
} from "../../../../../api";
import { Button } from "../../../../../components";
import { FormItemStyled, FormStyled } from "../../../../../components/form";
import StateHandler from "../../../../../components/statehandler/statehandler";
import { API_CONFIG } from "../../../../../constants/apiconfig";
import { useCancelModal, useGetAppState } from "../../../../../customhooks";
import { ExcelSourceFilesListResponse } from "../../../../../parsers/sources/sourcesparser.types";
import { DeleteExcelSourceFilesStyled } from "../excelsourcefiles.styles";

function DeleteExcelSourceFile(): JSX.Element {
  const { modal = {} } = useGetAppState();
  const onCancelModal = useCancelModal();
  const queryClient = useQueryClient();

  const { modalProps = {} } = modal;
  const {
    fileName = "",
    fileId = "",
    folderName = "",
    onSuccess,
    isAnyFileUploading,
  } = modalProps;

  const onSucessOfDeletion = useCallback(() => {
    if (isAnyFileUploading) {
      onSuccess && onSuccess();
    } else {
      const api = appendQueryParamInUrl(
        API_CONFIG.get_excel_source_folder_files.url,
        [folderName]
      );
      const previousData = queryClient.getQueryData<
        AxiosResponse<ExcelSourceFilesListResponse>
      >(api);
      const updatedData =
        previousData?.data?.body?.filter((item) => item?.Key !== fileId) || [];

      queryClient.setQueryData(api, {
        ...previousData,
        data: { body: updatedData, statusCode: 200 },
      });
    }

    onCancelModal();
  }, [modalProps]);

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "delete_excel_source_file",
    [fileId],
    true,
    onSucessOfDeletion
  );

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <DeleteExcelSourceFilesStyled>
        <div className="text">
          Are you sure you want to delete the file "{fileName}"?
        </div>
        <FormStyled>
          <FormItemStyled label="" className="form-actions-sec">
            <Button id="cancel" width="65px" onClick={onCancelModal}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              onClick={(): void => onExecuteRequest()}
            >
              Delete
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DeleteExcelSourceFilesStyled>
    </StateHandler>
  );
}

export default DeleteExcelSourceFile;
