import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "react-query";
import { useRequestWithMethod } from "../../api";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { TextAreaField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";
import { DeactivateUserFormStyled } from "./deactivateuserform.styles";
import { deactivateUserSchema } from "../../utils/schemas/userschemas";
import { UsersGridColumnsType } from "../../pages/userspage/userspage.types";
import { useCancelModal, useGetAppState } from "../../customhooks";
import { isArray, updateArrayWithSameSequence } from "../../utils";
import { API_CONFIG } from "../../constants/apiconfig";

const api = API_CONFIG?.get_all_users?.url;

function DeactivateUserForm(): JSX.Element {
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: { assign_user_to: undefined },
    mode: "onChange",
    resolver: yupResolver(deactivateUserSchema),
  });
  const {
    modal: { modalProps = {}, modalId },
  } = useGetAppState();

  const { id = "" } = modalProps as UsersGridColumnsType;

  const onCancel = useCancelModal();

  const onSuccess = useCallback(
    (res) => {
      const isArrayData = isArray(res?.data);
      const hasData = isArrayData ? res?.data?.length : res?.data;

      if (hasData) {
        const data = isArrayData ? res?.data?.[0] : res?.data;

        queryClient?.setQueryData(api, (oldData: any) => {
          return {
            ...oldData,
            data: updateArrayWithSameSequence(
              oldData.data,
              "USER_ID",
              data?.USER_ID,
              data
            ),
          };
        });
      }

      onCancel();
    },
    [modalProps]
  );

  const {
    isLoading: isLoadingInviteUser,
    error: errorInviteUser,
    onExecuteRequest: onExecuteRequestInviteUser,
  } = useRequestWithMethod("resend_invite_user", [id], false, onSuccess);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "deactivate_user",
    [id],
    true,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    modalId === "activate_user"
      ? onExecuteRequestInviteUser()
      : onExecuteRequest({
          status: "INA",
          comments: values?.comment,
        });
  }, []);

  return (
    <StateHandler
      isModal
      isFetching={isLoading || isLoadingInviteUser}
      error={error || errorInviteUser}
    >
      <DeactivateUserFormStyled>
        <FormStyled
          isItemColumnLayout
          paddingLeft="110px"
          onFinish={handleSubmit(onSubmit) as any}
        >
          <div className="content-sec">
            <FormItemStyled label="Comment" marginBottom="60px" required>
              <TextAreaField name="comment" control={control} width="410px" />
            </FormItemStyled>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button
              id="primary"
              width="78px"
              height="39px"
              htmlType="submit"
              disabled={!isValid}
            >
              {modalId === "activate_user" ? "Activate" : "Deactivate"}
            </Button>
            <Button
              id="primary"
              width="78px"
              height="39px"
              marginLeft="5px"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </FormItemStyled>
        </FormStyled>
      </DeactivateUserFormStyled>
    </StateHandler>
  );
}

export default DeactivateUserForm;
