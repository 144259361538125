import { useCallback, useRef, useState } from "react";
import { useOutsideClick } from "../../utils/useoutsideclick";
import LinkButton from "../linkbutton";
import { ShowMoreOrLessTextProps } from "./showmoreorlesstext.types";

function ShowMoreOrLessText(props: ShowMoreOrLessTextProps): JSX.Element {
  const { text = "", textLimit = 130 } = props;
  const [showMore, setShowMore] = useState(false);
  const showMoreOrLessRef = useRef(null);

  const isTextGreateThanLimit = text?.length > textLimit;

  const onChangeShowMore = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  useOutsideClick(
    showMoreOrLessRef,
    (): void => {
      setShowMore(false);
    },
    [showMore]
  );

  return (
    <span
      ref={showMoreOrLessRef}
      data-testid="show-more-text-main-wrapper"
      className="show-more-text-main-wrapper"
      title={text}
    >
      {isTextGreateThanLimit ? (
        <>
          {showMore ? <>{text}</> : <> {text?.substring(0, textLimit)}...</>}
          <LinkButton
            onClick={onChangeShowMore}
            marginLeft="5px"
            data-testid="show-more-btn"
          >
            {showMore ? "Show Less" : "Show More"}
          </LinkButton>
        </>
      ) : (
        <>{text || "--"}</>
      )}
    </span>
  );
}

export default ShowMoreOrLessText;
