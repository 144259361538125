import Dragger from "antd/lib/upload/Dragger";
import styled from "styled-components";

export const SingleFileUploaderWithProgressBarStyled = styled.div`
  .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50px;
    border: 2px solid #d3d3d3;
    width: 19px;
    height: 19px;
    background: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    padding: 3px;

    svg {
      position: relative;
      top: -8px;
      path {
        fill: ${({ theme }): string => theme.bgColors.RED_100};
      }
    }
  }
`;

export const SingleFileDraggerStyled = styled(Dragger)`
  &.ant-upload.ant-upload-drag.upload-error {
    border-color: ${({ theme }): string => theme.borderColors.RED};
    .additional-file-info {
      color: ${({ theme }): string => theme.textColors.RED};
    }
  }
  &.ant-upload.ant-upload-drag {
    display: flex;
    align-items: center;
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    border-radius: 6.4px;
    border-color: ${({ theme }): string => theme.borderColors.DARK_GREY_101};
    min-height: 100px;

    &:hover {
      border-color: ${({ theme }): string => theme.borderColors.YELLOW_RD};
    }

    &.file-added {
      .ant-upload-btn {
        padding: 24.8px 0;
      }
    }

    .ant-upload-btn {
      padding: 0;

      .ant-upload-drag-container {
        .loading-wrapper {
          margin: auto;
          display: flex;
          flex-direction: column;
          row-gap: 12.8px;
          width: 512.8px;

          .loading-text {
            font-weight: 600;
            font-size: 13.6px;
            color: ${({ theme }): string => theme.textColors.GREY_210};
          }
        }

        .ant-upload-text {
          font-family: OpenSansSemiBold;
          color: ${({ theme }): string => theme.textColors.GREY_210};

          .blue-text {
            color: ${({ theme }): string => theme.textColors.BLUE_116};
          }
        }
        > svg {
          path {
            fill: ${({ theme }): string => theme.textColors.LIGHT_GREY_40};
          }
        }
        .additional-file-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 12.6px;
          font-size: 13.6px;

          .file-info {
            color: ${({ theme }): string => theme.textColors.GREY_24};
            .file-name {
              font-weight: 600;
            }
          }

          .parse-desc {
            font-size: 12px;
            color: ${({ theme }): string => theme.textColors.GREY_210};
          }
        }
      }
    }
  }
`;
