import { AxiosResponse } from "axios";
import { jsonParse } from "../../utils";
import {
  ModuleSettingsObjResponse,
  ModuleSettingsResponse,
  ModuleSettingsReturnType,
} from "./modulesettingparser.types";
import { apiToFormCriteria } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";

export const getParsedModuleSettings = ({
  data,
}: AxiosResponse<ModuleSettingsResponse[]>): ModuleSettingsReturnType => {
  const dataKeys = Object.keys(data?.[0]);

  const moduleName = dataKeys?.[0];

  const moduleSettingObj: ModuleSettingsObjResponse = jsonParse(
    data?.[0]?.[moduleName] || ""
  );

  const accountSubObj = jsonParse(data?.[1]?.ACCOUNT_SUBSCRIPTION || "");

  const isTermCriteriaExists = !!moduleSettingObj?.filter_criteria?.TRM?.filter;
  const isTableCriteriaExists = !!moduleSettingObj?.filter_criteria?.TBL
    ?.filter;
  const isColumnCriteriaExists = !!moduleSettingObj?.filter_criteria?.COL
    ?.filter;

  const defaultSettingsFlag =
    moduleSettingObj?.default_flag !== undefined
      ? moduleSettingObj?.default_flag
        ? "enable"
        : "disable"
      : "enable";

  const parsedSettings = {
    is_active: moduleSettingObj?.is_active,
    is_finegrain_enabled: moduleSettingObj?.is_finegrain_enabled,
    default_flag: defaultSettingsFlag,
    enabled_objects: moduleSettingObj?.enabled_objects?.map((node: any) => ({
      keys: [
        node?.additional_info?.node_key || "",
        ...(node?.additional_info?.schema_keys || []),
        ...(node?.additional_info?.table_keys || []),
      ],
      node_access: node?.node_access || false,
    })),
    term_criteria_enabled: isTermCriteriaExists,
    table_criteria_enabled: isTableCriteriaExists,
    column_criteria_enabled: isColumnCriteriaExists,
    specify_term_criteria: apiToFormCriteria(
      moduleSettingObj?.filter_criteria?.TRM?.filter,
      moduleSettingObj?.filter_criteria?.TRM?.readableFilter
    ),
    specify_table_criteria: apiToFormCriteria(
      moduleSettingObj?.filter_criteria?.TBL?.filter,
      moduleSettingObj?.filter_criteria?.TBL?.readableFilter
    ),
    specify_column_criteria: apiToFormCriteria(
      moduleSettingObj?.filter_criteria?.COL?.filter,
      moduleSettingObj?.filter_criteria?.COL?.readableFilter
    ),
  };

  return {
    moduleSetting: parsedSettings,
    accountSubscription: accountSubObj,
  };
};
