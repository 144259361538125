import { ProminentTagsProps } from "../components/prominenttagslist/prominenttagslist.types";
import { SectionRowProps } from "../components/sectionrow/sectionrow.types";
import { CERTIFY_TAGSET } from "../pages/tablepage/tablepage.constants";

export function getProminentTags(
  classificationData: SectionRowProps[] = []
): ProminentTagsProps["tags"] {
  const certifiedTagset =
    classificationData?.filter((item) => item?.title === CERTIFY_TAGSET) || [];
  const otherTagsets =
    classificationData?.filter(
      (otherTagset) => otherTagset?.title !== CERTIFY_TAGSET
    ) || [];

  const sortedTagsets = [...certifiedTagset, ...otherTagsets];
  const sortedProminentTags = sortedTagsets
    ?.filter((sortedItem) => sortedItem?.prominentTags?.tags?.length)
    ?.map((filteredItem) => filteredItem?.prominentTags?.tags || [])
    ?.flat();

  return sortedProminentTags || [];
}
