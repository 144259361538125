import { useFormContext } from "react-hook-form";
import { Button } from "../../../../components";
import { FormItemStyled } from "../../../../components/form";
import { useCancelModal } from "../../../../customhooks";
import { ShareFooterProps } from "./sharefooter.types";
import { isSaveDisabled } from "./sharefooter.utils";

const ShareFooter = (props: ShareFooterProps): JSX.Element => {
  const { isShowShareForm } = props;
  const {
    formState: { isValid, dirtyFields },
  } = useFormContext();

  const onCancel = useCancelModal();

  return (
    <FormItemStyled label="" className="form-actions-sec" marginBottom="0px">
      <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
        Cancel
      </Button>
      {isShowShareForm ? (
        <Button
          id="primary"
          width="74px"
          height="39px"
          marginLeft="8px"
          htmlType="submit"
          disabled={!isValid}
        >
          Share
        </Button>
      ) : (
        <Button
          id="primary"
          width="74px"
          height="39px"
          marginLeft="8px"
          htmlType="submit"
          disabled={isSaveDisabled(dirtyFields)}
        >
          Save
        </Button>
      )}
    </FormItemStyled>
  );
};

export default ShareFooter;
