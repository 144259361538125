import * as yup from "yup";

import {
  REQUIRED_MESSAGE,
  CHARACTERS_LIMIT_EXCEEDED,
  MAX_USER_SELECTION_MESSAGE,
} from "../../constants/formconstants";

export const dqRuldeActionsFormSchema = ({
  isAssign,
}: {
  isAssign: boolean;
}): yup.ObjectSchema<any> =>
  yup.object().shape({
    ...(isAssign
      ? {
          assigned_to_name: yup
            .array()
            .of(yup.string())
            .required(REQUIRED_MESSAGE)
            .min(1, REQUIRED_MESSAGE)
            .max(1, MAX_USER_SELECTION_MESSAGE),
        }
      : {
          resolution_action_id: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .min(1, REQUIRED_MESSAGE),
        }),
    comments: yup
      .string()
      .max(1000, CHARACTERS_LIMIT_EXCEEDED(1000))
      .required(REQUIRED_MESSAGE),
  });
