import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { SelectField } from "../../components/formfields";
import { TagsGrid } from "../../pages/tagsetsdetailpage/tagsetsdetailpage.components";

import { useCancelModal, useGetAppState } from "../../customhooks";

import {
  DeleteTagsFormType,
  DeleteTagsModalProps,
} from "./deletetagsform.types";
import { HandleSubmitType } from "../../app.types";
import { TagType } from "../../pages/tagsetsdetailpage/tagsetsdetailpage.types";

import { mergeTags } from "./deletetagsform.utils";

import { deleteTagNote } from "./deletetagsform.constants";

import { DeleteTagsFormStyled } from "./deletetagsform.styles";

const DeleteTagsForm = (): JSX.Element => {
  const onCancel = useCancelModal();
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    allTags,
    selectedTagsId,
    setState,
    setSelectedTags,
    update,
    remove,
  } = modalProps as DeleteTagsModalProps;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
  } = useForm<DeleteTagsFormType>({
    defaultValues: { target_tag: undefined },
  });

  const targetTagValue = watch("target_tag");

  // Memoized variables
  const optionsForSelect = useMemo(() => {
    return allTags
      ?.filter(
        (tag) => tag?.tag_id !== -1 && !selectedTagsId?.includes(tag?.tag_id)
      )
      ?.map((tag) => ({
        label: tag?.tag || "",
        value: `${tag?.tag_id || 0}`,
        key: `${tag?.tag_id || 0}`,
      }));
  }, [allTags, selectedTagsId]);

  const selectedTags = useMemo(() => {
    return allTags?.filter((tag) => selectedTagsId?.includes(tag?.tag_id));
  }, [allTags, selectedTagsId]);

  // Callbacks
  const onSubmit = useCallback(
    (values: DeleteTagsFormType) => {
      const targetTagId = Number.parseInt(`${values?.target_tag}`);

      if (targetTagId) {
        // Target tag with updated usage count
        const mergedTags = {
          ...mergeTags([...allTags], [...selectedTags], `${targetTagId}`),
        };

        // Updating the usage count of target tag
        const tagToUpdateIndex = allTags?.findIndex(
          (tag) => tag?.tag_id === targetTagId
        );

        update(tagToUpdateIndex, mergedTags);

        // Removing selected tags from fields list.
        const tagsToDeleteIndexes: number[] = allTags
          ?.map((tag, index) =>
            selectedTagsId?.includes(tag?.tag_id) ? index : -1
          )
          ?.filter((tagIndex) => tagIndex !== -1);

        remove([...tagsToDeleteIndexes]);

        // Adding in merged_tags array for using when sending data to BEs
        setState((s) => ({
          ...s,
          merged_tags: [
            ...s?.merged_tags,
            {
              source_tags: selectedTagsId,
              target_tag: targetTagId,
            },
          ],
        }));
      } else {
        // Hard delete
        const tagsToDelete = selectedTags?.map(
          (tag): TagType => ({
            tag_id: tag?.tag_id,
            tag: tag?.tag,
            description: tag?.description,
            prominent_tag_id: tag?.prominent_tag_id,
            usage_count: tag?.usage_count,
          })
        );
        const tagsToDeleteIndexes: number[] = allTags
          ?.map((tag, index) =>
            selectedTagsId?.includes(tag?.tag_id) ? index : -1
          )
          ?.filter((tagIndex) => tagIndex !== -1);

        remove(tagsToDeleteIndexes);
        setState((s) => ({
          ...s,
          deleted_tags: [...s?.deleted_tags, ...tagsToDelete],
        }));
      }

      setSelectedTags([]);

      onCancel();
    },
    [selectedTagsId, selectedTags, allTags, modalProps]
  );

  return (
    <DeleteTagsFormStyled>
      <FormStyled
        onFinish={handleSubmit(onSubmit) as HandleSubmitType}
        isItemColumnLayout
      >
        <div className="form-content">
          <div className="confirmation-text">{deleteTagNote}</div>

          <FormItemStyled label="Target Tag">
            <SelectField
              control={control}
              name="target_tag"
              options={optionsForSelect}
              setValue={setValue}
              placeholder="Select"
            />
          </FormItemStyled>

          <TagsGrid tags={selectedTags} isShowBorder={false} />
        </div>

        <FormItemStyled
          label=""
          className="form-actions-sec"
          marginBottom="0px"
        >
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button id="primary" width="74px" marginLeft="8px" htmlType="submit">
            {targetTagValue === undefined ? "Delete" : "Migrate and Delete"}
          </Button>
        </FormItemStyled>
      </FormStyled>
    </DeleteTagsFormStyled>
  );
};

export default DeleteTagsForm;
