import styled from "styled-components";
import { DatePickerStyled } from "../../datepicker/datepikcer.styles";

export const DatePickerFieldContainerStyled = styled.div<{
  width?: string;
  isError: boolean;
}>`
  .ant-picker {
    ${({ isError, theme }): string =>
      isError
        ? `border: 1px solid ${theme.borderColors.RED_11} !important;`
        : "border: 1px solid transparent"}

    :hover {
      border: 1px solid transparent;
      ::placeholder {
        font-size: unset;
      }
    }
  }
  .error {
    color: ${({ theme }): string => theme.textColors.RED};
    text-align: right;
  }
`;

export const DatePickerFieldStyled = styled(DatePickerStyled)<{
  width?: string;
  height?: string;
}>``;
