import styled from "styled-components";
import LinkButton from "../../../../../../../../../../../components/linkbutton";

export const QueryActionSectionStyled = styled.div`
  .query-block-header-right-sec {
    display: flex;
    align-items: center;

    .ant-btn-link {
      display: flex;
    }

    .table-actions-sec {
      .tables-action-section-tooltip {
        .ant-tooltip-content {
          .ant-tooltip-inner {
            padding: 0; // TODO: this will be changed in reuseable component in SSA v2

            .tooltip-custom-content {
              .item {
                align-items: center;
              }
            }
          }
        }
      }

      .actions-container {
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }

    .query-delete-icon {
      height: 18px;

      svg {
        path {
          stroke: unset;
          fill: ${({ theme }): string => theme?.textColors?.RED_111};
        }
      }
    }
  }
`;

export const ResultsToggleIconContainerStyled = styled(LinkButton)<{
  isActive?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;

  &,
  &:hover,
  &:focus,
  &:active {
    background: ${({ theme, isActive }): string =>
      isActive ? theme?.bgColors?.LIGHT_BLUE_25 : theme?.bgColors?.WHITE_COLOR};
    border: 1px solid
      ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_22};
    border-radius: 0;
    border-color: ${({ theme, isActive }): string =>
      isActive
        ? theme?.borderColors?.LIGHT_BLUE_27
        : theme?.borderColors?.LIGHT_GREY_22};
  }

  &:nth-child(1) {
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &:hover {
      border-right: 0;
    }
  }

  &:nth-child(3) {
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover {
      border-left: 0;
    }
  }

  &.ant-btn {
    transition: none;
  }

  svg {
    path {
      fill: ${({ theme, isActive }): string =>
        isActive ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_18};
      stroke: ${({ theme, isActive }): string =>
        isActive ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_18};
    }
  }
`;
