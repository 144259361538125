// <--- Library Imports Start --->
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Components Start --->
import { InputField } from "../../components/formfields";

import { Button } from "../../components";
import StateHandler from "../../components/statehandler/statehandler";
// <--- Components End --->

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import { useCancelModal, useGetAppState, useSetData } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Schemas Start --->
import { addSAWSSchema } from "../../utils/schemas/accountsettingsschemas";
// <--- Schemas End --->

// <--- Services Start --->
import { useRequestWithMethod } from "../../api";
// <--- Services End --->

// <--- Styles Start --->
import { AddSAWSFormStyled } from "./addsawsform.styles";
import { FormItemStyled, FormStyled } from "../../components/form";
// <--- Styles End --->

// <--- Types Start --->
import { AddSAWSFormProps, AddSAWSFormType } from "./addsawsform.types";
import { SAWSGridColumnsType } from "../../pages/accountsettingspage/accountsettingspage.views/saws/saws.types";

import { SAWSResponse } from "../../parsers/accountsettings";
import { useGatewayStatusContext } from "../../contexts";
// <--- Types End --->

const AddSAWSForm = (props: AddSAWSFormProps): JSX.Element => {
  const { isEditMode } = props;

  const onCancel = useCancelModal();
  const onSetData = useSetData();

  const { get_all_saws: getAllSAWS } = API_CONFIG;

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    dispatch,
    state: { listOfGateways = [] },
  } = useGatewayStatusContext();

  const {
    id = 0,
    web_service = "",
    host_name = "",
    port = "",
    enable_source_types = [],
    version = "1",
    status = "NAC",
  } = modalProps as SAWSGridColumnsType;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<AddSAWSFormType>({
    defaultValues: {
      name: web_service,
      host_name,
      port,
    },
    resolver: yupResolver(addSAWSSchema),
    mode: "onChange",
  });

  const onSuccess = useCallback(
    (res: AxiosResponse<SAWSResponse[]>) => {
      onSetData(getAllSAWS, res?.data);
      if (isEditMode) {
        const updItem = res?.data?.find((item) => item?.SAWS_ID === id);
        dispatch({
          type: "UPDATE_GATEWAYS_LIST",
          listOfGateways: listOfGateways?.map((item) => ({
            ...item,
            ...(item?.id === `${id}` &&
              updItem && {
                host: updItem?.SAWS_HOSTNAME || "",
                port: `${updItem?.SAWS_PORT ?? ""}`,
                name: updItem?.SAWS_NAME || "",
              }),
          })),
        });
      } else {
        const gatewaysIds = listOfGateways?.map((item) => item?.id);

        const itemToAdd = res?.data?.find(
          (item) => !gatewaysIds?.includes(`${item?.SAWS_ID}`)
        );

        dispatch({
          type: "UPDATE_GATEWAYS_LIST",
          listOfGateways: itemToAdd
            ? [
                ...listOfGateways,
                {
                  host: itemToAdd?.SAWS_HOSTNAME || "",
                  port: `${itemToAdd?.SAWS_PORT ?? ""}`,
                  name: itemToAdd?.SAWS_NAME || "",
                  id: `${itemToAdd?.SAWS_ID}`,
                  isGatewayRunning: false,
                  isOnpremiseGateway: itemToAdd?.IS_ON_PREM_SAWS,
                  isPingcheckInProgress: false,
                  sawsCommunicationKey: itemToAdd?.SAWS_COMMUNICATION_KEY || "",
                },
              ]
            : listOfGateways,
        });
      }
      reset();
      onCancel();
    },
    [listOfGateways]
  );

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode ? "edit_saws" : "add_saws",
    isEditMode ? [id] : undefined,
    true,
    onSuccess
  );

  const onSubmit = useCallback(
    (values) => {
      let requestBody = {
        saws_name: values?.name,
        saws_hostname: values?.host_name,
        saws_port: parseInt(values?.port),
        saws_download_address: "54.186.200.213:8185",
        saws_version: isEditMode ? version : "1.0.0",
      };

      if (isEditMode) {
        requestBody = {
          ...requestBody,
          ...{
            saws_status: status ? "ACT" : "NAC" || "NAC",
            SAWS_LAST_PING_TIME: "",
          },
        };
      }

      onExecuteRequest(requestBody);
    },
    [isEditMode, version, status, enable_source_types]
  );

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <AddSAWSFormStyled>
        <FormStyled onFinish={handleSubmit(onSubmit) as any} isItemColumnLayout>
          <div className="form-content">
            <FormItemStyled label="Name" required>
              <InputField control={control} name="name" />
            </FormItemStyled>

            <FormItemStyled label="Host Name" required>
              <InputField control={control} name="host_name" />
            </FormItemStyled>

            <FormItemStyled label="Port" required>
              <InputField control={control} name="port" />
            </FormItemStyled>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddSAWSFormStyled>
    </StateHandler>
  );
};

export default AddSAWSForm;
