import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setModal, setSecondaryModal } from "../reducers/appreducer/appreducer";

import { AppState } from "../reducers/appreducer/appreducer.types";

export function useOpenModal(): (
  _modal: AppState["modal"],
  _isSecondaryModal?: boolean
) => void {
  const dispatch = useDispatch();

  return useCallback((modal: AppState["modal"], isSecondaryModal?: boolean) => {
    dispatch(isSecondaryModal ? setSecondaryModal(modal) : setModal(modal));
  }, []);
}
