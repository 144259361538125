import styled from "styled-components";
import { MenuItemStyledProps, MenuStyledProps } from "./dropdownmenu.types";

export const MenuStyled = styled.div<MenuStyledProps>`
  width: ${(props): string | undefined => props?.width};
  height: ${(props): string | undefined => props?.height};
  background: ${(props): string => props?.theme?.bgColors?.WHITE_COLOR};
  border: 1px solid ${(props): string => props?.theme?.borderColors?.GREY_56};
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 4px 0;

  ${(props): any =>
    props?.isDisabled &&
    ` >a,div{
pointer-events:none;
  div{
    color: ${props?.theme?.textColors?.GREY_COLOR};
    opacity: 0.5;
    cursor: default;
    :hover {
      background: ${props?.theme?.bgColors?.WHITE_COLOR};
      color: ${props?.theme?.textColors?.GREY_COLOR};
    }
  }
}
`}
`;

export const MenuItemStyled = styled.div<MenuItemStyledProps>`
  :hover {
    background-color: ${(props): string =>
      props?.theme?.bgColors?.LIGHT_BLUE_25};

    .menu-item-button .text {
      color: ${(props): string => props?.theme?.textColors?.BLUE_116};

      svg {
        path {
          fill: ${({ theme }): string => theme?.bgColors?.BLUE_116};
        }
      }
    }
  }

  &.menu-item {
    padding: 7px 12px;
  }

  > a,
  .menu-item-button {
    width: 100%;
    text-align: start;

    .text {
      width: 100%;

      color: ${(props): string => props?.theme?.textColors?.GREY_40};
      cursor: pointer;

      svg {
        path {
          fill: ${({ theme }): string => theme?.bgColors?.GREY_40};
        }
      }
    }
  }
`;
