import styled from "styled-components";

export const CheckboxWithLabelAndDescStyled = styled.div<{
  paddingLeft?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: ${({ paddingLeft }): string => paddingLeft || "0"};

  .label {
    font-family: OpenSansSemiBold;
    font-size: 13.6px;
  }

  .desc {
    font-size: 12px;
    text-align: left;
    color: ${({ theme }): string => theme.textColors.GREY_24};
    padding-left: 25px;
    max-width: 60%;
    margin-bottom: 0;
  }

  .alert {
    width: 430px;

    .ant-alert {
      svg {
        height: 12px;
        margin-right: 10px;
      }
    }
  }

  .link {
    a {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
    }
  }

  .ant-checkbox-wrapper-disabled {
    .label {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    }
  }
`;

export const CheckboxChildrenWrapper = styled.div`
  .children-components {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 25px;
  }
`;
