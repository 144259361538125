import { THEME_COLORS } from "../components/themeprovider/themeprovider.constant";
import { AppState } from "../reducers/appreducer/appreducer.types";

export const getTheme = (
  selectedTheme: AppState["selectedTheme"] = "light"
): { [key: string]: any } => {
  return THEME_COLORS[selectedTheme];
};

export function getErrorMessage(error: any): string {
  return error?.response?.data?.message
    ? error?.response?.data?.message
    : error?.response?.data?.error?.message
    ? error?.response?.data?.error?.message
    : error?.response?.data?.error
    ? error?.response?.data?.error
    : error?.response?.data?.detail
    ? error?.response?.data?.detail
    : error?.response?.data?.email
    ? error?.response?.data?.email
    : error?.message
    ? error?.message
    : error?.response?.status === 503 ||
      error?.code === "ECONNABORTED" ||
      error?.response?.status === 500 ||
      error?.response?.status === 504 ||
      error?.response?.status === 502 ||
      !error?.response
    ? "Network Error"
    : error;
}
