import { API_CONFIG } from "../../constants/apiconfig";

import {
  getParsedJobDocument,
  getParsedJobScheduleDocument,
  getJobExecutionHistoryParsedData,
  getParsedJobRefDocument,
} from "../../parsers/jobparser/jobparser";

import {
  JobDocumentParsedType,
  JobScheduleDocumentParsedType,
  JobExecutionHistoryDataParsedType,
  JobRefDocumentParsedType,
  JobExecutionHistoryDataResponse,
} from "../../parsers/jobparser/jobparser.types";

import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_node: getNodeData,
  get_job_execution_history: getJobExecutionHistory,
  get_job_execution_summary: getJobExecutionSummary,
} = API_CONFIG;

export function useGetJobDocForRefPage(
  nodeID: string,
  nodeType: string,
  nodeStatus: string
): CustomReactQueryHookReturnType<JobRefDocumentParsedType> {
  return useGetAll({
    apiConfig: getNodeData,
    params: [nodeID, nodeType, nodeStatus, "true", "", ""],
    parser: getParsedJobRefDocument,
  });
}

export function useGetJobDocForDetailPage(
  nodeID: string,
  nodeType: string,
  nodeStatus: string
): CustomReactQueryHookReturnType<JobDocumentParsedType> {
  return useGetAll({
    apiConfig: getNodeData,
    params: [nodeID, nodeType, nodeStatus, "false", "", ""],
    parser: getParsedJobDocument,
  });
}

export function useGetJobDocForSchedulePage(
  jobDefId: string,
  schId: string
): CustomReactQueryHookReturnType<JobScheduleDocumentParsedType> {
  return useGetAll({
    apiConfig: getJobExecutionSummary,
    params: [jobDefId, schId],
    parser: getParsedJobScheduleDocument,
  });
}

export function useGetJobExecutionHistory(
  nodeId: string,
  pageSize: string,
  pageNumber: string,
  filter: string,
  startDate: string,
  endDate: string,
  onSuccess: (response: any) => void
): CustomReactQueryHookReturnType<JobExecutionHistoryDataParsedType> {
  return useGetAll({
    apiConfig: getJobExecutionHistory,
    params: [nodeId, pageSize, pageNumber, filter, startDate, endDate],
    parser: getJobExecutionHistoryParsedData,
    options: { enabled: !!nodeId, onSuccess },
  });
}
