import styled from "styled-components";

export const AddGovernanceViewFormStyled = styled.div<{ maxHeight?: string }>`
  .ant-form {
    .ant-form-item {
      .ant-form-item-control {
        min-width: 92%;

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .input-field-container > input {
              width: 512px;
            }

            .textarea-field-container > textarea {
              /*  remove important */
              height: 40px !important;
              min-width: 550px;
            }

            .add-criteria-btn {
              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .scroll-sec {
      padding-top: 29px;
      height: ${({ maxHeight }): string => maxHeight || "calc(100vh - 165px)"};
      overflow: auto;

      .view-name + .error {
        width: 512px;
      }
    }

    .form-item-with-inline-info {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    .form-actions-sec {
      position: absolute;
      width: 100vw;
      bottom: 0px;
      margin-bottom: 0px;
    }
  }
`;
