import { useCallback, useMemo, useState, useEffect } from "react";

import {
  ActionsContainerStyled,
  ListItemContainer,
  EditInputStyled,
  Separator,
  MetricValueContainerStyled,
} from "./metriclistitem.styles";
import { MetricListItemProps } from "./metriclistitem.types";

import {
  editPencilIcon,
  crossIconInCircle,
  deleteIconSolid,
  tickIconInCircle,
} from "../../../../../../../../svgs";

import { isUserPrefferedPercentageMetricColumn as isMetricAlreadyAdded } from "../../../../../../../../utils";
import LinkButton from "../../../../../../../../components/linkbutton";

const editIcon = editPencilIcon("14", "14");
const deleteIcon = deleteIconSolid("20", "20");
const closeIcon = crossIconInCircle("25", "25");
const tickIcon = tickIconInCircle("25", "25");

const MetricListItem = (props: MetricListItemProps): JSX.Element => {
  const {
    children,
    onDelete,
    onUpdate,
    fieldId,
    onFieldEditButtonClick,
    keyName,

    restrictedMetrics,
    metrics,
    isEditOpen,
  } = props;

  const [editValue, setEditValue] = useState("");

  useEffect(() => {
    setEditValue(children);
  }, [children]);

  const onEdit = useCallback(() => {
    onFieldEditButtonClick(keyName, fieldId);
  }, [fieldId, keyName, onFieldEditButtonClick]);

  const onConfirmEdit = useCallback(() => {
    onUpdate(editValue);
    onFieldEditButtonClick(keyName, fieldId);
  }, [fieldId, keyName, editValue, onFieldEditButtonClick]);

  const onCloseEdit = useCallback(() => {
    setEditValue(children);

    onFieldEditButtonClick(keyName, fieldId);
  }, [fieldId, keyName, children, onFieldEditButtonClick]);

  const onEditValueChange = useCallback((e: any): void => {
    setEditValue(e?.target?.value);
  }, []);

  const isEditedValueValid = useMemo(() => {
    const trimmedValue = editValue?.trim();

    const isValueExistsInRestrictedMetrics = isMetricAlreadyAdded(
      trimmedValue,
      restrictedMetrics
    );
    const isValueExistsInMetrics = isMetricAlreadyAdded(trimmedValue, metrics);

    return (
      editValue?.length &&
      !isValueExistsInRestrictedMetrics &&
      !isValueExistsInMetrics
    );
  }, [restrictedMetrics, metrics, editValue]);

  return (
    <>
      <ListItemContainer isEditMode={isEditOpen}>
        {isEditOpen ? (
          <>
            <EditInputStyled
              value={editValue}
              onChange={onEditValueChange}
              className="edited-input"
            />

            <div
              className="actions-wrapper"
              data-testid="edit-actions-sec-wrapper"
            >
              <LinkButton
                tooltipProps={{
                  title: !editValue?.length
                    ? "Please enter valid value"
                    : children === editValue
                    ? undefined
                    : !isEditedValueValid
                    ? "Value Already Exists"
                    : "Confirm",
                }}
                disabled={!isEditedValueValid}
                onClick={onConfirmEdit}
                className="confirm-button"
              >
                {tickIcon}
              </LinkButton>
              <LinkButton
                tooltipProps={{
                  title: "Cancel",
                }}
                onClick={onCloseEdit}
                className="cancel-button"
              >
                {closeIcon}
              </LinkButton>
            </div>
          </>
        ) : (
          <>
            <MetricValueContainerStyled title={children}>
              {children}
            </MetricValueContainerStyled>
            <ActionsContainerStyled>
              <LinkButton
                tooltipProps={{
                  title: "Edit Metric",
                }}
                onClick={onEdit}
              >
                {editIcon}
              </LinkButton>
              <LinkButton
                tooltipProps={{
                  title: "Delete Metric",
                }}
                onClick={onDelete}
              >
                {deleteIcon}
              </LinkButton>
            </ActionsContainerStyled>
          </>
        )}
      </ListItemContainer>
      <Separator />
    </>
  );
};

export default MetricListItem;
