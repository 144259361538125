import { useCallback } from "react";

// <--- Components Start --->
import GridToggleButton from "../../../../../../../gridtogglebutton";
import LinkButton from "../../../../../../../linkbutton";
// <--- Components End --->

// <--- Styles Start --->
import { VerticalDividerStyled } from "../../../../../../../dividers/dividers.styles";
import { DQRuleDetailsModalRightSecStyled } from "./dqruledetailsmodalrightsec.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import {
  downloadIconOutlined,
  solidSuccessTickIcon,
} from "../../../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { DQRuleDetailsModalRightSecProps } from "./dqruledetailsmodalrightsec.types";
// <--- Types End --->

// <--- Utils Start --->
import { openNotification } from "../../../../../../../../utils";
// <--- Utils End --->

const DQRuleDetailsModalRightSec = (
  props: DQRuleDetailsModalRightSecProps
): JSX.Element => {
  const { toggleButtonProps, downloadLink = "" } = props;
  const { selectedItem = "" } = toggleButtonProps || {};

  const handleDownloadClick = useCallback(() => {
    window.open(downloadLink, "_self");

    openNotification(
      <>
        <span className="action-text">{solidSuccessTickIcon}</span> Downloading
        file
      </>
    );
  }, [downloadLink]);

  return (
    <DQRuleDetailsModalRightSecStyled>
      {selectedItem === "details" && (
        <>
          <LinkButton
            className="download-btn"
            tooltipProps={{ title: "Download CSV" }}
            onClick={handleDownloadClick}
            disabled={!downloadLink}
            // data-testid={`data-quality-rule-details-grid-right-sec-download-btn-${nodeId}`}
          >
            {downloadIconOutlined("19.2", "19.2")}
          </LinkButton>
          <VerticalDividerStyled marginRight="15px" marginLeft="15px" />
        </>
      )}
      <GridToggleButton
        {...toggleButtonProps}
        // data-testid={`data-quality-rule-details-grid-right-sec-grid-toggle-btn-${nodeId}`}
      />
    </DQRuleDetailsModalRightSecStyled>
  );
};

export default DQRuleDetailsModalRightSec;
