import { StringKeyValuePairType } from "../../app.types";
import { ProminentTagsType } from "../../components/genericprominenttag/genericprominenttag.types";

export const parsedData = {
  tagset_name: "Compliance Category",
  created_by: "Emma Engineer",
  updated_by: "Larry Logistics",
  created_on: "04/15/2022",
  updated_on: "04/18/2022",
  information: {
    name: "Compliance Category",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet",
    allow_suggestions: true,
    single_value: false,
    applicable_to: {
      fields: 25,
      terms: 4,
    },
  },
  tags: [
    {
      id: 0,
      tag: "Category 1",
      description: "This is a one liner description of compliance category",
      usage_count: 30,
    },
    {
      id: 1,
      tag: "Compliance Category",
      description: "This is a one liner description of category",
      usage_count: 10,
    },
    {
      id: 2,
      tag: "Category 2",
      description: "This is a one liner description of category",
      usage_count: 11,
    },
    {
      id: 3,
      tag: "Compliance Category 3",
      description: "This is a one liner description of compliance category tag",
      usage_count: 12,
    },
    {
      id: 4,
      tag: "Category 4",
      description: "This is a one liner description of category",
      usage_count: 22,
    },
  ],
};

export const tagsetLabels = {
  tagsetdetail: {
    information: {
      title: "Information",
      name: "Name",
      description: "Description",
      type: "Type",
      allow_suggestions: "Allow New Values During Tagging",
      single_value: "Only Single Value Allowed",
      applicable_to: "Applicable To",
      datasets: "Datasets",
      fields: "Fields",
      terms: "Terms",
      models_reports: "Models and Reports",
      rules: "Rules",
      tagset_policy_link: "Link to Policy or Standard",
      require_note: "Require Note When Applying Tag",
    },
    tags: {
      title: "Tags",
      name: "Tag",
      description: "Description",
      prominent_tag: "Prominent Tag",
      privacy: "Privacy",
      usage_count: "Usage Count",
      action: "Action",
      add: "Add",
      merge: "Merge",
      delete: "Delete",
      no_tags: "No tags added yet",
    },
  },
};

export const applicableToValues: StringKeyValuePairType = {
  datasets: "TBL",
  fields: "COL",
  terms: "TRM",
  models_reports: "DSR",
  rules: "RLS",
};

export const tagsetPrivacyOptions = [
  {
    label: "Select the option",
    options: [
      {
        label: "None",
        value: "NON",
      },
      {
        label: "Masked",
        value: "MSK",
      },
      {
        label: "No Access",
        value: "NOA",
      },
    ],
  },
];

export const tagTypesList: Array<ProminentTagsType> = [
  "LBL",
  "LGR",
  "LRD",
  "BLU",
  "GRN",
  "RED",
  "DBL",
  "DGR",
  "DRD",
];

export const is_policy_linked = "is_policy_linked";
