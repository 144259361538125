import { API_CONFIG } from "../../constants/apiconfig";
import { getUsersOrUserGroups } from "../../parsers/tablepage/tablepageparser";

import {
  getParsedActiveUsers,
  getParsedDataSecurityAdminUsers,
  getParsedUsers,
  getParsedUsersForSelect,
} from "../../parsers/users/usersparser";

import { UsersParserReturnType } from "../../parsers/users/usersparser.types";
import { useGetAll } from "../baseservice";

import { CustomReactQueryHookReturnType } from "../services.types";

const {
  get_all_users: allUsers,
  get_search_node: getSearchedUsers,
} = API_CONFIG;

export function useGetAllUsers(
  onSuccess?: (data: any) => void
): CustomReactQueryHookReturnType<UsersParserReturnType[]> {
  return useGetAll({
    apiConfig: allUsers,
    parser: getParsedUsers,
    options: { onSuccess },
  });
}

export function useGetSecurityAdminUsers(): CustomReactQueryHookReturnType<
  UsersParserReturnType[]
> {
  return useGetAll({
    apiConfig: allUsers,
    parser: getParsedDataSecurityAdminUsers,
  });
}

export function useGetAllActiveUsers(
  onSuccess?: (data: any) => void
): CustomReactQueryHookReturnType<UsersParserReturnType[]> {
  return useGetAll({
    apiConfig: allUsers,
    parser: getParsedActiveUsers,
    options: { onSuccess },
  });
}

export function useGetAllUsersForSelect(): CustomReactQueryHookReturnType {
  return useGetAll({ apiConfig: allUsers, parser: getParsedUsersForSelect });
}

export function useSearchUsersForSelect(
  searchText: string
): CustomReactQueryHookReturnType {
  return useGetAll({
    apiConfig: getSearchedUsers,
    parser: getUsersOrUserGroups,
    params: ["USR,UGP", searchText],
    options: { enabled: false },
  });
}

// export function useGetAllActiveUsers(
//   onSuccess?: (data: any) => void
// ): CustomReactQueryHookReturnType<UsersParserReturnType[]> {
//   return useGetAll({
//     apiConfig: allUsers,
//     parser: getParsedActiveUsers,
//     options: { onSuccess },
//   });
// }
