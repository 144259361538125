import styled from "styled-components";
import { ProgressBarItem } from "./multistatusprogressbar.types";

const colorMapping = (
  theme: any,
  type: ProgressBarItem["type"]
): { bgColor: string; textColor: string } => {
  const bgColors = {
    success: theme.bgColors.LIGHT_BLUE_28,
    warning: theme.textColors.GREEN_COLOR,
    error: theme.bgColors.RED_100,
  };
  const textColor = {
    success: theme.bgColors.LIGHT_BLUE_28,
    warning: theme.textColors.GREEN_COLOR,
    error: theme.textColors.RED_100,
  };
  return {
    bgColor: bgColors[type],
    textColor: textColor[type],
  };
};

export const MiltiProgressBarStyled = styled.div<{ width?: string }>`
  width: ${({ width }): string => width || "127px"};
  height: 11px;
`;

export const ProgressItemStyled = styled.div<ProgressBarItem>`
  &.bar-item {
    min-width: ${(props): any => props?.value < 1 && props?.value > 0 && "2%"};
    width: ${(props): string => `${props?.width}%`};
    height: 11px;
    background: ${(props): string =>
      colorMapping(props.theme, props.type).bgColor};
    color: ${(props): string =>
      colorMapping(props.theme, props.type).textColor};
    float: left;
    font-size: 12px;
    > p {
      text-align: ${(props): any =>
        props?.value < 1 && props?.value > 0 ? "left" : "right"};
      margin-top: 9px;
      margin-bottom: 0px;
      min-width: fit-content;
    }
  }
`;
