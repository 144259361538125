export const jsonParse = <T = any>(
  json: string | null | undefined,
  isObject?: boolean
): T => {
  try {
    if (typeof json === "object" && json !== null && json !== "null") {
      return json as T;
    }

    return JSON.parse(json || "");
  } catch {
    return (isObject ? {} : []) as T;
  }
};
