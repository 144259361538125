import styled from "styled-components";
import { hexToRgbA } from "../../../../../../utils";

export const TrainBotActionBarStyled = styled.div`
  padding-right: 5px;
  display: flex;
  align-items: center;

  .search-sec {
    display: flex;
    align-items: center;
  }

  .ant-btn.refresh-data-btn {
    display: flex;
    align-items: center;
    column-gap: 3px;

    svg {
      path {
        fill: ${({ theme }): string => theme?.bgColors?.BLUE_116};
      }
    }
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked) {
    background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  }

  .ques-mark-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${({ theme }): string => theme?.bgColors?.GREY_24};
    color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: OpenSansSemiBold;
    font-size: 12px;
    cursor: pointer;
  }

  svg {
    path {
      /* stroke: ${({ theme }): string => theme?.bgColors?.GREY_24}; */
    }
  }

  .dot-menu-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;

    svg {
      g {
        circle {
          fill: ${({ theme }): string => theme?.bgColors?.GREY_24};
        }
      }
    }
  }

  .ant-tooltip-open.dot-menu-btn {
    background-color: ${({ theme }): string =>
      hexToRgbA(theme?.bgColors?.BLUE_150, 0.3)};
  }

  .menu {
    .ant-popover-placement-leftTop {
      left: -285px !important;
      top: -9.79px !important;

      .ant-popover-arrow {
        display: block;
      }
    }
  }

  .analysis-right-section-actions-tooltip {
    padding-top: 0;
    .ant-tooltip-content {
      width: max-content;

      .ant-tooltip-arrow {
        display: none;
      }

      .ant-tooltip-inner {
        box-shadow: unset;
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        padding: unset;
        height: 100%;
        width: unset;
      }
    }
  }

  .ant-popover-placement-bottomRight {
    padding-top: 0px;
  }
`;
