import { useForm } from "react-hook-form";
import { DynamicAlertDataCheck } from "../connectionsettingstabform.types";

function getNotEmptyCheckFields(
  fCheck: DynamicAlertDataCheck,
  form?: ReturnType<typeof useForm>
): string[] {
  if (form) {
    const notEmptyFields =
      (fCheck?.fields &&
        fCheck?.fields?.filter((field) => {
          if (form?.getValues(field)) {
            return true;
          }
          return false;
        })) ||
      [];

    return notEmptyFields?.length === fCheck?.fields?.length
      ? notEmptyFields
      : [];
  }

  return [];
}

export function getFieldCheckData(
  fieldsCheck?: DynamicAlertDataCheck[],
  form?: ReturnType<typeof useForm>
): string[] {
  return (
    fieldsCheck
      ?.map((fCheck: DynamicAlertDataCheck) => {
        if (fCheck?.checkType) {
          switch (fCheck?.checkType) {
            case "DO_NOT_MATCH":
              return "";
            case "END_WITH":
              return "";
            case "INCLUDE":
              return "";
            case "MATCH":
              return "";
            case "START_WITH":
              return "";
            case "NOT_EMPTY": // get field which are empty
              return getNotEmptyCheckFields(fCheck, form);
            case "EMPTY": // get field which are empty
              return (
                (fCheck?.fields &&
                  fCheck?.fields?.filter((field) => {
                    if (form?.getValues(field)) {
                      return false;
                    }
                    return true;
                  })) ||
                []
              );
            default:
              return "";
          }
        }

        // if (rCheck?.checkDoNotMatch) {
        //   return getResponseCheckMissingData(result, rCheck);
        // }

        // if (rCheck?.checkDoMatch) {
        //   return getResponseCheckMatchedData(result, rCheck);
        // }

        return [];
      })
      ?.filter((value) => !!value)
      ?.flat() || []
  );
}
