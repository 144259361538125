import styled from "styled-components";

export const DvsumHelpDrawerBreadcrumStyled = styled.div`
  font-size: 19.2px;
  font-family: OpenSansSemiBold;

  .breadcrumb {
    font-size: 13.6px;
    display: flex;
    column-gap: 8px;

    .inactive-item {
      font-family: OpenSans;
      opacity: 0.8;
    }

    .pointer {
      cursor: pointer;
    }
  }
`;
