import { Node, Edge, MarkerType } from "reactflow";

import {
  ERDEdgeType,
  EdgesFieldDataType,
  ErdEdgeInfoType,
  RelationshipTableDataResponseType,
  RelationshipTablesDataResponse,
  RequiredRelationshipDataType,
} from "./tablepageparser.types";
import { TableFieldsInfoType } from "../erddiagramparser/erddiagramparser.types";
import { ErdCustomTableNodeDataType } from "../../pages/tablepage/views/datamodeltab/datamodeltab.views/relationshipdiagram/relationshipdiagram.types";
import { getObjectValues } from "../../utils";

const relationLabel = (relationshipType: string): string => {
  return relationshipType === "O" ? "1" : "N";
};

const removeIntersectionOfTables = (
  list1: RequiredRelationshipDataType[],
  list2: RequiredRelationshipDataType[]
): RequiredRelationshipDataType[] => {
  const list2Ids = list2?.map((item) => item?.id);
  return list1?.filter((item) => list2Ids?.indexOf(item?.id) === -1);
};

const getTablesListFromRelationshipData = (
  relations: RelationshipTableDataResponseType[],
  isSelectParentTable: boolean
): RequiredRelationshipDataType[] => {
  const uniqueTablesList: { [key: number]: RequiredRelationshipDataType } = {};

  relations?.forEach((item) => {
    const tblId = item?.[isSelectParentTable ? "REF_TBL_ID" : "TBL_ID"] || 0;
    const colId = `${
      item?.[isSelectParentTable ? "REF_COL_ID" : "COL_ID"] || 0
    }`;

    if (!uniqueTablesList?.[tblId]) {
      uniqueTablesList[tblId] = {
        id: tblId,
        name: item?.[isSelectParentTable ? "REF_TBL_NAME" : "TBL_NAME"],
        foreignColIdsList: isSelectParentTable ? [] : [colId],
        relationshipColIdsList: isSelectParentTable ? [colId] : [],
      };
    } else if (!uniqueTablesList[tblId]?.foreignColIdsList?.includes(colId)) {
      uniqueTablesList[tblId]?.[
        isSelectParentTable ? "relationshipColIdsList" : "foreignColIdsList"
      ]?.push(colId);
    }
  });

  return getObjectValues(uniqueTablesList);
};

const getUnionOfTables = (
  tables: RelationshipTableDataResponseType[],
  level: number
): {
  unionOfTables: RequiredRelationshipDataType[];
  levelFilteredRelations: RelationshipTableDataResponseType[];
} => {
  const levelFilteredRelations = tables?.filter(
    (item) => item?.TABLE_LEVEL === level
  );

  const childTables = getTablesListFromRelationshipData(
    levelFilteredRelations,
    false
  );

  const parentTables = getTablesListFromRelationshipData(
    levelFilteredRelations,
    true
  );

  const childTablesIdsList = childTables?.map((item) => item?.id);
  const filteredParentTables = parentTables?.filter(
    (item) => !childTablesIdsList?.includes(item?.id)
  );

  return {
    unionOfTables: Array.from([...filteredParentTables, ...childTables]),
    levelFilteredRelations,
  };
};

function findMaxPositiveAndNegativeAxis(
  arr: Node[] = [],
  isOnlyOneRelationExists: boolean
): { x: number; y: number } {
  if (arr?.length === 0) {
    return { x: 0, y: 0 };
  }

  let maxXPositive = 0;
  let maxXNegative = -1;

  let maxYPositive = 0;
  let maxYNegative = -1;

  arr?.forEach((item) => {
    const xValue = item?.position?.x || 0;
    if (xValue >= 0 && xValue > maxXPositive) {
      maxXPositive = xValue;
    } else if (xValue < 0 && xValue < maxXNegative) {
      maxXNegative = xValue;
    }

    const yValue = item?.position?.y || 0;

    if (yValue >= 0 && yValue > maxYPositive) {
      maxYPositive = yValue;
    } else if (yValue < 0 && yValue < maxYNegative) {
      maxYNegative = yValue;
    }
  });

  const space = isOnlyOneRelationExists ? 150 : 0;

  return {
    x: (maxXPositive - Math.abs(maxXNegative)) / 2 - space + (space ? 0 : 123),
    y: (maxYPositive - Math.abs(maxYNegative)) / 2 - space - (space ? 0 : 80),
  };
}

const autoLayout = (
  radius: number,
  tables: Node[] = [],
  currentTableId: string
): Node[] => {
  const fields: Node[] = [...tables];
  const step = (2 * Math.PI) / fields?.length;
  const innerWidth = 1377;
  const innerHeight = 663;

  const layoutedFields: Node[] = fields?.map(
    (({ angle }): any => (val: Node, _i: number): Node => {
      const x =
        val?.id === currentTableId
          ? 0
          : Math.round(innerWidth / 2 + radius * Math.cos(angle) - 245 / 2);
      const y =
        val?.id === currentTableId
          ? 0
          : Math.round(innerHeight / 2 + radius * Math.sin(angle) - 50 / 2);

      angle += step;

      return {
        ...val,
        position: { x, y },
      };
    })({ angle: 0 })
  );

  const { x, y } = findMaxPositiveAndNegativeAxis(
    layoutedFields,
    layoutedFields?.length === 2
  );

  return layoutedFields?.map(
    (val: Node, _i: number): Node => {
      return {
        ...val,
        position: {
          x: val?.id === currentTableId ? x : val?.position?.x,
          y: val?.id === currentTableId ? y : val?.position?.y,
        },
      };
    }
  );
};

const createNodes = (
  erdData: RelationshipTableDataResponseType[],
  tables: RequiredRelationshipDataType[]
): Node[] => {
  const getLevelOneSrcRefTables =
    erdData?.filter((item) => item?.TABLE_LEVEL === 1) || [];

  const getLevelOneSrcTableIds =
    getLevelOneSrcRefTables?.map((item) => item?.TBL_ID) || [];
  const getLevelOneRefTableIds =
    getLevelOneSrcRefTables?.map((item) => item?.REF_TBL_ID) || [];

  const levelOneSrcRefTableIds =
    Array.from(
      new Set([...getLevelOneSrcTableIds, ...getLevelOneRefTableIds])
    ) || [];

  return tables?.map((item) => ({
    id: `${item?.id}`,
    data: {
      tableTitle: item?.name,
      linkedRefTableIDs: levelOneSrcRefTableIds,
      foreignColIdsList: [...item?.foreignColIdsList],
      relationshipColIdsList: [...item?.relationshipColIdsList],
    } as ErdCustomTableNodeDataType,
    position: { x: 0, y: 0 },
    style: { width: 245, height: 45 },
    type: "customComponent",
  }));
};

export const getEdgeInfo = (
  currentErdEdge: RelationshipTableDataResponseType,
  currentErdEdgeFieldsData: TableFieldsInfoType
): ErdEdgeInfoType => {
  const edgeInfo = {
    sourceTable: {
      table_id: currentErdEdge?.TBL_ID || 0,
      table_name: currentErdEdge?.TBL_NAME || "",
      description: "",
    },

    fields: currentErdEdgeFieldsData || [],

    referenceTable: {
      table_id: currentErdEdge?.REF_TBL_ID || 0,
      table_name: currentErdEdge?.REF_TBL_NAME || "",
      description: currentErdEdge?.REF_TBL_DESC || "",
    },

    status: currentErdEdge?.CONS_STATUS || "confirmed",
    statusId: currentErdEdge?.CONS_STATUS_ID || "CNF",
    edgeSourceId: currentErdEdge?.CONS_SRC_ID || "",
    edgeSource: currentErdEdge?.CONS_SRC || "",
    cardinality: currentErdEdge?.CONS_REL_TYPE || "1:1",
    edgeUniqueId: currentErdEdge?.CONS_UNQ_ID || 0,
    edgeId: currentErdEdge?.CONS_ID || 0,
    edgeName: currentErdEdge?.CONS_NAME || "",
    sourceId: currentErdEdge?.SRC_ID || 0,
    primaryColumn: currentErdEdge?.COL_NAME || "",
    foreignColumn: currentErdEdge?.REF_COL_NAME || "",
    actions: "",
  };
  return edgeInfo;
};

const createEdges = (
  tables: RelationshipTableDataResponseType[],
  edgesFieldData: EdgesFieldDataType
): ERDEdgeType[] => {
  let occurence: { [key: string]: number } = {};

  return tables?.map((item) => {
    const relationshipTypes = item.CONS_REL_TYPE_ID.split("T");
    const childLabel = relationLabel(relationshipTypes[0]);
    const parentLabel = relationLabel(relationshipTypes[1]);
    const srcTrgtKey = `${item?.REF_TBL_ID}:${item?.TBL_ID}`;
    const constUniqueId = item?.CONS_UNQ_ID || 0;

    occurence = {
      ...occurence,
      [`${srcTrgtKey}`]: (occurence?.[srcTrgtKey] || 0) + 1,
    };

    const edgeInfo: ErdEdgeInfoType = getEdgeInfo(
      item,
      edgesFieldData?.[constUniqueId]?.fieldsData
    );

    return {
      id: `${item.CONS_UNQ_ID}`,
      source: `${item.REF_TBL_ID}`,
      target: `${item.TBL_ID}`,
      type: "floating",
      style: { strokeWidth: 2, zIndex: 10, stroke: "#807f80" },
      data: {
        edgeInfo,
        parentLabel,
        childLabel,
        edgeUniqueId: item?.CONS_UNQ_ID,
        occurence:
          (occurence?.[srcTrgtKey] || 1) > 1 ? occurence?.[srcTrgtKey] : 0,
      },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: "#807f80",
      },
    };
  });
};

export const erdGridFieldsData = (
  levelFilteredTable: RelationshipTableDataResponseType[]
): EdgesFieldDataType => {
  const edgesFieldData: EdgesFieldDataType = levelFilteredTable?.reduce(
    (
      acc: EdgesFieldDataType,
      currentObj: RelationshipTableDataResponseType
    ) => {
      const constUniqueId = currentObj?.CONS_UNQ_ID || 0;

      const contrainstExist = acc?.[constUniqueId];

      return {
        ...acc,
        [constUniqueId]: {
          fieldsData: contrainstExist
            ? [
                ...acc?.[constUniqueId]?.fieldsData,
                {
                  field_id: currentObj?.CONS_ID || 0,
                  source_field_id: currentObj?.COL_ID || 0,
                  source_field_name: currentObj?.COL_NAME || "",
                  target_field_id: currentObj?.REF_COL_ID || 0,
                  target_field_name: currentObj?.REF_COL_NAME || "",
                  field_postion: Number(currentObj?.CONS_COL_POSITION) || 0,
                  source_col_type: currentObj?.COL_DATA_TYPE || "STR",
                  target_col_type: currentObj?.REF_COL_DATA_TYPE || "STR",
                },
              ]
            : [
                {
                  field_id: currentObj?.CONS_ID || 0,
                  source_field_id: currentObj?.COL_ID || 0,
                  source_field_name: currentObj?.COL_NAME || "",
                  target_field_id: currentObj?.REF_COL_ID || 0,
                  target_field_name: currentObj?.REF_COL_NAME || "",
                  field_postion: Number(currentObj?.CONS_COL_POSITION) || 0,
                  source_col_type: currentObj?.COL_DATA_TYPE || "STR",
                  target_col_type: currentObj?.REF_COL_DATA_TYPE || "STR",
                },
              ],
        },
      };
    },
    {}
  );

  return edgesFieldData;
};

export const getErdDiagramNodesAndEdges = (
  tables: RelationshipTableDataResponseType[],
  level: number,
  currentTableId: string
): {
  nodes: Node[];
  edges: Edge[];
} => {
  if (level === 0) {
    return {
      nodes: [],
      edges: [],
    };
  }

  const { unionOfTables, levelFilteredRelations } = getUnionOfTables(
    tables,
    level
  );

  const nodes: Node[] = createNodes(tables, unionOfTables);

  const edgesFieldData = erdGridFieldsData(levelFilteredRelations);

  const edges = createEdges(levelFilteredRelations, edgesFieldData);

  const currentLevelNodeIdsWithNamesList = removeIntersectionOfTables(
    unionOfTables,
    getUnionOfTables(tables, level - 1)?.unionOfTables
  );

  const currentLevelNodeIdsList = currentLevelNodeIdsWithNamesList?.map(
    (item) => item?.id
  );

  const filterdChildLevelNodeTables = tables?.filter(
    (item) => currentLevelNodeIdsList?.indexOf(item?.TBL_ID) === -1
  );

  const currentLevelNodes = createNodes(
    tables,
    removeIntersectionOfTables(
      unionOfTables,
      getUnionOfTables(tables, level - 1)?.unionOfTables
    )
  );

  const childNodes = nodes?.filter((item) => item?.id !== currentTableId);
  const radius = childNodes?.length * 25;

  const nextLevelResult = getErdDiagramNodesAndEdges(
    filterdChildLevelNodeTables,
    level - 1,
    currentTableId
  );

  const combinedEdges = [...(edges || []), ...(nextLevelResult?.edges || [])];
  const uniqueCombineEdges = Array.from(
    new Map(combinedEdges?.map((v) => [v?.id, v]))?.values()
  );

  const minRadius = level === 1 ? 300 : level === 2 ? 500 : 750;

  const uniqueNodes = Array.from(
    new Map(
      [
        ...nextLevelResult?.nodes,
        ...autoLayout(
          radius < minRadius ? minRadius : radius,
          currentLevelNodes,
          currentTableId
        ),
      ]?.map((v) => [v?.id, v])
    )?.values()
  );

  return {
    nodes: uniqueNodes,
    edges: uniqueCombineEdges,
  };
};

export const getErdGridData = (
  data: RelationshipTablesDataResponse
): ErdEdgeInfoType[] => {
  const apiResData = data?.data || [];

  const allEdgesFieldsData = erdGridFieldsData(apiResData);

  const gridData =
    apiResData?.map((edgeInfo) => {
      const edgeUniqueId = edgeInfo?.CONS_UNQ_ID || 0;

      const edgeParsedData = getEdgeInfo(
        edgeInfo,
        allEdgesFieldsData?.[edgeUniqueId]?.fieldsData
      );

      return edgeParsedData;
    }) || [];

  return gridData || [];
};
