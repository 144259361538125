import { useCallback } from "react";
import { appendQueryParamInUrl } from "../api";
import { queryClient } from "../App";
import { API_CONFIG_TYPE } from "../constants/apiconfig";

// TODO: Use generics
export function useGetData(): (
  _api: API_CONFIG_TYPE,
  _params?: string[]
) => any {
  return useCallback((api: API_CONFIG_TYPE, params?: string[]) => {
    const url = appendQueryParamInUrl(api?.url, params ?? []);

    return queryClient.getQueryData(url);
  }, []);
}
