export const sourceScanLogsBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 2000 660" fill="none">
    <rect width="900" height="26" rx="13" fill="#F0F0F0" />
    <rect y="42" width="900" height="26" rx="13" fill="#F0F0F0" />
    <rect y="84" width="900" height="26" rx="13" fill="#F0F0F0" />
    <rect y="126" width="900" height="26" rx="13" fill="#F0F0F0" />
    <rect y="168" width="900" height="26" rx="13" fill="#F0F0F0" />
    <rect y="210" width="900" height="26" rx="13" fill="#F0F0F0" />
    <rect y="252" width="694" height="26" rx="13" fill="#F0F0F0" />
    <rect y="294" width="694" height="26" rx="13" fill="#F0F0F0" />
    <rect y="336" width="694" height="26" rx="13" fill="#F0F0F0" />
  </svg>
);
