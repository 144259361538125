import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const EditableInformationSectionStyled = styled.div`
  background: ${({ theme }): string => theme.bgColors.BLUE_11};
  padding: 27px 18px;

  .ant-form {
    .ant-form-item:not(.form-actions-sec) {
      margin-bottom: 9px;
    }

    .ant-tooltip-disabled-compatible-wrapper {
      width: fit-content;
    }
  }

  .tagset-policy-link-sec {
    padding-left: 51px;
    position: relative;

    .selected-link-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12.8px;
      margin-bottom: 12.8px;
      background: #e8e8e8;
      width: 642px;

      .link {
        ${ellipsis}
      }

      svg {
        fill: #777777;
      }
    }

    .link-field-wrapper {
      display: flex;
      align-items: center;
      column-gap: 13.2px;

      .ant-input {
        padding: 12.8px;
        height: auto;
        border-radius: 0;
      }

      .ant-btn-link {
        height: 37px;
      }
    }
  }
`;

export const VerticalBarStyled = styled.div<{ height?: string }>`
  position: absolute;
  top: -12px;
  left: 29px;
  border: 0.5px dashed #adb8c0;
  opacity: 0.5;
  height: ${({ height }): string => height || "38px"};
  width: 20px;
  border-top: 0;
  border-right: 0;
`;
