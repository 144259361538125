import { useRef } from "react";
import Flex from "../../../../../../../../../../../../components/flex/flex";

import {
  CCQ_SQL_COMPILATION_ERROR,
  CCQ_SQL_COMPILATION_ERROR_FOR_CADDI,
} from "../../../../../../../../../../analysisdetalpage.constants";

import { QueryErrorSectionStyled } from "./queryerrorsection.styles";
import { QueryErrorSectionTypes } from "./queryerrorsection.types";

import { checkIsChatPage } from "../../../../../../../../../../../../utils";
import LinkButton from "../../../../../../../../../../../../components/linkbutton";

const isChatPage = checkIsChatPage();

const QueryErrorSection = (props: QueryErrorSectionTypes): JSX.Element => {
  const {
    children,
    errorMessage = "",
    isBlackColor,
    queryItem,
    showFeedbackSec = false,
    onDislikeClick,
    isAuthor = false,
  } = props;

  const elementRef = useRef<HTMLDivElement>(null);

  const {
    user_feedback: { response },
  } = queryItem || {};

  return (
    <QueryErrorSectionStyled isBlackColor={isBlackColor}>
      <Flex alignItems="center" customClass="query-results-flex">
        <div
          ref={elementRef}
          className="error-state-msg"
          data-testid="analysis-detail-page-query-block-query-results-collapse-error-container"
        >
          {errorMessage === CCQ_SQL_COMPILATION_ERROR && isChatPage
            ? CCQ_SQL_COMPILATION_ERROR_FOR_CADDI
            : errorMessage}

          {showFeedbackSec && !response && !(isChatPage && !isAuthor) && (
            <LinkButton
              marginLeft="10px"
              id="secondary"
              onClick={onDislikeClick}
            >
              <Flex columnGap={4} alignItems="center">
                Give Feedback
              </Flex>
            </LinkButton>
          )}
        </div>
      </Flex>
      {children && children}
    </QueryErrorSectionStyled>
  );
};
export default QueryErrorSection;
