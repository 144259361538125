// <--- Components Start --->
import Trend from "../trend";
import LinkButton from "../linkbutton";
// <--- Components End --->

// <--- Styles Start --->
import { DQScoreStyled, DQScoreWrapperStyled } from "./dqscore.styles";
// <--- Styles End --->

// <--- Types Start --->
import { DQScoreProps } from "./dqscore.types";
// <--- Types End --->

// <--- Utils Start --->
import { dqScoreMapping } from "../../utils";
// <--- Utils End --->

const DQScore = (props: DQScoreProps): JSX.Element => {
  const {
    score = "",
    statusId,
    status = "",
    trend,
    size = "sm",
    showEmptyState = false,
    isShowChange = false,
  } = props;

  const {
    score: dqScore = "",
    scoreTooltip = "",
    trend: trendDirection = "CENTER",
    trendTooltip = "",
    variant,
  } = {
    ...dqScoreMapping(statusId, status, score, trend || ""),
  };

  return (
    <DQScoreWrapperStyled>
      {dqScore ? (
        <LinkButton
          data-testid={`dq-score-tag-${dqScore}`}
          tooltipProps={{ title: scoreTooltip, placement: "top" }}
        >
          <DQScoreStyled
            size={size}
            variant={variant}
            className="dq-score-styled"
          >
            {`${dqScore}%`}
          </DQScoreStyled>
        </LinkButton>
      ) : showEmptyState ? (
        "--"
      ) : (
        ""
      )}
      {/* TODO: Might need to uncomment this later */}
      {/* {dqScore && trendDirection && (
        <Trend
          trend={trendDirection}
          size={size}
          trendTooltip={trendTooltip}
          isShowChange={isShowChange}
        />
      )} */}
    </DQScoreWrapperStyled>
  );
};

export default DQScore;
