import { chevronRight } from "../../../../svgs";
import { DvsumHelpDrawerBreadcrumStyled } from "./dvsumhelpdrawerbreadcrum.styles";
import { DvsumHelpDrawerBreadcrumProps } from "./dvsumhelpdrawerbreadcrum.types";

const DvsumHelpDrawerBreadcrum = (
  props: DvsumHelpDrawerBreadcrumProps
): JSX.Element => {
  const {
    searchText = "",
    selectedTopic = "",
    category = "",
    setSelectedCategory,
    resetState,
  } = props;

  return (
    <DvsumHelpDrawerBreadcrumStyled>
      {!searchText && !selectedTopic && <div className="title">Help</div>}
      {(searchText || selectedTopic) && (
        <div className="breadcrumb">
          <div
            className="pointer inactive-item"
            role="button"
            onClick={resetState}
          >
            Help
          </div>
          <div className="inactive-item">{chevronRight}</div>
          <div
            className={category ? "inactive-item pointer" : "active-item"}
            role="button"
            onClick={(): void => setSelectedCategory({})}
          >
            {searchText ? "Search" : selectedTopic}
          </div>
          {category && (
            <div className="breadcrumb">
              <div className="inactive-item">{chevronRight}</div>
              <div className="active-item">{category}</div>
            </div>
          )}
        </div>
      )}
    </DvsumHelpDrawerBreadcrumStyled>
  );
};

export default DvsumHelpDrawerBreadcrum;
