import styled from "styled-components";

export const ConfirmationPopoverStyled = styled.div``;

export const ConfirmationPopoverContentStyled = styled.div<{ width?: string }>`
  width: ${({ width }): string => width || "268px"};
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 16px;

  .heading {
    font-family: "OpenSansSemiBold";
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
  }

  .desc {
    margin: 12.8px 0;
  }

  .action-sec {
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
  }
`;
