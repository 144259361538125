const CustomizedAxisTick = (props: any): JSX.Element => {
  const {
    x,
    y,
    payload,
    textAnchor,
    fill,
    transform,
    charsCountLimit = 14,
  } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={-10}
        dy={16}
        textAnchor={textAnchor}
        fill={fill}
        transform={transform}
      >
        <title>{payload?.value}</title>

        {payload?.value?.length > charsCountLimit
          ? `${payload?.value?.slice(0, charsCountLimit)}...`
          : payload?.value}
      </text>
    </g>
  );
};

export default CustomizedAxisTick;
