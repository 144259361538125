import styled from "styled-components";
import { IconWithTextProps } from "./iconwithtext.types";

export const IconWithTextStyled = styled.div<{
  iconAlignment?: IconWithTextProps["iconAlignment"];
  iconPosition?: IconWithTextProps["iconPosition"];
  flexDirection?: IconWithTextProps["textFlexDirection"];
}>`
  display: flex;
  align-items: baseline;
  column-gap: 8px;

  .iwt-icon {
    display: flex;
    position: relative;

    ${({ iconPosition }): any =>
      iconPosition &&
      `${iconPosition?.top ? `top: ${iconPosition?.top};` : ""}
      ${iconPosition?.right ? `right: ${iconPosition?.right};` : ""}
      ${iconPosition?.bottom ? `bottom: ${iconPosition?.bottom};` : ""}
      ${iconPosition?.left ? `left: ${iconPosition?.left};` : ""}
    `};
  }

  .iwt-icon-text {
    display: flex;
    flex-direction: ${({ flexDirection }): string => flexDirection || "row"};
  }
`;
