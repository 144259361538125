import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";
import { KeyValueTagProps } from "./keyvaluetag.types";

export const KeyValueTagStyled = styled.div<KeyValueTagProps>`
  display: flex;
  align-items: center;
  padding: 3px 10.6px 5.4px 11px;
  min-width: ${(props): string => props?.width || "255px"};
  max-width: ${(props): string => props?.width || "255px"};
  min-height: ${(props): string => props?.height || "41px"};
  background: ${({ theme }): string => theme.bgColors.GREEN_COLOR};
  color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
  font-size: 24px;
  border-radius: 8px;

  .key,
  .value {
    ${ellipsis}
  }

  .value {
    font-family: OpenSansExtraBold;
    margin-left: 5px;
  }
`;
