import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const ChartSecStyled = styled.div<{ isRotateXAxis: boolean }>`
  display: flex;
  column-gap: 19.2px;
  margin-bottom: 42.4px;
  height: ${({ isRotateXAxis }): string => (isRotateXAxis ? "419px" : "376px")};

  .line-chart-sec {
    width: 75%;
    position: relative;
    z-index: 0;

    .line-chart-blankstate {
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ theme }): string => theme?.textColors?.GREY_210};
    }

    .line-chart-title {
      position: absolute;
      top: 19.2px;
      left: 19.2px;
      font-size: 16px;
      font-family: OpenSansSemiBold;
    }

    .ant-card {
      z-index: -1;

      .ant-card-body {
        padding-bottom: ${({ isRotateXAxis }): string =>
          isRotateXAxis ? "24px" : "0"};

        .recharts-legend-wrapper {
          top: 0px !important;
          right: 0px !important;

          .legend-wrapper {
            margin: 0;
            justify-content: flex-end;
            max-width: 100%;

            .chart-legend {
              min-width: 40px;
            }
          }
        }
      }
    }
  }

  .bar-chart-sec {
    width: 25%;
    border: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
    padding: 20px;
    position: relative;

    .bar-chart-blankstate {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ theme }): string => theme?.textColors?.GREY_210};
    }

    .heading {
      font-size: 16px;
      font-family: OpenSansSemiBold;
      margin-bottom: 6.4px;
    }

    .desc {
      color: ${({ theme }): string => theme?.textColors?.GREY_26};
      font-size: 12px;
      margin-bottom: 19.2px;
    }

    .users-list {
      display: flex;
      flex-direction: column;
      row-gap: 26px;
      padding: 6.4px 6.4px 6.4px 0;
      overflow: auto;
      height: ${({ isRotateXAxis }): string =>
        isRotateXAxis ? "300px" : "255px"};
    }
  }
`;

export const TopUserItemStyled = styled.div`
  display: flex;
  column-gap: 12.8px;

  .bar-with-name-sec {
    width: 100%;

    .name {
      ${ellipsis}
      max-width: 22ch;
    }
  }
`;
