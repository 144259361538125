const roundingFactors = [
  2,
  20,
  200,
  2000,
  20000,
  200000,
  2000000,
  20000000,
  200000000,
  2000000000,
  20000000000,
  200000000000,
  2000000000000,
  20000000000000,
  200000000000000,
];

const thresholds = [
  100,
  1000,
  10000,
  100000,
  1000000,
  10000000,
  100000000,
  1000000000,
  10000000000,
  100000000000,
  1000000000000,
  10000000000000,
  100000000000000,
  1000000000000000,
  10000000000000000,
];

export const getYAxisMaxValWithNearestRoundedVal = (number: number): number => {
  for (let i = 0; i < thresholds?.length; i++) {
    if (number < thresholds?.[i]) {
      const computedVal =
        Math.ceil(number / roundingFactors?.[i]) * roundingFactors?.[i];
      return computedVal === number
        ? number + roundingFactors?.[i]
        : computedVal;
    }
  }

  return number;
};

export const getYAxisMinValWithNearestRoundedVal = (number: number): number => {
  for (let i = 0; i < thresholds?.length; i++) {
    if (number < thresholds?.[i]) {
      const computedVal =
        Math.floor(number / roundingFactors?.[i]) * roundingFactors?.[i];
      return computedVal === number
        ? number - roundingFactors?.[i]
        : computedVal;
    }
  }

  return number;
};
