// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

export const AnalysisDetailPageheaderLeftSecStyled = styled.div`
  position: relative;

  .gradient-div {
    position: absolute;
    background-image: linear-gradient(
      to left,
      #fff 32%,
      rgba(255, 255, 255, 0) 125%
    );
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .heading-sec {
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;

    .title {
      font-size: 19.2px;
      font-family: OpenSansSemiBold;
      color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    }

    .ant-btn {
      padding: 0;
    }

    .heading-icons-sec {
      display: flex;
      align-items: center;
      column-gap: 9px;

      .share-type-icon {
        display: flex;
        align-items: center;

        svg {
          path {
            fill: ${({ theme }): string => theme?.textColors?.GREY_18};
          }
        }
      }

      .info-icon-wrapper {
        height: 17px;
        cursor: pointer;
      }
    }
  }
  .show-more-text-main-wrapper {
    display: block;
    max-width: 94%;
  }

  .analysis-info-sec {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 5px;
    gap: 5px;
    column-gap: 10px;
    align-items: center;
  }
  .tags-wrapper {
    display: flex;
    align-items: center;
    column-gap: 7px;

    .tags-list {
      padding: 0;
    }
  }
  .datasource-wrapper {
    align-items: center;
    display: flex;
    gap: 10px;

    .datasource-details {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  .chat-exclusion-criteria-wrapper {
    display: flex;
    align-items: center;
    column-gap: 7px;

    .toggle-eye-icon-wrapper {
      padding-top: 5px;

      .show-icon {
        svg {
          path {
            stroke: ${({ theme }): string => theme?.textColors?.BLUE_116};
          }
        }
      }

      .selected {
        svg {
          g {
            g {
              path:first-of-type {
                fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
              }
            }
          }
        }
      }
    }
  }
`;
