import { useHistory } from "react-router";
import { SyntheticEvent, useCallback } from "react";
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";

import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
import { RULE_SECTION_TITLES } from "../../constants/labelconstants/ruleconfig";

import {
  getRuleRunStatusIcon,
  getRuleRunStatusLabel,
} from "../../utils/getrulerunstatusicon";

import Flex from "../../components/flex";
import { RunStatusId } from "../../parsers/ruleparser/ruleparser.types";

import LinkButton from "../../components/linkbutton/linkbutton";
import { useOpenDrawer } from "../../customhooks";

import { NodeType } from "../../app.types";
import StyledLink from "../../components/styledlink/styledlink";

import {
  getTableDetailPageUrl,
  numberFormatter,
  sortObjectsArrayByKey,
  utcTOLocalTimeZone,
} from "../../utils";
import { tableDetailPageTabsValues } from "../../pages/tablepage/tablepage.constants";

import { DetailPageHistoryState } from "../../components/noderefquickedit/noderefquickedit.types";

import { CheckboxFieldStyled } from "../../components/formfields/checkboxfield/checkboxfield.styles";
import FormItemLabel from "../../components/form/formitemlabel/formitemlabel";

import {
  DataDiffCheckReadableWrapperStyled,
  MultipleColumnsRendererStyled,
  SchemaRendererStyled,
} from "./rulerefdrawer.styles";

import { SingleTagRendrer } from "../../components/prominenttagslist/prominenttagslist";
import { ReturnTypeOfRefParser } from "../../parsers";

import { MONTH_DAY_YEAR_HOUR_MINUTE } from "../../constants";
import { getReferenceDictionaryDetailPageUrl } from "../../utils/getreferencedictionarydetailpageurl";

const {
  STATUS: { WORKFLOW_STATUS, RUN_RESULT, LAST_REFRESHED },
  DEFINITION: {
    TABLE_NAME,
    FIELDS_NAME,
    FIELD_NAME,
    REF_FIELD_NAME,
    REF_TABLE_NAME,
    DATA_DIFF_CHECK,
    SOURCE_SCHEMA,
    TARGET_SCHEMA,
    REF_METRIC_FIELD_NAME,
    REFERENCE_DICTIONARY,
    THRESHOLD_HOURS,
  },
} = RULE_SECTION_TITLES;

const NodeRenderer = (
  item: SectionRowProps & { nodeType: NodeType }
): JSX.Element => {
  const { id = "", nodeType, isFromDetailPage = false, value = "", url = "" } =
    item || {};

  const openDrawer = useOpenDrawer();
  const history = useHistory();

  const onNodeClick = useCallback(
    (e: SyntheticEvent) => {
      e?.stopPropagation();

      if (isFromDetailPage) {
        openDrawer({
          drawerId: nodeType === "TBL" ? "table_ref" : "column_ref",
          visible: true,
          drawerProps: {
            id,
          },
        });
      } else {
        const url = getTableDetailPageUrl(
          id,
          tableDetailPageTabsValues.overview
        );

        history.push({
          pathname: url,
          state: { isEdit: true } as DetailPageHistoryState,
        });
      }
    },
    [nodeType, id]
  );

  return value ? (
    <div className="table-field-name">
      {nodeType && (
        <CoreNodesCircleIcon width="20px" height="20px" nodeType={nodeType} />
      )}
      {url ? (
        <StyledLink to={url || ""}>{value}</StyledLink>
      ) : id ? (
        <LinkButton onClick={onNodeClick}>{value}</LinkButton>
      ) : (
        <div>{value}</div>
      )}
    </div>
  ) : (
    <div>--</div>
  );
};

const ReferenceDictRenderer = (item: SectionRowProps): JSX.Element => {
  const { id = "", value = "" } = item || {};

  const url = getReferenceDictionaryDetailPageUrl(id);

  return value ? (
    <div className="table-field-name">
      <StyledLink to={url || ""}>{value}</StyledLink>
    </div>
  ) : (
    <div>--</div>
  );
};

const MultipleColumnsRenderer = (
  item: SectionRowProps & { nodeType: NodeType }
): JSX.Element => {
  const openDrawer = useOpenDrawer();

  const onNodeClick = useCallback((nodeId) => {
    openDrawer({
      drawerId: "column_ref",
      visible: true,
      drawerProps: {
        id: nodeId,
      },
    });
  }, []);

  return (
    <MultipleColumnsRendererStyled>
      {item?.stringValues?.map((field, index) => {
        return (
          <div className="table-field-name" key={`field-${field}`}>
            <CoreNodesCircleIcon width="20px" height="20px" nodeType="COL" />
            <LinkButton onClick={(): void => onNodeClick(item?.ids?.[index])}>
              {field}
            </LinkButton>
          </div>
        );
      })}
    </MultipleColumnsRendererStyled>
  );
};

const SchemaRenderer = (item: SectionRowProps): JSX.Element => {
  const { additionalInfo, value = "" } = item || {};
  const { schemaName = "" } = additionalInfo || {};

  return (
    <SchemaRendererStyled>
      {value}
      <span className="svg">{" > "}</span>
      {schemaName}
    </SchemaRendererStyled>
  );
};

const DataDifferenceCheckRenderer = (item: SectionRowProps): JSX.Element => {
  const { additionalInfo } = item || {};
  const {
    tblInSrcNotInTgt,
    tblInTgtNotInSrc,
    colInSrcNotInTgt,
    colInTgtNotInSrc,
    colDataTypeCheckIsEnabled,
    colDataType,
    compareSchemaRef = [],
  } = additionalInfo || {};

  const sortedSrcSchemaList: ReturnTypeOfRefParser[] = sortObjectsArrayByKey(
    compareSchemaRef,
    "displayOrder"
  );

  const isValueChecked: Record<string, string> = {
    TES: tblInSrcNotInTgt,
    TET: tblInTgtNotInSrc,
    CES: colInSrcNotInTgt,
    CET: colInTgtNotInSrc,
    DTD: colDataTypeCheckIsEnabled,
  };

  return (
    <DataDiffCheckReadableWrapperStyled>
      {sortedSrcSchemaList?.map((schema: ReturnTypeOfRefParser) => {
        const { name = "", description = "", id = "tes" } = schema;

        const isChecked = isValueChecked[id];

        return (
          <div
            key={id}
            data-testid={`compare-schema-${name?.toLocaleLowerCase()}`}
          >
            <CheckboxFieldStyled checked={!!isChecked} disabled>
              <FormItemLabel
                label={`${name} - ${description}`}
                paddingLeft="0"
                marginBottom="0"
              />
            </CheckboxFieldStyled>
          </div>
        );
      })}

      {colDataType && (
        <div className="datatype-bullet">
          <span className="type-of-check">
            {colDataType === "basic" ? "Basic" : "Strict"} datatype check
          </span>
        </div>
      )}
    </DataDiffCheckReadableWrapperStyled>
  );
};

export const rulesRefDrawer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [RUN_RESULT]: (item) => {
    const statusIcon = getRuleRunStatusIcon(item?.value as RunStatusId);
    const statusLabel = getRuleRunStatusLabel(item?.value as RunStatusId);
    const exceptionCount = item?.additionalInfo?.exceptionCount;

    return (
      <Flex columnGap={2}>
        {statusIcon}
        {item?.value === "EXP" && !!exceptionCount && (
          <span className="exceptions-count">
            {numberFormatter(`${exceptionCount}`)}
          </span>
        )}
        <span className="run-status-label">
          {statusLabel}
          {item?.value === "EXP" ? "(s)" : ""}
        </span>
      </Flex>
    );
  },
  [WORKFLOW_STATUS]: (item) => {
    return item?.value ? (
      <Flex columnGap={5}>
        <SingleTagRendrer
          tag={{
            name: item?.value === "ACK" ? "Acknowledged" : "Resolved",
            type: item?.value === "ACK" ? "LBL" : "LGR",
          }}
          isBordered
        />

        <div className="assigned-to">
          By {item?.additionalInfo?.actionPerformedBy}
        </div>
      </Flex>
    ) : (
      <span>Assigned to {item?.additionalInfo?.actionPerformedBy} </span>
    );
  },
  [LAST_REFRESHED]: (item) => {
    return (
      <span>
        {item?.value
          ? utcTOLocalTimeZone(item?.value, MONTH_DAY_YEAR_HOUR_MINUTE)
          : ""}
      </span>
    );
  },

  [THRESHOLD_HOURS]: (item) => {
    return <span>{item?.value ? numberFormatter(item?.value) : ""}</span>;
  },

  [TABLE_NAME]: (item) => <NodeRenderer {...item} nodeType="TBL" />,
  [REFERENCE_DICTIONARY]: (item) => <ReferenceDictRenderer {...item} />,
  [REF_TABLE_NAME]: (item) => <NodeRenderer {...item} nodeType="TBL" />,
  [FIELD_NAME]: (item) => <NodeRenderer {...item} nodeType="COL" />,
  [FIELDS_NAME]: (item) => <MultipleColumnsRenderer {...item} nodeType="COL" />,
  [REF_METRIC_FIELD_NAME]: (item) => (
    <MultipleColumnsRenderer {...item} nodeType="COL" />
  ),
  [REF_FIELD_NAME]: (item) => (
    <MultipleColumnsRenderer {...item} nodeType="COL" />
  ),
  [DATA_DIFF_CHECK]: (item) => <DataDifferenceCheckRenderer {...item} />,
  [SOURCE_SCHEMA]: (item) => <SchemaRenderer {...item} />,
  [TARGET_SCHEMA]: (item) => <SchemaRenderer {...item} />,
};
