import styled from "styled-components";

export const QueryBlockHeaderStyled = styled.div<{
  isQuerySettingsVisible: boolean;
  isChatPage: boolean;
  isMaximized?: boolean;
  isShowTitleSecActionBtns?: boolean;
  isSavedContextsSecVisible: boolean;
  isViewOnlyChatQuestionPage: boolean;
}>`
  &.analysis-query-block-header {
    width: 100%;
    margin-left: 5px;
  }

  &.chat-query-block-header {
    width: ${({
      isChatPage,
      isQuerySettingsVisible,
      isSavedContextsSecVisible,
      isViewOnlyChatQuestionPage,
    }): string =>
      isViewOnlyChatQuestionPage
        ? "97%"
        : isChatPage && !isQuerySettingsVisible && !isSavedContextsSecVisible
        ? "70%"
        : isChatPage && (isQuerySettingsVisible || isSavedContextsSecVisible)
        ? "80%"
        : ""};
  }

  .query-block-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 31.9px;

    .query-block-header-left-sec {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      gap: 0.7rem;

      .query-question-action-section {
        display: flex;
        align-items: center;

        visibility: ${({ isShowTitleSecActionBtns }): string =>
          isShowTitleSecActionBtns ? "visible" : "hidden"};

        .query-delete-icon {
          height: 16px;
          svg {
            height: 16px;
          }
        }
        .query-edit-icon {
          height: 16px;
        }

        .query-refrech-icon {
          svg {
            path {
              stroke: unset;
              fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
            }
          }
        }
      }

      .title {
        flex-grow: ${({ isChatPage }): string => (isChatPage ? "1" : "")};
        font-size: 16px;
        word-break: break-word;

        color: ${({ theme }): string => theme?.textColors?.GREY_40};

        .edit-btn-form {
          display: none;
        }

        .edit-question-btn {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;
