// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Components Start --->
import { FlexStyled } from "../flex";
// <--- Components End --->

// <--- Types Start --->
import { DQScoreStyledProps } from "./dqscore.types";
// <--- Types End --->

export const DQScoreWrapperStyled = styled(FlexStyled)`
  align-content: center;
  column-gap: 4px;
`;

export const DQScoreStyled = styled.div<DQScoreStyledProps>`
  font-weight: 600;
  text-align: center;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ size }): string => (size === "sm" ? "19px" : "27px")};
  ${({ size }): string => (size === "lg" ? "padding: 1px 0;" : "")};
  width: ${({ size }): string => (size === "sm" ? "56px" : "67px")};
  font-size: ${({ size }): string => (size === "sm" ? "13px" : "16px")};
  color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};

  cursor: default;

  ${({ theme, variant }): string => {
    switch (variant) {
      case "GRN":
        return `background-color: ${theme?.bgColors?.GREEN_1};`;
      case "RED":
        return `background-color: ${theme?.bgColors?.RED_100};`;
      case "YLW":
        return `background-color: ${theme?.bgColors?.YELLOW_COLOR};`;
      case "ORG":
        return `background-color: ${theme?.bgColors?.ORANGE_10};`;
      default:
        return "";
    }
  }}
`;
