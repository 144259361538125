import {
  configureStore,
  createSerializableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import { appreducer } from "./reducers";

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable: () => true,
});

export const store = configureStore({
  reducer: {
    appreducer,
  },
  middleware: [serializableMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
