import { useIsFirstRender } from "usehooks-ts";
import { useMemo, useEffect } from "react";

import { Col, Row, Tooltip } from "antd";

import {
  QueryResults,
  QueryBlockHeader,
  // QueryBlockProfiling,
} from "./queryblock.comp";

import {
  downArrowFilledBlue,
  shareConceptSvgYellowVariant,
  sortRightIcon,
} from "../../../../../../../../svgs";

import {
  QueryBlockOuterWrapperStyled,
  QueryBlockStyled,
} from "./queryblock.styles";

import { QueryBlockProps } from "./queryblock.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../../../constants";

import { useGetTableDataTabData } from "../../../../../../../../api/columnsservice";
import { TABLE_DATA_TAB_SECTION_TITLES } from "../../../../../../../../constants/labelconstants";

import {
  checkIsViewOnlyChatQuestionPage,
  checkSourceType,
  getChatPrefrencesData,
  getObjectLength,
  isEqual,
  jsonStringify,
} from "../../../../../../../../utils";

import {
  checkQueryType,
  filterQueryFiltersWhereFilterModelsExists,
} from "../../../../../../analisisdetailpage.utils";

import { transformFilterModelToListOfFilters } from "./queryblock.comp/queryblock.comp.utils";
import { checkIsChatPage } from "../../../../../../../../utils/ischatpage";

import { AnalysisPageQueryBoxResultsColumns } from "../../../../../../analysisdetailpage.types";
import QueryBlockSettings from "./queryblock.comp/queryblocksettings/queryblocksettings";

const DataFetcherForSampleDataTables = (
  props: QueryBlockProps
): JSX.Element => {
  const { queryItem, handlers } = props;
  const { id = "", table_id: tableId = "" } = queryItem || {};

  const {
    onUpdateQueryResultsAndColumns,
    onStartLoadingOfQueryBlock,
    onErrorOfQueryBlock,
  } = handlers;

  const { parsedData, isFetching, error } = useGetTableDataTabData(
    tableId,
    true,
    true
  );

  useEffect(() => {
    onStartLoadingOfQueryBlock(id, "newly_added_question");
  }, []);

  useEffect(() => {
    if (error && !isFetching) {
      onErrorOfQueryBlock?.(id, error);
    }
  }, [error]);

  const {
    columnSampleData = [],
    column_details: columnDetails = [],
    sample_data_header_rendrer: sampleDataHeaderRendrer = [],
    profilingData = [],
    isSampleDataLoading,
  } = parsedData || {};

  useEffect(() => {
    if (!isFetching && !error && !isSampleDataLoading) {
      const updatedColDetails: AnalysisPageQueryBoxResultsColumns[] = columnDetails?.map(
        (col) => ({
          column_id: `${col?.column_id}`,
          column_name: col?.column_name,
          column_display_name: col?.column_display_name,
          description: col?.description,
          data_type: col?.data_type,
          data_type_info: col?.data_type_info,
          col_status: col?.col_status,
          col_status_id: col?.col_status_id,
          is_selected: false,
          is_applied: false,
          col_type_id: col?.col_type_id,
          col_dist_count: col?.col_dist_count,
          is_col_masked: col?.is_col_masked,
        })
      );

      const tableRecordCount = profilingData?.find(
        (item) =>
          item?.title ===
          TABLE_DATA_TAB_SECTION_TITLES?.profiling_information?.record_count
      )?.value;

      onUpdateQueryResultsAndColumns(
        id,
        columnSampleData,
        updatedColDetails,
        sampleDataHeaderRendrer,
        Number(tableRecordCount)
      );
    }
  }, [isFetching, isSampleDataLoading]);
  return <div />;
};

const QueryBlock = (props: QueryBlockProps): JSX.Element => {
  const {
    queryItem,
    handlers,
    hasUserAccessToEdit = false,
    parsedQueries,
    isQueryMounted,
    onChangeIsQueryMounted,
    onSaveAnalysisIsLoading,
    index = 0,
    onUseSampleQuestionClick,
    isSavedContextsSecVisible = false,
    totalItems,
    queries = [],
    analysisSrcTypeId,
    isAuthor = false,
    gatewayId = "",
  } = props;

  const isFirstRender = useIsFirstRender();
  const isChatPage = checkIsChatPage();
  const isViewOnlyChatQuestionPage = checkIsViewOnlyChatQuestionPage();

  const {
    collapse_view: collapseView = "default_view",
  } = getChatPrefrencesData();

  const {
    id = "",
    is_expanded: isExpanded = false,
    is_focused: isFocused,
    header,
    qry_prf_results_s3_url: qryPrfResultsS3Url = "",
    qry_tmp_results_s3_url: qryTmpResultsS3Url = "",
    results,
    loader_type: loaderType,
    type,
    sub_type: subType,
    filters,
    table_id: tableId,
    settings,
  } = queryItem || {};

  const { isConceptQuery } = checkQueryType(type, subType);

  const {
    data = [],
    is_server_side_filteration: isServerSideFilteration,
    columns,
    chart_config: chartConfig,
  } = results || {};

  const {
    transiant_filters: transientFilters = {},
    applied_filters: appliedFilters = {},
    sortModel = [],
  } = filters || {};

  const { isSummary, isAboutPrompt } = checkQueryType(type, subType);

  const isEnabled =
    !!Number(tableId) &&
    !qryPrfResultsS3Url &&
    !qryTmpResultsS3Url &&
    !data?.length &&
    ((isChatPage && type === "CCQ" && !isSummary && !isAboutPrompt) ||
      type !== "CCQ") &&
    !getObjectLength(transientFilters);

  const {
    title = "",
    is_profiled: isProfiled,
    is_maximize_query_block: isMaximizeQueryBlock,
  } = header || {};

  const {
    visible: isQuerySettingsVisible = false,
    tab: activeTab = "general",
  } = settings || {};

  const { onFocused, onExpand, onGetQueryGridResults } = handlers;

  const isAppliedfiltersChanged = useMemo(() => {
    const parsedQueryFilters =
      parsedQueries?.find((query) => query?.id === id)?.filters
        ?.applied_filters || {};

    const parsedQueryFiltersWhereFilterModelExists = filterQueryFiltersWhereFilterModelsExists(
      parsedQueryFilters
    );

    const appiedFiltersWhereFilterModelExists = filterQueryFiltersWhereFilterModelsExists(
      appliedFilters
    );

    return (
      !isEqual(
        parsedQueryFiltersWhereFilterModelExists,
        appiedFiltersWhereFilterModelExists
      ) &&
      !onSaveAnalysisIsLoading &&
      type !== "CCQ" &&
      !!parsedQueryFiltersWhereFilterModelExists?.length
    );
  }, [parsedQueries, appliedFilters, id, onSaveAnalysisIsLoading]);

  const queryContext = useMemo(() => {
    const mappedTransientFilters = filterQueryFiltersWhereFilterModelsExists(
      transientFilters
    )?.map((colFilter) => {
      const listOfMappedColFilters =
        transformFilterModelToListOfFilters(colFilter) || [];

      const isEqualsTypeFilter = colFilter?.filter_model?.type === "equals";

      const filterColumnName = listOfMappedColFilters?.[0]?.name || "";
      const filterColOperator = listOfMappedColFilters?.[0]?.operator || "";

      const mappedValues = {
        filter_name: filterColumnName,
        val: listOfMappedColFilters?.map((item) => item?.val || "") || [],
        oprator: isEqualsTypeFilter ? "" : filterColOperator,
      };

      return `${mappedValues?.filter_name}: ${
        mappedValues?.oprator
      } ${mappedValues?.val?.join(", ")}`;
    });

    const mappedAppliedFilters =
      type === "CCQ"
        ? []
        : filterQueryFiltersWhereFilterModelsExists(appliedFilters)?.map(
            (colFilter) => {
              const listOfMappedColFilters =
                transformFilterModelToListOfFilters(colFilter) || [];

              const isEqualsTypeFilter =
                colFilter?.filter_model?.type === "equals";

              const filterColumnName = listOfMappedColFilters?.[0]?.name || "";
              const filterColOperator =
                listOfMappedColFilters?.[0]?.operator || "";

              const mappedValues = {
                filter_name: filterColumnName,
                val:
                  listOfMappedColFilters?.map((item) => item?.val || "") || [],
                oprator: isEqualsTypeFilter ? "" : filterColOperator,
              };

              return `${mappedValues?.filter_name}: ${
                mappedValues?.oprator
              } ${mappedValues?.val?.join(", ")}`;
            }
          );

    const combinedMappedFilters = [
      ...mappedTransientFilters,
      ...mappedAppliedFilters,
    ];

    return combinedMappedFilters?.length ? combinedMappedFilters : [title];
  }, [transientFilters, appliedFilters, title]);

  useEffect(() => {
    if (isServerSideFilteration && !isFirstRender && columns?.length) {
      onGetQueryGridResults?.(id);
      onChangeIsQueryMounted?.(id);
    }
  }, [
    jsonStringify(transientFilters),
    sortModel?.[0]?.sort,
    sortModel?.[0]?.colId,
    columns?.length,
  ]);

  useEffect(() => {
    if (isServerSideFilteration && !isQueryMounted && columns?.length) {
      onGetQueryGridResults?.(id);
      onChangeIsQueryMounted?.(id);
    }
  }, []);

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  return (
    <div className="min-height-of-query-block">
      <QueryBlockOuterWrapperStyled
        isMaximizeQueryBlock={isMaximizeQueryBlock}
        isChatPage={isChatPage}
        isProfiled={isProfiled}
        isChartSettingsVisible={isQuerySettingsVisible}
      >
        <div className="query-block-full-screen-body">
          <QueryBlockStyled
            isMaximizeQueryBlock={isMaximizeQueryBlock}
            isChatPage={isChatPage}
            isProfiled={isProfiled}
            isExpanded={isExpanded}
            isViewOnlyChatQuestionPage={isViewOnlyChatQuestionPage}
            onClick={(): void => {
              !isFocused && !isViewOnlyChatQuestionPage && onFocused?.(id);
            }}
            isFocused={isFocused}
            data-testid="analysis-detail-page-query-block-main-wrapper"
            className={
              isChatPage
                ? "chat-query-block-styled"
                : "analysis-query-block-styled"
            }
            isQuerySettingsVisible={isQuerySettingsVisible}
          >
            {isEnabled && <DataFetcherForSampleDataTables {...props} />}
            <div className="query-block-left-sec" />

            <div
              className="query-block-right-sec"
              data-testid="analysis-detail-page-query-block-right-sec-wrapper"
            >
              <Row>
                <Col
                  span={
                    isQuerySettingsVisible &&
                    isExpanded &&
                    loaderType !== "running_analysis"
                      ? 18
                      : 24
                  }
                >
                  <div
                    className="query-block-header-collapse"
                    data-testid="analysis-detail-page-query-block-header-collapse"
                  >
                    {!isViewOnlyChatQuestionPage &&
                      (isConceptQuery ? (
                        <div
                          data-testid="analysis-detail-page-query-block-share-concept-icon"
                          className="concept-icon"
                        >
                          {shareConceptSvgYellowVariant}
                        </div>
                      ) : (
                        <Tooltip
                          title={
                            !isExpanded ? "Expand Block" : "Collapse Block"
                          }
                          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                        >
                          <div
                            onClick={(e): void => {
                              onExpand?.(id, e);
                            }}
                            role="button"
                            className="collapse-arrow"
                            data-testid={`analysis-detail-page-query-block-${
                              isExpanded ? "collapse" : "expand"
                            }-arrow`}
                          >
                            {isExpanded
                              ? downArrowFilledBlue()
                              : sortRightIcon()}
                          </div>
                        </Tooltip>
                      ))}

                    <QueryBlockHeader
                      queryItem={queryItem}
                      handlers={handlers}
                      hasUserAccessToEdit={hasUserAccessToEdit}
                      isSavedContextsSecVisible={isSavedContextsSecVisible}
                    />
                  </div>

                  {!(!isExpanded && collapseView === "questions_only") && (
                    <QueryResults
                      queryContext={queryContext}
                      queryItem={queryItem}
                      handlers={handlers}
                      hasUserAccessToEdit={hasUserAccessToEdit}
                      onUseSampleQuestionClick={onUseSampleQuestionClick}
                      isAppliedfiltersChanged={isAppliedfiltersChanged}
                      index={index}
                      isSavedContextsSecVisible={isSavedContextsSecVisible}
                      totalItems={totalItems}
                      queries={queries}
                      analysisSrcTypeId={analysisSrcTypeId}
                      isAuthor={isAuthor}
                      gatewayId={gatewayId}
                    />
                  )}
                </Col>

                {isQuerySettingsVisible &&
                  isExpanded &&
                  loaderType !== "running_analysis" &&
                  !isRestAPISource && (
                    <Col span={6}>
                      <QueryBlockSettings
                        chartConfig={chartConfig}
                        handlers={handlers}
                        queryItem={queryItem}
                        activeTab={activeTab}
                      />
                    </Col>
                  )}
              </Row>
            </div>
          </QueryBlockStyled>
        </div>
      </QueryBlockOuterWrapperStyled>
    </div>
  );
};

export default QueryBlock;
