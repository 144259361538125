export const toCapitalize = (val: string): string => {
  try {
    const words = val?.split(" ")?.filter((word) => word?.length) || [];

    return words
      ?.map(
        (word) =>
          `${word?.[0]?.toUpperCase()}${
            word?.length > 1 ? word?.substring(1) : ""
          }`
      )
      ?.join(" ");
  } catch {
    return val;
  }
};
