import styled from "styled-components";

export const MassUpdateFormStyled = styled.div<{
  type: string;
}>`
  .ant-form {
    .content-sec {
      padding-top: 50px;
      max-height: 456px;
      overflow-y: auto;

      .field-label {
        font-family: OpenSansSemiBold;
        margin-bottom: 2px;
        padding-left: 55px;
      }

      .field-label-desc {
        margin-bottom: 8px;
        padding-left: 55px;
        font-size: 12px;
        color: ${({ theme }): string => theme.textColors.GREY_24};
      }

      .field-content {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
      }
    }
    .description-sec {
      padding: 10px 55px 0px 55px;
      span {
        font-size: 12px;
        color: ${({ theme }): string => theme.textColors.GREY_24};
      }
    }

    .form-actions-sec {
      margin-top: 48px;
    }
  }
`;
