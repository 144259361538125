import { AxiosResponse } from "axios";

import { API_CONFIG } from "../../constants/apiconfig";
import { useGetAll } from "../baseservice";

import { ESPageNodes } from "../../pages/enterprisesearchpage/enterprisesearchpage.types";
import { CustomReactQueryHookReturnType } from "../services.types";

import { removeFilterCount } from "../../pages/enterprisesearchpage/enterprisesearchpage.utils";
import {
  MenuItemsGroupedType,
  ParsedDataForSearchType,
} from "../../components/searchwithdropdown/searchwithdropdown.types";

import { enterpriseSearchResultsParser } from "../../parsers";
import { RetuntypeOfEnterpriseSearchResults } from "../../parsers/enterprisesearchparser/enterprisesearch.parser.types";
import { getPostLoginData, jsonParse } from "../../utils";

const {
  post_enterprise_search: postSearchAPI,
  get_search_filters_aggregate: searchFilterAggregateAPI,
  get_enterprise_search_results: searchResultsAPI,
  get_nodes_count: getNodesCountAPI,
  get_recent_search_keywords: getRecentSearchKeywords,
} = API_CONFIG;

interface IEnterpriseSearchQuery {
  search_text: string;
  parser: (_data: any) => any;
}

function removeFilterCountKeywordFromFilterValues(
  filters: { [key: string]: string[] } = {}
): { [key: string]: string[] } {
  return Object.entries(filters)?.reduce((prev, next) => {
    const [objKey, objValue] = next;
    return {
      ...prev,
      [objKey]: objValue?.map((item) => removeFilterCount(item)),
    };
  }, {});
}

export const useGetHeaderSearchResults = (
  searchObject: IEnterpriseSearchQuery
): CustomReactQueryHookReturnType<ParsedDataForSearchType> => {
  const { user_info: userInfo = {} } = getPostLoginData();

  const {
    catalog_access_filter: catalogAccessFilter = "",
    glossary_access_filter: glossaryAccessFilter = "",
  } = userInfo;

  const glossaryAccessFilter1 = jsonParse(glossaryAccessFilter);
  const glossaryTrmFilter = glossaryAccessFilter1?.TRM?.filter_es_stmt || [];

  const catalogAccessFilter1 = jsonParse(catalogAccessFilter);

  const catalogColFilter = catalogAccessFilter1?.COL?.filter_es_stmt || [];
  const catalogDsrFilter = catalogAccessFilter1.DSR?.filter_es_stmt || [];
  const catalogTblFilter = catalogAccessFilter1.TBL?.filter_es_stmt || [];
  const catalogAnlFilter = catalogAccessFilter1.ANL?.filter_es_stmt || [];

  const asciiEncodedString = window.btoa(
    JSON.stringify({
      search_text: searchObject.search_text,
      TBL: {
        node_type: "TBL",
        manage_access_filter: catalogTblFilter,
      },
      COL: {
        node_type: "COL",
        manage_access_filter: catalogColFilter,
      },
      DSR: {
        node_type: "DSR",
        manage_access_filter: catalogDsrFilter,
      },
      TRM: {
        node_type: "TRM",
        manage_access_filter: glossaryTrmFilter,
      },
      ANL: {
        node_type: "ANL",
        manage_access_filter: catalogAnlFilter,
      },
    })
  );

  return useGetAll({
    apiConfig: postSearchAPI,
    params: [asciiEncodedString],
    parser: searchObject.parser,
    options: {
      enabled: false,
    },
  });
};

type RangeFilterType = {
  record_count?: {
    gte: string;
    lte: string;
  };
  last_refreshed_on?: {
    gte: string;
    lt: string;
  };
  "profiling_info.count.distinct_count"?: {
    gte: string;
    lte: string;
  };
  "profiling_info.count.record_count"?: {
    gte: string;
    lte: string;
  };
};

export interface IAppliedFiltersProps {
  from?: number;
  size?: number;
  search_text?: string;
  node_type: ESPageNodes;
  term_filters?: { [key: string]: string[] };
  nodes_range_filters?: { [key in ESPageNodes]?: RangeFilterType };
  range_filters: RangeFilterType;
  nodes_term_filters?: {
    [key in ESPageNodes]?: { [key: string]: string[] };
  };
  isNewSearch?: boolean;
  keyword?: {
    [key in ESPageNodes]?: string;
  };
}

interface IFilterAggregateProps {
  agg_prop?: string;
  agg_prop_values?: any[];
  node_type?: ESPageNodes;
  term_filters?: { [key: string]: string[] };
  range_filters?: RangeFilterType;
}

export const useGetFilterAggregateData = (
  filterAggregateObject: IFilterAggregateProps | undefined,
  search_text?: string
): any => {
  const { user_info: userInfo = {} } = getPostLoginData();

  const {
    catalog_access_filter: catalogAccessFilter = "",
    glossary_access_filter: glossaryAccessFilter = "",
  } = userInfo;

  const parsedCatalog = jsonParse(catalogAccessFilter);
  const parsedGlossary = jsonParse(glossaryAccessFilter);

  /* If Tag filters cause a problem, the condition has to be handled here to exclude the selected filters */

  const formattedTermFilters = removeFilterCountKeywordFromFilterValues(
    filterAggregateObject?.term_filters
  );

  const nodeType = filterAggregateObject?.node_type || "TBL";

  const manageAccessFilter =
    nodeType === "TRM"
      ? { TRM: parsedGlossary?.TRM?.filter_es_stmt }
      : { [nodeType]: parsedCatalog?.[nodeType]?.filter_es_stmt };

  const filterAggregateObjectExcludedCurrentSelected = {
    search_text,
    ...filterAggregateObject,
    manage_access_filter: manageAccessFilter,
    term_filters:
      filterAggregateObject?.term_filters &&
      Object.entries(formattedTermFilters)?.reduce(
        (termFilters, termFilter: any) => {
          const [filterAPIName, filterData] = termFilter;

          if (filterAPIName === filterAggregateObject?.agg_prop) {
            return termFilters;
          }
          termFilters[filterAPIName] = filterData;
          return termFilters;
        },
        {} as any
      ),
  };

  const asciiEncodedString = window.btoa(
    JSON.stringify(filterAggregateObjectExcludedCurrentSelected)
  );

  return useGetAll({
    apiConfig: searchFilterAggregateAPI,
    params: [asciiEncodedString],
    options: { enabled: !!filterAggregateObject },
    parser: ({ data = {} }) => {
      try {
        const [filterCategoryKey, filterDataRaw] =
          Object.assign(Object.entries(data || {}))?.[0] || [];
        const responseFilters = Object.keys(filterDataRaw || {}).map((item) => {
          //$$filterCount= is a count of filter concating with value it will remove from values when it send back to Backend
          return {
            key: `${item}$filterCount=${filterDataRaw?.[item] || 0}`,
            value: `${item}$filterCount=${filterDataRaw?.[item] || 0}`,
            isSelected: formattedTermFilters?.[filterCategoryKey]?.includes(
              item
            ),
          };
        });

        const selectedFiltersInTermFilter =
          formattedTermFilters?.[filterAggregateObject?.agg_prop || ""]
            ?.map((item) => {
              const isResContainSelFilt = responseFilters.some(
                (res) => res?.key?.split("$filterCount=")?.[0] === item
              );
              return isResContainSelFilt
                ? { key: "", val: "", isSelected: false }
                : {
                    key: `${item}$filterCount=0`,
                    value: `${item}$filterCount=0`,
                    isSelected: true,
                  };
            })
            ?.filter((item) => item?.key) || [];
        return [...responseFilters, ...selectedFiltersInTermFilter];
      } catch (e) {
        return undefined;
      }
    },
  });
};

export const useGetResults = (
  appliedFilters: IAppliedFiltersProps
): CustomReactQueryHookReturnType<RetuntypeOfEnterpriseSearchResults> => {
  const { user_info: userInfo = {} } = getPostLoginData();

  const {
    catalog_access_filter: catalogAccessFilter = "",
    glossary_access_filter: glossaryAccessFilter = "",
  } = userInfo;

  const parsedCatalog = jsonParse(catalogAccessFilter);
  const parsedGlossary = jsonParse(glossaryAccessFilter);
  const nodeType = appliedFilters?.node_type || "TBL";

  const manageAccessFilter =
    nodeType === "TRM"
      ? { TRM: parsedGlossary?.TRM?.filter_es_stmt }
      : { [nodeType]: parsedCatalog?.[nodeType]?.filter_es_stmt };

  const updatedAppliedFilters = {
    ...appliedFilters,
    term_filters: removeFilterCountKeywordFromFilterValues(
      appliedFilters?.term_filters
    ),
    range_filters: appliedFilters?.range_filters,
    search_text: appliedFilters?.keyword?.[appliedFilters?.node_type] || "",
    manage_access_filter: manageAccessFilter,
  };

  const asciiEncodedString = window.btoa(JSON.stringify(updatedAppliedFilters));

  return useGetAll({
    apiConfig: searchResultsAPI,
    params: [asciiEncodedString],
    parser: enterpriseSearchResultsParser,
  });
};

export const useGetNodesCount = (
  appliedFilters: IAppliedFiltersProps
): CustomReactQueryHookReturnType<{
  nodes_count: {
    tables: number;
    columns: number;
    terms: number;
    datasets: number;
    analysis: number;
    rules: number;
  };
}> => {
  const { user_info: userInfo = {} } = getPostLoginData();

  const {
    catalog_access_filter: catalogAccessFilter = "",
    glossary_access_filter: glossaryAccessFilter = "",
  } = userInfo;

  const parsedCatalog = jsonParse(catalogAccessFilter);
  const parsedGlossary = jsonParse(glossaryAccessFilter);

  const asciiEncodedString = window.btoa(
    JSON.stringify({
      ...appliedFilters,
      TBL: {
        search_text: appliedFilters?.keyword?.TBL,
        node_type: "TBL",
        term_filters: removeFilterCountKeywordFromFilterValues(
          appliedFilters?.nodes_term_filters?.TBL
        ),
        range_filters: appliedFilters?.nodes_range_filters?.TBL,
        manage_access_filter: parsedCatalog?.TBL?.filter_es_stmt,
      },
      COL: {
        search_text: appliedFilters?.keyword?.COL,
        node_type: "COL",
        term_filters: removeFilterCountKeywordFromFilterValues(
          appliedFilters?.nodes_term_filters?.COL
        ),
        range_filters: appliedFilters?.nodes_range_filters?.COL,
        manage_access_filter: parsedCatalog?.COL?.filter_es_stmt,
      },
      TRM: {
        search_text: appliedFilters?.keyword?.TRM,
        node_type: "TRM",
        term_filters: removeFilterCountKeywordFromFilterValues(
          appliedFilters?.nodes_term_filters?.TRM
        ),
        manage_access_filter: parsedGlossary?.TRM?.filter_es_stmt,
      },
      DSR: {
        search_text: appliedFilters?.keyword?.DSR,
        node_type: "DSR",
        term_filters: removeFilterCountKeywordFromFilterValues(
          appliedFilters?.nodes_term_filters?.DSR
        ),
        range_filters: appliedFilters?.nodes_range_filters?.DSR,
        manage_access_filter: parsedCatalog?.DSR?.filter_es_stmt,
      },
      ANL: {
        search_text: appliedFilters?.keyword?.ANL,
        node_type: "ANL",
        term_filters: removeFilterCountKeywordFromFilterValues(
          appliedFilters?.nodes_term_filters?.ANL
        ),
        range_filters: appliedFilters?.nodes_range_filters?.ANL,
        manage_access_filter: parsedCatalog?.ANL?.filter_es_stmt,
      },
      RLS: {
        search_text: appliedFilters?.keyword?.RLS,
        node_type: "RLS",
        term_filters: removeFilterCountKeywordFromFilterValues(
          appliedFilters?.nodes_term_filters?.RLS
        ),
        range_filters: appliedFilters?.nodes_range_filters?.RLS,
        manage_access_filter: parsedCatalog?.RLS?.filter_es_stmt,
      },
    })
  );

  return useGetAll({
    apiConfig: getNodesCountAPI,
    params: [asciiEncodedString],
    parser: ({
      data,
    }: AxiosResponse<{
      nodes_count: {
        tables: number;
        columns: number;
        terms: number;
        datasets: number;
        analysis: number;
        rules: number;
      };
    }>) => {
      return {
        nodes_count: {
          tables: data?.nodes_count?.tables || 0,
          columns: data?.nodes_count?.columns || 0,
          terms: data?.nodes_count?.terms || 0,
          datasets: data?.nodes_count?.datasets || 0,
          analysis: data?.nodes_count?.analysis || 0,
          rules: data?.nodes_count?.rules || 0,
        },
      };
    },
    options: {
      enabled: true,
    },
  });
};

export const useGetRecentSearchKeywords = (): CustomReactQueryHookReturnType<
  MenuItemsGroupedType[]
> => {
  return useGetAll({
    apiConfig: getRecentSearchKeywords,
  });
};
