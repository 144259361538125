import { AxiosResponse } from "axios";
import moment from "moment";
import { NodeType } from "../../app.types";

import {
  getObjectKeys,
  jsonParse,
  sortTagsetsDetailPageTags,
} from "../../utils";
import { NodeResponseTagsetConfig } from "../parser.types";

import {
  TagsetListingTagDetail,
  TagsetDetailParserReturnType,
  TagsetDetailResponse,
  TagsetsParserReturnType,
  TagsetsResponse,
  ReturnTypeOfAllTagsetConfigParser,
} from "./tagsetsparser.types";

export const getParsedTagsets = ({
  data,
}: AxiosResponse<TagsetsResponse[]>): TagsetsParserReturnType[] => {
  return (
    data?.map((item) => {
      const tagDetails = (jsonParse(
        item?.TAG_DTLS
      ) as TagsetListingTagDetail[])?.filter((tag) => tag?.tag_id);
      const tags = tagDetails?.map((item) => item?.tag_name || "");
      const applicableTo = item?.APPLICABLE_NODE_TYPES?.split(",") || [];
      const modifiedOn = item?.UPDATED_ON || "";
      return {
        id: item?.TAGSET_ID || 0,
        name: item?.TAGSET_NAME || "",
        tags,
        applicable_to: applicableTo,
        usage_count: Number.parseInt(item?.USABILITY_COUNT || "0"),
        modified_on: modifiedOn,
        is_prominent: item?.IS_PROMINENT,
        tag_summary: {
          tags: tagDetails?.map((nestItem) => ({
            name: nestItem?.tag_name || "",
            type: nestItem?.is_prominent ? nestItem?.color_code : "GRY",
            tagDetails: {
              tagName: nestItem?.tag_name || "",
              tagsetName: item?.TAGSET_NAME || "",
              showDetailInfo: false,
              tagsetDesc: nestItem?.tag_desc || "",
              tagsetId: `${item?.TAGSET_ID}`,
            },
          })),
          isShowOnlyOne: true,
        },
      };
    }) || []
  );
};

export const getParsedTagsetDetail = ({
  data,
}: AxiosResponse<TagsetDetailResponse>): TagsetDetailParserReturnType => {
  const informationObj = data?.information?.[0] || {};
  const applicableToObj = jsonParse(informationObj?.APPLICABLE_TO);
  const applicableToCount: {
    terms?: number;
    fields?: number;
    datasets?: number;
    models_reports?: number;
    rules?: number;
  } = {};

  const updated_on = informationObj?.UPDATED_ON
    ? moment
        .utc(informationObj?.UPDATED_ON)
        .local()
        .format("MM/DD/YYYY hh:mm A")
    : "";
  const created_on = informationObj?.CREATED_ON
    ? moment
        .utc(informationObj?.CREATED_ON)
        .local()
        .format("MM/DD/YYYY hh:mm A")
    : "";

  if (applicableToObj?.TRM)
    applicableToCount.terms = applicableToObj?.TRM?.count || 0;
  if (applicableToObj?.TBL)
    applicableToCount.datasets = applicableToObj?.TBL?.count || 0;
  if (applicableToObj?.COL)
    applicableToCount.fields = applicableToObj?.COL?.count || 0;
  if (applicableToObj?.DSR)
    applicableToCount.models_reports = applicableToObj?.DSR?.count || 0;
  if (applicableToObj?.RLS)
    applicableToCount.rules = applicableToObj?.RLS?.count || 0;

  const parsedTags = data?.tags?.map((tag) => ({
    tag_id: tag?.TAG_ID || 0,
    tag: tag?.TAG_NAME || "",
    description: tag?.TAG_DESC || "",
    prominent_tag_id: tag?.COLOR_CODE || "GRY",
    privacy: tag?.TAG_PRIVACY || "NON",
    usage_count: tag?.USABILITY_COUNT || 0,
  }));

  return {
    tagset_name: informationObj?.TAGSET_NAME || "",
    created_by: informationObj?.CREATED_BY || "",
    updated_by: informationObj?.UPDATED_BY || "",
    created_on,
    updated_on,
    is_editable: !!informationObj?.IS_EDITABLE,
    information: {
      name: informationObj?.TAGSET_NAME || "",
      description: informationObj?.TAGSET_DESC || "",
      type: informationObj?.TAGSET_TYPE_ID || "",
      allow_suggestions: informationObj?.IS_NEW_VALUE_ALLOWED || false,
      single_value: !informationObj?.IS_MULTI_VALUE_ALLOWED || false,
      applicable_to: applicableToCount,
      is_note_required: !!informationObj?.IS_COMMENT_REQUIRED,
      is_policy_linked: !!informationObj?.IS_POLICY_LINKED,
      tagset_policy_link: informationObj?.TAGSET_POLICY_INFO,
    },
    tags: sortTagsetsDetailPageTags(
      parsedTags,
      informationObj?.TAGSET_TYPE_ID === "CLS"
    ),
  };
};

export const getparsedAllTagsetConfig = ({
  data,
}: AxiosResponse<
  { [key in NodeType]: NodeResponseTagsetConfig }
>): ReturnTypeOfAllTagsetConfigParser => {
  const keys = getObjectKeys(data) as Array<NodeType>;
  return keys.reduce(
    (prev, next) => ({
      ...prev,
      [next]: data?.[next] || {},
    }),
    {}
  );
};
