export const containsPercentageFormatingForCol = (
  sqlQuery: string,
  colName: string
): boolean => {
  try {
    // Define the regular expression pattern to search for with word boundaries
    const pattern = new RegExp(`\\s\\*\\s+100\\s+AS\\s+${colName}\\b`, "i");

    // Check if the pattern exists in the SQL query
    return pattern.test(sqlQuery);
  } catch {
    return false;
  }
};
