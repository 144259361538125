import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../constants";
import { tableDetailPageTabsValues } from "../pages/tablepage/tablepage.constants";
import { TablePageParamViewKeys } from "../pages/tablepage/tablepage.types";

export function getTableDetailPageUrl(
  id: string,
  tab?: TablePageParamViewKeys
): string {
  const recentlyVisitedTab =
    localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.recent_visited_table_detail_page_tab
    ) || tableDetailPageTabsValues.overview;

  const redirectTo = tab || recentlyVisitedTab;

  return `${APP_ROUTES.private_routes.table_dictionary}/${id}/${redirectTo}`;
}
