import { useGetAppState } from "../../customhooks";
import { noDataExistsIcon } from "../../svgs";

import { InformationModalStyled } from "./informationmodal.styles";
import { InformationModalProps } from "./informationmodal.types";

const InformationModal = (): JSX.Element => {
  const { modal } = useGetAppState();

  const { modalProps } = modal || {};

  const {
    textTitle,
    textDescription = "",
    actionsButtonContent,
  } = modalProps as InformationModalProps;

  return (
    <InformationModalStyled>
      <div className="modal-content">
        <span className="icon">{noDataExistsIcon("40", "40")}</span>
        <div className="text-wrapper">
          <p className="title">{textTitle}</p>
          <span className="desc">{textDescription}</span>
        </div>
        <div className="actions-wrapper">
          {actionsButtonContent && actionsButtonContent?.()}
        </div>
      </div>
    </InformationModalStyled>
  );
};

export default InformationModal;
