import { TagType } from "../../pages/tagsetsdetailpage/tagsetsdetailpage.types";

export const mergeTags = (
  selectedTags: TagType[],
  targetTagId: string
): TagType => {
  let mergedTags: TagType = selectedTags?.filter(
    (tag) => `${tag?.tag_id}` === targetTagId
  )?.[0];

  const nonTargetTags = [
    ...(selectedTags?.filter((tag) => `${tag?.tag_id}` !== targetTagId) || []),
  ];

  const usageCounts = nonTargetTags
    ?.map((tag) => tag?.usage_count)
    ?.reduce((total, current) => total + current);

  mergedTags = {
    ...mergedTags,
    usage_count: (mergedTags?.usage_count || 0) + usageCounts,
  };

  return mergedTags;
};
