import {
  DataImportParamKeysType,
  DataImportParamValuesType,
} from "./dataimportpage.types";

export const DATA_IMPORT_STEPS = [
  "Select Asset Type",
  "Upload File",
  "Map Fields",
  "Import",
];

export const DATA_IMPORT_PARAMS: {
  [key in DataImportParamKeysType]: DataImportParamValuesType;
} = {
  ACTIVE_STEP: "active-step",
  SELECTED_ASSET_TYPE: "selected-asset-type",
};

export const resetFieldMappingModalText =
  "By resetting the fields mapping, you will override the current settings. Do you want to proceed?";

export const applyAutoMappingModalText =
  "When you apply auto mapping, the remaining unmapped fields will be mapped with the matching fields in the file. Do you want to proceed?";

export const FILE_UPLOADING_TOOLTIP_MESSAGE = "File uploading is in progress";
export const RECORDS_LIMIT_EXCEEDED_TOOLTIP_MESSAGE =
  "Please upload a file with upto 50,000 records";
export const MAP_REQUIRED_FIELDS_TOOLTIP_MESSAGE = "Please map required fields";