import styled from "styled-components";
import { hexToRgbA } from "../../utils";
import { ellipsis } from "../../styles/app.styles";

export const RuleRefDrawerStyled = styled.div`
  padding-bottom: 2px;

  .rule-ref-header {
    padding: 32px 44px 22px 31px;

    .rule-header-breadcrums {
      margin-bottom: 15px;

      .header-item {
        display: flex;
        align-items: center;
        min-width: 70px;
        .item-icon {
          margin-right: 6px;
          max-height: 23.5px;
          svg {
            path {
              fill: ${({ theme }): string =>
                theme.bgColors.BLUE_116} !important;
            }
          }
        }
        .item-title {
          cursor: pointer;
          font-size: 15px;
          color: ${({ theme }): string => theme.textColors.BLUE_116};
          ${ellipsis}
        }
        .right-icon {
          margin: 0px 11px;
        }
      }
    }

    .header_row_2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;

      .section-header {
        display: flex;
        gap: 10px;

        .info {
          margin: 0;
        }
      }

      .letter-icon-mapping {
        svg {
          path {
            fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
          }
        }
      }

      .scheduled-icon {
        svg {
          path {
            fill: ${({ theme }): string => theme.bgColors.GREY_210};
          }
        }
      }
    }
  }

  .rule-ref-section {
    padding: 15px 44px 0 31px;
    border-top: 1px solid
      ${({ theme }): string => hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};

    .table-field-name {
      display: flex;
      align-items: center;
      gap: 6px;

      .letter-icon-mapping {
        font-size: 10px;
      }
    }
  }
  .definition-sec {
    padding: 30px 44px 0 31px;
  }
`;

export const DataDiffCheckReadableWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 5px;
  flex-direction: column;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-form-item-label > label::after {
    content: "";
  }

  .datatype-bullet {
    width: 9px;
    height: 20px;
    border-left: 1px solid rgb(207, 206, 206);
    border-bottom: 1px solid rgb(207, 206, 206);
    margin-left: 7px;

    span {
      display: block;
      width: max-content;
      font-size: 13.6px;
      margin-left: 17px;
      margin-top: 6px;
    }
  }
`;

export const SchemaRendererStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  .svg {
    color: ${({ theme }): string => theme.textColors.GREY_18};
    font-size: 12px;
  }
`;

export const MultipleColumnsRendererStyled = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 5px;
  flex-wrap: wrap;
  row-gap: 10px;
`;
