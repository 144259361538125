import dvSumInformativeText from "../dvsuminformativetext.json";
import dvSumInformativeTextWithoutDesc from "../dvsuminformativetextwithoutdesc.json";

import { getChatPrefrencesData } from "../utils";

export const getDvSumInformativeText = (): typeof dvSumInformativeText => {
  const {
    is_descriptive_mode_enabled: isDescriptiveModeEnabled,
  } = getChatPrefrencesData();

  return isDescriptiveModeEnabled
    ? dvSumInformativeText
    : dvSumInformativeTextWithoutDesc;
};
