import { AxiosResponse } from "axios";

import {
  DataImportDataAndCountResponse,
  DataImportDataAndCountReturnType,
  DataImportUploadSignedUrlResponse,
  DataImportUploadSignedUrlReturnType,
  FieldsInFileType,
  ResponseTypeOfDataImportConfig,
  ResponseTypeOfDataImportFieldsConfig,
  ReturnTypeOfDataImportConfig,
  ReturnTypeOfDataImportFieldsConfig,
} from "./dataimportparsers.types";

export const getParsedDataImportConfig = ({
  data,
}: AxiosResponse<ResponseTypeOfDataImportConfig>): ReturnTypeOfDataImportConfig => {
  return {
    assets_view_config: data?.map((asset) => {
      const { REF_ADHOC_INFO: refAdhocInfo } = asset || {};
      const {
        asset_type_id: assetTypeId = "TBL",
        asset_type_name: assetTypeName = "",
        asset_type_active_url: assetTypeActiveUrl = "",
        asset_type_inactive_url: assetTypeInactiveUrl = "",
        display_order: displayOrder = 0,
        is_imp_enabled: isEnabled = true,
      } = refAdhocInfo || {};

      return {
        id: assetTypeId,
        name: assetTypeName,
        inactive_icon_url: assetTypeActiveUrl,
        active_icon_url: assetTypeInactiveUrl,
        display_order: displayOrder,
        is_enabled: isEnabled,
      };
    }),
    upload_file_view_config: data?.map((asset) => {
      const { REF_ADHOC_INFO: refAdhocInfo } = asset || {};
      const {
        asset_type_id: assetTypeId = "TBL",
        asset_type_name: assetTypeName = "",
        import_type: importType = [],
        record_identifiers: recordIdentifiers = [],
        asset_type_update_option: assetTypeUpdateOption = {
          field_label: "",
          field_value: false,
          tooltip_text: "",
        },
        retain_existing_value_if_blank: retainExistingValueIfBlank = false,
      } = refAdhocInfo || {};

      return {
        asset_id: assetTypeId,
        asset_name: assetTypeName,
        import_type: importType,
        record_identifiers:
          recordIdentifiers?.map((identifier) => ({
            value: identifier?.field_id.toString() || "",
            label: identifier?.field_name || "",
          })) || [],
        asset_type_update_option: { ...assetTypeUpdateOption },
        is_retain_existing_value_if_blank: retainExistingValueIfBlank,
      };
    }),
  };
};

export const getParsedDataImportFieldsConfig = ({
  data = [],
}: AxiosResponse<ResponseTypeOfDataImportFieldsConfig>): ReturnTypeOfDataImportFieldsConfig => {
  const gridData = data?.map((field) => {
    const {
      field_id: fieldId = 0,
      field_name: fieldName = "",
      data_type: dataType = "",
      display_order: displayOrder = 0,
      imp_tgt_field_nm: impTgtFieldNm = "",
      is_mandatory: isMandatory = false,
      selected_record_identifier_id: selectedRecordIdentifierId = "",
    } = field;

    return {
      id: fieldId,
      name: fieldName,
      data_type: dataType,
      display_order: displayOrder,
      imp_tgt_field_nm: impTgtFieldNm,
      is_mandatory: isMandatory,
      selected_record_identifier_id: selectedRecordIdentifierId,
    };
  });

  return {
    grid_data: gridData,
    fields_list: gridData?.map((field) => ({
      label: field?.name,
      value: field?.name,
      key: field?.name,
      is_required: field?.is_mandatory,
      is_mapped: false,
      selected_record_identifier_id: field?.selected_record_identifier_id,
    })),
  };
};

export const getParsedDataImportUploadSignedUrl = ({
  data,
}: AxiosResponse<DataImportUploadSignedUrlResponse>): DataImportUploadSignedUrlReturnType => {
  const {
    url = "",
    fields: {
      key = "",
      AWSAccessKeyId = "",
      "x-amz-security-token": xAmzSecurityToken = "",
      policy = "",
      signature = "",
    } = {},
  } = data;

  return {
    url,
    fields: {
      key,
      AWSAccessKeyId,
      "x-amz-security-token": xAmzSecurityToken,
      policy,
      signature,
    },
  };
};

export const getParsedDataImportDataAndCount = ({
  data,
}: AxiosResponse<DataImportDataAndCountResponse>): DataImportDataAndCountReturnType => {
  const { sample_data: sampleData = [], count } = data;

  if (sampleData?.length) {
    const keys: string[] = sampleData?.[0]?.map((item) => item?.trim()) || [];
    const sampleDataObj: FieldsInFileType = {};

    for (let i = 0; i < keys?.length; i++) {
      sampleDataObj[keys[i]] = [
        sampleData?.[1]?.[i] || "",
        sampleData?.[2]?.[i] || "",
        sampleData?.[3]?.[i] || "",
      ];
    }

    return {
      fields: sampleDataObj,
      records_count: count,
      fields_with_index: keys?.reduce(
        (prev, next, index) => ({
          ...prev,
          [next]: index + 1,
        }),
        {}
      ),
    };
  }

  return { fields: {}, records_count: 0, fields_with_index: {} };
};

// TODO:  use real API data, this one is for glossary upload
export const getDataImportTemplateDownloadLinkMetadata = ({
  data,
}: AxiosResponse<string>): string => {
  return data || "";
};

export const getImportOwnerParsedData = ({
  data,
}: AxiosResponse<string>): string => {
  return data || "";
};
