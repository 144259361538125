import styled from "styled-components";
import { ellipsis } from "../../../../../../../../../../../../styles/app.styles";

export const QueryFieldSettingsWrapperStyled = styled.div`
  display: flex;
  padding: 15px 0;
  row-gap: 15px;
  flex-direction: column;

  .divider {
    background-color: ${({ theme }): string => theme?.bgColors?.GREY_56};
  }

  .alert-container {
    .ant-alert-message {
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
      font-style: italic;
      font-size: 12px;

      .view-name-span {
        font-size: 12px;
        font-style: italic;
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }

    svg {
      max-height: 14px;
      margin-right: 8px;
      path {
        fill: ${({ theme }): string => theme?.textColors?.GREY_210};
      }
    }
  }

  .row-1 {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
      margin-right: 7px;
      max-width: 13px;
      path {
        fill: ${({ theme }): string => theme?.bgColors?.GREY_25};
      }

      circle {
        fill: ${({ theme }): string => theme?.bgColors?.GREY_25};
      }
    }

    .col-profile-data-type-icon {
      display: flex;

      svg {
        opacity: 0.3;
      }
    }

    .column-name {
      color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      font-family: OpenSansSemiBold;
      flex: 1;
      ${ellipsis}
    }
  }
`;

export const FieldDetailsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    color: ${({ theme }): string => theme?.textColors?.GREY_40};
    font-family: OpenSansSemiBold;
    font-size: 14px;
  }

  .desc {
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    font-size: 12px;
    margin-bottom: 10px;
  }

  .default-preference {
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    font-size: 12px;
    text-align: start;
    margin-top: 5px;
  }
`;

export const VerticalSpacerStyled = styled.div`
  height: 20px;
`;

export const RadioGroupWrapper = styled.div`
  .ant-radio-group-outline {
    margin-top: 6px;
  }
  .ant-radio-wrapper > span {
    margin-left: 4px;
  }
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-radio {
    top: 0;
  }

  .ant-radio-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-radio-group .ant-radio-wrapper {
    width: 200px;
  }

  .label-icon-wrapper {
    display: flex;
    column-gap: 5px;
    align-items: center;

    span {
      line-height: 12px;
      margin-top: 3px;
    }
  }
`;
