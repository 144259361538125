import styled from "styled-components";

export const AddItemFormStyled = styled.div`
  margin-top: 15px;

  .ant-form-item {
    margin-bottom: 7px !important;
  }

  .form-row {
    display: flex;
    gap: 8px;
    margin-top: 10px;

    .form-item {
      width: 494px !important;
      padding: 10px 15px;
    }

    .form-item-select{
      width: 500px;
    }
  }
`;
