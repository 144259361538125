export const selectFilterOption = (input: string, option: any): boolean => {
  try {
    const finalText =
      typeof option?.label !== "string"
        ? option?.textForSearch || option?.labelText
        : option?.label;

    return !!finalText
      ?.toString()
      .toLowerCase()
      .includes(input?.toString().toLowerCase());
  } catch {
    return false;
  }
};
