import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useRequestWithMethod } from "../../api";
import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { CheckboxField } from "../../components/formfields";
import LinkButton from "../../components/linkbutton";
import StateHandler from "../../components/statehandler/statehandler";
import { APP_ROUTES } from "../../constants";

import { filledSuccessTickInCircle } from "../../svgs";
import { registrationSchema } from "../../utils/schemas/registrationschema";
import {
  LoadingScreen,
  Loader,
  InputFieldRegistration,
  PasswordPolicyTooltip,
} from "./registrationform.components";

import {
  agreeToTermsAndConditions,
  alreadyHaveAccount,
  creatingAccountText,
  description,
  RedirectText,
} from "./registrationform.constants";
import { RegistrationFormStyled } from "./registrationform.styles";
import {
  RegistrationFormScreenType,
  RegistrationFormType,
} from "./registrationform.types";

const RegistrationForm = (): JSX.Element => {
  const history = useHistory();
  const [screen, setScreen] = useState<RegistrationFormScreenType>(
    "registration-form"
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<RegistrationFormType>({
    defaultValues: {
      first_name: "",
      last_name: "",
      company_name: "",
      password: "",
      accept_terms_and_conditions: false,
    },
    resolver: yupResolver(registrationSchema),
    mode: "onChange",
  });

  const formValues = watch();

  const {
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    password,
    accept_terms_and_conditions: acceptTermsAndConditions,
  } = formValues;

  const email = (history?.location?.state as { [key: string]: string })?.email;

  const onSuccess = useCallback(() => {
    setScreen("redirect");
    setTimeout(() => {
      history.push(APP_ROUTES.auth_routes.login);
    }, 5000);
  }, [screen]);

  const onFailure = useCallback(() => {
    setScreen("registration-form");
  }, [screen]);

  const { error, onExecuteRequest } = useRequestWithMethod(
    "register",
    undefined,
    false,
    onSuccess,
    onFailure
  );

  const onSubmit = useCallback(
    (values) => {
      onExecuteRequest({ ...values, email });
      setScreen("creating-account");
    },
    [email, screen]
  );

  const renderContent = useMemo(() => {
    switch (screen) {
      case "creating-account":
        return <LoadingScreen icon={<Loader />} text={creatingAccountText} />;

      case "redirect":
        return (
          <LoadingScreen
            icon={filledSuccessTickInCircle("73.6px", "73.6px")}
            text={<RedirectText />}
          />
        );
      case "registration-form":
      default:
        return (
          <RegistrationFormStyled>
            <div className="already-have-account">
              {alreadyHaveAccount}{" "}
              <LinkButton href={APP_ROUTES.auth_routes.login}>
                Sign in
              </LinkButton>
            </div>

            <FormStyled
              onFinish={handleSubmit(onSubmit) as any}
              isItemColumnLayout
            >
              <div className="form-content">
                <div className="name-fields">
                  <FormItemStyled>
                    <InputFieldRegistration
                      control={control}
                      name="first_name"
                      placeholder="First Name"
                      value={firstName}
                    />
                  </FormItemStyled>

                  <FormItemStyled>
                    <InputFieldRegistration
                      control={control}
                      name="last_name"
                      placeholder="Last Name"
                      value={lastName}
                    />
                  </FormItemStyled>
                </div>
                <FormItemStyled>
                  <InputFieldRegistration
                    control={control}
                    name="company_name"
                    placeholder="Company Name"
                    value={companyName}
                  />
                </FormItemStyled>

                <FormItemStyled>
                  <PasswordPolicyTooltip>
                    <InputFieldRegistration
                      control={control}
                      name="password"
                      type="password"
                      isPasswordField
                      placeholder="Password"
                      value={password}
                    />
                  </PasswordPolicyTooltip>
                </FormItemStyled>

                <FormItemStyled className="terms-and-conditions">
                  <CheckboxField
                    name="accept_terms_and_conditions"
                    control={control}
                    checked={acceptTermsAndConditions}
                  />

                  <div className="terms-and-conditions-text">
                    {agreeToTermsAndConditions}{" "}
                    <LinkButton
                      isTextUnderLine
                      fontSize="13.6px"
                      href="https://dvsum.ai/terms-conditions/"
                    >
                      Terms and Conditions
                    </LinkButton>
                  </div>
                </FormItemStyled>

                <FormItemStyled className="submit-btn">
                  <Button
                    id="primary_2"
                    width="197.6px"
                    height="68px"
                    marginLeft="8px"
                    htmlType="submit"
                    disabled={!isValid}
                  >
                    Create Account
                  </Button>
                </FormItemStyled>
              </div>
            </FormStyled>
          </RegistrationFormStyled>
        );
    }
  }, [screen, formValues]);

  return (
    <StateHandler isFetching={false} error={error} isShowBlankSlate={false}>
      <>
        <div className="header">
          <div
            role="button"
            onClick={(): void => {
              window.location.href = "https://dvsum.ai";
            }}
            className="image-click-sec"
          >
            <img src="/assets/dvsum-logo.png" alt="app-logo" />
          </div>
        </div>
        <div className="registration-page-body">
          {screen === "registration-form" && (
            <h1 className="title">{description}</h1>
          )}
          {renderContent}
        </div>
      </>
    </StateHandler>
  );
};

export default RegistrationForm;
