import { getEnvVariables } from "../../../../utils";

export const getJobApiInformation = (
  jobId: string
): {
  postReqWithId: string;
  getReqWithId: string;
} => {
  const { REACT_APP_API_DOMAIN } = getEnvVariables();

  const postReqWithId = `curl -X POST ${REACT_APP_API_DOMAIN}/integration/jobs/${jobId}/execute -H "x-api-key: API_KEY"  -H "Authorization:default" -H "Content-Type: application/json" \\`;

  const getReqWithId = `curl -X GET ${REACT_APP_API_DOMAIN}/integration/jobs/${jobId}/details -H "x-api-key: API_KEY"  -H "Authorization:default" -H "Content-Type: application/json"\\ `;

  return { postReqWithId, getReqWithId };
};
