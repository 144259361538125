import styled from "styled-components";
import { CustomDynamicProgressBarStyledProps } from "./customdynamicprogressbar.types";

export const CustomDynamicProgressBarStyled = styled.div<CustomDynamicProgressBarStyledProps>`
  width: ${({ width }): string => width || "275px"};
  position: relative;
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  min-height: ${({ minHeight }): string => (minHeight ? `${minHeight}px` : "")};

  .progress-inner {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 0px;

    .progress-inner-fill {
      width: ${({ value }): string => `${value}%`};
      background-color: ${({ theme }): string => theme?.bgColors?.BLUE_116};
      height: ${({ height }): string => height || "12.8px"};
      cursor: ${({ isFillClickable }): string =>
        isFillClickable ? "pointer" : ""};
      color: ${({ theme }): string => theme?.bgColors?.GREY_24};
    }
    .progress-inner-empty {
      width: ${({ emptyValue }): string => `${emptyValue}%`};
      background-color: ${({ theme }): string => theme?.bgColors?.GREY_56};
      height: ${({ height }): string => height || "12.8px"};
      cursor: ${({ isEmptyClickable }): string =>
        isEmptyClickable ? "pointer" : ""};
      color: ${({ theme }): string => theme?.bgColors?.GREY_24};
    }
  }

  .progress-text {
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    display: flex;
    text-align: right;
    font-size: 12px;

    .progress-text-fill {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      left: 0;
      bottom: -4px;
    }

    .progress-text-empty {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      bottom: -4px;
    }
  }
`;
