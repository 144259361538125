import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useElementSize } from "usehooks-ts";

import { TextEditorStyled } from "./texteditor.styles";

import { TextEditorProps } from "./texteditor.types";

function TextEditor(props: TextEditorProps): JSX.Element {
  const [textEditorRef, { width }] = useElementSize();

  return (
    <TextEditorStyled ref={textEditorRef} width={width}>
      <ReactQuill {...props} />
    </TextEditorStyled>
  );
}

export default TextEditor;
