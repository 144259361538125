import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const ListingPageStyled = styled.div<{
  isViewSaved?: boolean;
  isLoadingData?: boolean;
  isSelectAll?: boolean;
  isProfilingInProgress?: boolean;
  isRulePageOutsideOfListing?: boolean;
}>`
  .profiling-info-wrapper {
    margin-bottom: ${({ isRulePageOutsideOfListing }): string =>
      isRulePageOutsideOfListing ? "20px" : "0"};
  }

  .ant-progress {
    top: ${({ isRulePageOutsideOfListing }): string =>
      isRulePageOutsideOfListing ? "85px" : "66px"};
  }

  .gritter-notification {
    top: ${({ isRulePageOutsideOfListing }): string =>
      isRulePageOutsideOfListing ? "55px" : "37px"};
    margin-left: ${({ isRulePageOutsideOfListing }): string =>
      isRulePageOutsideOfListing ? "15px" : ""};
    width: ${({ isRulePageOutsideOfListing }): string =>
      isRulePageOutsideOfListing ? "99%" : "100%"};
  }

  .ant-progress-outer {
    width: ${({ isRulePageOutsideOfListing }): string =>
      isRulePageOutsideOfListing ? "99%" : "100%"};
  }

  .page-content {
    padding: ${({
      isProfilingInProgress,
      isRulePageOutsideOfListing,
    }): string =>
      isProfilingInProgress
        ? "45px 16.4px 36.8px"
        : isRulePageOutsideOfListing
        ? "0"
        : "0 16.4px 20px"};

    ${({ isViewSaved, theme }): string =>
      isViewSaved
        ? `
    .page-header {
      .right {
        .save-icon {
          svg {
            path {
              fill: ${theme.borderColors.DARK_GREY_10} !important;
            }
          }
        }
      }
    }
    `
        : ""}

    .listing-page-header-divider {
      background: ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
    }

    .grid-buttons-bar {
      display: flex;
      gap: 15.6px;
      padding: 9.6px 0px;
      margin-top: 16.8px;

      button {
        display: flex;
        gap: 5px;
        align-items: baseline;
        font-family: OpenSansSemiBold;

        svg {
          width: 12px;
          height: 12px;

          path {
            fill: ${({ theme }): string => theme.textColors.DARK_BLUE};
          }
        }
      }
    }

    .content-header {
      .ant-btn svg {
        margin-right: 4.8px;

        .plus-circle-cls-1 {
          stroke: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        }

        path {
          fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        }
      }
    }

    .ag-theme-alpine {
      cursor: ${({ isSelectAll }): string =>
        isSelectAll ? "not-allowed" : ""};
      opacity: ${({ isSelectAll }): string => (isSelectAll ? "0.5" : "")};
      .ag-root-wrapper {
        pointer-events: ${({ isSelectAll }): string =>
          isSelectAll ? "none" : ""};
      }
    }

    .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
      min-height: 420px;
    }

    .ag-selection-checkbox {
      margin-right: 15px;
    }

    .ag-checkbox-input-wrapper {
      font-size: 13px;
      line-height: 13px;
      width: 13px;
      height: 13px;
      margin-top: 1px;
    }

    .ag-header-cell {
      .ag-header-icon {
        .ag-icon-menu {
          display: block;
        }
      }
    }

    .ag-row {
      font-size: 13px;

      &.default-row {
        background: ${({ theme }): string => theme.bgColors.BLUE_11};
      }
      &.disabled-row {
        opacity: 0.5;
      }

      &.hidden-row {
        background: ${({ theme }): string => theme.bgColors.BLUE_10};
      }

      .ag-selection-checkbox + .ag-cell-value {
        .ag-react-container {
          div {
            button.name-renderer {
              height: 40px;
            }

            :not(.status-renderer) {
              /* margin-left: 2px; */
            }
          }
        }
      }
    }

    .listing-grid {
      margin-top: ${({
        isRulePageOutsideOfListing,
        isProfilingInProgress,
      }): string =>
        isRulePageOutsideOfListing && isProfilingInProgress ? "50px" : ""};

      display: ${({ isLoadingData }): string =>
        isLoadingData ? "none" : "block"};
    }
  }

  .chat-listing-heading {
    font-size: 16px;
    font-family: OpenSansSemiBold;
    margin-bottom: 12.8px;
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
  }
`;

export const StatusRendererStyled = styled.div`
  padding: 13px 0px;
  padding-left: 15px;

  .node-status-rendrer {
    width: 14px;

    svg {
      width: 13.6px;
      height: 13.6px;
    }
  }

  .new-icon {
    width: 21px;

    svg {
      width: 20.6px;
      height: 13.6px;
      margin-left: -4px;
    }
  }
`;

export const DateTimeRendererStyled = styled.div``;

export const FeedbackRendererStyled = styled.div`
  display: flex;
  align-items: center;
  height: 41px;
  width: fit-content;

  &.approved-icon {
    svg {
      path {
        fill: ${({ theme }): string => theme?.textColors?.GREEN_111};
      }
    }
  }

  &.rejected-icon {
    svg {
      path {
        fill: ${({ theme }): string => theme?.textColors?.RED_16};
      }
    }
  }
`;

export const WorkflowStatusRendererStyled = styled.div<{
  workflowStatusId?: string;
  isFromListing?: boolean;
}>`
  display: flex;
  align-items: center;
  margin: ${({ isFromListing }): string => (isFromListing ? " 7px 0" : "")};
  div {
    display: flex;
    align-items: center;
    height: 26px;
    padding: 0 10px;
    border-radius: 4px;
    border: solid 1px
      ${({ theme, workflowStatusId }): string =>
        workflowStatusId === "ACK"
          ? theme.borderColors.BLUE_150
          : theme.bgColors.GREEEN_202};
    background-color: ${({ theme, workflowStatusId }): string =>
      workflowStatusId === "ACK"
        ? theme.bgColors.LIGHT_BLUE_25
        : theme.bgColors.GREEN_12};
    color: ${({ theme, workflowStatusId }): string =>
      workflowStatusId === "ACK"
        ? theme.textColors.BLUE_116
        : theme.textColors.GREEN_11};
  }
`;

export const TagsRendererStyled = styled.div`
  .prominent-tags-sec {
    height: 40px;
  }
`;

export const TagsRemainingSecStyled = styled.div`
  max-height: 200px;
  overflow-y: auto;
  .remaining-tags-tag {
    ${ellipsis}
  }
`;

export const NameRendererWrapperStyled = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  .scheduled-icon {
    height: 35px;
    svg {
      height: 10px;
      width: 10px;

      path {
        fill: ${({ theme }): string => theme.textColors.GREY_18};
      }
    }
  }

  .eye-icon {
    display: flex;
    align-items: center;
    // margin-bottom: 14px;
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.GREY_18};
      }
    }
  }

  .sensitive-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: ${({ theme }): string => theme.textColors.RED_101};
    color: ${({ theme }): string => theme.textColors.RED_100};
    width: 65px;
    height: 25px;
    font-size: 10px;
    font-weight: 800;
    margin-left: 6px;
  }
`;

export const NameRendererStyled = styled.div<{
  isHidden?: boolean;
  isOnlineJob?: boolean;
}>`
  display: flex;
  gap: 5px;
  height: 40px;

  button {
    text-align: left;
    max-width: 100%;

    span {
      max-width: 100%;
      color: ${({ theme, isHidden, isOnlineJob }): string =>
        isHidden || isOnlineJob ? theme.textColors.GREY_40 : ""};

      font-style: ${({ isOnlineJob }): string => (isOnlineJob ? "italic" : "")};

      cursor: ${({ isHidden, isOnlineJob }): string =>
        isHidden || isOnlineJob ? "default" : "pointer"};

      ${ellipsis};
      overflow: ${({ isOnlineJob }): string =>
        !isOnlineJob ? "hidden" : "inherit"};
    }
  }

  .default-item {
    color: ${({ theme }): string => theme.textColors.GREY_101};
    font-style: italic;
    font-size: 12px;
  }

  .url-link {
    text-decoration: underline;
    color: ${({ theme, isHidden, isOnlineJob }): string =>
      isHidden || isOnlineJob
        ? theme.textColors.GREY_40
        : theme.textColors.DARK_BLUE};
  }

  .deactivated-item {
    font-style: italic;
    color: ${({ theme }): string => theme.textColors.GREY_210};
    font-size: 14px;
    width: 90px;
  }
`;

export const RefreshStatusRendererStyled = styled.div`
  padding-left: 15px;
`;

export const DataTypeRendererStyled = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const ListingEntityRendrerStyled = styled.div`
  display: flex;
  align-items: flex-start;

  > div {
    width: auto;
  }

  .listing-node-status-rendrer {
    display: block;

    svg {
      margin-left: 5px;
      margin-top: 15px;
      width: 13.6px;
      height: 13.6px;
    }
  }
`;

export const ConfidenceScoreRendererStyled = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

export const MatchInfoRendrerStyled = styled.div`
  .menu-sec {
    justify-content: flex-start;
  }
`;

export const DescriptionRendererStyled = styled.div`
  ${ellipsis}
`;

export const IsCDEFieldRendrerStyled = styled.div`
  padding-left: 17px;
`;

export const GlobalFiltersWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  margin-bottom: 15px;

  .filters-list-label {
    min-width: fit-content;
  }

  .filters-list-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;

    .filter-tag {
      height: 25px;
      border-radius: 37px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      text-transform: capitalize;
      cursor: default;
      color: ${({ theme }): string => theme.textColors.GREY_24};

      .filter-count {
        font-weight: 600;
        margin-right: 3px;
      }

      .ant-tag-close-icon {
        margin-top: 1px;
      }
    }

    .clear-all-btn {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      cursor: pointer;
    }
  }
`;

export const PopularityScoreRendererStyled = styled.div`
  width: max-content;

  &:first-child {
    display: flex;
    align-items: center;
    height: 40px;
  }
`;
export const UserStyled = styled.div`
  line-height: 16px;

  div {
    background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_27};
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
    font-size: 11.2px;
    padding: 0px 5px;
    width: fit-content;
    max-width: 150px;
    height: 17.6px;
    border-radius: 5px;
    margin: 13.6px 0px;
    text-align: center;
    ${ellipsis}
  }
`;

export const ListingProfilingColumnRendererStyled = styled.div`
  margin-top: 13px;
  .profiling-rendrer-progress {
    .bar-item {
      > p {
        line-height: 3.2;
        margin-top: 0px;
      }
    }
  }
`;

export const QuestionRendererStyled = styled.div``;

export const QuestionRendererTooltipContent = styled.div`
  width: 260px;

  .value {
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
  }

  .desc {
    color: ${({ theme }): string => theme?.textColors?.GREY_210};
    margin-top: 16px;
  }
`;

export const AnalyticsStatusRendererStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 41px;
  padding-right: 38px;

  .ant-btn-link {
    display: flex;

    svg {
      path {
        fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }
  }
`;

export const AlertStatusRendererStyled = styled.div<{
  status?: "HEL" | "ALE" | "";
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 80px;
  height: 30px;
  margin-top: 4px;

  background: ${({ theme, status }): string =>
    status === "HEL"
      ? theme.bgColors.GREEN_12
      : status === "ALE"
      ? theme.bgColors.RED_101
      : ""};
  color: ${({ theme, status }): string =>
    status === "HEL"
      ? theme.bgColors.GREEN_11
      : status === "ALE"
      ? theme.bgColors.RED_100
      : ""};
  border: ${({ theme, status }): string =>
    status === "HEL"
      ? `1px solid ${theme.bgColors.GREEEN_202}`
      : status === "ALE"
      ? `1px solid ${theme.bgColors.RED_202}`
      : ""};
`;

export const RunStatusLoaderStyled = styled.div`
  margin-top: 3px;
`;

export const RuleActionsRendererStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  margin-top: 8px;
  margin-left: 8px;
`;

export const RulesWorkflowStatusRendererStyled = styled.div<{
  workflowStatusId?: string;
  isFromListing?: boolean;
}>`
  display: flex;
  align-items: center;
  margin: ${({ isFromListing }): string => (isFromListing ? " 7px 0" : "")};
  div {
    display: flex;
    align-items: center;
    height: 26px;
    padding: 0 10px;
    border-radius: 4px;
    border: solid 1px
      ${({ theme, workflowStatusId }): string =>
        workflowStatusId === "ACK"
          ? theme.borderColors.BLUE_150
          : theme.bgColors.GREEEN_202};
    background-color: ${({ theme, workflowStatusId }): string =>
      workflowStatusId === "ACK"
        ? theme.bgColors.LIGHT_BLUE_25
        : theme.bgColors.GREEN_12};
    color: ${({ theme, workflowStatusId }): string =>
      workflowStatusId === "ACK"
        ? theme.textColors.BLUE_116
        : theme.textColors.GREEN_11};
  }
`;

export const JobExecutionStatusRendererStyled = styled.div<{
  execStatus?: "RUN" | "SCH" | "CMP" | "ABR" | "ERR";
}>`
  color: ${({ theme, execStatus }): string =>
    execStatus === "RUN"
      ? theme.textColors.YELLOW_223
      : execStatus === "CMP"
      ? theme.textColors.GREEN_11
      : execStatus === "ABR" || execStatus === "ERR"
      ? theme.textColors.RED_100
      : theme.textColors.GREY_24};
`;
