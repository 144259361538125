import styled from "styled-components";

export const ShareOwnerFormStyled = styled.div`
  .ant-form {
    .content-sec {
      padding: 25.6px 35.2px 39.2px 28px;

      .ant-form-item {
        padding-left: unset;
      }

      .copy-link-icon {
        margin-bottom: 0px !important;

        .chain-icon {
          display: flex;
          gap: 8px;
          align-items: center;

          svg {
            path {
              fill: ${({ theme }): string =>
                theme.textColors.BLUE_116} !important;
            }
          }
        }
      }
    }
  }
`;
