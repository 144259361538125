import styled from "styled-components";

import { FlexStyled } from "../../../../../../../flex";

export const DQRuleDetailsModalRightSecStyled = styled(FlexStyled)`
  .download-btn {
    display: flex;
    align-items: center;
  }
`;
