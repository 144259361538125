import {
  DynamicAlertDataCheck,
  DynamicHideShowElementsProps,
} from "../connectionsettingstabform.types";

/**
 *
 * @param newHideShowElements
 * @param responseCheckData
 * @param singleResponseCheck
 * @returns any
 * @description
 * map over  newHideShowElements and filter elements based on the
 * check failed or passed. If responseCheckData is empty then onCheckPass will be performed otherwise onCheckFail will be performed.
 * inside newHideShowElements map it map over onCheckFail and onCheckPass props
 */
export function getHideShowElementsForResponseCheck(
  newHideShowElements: DynamicHideShowElementsProps[],
  responseCheckData: string[],
  dataCheck: DynamicAlertDataCheck
): any {
  const updated = newHideShowElements?.map((item) => {
    if (responseCheckData?.length) {
      // Failed
      return dataCheck?.onCheckFail?.hideShowElements
        ?.map((cr) => {
          if (cr?.elementsType === item?.elementsType) {
            if (
              item?.elementsRef?.every((eItem) =>
                cr?.elementsRef?.includes(eItem)
              )
            ) {
              return { ...cr };
            }

            return [
              {
                ...item,
                elementsRef: item?.elementsRef?.filter(
                  (fItem) => !cr?.elementsRef?.includes(fItem)
                ),
              },
              { ...cr },
            ];
          }

          return cr;
        })
        ?.flat();
    }

    // Passed
    return dataCheck?.onCheckPass?.hideShowElements
      ?.map((cr) => {
        if (cr?.elementsType === item?.elementsType) {
          if (
            item?.elementsRef?.every((eItem) =>
              cr?.elementsRef?.includes(eItem)
            )
          ) {
            return { ...cr };
          }

          return [
            {
              ...item,
              elementsRef: item?.elementsRef?.filter(
                (fItem) => !cr?.elementsRef?.includes(fItem)
              ),
            },
            { ...cr },
          ];
        }

        return cr;
      })
      ?.flat();
  });

  return updated?.flat();
}
