import { useHistory } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  useGetAllSAWS,
  usePingCheck,
} from "../../api/accountsettingsservice/sawsservice";

import { useGatewayStatusContext } from "../../contexts";

import {
  getGatewaysStatusesListFromLocalStorage,
  jsonStringify,
} from "../../utils";

import { ModalStyled } from "../modalwrapper/modalwrapper.styles";
import { GatewayStatusWrapperStyled } from "./gatewayswrapper.styles";

import { notAllowedIcon } from "../../svgs";
import { Button } from "../button/button.component";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants";
import { useGatewayConRetry } from "../../customhooks/usegatewayretry";

const GatewaysWrapper = (): JSX.Element => {
  const history = useHistory();

  const [isDataSavedIntoContext, setIsDataSavedIntoContext] = useState(false);

  const {
    refetch,
    isFetched,
    parsedData: allSaws = [],
    isFetching,
  } = useGetAllSAWS(false, false);

  const {
    dispatch,
    state: {
      listOfGateways,
      currentGatewayId = "",
      showGatewayDownPrompt = false,
      isFetchingGatewaysList = false,
    },
  } = useGatewayStatusContext();

  const { onRetryInBackground } = useGatewayConRetry(currentGatewayId);

  const data = usePingCheck(
    isFetching || isFetchingGatewaysList ? false : !!allSaws?.length,
    allSaws
      ?.filter((item) => item?.is_on_prem_saws)
      ?.map((item) => ({
        host_name: item?.host_name,
        port: item?.port,
        id: `${item?.id}`,
      })),
    false
  );

  useEffect(() => {
    if (!isFetched || isFetching) {
      setIsDataSavedIntoContext(false);
    }
  }, [isFetched, isFetching]);

  useEffect(() => {
    const isGatewaysListAlredayExists = getGatewaysStatusesListFromLocalStorage();
    const currentTime = new Date().getTime();
    const nextGatewayCheckTime = localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.next_gateway_check_time
    );

    if (
      !isGatewaysListAlredayExists ||
      (isGatewaysListAlredayExists &&
        nextGatewayCheckTime &&
        +nextGatewayCheckTime < currentTime)
    ) {
      dispatch({ type: "START_FETCHING_DETAILS" });
      refetch();
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (isFetched && !isFetching) {
      dispatch({ type: "STOP_FETCHING_DETAILS" });
      dispatch({
        type: "UPDATE_GATEWAYS_LIST",
        listOfGateways: allSaws?.map((saws) => ({
          host: saws?.host_name,
          port: saws?.port,
          id: `${saws?.id}`,
          isGatewayRunning: false,
          isOnpremiseGateway: saws?.is_on_prem_saws,
          isPingcheckInProgress: true,
          sawsCommunicationKey: saws?.communication_key,
          name: saws?.web_service,
          isCloudGateway: !saws?.is_on_prem_saws,
        })),
      });
      const currentTime = new Date().getTime();
      const newDate = new Date(currentTime);

      const nextGatewayCheckTime = localStorage.getItem(
        LOCAL_STORAGE_CONSTANTS.next_gateway_check_time
      );

      if (!nextGatewayCheckTime || +nextGatewayCheckTime < currentTime) {
        localStorage.setItem(
          LOCAL_STORAGE_CONSTANTS.next_gateway_check_time,
          newDate.setTime(newDate.getTime() + 24 * 60 * 60 * 1000).toString()
        );
      }

      setIsDataSavedIntoContext(true);
    }
  }, [isFetched, isFetching]);

  useEffect(() => {
    if (
      !isFetchingGatewaysList &&
      isDataSavedIntoContext &&
      isFetched &&
      !isFetching
    ) {
      dispatch({
        type: "UPDATE_GATEWAYS_LIST",
        listOfGateways: listOfGateways?.map((item) => ({
          ...item,
          isGatewayRunning: !!data?.[item?.id]?.isGatewayRuning,
          isPingcheckInProgress: !!data?.[item?.id]?.isLoading,
        })),
      });
    }
  }, [jsonStringify(data), isDataSavedIntoContext, isFetching]);

  const showPrompt = useMemo(() => {
    const currentGateway = listOfGateways?.find(
      (gateway) => gateway?.id === currentGatewayId
    );

    return (
      currentGateway?.isGatewayRunning &&
      showGatewayDownPrompt &&
      !currentGateway?.isCloudGateway
    );
  }, [showGatewayDownPrompt, currentGatewayId]);

  const onContinue = useCallback(() => {
    dispatch({
      type: "HIDE_GATEWAY_DOWN_PROMPT",
    });

    if (currentGatewayId) {
      onRetryInBackground?.();
    }
  }, [listOfGateways, currentGatewayId, onRetryInBackground]);

  return (
    <ModalStyled visible={showPrompt} closable={false} width="496px" centered>
      <GatewayStatusWrapperStyled>
        {notAllowedIcon()}
        <div className="heading-sec">Something went wrong</div>
        <div className="desc-sec">
          Seems like there is an issue with your Gateway connection, please
          contact the Gateway administrator.
        </div>
        <Button onClick={onContinue}>Continue</Button>
      </GatewayStatusWrapperStyled>
    </ModalStyled>
  );
};

export default GatewaysWrapper;
