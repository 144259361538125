import { useCallback, useEffect, useState } from "react";
import AgGridTable, {
  DateTimeRendrer,
} from "../../../../components/aggridtable";

import {
  GuidelinesTabStyled,
  GuidelinesTabWrapperStyled,
} from "./guidelinestab.styles";

import { BotGuidelinesColumns } from "./columndef";
import { GuidelinesTabProps } from "./guidelinestab.types";

import { GuidelineRendrer, TypeRendrer, ActionsRendrer } from "./rendrer";
import { TableWithSearchAndFiltersHOC } from "../../../../components/tablewithsearchandfiltershoc";

import { AgGridLocalPaginationWithSelect } from "../../../../components/aggridpaginationwithselect";
import LinkButton from "../../../../components/linkbutton/linkbutton";

import { GuidelinesBlankstate } from "./blankstate";
import { jsonStringify, openNotification } from "../../../../utils";

import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer";
import { deleteIcon1 } from "../../../../svgs";

import ConfirmationPopover from "../../../../components/confirmationpopover";
import StateHandler from "../../../../components/statehandler/statehandler";

import { BotGuidelineType } from "../../../../parsers";
import { useRequestWithMethod } from "../../../../api";

import { useSetData, useDebounce } from "../../../../customhooks";
import { API_CONFIG } from "../../../../constants/apiconfig";

import { useGetCaddiNegFeedbackProps } from "../../useGetCaddiNegFeedbackProps";
import { AnalysisPageQueryBox } from "../..";

import { QueryBlocksProps } from "../../analysisdetailpage.views";

const deleteIcon = deleteIcon1("9.6px", "11.2px");

const emptyFuncForDefVal = (): void => {};

const DataHandler = ({
  guideline,
}: {
  guideline: BotGuidelineType;
}): JSX.Element => {
  useGetCaddiNegFeedbackProps(
    (guideline?.query_data || {}) as AnalysisPageQueryBox,
    (guideline?.handlers || {}) as QueryBlocksProps["handlers"],
    guideline?.queries,
    guideline?.src_type_id,
    !guideline?.query_data?.results?.chart_config?.chart_type &&
      !!guideline?.query_data?.results?.columns?.length
  );

  return <div />;
};

const GuidelinesTabContent = (props: GuidelinesTabProps): JSX.Element => {
  const onSetData = useSetData();

  const {
    guidelines = [],
    handleClearAll = emptyFuncForDefVal,
    handleSelectionChanged = emptyFuncForDefVal,
    selectedRecords = 0,
    isSelectAll = false,
    gridApi,
    onGridReady,
    allGuidelinesLength = 0,
    onAddGuidelineClick,
    onRemoveFeedback,
    analysisId = "",
    sourceId = "",
    hiddenQrys = [],
    gatewayId = "",
  } = props;

  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);

  const selectedNodesCount = gridApi?.getSelectedNodes()?.length || 0;

  const guidlinesDebounce = useDebounce(guidelines, 250);

  useEffect(() => {
    gridApi?.refreshCells({ force: true });
  }, [selectedRecords]);

  const onBulkDeleteSuccess = useCallback(
    (res?: any) => {
      res?.data &&
        onSetData(API_CONFIG.get_bot_concepts, res?.data, [
          analysisId,
          sourceId,
        ]);

      gridApi?.deselectAll();

      if (res) {
        openNotification(
          <SuccessNotificationMessage
            message="Training deleted successfully."
            showSuccess
          />
        );
      }
    },
    [gridApi, sourceId, analysisId]
  );

  const {
    onExecuteRequest: onExecuteDeleteConceptRequest,
    isLoading: deleteConceptsIsLoading,
    error: deleteConceptsError,
  } = useRequestWithMethod(
    "delete_bot_concepts",
    [analysisId, sourceId],
    true,
    onBulkDeleteSuccess
  );

  const onBulkDelete = useCallback(() => {
    if (gridApi) {
      const selectedConcepts: BotGuidelineType[] = [];
      const selectedFeedbacks: BotGuidelineType[] = [];

      gridApi
        ?.getSelectedNodes()
        ?.forEach((item) =>
          ((item?.data || {}) as BotGuidelineType)?.type === "concept"
            ? selectedConcepts?.push(item?.data)
            : selectedFeedbacks?.push(item?.data)
        );

      const selectedConceptIds = selectedConcepts?.map((item) => ({
        cnp_id: item?.id,
      }));

      // concepts
      if (selectedConcepts?.length) {
        onExecuteDeleteConceptRequest({
          src_id: parseInt(sourceId || ""),
          da_id: parseInt(analysisId || ""),
          concept_json: selectedConceptIds,
        });
      }

      // feedbacks
      if (selectedFeedbacks?.length) {
        setIsFeedbackLoading(true);
        const selectedFeedbacksIds = selectedFeedbacks?.map((item) => item?.id);
        onRemoveFeedback(
          selectedFeedbacksIds,
          (): void => {
            setIsFeedbackLoading(false);
            onBulkDeleteSuccess();
          },
          true
        );
      }
    }
  }, [gridApi, onRemoveFeedback, onBulkDeleteSuccess]);

  const applyTransactionToGrid = useCallback(() => {
    const guidlinesIds: string[] = guidelines?.map((item) => item?.id);
    const renderedRowData: BotGuidelineType[] = [];

    const itemsToAdd: BotGuidelineType[] = [];
    const itemsToUpdate: BotGuidelineType[] = [];

    gridApi?.forEachNode((node) => {
      renderedRowData.push(node?.data);
    });

    const itemsToRemove: BotGuidelineType[] = renderedRowData?.filter(
      (rowData) => !guidlinesIds.includes(rowData?.id)
    );

    guidelines?.forEach((item) => {
      const rowNode = gridApi?.getRowNode(item?.id);
      if (rowNode) {
        itemsToUpdate.push({ ...item });
      } else {
        itemsToAdd.push({ ...item });
      }
    });

    if (itemsToAdd?.length) {
      itemsToAdd?.forEach((item) => {
        gridApi?.applyTransaction({
          add: [item],
        });
      });
    }

    if (itemsToUpdate?.length) {
      gridApi?.applyTransaction({
        update: itemsToUpdate,
      });
    }

    if (itemsToRemove?.length) {
      gridApi?.applyTransaction({
        remove: itemsToRemove,
      });
    }

    gridApi?.refreshCells({
      force: true,
      suppressFlash: true,
    });
  }, [guidelines, gridApi]);

  useEffect(() => {
    applyTransactionToGrid?.();
  }, [jsonStringify(guidlinesDebounce), gridApi]);

  return (
    <StateHandler
      isFetching={isFeedbackLoading || deleteConceptsIsLoading}
      error={deleteConceptsError}
      isModal
    >
      {allGuidelinesLength ? (
        <>
          {!!selectedNodesCount && (
            <ConfirmationPopover
              heading="Delete Training"
              desc="This action cannot be reversed. Are you sure?"
              placement="topRight"
              arrowPointAtCenter
              okText="Delete"
              cancelText="Cancel"
              onOk={onBulkDelete}
            >
              <LinkButton id="danger" className="bulk-del-btn">
                {deleteIcon} Delete
              </LinkButton>
            </ConfirmationPopover>
          )}
          <GuidelinesTabStyled>
            {gridApi && (
              <AgGridLocalPaginationWithSelect
                selectionState={{
                  isSelectAll,
                  selectedRecords: selectedNodesCount,
                }}
                onClear={handleClearAll}
                gridApi={gridApi}
              />
            )}
            <AgGridTable<BotGuidelineType>
              tableColumns={BotGuidelinesColumns}
              rowData={[]}
              rowHeight={70}
              defaultColDef={{ resizable: true, sortable: true }}
              cellRenderers={{
                guideline: { render: "GuidelineRendrer" },
                type: { render: "TypeRendrer" },
                action_rend: {
                  render: "ActionsRendrer",
                  params: { selectedRecords, gatewayId },
                },
                updated_on: {
                  render: "DateTimeRendrer",
                },
              }}
              getRowNodeId={(params: any): string => {
                return params?.id;
              }}
              maxHeight="calc(100vh - 380px)"
              frameworkComponents={{
                GuidelineRendrer,
                TypeRendrer,
                ActionsRendrer,
                DateTimeRendrer,
              }}
              isTableWidthfitTowindowSize={false}
              showDefaultPagination={false}
              onSelectionChanged={handleSelectionChanged}
              onGridReady={onGridReady}
              rowSelection="multiple"
              theme="ag-theme-alpine"
            />
          </GuidelinesTabStyled>
          {hiddenQrys?.map((item) => (
            <DataHandler guideline={item} key={item?.id} />
          ))}
        </>
      ) : (
        <GuidelinesBlankstate onAddGuidelineClick={onAddGuidelineClick} />
      )}
    </StateHandler>
  );
};

const GuidelinesTab = (props: GuidelinesTabProps): JSX.Element => (
  <GuidelinesTabWrapperStyled>
    <TableWithSearchAndFiltersHOC filterColumnName="" appliedFilters={[]}>
      <GuidelinesTabContent {...props} />
    </TableWithSearchAndFiltersHOC>
  </GuidelinesTabWrapperStyled>
);

export default GuidelinesTab;
