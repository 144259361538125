import { BannerStyled } from "./banner.styles";
import { BannerProps } from "./banner.types";

const Banner = (props: BannerProps): JSX.Element => {
  const { content = "", bgColor, color } = props;

  return <BannerStyled bgColor={bgColor} color={color}>{content}</BannerStyled>;
};

export default Banner;
