import { Tooltip } from "antd";
import { useMemo } from "react";
import { tickIconGreen } from "../../../../svgs";
import { passwordPolicy } from "./passwordpolicytooltip.constants";
import {
  PasswordPolicyPoints,
  PasswordPolicyTooltipStyled,
} from "./passwordpolicytooltip.styles";
import { PasswordPolicyTooltipProps } from "./passwordpolicytooltip.types";

const PasswordPolicyTooltip = ({
  children,
}: PasswordPolicyTooltipProps): JSX.Element => {
  const renderPasswordPolicyPoints = useMemo(() => {
    return (
      <PasswordPolicyPoints>
        {passwordPolicy?.map((point: string, index: number) => (
          <div className="point" key={index}>
            {tickIconGreen()} <span>{point}</span>
          </div>
        ))}
      </PasswordPolicyPoints>
    );
  }, []);

  return (
    <PasswordPolicyTooltipStyled>
      <Tooltip
        title={renderPasswordPolicyPoints}
        placement="right"
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
        overlayClassName="available-filters-tooltip"
      >
        {children}
      </Tooltip>
    </PasswordPolicyTooltipStyled>
  );
};

export default PasswordPolicyTooltip;
