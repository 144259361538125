import styled from "styled-components";

export const CriteriaContainerStyled = styled.div<{ paddingLeft?: string }>`
  padding-left: ${({ paddingLeft }): string => paddingLeft || "0"};

  .specify-criteria-desc {
    color: ${({ theme }): string => theme.textColors.GREY_24};
    font-size: 13.8px;
    margin: 5px 0 8px 0;
    max-width: 50%;
  }

  .specify-criteria-fields {
    .field {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;

      .ant-select-multiple {
        width: 500px;

        .ant-select-selector {
          max-height: 34px;

          .ant-select-selection-item {
            height: 26px;
          }
        }
      }

      .ant-select-single {
        width: 270px;
      }

      .input-field-container > input {
        width: 270px;
      }

      .filters-container {
        display: grid;
        grid-template-columns: repeat(2, 270px);
        gap: 15px;
      }

      .values-container {
        display: grid;
        grid-template-columns: repeat(2, 270px);
        gap: 15px;

        .ant-select-multiple,
        .ant-select-multiple ~ .error {
          width: 560px;

          .ant-select-selector {
            max-height: 34px;

            .ant-select-selection-item {
              height: 26px;
            }
          }
        }

        &.date-type {
          grid-template-columns: 186px repeat(2, 167px);

          .dvsum-select-field {
            width: 186px;
          }

          .input-field-container > input {
            width: 167px;
          }
        }
      }
    }
  }

  .remove-criteria-btn {
    position: absolute;
    right: -15px;
    top: 7px;
  }

  .add-criteria-btn {
    svg {
      margin-right: 5px;
    }
  }
`;
