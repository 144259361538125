import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const addGroupSchema = yup.object().shape({
  name: yup.string().trim().required(REQUIRED_MESSAGE),
  group_role: yup.string().required(REQUIRED_MESSAGE),
});

export const deleteUserGroupSchema = yup.object().shape({
  assign_user_group_to: yup.string().required(REQUIRED_MESSAGE),
});
