import { categoryIcon, rightAngleIcon, termIcon } from "../../../svgs";
import {
  TermRenderItemForSelectStyled,
  TermRenderTitleForSelectStyled,
} from "./termrendrer.styles";

export const termTitleRenderForSelect = (title: string): any => (
  <TermRenderTitleForSelectStyled>{title}</TermRenderTitleForSelectStyled>
);

export const termItemRrenderForSelect = (item: any): any => ({
  value: item?.nodeId,
  label: (
    <TermRenderItemForSelectStyled className="render-item">
      {categoryIcon()}
      <div className="parent-name" title={item?.parentName}>
        {item?.parentName}
      </div>
      <div className="right-icon">{rightAngleIcon}</div> {termIcon("11", "10")}
      <div className="node-name" title={item?.nodeName}>
        {item.nodeName}
      </div>
    </TermRenderItemForSelectStyled>
  ),
});
