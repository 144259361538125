import { ElementWithDefaultTooltipProps } from "./elementwithdefaulttooltip.types";
import { ElementWithDefaultTooltipStyled } from "./elementwithdefaulttooltip.styles";

const ElementWithDefaultTooltip = (
  props: ElementWithDefaultTooltipProps
): JSX.Element => {
  const { title, value, children, className, width, showEllipsis } = props;

  return (
    <ElementWithDefaultTooltipStyled
      title={title}
      width={width}
      showEllipsis={showEllipsis}
      className={className || "element-with-default-tooltip"}
    >
      {children || value}
    </ElementWithDefaultTooltipStyled>
  );
};

export default ElementWithDefaultTooltip;
