import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { endOfDay, isBefore, parse, subDays } from "date-fns";
import { FormItemStyled } from "../form";

import {
  CheckboxField,
  CheckboxGroupField,
  DatePickerField,
  InputField,
  RadioGroupField,
  SelectField,
} from "../formfields";

import {
  SCAN_END_TYPES,
  SCAN_FREQUENCY_TYPES,
} from "../../constants/scanoptionscontants";

import { sortListOnSpecificKeyValue, sortObjectsArrayByKey } from "../../utils";
import TimePicker from "../timepicker/timepicker";

import { calendarIcon, clockIcon } from "../../svgs";
import { getAfterFieldForFrequencyType } from "./scanninginfo.renderer";

import {
  AfterFieldContainerStyled,
  InlineFieldsStyled,
  OnFieldContainerStyled,
  ScanningInfoFormStyled,
} from "./scanninginfoform.styles";

import { ScanningInfoFormProps } from "./scanninginfoform.type";
import { AddNewJobFormType } from "../../forms/addnewjobform/addnewjobform.types";
import FormItemLabel from "../form/formitemlabel/formitemlabel";

const calendarIconSvg = calendarIcon("10.347px", "11.144px");
const clockIconSvg = clockIcon("12.8px");

export const ScanningInfoForm = (props: ScanningInfoFormProps): JSX.Element => {
  const { data, invokedFrom } = props;

  const { control, setValue, watch } = useFormContext();

  const {
    frequencyType,
    isRepeat,
    repeatAfterXMinutes,
    daysOfMonth,
    ends,
    startOnTime,
  } = watch();

  const scanFrequencyTypeOptions = useMemo(
    () =>
      sortObjectsArrayByKey(SCAN_FREQUENCY_TYPES, "displayOrder")?.map(
        (item) => ({
          key: `key-scan-freq-op-${item?.id}`,
          label: item?.name,
          value: item?.id,
        })
      ) || [],
    []
  );

  const isSourcePage = invokedFrom === "SRC";
  const isAddNewJobPage = invokedFrom === "JOB";

  return (
    <ScanningInfoFormStyled>
      <FormItemStyled label="Frequency" paddingLeft="0">
        <InlineFieldsStyled>
          <SelectField
            placeholder="Select"
            setValue={setValue}
            control={control}
            allowClear={false}
            name="frequencyType"
            options={scanFrequencyTypeOptions}
            height="34px"
            width="240px"
          />
          {getAfterFieldForFrequencyType(frequencyType, control, setValue)}
        </InlineFieldsStyled>
      </FormItemStyled>
      <CheckboxField
        control={control}
        name="isRepeat"
        className="is-repeat-cb"
        disabled={!frequencyType}
      >
        Repeat tasks every
      </CheckboxField>
      {isRepeat && (
        <FormItemStyled paddingLeft="0">
          <InlineFieldsStyled>
            <InputField
              name="repeatAfterXMinutes"
              control={control}
              value={repeatAfterXMinutes}
              placeholder="Minutes"
              maxLength={2}
              width="134px"
              height="34px"
              type="number"
            />
            <label>for a duration of</label>
            <InputField
              control={control}
              name="repeatForXHours"
              placeholder="Hours"
              maxLength={2}
              width="134px"
              height="34px"
              type="number"
            />
          </InlineFieldsStyled>
        </FormItemStyled>
      )}
      {frequencyType === "WLY" && (
        <div className="weekly-field-item-container">
          <FormItemStyled
            label="Repeat On"
            marginBottom="0px!important"
            paddingLeft="0"
          />
          <InlineFieldsStyled>
            <CheckboxGroupField
              control={control}
              setValue={setValue}
              name="daysOfWeek"
              className="is-repeat-cb"
              options={[
                { label: "S", value: "SUN" },
                { label: "M", value: "MON" },
                { label: "T", value: "TUE" },
                { label: "W", value: "WED" },
                { label: "T", value: "THU" },
                { label: "F", value: "FRI" },
                { label: "S", value: "SAT" },
              ]}
            />
          </InlineFieldsStyled>
        </div>
      )}
      {frequencyType === "MLY" && (
        <>
          <FormItemStyled label="Repeat By" paddingLeft="0">
            <RadioGroupField
              value={daysOfMonth}
              control={control}
              name="daysOfMonth"
              options={[
                { label: "Day of the month", value: "DOM" },
                { label: "Day of the week", value: "DOW" },
              ]}
              direction="row"
              containerWidth="200px!important"
            />
          </FormItemStyled>
        </>
      )}
      <FormItemStyled label="Starts On" paddingLeft="0">
        <InlineFieldsStyled>
          <DatePickerField
            control={control}
            name="activationDate"
            width="240px"
            showToday={false}
            disabled={!frequencyType}
            format="MM/DD/YYYY"
            disabledDate={(currentDate): boolean =>
              isBefore(currentDate, endOfDay(subDays(new Date(), 1)))
            }
            suffixIcon={calendarIconSvg}
            placeholder="MM/DD/YYYY"
          />
          <label>at</label>
          <TimePicker
            control={control}
            name="startOnTime"
            setValue={setValue}
            defaultValue={
              startOnTime
              // ? // ? parse(startOnTime, "hh:mm a", new Date())
              //   startOnTime
              // : new Date()
            }
            placeholder="Select Time"
            width="142px"
            height="34px"
            suffixIcon={clockIconSvg}
            showClearButton={false}
            disabled={!frequencyType}
            // showToday={false}
          />
        </InlineFieldsStyled>
      </FormItemStyled>
      <FormItemStyled label="Ends" paddingLeft="0">
        <RadioGroupField
          value={ends}
          control={control}
          name="ends"
          options={[
            {
              label: "Never",
              value: "never",
              key: "ends-key-1",
            },
            {
              label: "After",
              value: "after",
              key: "ends-key-2",
              children: isAddNewJobPage ? (
                <FormItemLabel
                  // label={fieldLabel}
                  // description={fieldDescription}
                  key="endAfterRepeatingXTimes"
                  // width="56px"
                  // required={isFieldRequired}
                  // paddingLeft={}
                >
                  <AfterFieldContainerStyled>
                    <InputField
                      type="number"
                      width="56px"
                      control={control}
                      name="endAfterRepeatingXTimes"
                    />
                    <div className="text">occurrences</div>
                  </AfterFieldContainerStyled>
                </FormItemLabel>
              ) : undefined,
            },
            {
              label: "On",
              value: "on",
              key: "ends-key-3",
              children: isAddNewJobPage ? (
                <OnFieldContainerStyled>
                  <DatePickerField
                    width="150px"
                    control={control}
                    name="endsOnDate"
                    showToday={false}
                    format="MM/DD/YYYY"
                    disabledDate={(currentDate): boolean =>
                      isBefore(currentDate, endOfDay(subDays(new Date(), 1)))
                    }
                    suffixIcon={calendarIcon("10.347px", "11.144px")}
                  />
                </OnFieldContainerStyled>
              ) : undefined,
            },
          ]}
          disabled={!frequencyType}
          direction={isAddNewJobPage ? "column" : "row"}
          style={{ width: "340px" }}
        />
        {(ends === "after" || ends === "on") && isSourcePage && (
          <div className="ends-on-options-container">
            {ends === "after" && (
              <AfterFieldContainerStyled calledFromSourcePage>
                <InputField
                  width="56px"
                  control={control}
                  name="endAfterRepeatingXTimes"
                />
                <span>occurrences</span>
              </AfterFieldContainerStyled>
            )}
            {ends === "on" && (
              <OnFieldContainerStyled calledFromSourcePage>
                <DatePickerField
                  control={control}
                  name="endsOnDate"
                  showToday={false}
                  format="MM/DD/YYYY"
                  disabledDate={(currentDate): boolean =>
                    isBefore(currentDate, endOfDay(subDays(new Date(), 1)))
                  }
                  suffixIcon={calendarIcon("10.347px", "11.144px")}
                />
              </OnFieldContainerStyled>
            )}
          </div>
        )}
      </FormItemStyled>
    </ScanningInfoFormStyled>
  );
};

export default ScanningInfoForm;
